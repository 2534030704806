import {
  Avatar,
  Badge,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";

import { stringAvatar } from "../services/common";
type IProps = {
  RequestApprovers: Array<any>;
  loggedUser: any;
};
const Reviewers = (props: IProps) => {
  const { RequestApprovers, loggedUser } = props;
  let approver =
    RequestApprovers?.filter(
      (approver: any) => approver?.category === "approver"
    ) ?? []; //the transfer approvers
  approver = approver?.sort((a: any, b: any) => a.index - b.index);

  const reviewer =
    RequestApprovers?.filter(
      (approver: any) => approver?.category === "reviewer"
    ) ?? []; //the transfer reviewers
  return (
    <>
      {approver?.length > 0 && (
        <div className="row mb-4">
          <Typography style={{fontFamily:'inter'}} className="col-md-12 mb-3 fw-bold">
            Approvers Section
          </Typography>

          {approver?.map((appr: any) => (
            <div className="col-md-6">
              <List className="bg-light border rounded">
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    {loggedUser?.id === appr?.approver ? (
                      <Avatar>Me</Avatar>
                    ) : (
                      <Avatar
                        {...stringAvatar(
                          `${appr?.User?.Employee?.firstName ?? ""} ${
                            appr?.User?.Employee?.lastName ?? ""
                          }`
                        )}
                      />
                    )}
                  </ListItemAvatar>
                  <Badge
                    badgeContent={appr?.status === "active" ? "active" : null}
                    color={appr?.status === "active" ? "success" : "primary"}
                  >
                    <ListItemText
                      primary={`${appr?.User?.Employee?.firstName ?? ""} ${
                        appr?.User?.Employee?.lastName ?? ""
                      }`}
                      secondary={appr?.User?.Employee?.position?.name ?? ""}
                    />
                  </Badge>
                </ListItem>
              </List>
            </div>
          ))}
        </div>
      )}
      {reviewer?.length ? (
        <div className="row mb-4">
          <Typography style={{fontFamily:'inter'}} className="col-md-12 mb-3 fw-bold">
            Reviewers Section
          </Typography>

          {reviewer?.map((appr: any) => (
            <div className="col-md-3">
              <List>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    {loggedUser?.id === appr?.approver ? (
                      <Avatar>Me</Avatar>
                    ) : (
                      <Avatar
                        {...stringAvatar(
                          `${appr?.User?.Employee?.firstName ?? ""} ${
                            appr?.User?.Employee?.lastName ?? ""
                          }`
                        )}
                      />
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${appr?.User?.Employee?.firstName ?? ""} ${
                      appr?.User?.Employee?.lastName ?? ""
                    }`}
                    secondary={appr?.User?.Employee?.position?.name ?? ""}
                  />
                </ListItem>
              </List>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};
export default Reviewers;
