import React, { JSX, useEffect, useState } from "react";
import { fetchDetails, monthList, statusList } from "../../../services/common";
import ReportEngineComponent from "../../../components/ReportEngineComponent";

function ContributionReport(): JSX.Element {
  const [fiscalYears, setFiscalYears] = useState([]);
  const [value, setValue] = useState<any>({});
  const [list, setList] = useState<any>([]);
  const [cols, setCols] = useState<any>();

  const loadInitialData = async () => {
    // const employeeList = await fetchDetails("report/caisse/members");
    const fiscalYears = await fetchDetails(`fiscal/view`);
    if (fiscalYears.status) {
      setFiscalYears(fiscalYears.data);
    }
    // if (employeeList.status) setRequesters(employeeList?.data);
  };
  const getNewTemplate = () => {
    const data = list?.data;
    let newCols: any = [];
    newCols = [
      {
        label: "Emp. ID",
        name: "employee.employeeNumber",
      },
      {
        label: "Emp. Names",
        name: "employee.names",
        data: "employee.firstName"
      },
    ];
    for (let i = 0; i < monthList?.length; i++) {
      newCols.push({
        label: monthList[i]?.name,
        name: `${monthList[i]?.name}`,
        data:monthList[i]?.name,
        type: "number",
      });
    }

    newCols.push({
      label: "Total",
      name: "total",
      type: "number",
    });
    setCols(newCols);
  };
  useEffect(() => {
    loadInitialData().then();
  }, []);

  useEffect(() => {
    getNewTemplate();
  }, [list, monthList]);
  return (
    <ReportEngineComponent
      setList={setList}
      setValue={setValue}
      value={value}
      title={"Contribution Report"}
      url={`cde-report/contribution`}
      columns={cols}
      filters={[
        {
          label: "Fiscal Years",
          name: "fiscal",
          values: fiscalYears.map(({ name, id }: any) => ({
            label: name,
            value: id,
          })),
        },
        {
          label: "Month",
          name: "month",
          values: monthList.map(({ name, id }: any) => ({
            label: name,
            value: id,
          })),
        },

        // {
        //     label:"Requester",
        //     name:"requester",
        //     values:requesters.map(({employee, id}: any) => ({
        //         label: employee?.names,
        //         value: id
        //     }))
        // },
        {
          label: "Employee Name/ID",
          name: "empName",
          type: "text",
        },
        {
          label: "CDE Number",
          name: "cdeNumber",
          type: "text",
        },
        {
          label: "Status",
          name: "status",
          value: "approved",
          values: statusList,
        },
        {
          label: "Start Date",
          name: "startDate",
          type: "date",
        },
        {
          label: "End Date",
          name: "endDate",
          type: "date",
        },
      ]}
    />
  );
}

export default ContributionReport;
