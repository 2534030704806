import React, { JSX, useEffect, useState } from "react";
import { fetchDetails, monthList, statusList } from "../../../services/common";
import moment from "moment";
import { Chip } from "@mui/material";
import ReportEngineComponent from "../../../components/ReportEngineComponent";

function SocialFundReport(): JSX.Element {
  const [fundTypes, setFundTypes] = useState([]);
  const [value, setValue] = useState<any>({});

  const loadInitialData = async () => {
    const types = await fetchDetails("/social-fund/view");
    if (types.status) setFundTypes(types?.data);
  };

  useEffect(() => {
    loadInitialData().then();
  }, []);

  return (
    <ReportEngineComponent
    setValue={setValue}
    value={value}
      title={"Social Fund Report"}
      url={`cde-report/social`}
      columns={[
        {
          label: "No",
          name: "index",
          type:'integer',
        },
        {
          label: "Member",
          name: "CaisseMember.employee.names",
          data: "CaisseMember.employee.firstName",
        },
        {
          label: "Amount",
          name: "amount",
          type: "number",
          options: {
            customBodyRender: (v: any) => v?.toLocaleString(),
          },
        },
        {
          label: "Type",
          name: "SocialFundType.name",
        },
        {
          label: "Status",
          name: "status",
          options: {
            customBodyRender: (v: any) => <Chip label={v} size={"small"} />,
          },
        },
        {
          label: "Date",
          name: "createdAt",
          type: "date",
          options: {
            customBodyRender: (v: any) => moment(v).format("YYYY-MMM-DD"),
          },
        },
      ]}
      filters={[
        {
          label: "Social Fund Type",
          name: "type",
          values: fundTypes.map(({ name, id }: any) => ({
            label:name,
            value: id,
          })),
        },
       
        // {
        //     label:"Requester",
        //     name:"requester",
        //     values:requesters.map(({employee, id}: any) => ({
        //         label: employee?.names,
        //         value: id
        //     }))
        // },
        {
          label: "Employee Name/ID",
          name: "empName",
          type: "text",
        },
        {
          label: "CDE Number",
          name: "cdeNumber",
          type: "text",
        },
        {
          label: "Status",
          name: "status",
          values: statusList,
        },
        {
            label: "Start Date",
            name: "startDate",
            type: "date",
          },
          {
            label: "End Date",
            name: "endDate",
            type: "date",
          },
      ]}
    />
  );
}

export default SocialFundReport;
