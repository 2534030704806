import { Col, Row } from "react-bootstrap";
import Breadcrumb from "../../../components/Breadcrumb";
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Snackbar,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchDetails,
  getError,
  getFileMime,
  getSuccessMessage,
  submitGeneralForm,
} from "../../../services/common";
import { useEffect, useState } from "react";
import {
  Check,
  BorderColorOutlined,
  Forward,
  HighlightOff,
  Info,
  Report,
  Visibility,
  VisibilityOff,
  ToggleOff,
  ToggleOn,
} from "@mui/icons-material";
import moment from "moment";
import SmallDialog from "../../../components/SmallModel";
import { mapStateToProps } from "../../../store/helpers/mapState";
import { mapDispatchToProps } from "../../../store/helpers/mapDispatch";
import { connect } from "react-redux";
import WorkPlan from "./WorkPlan";
import OpenDocuments from "../../../components/openDocument";
import RequestHistory from "../../../components/RequestHistory";
import GeneralButton from "../../../components/Button";
import Reviewers from "../../../components/Reviewer";
import { LinearProgress } from "@material-ui/core";
import MissionDestination from "./MissionDestination";

const MissionDetails = (props: any) => {
  const { loggedUser } = props;
  const navigate = useNavigate();
  const { id }: any = useParams(); //transfer id
  const [viewDocument, setViewDocument] = useState(false);
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const [openSnack, setOpenSnack] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewFinalReport, setViewFinalReport] = useState(false);
  const [viewMissionOrder, setViewMissionOrder] = useState(false);
  const [documentFinal, setDocumentFinal] = useState<any>();
  const [info, setInfo] = useState<any>();
  const [plan, setPlan] = useState<Array<any>>([{ plan: "", date: "" }]);
  const [newData, setData] = useState<any>();
  const [openDialogue, setOpenDialogue] = useState<any>(false);
  const [destination, setDestination] = useState<Array<any>>([]);
  const [allDistrict, setAllDistrict] = useState<Array<any>>([]);

  const hasMissionApproverRole =
    loggedUser?.roles?.filter(
      (item: any) => item.role?.name === "mission-approver"
    )?.length > 0;

  const isInitiator = loggedUser?.id === info?.requester; //the transfer requestor
  const isApprover = loggedUser?.id === info?.approver; //the transfer approver

  const canUpdate =
    isInitiator &&
    (info?.status === "pending" || info?.status === "request action");
  let approver =
    info?.approvers?.filter(
      (approver: any) => approver?.category === "approver"
    ) ?? []; //the mission approvers
  approver = approver?.sort((a: any, b: any) => a.index - b.index);
  const lastApprover =
    approver[approver.length - 1]?.approver === info?.approver;

  const fetchMissionDetails = async () => {
    const resp = await fetchDetails(`mission/view/${id}`);
    const district = await fetchDetails(`report/districts`);
    if (district.status) {
      setAllDistrict(district.data);
    }
    if (resp.status) {
      setInfo(resp.data);
      setDestination(resp.data?.MissionDestinations);
      setViewFinalReport(false);
      setDocumentFinal(false);
    }
  };
  useEffect(() => {
    if (id) {
      //fetch mission details
      fetchMissionDetails().then();
    }
  }, [id]);

  useEffect(() => {
    if ((isApprover || hasMissionApproverRole) && info?.status === "pending") {
      onSubmit({
        id: info?.id,
        status: "under review",
        comment: "request is under review",
      });
    }
  }, [hasMissionApproverRole, info?.id, info?.status, isApprover]);

  const onSubmit = async (data?: any) => {
    setLoading(true);
    setConfirm(false);
    let infoToSave = data ?? newData;
    const resp = await submitGeneralForm(
      `/mission/change-status/${info?.id}`,
      infoToSave,
      null,
      "PUT"
    );
    if (resp.status) {
      setData({});
      fetchMissionDetails().then();
      setOpenDialogue(false);
      setSuccess(getSuccessMessage(resp));
      setError(null);
      setLoading(false);
    } else {
      setError(getError(resp));
      setSuccess(null);
      setLoading(false);
    }
    // setConfirm(false);
    // setLoading(false);
    setOpenSnack(true);
  };

  return (
    <div>
      <Breadcrumb />
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={() => {
          setOpenSnack(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {error ? (
          <Alert
            style={{ width: "100%" }}
            className={"mb-3"}
            severity={"error"}
            onClose={() => setError(null)}
          >
            {error}
          </Alert>
        ) : success ? (
          <Alert
            style={{ width: "100%" }}
            className={"mb-3"}
            severity={"success"}
            onClose={() => setSuccess(null)}
          >
            {success}
          </Alert>
        ) : (
          <></>
        )}
      </Snackbar>

      <Row>
        <Col md={12}>
          {!info?.id ? <LinearProgress /> : null}
          <Card className={"bg- shadow-sm p-3"}>
            <CardHeader
              subheader={<span className="fw-bold">{"Mission Details"}</span>}
              action={
                <span
                  className={
                    info?.status === "pending"
                      ? "badge bg-primary rounded-pill"
                      : info?.status === "rejected"
                      ? "badge bg-danger rounded-pill"
                      : info?.status === "request action"
                      ? "badge bg-warning rounded-pill"
                      : info?.status === "forwarded"
                      ? "badge bg-info rounded-pill"
                      : info?.status === "under review"
                      ? "badge bg-secondary rounded-pill"
                      : "badge bg-success rounded-pill"
                  }
                >
                  {info?.status}
                </span>
              }
            />
            <CardContent>
              {/* {info?.transportMeans ? null : (
                <Alert severity="warning" className="mb-2">
                  <AlertTitle>Warning</AlertTitle>
                  Request sent in finance
                </Alert>
              )} */}
              <div className="row mb-3 justify-content-end">
                {info?.status !== "approved" && info?.status !== "rejected" ? (
                  <div className="col-md-8 mb-3 fw-bold d-flex justify-content-end">
                    {isInitiator ? (
                      <>
                        {/*<Button*/}
                        {/*  className="me-2"*/}
                        {/*  size="small"*/}
                        {/*  color="error"*/}
                        {/*  variant="outlined"*/}
                        {/*  startIcon={<Delete />}*/}
                        {/*  onClick={() => {*/}
                        {/*    setDeleteOpen(1);*/}
                        {/*    setDeleteActionUrl(`/mission/delete/${info?.id}`);*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  Delete*/}
                        {/*</Button>*/}
                      </>
                    ) : null}
                    {canUpdate ? (
                      <Button
                        className="btn-primary me-2"
                        size="small"
                        variant="outlined"
                        startIcon={<BorderColorOutlined />}
                        onClick={() => {
                          navigate(`../mission/${info?.id}`);
                        }}
                      >
                        Update
                      </Button>
                    ) : null}
                    {(info?.status !== "rejected" ||
                      info?.status !== "approved") &&
                    hasMissionApproverRole &&
                    info?.financeAccess ? (
                      <Button
                        className="me-2 text-nowrap"
                        size="small"
                        color="warning"
                        variant="outlined"
                        startIcon={<Report />}
                        onClick={() => {
                          navigate(
                            `/console/approvals/missions/mission/${info?.id}`
                          );
                        }}
                      >
                        Add Transport Cost
                      </Button>
                    ) : null}
                    {isApprover &&
                    (info?.status === "under review" ||
                      info?.status === "forwarded") ? (
                      <>
                        <Button
                          className="me-1 text-nowrap"
                          color="secondary"
                          size="small"
                          variant="outlined"
                          startIcon={
                            info?.financeAccess ? <ToggleOn /> : <ToggleOff />
                          }
                          onClick={() => {
                            setOpenDialogue(true);
                            setData({
                              id: info?.id,
                              financeAccess: !info?.financeAccess,
                              title: info?.financeAccess
                                ? "Disable Finance Access"
                                : "Enable Finance Access",
                            });
                          }}
                        >
                          {info?.financeAccess
                            ? "Disable Finance Access"
                            : "Enable Finance Access"}
                        </Button>
                        <Button
                          className="me-1 text-nowrap"
                          color="warning"
                          size="small"
                          variant="outlined"
                          startIcon={<Info />}
                          onClick={() => {
                            setOpenDialogue(true);
                            setData({
                              id: info?.id,
                              status: "request action",
                              title: "Request Action",
                            });
                          }}
                        >
                          Request for action
                        </Button>
                        {approver?.length > 1 ? (
                          <Button
                            className="me-2 text-nowrap"
                            color="info"
                            size="small"
                            variant="outlined"
                            startIcon={<Forward />}
                            onClick={() => {
                              setOpenDialogue(true);
                              setData({
                                id: info?.id,
                                status: "forwarded",
                                title: "Forward Request",
                              });
                            }}
                          >
                            Review & Forward
                          </Button>
                        ) : null}
                        <Button
                          className="me-2 text-nowrap"
                          color="error"
                          size="small"
                          variant="outlined"
                          startIcon={<HighlightOff />}
                          onClick={() => {
                            setOpenDialogue(true);
                            setData({
                              id: info?.id,
                              status: "rejected",
                              title: "Reject Request",
                            });
                          }}
                        >
                          Reject
                        </Button>
                        {lastApprover ? (
                          <Button
                            className="me-2 text-nowrap"
                            color="success"
                            size="small"
                            // disabled={!lastApprover}
                            variant="outlined"
                            startIcon={<Check />}
                            onClick={() => {
                              setOpenDialogue(true);
                              setData({
                                id: info?.id,
                                status: "approved",
                                title: "Approve Request",
                              });
                            }}
                          >
                            Approve
                          </Button>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                ) : null}

                {info?.status === "approved" ? (
                  <div className="col-md-8 mb-3 fw-bold d-flex justify-content-end">
                    {info?.reported === "yes" ? (
                      <Button
                        className="btn-primary me-2"
                        size="small"
                        variant="outlined"
                        startIcon={
                          viewFinalReport ? <VisibilityOff /> : <Visibility />
                        }
                        onClick={() => {
                          setViewFinalReport(!viewFinalReport);
                          setDocumentFinal(info?.missionReport);
                        }}
                      >
                        Mission Report
                      </Button>
                    ) : null}
                    <Button
                      className="me-2"
                      size="small"
                      color="primary"
                      variant="outlined"
                      startIcon={
                        viewMissionOrder ? <VisibilityOff /> : <Visibility />
                      }
                      onClick={() => {
                        setViewMissionOrder(!viewMissionOrder);
                        setDocumentFinal(info?.missionOrder);
                      }}
                    >
                      Mission Order
                    </Button>
                    {isInitiator && info?.reported === "no" ? (
                      <Button
                        className="me-2"
                        size="small"
                        color="success"
                        variant="outlined"
                        startIcon={<Report />}
                        onClick={() => {
                          setOpenDialogue(true);
                          setData({ id: info?.id, reported: "yes" });
                        }}
                      >
                        Generate Report
                      </Button>
                    ) : null}
                  </div>
                ) : null}
              </div>
              {viewFinalReport || viewMissionOrder ? (
                <div>
                  {documentFinal && (viewFinalReport || viewMissionOrder) ? (
                    <div className={"row justify-content-center"}>
                      <div className={"col-md-8"}>
                        {documentFinal?.map((v: string) => (
                          <OpenDocuments
                            downloadable={true}
                            card={true}
                            document={v}
                            mimeType={getFileMime(v)}
                          />
                        ))}
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                <>
                  <div className="row mb-4">
                  <div className="col-md-4">
                  <div className="fw-bold mb-2">First Name</div>
                      <div>{info?.missionaryEmployee?.firstName ?? "---"}</div>
                    </div>
                    <div className="col-md-4">
                      <div className="fw-bold mb-2">Last Name</div>
                      <div>{info?.missionaryEmployee?.lastName ?? "---"}</div>
                    </div>
                    <div className="col-md-4">
                      <div className="fw-bold mb-2">Position</div>
                      <div>
                        {info?.missionaryEmployee?.position?.name ?? "---"}
                      </div>
                    </div>
                  </div>
                  {/* <div className="row mb-4">
                    <div className="col-4">
                      <div className="fw-bold mb-2">Start Date</div>
                      <div>
                        {moment(info?.startDate).format("YYYY-MMM-DD") ?? "---"}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="fw-bold mb-2">End Date</div>
                      <div>
                        {moment(info?.endDate).format("YYYY-MMM-DD") ?? "---"}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="fw-bold mb-2">Destination</div>
                      <div>{info?.District?.name}</div>
                    </div>
                  </div> */}
                  {/* <div className="row mb-4">
                    <div className="col-4">
                      <div className="fw-bold mb-2">Transportation means</div>
                      <div> {info?.transportMeans ?? "---"}</div>
                    </div>
                    <div className="col-4">
                      <div className="fw-bold mb-2">Kilometer(s)</div>
                      <div>{info?.kilometer ?? "---"}</div>
                    </div>
                    <div className="col-4">
                      <div className="fw-bold mb-2">Transport Cost(RWF)</div>
                      <div>
                        {info?.transportPrice?.toLocaleString() ?? "---"}
                      </div>
                    </div>
                  </div> */}
                  <div className="row mb-3">
                  <div className="col-md-4">
                  <div className="fw-bold mb-2">Mission Cost (RWF)</div>
                      <div>{info?.missionCost?.toLocaleString() ?? "---"}</div>
                    </div>
                    <div className="col-md-4">
                      <div className="fw-bold mb-2">Who cover the mission allowance</div>
                      <div>{info?.missionCoveredBy}</div>
                    </div>
                    <div className="col-md-4">
                      <div className="fw-bold mb-2">Covered Allowance (RWF)</div>
                      <div>{info?.coveredAllowance?.toLocaleString() ?? "---"}</div>
                    </div>
                  </div>
                  {/* <div className="row mb-4">
                    <div className="col-4">
                      <div className="fw-bold mb-2">Other Fees(RWF)</div>
                      <div>{info?.otherFee?.toLocaleString() ?? "---"}</div>
                    </div>
                  </div> */}
                  {/* <div className="row mb-4">
                    <div className="fw-bold mb-2">Fees Of Other RWF </div>
                    <div className="col-10">
                      <div>{info?.otherFeeDescription ?? "---"}</div>
                    </div>
                  </div> */}
                  <div className="row mb-3">
                    <div className="fw-bold mb-2">Reason</div>
                    <div className="col-10">
                      <div>{info?.reason ?? "---"}</div>
                    </div>
                  </div>
                  {info?.supportingDocument ? (
                    <div className="row mb-4">
                      <div className="col-md-12">
                        <div className="input-group mb-3">
                          <Typography
                            style={{ fontFamily: "Inter" }}
                            className="mb-3 fw-bold me-3"
                          >
                            Supporting Document
                          </Typography>
                          <GeneralButton
                            variant="outlined"
                            icon={
                              viewDocument ? <VisibilityOff /> : <Visibility />
                            }
                            onClick={() => {
                              setViewDocument(!viewDocument);
                            }}
                            name={"View Document"}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {info?.supportingDocument && viewDocument ? (
                    <div className={"row justify-content-center"}>
                      <div className={"col-md-8"}>
                        {info.supportingDocument.map((v: string) => (
                          <OpenDocuments
                            downloadable={true}
                            onDelete={() => {
                              let documentCopy = [...info?.supportingDocument];
                              const index = documentCopy.indexOf(v);
                              documentCopy.splice(index, 1);
                              onSubmit({
                                id: info.id,
                                supportingDocument:
                                  JSON.stringify(documentCopy),
                              });
                            }}
                            deletable={
                              (info?.status === "pending" ||
                                info?.status === "request action") &&
                              isInitiator
                            }
                            card={true}
                            document={v}
                            mimeType={getFileMime(v)}
                          />
                        ))}
                      </div>
                    </div>
                  ) : null}
                  {destination?.length ? (
                  <div className="mb-3">
                      <Typography
                        style={{ fontFamily: "Inter" }}
                        className="mb-3 fw-bold me-3 text-center"
                      >
                        Destination(s)
                      </Typography>
                      <MissionDestination
                        editable={false}
                        setDestination={setDestination}
                        destination={destination}
                        allDistrict={allDistrict}
                      />
                    </div>
                  ) : null}
                  <div className="mb-3">
                  <Typography
                        style={{ fontFamily: "Inter" }}
                        className="mb-3 fw-bold me-3 text-center"
                      >
                        Work Plan(s)
                      </Typography>
                    <WorkPlan
                      setPlan={setPlan}
                      plan={plan}
                      planInfo={info?.MissionPlans}
                      onSuccess={() => {
                        fetchMissionDetails().then();
                      }}
                    />
                  </div>
                  <div className="row mb-4">
                    <Reviewers
                      loggedUser={loggedUser}
                      RequestApprovers={info?.approvers}
                    />
                  </div>

                  <hr />
                  <div className="row">
                    <Typography
                      style={{ fontFamily: "Inter" }}
                      className="col-md-12 mb-3 fw-bold"
                    >
                      Request History
                    </Typography>
                    <div className="col-md-12">
                      <RequestHistory
                        RequestProcesses={info?.RequestProcesses}
                      />
                    </div>
                  </div>
                </>
              )}
            </CardContent>
          </Card>
        </Col>
      </Row>
      {openDialogue ? (
        <SmallDialog
          maxWidth="md"
          title={
            newData?.report
              ? "Add Report"
              : newData?.reported === "yes"
              ? "Generate Report"
              : newData?.title
          }
          buttonTitle="Submit"
          needButton={true}
          open={openDialogue}
          setOpen={setOpenDialogue}
        >
          {error ? (
            <Alert
              style={{ width: "100%" }}
              className={"mb-3"}
              severity={"error"}
              onClose={() => setError(null)}
            >
              {error}
            </Alert>
          ) : null}
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              setConfirm(true);
            }}
          >
            {newData?.report ? (
              <div>
                <label className="col-form-label text-capitalize">
                  Other Fees(RWF)
                </label>
                <input
                  className="form-control mb-2"
                  type="number"
                  value={newData.otherFee}
                  onChange={(e) => {
                    setData({
                      ...newData,
                      otherFee: e.currentTarget.value,
                    });
                  }}
                />
                {newData.otherFee ? (
                  <>
                    <label className="col-form-label text-capitalize">
                      Explain RWF of Other Fees
                    </label>
                    <textarea
                      placeholder="Type here..."
                      className="form-control form-control mb-3"
                      required={true}
                      value={newData?.otherFeeDescription}
                      rows={5}
                      onChange={(e: any) => {
                        setData({
                          ...newData,
                          otherFeeDescription: e.currentTarget.value,
                        });
                      }}
                    />
                  </>
                ) : null}
              </div>
            ) : (
              <div>
                {newData?.status === "forwarded" && (
                  // <Select
                  //   required={true}
                  //   onChange={(v: any) => {
                  //     setData({
                  //       ...newData,
                  //       approver: v.id,
                  //     });
                  //   }}
                  //   options={info?.approvers
                  //     ?.filter((v: any) => v.approver !== loggedUser?.id)
                  //     ?.map((v: any) => ({
                  //       label: `${v.User?.username} - ${v.User?.Role?.label}`,
                  //       id: v.id,
                  //     }))}
                  // />
                  <div>
                    <Typography
                      style={{ fontFamily: "Inter" }}
                      className="fw-bold"
                    >
                      Forward To{" "}
                    </Typography>{" "}
                    <List>
                      {approver
                        ?.filter(
                          (notMe: any) => notMe?.approver !== loggedUser?.id
                        )
                        ?.map((appr: any) => (
                          <ListItem
                            alignItems="flex-start"
                            key={appr.id}
                            onClick={() => {
                              setData({
                                ...newData,
                                approver: appr?.approver,
                              });
                            }}
                          >
                            <ListItemAvatar>
                              <Checkbox
                                required={!newData?.approver}
                                checked={appr?.approver === newData?.approver}
                              />{" "}
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${
                                appr?.User?.Employee?.firstName ?? ""
                              } ${appr?.User?.Employee?.lastName ?? ""}`}
                              secondary={
                                appr?.User?.Employee?.position?.name ?? ""
                              }
                            />
                          </ListItem>
                        ))}
                    </List>
                  </div>
                )}
                {newData?.reported === "yes" ? (
                  <Alert severity="warning">
                    <AlertTitle>Warning</AlertTitle>
                    <strong>
                      {
                        "Before generating a mission report,Please make sure you have marked the completed tasks in work plan table"
                      }
                    </strong>
                  </Alert>
                ) : null}
                <label className="col-form-label text-capitalize">
                  Comment
                </label>
                <textarea
                  placeholder="Type here..."
                  className="form-control form-control mb-3"
                  required={true}
                  value={newData?.comment}
                  rows={5}
                  onChange={(e: any) => {
                    setData({
                      ...newData,
                      comment: e.currentTarget.value,
                    });
                  }}
                />
              </div>
            )}
            <div className={"float-end"}>
              <button
                type={"button"}
                onClick={() => {
                  setOpenDialogue(false);
                }}
                className={"btn btn-secondary me-1"}
              >
                <i className={"bi bi-x-lg"}></i> Cancel
              </button>
              {loading ? (
                <CircularProgress />
              ) : (
                <button type={"submit"} className={"btn btn-primary"}>
                  <i className={"bi bi-send-fill"}></i> Submit
                </button>
              )}
            </div>
          </form>
        </SmallDialog>
      ) : null}
      {openDialogue ? (
        <SmallDialog
          open={confirm}
          setOpen={setConfirm}
          onSubmit={() => {
            onSubmit(newData);
          }}
        >
          <div className="d-flex ">
            <CheckCircleIcon color="success" sx={{ fontSize: 50 }} />
            <div className="mt-4">{"Are you sure you want to submit?"}</div>
          </div>
        </SmallDialog>
      ) : null}
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(MissionDetails);
