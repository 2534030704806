import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { Col, Row } from "react-bootstrap";
import { FormDialog } from "../../../components/FormDialog";
import React, { useEffect } from "react";
import { fetchDetails } from "../../../services/common";
import moment from "moment";
import { AddForm } from "../../employee/AddForm";

const CreateHoliday = () => {
  const navigate = useNavigate();
  const [info, setInfo] = React.useState<any>();
  const { id } = useParams();

  const fetchInfoDetails = async (id: string) => {
    const resp = await fetchDetails(`holiday/view/${id}`);
    if (resp.status) {
      setInfo(resp.data);
    }
  };

  useEffect(() => {
    if (id) {
      fetchInfoDetails(id).then();
    }
  }, [id]);
  return (
    <div>
      <Breadcrumb />
      <Row className={"justify-content-center"}>
        <Col md={5}>
          <AddForm
            inline={true}
            mainFields={[
              {
                title: "",
                fields: [
                  {
                    label: "Name",
                    name: "name",
                    type: "name",
                    value: info?.name,
                    required: true,
                  },
                  {
                    label: "Date",
                    name: "date",
                    type: `${id ? "text" : "date"}`,
                    value: moment(info?.date).format("yyyy-MM-DD"),
                    required: true,
                  },
                ],
              },
            ]}
            url={id ? `holiday/update/${id}` : "holiday/create"}
            method={id ? "PUT" : "POST"}
            title={id ? "Update Holiday" : "Create New Holiday"}
            onSuccess={() => navigate("../")}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CreateHoliday;
