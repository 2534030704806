import Breadcrumb from "../../../components/Breadcrumb";
import { AddForm } from "../../employee/AddForm";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import {
  fetchDetails,
  getFileMime,
  validateFileNotNull,
} from "../../../services/common";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/helpers/mapState";
import { mapDispatchToProps } from "../../../store/helpers/mapDispatch";
import FileUpload from "../../../components/FileUploader";
import {
  Button,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from "@mui/material";
import OpenDocuments from "../../../components/openDocument";
import RequestHistoryProcess from "../../../components/RequestHistory";
import Reviewers from "../../../components/Reviewer";
import GeneralButton from "../../../components/Button";
import { BorderColorOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import StatusComponent from "../../../components/StatusComponent";

const RequestSocialExit = (props: any) => {
  const { loggedUser } = props;
  const navigate = useNavigate();
  const [info, setInfo] = useState<any>();
  const [supportingDocument, setSupportingDocument] = useState<any>([]);
  const [viewDocument, setViewDocument] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [document, setDocument] = useState<any>();

  const id = info?.id;

  const loadInfo = async () => {
    const resp = await fetchDetails(
      `caise/view-member/${loggedUser?.employeeId}`
    );
    if (resp.status) {
      setInfo(resp.data?.ExitRequest);
      setEditMode(true);
    }
  };

  useEffect(() => {
    loadInfo().then();
  }, []);
  const canUpdate =
    info?.status === "pending" || info?.status === "request action";
  return (
    <div>
      <Breadcrumb />
      <Row className={"bg-white p-3"}>
        <Col md={12}>
          {editMode && info?.id ? (
            <Card className={"bg-light shadow-sm"}>
              <CardHeader
                subheader={<span className="fw-bold">Request Details</span>}
                action={<StatusComponent status={info?.status} badge={true} />}
              />
              <CardContent>
                {canUpdate ? (
                  <div className={"row justify-content-end"}>
                    <div className="col-md-8 fw-bold d-flex justify-content-end">
                      <Button
                        className="btn-primary me-2"
                        size="small"
                        variant="outlined"
                        startIcon={<BorderColorOutlined />}
                        onClick={() => {
                          setEditMode(false);
                        }}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                ) : null}

                <Row className={"mb-3"}>
                  <Col md={12}>
                    <div className={"mb-2"}>Reason : </div>
                    <div className={"fw-bold"}>
                      <Card>
                        <div className={"card-body"}>
                          <div
                            dangerouslySetInnerHTML={{ __html: info?.reason }}
                          ></div>
                        </div>
                      </Card>
                    </div>
                  </Col>
                </Row>
                <Row className={"mb-3"}>
                  {info?.supportingDocument ? (
                    <Col md={4}>
                      <div className=" mb-3">
                        <Typography style={{fontFamily:'Inter'}} className="mb-3 fw-bold me-3">
                          Supporting Document
                        </Typography>
                        <GeneralButton
                          variant="outlined"
                          icon={
                            viewDocument ? <VisibilityOff /> : <Visibility />
                          }
                          onClick={() => {
                            setViewDocument(!viewDocument);
                            setDocument(info?.supportingDocument);
                          }}
                          name={"View Document"}
                        />
                      </div>
                    </Col>
                  ) : null}
                  {info?.minutes ? (
                    <Col md={4}>
                      <div className=" mb-3">
                        <Typography style={{fontFamily:'Inter'}} className="mb-3 fw-bold me-3">
                          Minutes
                        </Typography>
                        <GeneralButton
                          variant="outlined"
                          icon={
                            viewDocument ? <VisibilityOff /> : <Visibility />
                          }
                          onClick={() => {
                            setViewDocument(!viewDocument);
                            setDocument(info?.minutes);
                          }}
                          name={"View Document"}
                        />
                      </div>
                    </Col>
                  ) : null}
                </Row>
                <Reviewers
                  loggedUser={loggedUser}
                  RequestApprovers={info?.RequestApprovers}
                />
                <hr />
                <div className="row">
                  <Typography style={{fontFamily:'Inter'}} className="col-md-12 mb-3 fw-bold">
                    Request History
                  </Typography>
                  <div className="col-md-12">
                    <RequestHistoryProcess
                      RequestProcesses={info?.RequestProcesses}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          ) : (
            <AddForm
              inline={true}
              title={`Request To Exit Caise d'entraide`}
              formAlert={
                <div>
                  <h5>GUSEZERA</h5>
                  <div className={"card-body"}>
                    <b>Ingingo ya 12: Gusezera k‘umunyamuryango</b>
                    <p>
                      Umunyamuryango ushaka gusezera muri “CAISSE D’ENTRAIDE”
                      yandikira Inama ya Komite asezera. Ikamusubiza mu gihe
                      kitarenze iminsi 30 ibonye ibaruwa, ikazabimenyesha inama
                      Nkuru mu nama yayo ikurikira. Iyo icyo gihe kirenze
                      uwasabye gusezera adasubijwe bifatwa nk’aho gusezera kwe
                      kwemewe.
                    </p>
                    <b>
                      Ingingo ya 14: Uburenganzira bw’umunyamuryango wirukanwe
                      cyangwa wasezeye
                    </b>
                    <p>
                      Umunyamuryango usezeye cyangwa wirukanywe afite
                      uburenganzira bwo gusubizwa mu gihe kitarenze amezi atatu
                      (3) imigabane, ubwizigame n’inyungu ye havuyemo igihombo
                      cyabonetse mu mari shingiro, imyenda afitiye “CAISSE
                      D’ENTRAIDE”, kimwe n’uruhare rwe mu myenda n’inguzanyo
                      byafashwe na “CAISSE D’ENTRAIDE” atarayivamo.
                    </p>
                  </div>
                </div>
              }
              mainFields={[
                {
                  title: "",
                  fields: [
                    {
                      label: "Reason",
                      name: "reason",
                      type: "editor",
                      value: info?.reason,
                      required: true,
                    },
                    {
                      label: "Attachment(Exit letter)",
                      name: "supportingDocument",
                      type: "file",
                      multiple: true,
                      col: 12,
                      required: !info?.id,
                      value:supportingDocument,
                      validate: validateFileNotNull,
                      render: () => {
                        return (
                          <FileUpload
                            setFiles={setSupportingDocument}
                            files={supportingDocument}
                            required={!info?.supportingDocument}
                            title="Upload"
                            currentFile={info?.supportingDocument}
                            accept={"application/pdf,image/*"}
                          />
                        );
                      },
                    },
                    {
                      label: "Add your comment",
                      name: "comment",
                      type: "textarea",
                      hidden: info?.status !== "request action",
                      value: info?.comment,
                      required: true,
                    },
                  ],
                },
              ]}
              url={id ? `/exit/update/${id}` : `/exit/request`}
              method={id ? "PUT" : "POST"}
              onSuccess={() => {
                loadInfo().then();
                // setEditMode(false);
              }}
            />
          )}

          {/* ========================supporting document============= */}
          {viewDocument ? (
            <Dialog
              fullWidth={true}
              maxWidth={"lg"}
              open={viewDocument}
              onClose={() => setViewDocument(false)}
            >
              <DialogTitle>{"Attachment(Exit letter)"}</DialogTitle>
              <DialogContent>
                <div className={"row justify-content-center"}>
                  <div className={"col-md-8"}>
                    {info?.supportingDocument?.map((v: string) => (
                      <OpenDocuments
                        downloadable={true}
                        card={true}
                        document={v}
                        mimeType={getFileMime(v)}
                      />
                    ))}
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestSocialExit);
