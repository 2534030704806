import { Close, HighlightOff } from "@mui/icons-material";
import { useRef, useState } from "react";
import { allowedTypes, getFileMime } from "../services/common";
import { Link } from "@mui/material";
import OpenDocuments from "../components/openDocument";
import PdfPreview from "./pdfView";

const FileUpload = (props: any) => {
  const { files, setFiles, required, title, accept, currentFile } = props;
  const [openDocument, setOpenDocument] = useState<any>(false);
  const [openCurrentFile, setOpenCurrentFile] = useState<any>(false);
  const [document, setDocument] = useState<any>();

  const checkCurrentFile =
    currentFile && typeof currentFile === "string"
      ? JSON.parse(currentFile)
      : currentFile;

  const inputRef: any = useRef();
  const handleClick = () => {
    inputRef.current.click();
  };
  return (
    <div className={"mb-3 "}>
      <div className="input-group mb-3 d-flex">
        <button className="btn btn-primary" type="button" onClick={handleClick}>
          {title ?? "Add New File"}
        </button>
        {checkCurrentFile?.length > 0 && files?.length <= 0 ? (
          <div className="border flex-grow-1 rounded-end-2 d-flex align-items-center">
            <div
              className="btn-group ms-2 p-1 bg-light"
              role="button"
              onClick={() => {
                setOpenCurrentFile(true);
              }}
            >
              <Link className="me-2 ">{"open file"}</Link>
            </div>
          </div>
        ) : (
          <div className="border flex-grow-1 rounded-end-2 d-flex align-items-center">
            {files?.length
              ? files?.map((file: any, index: any) => (
                  // <Link className="me-2 ">{"open file"}</Link>
                  <Link className="btn-group ms-2 p-1  me-1">
                    <span
                      className="me-2"
                      role="button"
                      onClick={() => {
                        setOpenDocument(true);
                        setDocument(file);
                      }}
                    >
                      {file?.name}
                    </span>
                    <HighlightOff
                      role="button"
                      color={"error"}
                      onClick={() => {
                        files?.splice(index, 1);
                        setFiles([...files]);
                      }}
                    />
                  </Link>
                ))
              : null}

            {required && files?.length <= 0 ? (
              <span className="text-danger ms-2">
                {"This field is required,Please upload at least one file."}
              </span>
            ) : null}
          </div>
        )}
      </div>

      <input
        type="file"
        accept={accept ?? allowedTypes}
        className="form-control"
        style={{ display: "none" }}
        ref={inputRef}
        required={required}
        onChange={(e: any) => {
          let newFiles = [...files];
          newFiles.push(e.currentTarget.files[0]);
          setFiles(newFiles);
        }}
      />

      {openDocument ? (
        <div className="row bg-light">
          {" "}
          <div className="d-flex justify-content-end col-md-12 mb-2">
            <Close
              color="error"
              className=" border"
              type="button"
              onClick={() => {
                setOpenDocument(false);
              }}
            />
          </div>
          {openDocument && document ? (
            <div className="col-md-12">
              <PdfPreview
                    mimeType={document?.type ?? "application/pdf"}
                    document={URL.createObjectURL(document)}
                  />
            </div>
          ) : null}
        </div>
      ) : null}

      {openCurrentFile ? (
        <div className={"row justify-content-center"}>
          <div className="d-flex justify-content-end col-md-12 mb-2">
            <Close
              color="error"
              className=" border"
              type="button"
              onClick={() => {
                setOpenCurrentFile(false);
              }}
            />
          </div>
          <div className="col-md-12">
            {checkCurrentFile?.map((v: string) => (
              <OpenDocuments
                downloadable={true}
                card={true}
                document={v}
                mimeType={getFileMime(v)}
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FileUpload;
