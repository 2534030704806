export const GET_USER = "GET_USER";
export const GET_CDE_ACTIVE_INFO = "GET_CDE_ACTIVE_INFO";
export const ERRORS = "ERRORS";
export const SET_SOCKET = "SET_SOCKET";


export interface IError {
  error: string[];
  status: number;
  statusText: string;
}
interface IUserDetails {
  type: typeof GET_USER;
  payload: {
    info: any;
  };
}

interface ISetSocket {
  type: typeof SET_SOCKET;
  payload: {
    socket: any;
  };
}

interface IErrors {
  type: typeof ERRORS;
  payload: {
    error: IError;
  };
}interface I_CDE_info {
  type: typeof GET_CDE_ACTIVE_INFO;
  payload: {
    error: IError;
  };
}
export type UserType =
  |IUserDetails|IErrors|ISetSocket | I_CDE_info;
