import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { Col, Row } from "react-bootstrap";
import { FormDialog } from "../../../components/FormDialog";
import React, { useEffect } from "react";
import { fetchDetails } from "../../../services/common";
import { AddForm } from "../../employee/AddForm";

const CreateLevel = () => {
  const navigate = useNavigate();
  const [info, setInfo] = React.useState<any>();
  const { id } = useParams();

  const fetchInfoDetails = async (id: string) => {
    const resp = await fetchDetails(`level/view/${id}`);
    if (resp.status) {
      setInfo(resp.data);
    }
  };

  useEffect(() => {
    if (id) {
      fetchInfoDetails(id).then();
    }
  }, [id]);
  return (
    <div>
      <Breadcrumb />
      <Row className={"justify-content-center"}>
        <Col md={5}>
          <AddForm
            inline={true}
            mainFields={[
              {
                title: "",
                fields: [
              {
                label: "Order",
                name: "order",
                type: "number",
                value: info?.order,
                required: true,
                min:0
              },
              {
                label: "Name",
                name: "name",
                // type: "name",
                value: info?.name,
                required: true,
              },
            ]}]}
            url={id ? `level/update/${id}` : "level/create"}
            method={id ? "PUT" : "POST"}
            title={id ? "Update Level" : "Create New Level"}
            onSuccess={() => navigate(-1)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CreateLevel;
