import { Col, Row } from "react-bootstrap";
import Breadcrumb from "../../../components/Breadcrumb";
import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Snackbar,
  Typography,
  Checkbox,

} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchDetails,
  getError,
  getFileMime,
  getSuccessMessage,
  stringAvatar,
  submitGeneralForm,
} from "../../../services/common";
import { useEffect, useState } from "react";
import {
  Check,
  Delete,
  Download,
  BorderColorOutlined,
  Forward,
  HighlightOff,
  Info,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import SmallDialog from "../../../components/SmallModel";
import DeleteDialog from "../../../components/DeleteDialog";
import { mapStateToProps } from "../../../store/helpers/mapState";
import { mapDispatchToProps } from "../../../store/helpers/mapDispatch";
import { connect } from "react-redux";
import RequestHistoryProcess from "../../../components/RequestHistory";
import OpenDocuments from "../../../components/openDocument";
import Approver from "../../../components/Approver";
import GeneralButton from "../../../components/Button";
import Reviewers from "../../../components/Reviewer";
import { LinearProgress } from "@material-ui/core";

const TransferDetails = (props: any) => {
  const { loggedUser } = props;
  const navigate = useNavigate();
  const { id }: any = useParams(); //transfer id
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const [openSnack, setOpenSnack] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [viewDocument, setViewDocument] = useState(false);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState<any>();
  const [newData, setData] = useState<any>();
  const [viewFinal, setViewFinal] = useState(false);
  const [openDialogue, setOpenDialogue] = useState<any>(false);
  const [deleteOpen, setDeleteOpen] = useState(0);
  const [deleteActionUrl, setDeleteActionUrl] = useState<
    string | undefined | null
  >();

  const isInitiator = loggedUser?.id === info?.requester; //the transfer requestor
  const isApprover = loggedUser?.id === info?.approver; //the transfer approver
  const canUpdate =
    isInitiator &&
    (info?.status === "pending" || info?.status === "request action");

  let approver =
    info?.RequestApprovers?.filter(
      (approver: any) => approver?.category === "approver"
    ) ?? []; //the transfer approvers
  approver = approver?.sort((a: any, b: any) => a.index - b.index);

  const reviewer =
    info?.RequestApprovers?.filter(
      (approver: any) => approver?.category === "reviewer"
    ) ?? []; //the transfer reviewers

  const lastApprover =
    approver[approver.length - 1]?.approver === info?.approver;
  const fetchTransferDetails = async () => {
    const resp = await fetchDetails(`transfer/view/${id}`);
    if (resp.status) {
      setViewFinal(false);
      setInfo({ ...resp.data, reviewer: [] });
    }
  };
  useEffect(() => {
    if (id) {
      //fetch transfer details
      fetchTransferDetails().then();
    }
  }, [id]);

  useEffect(() => {
    if (isApprover && info?.status === "pending") {
      onSubmit({
        id: info?.id,
        status: "under review",
        comment: "request is under review",
      });
    }
  }, [info?.id, info?.status, isApprover]);

  const onSubmit = async (data?: any) => {
    setLoading(true);
    setConfirm(false);
    let infoToSave = data ?? newData;
    const resp = await submitGeneralForm(
      `/transfer/change-status/${info?.id}`,
      infoToSave,
      null,
      "PUT"
    );
    if (resp.status) {
      setData({});
      fetchTransferDetails().then();
      setOpenDialogue(false);
      setSuccess(getSuccessMessage(resp));
      setError(null);
      setLoading(false);
    } else {
      setError(getError(resp));
      setSuccess(null);
      setLoading(false);
    }
    setOpenSnack(true);
  };

  return (
    <div>
      <Breadcrumb />
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={() => {
          setOpenSnack(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {error ? (
          <Alert
            style={{ width: "100%" }}
            className={"mb-3"}
            severity={"error"}
            onClose={() => setError(null)}
          >
            {error}
          </Alert>
        ) : success ? (
          <Alert
            style={{ width: "100%" }}
            className={"mb-3"}
            severity={"success"}
            onClose={() => setSuccess(null)}
          >
            {success}
          </Alert>
        ) : (
          <></>
        )}
      </Snackbar>

      <Row>
        <Col md={12}>
          {!info?.id ? 
            <LinearProgress />
           : null}
            <Card className={"bg- shadow-sm p-3"}>
              <CardHeader
                subheader={
                  <span className="fw-bold">{"Mutation Details"}</span>
                }
                action={
                  <span
                    className={
                      info?.status === "pending"
                        ? "badge bg-primary rounded-pill"
                        : info?.status === "rejected"
                        ? "badge bg-danger rounded-pill"
                        : info?.status === "request action"
                        ? "badge bg-warning rounded-pill"
                        : info?.status === "forwarded"
                        ? "badge bg-info rounded-pill"
                        : info?.status === "under review"
                        ? "badge bg-secondary rounded-pill"
                        : "badge bg-success rounded-pill"
                    }
                  >
                    {info?.status}
                  </span>
                }
              />
              <CardContent>
                <div className="row mb-3 justify-content-end">
                  {info?.status !== "approved" &&
                  info?.status !== "rejected" ? (
                    <div className="col-md-8 fw-bold d-flex justify-content-end">
                      {/* {isInitiator && info?.status === "pending" ? (
                      <Button
                        className="me-2"
                        size="small"
                        color="error"
                        variant="outlined"
                        startIcon={<Delete />}
                        onClick={() => {
                          setDeleteOpen(1);
                          setDeleteActionUrl(`/transfer/delete/${info?.id}`);
                        }}
                      >
                        Delete
                      </Button>
                    ) : null} */}
                      {canUpdate ? (
                        <Button
                          className="btn-primary me-2"
                          size="small"
                          variant="outlined"
                          startIcon={<BorderColorOutlined />}
                          onClick={() => {
                            navigate(`../transfer/${info?.id}`);
                          }}
                        >
                          Update
                        </Button>
                      ) : null}
                      {isApprover &&
                      (info?.status === "under review" ||
                        info?.status === "forwarded") ? (
                        <>
                          <Button
                            className="me-1 text-nowrap"
                            color="secondary"
                            size="small"
                            variant="outlined"
                            startIcon={<Info />}
                            onClick={() => {
                              setOpenDialogue(true);
                              setData({
                                id: info?.id,
                                status: "request action",
                              });
                            }}
                          >
                            Request for action
                          </Button>
                          {approver?.length > 1 ? (
                            <Button
                              className="me-2 text-nowrap"
                              color="info"
                              size="small"
                              variant="outlined"
                              startIcon={<Forward />}
                              onClick={() => {
                                setOpenDialogue(true);
                                setData({ id: info?.id, status: "forwarded" });
                              }}
                            >
                              Review & Forward
                            </Button>
                          ) : null}
                          <Button
                            className="me-2 text-nowrap"
                            color="error"
                            size="small"
                            variant="outlined"
                            startIcon={<HighlightOff />}
                            onClick={() => {
                              setOpenDialogue(true);
                              setData({ id: info?.id, status: "rejected" });
                            }}
                          >
                            Reject
                          </Button>
                          <Button
                            className="me-2 text-nowrap"
                            disabled={!lastApprover}
                            color="success"
                            size="small"
                            variant="outlined"
                            startIcon={<Check />}
                            onClick={() => {
                              setOpenDialogue(true);
                              setData({ id: info?.id, status: "approved" });
                            }}
                          >
                            Approve
                          </Button>
                        </>
                      ) : null}
                    </div>
                  ) : null}

                  {info?.status === "approved" ? (
                    <div className="col-md-8 fw-bold d-flex justify-content-end">
                      {/* <Button
                      className="btn-primary me-2"
                      size="small"
                      variant="outlined"
                      startIcon={viewFinal ? <VisibilityOff /> : <Visibility />}
                      onClick={() => {
                        setViewFinal(!viewFinal);
                      }}
                    >
                      
                    </Button> */}
                      <GeneralButton
                        variant="outlined"
                        icon={viewFinal ? <VisibilityOff /> : <Visibility />}
                        onClick={() => {
                          setViewFinal(!viewFinal);
                        }}
                        name={"Approved Document"}
                      />
                    </div>
                  ) : null}
                </div>
                {viewFinal ? (
                  <div>
                    {info?.finalDocument && viewFinal ? (
                      <div className={"row justify-content-center"}>
                        <div className={"col-md-8"}>
                          {info?.finalDocument?.map((v: string) => (
                            <OpenDocuments
                              downloadable={true}
                              card={true}
                              document={v}
                              mimeType={getFileMime(v)}
                            />
                          ))}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <>
                    <div className="row mb-4">
                      <div className="col-4">
                        <div className="fw-bold mb-2">First Name</div>
                        <div>{info?.Employee?.firstName ?? "---"}</div>
                      </div>
                      <div className="col-4">
                        <div className="fw-bold mb-2">Last Name</div>
                        <div>{info?.Employee?.lastName ?? "---"}</div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <Typography
                        style={{ fontFamily: "Inter" }}
                        className="col-md-12 mb-3 fw-bold"
                      >
                        Transferred From
                      </Typography>

                      <div className="col-4">
                        <div className="fw-bold mb-2">Position</div>
                        <div>{info?.fromPosition?.name ?? "---"}</div>
                      </div>
                      <div className="col-4">
                        <div className="fw-bold mb-2">Department</div>
                        <div>{info?.fromDepartment?.name ?? "---"}</div>
                      </div>
                      <div className="col-4">
                        <div className="fw-bold mb-2">Branch</div>
                        <div>{info?.fromBranch?.name ?? "---"}</div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <Typography
                        style={{ fontFamily: "Inter" }}
                        className="col-md-12 mb-3 fw-bold"
                      >
                        Transferred To
                      </Typography>

                      <div className="col-4">
                        <div className="fw-bold mb-2">Position</div>
                        <div>{info?.fromPosition?.name ?? "---"}</div>
                      </div>
                      <div className="col-4">
                        <div className="fw-bold mb-2">Department</div>
                        <div>{info?.toDepartment?.name ?? "---"}</div>
                      </div>
                      <div className="col-4">
                        <div className="fw-bold mb-2">Branch</div>
                        <div>{info?.toBranch?.name ?? "---"}</div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <Typography
                        style={{ fontFamily: "Inter" }}
                        className="col-md-12 mb-3 fw-bold"
                      >
                        Reason
                      </Typography>
                      <div className="col-10">
                        <div>{info?.reason ?? "---"}</div>
                      </div>
                    </div>
                    {info?.supportingDocument ? (
                      <div className="row mb-4">
                        <div className="col-md-12">
                          <div className="input-group mb-3">
                            <Typography
                              style={{ fontFamily: "Inter" }}
                              className="mb-3 fw-bold me-3"
                            >
                              Supporting Document
                            </Typography>
                            <GeneralButton
                              variant="outlined"
                              icon={
                                viewDocument ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )
                              }
                              onClick={() => {
                                setViewDocument(!viewDocument);
                              }}
                              name={"View Document"}
                            />
                            {/* <Button
                        className="me-2"
                        color="info"
                        size="small"
                        variant="contained"
                        startIcon={
                          viewDocument ? <VisibilityOff /> : <Visibility />
                        }
                        onClick={() => {
                          setViewDocument(!viewDocument);
                        }}
                      >
                        View Document
                      </Button> */}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {info?.supportingDocument && viewDocument ? (
                      <div className={"row justify-content-center"}>
                        <div className={"col-md-8"}>
                          {info?.supportingDocument?.map((v: string) => (
                            <OpenDocuments
                              downloadable={true}
                              onDelete={() => {
                                let documentCopy = [
                                  ...info?.supportingDocument,
                                ];
                                const index = documentCopy.indexOf(v);
                                documentCopy.splice(index, 1);
                                onSubmit({
                                  id: info.id,
                                  supportingDocument:
                                    JSON.stringify(documentCopy),
                                });
                              }}
                              deletable={
                                (info?.status === "pending" ||
                                  info?.status === "request action") &&
                                isInitiator
                              }
                              card={true}
                              document={v}
                              mimeType={getFileMime(v)}
                            />
                          ))}
                        </div>
                      </div>
                    ) : null}
                    {/* <div className="row mb-4">
                    <Typography className="col-md-12 mb-3 fw-bold">
                      Approvers Section
                    </Typography>

                    {approver?.map((appr: any) => (
                      <div className="col-md-3">
                        <List>
                          <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                              {loggedUser?.id === appr?.approver ? (
                                <Avatar>Me</Avatar>
                              ) : (
                                <Avatar
                                  {...stringAvatar(
                                    `${appr?.User?.Employee?.firstName ?? ""} ${
                                      appr?.User?.Employee?.lastName ?? ""
                                    }`
                                  )}
                                />
                              )}
                            </ListItemAvatar>
                            <Badge
                              badgeContent={
                                appr?.status === "active" ? "active" : null
                              }
                              color={
                                appr?.status === "active"
                                  ? "success"
                                  : "primary"
                              }
                            >
                              <ListItemText
                                primary={`${
                                  appr?.User?.Employee?.firstName ?? ""
                                } ${appr?.User?.Employee?.lastName ?? ""}`}
                                secondary={
                                  appr?.User?.Employee?.position?.name ?? ""
                                }
                              />
                            </Badge>
                          </ListItem>
                        </List>
                      </div>
                    ))}
                  </div>
                  <div className="row mb-4">
                    <Typography className="col-md-12 mb-3 fw-bold">
                      Reviewers Section
                    </Typography>

                    {reviewer?.map((appr: any) => (
                      <div className="col-md-3">
                        <List>
                          <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                              {loggedUser?.id === appr?.approver ? (
                                <Avatar>Me</Avatar>
                              ) : (
                                <Avatar
                                  {...stringAvatar(
                                    `${appr?.User?.Employee?.firstName ?? ""} ${
                                      appr?.User?.Employee?.lastName ?? ""
                                    }`
                                  )}
                                />
                              )}
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${
                                appr?.User?.Employee?.firstName ?? ""
                              } ${appr?.User?.Employee?.lastName ?? ""}`}
                              secondary={
                                appr?.User?.Employee?.position?.name ?? ""
                              }
                            />
                          </ListItem>
                        </List>
                      </div>
                    ))}
                  </div> */}
                    <Reviewers
                      loggedUser={loggedUser}
                      RequestApprovers={info?.RequestApprovers}
                    />
                    <hr />
                    <div className="row">
                      <Typography
                        style={{ fontFamily: "Inter" }}
                        className="col-md-12 mb-3 fw-bold"
                      >
                        Request History
                      </Typography>
                      <div className="col-md-12">
                        <RequestHistoryProcess
                          RequestProcesses={info?.RequestProcesses}
                        />
                      </div>
                    </div>
                  </>
                )}
              </CardContent>
            </Card>
          
        </Col>
      </Row>
      {openDialogue ? (
        <SmallDialog
          maxWidth="md"
          title=""
          buttonTitle="Submit"
          needButton={true}
          open={openDialogue}
          setOpen={setOpenDialogue}
        >
          {error ? (
            <Alert
              style={{ width: "100%" }}
              className={"mb-3"}
              severity={"error"}
              onClose={() => setError(null)}
            >
              {error}
            </Alert>
          ) : null}
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              setConfirm(true);
            }}
          >
            {newData?.status === "approved" ? (
              <>
                <label className="col-form-label text-capitalize">
                  Articles
                </label>
                <input
                  className="form-control"
                  required={true}
                  onChange={(e: any) => {
                    setData({
                      ...newData,
                      articles: e.currentTarget.value,
                    });
                  }}
                />
                <label className="col-form-label text-capitalize">Laws</label>
                <input
                  className="form-control"
                  required={true}
                  onChange={(e: any) => {
                    setData({
                      ...newData,
                      laws: e.currentTarget.value,
                    });
                  }}
                />
              </>
            ) : null}
            <label className="col-form-label text-capitalize">comment</label>
            <textarea
              placeholder="Type here..."
              className="form-control form-control mb-3"
              required={true}
              value={newData?.comment}
              rows={5}
              onChange={(e: any) => {
                setData({
                  ...newData,
                  comment: e.currentTarget.value,
                });
              }}
            />
            {newData?.status === "forwarded" ||
            newData?.status === "approved" ? (
              <div>
                {newData?.status === "forwarded" ? (
                  <div>
                    <Typography
                      style={{ fontFamily: "Inter" }}
                      className="fw-bold"
                    >
                      Forward To{" "}
                    </Typography>{" "}
                    <List>
                      {approver
                        ?.filter(
                          (notMe: any) => notMe?.approver !== loggedUser?.id
                        )
                        ?.map((appr: any) => (
                          <ListItem
                            alignItems="flex-start"
                            key={appr.id}
                            onClick={() => {
                              setData({
                                ...newData,
                                approver: appr?.User?.id,
                              });
                            }}
                          >
                            <ListItemAvatar>
                              <Checkbox
                                required={!newData?.approver}
                                checked={appr?.User?.id === newData?.approver}
                              />{" "}
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${
                                appr?.User?.Employee?.firstName ?? ""
                              } ${appr?.User?.Employee?.lastName ?? ""}`}
                              secondary={
                                appr?.User?.Employee?.position?.name ?? ""
                              }
                            />
                          </ListItem>
                        ))}
                    </List>
                  </div>
                ) : (
                  <div>
                    <Approver
                      title={"Copy Transfer To"}
                      buttonName={"Add "}
                      onSuccess={(val: any) => {
                        const reviewer = info.reviewer;
                        reviewer.push({
                          approver: val?.user?.id,
                          category: "reviewer",
                          transferId: info?.id,
                        });
                        setData({
                          ...newData,
                          reviewer,
                        });
                      }}
                      allBranch={true}
                    />
                  </div>
                )}
              </div>
            ) : null}
            <div className={"float-end"}>
              <button
                type={"button"}
                onClick={() => {
                  setOpenDialogue(false);
                }}
                className={"btn btn-secondary me-1"}
              >
                <i className={"bi bi-x-lg"}></i> Cancel
              </button>
              {loading ? (
                <CircularProgress />
              ) : (
                <button type={"submit"} className={"btn btn-primary"}>
                  <i className={"bi bi-send-fill"}></i> Submit
                </button>
              )}
            </div>
          </form>
        </SmallDialog>
      ) : null}
      {openDialogue ? (
        <SmallDialog
          open={confirm}
          setOpen={setConfirm}
          onSubmit={() => {
            onSubmit(newData);
          }}
        >
          <div className="d-flex ">
            <CheckCircleIcon color="success" sx={{ fontSize: 50 }} />
            <div className="mt-4">{"Are you sure you want to submit?"}</div>
          </div>
        </SmallDialog>
      ) : null}
      {deleteActionUrl ? (
        <DeleteDialog
          open={deleteOpen}
          onSuccess={() => {
            navigate(-1);
          }}
          setOpen={setDeleteOpen}
          url={deleteActionUrl!}
        />
      ) : null}
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(TransferDetails);
