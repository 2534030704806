import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";

const LoanCollateral = (props: any) => {
  const { info, approver } = props;
  return (
    <>
      {info?.collateralType === "collateral" ? (
        <Row className={"mb-3"}>
          <Col md={4} className={"mb-3"}>
            <div>Type</div>
            <div className={"fw-bold"}>
              {`${info?.collateralType} ` ?? "---"}
            </div>
          </Col>
          <Col md={4}>
            <div>UPI</div>
            <div className={"fw-bold"}>{`${info?.landUpi} ` ?? "---"}</div>
          </Col>
          <Col md={4}>
            <div>Land Owners</div>
            <div className={"fw-bold"}>{`${info?.landUpiOwner} ` ?? "---"}</div>
          </Col>
          <Col md={4}>
            <div>Land Usage</div>
            <div className={"fw-bold"}>{`${info?.landUsage} ` ?? "---"}</div>
          </Col>
          <Col md={4}>
            <div>Land Address</div>
            <div className={"fw-bold"}>
              {`${info?.landUpiAddress} ` ?? "---"}
            </div>
          </Col>
        </Row>
      ) : null}
      
      
        {info?.collateralType === "cdeMember" ?  <Row>
          <Col md={12}>
            <TableContainer className="border">
              <Table>
                <TableHead>
                  <TableRow className="fw-bold">
                    <TableCell className="fw-bold">Names</TableCell>
                    <TableCell className="fw-bold">Email</TableCell>
                    <TableCell className="fw-bold">Phone No</TableCell>
                    <TableCell className="fw-bold">Comment</TableCell>
                    <TableCell className="fw-bold">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {info?.LoanInsurer?.CaisseMember?.employee?.names}
                    </TableCell>
                    <TableCell>
                      {info?.LoanInsurer?.CaisseMember?.employee?.email}
                    </TableCell>
                    <TableCell>
                      {info?.LoanInsurer?.CaisseMember?.employee?.phone}
                    </TableCell>
                    <TableCell>{info?.LoanInsurer?.comment}</TableCell>
                    <TableCell
                      className={`${
                        info?.LoanInsurer?.status === "pending"
                          ? "text-warning text-capitalize"
                          : info?.LoanInsurer?.status === "denied"
                          ? "text-danger text-capitalize"
                          : "text-success text-capitalize"
                      } fw-bold`}
                    >
                      {info?.LoanInsurer?.status
                        ? info?.LoanInsurer?.status
                        : "pending"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Col>
        </Row>:null
      }
       {info?.collateralType === "other" ?  <Row>
       <Col md={12}>
            <div className={"fw-bold mb-2"}>Collateral</div>
            <div dangerouslySetInnerHTML={{ __html: info?.otherCollateral }} />
          </Col>
        </Row>:null
      }
    </>
  );
};
export default LoanCollateral;
