import { useEffect, useState } from "react";
import moment from "moment/moment";
import {
  submitGeneralForm,
} from "../../../services/common";
import { DatatableComponent } from "../../../components/DatatableComponent";
import { default as ReactSelect } from "react-select";
import Breadcrumb from "../../../components/Breadcrumb";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { mapStateToProps } from "../../../store/helpers/mapState";
import { mapDispatchToProps } from "../../../store/helpers/mapDispatch";
import { connect } from "react-redux";

type IProps={
  loggedUser:any
}
const MyAttendanceList = (props:IProps) => {
  const {loggedUser}=props
  const [info, setInfo] = useState<any>();
  const [change, setChange] = useState<any>(0);
  // const [list, setList] = useState<Array<any>>([]);
  const statusList = [
    {
      label: "Absent",
      value: "a",
    },
    {
      label: "Late",
      value: "l",
    },
    {
      label: "Holiday",
      value: "h",
    },
    {
      label: "Leave",
      value: "v",
    },
    {
      label: "Present",
      value: "p",
    },
  ];
  useEffect(() => {
    if (!info) {
      let newDate = new Date();
      setInfo({
        ...info,
        month: newDate.getMonth(),
        year: newDate.getFullYear(),
        startDate: new Date(newDate.getFullYear(), newDate.getMonth(), 1),
        endDate: new Date(newDate.getFullYear(), newDate.getMonth(), 31),
      });
    }
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <Breadcrumb
        addButton={
          // CheckHasPermission("attendance", "create")
            {
                label: "Today's Attendance",
                onClick: () => navigate("create"),
              }
            // : undefined
        }
      />
      <Row className="mb-3 ">
    
      <Col md={4}>
            <label className="col-form-label text-capitalize">Start Date</label>
            <input
              className="form-control"
              value={info?.startDate}
              type="date"
              onChange={(e: any) => {
                setInfo({
                  ...info,
                  startDate:e.target.value,
                });
              }}
            />
          </Col>
          <Col md={4}>
            <label className="col-form-label text-capitalize">End Date</label>
            <input
              className="form-control"
              value={info?.endDate}
              type="date"
              onChange={(e: any) => {
                setInfo({
                  ...info,
                  endDate:e.target.value,
                });
              }}
            />
          </Col>
          <Col md={4}>
                <label className="col-form-label text-capitalize">Status</label>
                <ReactSelect
                  className="text-nowrap"
                  onChange={(v: any) => {
                    setInfo({
                      ...info,
                      status: v?.value,
                    });
                  }}
                  options={statusList}
                />
              </Col>
      </Row>
      <DatatableComponent
        title={
          <div className="mb-4">
            <span className="fw-bolder text-capitalize badge bg-secondary rounded me-2">
              {"Legends:"}
            </span>
            <span className="fw-bolder text-capitalize me-2">
              {"Present:"}
              <strong className="text-success ms-1">P</strong>
            </span>
            <span className="fw-bolder text-capitalize me-2">
              {"Late:"}
              <strong className="text-warning ms-1">L</strong>
            </span>
            <span className="fw-bolder text-capitalize me-2">
              {"Absent:"}
              <strong className="text-danger ms-1">A</strong>
            </span>
            <span className="fw-bolder text-capitalize me-2">
              {"Holiday:"}
              <strong className="text-info ms-1">H</strong>
            </span>
            <span className="fw-bolder text-capitalize me-2">
              {"Leave:"}
              <strong className="text-secondary ms-1">V</strong>
            </span>
          </div>
        }
        columns={[
          {
            label: "Date",
            name: "date",
            options: {
              customBodyRender: (v: any) => moment(v).format("YYYY-MM-DD"),
            },
          },
          {
            label: "Punch In",
            name: "checkInTime",
            options: {
              customBodyRender: (v: any) => v?moment(v).format("HH:mm:ss A"):null,
            },
          },
          {
            label: "Punch Out",
            name: "checkOutTime",
            options: {
              customBodyRender: (v: any) => v?moment(v).format("HH:mm:ss A"):null,
            },
          },
          // {
          //   label: "Production",
          //   name: "production",
          // },
          {
            label: "Status",
            name: "status",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (status: any) => (
                <span
                  className={
                    status === "a"
                      ? "text-danger text-uppercase fw-bolder"
                      : status === "l"
                      ? "text-warning text-uppercase fw-bolder"
                      : status === "h"
                      ? "text-info text-uppercase fw-bolder"
                      : status === "v"
                      ? "text-secondary text-uppercase fw-bolder"
                      : "text-success text-uppercase fw-bolder"
                  }
                >
                  {status}
                </span>
              ),
            },
          },
        ]}
        url={`/attendance/my-attendances?info=${JSON.stringify(info)}&change=${change}`}
        // processData={(data: any) => {
        //   setList(data.data);
        //   return data;
        // }}
      />
    </>
  );
};

// export default MyAttendanceList;
export default connect(mapStateToProps, mapDispatchToProps)(MyAttendanceList);
