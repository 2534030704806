import { Col, Row } from "react-bootstrap";
import Breadcrumb from "../../../components/Breadcrumb";
import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
 
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Snackbar,
  Typography,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchDetails,
  getError,
  getFileMime,
  getSuccessMessage,
  stringAvatar,
  submitGeneralForm,
} from "../../../services/common";
import React, { useEffect, useState } from "react";
import {
  Check,
  Close,
  Delete,
  Download,
  BorderColorOutlined,
  HighlightOff,
  Info,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import moment from "moment";
import { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import SmallDialog from "../../../components/SmallModel";
import DeleteDialog from "../../../components/DeleteDialog";
import { connect } from "react-redux";
import OpenDocuments from "../../../components/openDocument";
import RequestHistoryProcess from "../../../components/RequestHistory";
import GeneralButton from "../../../components/Button";
import FileUpload from "../../../components/FileUploader";
import { mapStateToProps } from "../../../store/helpers/mapState";

const FundRequestDetails = (props: any) => {
  const { loggedUser } = props;
  const navigate = useNavigate();
  const { id }: any = useParams(); //request id
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const [openSnack, setOpenSnack] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewDocument, setViewDocument] = useState(false);
  const [viewPaymentDocument, setViewPaymentDocument] = useState(false);
  const [viewFinal, setViewFinal] = useState(false);
  const [info, setInfo] = useState<any>();
  const [finalDocument, setFinalDocument] = useState<any>([]);
  const [newData, setData] = useState<any>();
  const [openDialogue, setOpenDialogue] = useState<any>(false);
  const [deleteOpen, setDeleteOpen] = useState(0);
  const [files, setFiles] = React.useState<any>([]);
  const [deleteActionUrl, setDeleteActionUrl] = useState<
    string | undefined | null
  >();
  const isInitiator = loggedUser?.id === info?.requester; //the requestor
  const isApprover = loggedUser?.id === info?.approver; //the approver
  const canUpdate =
    isInitiator &&
    (info?.status === "pending" || info?.status === "request action");

  let approver =
    info?.RequestApprovers?.filter(
      (approver: any) => approver?.category === "approver"
    ) ?? []; //the request approvers
  approver = approver?.sort((a: any, b: any) => a.index - b.index);

  const fetchFundRequestDetails = async () => {
    const resp = await fetchDetails(`social-fund/view/${id}`);
    if (resp.status) {
      setInfo(resp.data);
      setViewFinal(false);
    }
  };
  useEffect(() => {
    if (id) {
      //fetch service-request details
      fetchFundRequestDetails().then();
    }
  }, [id]);

  useEffect(() => {
    if (isApprover && info?.status === "pending") {
      onSubmit({
        id: info?.id,
        status: "under review",
        comment: "request is under review",
      });
    }
  }, [info?.id, info?.status, isApprover]);

  const onSubmit = async (data?: any) => {
    setLoading(true);
    setConfirm(false);
    let infoToSave = data ?? newData;

    const formData = new FormData();
    formData.append("body", JSON.stringify(infoToSave));

    if (finalDocument) {
      for (let i = 0; i < finalDocument.length; i++) {
        formData.append("finalDocument", finalDocument[i]);
      }
    }

    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append("paymentDocument", files[i]);
      }
    }
    const resp = await submitGeneralForm(
      `/social-fund/update/${info?.id}`,
      formData,
      null,
      "PUT"
    );
    if (resp.status) {
      setData({});
      fetchFundRequestDetails().then();
      setOpenDialogue(false);
      setLoading(false);
      setSuccess(getSuccessMessage(resp));
      setError(null);
    } else {
      setError(getError(resp));
      setSuccess(null);
      setLoading(false);
    }
    setOpenSnack(true);
  };
  return (
    <div>
      <Breadcrumb />
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={() => {
          setOpenSnack(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {error ? (
          <Alert
            style={{ width: "100%" }}
            className={"mb-3"}
            severity={"error"}
            onClose={() => setError(null)}
          >
            {error}
          </Alert>
        ) : success ? (
          <Alert
            style={{ width: "100%" }}
            className={"mb-3"}
            severity={"success"}
            onClose={() => setSuccess(null)}
          >
            {success}
          </Alert>
        ) : (
          <></>
        )}
      </Snackbar>

      <Row>
        <Col md={12}>
          <Card className={"bg- shadow-sm p-3"}>
            <CardHeader
              subheader={
                <span className="fw-bold text-capitalize">{`Request for Social Fund`}</span>
              }
              action={
                <span
                  className={
                    info?.status === "pending"
                      ? "badge bg-primary rounded-pill"
                      : info?.status === "rejected"
                      ? "badge bg-danger rounded-pill"
                      : info?.status === "request action"
                      ? "badge bg-warning rounded-pill"
                      : info?.status === "forwarded"
                      ? "badge bg-info rounded-pill"
                      : info?.status === "under review"
                      ? "badge bg-secondary rounded-pill"
                      : "badge bg-success rounded-pill"
                  }
                >
                  {info?.status}
                </span>
              }
            />
            <CardContent>
              <div className="row mb-3 justify-content-end">
                {info?.status !== "approved" && info?.status !== "rejected" ? (
                  <div className="col-md-8 fw-bold d-flex justify-content-end">
                    {canUpdate ? (
                      <Button
                        className="btn-primary me-2"
                        size="small"
                        variant="outlined"
                        startIcon={<BorderColorOutlined />}
                        onClick={() => {
                          navigate(`/console/saving/funds/request/${info?.id}`);
                        }}
                      >
                        Update
                      </Button>
                    ) : null}
                    {isApprover && info?.status === "under review" ? (
                      <>
                        <Button
                          className="me-2"
                          color="error"
                          size="small"
                          variant="outlined"
                          startIcon={<HighlightOff />}
                          onClick={() => {
                            setOpenDialogue(true);
                            setData({ id: info?.id, status: "rejected" });
                          }}
                        >
                          Reject
                        </Button>
                        <Button
                          className="me-1"
                          color="secondary"
                          size="small"
                          variant="outlined"
                          startIcon={<Info />}
                          onClick={() => {
                            setOpenDialogue(true);
                            setData({
                              id: info?.id,
                              status: "request action",
                            });
                          }}
                        >
                          Request for action
                        </Button>
                        <Button
                          className="me-2"
                          color="success"
                          size="small"
                          variant="outlined"
                          startIcon={<Check />}
                          onClick={() => {
                            setOpenDialogue(true);
                            setData({ id: info?.id, status: "approved" });
                          }}
                        >
                          Approve
                        </Button>
                      </>
                    ) : null}
                  </div>
                ) : null}

                {/* {info?.status === "approved" ? (
                  <div className="col-md-8 fw-bold d-flex justify-content-end">
                    <GeneralButton
                      variant="outlined"
                      icon={viewFinal ? <VisibilityOff /> : <Visibility />}
                      onClick={() => {
                        setViewFinal(!viewFinal);
                      }}
                      name={"Approved Document"}
                    />
                  </div>
                ) : null} */}
              </div>
              {viewFinal ? (
                <div>
                  {info?.finalDocument && viewFinal ? (
                    <div className={"row justify-content-center"}>
                      <div className={"col-md-8"}>
                        {info?.finalDocument?.map((v: string) => (
                          <OpenDocuments
                            downloadable={true}
                            card={true}
                            document={v}
                            mimeType={getFileMime(v)}
                          />
                        ))}
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                <>
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <div className="fw-bold mb-2">Type of fund</div>
                      <div>{info?.SocialFundType?.name}</div>
                    </div>
                    <div className="col-md-4">
                      <div className="fw-bold mb-2">Amount</div>
                      <div>
                        {info?.SocialFundType?.amount?.toLocaleString()}
                      </div>{" "}
                    </div>
                    <div className="col-md-4">
                      <div className="fw-bold mb-2">Requested Date</div>
                      <div>{moment(info?.createdAt).format("DD/MM/YYYY")}</div>
                    </div>

                  </div>
                  <div className="row mb-4">
                  <div className="col-md-4">
                      <div className="fw-bold mb-2">Requested By</div>
                      <div>
                        {`${info?.requestedBy?.Employee?.firstName ?? ""} ${
                          info?.requestedBy?.Employee?.lastName ?? ""
                        }`}
                      </div>
                    </div>
                  <div className="col-md-4">
                      <div className="fw-bold mb-2">Requested For</div>
                      <div>
                        {`${info?.CaisseMember?.employee?.firstName ?? ""} ${
                          info?.CaisseMember?.employee?.lastName ?? ""
                        }`}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-md-10">
                      <div className="fw-bold mb-2">Fund Description</div>
                      <div>{info?.SocialFundType?.description}</div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    {info?.supportingDocument ? (
                      <div className="col-md-4">
                        <div className="input-group mb-3">
                          <Typography style={{fontFamily:'inter'}} className="mb-3 fw-bold me-3">
                            {info?.SocialFundType?.docName ?? 'Support Document'}
                          </Typography>
                          <GeneralButton
                            variant="outlined"
                            icon={
                              viewDocument ? <VisibilityOff /> : <Visibility />
                            }
                            onClick={() => {
                              setViewDocument(!viewDocument);
                            }}
                            name={"View Document"}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {info?.supportingDocument && viewDocument ? (
                    <div className={"row justify-content-center"}>
                      <div className={"col-md-8"}>
                        {info.supportingDocument.map((v: string) => (
                          <OpenDocuments
                          downloadable={true}
                          onDelete={() => {
                            let documentCopy=[...info?.supportingDocument]
                            const index = documentCopy.indexOf(v);
                          documentCopy.splice(index, 1);
                            onSubmit({
                              id: info.id,
                              supportingDocument: JSON.stringify(documentCopy),
                            });
                          }}
                          deletable={(info?.status==='pending'||info?.status==='request action')&&isInitiator}
                          card={true}
                          document={v}
                          mimeType={getFileMime(v)}
                        />
                        ))}
                      </div>
                    </div>
                  ) : null}
                  <div className="row mb-4">
                    {info?.paymentDocument ? (
                      <div className="col-md-4">
                        <div className="input-group mb-3">
                          <Typography style={{fontFamily:'inter'}} className="mb-3 fw-bold me-3">
                            {'Payment Document'}
                          </Typography>
                          <GeneralButton
                            variant="outlined"
                            icon={
                              viewPaymentDocument ? <VisibilityOff /> : <Visibility />
                            }
                            onClick={() => {
                              setViewPaymentDocument(!viewPaymentDocument);
                            }}
                            name={"View Payment Document"}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {info?.paymentDocument && viewPaymentDocument ? (
                    <div className={"row justify-content-center"}>
                      <div className={"col-md-8"}>
                        {info.paymentDocument.map((v: string) => (
                          <OpenDocuments
                          downloadable={true}
                          onDelete={() => {
                            let documentCopy=[...info?.paymentDocument]
                            const index = documentCopy.indexOf(v);
                          documentCopy.splice(index, 1);
                            onSubmit({
                              id: info.id,
                              paymentDocument: JSON.stringify(documentCopy),
                            });
                          }}
                          deletable={(info?.status==='pending'||info?.status==='request action')&&isInitiator}
                          card={true}
                          document={v}
                          mimeType={getFileMime(v)}
                        />
                        ))}
                      </div>
                    </div>
                  ) : null}
                  <div className="row mb-4">
                    <Typography style={{fontFamily:'Inter'}} className="col-md-12 mb-3 fw-bold">
                      Approvers Section
                    </Typography>

                    {approver?.map((appr: any) => (
                      <div className="col-md-3">
                        <List>
                          <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                              {loggedUser?.id === appr?.approver ? (
                                <Avatar>Me</Avatar>
                              ) : (
                                <Avatar
                                  {...stringAvatar(
                                    `${appr?.User?.Employee?.firstName ?? ""} ${
                                      appr?.User?.Employee?.lastName ?? ""
                                    }`
                                  )}
                                />
                              )}
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${
                                appr?.User?.Employee?.firstName ?? ""
                              } ${appr?.User?.Employee?.lastName ?? ""}`}
                              secondary={
                                appr?.User?.Employee?.position?.name ?? ""
                              }
                            />
                          </ListItem>
                        </List>
                      </div>
                    ))}
                  </div>

                  <hr />
                  <div className="row">
                    <Typography style={{fontFamily:'Inter'}} className="col-md-12 mb-3 fw-bold">
                      Request History
                    </Typography>
                    <div className="col-md-12">
                      <RequestHistoryProcess
                        RequestProcesses={info?.RequestProcesses}
                      />
                    </div>
                  </div>
                </>
              )}
            </CardContent>
          </Card>
        </Col>
      </Row>
      {openDialogue ? (
        <SmallDialog
          maxWidth="md"
          title=""
          buttonTitle="Submit"
          needButton={true}
          open={openDialogue}
          setOpen={setOpenDialogue}
        >
          {error ? (
            <Alert
              style={{ width: "100%" }}
              className={"mb-3"}
              severity={"error"}
              onClose={() => setError(null)}
            >
              {error}
            </Alert>
          ) : null}
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              setConfirm(true);
            }}
          >
            <label className="col-form-label text-capitalize">comment</label>

            <textarea
              placeholder="Type here..."
              className="form-control form-control mb-3"
              required={true}
              value={newData?.comment}
              rows={5}
              onChange={(e: any) => {
                setData({
                  ...newData,
                  comment: e.currentTarget.value,
                });
              }}
            />


            {newData?.status === 'approved' && <>
              <label className="col-form-label text-capitalize">Payment Document</label>
              <FileUpload setFiles={setFiles} files={files}/>
            </>}

            <div className={"float-end"}>
              <button
                type={"button"}
                onClick={() => {
                  setOpenDialogue(false);
                }}
                className={"btn btn-secondary me-1"}
              >
                <i className={"bi bi-x-lg"}></i> Cancel
              </button>
              {loading ? (
                <CircularProgress />
              ) : (
                <button type={"submit"} className={"btn btn-primary"}>
                  <i className={"bi bi-send-fill"}></i> Submit
                </button>
              )}
            </div>
          </form>
        </SmallDialog>
      ) : null}
      {/* {openDialogue ? ( */}
      <SmallDialog
        open={confirm}
        setOpen={setConfirm}
        onSubmit={() => {
          onSubmit(newData);
        }}
      >
        <div className="d-flex ">
          <CheckCircleIcon color="success" sx={{ fontSize: 50 }} />
          <div className="mt-4">{"Are you sure you want to submit?"}</div>
        </div>
      </SmallDialog>
      {/* ) : null} */}
      {deleteActionUrl ? (
        <DeleteDialog
          open={deleteOpen}
          onSuccess={() => {
            navigate(`../`);
          }}
          setOpen={setDeleteOpen}
          url={deleteActionUrl!}
        />
      ) : null}
    </div>
  );
};
export default connect(mapStateToProps)(FundRequestDetails);
