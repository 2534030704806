import React from "react";
import { Col } from "react-bootstrap";
import bcrypt from "bcryptjs";
import { Alert, Card, CardContent, CircularProgress } from "@mui/material";
import {
  CheckHasPermission,
  getError,
  submitGeneralForm,
} from "../../services/common";
import { useNavigate } from "react-router-dom";
import { logout } from "../../services/MockAuthService";

const UserPassword = (props: any) => {
  const { setChangePassword, loggedUser,userId,isMyAccount } = props;
  const [info, setInfo] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [valid, setValid] = React.useState(true);
  const navigate = useNavigate();

  const checkPassword = () => {
    // validate password
    const isCurrentPassword =info?.currentPassword? bcrypt.compareSync(
      info?.currentPassword,
      loggedUser?.password
    ):false;
    if (!isCurrentPassword) {
      setError("wrong password");
      setValid(false);
    } else if (
      info?.newPassword  &&
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/.test(
        info?.newPassword
      )
    ) {
      setError("password must contain(upper/lower case, number & symbol)!");
      setValid(false);
    } else if (
      info?.newPassword &&
      info?.newPassword !== info?.confirmNewPassword
    ) {
      setError("Re-Enter your new password to confirm");
      setValid(false);
    } else {
      setError("");
      setValid(true);
    }
  };

  const onSubmit = async () => {
    const resp = await submitGeneralForm(
      `/user/change-password/${userId}`,
      info,
      null,
      "PUT"
    );
    if (resp.status) {
      await logout();
      navigate("/");
    } else {
      setError(getError(resp));

    }
    setLoading(false);
  };
  return (
    <Col md={12}>
      <Card className={"bg-light shadow-sm"}>
        <CardContent>
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              setLoading(true);
              onSubmit();
            }}
          >
            {CheckHasPermission("employee", "create")&&isMyAccount ? (
              <div className={"row justify-content-center my-4"}>
                <div className="col-md-8 mb-3">
                  {error ? <Alert severity="error">{error}</Alert> : null}
                </div>
                <div className={"col-md-5"}>
                    <button
                      type={"button"}
                      onClick={() => {
                        setChangePassword(false);
                      }}
                      className={"btn btn-secondary btn-lg me-1"}
                    >
                      <i className={"bi bi-x-lg"}></i> Back To Profile
                    </button>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <button type={"submit"} className={"btn btn-primary btn-lg"}>
                        <i className={"bi bi-send-fill"}></i> Reset Password
                      </button>
                    )}
                </div>
              </div>
            ) : (
              <div className={"row justify-content-center my-4"}>
                <div className="col-md-8 mb-3">
                  {error ? <Alert severity="error">{error}</Alert> : null}
                </div>
                <div className={"col-md-8"}>
                  <div className="mb-2">
                    {info?.currentPassword ? (
                      <label className="col-form-label fw-bold text-capitalize">
                        Current Password
                      </label>
                    ) : null}
                    <input
                      className="form-control form-control input-bg "
                      placeholder=" Current Password"
                      type="password"
                      required={true}
                      value={info?.currentPassword}
                      onKeyUp={checkPassword}
                      onChange={(e) => {
                        setInfo({
                          ...info,
                          currentPassword: e.currentTarget.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    {info?.newPassword ? (
                      <label className="col-form-label fw-bold text-capitalize">
                        New Password
                      </label>
                    ) : null}

                    <input
                      className="form-control form-control input-bg "
                      placeholder=" New Password"
                      required={true}
                      value={info?.newPassword}
                      type="password"
                      onKeyUp={checkPassword}
                      onChange={(e) => {
                        setInfo({
                          ...info,
                          newPassword: e.currentTarget.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    {info?.confirmNewPassword ? (
                      <label className="col-form-label fw-bold text-capitalize">
                        Confirm Password
                      </label>
                    ) : null}
                    <input
                      className="form-control form-control input-bg "
                      placeholder=" Confirm Password"
                      type="password"
                      required={true}
                      value={info?.confirmNewPassword}
                      onKeyUp={checkPassword}
                      onChange={(e) => {
                        setInfo({
                          ...info,
                          confirmNewPassword: e.currentTarget.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={"col-md-8 clearfix mt-3"}>
                  <div className={"float-end"}>
                    <button
                      type={"button"}
                      onClick={() => {
                        setChangePassword(false);
                      }}
                      className={"btn btn-secondary me-1"}
                    >
                      <i className={"bi bi-x-lg"}></i> Cancel
                    </button>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <button type={"submit"} className={"btn btn-primary"}>
                        <i className={"bi bi-send-fill"}></i> Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </form>
        </CardContent>
      </Card>
    </Col>
  );
};

export default UserPassword;
