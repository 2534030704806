import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import React from "react";
import BlueNavBar from "../layouts/BlueNavBar";
import useStyles from "./style";

function DashboardLayout() {
  const title = "Dashboard";
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {/* <BlueNavBar
        baseUrl={"/console/dashboard"}
        items={[
          {
            label: "My Dashboard",
            hoverName: "My Dashboard",
            href: "/",
            icon: "bi bi-people-fill",
          },
          {
            label: "Admin Dashboard",
            hoverName: "Admin Dashboard",
            href: "/admin",
            icon: "bi bi-house-door-fill lg",
            module: "dashboard",
          },
        ]}
      /> */}
    <div className="container-fluid">
      <div className="row">
          <div className='col-md-12'>
          <Outlet />
          </div>
          </div>
      </div>
    </>
  );
}

export default DashboardLayout;
