import { Button, Card, Col, Row } from "react-bootstrap";
import { Edit } from "@mui/icons-material";
import { useEffect, useState } from "react";
import {
  CheckHasAnyPermission,
  fetchDetails,
  relationshipList,
} from "../../../services/common";
import { AddForm } from "../../employee/AddForm";
import Breadcrumb from "../../../components/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/helpers/mapState";
import { mapDispatchToProps } from "../../../store/helpers/mapDispatch";

const UpdateMember = (props: any) => {
  const { cdeInfo } = props;
  const [info, setInfo] = useState<any>(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const loadInfo = async () => {
    const resp = await fetchDetails(`caise/view-member/${id}`);
    if (resp.status) {
      setInfo(resp.data);
    }
  };
  useEffect(() => {
    loadInfo().then();
  }, [id]);

  const notExited = info?.employee?.status !== "exited";
  const canUpdatePayDate =
    info?.employee?.status !== "retired" &&
    notExited &&
    CheckHasAnyPermission("approval-saving");
  return (
    <>
      <Breadcrumb />
      <Row className={"justify-content-center"}>
        <Col lg={12}>
          <AddForm
            hideSubmitButton={info?.identity}
            inline={true}
            title={"Member Identification"}
            col={6}
            mainFields={[
              {
                title: "Member Information",
                fields: [
                  {
                    label: "First Name",
                    name: "firstName",
                    disabled: true,
                    value: info?.employee?.firstName,
                  },
                  {
                    label: "Last Name",
                    name: "lastName",
                    disabled: true,
                    value: info?.employee?.lastName,
                  },
                  {
                    label: "Father's Name",
                    name: "fatherName",
                    disabled: true,
                    value: info?.employee?.fatherName,
                  },
                  {
                    label: "Mother's Name",
                    name: "motherName",
                    disabled: true,
                    value: info?.employee?.motherName,
                  },

                  {
                    label: "Civil Status",
                    name: "civilStatus",
                    col: 12,
                    disabled: true,
                    value: info?.employee?.civilStatus,
                  },
                ],
              },
              {
                title: "Next of Kin Information ",
                fields: [
                  {
                    label: "Names",
                    name: "nextOfKinNames",
                    required: true,
                    value: info?.nextOfKinNames,
                  },
                  {
                    label: "Relationship",
                    name: "nextOfKinRelationShip",
                    required: true,
                    value: info?.nextOfKinRelationShip,
                    type: "select",
                    values: relationshipList,
                  },
                  {
                    label: "National ID or Passport",
                    name: "nextOfKinNationalId",
                    // type: "number",
                    // required: true,
                    value: info?.nextOfKinNationalId,
                    minLength: 4,
                    maxLength: 16,
                  },
                  {
                    label: "Address",
                    name: "nextOfKinAddress",
                    required: true,
                    value: info?.nextOfKinAddress,
                  },
                  {
                    label: "Email",
                    name: "nextOfKinEmail",
                    type: "email",
                    // required: true,
                    value: info?.nextOfKinEmail,
                  },
                  {
                    label: "Phone Number",
                    name: "nextOfKinPhone",
                    type: "tel",
                    required: true,
                    value: info?.nextOfKinPhone,
                  },
                ],
              },
              {
                title: "Member Share Information",
                fields: [
                  {
                    label: "CDE membership ",
                    name: "cdeNumber",
                    // required: true,
                    disabled: true,
                    col: 12,
                    value: info?.cdeNumber,
                  },
                  {
                    type: "number",
                    label: "Salary(RWF)",
                    name: "currentSalary",
                    value: notExited?info?.employee?.salary:info?.currentSalary,
                    min: notExited?0:200000,
                    disabled: notExited,
                  },
                  {
                    type: "number",
                    label: "Fixed rate",
                    hidden: info?.incrementAmount === null,
                    name: "fixed",
                    disabled: true,
                    value: cdeInfo?.shareRate,
                  },
                  {
                    type: "number",
                    label: "Increment rate",
                    name: "incrementRate",
                    hidden: info?.incrementAmount === null,
                    disabled: true,
                    value: info?.incrementAmount?info?.shareRate - cdeInfo?.shareRate:null,
                  },
                  {
                    type: "number",
                    label: "Monthly contribution rate",
                    name: "shareRate",
                    disabled: true,
                    value: info?.incrementAmount?info?.shareRate:cdeInfo?.shareRate,
                  },
                  {
                    type: "number",
                    label: "Monthly contribution(RWF)",
                    name: "share",
                    value: (_val, values) =>
                    isNaN(values["shareRate"]) ||
                    isNaN(values["currentSalary"])
                      ? ""
                      :values["currentSalary"]<=0?info?.share: (values["currentSalary"] * values["shareRate"]) / 100,
                  disabled: true,
                  },
                  {
                    label: "Contribution Pay Date",
                    name: "monthlyContributionPaymentDate",
                    type: "number",
                    col: 6,
                    required: true,
                    value: info?.monthlyContributionPaymentDate,
                    disabled: !canUpdatePayDate,
                    max: 31,
                    min: 1,
                  },
                  {
                    label: "Loan Pay Date",
                    name: "monthlyLoanPaymentDate",
                    type: "number",
                    col: 6,
                    required: true,
                    value: info?.monthlyLoanPaymentDate,
                    disabled: !canUpdatePayDate,
                    max: 31,
                    min: 1,
                  },
                ],
              },
            ]}
            // title={"Create New Employee"}
            url={`/caise/update-member/${info?.id}`}
            method={"PUT"}
            onSuccess={() => {
              navigate(-1);
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateMember);
