import { useEffect, useState } from "react";
import { AddForm } from "../../employee/AddForm";
import moment from "moment";
import FileUpload from "../../../components/FileUploader";
import { fetchDetails, validateFileNotNull } from "../../../services/common";

const PayLoan = (props: any) => {
  const { setAddPayment, onSuccess, addPayment, loanList,loan } = props;
  // const [unPaidLoan, setUnPaidLoan] = useState<Array<any>>([]);
  const [selectedLoan, setSelectedLoan] = useState<any>();
  const [info, setInfo] = useState<any>();
  const [paymentProof, setPaymentProof] = useState<any>([]);

  // const unPaidLoanList = async () => {
  //   const resp = await fetchDetails(`loan/get-unpaid-loan/`);
    
  //   if (resp.status) {
  //     console.log(resp.data?.loan)
  //       setUnPaidLoan(resp.data?.loan);
  //   }
  // };
  // useEffect(() => {
  //   if (loanList&&loanList?.length>0) {
  //     setUnPaidLoan(loanList);
  //   } else {
  //     unPaidLoanList().then();
  //   }
  // }, [loanList,addPayment]);

  // const date = new Date();
  // const lastDay = new Date(
  //   date.getFullYear(),
  //   Number(info?.currentPaidMonth),
  //   info?.monthlyLoanPaymentDate
  // );
  // const currentPaidMonth = moment(lastDay).format("YYYY-MM-DD");
  // const hasPenalty =
  //   moment(currentPaidMonth).diff(moment().format("YYYY-MM-DD"), "days") < 0;

  useEffect(() => {
    if (loan) {
      setInfo(loan);
    }
  }, [loan]);

  return (
    <AddForm
      title={"Pay loan monthly installment"}
      maxWidth="md"
      formAlert={
        info?.penalty!==0 ? (   <div>
          <strong>
            N.B :Please settle the outstanding amount, including the penalty.
          </strong>
        </div>):null
      }
      mainFields={[
        {
          title: "",
          fields: [
            // {
            //   label: "Loan",
            //   name: "loanId",
            //   type: "select",
            //   col: 12,
            //   required: true,
            //   values: unPaidLoan?.map((v: any) => ({
            //     label: v?.LoanType?.label,
            //     id: v?.id,
            //   })),
            //   setValue: setSelectedLoan,
            // },
            {
              label:info?.outStandingPayment? "Own Payment(RWF)":"Amount(RWF)", 
              name: "amount",
              type: "number",
              disabled: true,
              col: 12,
              value: info?.amount,
            },
            {
              label: "Penalty Amount(RWF)",
              name: "penalty",
              col: 12,
              disabled: true,
              hidden:info?.penalty===0,
              value: info?.penalty,

            },
            // {
            //   label: "Date",
            //   name: "date",
            //   col: 12,
            //   disabled: true,
            //   value: moment().format("YYYY-MM-DD"),
            // },
            {
              label: "Proof of payment",
              name: "supportingDocument",
              type: "file",
              multiple: true,
              required: true,
              col: 12,
              value: paymentProof,
              validate: validateFileNotNull,
              render: () => {
                return (
                  <FileUpload
                    setFiles={setPaymentProof}
                    files={paymentProof}
                    required={true}
                    title="Upload"
                  />
                );
              },
            },
          ],
        },
      ]}
      url={`loan/pay-loan/${info?.id}`}
      method={"PUT"}
      onSuccess={onSuccess}
      open={addPayment}
      setOpen={() => setAddPayment(0)}
    />
  );
};
export default PayLoan;
