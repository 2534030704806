import Breadcrumb from "../../../components/Breadcrumb";
import {Box, Card, Tab, Tabs} from "@mui/material";
import {a11yProps, TabPanel} from "../../employee/leave/List";
import React, {useState} from "react";
import MutationReport from "./MutationReport";
import LeaveReport from "./LeaveReport";
import MissionReport from "./MissionReport";
import ServiceRequestReport from "./ServiceRequestReport";

function ServiceRequestReportIndex(){
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return <div>
        <Breadcrumb/>
        <Card style={{overflow:"visible"}}>
            <Box sx={{ borderBottom: 0.5, Update: "divider",fontFamily:'Inter' }} className="general-font">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <Tab label="Mutation" {...a11yProps(0)} className="general-font"/>
                    {/* <Tab label="Leave" {...a11yProps(1)} /> */}
                    <Tab label="Mission" {...a11yProps(1)} />
                    <Tab label="Self Service" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={value} p={2} index={0}>
                <MutationReport/>
            </TabPanel>
            {/* <TabPanel value={value} p={2} index={1}>
                <LeaveReport/>
            </TabPanel> */}
            <TabPanel value={value} p={2} index={1}>
                <MissionReport/>
            </TabPanel>
            <TabPanel value={value} p={2} index={2}>
                <ServiceRequestReport/>
            </TabPanel>
        </Card>
    </div>
}

export default ServiceRequestReportIndex;
