import secureLocalStorage from 'react-secure-storage';
import {keyUser} from "./MockAuthService";

export default function authHeader() {
  const user = JSON.parse(secureLocalStorage.getItem(keyUser) as string);

  if (user) {
    return { Authorization: user.token };
  } else {
    return {};
  }
}
