import { Add, CheckBox } from "@mui/icons-material";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { default as ReactSelect } from "react-select";
import SmallDialog from "./SmallModel";
import { fetchDetails } from "../services/common";
import { mapStateToProps } from "../store/helpers/mapState";
import { connect } from "react-redux";

// type IProps = {
//   onSuccess?: (val: any) => void | undefined;
//   plan?: any;
//   setPlan?: any;
//   addNew?: any;
//   planInfo?: any;
//   title?: string;
//   allBranch?: boolean;
//   singleSelection?: boolean | undefined;
//   listCol?: number | undefined;
//   buttonName?: string | undefined;
// };
type DProps = {
  list: Array<any>;
  parent: (elem: any) => any;
  expandable: boolean;
  onSearch: (elem: any) => void;
  GetAllChildren: (department: any) => Promise<any>;
  onChange: any;
  branch: any;
  selectedId: any;
};

type LProps = {
  list: Array<any>;
  parent: (elem: any) => any;
  expandable: boolean;
  GetAllChildren: (department: any) => Promise<any>;
  selectedId: any;
};
type RProps = {
  department: any;
  parent: (elem: any) => any;
  expandable: boolean;
  selectedId: any;
  GetAllChildren: (department: any) => Promise<any>;
};

const DepartmentRow = (props: RProps) => {
  const { department, GetAllChildren, parent, expandable, selectedId } = props;

  const [children, setChildren] = useState<Array<any>>(department.list ?? []);
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(true);
  return (
    <div>
      <div className={"d-flex justify-content-center align-items-center"}>
        {parent(department) ? (
          <div style={{ width: 15, borderBottom: "1px dotted black" }}></div>
        ) : null}
        <div className={"flex-grow-1"}>
          <ListItemButton
            // selected={active === index}
            onClick={async () => {
              // if (!children.length && expandable) {
              setLoading(true);
              let response = await GetAllChildren(department);
              if (response) {
                department.list = response;
                setChildren(response);
              }
              setLoading(false);
              // } else {
              //   if (children.length) {
              setShow(!show);
              //   }
              // GetUsersBydepartment(department.id);
              // }
            }}
          >
            {department?.id === selectedId ? (
              <CheckBox color="primary" />
            ) : (
              <Add />
            )}{" "}
            <div className={"ms-1"}>
              <ListItemText primary={department?.name} />
            </div>
          </ListItemButton>
        </div>
      </div>
      {children.length && show ? (
        <div className={"d-flex"}>
          <div style={{ borderLeft: "1px dotted black", marginLeft: 15 }}></div>
          <div className={"flex-grow-1"}>
            <DepartmentList
              expandable={expandable}
              list={department.list}
              parent={() => department}
              GetAllChildren={GetAllChildren}
              selectedId={selectedId}
            />
          </div>
        </div>
      ) : loading ? (
        <div className={"text-center"}>
          <CircularProgress size={12} />
        </div>
      ) : null}
    </div>
  );
};

const DepartmentList = (props: LProps) => {
  const { list, GetAllChildren, parent, expandable, selectedId } = props;

  return (
    <List>
      {list?.map((struct: any) => (
        <DepartmentRow
          expandable={expandable}
          parent={parent}
          department={struct}
          GetAllChildren={GetAllChildren}
          selectedId={selectedId}
        />
      ))}
    </List>
  );
};
const DepartmentCard = (props: DProps) => {
  const { list, GetAllChildren, expandable, onChange, branch, selectedId } =
    props;

  // const [departments,setdepartments] = React.useState<Array<any>>(list);

  return (
    <Card variant={"outlined"}>
      {/* <CardHeader
        title={"Branch"}
        titleTypographyProps={{ variant: "subtitle1" }}
      /> */}
      <CardContent>
        <Row>
          <Col>
            <Typography style={{fontFamily:'inter'}}>Branch</Typography>
          </Col>
          <Col>
            <ReactSelect
              className="text-nowrap"
              options={branch}
              onChange={(v: any) => {
                onChange(
                  `/department/search?info=${JSON.stringify({
                    branchId: v?.value,
                    level: "department",
                  })}`,
                  "department"
                );
              }}
            />
            {/* <select
              className="form-control form-control-sm"
              onChange={(e: any) => {
                onChange(
                  `/department/search?info=${JSON.stringify({
                    branchId: e.target.value,
                    level: "department",
                  })}`,
                  "department"
                );
              }}
            >
              <option>{"Select Branch"}</option>
              {branch?.map((value: any) => (
                <option value={value.id}>{value?.name}</option>
              ))}
            </select> */}
          </Col>
        </Row>
        <DepartmentList
          expandable={expandable}
          list={list}
          parent={() => null}
          GetAllChildren={GetAllChildren}
          selectedId={selectedId}
        />
      </CardContent>
    </Card>
  );
};
const SelectDepartment = (props: any) => {
  const { onSuccess, title, allBranch, listCol, setSelect } = props;
  const [openDialogue, setOpenDialogue] = useState(false);
  const [branch, setBranch] = useState<any>([]);
  const [info, setInfo] = useState<any>();
  const [searchDepartment, setSearchDepartment] = useState<any>([]);
  const [selectedId, setSelectedId] = useState<any>();

  let filterdepartment = searchDepartment?.length
    ? searchDepartment
    : info?.department;

  useEffect(() => {
    laodInitialData().then();
  }, []);

  const laodInitialData = async () => {
    const branchList = await fetchDetails(
      allBranch ? "/branch/view" : "/branch/viewByEmployee"
    );
    if (branchList.status) {
      const data = branchList?.data?.map(({ id, name }: any) => ({
        value: id,
        label: name,
      }));
      setBranch(data);
    }
  };
  const onChange = async (url: string, type: string) => {
    const response = await fetchDetails(url);
    if (response.status) {
      if (type === "department") {
        setInfo({ ...info, department: response?.data });
      }
      if (type === "unit") {
        setInfo({
          ...info,
          unit: response?.data?.departments,
          employee: response?.data?.employees,
        });
      }
      if (type === "employee") {
        setInfo({ ...info, employee: response?.data });
      }
    }
  };

  const handleOnkeyUpStrucrure = (e: any) => {
    let value = e.currentTarget.value.toLowerCase();
    let newArray: any = info?.department?.filter(
      (item: any) => item.name.toLowerCase().indexOf(value) !== -1
    );
    setSearchDepartment(newArray);
  };
  return (
    <Row className="mt-4">
      <Col>
        <Divider />
        <CardHeader className="fw-bold" />
        <div className="row">
          <div className={`col-md-${listCol ?? 12}`}>
            <div className="input-group mb-3 row p-2">
              <button
                className="btn btn-primary col-2"
                type="button"
                onClick={() => {
                  setOpenDialogue(true);
                }}
              >
                {"Department/Unit"}
              </button>
              <div className="border col-10">
                <div className="btn-group mb-3 p-1  me-1">
                  <span className="me-2">{info?.selectedDepartment?.name}</span>
                </div>
              </div>
            </div>
          </div>
          {/* // ))} */}
        </div>
      </Col>
      {openDialogue ? (
        <SmallDialog
          maxWidth="lg"
          title=""
          buttonTitle="Submit"
          needButton={true}
          open={openDialogue}
          setOpen={setOpenDialogue}
        >
          <Card>
            <CardHeader
              title={
                title ??
                `Select:${
                  info?.selectedDepartment ? info?.selectedDepartment?.name : ""
                }`
              }
              titleTypographyProps={{ variant: "h6" }}
              style={{fontFamily:'inter'}}
            />
            <form
              onSubmit={(e: any) => {
                e.preventDefault();
                setSelect(false);
              }}
            >
              <CardContent>
                <div className="row ">
                  <div className="col ">
                    <DepartmentCard
                      onSearch={handleOnkeyUpStrucrure}
                      onChange={onChange}
                      branch={branch}
                      expandable={true}
                      parent={() => null}
                      list={filterdepartment}
                      selectedId={selectedId}
                      GetAllChildren={async (department: any) => {
                        const response = await fetchDetails(
                          `/department/search?info=${JSON.stringify({
                            parent: department?.id,
                            level: "unit",
                          })}`
                        );
                        setSelectedId(department?.id);
                        setInfo({
                          ...info,
                          selectedDepartment: department,
                        });
                       if(response.status){
                        return response?.data?.departments;
                       }
                      }}
                    />
                  </div>
                </div>
              </CardContent>
              <CardActions className={"float-end"}>
                <button
                  type={"button"}
                  onClick={() => {
                    setOpenDialogue(false);
                  }}
                  className={"btn btn-secondary me-1"}
                >
                  <i className={"bi bi-x-lg"}></i> Cancel
                </button>
                <button
                  type={"button"}
                  onClick={() => {
                    setOpenDialogue(false);
                    if (typeof onSuccess === "function") {
                      onSuccess(info?.selectedDepartment);
                    }
                  }}
                  className={"btn btn-primary"}
                >
                  <i className={"bi bi-send-fill"}></i> Add
                </button>
              </CardActions>
            </form>
          </Card>
        </SmallDialog>
      ) : null}
    </Row>
  );
};

// export default SelectDepartment;
export default connect(mapStateToProps)(SelectDepartment);
