import { DatatableComponent } from "../../components/DatatableComponent";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import { CustomizedMenus } from "../../components/customizeMenu";
import DeleteDialog from "../../components/DeleteDialog";
import { Col, Row } from "react-bootstrap";
import { default as ReactSelect } from "react-select";
import { CheckHasPermission, fetchDetails } from "../../services/common";
import { connect } from "react-redux";
import { mapStateToProps } from "../../store/helpers/mapState";
import StatusComponent from "../../components/StatusComponent";
import { Add, ExpandMore } from "@mui/icons-material";
import GeneralButton from "../../components/Button";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

const EmployeeList = (props: any) => {
  const { loggedUser } = props;
  const location = useLocation();

  const isHeadQuarter =
    loggedUser?.Employee?.employeeBranches?.filter(
      (v: any) => v?.branch?.level === "hq"
    ).length > 0;
  const navigate = useNavigate();
  const [list, setList] = useState<Array<any>>([]);
  const [deleteOpen, setDeleteOpen] = useState(0);
  const [deleteActionUrl, setDeleteActionUrl] = useState<
    string | undefined | null
  >();
  const [change, setChange] = useState(0);
  const [branch, setBranch] = useState<any>([]);
  const [branchId, setBranchId] = useState<any>();
  const [search, setSearch] = useState(false);
  const [info, setInfo] = useState<any>({});

  const [department, setDepartment] = useState<any>([]);
  const [echelon, setEchelon] = useState<any>([]);
  const [roles, setRole] = useState<any>([]);
  const [position, setPosition] = useState<any>([]);
  const reload = () => {
    setChange((v: any) => v + 1);
  };
  const dataSummary = async () => {
    const branchList = await fetchDetails("/branch/view");

    if (branchList.status) {
      setBranch(
        branchList?.data?.map((v: any) => ({
          label: v?.name,
          value: v?.id,
        }))
      );
      // setBranchId(branchList?.data[0]);
    }
  };
  useEffect(() => {
    //fetch dataSummary
    if (!location.state?.fromEmployee) {
      if (isHeadQuarter) {
        // setBranchId(loggedUser?.Employee?.employeeBranches[0]?.branch);
        dataSummary().then();
      } else {
        setBranch(
          loggedUser?.Employee?.employeeBranches?.map((v: any) => ({
            label: v?.branch?.name,
            value: v?.branch?.id,
          }))
        );
        const defaultVal=loggedUser?.Employee?.employeeBranches[0]?.branch
        setBranchId({value:defaultVal?.id});
      }
    }
  }, [isHeadQuarter, loggedUser?.Employee?.employeeBranches]);

  const hasHrRole =
    loggedUser?.roles?.filter((item: any) => item.role?.name === "hr")?.length >
    0;

  useEffect(() => {
    laodInitialData();
  }, []);
  const laodInitialData = async () => {
    const role = await fetchDetails("/role/view");
    const echelon = await fetchDetails("/echlon/view");
    const department = await fetchDetails("/department/view");
    if (role.status)
      setRole(
        role?.data?.map((v: any) => ({
          label: v?.label,
          value: v?.id,
        }))
      );
    if (department.status)
      setDepartment(
        department?.data?.map((v: any) => ({
          label: v?.name,
          value: v?.id,
        }))
      );
    if (echelon.status)
      setEchelon(
        echelon?.data?.map((v: any) => ({
          label: v?.name,
          value: v?.id,
        }))
      );
    // if (level.status)
    //   setLevel(
    //     level?.data?.map((v: any) => ({
    //       label: v?.name,
    //       value: v?.id,
    //     }))
    //   );
  };
  const getPositionList = async () => {
    const position = await fetchDetails(
      `/position/view?info=${JSON.stringify({
        echlonId: info?.echlonId,
      })}`
    );
    if (position.status)
      setPosition(
        position?.data?.map((v: any) => ({
          label: v?.name,
          value: v?.id,
        }))
      );
  };
  const laodDepartment = async () => {
    const department = await fetchDetails(
      `/department/search?info=${JSON.stringify({
        branchId: branchId?.value,
      })}`
    );
    if (department.status)
      setDepartment(
        department?.data?.map((v: any) => ({
          label: v?.name,
          value: v?.id,
        }))
      );
  };
  useEffect(() => {
    if (info?.echlonId) {
      getPositionList();
    }
  }, [info?.echlonId]);

  useEffect(() => {
    if (branchId) {
      laodDepartment();
    }
  }, [branchId]);

  return (
    <div>
      <Breadcrumb
        endElement={
          <>
            {CheckHasPermission("settings-user", "create") ? (
              <GeneralButton
                variant="outlined"
                size="medium"
                name={"Add New"}
                icon={<Add />}
                onClick={() => navigate("setting/new")}
              />
            ) : null}
            {hasHrRole && (
              <GeneralButton
                variant="outlined"
                size="medium"
                name={"Request Social fund for CDE Member"}
                icon={<Add />}
                onClick={() => navigate("social/request/new", {
                  state: { fromEmployee: true },
                })}
              />
              // <button
              //   className={"btn btn-primary"}
              //   onClick={() => navigate("social/request/new")}
              // >
              //   Request Social Fund For Members
              // </button>
            )}
          </>
        }
      />
      <Row>
        <Col md={12}>
          {/* Advanced search */}
          <Accordion
            expanded={search}
            onChange={() => setSearch(!search)}
            className="mb-3 mt-3"
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography style={{fontFamily:'inter'}}>Advanced Search</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Row>
                <Col md={3}>
                  <label className="col-form-label text-capitalize">
                    Branch
                  </label>
                  <ReactSelect
                    className={"text-nowrap"}
                    options={branch}
                    // value={branchId}
                    onChange={(v: any) => {
                      setBranchId(v);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <label className="col-form-label text-capitalize">
                    Department
                  </label>
                  <ReactSelect
                    className={"text-nowrap"}
                    options={department}
                    // value={branch?.find((option: any) => {
                    //   return option.id === branchId?.id;
                    // })}
                    onChange={(v: any) => {
                      setInfo({ ...info, departmentId: v?.value });
                    }}
                  />
                </Col>
                <Col md={3}>
                  <label className="col-form-label text-capitalize">
                    Joined From
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={info?.fromDate}
                    onChange={(e: any) => {
                      setInfo({ ...info, fromDate: e.target.value });
                    }}
                  />
                </Col>
                <Col md={3}>
                  <label className="col-form-label text-capitalize">
                    Joined To
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={info?.toDate}
                    onChange={(e: any) => {
                      setInfo({ ...info, toDate: e.target.value });
                    }}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={3}>
                  <label className="col-form-label text-capitalize">
                    Category
                  </label>
                  <ReactSelect
                    className={"text-nowrap"}
                    options={[
                      {
                        label: "Contractual",
                        value: "contractual",
                      },
                      {
                        label: "Under Statute",
                        value: "under statute",
                      },
                      {
                        label: "Political appointee",
                        value: "political appointee",
                      },
                    ]}
                    onChange={(v: any) => {
                      setInfo({ ...info, category: v?.value });
                    }}
                  />
                </Col>
                <Col md={3}>
                  <label className="col-form-label text-capitalize">
                    Gender
                  </label>
                  <ReactSelect
                    className={"text-nowrap"}
                    options={[
                      {
                        label: "Male",
                        value: "male",
                      },
                      {
                        label: "Female",
                        value: "female",
                      },
                    ]}
                    onChange={(v: any) => {
                      setInfo({ ...info, gender: v?.value });
                    }}
                  />
                </Col>
                <Col md={3}>
                  <label className="col-form-label text-capitalize">
                    Scale
                  </label>
                  <ReactSelect
                    className={"text-nowrap"}
                    options={echelon}
                    // value={echelon?.find((option: any) => {
                    //   return option.id === branchId?.id;
                    // })}
                    onChange={(v: any) => {
                      setInfo({ ...info, echlonId: v?.value });
                    }}
                  />
                </Col>
                <Col md={3}>
                  <label className="col-form-label text-capitalize">
                    Position
                  </label>
                  <ReactSelect
                    className={"text-nowrap"}
                    options={position}
                    // value={branch?.find((option: any) => {
                    //   return option.id === branchId?.id;
                    // })}
                    onChange={(v: any) => {
                      setInfo({ ...info, positionId: v?.value });
                    }}
                  />
                </Col>
                <Col md={3}>
                  <label className="col-form-label text-capitalize">Role</label>
                  <ReactSelect
                    className={"text-nowrap"}
                    options={roles}
                    onChange={(v: any) => {
                      setInfo({ ...info, roleId: v?.value });
                    }}
                  />
                </Col>
                {/* <Col md={12}>
              <Button
                size="sm"
                className="ms-3 float-end"
                onClick={() => {
                  setInfo({});
                }}
              >
                <LockReset /> Reset
              </Button>
            </Col> */}
              </Row>
            </AccordionDetails>
          </Accordion>
          <DatatableComponent
            columns={[
              {
                label: "No",
                name: "firstName",
                options: {
                  customBodyRenderLite: (id: any, index: any) => {
                    return (
                      <span className="text-capitalize  ">{id+1}</span>
                    );
                  },
                },
              },
              {
                label: "Names",
                name: "firstName",
                options: {
                  customBodyRenderLite: (id: any, index: any) => {
                    return (
                      <span className="text-capitalize  ">{`${list[index]?.firstName} ${list[index]?.lastName}`}</span>
                    );
                  },
                },
              },
              // {
              //   label: "Position",
              //   name: "positionId",
              //   options: {
              //     customBodyRenderLite: (id: any, index: any) => {
              //       return (
              //         <span className="text-capitalize">
              //           {list[index]?.position?.name}
              //         </span>
              //       );
              //     },
              //   },
              // },
              // {
              //   label: "Category",
              //   name: "category",
              // },
              // {
              //   label: "Level",
              //   name: "levelId",
              //   options: {
              //     customBodyRenderLite: (id: any, index: any) => {
              //       return (
              //         <span className="text-capitalize">
              //           {list[index]?.level?.name}
              //         </span>
              //       );
              //     },
              //   },
              // },
              {
                label: "Join Date",
                name: "createdAt",
                options: {
                  customBodyRender: (v: any) => moment(v).format("YYYY-MMM-DD"),
                },
              },
              {
                label: "Email",
                name: "email",
              },

              {
                label: "Status",
                name: "status",
                options: {
                  customBodyRender: (status: any) => (
                    <StatusComponent status={status} badge={false} />
                  ),
                },
              },
              {
                label: "Last Updated",
                name: "updatedAt",
                options: {
                  customBodyRender: (v: any) => moment(v).format("L"),
                },
              },
              {
                name: "id",
                label: "Action",
                options: {
                  customBodyRenderLite: (id: any, index: any) => {
                    return (
                      <CustomizedMenus
                        onView={() => {
                          navigate(`details/${list[index].id}`);
                        }}
                        onEdit={() => {
                          navigate(`setting/${list[index].id}`);
                        }}
                        onDelete={() => {
                          setDeleteOpen(1);
                          setDeleteActionUrl(
                            `/employee/delete/${list[index].id}`
                          );
                        }}
                        needEdit={CheckHasPermission("settings-user", "create")}
                        needDelete={false}
                      />
                    );
                  },
                },
              },
            ]}
            url={`/employee/datatable?change=${change}&branchId=${
              branchId?.value ?? ""
            }&info=${JSON.stringify(info)}`}
            title={`List of Employees${
              branchId?.name || branchId?.label
                ? ` - ${branchId?.name ?? branchId?.label}`
                : ""
            }`}
            processData={(data: any) => {
              setList(data.data);
              return data;
            }}
            onRowClick={(row, { rowIndex }) => {
              navigate(`details/${list[rowIndex].id}`);
            }} 
          />
          {deleteActionUrl ? (
            <DeleteDialog
              open={deleteOpen}
              onSuccess={reload}
              setOpen={setDeleteOpen}
              url={deleteActionUrl!}
            />
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

// export default EmployeeList;
export default connect(mapStateToProps)(EmployeeList);
