import Breadcrumb from "../../../components/Breadcrumb";
import { Card, Col, Row } from "react-bootstrap";
import { Alert, AlertTitle, CardHeader } from "@mui/material";
import { useEffect, useState } from "react";
import {
  CheckHasPermission,
  fetchDetails,
  getError,
} from "../../../services/common";
import PageLoading from "../../../components/PageLoading";
import { useNavigate } from "react-router-dom";
import { default as ReactSelect } from "react-select";
import GeneralButton from "../../../components/Button";
import {
  AccountBalance,
  BusinessCenter,
  CreditCard,
  Diversity1,
  Diversity3,
  Gavel,
  Inventory2,
  List,
  Money,
  Output,
  PaymentSharp,
  Settings,
  Support,
} from "@mui/icons-material";
import moment from "moment";

function CdeSummaryDashboard() {
  const [info, setInfo] = useState<any>();
  const [error, setError] = useState<any>();
  const [fiscalYearList, setFiscalYearList] = useState<Array<any>>([]);
  const [fiscalYear, setFiscalYear] = useState<any>("all");
  const [currentFiscalYear, setCurrentFiscalYear] = useState<any>();
  const [data, setData] = useState<any>();

  const unPaidLoan = info?.totalLoans ? info?.totalLoans - info?.paidLoans : 0;
  const navigate = useNavigate();
  let fiscalYearDaysRemain = moment(currentFiscalYear?.endDate).diff(
    moment().format("YYYY-MM-DD"),
    "days"
  );
  const yearAboutToEnd=fiscalYearDaysRemain<7&&fiscalYearDaysRemain>0
  const loadInfo = async () => {
    const resp = await fetchDetails(`cde/summary?fiscalYear=${fiscalYear}`);
    const resps = await fetchDetails(`cde/active/summary`);
    if (resps.status) setData(resps.data);
    if (resp.status) {
      setInfo({
        ...resp.data?.account[0],
        loanMembers: resp.data?.loanMembers,
        totalMembers: resp.data?.totalMembers,
        usedFund: resp.data?.usedFund,
      });
    } else {
      setError(getError(resp));
    }
  };
  const getFiscalYear = async () => {
    const fiscalYear = await fetchDetails("/fiscal/view");
    let allYear = [];
    if (fiscalYear.status) {
      allYear = fiscalYear?.data?.map(({ label, id, name }: any) => ({
        value: id,
        label: label ?? name,
      }));
      allYear.push({ value: "all", label: "All" });
      setFiscalYearList(allYear);
      setCurrentFiscalYear(
        fiscalYear?.data?.find((item: any) => item?.status === "open")
      );
    }
  };
  const endDay = moment()
    .set({ date: data?.generalContributionEndDate })
    .format("YYYY-MM-DD");
  const startDay = moment()
    .set({ date: data?.generalContributionDate })
    .format("YYYY-MM-DD");

  const endDayOfContribution =
    moment(endDay).diff(moment().format("YYYY-MM-DD"), "days") >= 0;

  const startDayOfContribution =
    moment(startDay).diff(moment().format("YYYY-MM-DD"), "days") >= 0;
  // const canSubmitLeave = startLeavePlan || endLeavePlanDate;
  // const startDayOfContribution = moment().set({ date: info?.generalContributionDate }).format("YYYY-MM-DD");

  const timeToContribute =
    (startDayOfContribution || endDayOfContribution) &&
    data &&
    data?.completedMonth !== data?.currentMonth;
  const usedFunds = info?.socialFunds - info?.usedFund;
  useEffect(() => {
    //fetch all FiscalYear
    getFiscalYear().then();
  }, []);
  useEffect(() => {
    // if(fiscalYear){
    loadInfo().then();
    // }
  }, [fiscalYear]);

  return (
    <div>
      <Breadcrumb
        endElement={
          CheckHasPermission("approval-saving", "create") ? (
            <div className="mb-3">
              {/* {timeToContribute ? ( */}
                <GeneralButton
                  variant="outlined"
                  size="medium"
                  name={
                    "Confirm Payment contributions and loan"
                  }
                  icon={<PaymentSharp />}
                  onClick={() => {
                    navigate("create");
                  }}
                />
              {/* // ) : null} */}
              <GeneralButton
                size="medium"
                variant="contained"
                name="General Settings"
                icon={<Settings />}
                onClick={() => {
                  navigate("settings");
                }}
              />
            </div>
          ) : null
        }
      />

      {!info ? (
        <PageLoading error={error} />
      ) : (
        <>
          {/* alert display when fiscal year remain with seven days or less */}
          {yearAboutToEnd  ? (
            <Alert severity="warning" className="mb-1">
              <AlertTitle>Fiscal year alert</AlertTitle>
              Fiscal year is about to end in {fiscalYearDaysRemain} days!&nbsp;
              <strong>
                New fiscal year will be created automatically by the system on
                &nbsp;
                {moment()
                  .set({ date: 31, month: 6 })
                  .format("dddd, MMMM DD, YYYY")}
              </strong>
              .
            </Alert>
          ) : null}

          <Row className="mb-3 ">
            <Col md={2}>
              <ReactSelect
                className="text-nowrap"
                options={fiscalYearList}
                value={fiscalYearList?.find(
                  (option: any) => option.value === fiscalYear
                )}
                onChange={(v: any) => {
                  setFiscalYear(v?.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col className={"mb-2"} md={4}>
              <Card className={" shadow-sm "} style={{ height: "80%" }}>
                <CardHeader
                  onClick={() => navigate("contributions")}
                  // className="color1"
                  title={
                    <span className=" dataTableTitle fw-bold">
                      Contributions(RWF)
                    </span>
                  }
                  subheader={
                    <div className="d-flex">
                      <span className="fw-bold me-1 border-right p-3 data-table-header ">
                        {"Monthly"}
                        <h5 className={"mt-2 "}>
                          {info?.monthlyContributions?.toLocaleString() ?? 0}
                        </h5>
                      </span>
                      <span className="fw-bold p-3 data-table-header">
                        {"Total"}
                        <h5 className={"mt-2 "}>
                          {info?.totalContributions?.toLocaleString() ?? 0}
                        </h5>
                      </span>
                    </div>
                  }
                  action={<Diversity1 color="primary" />}
                />
              </Card>
            </Col>
            <Col className={"mb-2"} md={4}>
              <Card className={"shadow-sm"} style={{ height: "80%" }}>
                {" "}
                <CardHeader
                  onClick={() => navigate("loans")}
                  // className="color1"
                  title={
                    <span className=" dataTableTitle fw-bold">Loans(RWF)</span>
                  }
                  subheader={
                    <div className="d-flex">
                      <span className="fw-bold me-1 border-right p-3 data-table-header">
                        {"Unpaid"}
                        <h5 className={"mt-2 "}>
                          {unPaidLoan?.toLocaleString() ?? 0}
                        </h5>
                      </span>
                      <span className="fw-bold p-3 data-table-header">
                        {"Total Given"}
                        <h5 className={"mt-2 "}>
                          {info?.totalLoans?.toLocaleString() ?? 0}
                        </h5>
                      </span>
                    </div>
                  }
                  action={<AccountBalance color="primary" />}
                />
              </Card>
            </Col>
            <Col className={"mb-2"} md={4}>
              <Card className={"shadow-sm"} style={{ height: "80%" }}>
                <CardHeader
                  // className="color1"
                  title={
                    <span className=" dataTableTitle fw-bold">
                      Penalties(RWF)
                    </span>
                  }
                  subheader={
                    <div className="d-flex">
                      <span className="fw-bold me-1 border-right p-3 data-table-header">
                        {"Loan"}
                        <h5 className={"mt-2 "}>
                          {info?.loanPenalty?.toLocaleString() ?? 0}
                        </h5>
                      </span>
                      <span className="fw-bold p-3 data-table-header">
                        {"Contributions"}
                        <h5 className={"mt-2 "}>
                          {info?.otherPenalty?.toLocaleString() ?? 0}
                        </h5>
                      </span>
                    </div>
                  }
                  action={<Gavel color="primary" />}
                />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className={"mb-2"} md={4}>
              <Card className={"colorWarning shadow-sm"}>
                <CardHeader
                  subheader={
                    <span className="fw-bold dataTableTitle">
                      {"Income"}
                      <h6 className={"mt-2 color2"}>
                        {info?.incomes?.toLocaleString() ?? 0} RWF
                      </h6>
                    </span>
                  }
                  action={<Output color="primary" />}
                />
              </Card>
            </Col>
            <Col className={"mb-2"} md={4} role="button">
              {/* <Card className={"colorWarning shadow-sm"}>
                <CardHeader
                  subheader={
                    <span className="fw-bold dataTableTitle">
                      {"Social Funds"}
                      <h6 className={"mt-2 color2"}>
                        {info?.socialFunds?.toLocaleString() ?? 0} RWF
                      </h6>
                    </span>
                  }
                  action={<Support color="primary" />}
                />
              </Card> */}
              <Card className={"colorWarning shadow-sm"}>
                <CardHeader
                  onClick={() => navigate("funds")}
                  subheader={
                    <div className={"d-flex"}>
                      <span className="fw-bold flex-grow-1 dataTableTitle">
                        {"Social Funds"}
                        <h6 className={"mt-2 color2"}>
                          {info?.socialFunds?.toLocaleString() ?? 0} RWF
                        </h6>
                      </span>
                      <span className="fw-bold flex-grow-1 dataTableTitle">
                        {"Used Funds"}
                        <h6 className={"mt-2 color2"}>
                          {info?.usedFund?.toLocaleString() ?? 0} RWF
                        </h6>
                      </span>
                    </div>
                  }
                  action={<Support color="primary" />}
                />
              </Card>
            </Col>
            <Col className={"mb-2"} md={4} role="button">
              <Card className={"colorWarning shadow-sm"}>
                <CardHeader
                  onClick={() => navigate("expenses")}
                  subheader={
                    <div className={"d-flex"}>
                      <span className="fw-bold flex-grow-1 dataTableTitle">
                        {"Expenses"}
                        <h6 className={"mt-2 color2"}>
                          {info?.expense?.toLocaleString() ?? 0} RWF
                        </h6>
                      </span>
                      <span className="fw-bold flex-grow-1 dataTableTitle">
                        {"Used Expenses"}
                        <h6 className={"mt-2 color2"}>
                          {info?.consumedExpense?.toLocaleString() ?? 0} RWF
                        </h6>
                      </span>
                    </div>
                  }
                  action={<List color="primary" />}
                />
              </Card>
            </Col>
            <Col className={"mb-2"} md={4}>
              <Card className={"colorInfo shadow-sm "}>
                <CardHeader
                  onClick={() => navigate("capitals")}
                  subheader={
                    <span className="fw-bold dataTableTitle">
                      {"CDE share capital"}
                      <h6 className={"mt-2 color2"}>
                        {info?.shareCapitals?.toLocaleString() ?? 0} RWF
                      </h6>
                    </span>
                  }
                  action={<Inventory2 color="primary" />}
                />
              </Card>
            </Col>
            <Col className={"mb-2"} md={4}>
              <Card className={"colorInfo shadow-sm "}>
                <CardHeader
                  onClick={() => navigate("dividend")}
                  subheader={
                    <span className="fw-bold dataTableTitle">
                      {"Dividend"}
                      <h6 className={"mt-2 color2"}>
                        {info?.dividends?.toLocaleString() ?? 0} RWF
                      </h6>
                    </span>
                  }
                  action={<Money color="primary" />}
                />
              </Card>
            </Col>
            <Col className={"mb-2"} md={4}>
              <Card className={"colorInfo shadow-sm"}>
                <CardHeader
                  onClick={() => navigate("investment")}
                  subheader={
                    <span className="fw-bold dataTableTitle">
                      {"Investment"}
                      <h6 className={"mt-2 color2"}>
                        {info?.investments?.toLocaleString() ?? 0} RWF
                      </h6>
                    </span>
                  }
                  action={<BusinessCenter color="primary" />}
                />
              </Card>
            </Col>
            <Col className={"mb-2"} md={4}>
              <Card className={"colorWarning shadow-sm"}>
                <CardHeader
                  onClick={() => navigate("applications?tab=members")}
                  subheader={
                    <span className="fw-bold dataTableTitle">
                      {"CDE Members"}
                      <h6 className={"mt-2 color2"}>
                        {info?.totalMembers?.toLocaleString() ?? 0}
                      </h6>
                    </span>
                  }
                  action={<Diversity3 color="primary" />}
                />
              </Card>
            </Col>
            <Col className={"mb-2"} md={4}>
              <Card className={"colorWarning shadow-sm "}>
                <CardHeader
                  onClick={() =>
                    navigate("applications?tab=members", { state: { loan: 1 } })
                  }
                  subheader={
                    <span className="fw-bold dataTableTitle">
                      {"Members With Loan"}
                      <h6 className={"mt-2 color2"}>
                        {info?.loanMembers?.toLocaleString() ?? 0}
                      </h6>
                    </span>
                  }
                  action={<CreditCard color="primary" />}
                />
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default CdeSummaryDashboard;
