
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import {downloadExcelFile, getActiveCdeInfo, getBasicActiveCdeInfo, getLoggedUser, setSocket} from "../user/action";

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return bindActionCreators(
    {
      GetLoggedUser:getLoggedUser,
      GetActiveCdeInfo:getActiveCdeInfo,
      GetBasicActiveCdeInfo:getBasicActiveCdeInfo,
      SetSocket:setSocket,
      DownloadExcelFile:downloadExcelFile
    },
    dispatch
  );
};
