import { useNavigate, useParams } from "react-router-dom";
import {
  fetchDetails,
  getError,
  getFileMime,
  getSuccessMessage,
  submitGeneralForm,
} from "../../../services/common";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import PageLoading from "../../../components/PageLoading";
import { Card, Col, Container, Row } from "react-bootstrap";
import {
  Alert,
  Button,
  CardContent,
  CardHeader,
  CircularProgress,
  Link,
  Typography,
} from "@mui/material";
import StatusComponent from "../../../components/StatusComponent";
import { BorderColorOutlined, Check, Forward, HighlightOff, Info } from "@mui/icons-material";
import RequestHistoryProcess from "../../../components/RequestHistory";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/helpers/mapState";
import { mapDispatchToProps } from "../../../store/helpers/mapDispatch";
import SmallDialog from "../../../components/SmallModel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Approver from "../../../components/Approver";
import Reviewers from "../../../components/Reviewer";
import moment from "moment";

const ApplicationDetails = (props: any) => {
  const { loggedUser } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [info, setInfo] = useState<any>();
  const [error, setError] = useState<any>();
  const [confirm, setConfirm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [viewApplication, setViewApplication] = useState<boolean>(false);
  const [openDialogue, setOpenDialogue] = useState<boolean>(false);
  const [newData, setData] = useState<any>();
  const identification = info?.employee?.CaisseMember;

  const loadInfo = async () => {
    const resp = await fetchDetails(`caise/view/${id}`);
    if (resp.status) {
      setInfo(resp.data);
    } else {
      setError(getError(resp));
    }
  };

  const onSubmit = async (data?: any) => {
    setLoading(true);
    setConfirm(false);
    let infoToSave = data ?? newData;
    const resp = await submitGeneralForm(
      `/caise/change-status/${id}`,
      infoToSave,
      null,
      "PUT"
    );
    if (resp.status) {
      setData({});
      setError(null);
      setOpenDialogue(false);
      loadInfo().then();
    } else {
      setError(getError(resp));
    }
    setLoading(false);
  };

  useEffect(() => {
    loadInfo().then();
  }, [id]);

  const isInitiator = loggedUser?.employeeId === info?.employeeId;
  const isApprover = loggedUser?.id === info?.approverId &&(info?.status === "under review" ||
  info?.status === "forwarded" ||
  info?.status === "need-identification" ||
  info?.status === "pending");

  const canUpdate = isInitiator && info?.status === "request action";

  const branch = info?.employee?.employeeBranches?.map(
    (bran: any) => bran?.branch?.name
  );
  return (
    <div>
      <Breadcrumb />
      {!info ? (
        <PageLoading error={error} />
      ) : (
        <Row>
          <Col md={12}>
            <Card className={"bg-light shadow-sm"}>
              <CardHeader
                subheader={
                  <span className="fw-bold">
                    Caisse d'entraide Application Details
                  </span>
                }
                action={<StatusComponent status={info?.status} badge={true} />}
              />
              <CardContent>
                <div className={"row justify-content-end"}>
                  {info?.status !== "approved" &&
                  info?.status !== "rejected" ? (
                    <div className="col-md-8 fw-bold d-flex justify-content-end">
                      {canUpdate ? (
                        <Button
                          className="btn-primary me-2"
                          size="small"
                          variant="outlined"
                          startIcon={<BorderColorOutlined />}
                          onClick={() => {
                            navigate(
                              `/console/saving/contributions/update/${info?.id}`
                            );
                          }}
                        >
                          Update
                        </Button>
                      ) : null}
                      {isApprover ? (
                        <>
                          <Button
                            className="me-1"
                            color="warning"
                            size="small"
                            variant="outlined"
                            startIcon={<Info />}
                            onClick={() => {
                              setOpenDialogue(true);
                              setData({
                                id: info?.id,
                                status: "request action",
                              });
                            }}
                          >
                            Request for action
                          </Button>
                          <Button
                            className="me-2"
                            color="error"
                            size="small"
                            variant="outlined"
                            startIcon={<HighlightOff />}
                            onClick={() => {
                              setOpenDialogue(true);
                              setData({ id: info?.id, status: "rejected" });
                            }}
                          >
                            Reject
                          </Button>
                          {/* {!isReviewer && ( */}

                          {/*<Button*/}
                          {/*  className="me-2"*/}
                          {/*  color="info"*/}
                          {/*  size="small"*/}
                          {/*  variant="outlined"*/}
                          {/*  startIcon={<Info />}*/}
                          {/*  disabled={canApprove}*/}
                          {/*  onClick={() => {*/}
                          {/*    setOpenDialogue(true);*/}
                          {/*    setData({*/}
                          {/*      id: info?.id,*/}
                          {/*      status: "need-identification",*/}
                          {/*    });*/}
                          {/*  }}*/}
                          {/*>*/}
                          {/*  Request identification*/}
                          {/*</Button>*/}
                          <Button
                            className="me-2"
                            color="success"
                            size="small"
                            // disabled={!canApprove}
                            variant="outlined"
                            startIcon={<Check />}
                            onClick={() => {
                              setOpenDialogue(true);
                              setData({ id: info?.id, status: "approved" });
                            }}
                          >
                            Approve
                          </Button>
                          {/* )} */}
                        </>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                {identification ? (
                  <Button
                    onClick={() => {
                      setViewApplication(!viewApplication);
                    }}
                  >
                    {" "}
                    <u>{viewApplication?'Back To Identity':'Application letter'}</u>
                  </Button>
                ) : null}

                {identification && !viewApplication ? (
                  <div className="row justify-content-center mb-3 mt-4">
                    <div className="col-md-9 border">
                      {" "}
                      <Typography style={{fontFamily:'Inter'}} className="fw-bold mb-4">
                        CDE MEMBER IDENTIFICATION
                      </Typography>
                      <p>
                        CDE membership number (numéro d’adhésion):{" "}
                        {/* <strong>{identification?.cdeNumber}</strong> */}
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          disabled={true}
                          value={identification?.cdeNumber}
                        />
                      </p>
                      <p>
                        Names :
                        {/* <strong>{`${info?.employee?.firstName} ${info?.employee?.lastName}`}</strong> */}
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          disabled={true}
                          value={`${info?.employee?.firstName} ${info?.employee?.lastName}`}
                        />
                      </p>
                      <p>
                        National identity card number :{" "}
                        {/* <strong>{info?.employee?.nationalId}</strong> */}
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          disabled={true}
                          value={info?.employee?.nationalId}
                        />
                      </p>
                      <p>
                        Marital Status :
                        {/* <strong>{info?.employee?.civilStatus}</strong> */}
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          disabled={true}
                          value={info?.employee?.civilStatus}
                        />
                      </p>
                      <p>
                        Father’s name
                        {/* <strong>{info?.employee?.fatherName}</strong> */}
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          disabled={true}
                          value={info?.employee?.fatherName}
                        />
                      </p>
                      <p>
                        Mother’s name
                        {/* <strong>{info?.employee?.motherName}</strong> */}
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          disabled={true}
                          value={info?.employee?.motherName}
                        />
                      </p>
                      <p>
                        Phone number:
                        {/* <strong>{info?.employee?.phone}</strong> */}
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          disabled={true}
                          value={info?.employee?.phone}
                        />
                      </p>
                      <p>
                        Email address:
                        {/* <strong>{info?.employee?.email}</strong> */}
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          disabled={true}
                          value={info?.employee?.email}
                        />
                      </p>
                      <Typography style={{fontFamily:'Inter'}} className="fw-bold mb-4">
                        Next of kin
                      </Typography>
                      <p>
                        Names:
                        {/* <strong>{identification?.nextOfKinNames}</strong> */}
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          disabled={true}
                          value={identification?.nextOfKinNames}
                        />
                      </p>
                      <p>
                        Address:
                        {/* <strong>{identification?.nextOfKinAddress}</strong> */}
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          disabled={true}
                          value={identification?.nextOfKinAddress}
                        />
                      </p>
                      <p>
                        Relationship:
                        {/* <strong>{identification?.nextOfKinRelationShip}</strong> */}
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          disabled={true}
                          value={identification?.nextOfKinRelationShip}
                        />
                      </p>
                      <p>
                        National ID:
                        {/* <strong>{identification?.nextOfKinRelationShip}</strong> */}
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          disabled={true}
                          value={identification?.nextOfKinNationalId}
                        />
                      </p>
                      <p>
                        Email:
                        {/* <strong>{identification?.nextOfKinRelationShip}</strong> */}
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          disabled={true}
                          value={identification?.nextOfKinEmail}
                        />
                      </p>
                      <p>
                        Phone number:
                        {/* <strong>{identification?.nextOfKinPhone}</strong> */}
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          disabled={true}
                          value={identification?.nextOfKinPhone}
                        />
                      </p>
                    </div>
                  </div>
                ) : null}
                {viewApplication || !identification ? (
                  <div className="row justify-content-center mb-3 mt-4">
                    <div className="col-md-9 border">
                      {" "}
                      <Typography style={{fontFamily:'Inter'}} className="fw-bold mb-4">
                        GUSABA KUBA UMUNYAMURYANGO WA CAISSE D’ENTRAIDE
                      </Typography>
                      <p>
                        NJYEWE,{" "}
                        <strong>{`${info?.employee?.firstName} ${info?.employee?.lastName}`}</strong>
                        , NEMEYE KO BURI KWEZI NZAJYA NTANGA UMUGABANE WA :{" "}
                        {info?.share?.toLocaleString()}(Ishobora guhinduka)
                      </p>
                      <p>
                        {" "}
                        AVUYE KU MUSHAHARA WANJYE BURI KWEZI AKAJYA MURI CAISSE
                        D’ENTRAIDE Y’ABAKOZI B’UBUSHINJACYAHA NIFUZA KUBERA
                        UMUNYAMURYANGO.
                      </p>
                      <p>
                        AMAZINA :{" "}
                        <strong>{`${info?.employee?.firstName} ${info?.employee?.lastName}`}</strong>
                      </p>
                      <p>
                        ICYO AKORA :{" "}
                        <strong>{info?.employee?.position?.name}</strong>
                      </p>
                      <p>
                        AHO AKORERA (Prosecution Office) :
                        <strong>{branch?.toString()}</strong>
                      </p>
                      <p>
                        {" "}
                        TARIKI:
                        <strong>{moment(info?.createdAt).format("L")}</strong>
                      </p>
                    </div>
                    <div className={'col-md-9'}>
                      <h3 className={'mt-3'}>Next of kin Information</h3>
                      <div className={'row mt-2'}>
                        <div className={'col-md-4'}>
                          <div><span>Next of kin</span></div>
                          <div><span className={'fw-bold'}>{info?.nextOfKinNames}</span></div>
                        </div>
                        <div className={'col-md-4'}>
                          <div><span>Address</span></div>
                          <div><span className={'fw-bold'}>{info?.nextOfKinAddress}</span></div>
                        </div>
                        <div className={'col-md-4'}>
                          <div><span>National ID</span></div>
                          <div><span className={'fw-bold'}>{info?.nextOfKinNationalId}</span></div>
                        </div>
                      </div>
                      <div className={'row mt-2'}>
                        <div className={'col-md-4'}>
                          <div><span>Relationship</span></div>
                          <div><span className={'fw-bold'}>{info?.nextOfKinRelationShip}</span></div>
                        </div>
                        <div className={'col-md-4'}>
                          <div><span>Phone Number</span></div>
                          <div><span className={'fw-bold'}>{info?.nextOfKinPhone}</span></div>
                        </div>
                        <div className={'col-md-4'}>
                          <div><span>Email Address</span></div>
                          <div><span className={'fw-bold'}>{info?.nextOfKinEmail}</span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <Reviewers
                  loggedUser={loggedUser}
                  RequestApprovers={info?.RequestApprovers}
                />
                <hr />
                <div className="row">
                  <Typography style={{fontFamily:'Inter'}} className="col-md-12 mb-3 fw-bold">
                    Request History
                  </Typography>
                  <div className="col-md-12">
                    <RequestHistoryProcess
                      RequestProcesses={info?.RequestProcesses}
                    />
                  </div>
                </div>

                {openDialogue ? (
                  <SmallDialog
                    maxWidth="md"
                    title={
                      newData?.status === "request action"
                        ? "Request Action"
                        : newData?.status === "approved"
                        ? "Approve Request"
                        : newData?.status === "need-identification"
                        ? "Request member to fill identification "
                        :"Reject Caisse d'entraide Application"
                    }
                    buttonTitle="Submit"
                    needButton={true}
                    open={openDialogue}
                    setOpen={setOpenDialogue}
                  >
                    {error ? (
                      <Alert
                        style={{ width: "100%" }}
                        className={"mb-3"}
                        severity={"error"}
                        onClose={() => setError(null)}
                      >
                        {error}
                      </Alert>
                    ) : null}
                    <form
                      onSubmit={(e: any) => {
                        e.preventDefault();
                        setConfirm(true);
                      }}
                    >
                      {
                        <div>
                          <label className="col-form-label text-capitalize">
                            Comment
                          </label>
                          <textarea
                            placeholder="Type here..."
                            className="form-control form-control mb-3"
                            required={true}
                            value={newData?.comment}
                            rows={5}
                            onChange={(e: any) => {
                              setData({
                                ...newData,
                                comment: e.currentTarget.value,
                              });
                            }}
                          />
                        </div>
                      }
                      {/*{newData?.status === "approved" && (*/}
                      {/*  <div className={"mb-3"}>*/}
                      {/*    <Approver*/}
                      {/*      title={"Copy Application To"}*/}
                      {/*      buttonName={"CC Members"}*/}
                      {/*      onSelectUser={(val: any) => {*/}
                      {/*        let reviewer;*/}
                      {/*        reviewer =*/}
                      {/*          val?.map((e: any) => ({*/}
                      {/*            approver: e?.user?.id,*/}
                      {/*            category: "reviewer",*/}
                      {/*            caiseApplicationId: info?.id,*/}
                      {/*          })) ??*/}
                      {/*          info.reviewer ??*/}
                      {/*          [];*/}
                      {/*        setData({*/}
                      {/*          ...newData,*/}
                      {/*          reviewer,*/}
                      {/*        });*/}
                      {/*      }}*/}
                      {/*      allBranch={true}*/}
                      {/*    />*/}
                      {/*  </div>*/}
                      {/*)}*/}
                      <div className={"float-end"}>
                        <button
                          type={"button"}
                          onClick={() => {
                            setOpenDialogue(false);
                          }}
                          className={"btn btn-secondary me-1"}
                        >
                          <i className={"bi bi-x-lg"}></i> Cancel
                        </button>
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <button type={"submit"} className={"btn btn-primary"}>
                            <i className={"bi bi-send-fill"}></i> Submit
                          </button>
                        )}
                      </div>
                    </form>
                  </SmallDialog>
                ) : null}
                {openDialogue ? (
                  <SmallDialog
                    open={confirm}
                    setOpen={setConfirm}
                    onSubmit={() => {
                      onSubmit(newData);
                    }}
                  >
                    <div className="d-flex ">
                      <CheckCircleIcon color="success" sx={{ fontSize: 50 }} />
                      <div className="mt-4">
                        {"Are you sure you want to submit?"}
                      </div>
                    </div>
                  </SmallDialog>
                ) : null}
              </CardContent>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationDetails);
