import React, { useEffect } from "react";
import { AddForm } from "../../employee/AddForm";
import moment from "moment";
import { Alert, AlertTitle } from "@mui/material";

const UpdateLeave = (props: any) => {
  const { leaveInfo, setUpdateLeave, onSuccess, updateLeave } = props;
  const [startDate, setStartDate] = React.useState<any>();
  const [movedToStartDate, setMovedToStartDate] = React.useState<any>();
  const [endDate, setEndDate] = React.useState<any>();
  const [movedToEndDate, setMovedToEndDate] = React.useState<any>();
  useEffect(() => {
    if (leaveInfo?.status !== "canceled") {
      setStartDate(moment(leaveInfo?.startDate).format("YYYY-MM-DD"));
      setEndDate(moment(leaveInfo?.endDate).format("YYYY-MM-DD"));
    }
    if (leaveInfo?.status === "canceled") {
      setMovedToStartDate(
        moment(leaveInfo?.movedToStartDate).format("YYYY-MM-DD")
      );
      setMovedToEndDate(moment(leaveInfo?.movedToEndDate).format("YYYY-MM-DD"));
    }
  }, [leaveInfo]);

  return (
    <>
      {leaveInfo?.status === "canceled" ? (
        <AddForm
          title={
            leaveInfo?.status === "canceled"
              ? "Resume My Leave"
              : "Update Leave"
          }
          maxWidth="lg"
          mainFields={[
            {
              title: "",
              fields: [
                // {
                //   name: "requestType",
                //   required: true,
                //   col: 6,
                //   render: () => {
                //     return (
                //       <div className={"row "}>
                //         <div className="col-md-12 mb-4 p-3">
                //           <Alert severity="warning">
                //             <AlertTitle>Warning</AlertTitle>
                //             <strong>
                //               {
                //                 "The postponed leave is taken not later than 31st December of the following fiscal year."
                //               }
                //             </strong>
                //           </Alert>
                //         </div>
                //       </div>
                //     );
                //   },
                // },
                {
                  label: "Start Date",
                  name: "movedToStartDate",
                  type: "date",
                  setValue: setMovedToStartDate,
                  required: true,
                  col: 12,
                  value: movedToStartDate,
                  minDate: moment().startOf("day"),
                },
                {
                  label: "End Date",
                  name: "movedToEndDate",
                  setValue: setMovedToEndDate,
                  type: "date",
                  value: movedToEndDate,
                  required: true,
                  col: 12,
                  minDate: moment(movedToStartDate).startOf("day"),
                  maxDate: moment().endOf("year"),
                },
              ],
            },
          ]}
          url={`/leave/update/leavePlan/${leaveInfo?.id}`}
          method={"PUT"}
          onSuccess={onSuccess}
          open={updateLeave}
          setOpen={() => setUpdateLeave(0)}
        />
      ) : (
        <AddForm
          title={
            leaveInfo?.status === "canceled"
              ? "Resume My Leave"
              : "Update Leave"
          }
          maxWidth="lg"
          mainFields={[
            {
              title: "",
              fields: [
                {
                  label: "Start Date",
                  name: "startDate",
                  type: "date",
                  setValue: setStartDate,
                  required: true,
                  col: 12,
                  value: startDate,
                  minDate: moment().startOf("day"),
                },
                {
                  label: "End Date",
                  name: "endDate",
                  setValue: setEndDate,
                  type: "date",
                  value: endDate,
                  required: true,
                  col: 12,
                  minDate: moment(startDate).startOf("day"),
                  maxDate: moment(leaveInfo?.startDate).endOf("year"),
                },
              ],
            },
          ]}
          url={`/leave/update/leavePlan/${leaveInfo?.id}`}
          method={"PUT"}
          onSuccess={onSuccess}
          open={updateLeave}
          setOpen={() => setUpdateLeave(0)}
        />
      )}
    </>
  );
};
export default UpdateLeave;
