export const mapStateToProps = (state: {
  generalData:any
  user:any
}) => {
  return {
    loggedUser:state.user.loggedUser,
    cdeInfo:state.user.cdeInfo,
    socket:state.user.socket

  };
};
