import { useNavigate, useParams } from "react-router-dom";
import {
  CheckHasAnyPermission,
  fetchDetails,
  getError,
  getFileMime,
  monthList,
  submitGeneralForm,
} from "../../../services/common";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import PageLoading from "../../../components/PageLoading";
import { Card, Col, Row } from "react-bootstrap";
import {
  Alert,
  Button,
  CardContent,
  CardHeader,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import StatusComponent from "../../../components/StatusComponent";
import GeneralButton from "../../../components/Button";
import {
  Check,
  BorderColorOutlined,
 
  Info,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import OpenDocuments from "../../../components/openDocument";
import RequestHistoryProcess from "../../../components/RequestHistory";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/helpers/mapState";
import { mapDispatchToProps } from "../../../store/helpers/mapDispatch";
import SmallDialog from "../../../components/SmallModel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";

const ContributionDetails = (props: any) => {
  const { loggedUser } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [info, setInfo] = useState<any>();
  const [error, setError] = useState<any>();
  const [confirm, setConfirm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDialogue, setOpenDialogue] = useState<boolean>(false);
  const [newData, setData] = useState<any>();
  // const [others, setOthers] = useState<Array<any>>([]);
  const [viewDocument, setViewDocument] = useState(false);

  const loadInfo = async () => {
    const resp = await fetchDetails(`contribution/view/${id}`);
    if (resp.status) {
      setInfo(resp.data);
      // setOthers(resp.data.others);
    } else {
      setError(getError(resp));
    }
  };

  useEffect(() => {
    if (loggedUser?.id === info?.approverId && info?.status === "pending") {
      onSubmit({
        id: info?.id,
        status: "under review",
        comment: "request is under review",
      });
    }
  }, [info?.id, info?.status]);

  const onSubmit = async (data?: any) => {
    setLoading(true);
    setConfirm(false);
    let infoToSave = data ?? newData;
    const resp = await submitGeneralForm(
      `/approvalContribution/change-status/${id}`,
      infoToSave,
      null,
      "PUT"
    );
    if (resp.status) {
      setData({});
      setError(null);
      setOpenDialogue(false);
      loadInfo().then();
    } else {
      setError(getError(resp));
    }
    setLoading(false);
  };

  useEffect(() => {
    loadInfo().then();
  }, [id]);
  const employeeId=info?.employeeId??info?.CaisseMember?.employee?.id
  const isInitiator = loggedUser?.employeeId === employeeId;
  const suspensionDate = loggedUser?.Employee?.CaisseMember?.contributionSuspensionDate;
  const isApprover =
    loggedUser?.id === info?.approverId &&
    (info?.status === "under review" ||
      info?.status === "forwarded" ||
      info?.status === "pending");
  const canUpdate = isInitiator && info?.status === "request action";

  const canApprove = CheckHasAnyPermission("approval-saving");
  const sortedPreviousPayment = info?.parentContribution?.sort(
    (a: any, b: any) => a.month - b.month
  );

  return (
    <div>
      <Breadcrumb />
      {!info ? (
        <PageLoading error={error} />
      ) : (
        <Row className={" p-3 "}>
          <Col className={"mb-4"} md={12}>
            <Card className={"bg-light shadow-sm"}>
              <CardHeader
                subheader={
                  <h4 className="fw-bold">{"Contribution Details"}</h4>
                }
                action={<StatusComponent status={info?.status} badge={true} />}
              />
              <CardContent>
                <div className={"row justify-content-end"}>
                  {info?.status !== "approved" &&
                  info?.status !== "rejected" ? (
                    <div className="col-md-8 fw-bold d-flex justify-content-end">
                      {canUpdate ? (
                        <Button
                          className="btn-primary me-2"
                          size="small"
                          variant="outlined"
                          startIcon={<BorderColorOutlined />}
                          onClick={() => {
                            navigate(
                              `/console/saving/contributions/update/${info?.id}`
                            );
                          }}
                        >
                          Update
                        </Button>
                      ) : null}
                      {canApprove && isApprover ? (
                        <>
                          <Button
                            className="me-1"
                            color="warning"
                            size="small"
                            variant="outlined"
                            startIcon={<Info />}
                            onClick={() => {
                              setOpenDialogue(true);
                              setData({
                                id: info?.id,
                                status: "request action",
                              });
                            }}
                          >
                            Request for action
                          </Button>
                          {/* <Button
                            className="me-2"
                            color="error"
                            size="small"
                            variant="outlined"
                            startIcon={<HighlightOff />}
                            onClick={() => {
                              setOpenDialogue(true);
                              setData({ id: info?.id, status: "rejected" });
                            }}
                          >
                            Reject
                          </Button> */}
                          {/* {!isReviewer && ( */}
                          <Button
                            className="me-2"
                            color="success"
                            size="small"
                            disabled={!canApprove}
                            variant="outlined"
                            startIcon={<Check />}
                            onClick={() => {
                              setOpenDialogue(true);
                              setData({ id: info?.id, status: "approved" });
                            }}
                          >
                            Approve
                          </Button>
                          {/* )} */}
                        </>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                <h6>Employee Details</h6>
                <Row className={"mb-3"}>
                  <Col md={4}>
                    <div>Names</div>
                    <div className={"fw-bold"}>
                      {info?.CaisseMember?.employee?.names ?? "---"}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>Phone Number</div>
                    <div className={"fw-bold"}>
                      {info?.CaisseMember?.employee?.phone ?? "---"}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>Email</div>
                    <div className={"fw-bold"}>
                      {info?.CaisseMember?.employee?.email ?? "---"}
                    </div>
                  </Col>
                
                </Row>
                <Row className={"mb-3"}>
                <Col md={4}>
                    <div>Monthly Contribution</div>
                    <div className={"fw-bold"}>
                      {info?.CaisseMember?.share?.toLocaleString() ??
                        "---"}{" "}
                      RWF
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>Total Shares</div>
                    <div className={"fw-bold"}>
                      {info?.CaisseMember?.totalShare?.toLocaleString() ??
                        "---"}{" "}
                      RWF
                    </div>
                  </Col>
                </Row>
                <h6>
                  {info?.previousUnpaidMonths
                    ? "Bulk Payment Details"
                    : "Payment Details"}
                </h6>
                <Card>
                  <div className={"card-body"}>
                    <Row className={"mb-3"}>
                      <Col md={4}>
                        <div>Month</div>
                        <div className={"fw-bold"}>
                          {monthList.find((v) => v.id === info?.month)?.name ??
                            "---"}
                        </div>
                      </Col>
                      <Col md={4} className={"mb-3"}>
                        <div>Amount</div>
                        <div className={"fw-bold"}>
                          {info?.amount?.toLocaleString() ?? "---"}
                        </div>
                      </Col>
                      <Col md={4}>
                        <div>Penalties</div>
                        <div className={"fw-bold"}>
                          {info?.penalty?.toLocaleString() ?? "---"}
                        </div>
                      </Col>
                      {info?.previousUnpaidMonths ? (
                        <Col md={4}>
                          <div>Previous Unpaid Months</div>
                          <div className={"fw-bold"}>
                            {info?.previousUnpaidMonths ?? "---"}
                          </div>
                        </Col>
                      ) : null}
                      {/* {info?.prePaidMonths ? (
                        <Col md={4}>
                          <div>Pre Paid Months</div>
                          <div className={"fw-bold"}>
                            {info?.prePaidMonths ?? "---"}
                          </div>
                        </Col>
                      ) : null} */}
                          {info?.prePaidMonths ? (
                        <Col md={6}>
                          <div>Pre Paid Months</div>
                          <div className={"fw-bold"}>
                          {`(${info?.prePaidMonths})-`} {info?.bulkMonths ?? "---"}
                          </div>
                        </Col>
                      ) : null}
                        {suspensionDate ? (
                        <Col md={2}>
                          <div>End Date</div>
                          <div className={"fw-bold"}>
                            {moment(suspensionDate).format('DD/MM/YYYY')}
                          </div>
                        </Col>
                      ) : null}
                    </Row>
                    {info?.previousUnpaidMonths?
                    <>
                      <h6 className={"fw-bold"}>
                      Previous Unpaid Installment:{info?.previousUnpaidMonths}
                    </h6>
                    <TableContainer className="border">
                      <Table>
                        <TableHead>
                          <TableRow className="fw-bold">
                            <TableCell className="fw-bold">Month</TableCell>
                            <TableCell className="fw-bold">
                              Amount(RWF)
                            </TableCell>
                            <TableCell className="fw-bold">Penalty</TableCell>
                            <TableCell className="fw-bold">
                              Created Date
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sortedPreviousPayment?.map((item: any) => (
                            <TableRow>
                              <TableCell>
                                {
                                  monthList.find((v) => v.id === item?.month)
                                    ?.name
                                }
                              </TableCell>
                              <TableCell>
                                {item?.amount?.toLocaleString()}
                              </TableCell>
                              <TableCell>{item?.penalty}</TableCell>
                              <TableCell>
                                {moment(item?.createdAt).format("DD/MM/YYYY")}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    </>
                    :null}
                  
                  </div>
                </Card>

                <Row className={"mt-2"}>
                  <Col md={4}>
                    <div>Reference Number</div>
                    <div className={"fw-bold"}>
                      {info?.referenceNumber ?? "---"}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>Fiscal Year</div>
                    <div className={"fw-bold"}>
                      {info?.fiscal?.name ?? "---"}
                    </div>
                  </Col>
                  {info?.supportDocument ? (
                    <Col md={4}>
                      <div>Support document</div>

                      <div className="row mb-4">
                        <div className="col-md-12">
                          <div className="input-group mb-3">
                            <Typography style={{fontFamily:'Inter'}} className="mb-3 fw-bold me-3">
                              Supporting Document
                            </Typography>
                            <GeneralButton
                              variant="outlined"
                              icon={
                                viewDocument ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )
                              }
                              onClick={() => {
                                setViewDocument(!viewDocument);
                              }}
                              name={"View Document"}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  ) : null}
                </Row>
                {info?.supportDocument && viewDocument ? (
                  <div className={"row justify-content-center"}>
                    <div className={"col-md-8"}>
                      {info.supportDocument.map((v: string) => (
                        <OpenDocuments
                          downloadable={true}
                          card={true}
                          name={v}
                          document={v}
                          mimeType={getFileMime(v)}
                        />
                      ))}
                    </div>
                  </div>
                ) : null}

                <hr />
                <div className="row">
                  <Typography style={{fontFamily:'Inter'}} className="col-md-12 mb-3 fw-bold">
                    Request History
                  </Typography>
                  <div className="col-md-12">
                    <RequestHistoryProcess
                      RequestProcesses={info?.RequestProcesses}
                    />
                  </div>
                </div>

                {openDialogue ? (
                  <SmallDialog
                    maxWidth="md"
                    title={
                      newData?.status === "request action"
                        ? "Request Action"
                        : newData?.status === "approved"
                        ? "Approve Contribution"
                        : "Reject Contribution"
                    }
                    buttonTitle="Submit"
                    needButton={true}
                    open={openDialogue}
                    setOpen={setOpenDialogue}
                  >
                    {error ? (
                      <Alert
                        style={{ width: "100%" }}
                        className={"mb-3"}
                        severity={"error"}
                        onClose={() => setError(null)}
                      >
                        {error}
                      </Alert>
                    ) : null}
                    <form
                      onSubmit={(e: any) => {
                        e.preventDefault();
                        setConfirm(true);
                      }}
                    >
                      {newData?.report ? (
                        <div>
                          <label className="col-form-label text-capitalize">
                            Other Fees(RWF)
                          </label>
                          <input
                            className="form-control mb-2"
                            type="number"
                            value={newData.otherFee}
                            onChange={(e) => {
                              setData({
                                ...newData,
                                otherFee: e.currentTarget.value,
                              });
                            }}
                          />
                          {newData.otherFee ? (
                            <>
                              <label className="col-form-label text-capitalize">
                                Explain RWF of Other Fees
                              </label>
                              <textarea
                                placeholder="Type here..."
                                className="form-control form-control mb-3"
                                required={true}
                                value={newData?.otherFeeDescription}
                                rows={5}
                                onChange={(e: any) => {
                                  setData({
                                    ...newData,
                                    otherFeeDescription: e.currentTarget.value,
                                  });
                                }}
                              />
                            </>
                          ) : null}
                        </div>
                      ) : (
                        <div>
                          <label className="col-form-label text-capitalize">
                            Comment
                          </label>
                          <textarea
                            placeholder="Type here..."
                            className="form-control form-control mb-3"
                            required={true}
                            value={newData?.comment}
                            rows={5}
                            onChange={(e: any) => {
                              setData({
                                ...newData,
                                comment: e.currentTarget.value,
                              });
                            }}
                          />
                        </div>
                      )}
                      <div className={"float-end"}>
                        <button
                          type={"button"}
                          onClick={() => {
                            setOpenDialogue(false);
                          }}
                          className={"btn btn-secondary me-1"}
                        >
                          <i className={"bi bi-x-lg"}></i> Cancel
                        </button>
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <button type={"submit"} className={"btn btn-primary"}>
                            <i className={"bi bi-send-fill"}></i> Submit
                          </button>
                        )}
                      </div>
                    </form>
                  </SmallDialog>
                ) : null}
                {openDialogue ? (
                  <SmallDialog
                    open={confirm}
                    setOpen={setConfirm}
                    onSubmit={() => {
                      onSubmit(newData);
                    }}
                  >
                    <div className="d-flex ">
                      <CheckCircleIcon color="success" sx={{ fontSize: 50 }} />
                      <div className="mt-4">
                        {"Are you sure you want to submit?"}
                      </div>
                    </div>
                  </SmallDialog>
                ) : null}
              </CardContent>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContributionDetails);
