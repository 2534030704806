import { makeStyles} from "@material-ui/core";

const drawerWidth = 244;
const useStyles = makeStyles((theme) => ({
  // root: {
  //   display: "flex",
  // },
  // appBar: {
  //   zIndex: theme.zIndex.drawer + 1,
  // },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  // menuButton: {
  //   marginLeft: drawerWidth+100,
  // },
  // hide: {
  //   display: "none",
  // },
  // drawer: {
  //   width: drawerWidth,
  //   flexShrink: 0,
  //   whiteSpace: "nowrap",
    
  // },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  // toolbar: {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "flex-end",
  //   padding: theme.spacing(0, 1),
  //   ...theme.mixins.toolbar,
  // },
  drawerHeader:{
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),

  },
  logo: {
    flexGrow: 1,
    padding: theme.spacing(0, 1),
    marginLeft: theme.spacing(6),
    color:"white"
  },
  subList: {
    width: "100%",
    maxWidth: 360,
    // height: 1000,
    maxHeight: 1400,
    // display:"flex-block",
  },
  icon: {
    color: "#198754",
  },
  profile: {
    fontSize: 38,
  },
  sideBarProfile: {
    flexGrow: 1,
    padding: theme.spacing(0, 4),
    marginLeft: theme.spacing(6),
    marginTop: theme.spacing(4),

  },
  sideBarProfileText2:{
    fontSize: 12,
    color:"#ced4da"
  },
  sideBarProfileText:{
    fontSize: 13,
    color:"#ffffff",
  },
  menuItem:{
    fontSize: 12,
    color:"#ffffff",
    fontWeight:'bolder'

  },
  blueNav: {
    // flexGrow: 1,
    padding: theme.spacing(1),
    color:"#F4F5F9",
    marginLeft: drawerWidth,
    display: 'flex',
    },
  root:{
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },
    // width: `calc(100% - ${drawerWidth}px)`,

  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor:'#E8EFF4',
    // width: `calc(100% - ${drawerWidth}px)`,

    // width: `calc(100% - ${drawerWidth}px)`,

  },
  contentAll: {
    [theme.breakpoints.up("sm")]: {
      // marginLeft: drawerWidth,
      // width: `calc(100% - ${drawerWidth}px)`
      width: `10px`

    }
  },
}));

export default useStyles;
