import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import {
  Close,
  Notifications,
  NotificationsNone,
  Search,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Badge, Button, Chip, Tooltip, styled } from "@mui/material";
import moment from "moment";
import useStyles from "../layouts/style";
import useAuth from "../hooks/useAuth";
import {
  downloadBase64,
  getFileMime,
  submitGeneralForm,
} from "../services/common";
import { useNavigate } from "react-router-dom";
import AccountMenu from "./SettingsMenu";
import BlueNavBar from "../layouts/BlueNavBar";
import MoreIcon from "@mui/icons-material/MoreVert";
import SearchDialog from "./UI/SearchModal";

const NavigationBar = (props: any) => {
  const {
    handleDrawerOpen,
    loggedUser,
    socket,
    GetLoggedUser,
    setShowBlueNav,
  } = props;
  const classes = useStyles();
  const { logout } = useAuth();
  const [data, setData] = useState<any>();
  const [search, setSearch] = useState<any>();
  const [openNav, setOpenNav] = useState<any>(false);
  const [openSearch, setOpenSearch] = useState<any>(false);

  
  const profile = loggedUser?.Employee?.profile; //profile image

  const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    alignItems: "flex-start",
    paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(2),
    // Override media queries injected by theme.mixins.toolbar
    "@media all": {
      minHeight: 18,
      maxHeight: 48,
    },
  }));
  const downloadData = async () => {
    const res = await downloadBase64(
      profile,
      profile,
      "",
      getFileMime(profile)
    );
    if (res) {
      setData(res);
    }
  };

  useEffect(() => {
    if (profile) {
      downloadData().then();
    }
  }, [profile]);

  const keyUpHandler = () => {
    const tempWindow: any = window;
    tempWindow.find(search);
  };
  const [notification, setNotifications] = useState<any[]>([]);
  // const [anchorElNotification, setAnchorElNotification] =
  //   useState<null | HTMLElement>(null);
  const [anchorElNotification, setAnchorElNotification] =
  useState(false);
  const notifications = loggedUser?.Notification?.NotificationContents;
  const navigate = useNavigate();

  const handleRead = () => {
    setNotifications([]);
    // setAnchorElNotification(null);
    setAnchorElNotification(false);

    markAsRead("All", null);
  };

  const handleOpenNotifications = (event: React.MouseEvent<HTMLElement>) => {
    // setAnchorElNotification(event.currentTarget);
    setAnchorElNotification(true);

  };

  useEffect(() => {
    socket?.on("getNotification", (data: any) => {
      setNotifications((prev: any) => [...prev, data.data]);
    });
    socket?.on("logoutPrevious", (data: any) => {
      logout();
    });
  }, [socket]);

  useEffect(() => {
    const noti = notifications
      ? notifications?.sort(
          (a: any, b: any) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      : [];
    setNotifications(noti);
  }, [notifications]);

  const markAsRead = async (notificationId: string, route: any) => {
    const resp = await submitGeneralForm(
      `/notification/update/${notificationId}`,
      {},
      null,
      "PUT"
    );
    setNotifications(notification.filter((v: any) => v.id !== notificationId));
    if (resp.status && route !== null) {
      navigate(route);
    }
    await GetLoggedUser(loggedUser?.id);
    setAnchorElNotification(false);
  };

  const displayNotification = (item: any) => {
    let message, tab: any;
    let route = "";
    if (item?.content.type === "serviceRequest") {
      message = item.content.message;
      route =
        item.content.tag === "requester"
          ? `/console/employee/requests/details/${item.content.id}`
          : `/console/approvals/requests/details/${item.content.id}`;
    } else if (item?.content.type === "transfer") {
      message = item.content.message;
      route =
        item.content.tag === "requester"
          ? `/console/employee/details/${item.content.id}`
          : `/console/approvals/transfers/details/${item.content.id}`;
    } else if (item?.content.type === "mission") {
      message = item.content.message;
      route =
        item.content.tag === "requester"
          ? `/console/employee/missions/details/${item.content.id}`
          : `/console/approvals/missions/details/${item.content.id}`;
    } else if (item?.content.type === "leave") {
      message = item.content.message;
      route =
        item.content.tag === "requester"
          ? `/console/employee/leaves/details/${item.content.id}`
          : `/console/approvals/leaves/details/${item.content.id}`;
    } else if (item?.content.type === "leave") {
      message = item.content.message;
      route =
        item.content.tag === "requester"
          ? `/console/employee/leaves/details/${item.content.id}`
          : `/console/approveSaving/funds/details/${item.content.id}`;
    } else if (item?.content.type === "increment") {
      message = item.content.message;
      route =
        item.content.tag === "requester"
          ? `/console/saving/increment/details/${item.content.id}`
          : `/console/approveSaving/increments/details/${item.content.id}`;
    } else if (item?.content.type === "penaltyPayment") {
      message = item.content.message;
      route =
        item.content.tag === "requester"
          ? `/console/saving/penalties/payment/details/${item.content.id}`
          : `/console/approveSaving/penaltyPayments/details/${item.content.id}`;
    } else if (item?.content.type === "exit") {
      message = item.content.message;
      route =
        item.content.tag === "requester"
          ? `/console/saving/exit/request`
          : `/console/approveSaving/exit/details/${item.content.id}`;
    } else if (item?.content.type === "contribution") {
      message = item.content.message;
      route =
        item.content.tag === "requester"
          ? `/console/saving/contributions/details/${item.content.id}`
          : `/console/approveSaving/contributions/details/${item.content.id}`;
    } else if (item?.content.type === "loan") {
      message = item.content.message;
      route =
        item.content.tag === "requester"
          ? `/console/saving/loans/details/${item.content.id}`
          : `/console/approveSaving/loans/details/${item.content.id}`;
    } else if (item?.content.type === "application") {
      message = item.content.message;
      route =
        item.content.tag === "requester"
          ? `/console/saving`
          : `/console/approveSaving/applications/details/${item.content.id}`;
    } else if (item?.content.type === "fund") {
      message = item.content.message;
      route =
        item.content.tag === "requester"
          ? `/console/saving/funds/details/${item.content.id}`
          : `/console/approveSaving/funds/details/${item.content.id}`;
    } else {
      message = item.content.message;
      route = "/";
    }

    return (
      <MenuItem
        onClick={() => {
          markAsRead(item.id, route);
        }}
        title={message}
      >
        <div className="notification d-flex w-100">
          <div className={"flex-grow-1"}>{`${item.content.title}`} </div>
          <Chip
            size={"small"}
            className={"ml-2"}
            label={moment(item.createdAt).format("YYYY-MMM-DD HH:mm")}
          />
        </div>
      </MenuItem>
    );
  };

  const renderMenu = (
    <>
      <Tooltip title={"Notifications"}>
        <IconButton
          aria-label="show 17 new notifications"
          aria-haspopup="true"
          onClick={handleOpenNotifications}
        >
          {/* <Badge badgeContent={notification?.length} color="warning" max={20}>
              <NotificationsActive className="text-white" />
            </Badge> */}

          <Badge
            badgeContent={notification?.length}
            color="warning"
            max={20}
            // color="warning"
            // max={20}
            // variant={notification?.length ? "dot" : "standard"}
            // overlap="circular"
          >
            {notification?.length ? (
              <Notifications className="text-white" />
            ) : (
              <NotificationsNone className="text-white" />
            )}
          </Badge>
        </IconButton>
      </Tooltip>
      <AccountMenu loggedUser={loggedUser} showProfile={openNav} />
      {notification?.length ? (
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          // anchorEl={anchorElNotification}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          // Boolean(anchorElNotification)
          open={anchorElNotification}
          onClose={() => {
            setAnchorElNotification(false);
          }}
        >
          {notification?.map((item: any, key: number) => (
            <span key={key}>{displayNotification(item)}</span>
          ))}

          <MenuItem onClick={handleRead}>
            <Button className="nButton">Mark as read</Button>
          </MenuItem>
        </Menu>
      ) : null}
    </>
  );
  return (
    <AppBar
      position="fixed"
      className={`${classes.appBar} shadow-sm border-bottom`}
    >
      <StyledToolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerOpen}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>

        <Box sx={{ flexGrow: 2, display: { xs: "none", md: "flex" } }} />

        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          <div className="input-group w-70 rounded background">
            <input
              type="text"
              className="mt-1 form-control  border-0 bg-transparent text-white placeholder"
              placeholder={"Search here ..."}
              onChange={(e: any) => {
                setSearch(e.target.value);
                
              }}
              value={search}
              // onClick={()=>{setOpenSearch(true)}}
            />
            <span
              className="input-group-text border-0 bg-transparent"
              onClick={keyUpHandler}
              role="button"
            >
              <Search className="text-white" />
            </span>
          </div>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
        <Box sx={{ display: { xs: "none", md: "flex" } }}>{renderMenu}</Box>
        <Box
          sx={{
            flexGrow: 1,
            alignSelf: "flex-end",
            display: { xs: "flex", md: "none" },
            marginTop: 5,
          }}
        />
        <Box sx={{ display: { xs: "flex", md: "none" } }}>{renderMenu}</Box>

        <IconButton
          size="large"
          aria-label="display more actions"
          edge="end"
          color="inherit"
          sx={{ display: { xs: "flex", md: "none" } }}
          onClick={() => {
            setOpenNav(!openNav);
            setShowBlueNav(false);
          }}
        >
          {openNav ? <Close /> : <MoreIcon />}
        </IconButton>
      </StyledToolbar>
      <Toolbar>
        <Box
          sx={{
            alignSelf: "flex-end",
            display: { xs: "none", md: "flex" },
            marginTop: 5,
          }}
        >
          {" "}
          <BlueNavBar setShowBlueNav={setShowBlueNav} />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            // display: { xs: "flex", md: "none" },
            marginTop: 5,
          }}
        >
          {openNav ? <BlueNavBar setShowBlueNav={setShowBlueNav} /> : null}
        </Box>
      </Toolbar>
      <SearchDialog open={openSearch} setOpen={setOpenSearch} />
    </AppBar>
  );
};
export default NavigationBar;
