import {ERRORS, GET_CDE_ACTIVE_INFO, GET_USER, SET_SOCKET, UserType} from "./type";


const initialState = {
    loggedUser:{},
    socket:null,
    errorMessage: null,
    cdeInfo: null,
    successMessage: null,
  };
  
  export const userReducer = (
    state = initialState,
    { type, payload }: UserType
  ) => {
    switch (type) {
      case ERRORS:
        return { ...state, errorMessage: payload, successMessage: null };
      case GET_CDE_ACTIVE_INFO:
        return { ...state, cdeInfo: payload };
      case GET_USER:
        return {
          ...state,
          loggedUser: payload,
          successMessage: null,
          errorMessage: null,
        };
        case SET_SOCKET:
          return {
            ...state,
            socket: payload,
          };
      default:
        return state;
    }
  };
  