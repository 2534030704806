import React from "react";
import AllAttendanceList from "../../attendance/lists/AllAttendanceList";

function AttendanceReportIndex() {
  return (
    <div>
      <AllAttendanceList report={true} />
    </div>
  );
}

export default AttendanceReportIndex;
