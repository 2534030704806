import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { ExpandMore, BorderColorOutlined } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import { fetchDetails, getFileMime } from "../../../services/common";
import { useEffect, useState } from "react";
import GeneralButton from "../../../components/Button";
import OpenDocuments from "../../../components/openDocument";
import { LinearProgress } from "@material-ui/core";

const SettingsList = () => {
  const [setting, setSettings] = useState<any>();
  const [showGuide, setShowGuide] = useState(false);

  const fetchSettingDetails = async () => {
    const resp = await fetchDetails(`settings/view/`);
    if (resp.status) {
      setSettings(resp.data);
    }
  };
  useEffect(() => {
    fetchSettingDetails().then();
  }, []);
  const navigate = useNavigate();

  return (
    <div>
      <Breadcrumb />
      {!setting?.id?<LinearProgress/>:null}
      <Card>
        <CardHeader
          style={{ fontFamily: "inter" }}
          subheader="General Settings"
          action={
            <GeneralButton
              onClick={() => navigate(`update/${setting?.id}`)}
              icon={<BorderColorOutlined />}
              name={"Update"}
              size={"small"}
            />
          }
        />
        <CardContent>
          {/* <Accordion expanded className="shadow-sm">
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography style={{ fontFamily: "inter" }}>
                Leave plan
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="row">
              <span className="col-md-4">
                Start Date:{" "}
                {moment(setting?.startLeavePlanDate).format("DD/MM/YYYY")}
              </span>
              <span className="col-md-4">
                End Date:{" "}
                {moment(setting?.endLeavePlanDate).format("DD/MM/YYYY")}
              </span>
            </AccordionDetails>
          </Accordion> */}
          <Accordion expanded className="shadow-sm">
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography style={{ fontFamily: "inter" }}>
                Annual Leave days{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="row">
              <span className="col-md-4">
                Under Statute: {setting?.underStatute}
              </span>
              <span className="col-md-4">
                Contractual: {setting?.contractual}
              </span>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded className="shadow-sm">
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography style={{ fontFamily: "inter" }}>
                Work hours
              </Typography>
              {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
            </AccordionSummary>
            <AccordionDetails className="row">
              <span className="col-md-4">Start: {setting?.workStartTime}</span>
              <span className="col-md-4">End: {setting?.workEndTime}</span>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={showGuide} className="shadow-sm" onClick={()=>{setShowGuide(!showGuide)}}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography style={{ fontFamily: "inter" }}>
                User Guide
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="row">
              {setting?.userManual?.map((v: string) => (
                <OpenDocuments
                  downloadable={true}
                  card={true}
                  document={v}
                  mimeType={getFileMime(v)}
                />
              ))}
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
      {/* <DatatableComponent
        columns={[
          {
            label: "Start Leave Plan",
            name: "startLeavePlanDate",
            options: {
              customBodyRender: (v: any) =>
                moment(v).format("YYYY-MMM-DD HH:mm"),
            },
          },
          {
            label: "End Leave Plan",
            name: "endLeavePlanDate",
            options: {
              customBodyRender: (v: any) =>
                moment(v).format("YYYY-MMM-DD HH:mm"),
            },
          },
          {
            label: "Work Start At",
            name: "workStartTime",
            // options: {
            //   customBodyRender: (v: any) =>
            //     moment(v).format("YYYY-MMM-DD HH:mm"),
            // },
          },
          {
            label: "Work End At",
            name: "workEndTime",
            // options: {
            //   customBodyRender: (v: any) =>
            //     moment(v).format("YYYY-MMM-DD HH:mm"),
            // },
          },
          {
            label: "Last Updated At",
            name: "updatedAt",
            options: {
              customBodyRender: (v: any) =>
                moment(v).format("YYYY-MMM-DD HH:mm"),
            },
          },
        ]}
        url={"settings/datatable"}
        title={"Settings"}
        actions={(id) => [
        //   <IconButton onClick={() => navigate(`update/${id}`)}>
        //     <Update />
        //   </IconButton>,
        <button title="update" className={"border-0 bg-transparent"}>
        <Update onClick={() => navigate(`update/${id}`)} fontSize="small" />
      </button>,
        ]}
      /> */}
    </div>
  );
};

export default SettingsList;
