// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-size: .875rem;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/* Navbar */

.nav-avatar {
  width: 32px;
  height: 32px;
}

/* Sidebar */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

@media (max-width: 767.98px) {
  .sidebar {
    /*top: 5rem;*/
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 900;
  color: white;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: white;

  /* color: #727272; */
}

.sidebar .nav-link.active {
  color: white;
  /* background-color: #054D6F; */
  /* border-radius: 50rem; */
}

/* .sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
} */

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}
.sidebarBackground{
  color: white;
  background-color: #054D6F;
}
`, "",{"version":3,"sources":["webpack://./src/components/sidebar.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,2BAA2B;AAC7B;;AAEA,WAAW;;AAEX;EACE,WAAW;EACX,YAAY;AACd;;AAEA,YAAY;;AAEZ;EACE,eAAe;EACf,MAAM;EACN,SAAS;EACT,OAAO;EACP,YAAY;EACZ,iBAAiB;EACjB,4CAA4C;AAC9C;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,0BAA0B;EAC1B,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB,EAAE,6DAA6D;AACjF;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,YAAY;;EAEZ,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,+BAA+B;EAC/B,0BAA0B;AAC5B;;AAEA;;;GAGG;;AAEH;EACE,iBAAiB;EACjB,yBAAyB;AAC3B;AACA;EACE,YAAY;EACZ,yBAAyB;AAC3B","sourcesContent":["body {\n  font-size: .875rem;\n}\n\n.feather {\n  width: 16px;\n  height: 16px;\n  vertical-align: text-bottom;\n}\n\n/* Navbar */\n\n.nav-avatar {\n  width: 32px;\n  height: 32px;\n}\n\n/* Sidebar */\n\n.sidebar {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 100;\n  padding: 48px 0 0;\n  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);\n}\n\n@media (max-width: 767.98px) {\n  .sidebar {\n    /*top: 5rem;*/\n  }\n}\n\n.sidebar-sticky {\n  position: relative;\n  top: 0;\n  height: calc(100vh - 48px);\n  padding-top: .5rem;\n  overflow-x: hidden;\n  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */\n}\n\n.sidebar .nav-link {\n  font-weight: 900;\n  color: white;\n}\n\n.sidebar .nav-link .feather {\n  margin-right: 4px;\n  color: white;\n\n  /* color: #727272; */\n}\n\n.sidebar .nav-link.active {\n  color: white;\n  /* background-color: #054D6F; */\n  /* border-radius: 50rem; */\n}\n\n/* .sidebar .nav-link:hover .feather,\n.sidebar .nav-link.active .feather {\n  color: inherit;\n} */\n\n.sidebar-heading {\n  font-size: .75rem;\n  text-transform: uppercase;\n}\n.sidebarBackground{\n  color: white;\n  background-color: #054D6F;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
