import React, { useMemo } from "react";
import { default as ReactSelect } from "react-select";
import countryList from "react-select-country-list";

type IProps = {
  value?: any;
  className?: any;
  name: string;
  onChange: (value: any) => void;
};

function CountrySelector(props: IProps) {
  const options = useMemo(() => countryList().getData(), []);

  const option: any = options.map((v: any) => ({
    label: v.label,
    id: v.label,
  }));

  return (
    <ReactSelect
      {...props}
      options={option}
      value={
        typeof props.value === "string"
          ? option.find((v: any) => v.id === props.value)
          : props.value
      }
    />
  );
}

export default CountrySelector;
