import { Link, useLocation, useParams } from "react-router-dom";

type AddButtonType = { label: string; onClick: (e: any) => void };

type IProps = {
  addButton?: AddButtonType | undefined;
  endElement?: any;
  activeLabel?: string | undefined;
};

const Breadcrumb = (props: IProps) => {
  const { endElement, addButton, activeLabel } = props;
  let { pathname } = useLocation();
  const params = useParams();

  for (const key in params) {
    pathname = pathname.replace(`${params[key]}/`, "");
    pathname = pathname.replace(`${params[key]}`, "");
  }
  const items = pathname.split("/").filter((v) => v);
  const last = items[items.length - 1];
  const secondItem = items?.find(
    (item: any) => item === "saving" || item === "approveSaving"
  );

  return (
    <div className={"d-flex align-items-center"}>
      <div className={"flex-grow-1 mt-2"}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {items.map((item: any, index: any) => {
              let url = "/";

              items.forEach((item2: any, index2: any) => {
                if (index2 <= index) {
                  url += `${item2}/`;
                }
                if (index === 0) {
                  url =
                    secondItem === "saving"
                      ? "/console/saving/"
                      : secondItem === "approveSaving"
                      ? "/console/approveSaving/"
                      : "/console/dashboard/";
                }
              });

              const active = last === item;
              const label =
                index === 0
                  ? "Dashboard"
                  : activeLabel && active
                  ? activeLabel
                  : item;

              return (
                <li
                  className={`breadcrumb-item text-capitalize text-blue ${
                    active ? "active" : ""
                  }`}
                  aria-current="page"
                  key={index}
                >
                  {active ? (
                    label
                  ) : (
                    <Link to={url} aria-current="page" className="text-blue">
                      {label}
                    </Link>
                  )}
                </li>
              );
            })}
          </ol>
        </nav>
      </div>
      {endElement ??
        (addButton ? (
          <button className={"btn btn-primary "} onClick={addButton.onClick}>
            <i className={"bi bi-plus"}></i> {addButton.label}
          </button>
        ) : null)}
    </div>
  );
};

export default Breadcrumb;
