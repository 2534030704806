import Breadcrumb from "../../../components/Breadcrumb";
import { Col, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import {
  fetchDetails
} from "../../../services/common";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/helpers/mapState";
import { mapDispatchToProps } from "../../../store/helpers/mapDispatch";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import {
  Button,
  CircularProgress,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { default as ReactSelect } from "react-select";
import GeneralButton from "../../../components/Button";
import { Check, CheckCircle, Preview } from "@mui/icons-material";
import SmallDialog from "../../../components/SmallModel";

const AttendanceSetting = () => {
  const [confirm, setConfirm] = React.useState<any>(false);
  const [loading, setLoading] = React.useState(false);
  const [approveData, setApproveData] = React.useState<any>([]);
  const [list, setList] = React.useState<any>([]);

  const loadDevices = async () => {
    setLoading(true);
    const resp = await fetchDetails('/public/zk/devices');
    if (resp.status) {
      setList(resp.data?.devices
      );
    }
    setLoading(false);

  };
useEffect(()=>{loadDevices().then()},[])
const columns=[
  {
    // label: moment(date).format("MMMM YYYY"),
    label: "Name",
    name: "name"
  },
  {
    label: "Serial No",
    name: "sn",
  },
  {
    label: "Type",
    name: "type",
  },
  {
    label: "Status",
    name: "status",
  },
]
  const options: MUIDataTableOptions = {
    filter: false,
    // filterType: "dropdown",
    responsive: "standard",
    enableNestedDataAccess: ".",
    elevation: 1,
    rowsPerPage:100,
    rowsPerPageOptions: [ 100, 200, 400, 600, 800, 1000],
    onRowSelectionChange: (
      currentRowsSelected: any[],
      allRowsSelected: any[],
      rowsSelected?: any[]
    ) => {
      setApproveData(
        list?.filter((v: any, index: any) => rowsSelected?.includes(index))
      );
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <div>
        {approveData?.length ? (
          <Button
            startIcon={<Check />}
            onClick={() => {
              setConfirm(true);
            }}
            size={"medium"}
            variant="contained"
            color="success"
          >
            Approve
          </Button>
        ) : null}
      </div>
    ),
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    download: false,
    print: false,
    expandableRowsHeader: false,
    searchPlaceholder: "Search here...",
  };
  const theme = () =>
    createTheme({
      components: {
        MuiTable: {
          styleOverrides: {
            root: {
              border: 1,
              // width:'10% !important'
              // backgroundColor: "#f8f9fa",
            },
          },
        },

        MuiTableCell: {
          styleOverrides: {
            head: {
              paddingLeft: "0px !important",
              backgroundColor: "#054D6F !important",
              color: "#F9FAFC !important",
              whiteSpace: "nowrap",
              "&:not(:last-child)": {
                borderRight: [[1, "solid", "#c0c0c0"]],
              },
            },
            body: {
              fontSize: "12px !important",
              whiteSpace: "nowrap",
              fontFamily: "Inter !important",
            },
          },
        },
        MuiTableSortLabel: {
          styleOverrides: {
            root: {
              // alignItems: "flex-start",
              // background: "white",
              color: "white !important",
              fontFamily: "Inter !important",
            },
            active: {
              // background: "white",
              // color: "white",
            },
          },
        },
        MuiTableFooter: {
          styleOverrides: {
            root: {
              background: "#f5f7f7 !important",
            },
          },
        },
      },
    });

  return (
    <div>
      <Breadcrumb />
      <Row className={"justify-content-center"}>
        <Col md={12}>


          {loading ? (
            <div
              className={
                "position-absolute h-100 w-100 top-0 start-0 d-flex align-items-center justify-content-center"
              }
            >
              <div
                className={"bg-white p-3 rounded-4 shadow-sm text-center"}
                style={{ zIndex: 20000 }}
              >
                <div>
                  <CircularProgress color={"success"} />
                </div>
                <span>
                  <Typography style={{ fontFamily: "inter" }}>
                    Loading ....
                  </Typography>
                </span>
              </div>
            </div>
          ) : null}
          <ThemeProvider theme={theme}>
          <MUIDataTable
                title={''}
                data={list}
                columns={columns}
                options={options}
              />
          </ThemeProvider>
        </Col>
      </Row>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttendanceSetting);
