import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { Col, Row } from "react-bootstrap";
import { FormDialog } from "../../../components/FormDialog";
import React, { useEffect } from "react";
import { fetchDetails } from "../../../services/common";
import { AddForm } from "../../employee/AddForm";

const CreateAllowance = () => {
  const navigate = useNavigate();
  const [info, setInfo] = React.useState<any>();
  const [zones, setZone] = React.useState<any>([]);
  const [levels, setLevel] = React.useState<any>([]);

  const { id }: any = useParams();

  const fetchInfoDetails = async (id: string) => {
    const resp = await fetchDetails(`mission-allowance/view/${id}`);
    if (resp.status) {
      setInfo(resp.data);
    }
  };

  useEffect(() => {
    if (id !== "new") {
      fetchInfoDetails(id).then();
    }
  }, [id]);

  useEffect(() => {
    laodInitialData();
  }, []);
  const laodInitialData = async () => {
    const zone = await fetchDetails("/zone/view");
    const level = await fetchDetails("/level/view");
    if(zone.status)setLevel(level?.data);
    if(level.status)setZone(zone?.data);
  };
  return (
    <div>
      <Breadcrumb />
      <Row className={"justify-content-center"}>
        <Col md={5}>
          <AddForm
            inline={true}
            mainFields={[
              {
                title: "",
                fields: [
              {
                label: "Level",
                name: "levelId",
                type: "select",
                required: true,
                values: levels?.map((v: any) => ({
                  label: v.name,
                  value: v.id,
                })),
                value: info?.levelId,
              },
              {
                label: "Zone",
                name: "zoneId",
                type: "select",
                required: true,
                values: zones?.map((v: any) => ({
                  label: v.name,
                  value: v.id,
                })),
                value: info?.zoneId,
              },
              {
                label: "Day",
                name: "day",
                type: "text",
                value: info?.day,
                required: true,
              },
              {
                label: "Night",
                name: "night",
                type: "text",
                value: info?.night,
                required: true,
              },
            ]} ]}
            url={id !== "new"?`mission-allowance/update/${id}` : "mission-allowance/create"}
            method={id !== "new" ? "PUT" : "POST"}
            title={ "Allowance Configuration"}
            onSuccess={() => navigate(-1)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CreateAllowance;
