import { useNavigate, useParams } from "react-router-dom";
import {
  CheckHasAnyPermission,
  fetchDetails,
  getError,
  getFileMime,
  getSuccessMessage,
  submitGeneralForm,
} from "../../../services/common";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import PageLoading from "../../../components/PageLoading";
import { Card, Col, Container, Row } from "react-bootstrap";
import {
  Alert,
  Button,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
} from "@mui/material";
import StatusComponent from "../../../components/StatusComponent";
import GeneralButton from "../../../components/Button";
import {
  Check,
  BorderColorOutlined,
  Forward,
  HighlightOff,
  Info,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import OpenDocuments from "../../../components/openDocument";
import RequestHistoryProcess from "../../../components/RequestHistory";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/helpers/mapState";
import { mapDispatchToProps } from "../../../store/helpers/mapDispatch";
import SmallDialog from "../../../components/SmallModel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Approver from "../../../components/Approver";
import Reviewers from "../../../components/Reviewer";
import FileUpload from "../../../components/FileUploader";

const ExitRequestDetails = (props: any) => {
  const { loggedUser } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [info, setInfo] = useState<any>();
  const [error, setError] = useState<any>();
  const [confirm, setConfirm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDialogue, setOpenDialogue] = useState<boolean>(false);
  const [newData, setData] = useState<any>();
  const [viewDocument, setViewDocument] = useState(false);
  const [document, setDocument] = useState<any>();
  const [minutes, setMinutes] = useState<any>([]);

  const loadInfo = async () => {
    const resp = await fetchDetails(`exit/view/${id}`);
    if (resp.status) {
      setInfo(resp.data);
    } else {
      setError(getError(resp));
    }
  };

  const onSubmit = async (data?: any) => {
    setLoading(true);
    setConfirm(false);

    let infoToSave = data ?? newData;
    const formData = new FormData();
    formData.append("body", JSON.stringify(infoToSave));

    if (minutes) {
      for (let i = 0; i < minutes.length; i++) {
        formData.append("supportingDocument", minutes[i]);
      }
    }

    const resp = await submitGeneralForm(
      `/approvalExits/change-status/${id}`,
      formData,
      null,
      "PUT"
    );
    if (resp.status) {
      setData({});
      setError(null);
      setOpenDialogue(false);
      loadInfo().then();
    } else {
      setError(getError(resp));
    }
    setLoading(false);
  };

  useEffect(() => {
    loadInfo().then();
  }, [id]);

  const isInitiator = loggedUser?.employeeId === info?.employeeId;
  const canUpdate = isInitiator && info?.status === "request action";

  const canApprove = loggedUser?.id === info?.approverId;

  return (
    <div>
      <Breadcrumb />
      {!info ? (
        <PageLoading error={error} />
      ) : (
        <Row className={" p-3 "}>
          <Col className={"mb-4"} md={12}>
            <Card className={"bg-light shadow-sm"}>
              <CardHeader
                subheader={
                  <span className="fw-bold">Exit Request Details</span>
                }
                action={<StatusComponent status={info?.status} badge={true} />}
              />
              <CardContent>
                <div className={"row justify-content-end"}>
                  {info?.status !== "approved" &&
                  info?.status !== "rejected" ? (
                    <div className="col-md-8 fw-bold d-flex justify-content-end">
                      {canUpdate ? (
                        <Button
                          className="btn-primary me-2"
                          size="small"
                          variant="outlined"
                          startIcon={<BorderColorOutlined />}
                          onClick={() => {
                            navigate(`/console/saving/exit/update/${info?.id}`);
                          }}
                        >
                          Update
                        </Button>
                      ) : null}
                      {canApprove &&
                      (info?.status === "under review" ||
                        info?.status === "forwarded" ||
                        info?.status === "pending") ? (
                        <>
                          <Button
                            className="me-1"
                            color="warning"
                            size="small"
                            variant="outlined"
                            startIcon={<Info />}
                            onClick={() => {
                              setOpenDialogue(true);
                              setData({
                                id: info?.id,
                                status: "request action",
                              });
                            }}
                          >
                            Request for action
                          </Button>
                          <Button
                            className="me-2"
                            color="error"
                            size="small"
                            variant="outlined"
                            startIcon={<HighlightOff />}
                            onClick={() => {
                              setOpenDialogue(true);
                              setData({ id: info?.id, status: "rejected" });
                            }}
                          >
                            Reject
                          </Button>
                          {/* {!isReviewer && ( */}
                          <Button
                            className="me-2"
                            color="success"
                            size="small"
                            disabled={!canApprove}
                            variant="outlined"
                            startIcon={<Check />}
                            onClick={() => {
                              setOpenDialogue(true);
                              setData({ id: info?.id, status: "approved" });
                            }}
                          >
                            Approve
                          </Button>
                          {/* )} */}
                        </>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                <h5>Employee Details</h5>
                <Row className={"mb-3"}>
                  <Col md={4}>
                    <div>Names</div>
                    <div className={"fw-bold"}>
                      {info?.CaisseMember?.employee?.names ?? "---"}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>Phone Number</div>
                    <div className={"fw-bold"}>
                      {info?.CaisseMember?.employee?.phone ?? "---"}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>Current Total Shares</div>
                    <div className={"fw-bold"}>
                      {info?.CaisseMember?.totalShare?.toLocaleString() ??
                        "---"}{" "}
                      RWF
                    </div>
                  </Col>
                </Row>
                <Row className={"mb-3"}>
                  <Col md={4}>
                    <div>Employee Number</div>
                    <div className={"fw-bold"}>
                      {info?.CaisseMember?.employee?.employeeNumber ?? "---"}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>Email</div>
                    <div className={"fw-bold"}>
                      {info?.CaisseMember?.employee?.email ?? "---"}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>Category</div>
                    <div className={"fw-bold"}>
                      {info?.CaisseMember?.employee?.category ?? "---"}
                    </div>
                  </Col>
                </Row>
                <h5>Exit Request Details</h5>
                <Row className={"mb-3"}>
                  <Col md={12}>
                    <div className={"mb-2"}>Reason : </div>
                    <div className={"fw-bold"}>
                      <Card>
                        <div className={"card-body"}>
                          <div
                            dangerouslySetInnerHTML={{ __html: info?.reason }}
                          ></div>
                        </div>
                      </Card>
                    </div>
                  </Col>
                </Row>
                <Row className={"mb-3"}>
                  {info?.supportingDocument ? (
                    <Col md={4}>
                      <div className=" mb-3">
                        <Typography style={{fontFamily:'Inter'}} className="mb-3 fw-bold me-3">
                          Supporting Document
                        </Typography>
                        <GeneralButton
                          variant="outlined"
                          icon={
                            viewDocument ? <VisibilityOff /> : <Visibility />
                          }
                          onClick={() => {
                            setViewDocument(!viewDocument);
                            setDocument(info?.supportingDocument);
                          }}
                          name={"View Document"}
                        />
                      </div>
                    </Col>
                  ) : null}
                  {info?.minutes ? (
                    <Col md={4}>
                      <div className=" mb-3">
                        <Typography style={{fontFamily:'Inter'}} className="mb-3 fw-bold me-3">
                          Minutes
                        </Typography>
                        <GeneralButton
                          variant="outlined"
                          icon={
                            viewDocument ? <VisibilityOff /> : <Visibility />
                          }
                          onClick={() => {
                            setViewDocument(!viewDocument);
                            setDocument(info?.minutes);
                          }}
                          name={"View Document"}
                        />
                      </div>
                    </Col>
                  ) : null}
                </Row>
                <Reviewers
                  loggedUser={loggedUser}
                  RequestApprovers={info?.RequestApprovers}
                />
                <hr />
                <div className="row">
                  <Typography style={{fontFamily:'Inter'}} className="col-md-12 mb-3 fw-bold">
                    Request History
                  </Typography>
                  <div className="col-md-12">
                    <RequestHistoryProcess
                      RequestProcesses={info?.RequestProcesses}
                    />
                  </div>
                </div>

                {openDialogue ? (
                  <SmallDialog
                    maxWidth="md"
                    title={
                      newData?.status === "request action"
                        ? "Request Action"
                        : newData?.status === "approved"
                        ? "Approve Request"
                        : "Reject Request"
                    }
                    buttonTitle="Submit"
                    needButton={true}
                    open={openDialogue}
                    setOpen={setOpenDialogue}
                  >
                    {error ? (
                      <Alert
                        style={{ width: "100%" }}
                        className={"mb-3"}
                        severity={"error"}
                        onClose={() => setError(null)}
                      >
                        {error}
                      </Alert>
                    ) : null}
                    <form
                      onSubmit={(e: any) => {
                        e.preventDefault();
                        setConfirm(true);
                      }}
                    >
                      {newData?.status === "approved" ? (
                        <>
                          <label className="col-form-label text-capitalize">
                            {"Minutes"}
                          </label>
                          <FileUpload
                            setFiles={setMinutes}
                            files={minutes}
                            required={true}
                            title={"upload"}
                          />
                        </>
                      ) : null}
                      <div>
                        <label className="col-form-label text-capitalize">
                          Comment
                        </label>
                        <textarea
                          placeholder="Type here..."
                          className="form-control form-control mb-3"
                          required={true}
                          value={newData?.comment}
                          rows={5}
                          onChange={(e: any) => {
                            setData({
                              ...newData,
                              comment: e.currentTarget.value,
                            });
                          }}
                        />
                      </div>
                      <div className={"float-end"}>
                        <button
                          type={"button"}
                          onClick={() => {
                            setOpenDialogue(false);
                          }}
                          className={"btn btn-secondary me-1"}
                        >
                          <i className={"bi bi-x-lg"}></i> Cancel
                        </button>
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <button type={"submit"} className={"btn btn-primary"}>
                            <i className={"bi bi-send-fill"}></i> Submit
                          </button>
                        )}
                      </div>
                    </form>
                  </SmallDialog>
                ) : null}
                {openDialogue ? (
                  <SmallDialog
                    open={confirm}
                    setOpen={setConfirm}
                    onSubmit={() => {
                      onSubmit(newData);
                    }}
                  >
                    <div className="d-flex ">
                      <CheckCircleIcon color="success" sx={{ fontSize: 50 }} />
                      <div className="mt-4">
                        {"Are you sure you want to submit?"}
                      </div>
                    </div>
                  </SmallDialog>
                ) : null}
                <SmallDialog
                  maxWidth="lg"
                  title="Document"
                  buttonTitle="Close"
                  needButton={true}
                  open={viewDocument}
                  setOpen={setViewDocument}
                  onSubmit={() => {
                    setViewDocument(false);
                  }}
                >
                  <div className={"row justify-content-center"}>
                    <div className={"col-md-7"}>
                      {document?.map((v: string) => (
                        <OpenDocuments
                          downloadable={true}
                          card={true}
                          document={v}
                          mimeType={getFileMime(v)}
                        />
                      ))}
                    </div>
                  </div>
                </SmallDialog>
              </CardContent>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExitRequestDetails);
