import Breadcrumb from "../../components/Breadcrumb";
import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FormDialog } from "../../components/FormDialog";
import { useNavigate, useParams } from "react-router-dom";
import { AddForm } from "./AddForm";
import {
  CheckHasAnyPermission,
  CheckHasPermission,
  fetchDetails,
  getFileMime,
  submitGeneralForm,
} from "../../services/common";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  styled,
} from "@mui/material";
import {
  Add,
  ArrowRight,
  ArrowRightAlt,
  Block,
  Cancel,
  CheckCircle,
  Close,
  CloudUpload,
  BorderColorOutlined,
  HighlightOff,
  Lock,
  Security,
  Verified,
  Warning,
} from "@mui/icons-material";
import profile from "../../assets/images.jpg";
import UserPermission from "./Permission";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import UserPassword from "./UserPassword";
import OpenDocuments from "../../components/openDocument";
import { CustomizedMenus } from "../../components/customizeMenu";
import DeleteDialog from "../../components/DeleteDialog";
import moment from "moment";
import GeneralButton from "../../components/Button";
import FileUpload from "../../components/FileUploader";
import SmallDialog from "../../components/SmallModel";
import { TabPanel, a11yProps } from "./leave/List";
import UpdateLeave from "../approval/leave/UpdateModel";
import { mapStateToProps } from "../../store/helpers/mapState";
import { mapDispatchToProps } from "../../store/helpers/mapDispatch";
import { connect } from "react-redux";
import { LinearProgress } from "@material-ui/core";

const EmployeeDetails = (props: any) => {
  const { loggedUser } = props;
  const navigate = useNavigate();
  const [deleteOpen, setDeleteOpen] = useState(0);
  const [fileData, setFileData] = useState<any>([]);
  const [viewFile, setViewFile] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);
  const [newPermission, setNewPermission] = useState<any>([]);
  const [addPermission, setAddPermission] = useState<any>(false);
  const [isMyAccount, setIsMyAccount] = useState<any>(false);
  const [addFile, setAddFile] = React.useState<number>(0);
  const [updateLeave, setUpdateLeave] = useState<any>(0);
  const [info, setInfo] = useState<any>({});
  const [leaveInfo, setLeaveInfo] = useState<any>({});
  const [docInfo, setDocInfo] = useState<any>({});
  const [openPermission, setOpenPermission] = React.useState(false);
  const [openChangePassword, setChangePassword] = React.useState(false);
  const { id }: any = useParams();
  const empId = id ?? loggedUser?.employeeId;
  const [files, setFiles] = React.useState<any>([]);
  const [setting, setSetting] = useState<any>();
  const [confirm, setConfirm] = useState(false);
  const [value, setValue] = useState(0);
  // const LeaveDetail = info?.LeaveDetails;
  const inputRef: any = useRef();

  const isOwner = info?.id === loggedUser?.employeeId;
  const hasHrRole =
    loggedUser?.roles?.filter((item: any) => item.role?.name === "hr")?.length >
    0;
  const roles = info?.user?.roles?.map((role: any) => role?.role?.label);
  // const fiscalYear = moment().set({ month: 6, date: 31 }).format("YYYY-MM-DD"); //deadline to submit leave plan is 31st of july,but according to moment js it's takes previous month
  // const canSubmitLeave =
  //   moment(fiscalYear).diff(moment().format("YYYY-MM-DD"), "days") >= 0;

  // const retakeDeadline = moment()
  //   .set({ month: 11, date: 31 })
  //   .format("YYYY-MM-DD"); //deadline to retake postponed leave is 31st of december,but according to moment js it's takes previous month
  // const canRetake =
  //   moment(retakeDeadline).diff(moment().format("YYYY-MM-DD"), "days") >= 0;

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const StyledTabs = styled((props: any) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      backgroundColor: "#035c85",
    },
  });
  const StyledTab = styled((props: any) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      "&.Mui-selected": {
        color: "#035c85",
      },
      "&.Mui-focusVisible": {
        color: "#035c85",
      },
    })
  );
  const closeAddPermission = () => {
    setNewPermission([]);
    setAddPermission(false);
  };
  const fetchInfoDetails = async (id: string) => {
    const resp = await fetchDetails(`employee/view/${empId}`);
    const setting = await fetchDetails(`settings/view/`);
    if (setting.status) {
      setSetting(setting.data);
    }
    if (resp.status) {
      setInfo(resp.data);
    }
  };
  useEffect(() => {
    if (empId) {
      fetchInfoDetails(empId).then();
    }
  }, [empId]);

  //update profile picture
  const onChangeProfile = async (profile: any) => {
    setLoading(true);
    const formData = new FormData();
    if (profile?.length) {
      formData.append("profile", profile[0]);
    }
    const resp = await submitGeneralForm(
      `/employee/update-profile/${info?.id}`,
      formData,
      null,
      "PUT"
    );
    if (resp.status) {
      fetchInfoDetails(info?.id).then();
    }
    setLoading(false);
  };
  const getEmployeeLeave = async () => {
    const leave = await fetchDetails(
      `/leave/search/plan?info=${JSON.stringify({
        employeeId: info?.id,
        status: "on leave",
      })}`
    );
    if (leave.status) setLeaveInfo(leave.data[0]);
  };
  const date = moment().format("YYYY-MM-DD");

  const startTime = new Date(date + " " + setting?.workStartTime);
  const endTime = new Date(date + " " + setting?.workEndTime);
  const isActive = info?.user?.status === "active" && info?.user;
  return (
    <div>
      <Breadcrumb />
      <Row className={" p-3 "}>
      {info?.id ? null : <LinearProgress />}

        {!info?.positionId&&info ?  (
          <Col className={"mb-4"} md={12}>
            <Alert severity="warning">
              <AlertTitle>Position alert</AlertTitle>
              It appears that your position has changed. Please update it.&nbsp;
              <Button
                size="small"
                startIcon={<ArrowRightAlt />}
                onClick={() => {
                  if (loggedUser) {
                    navigate(
                      `/console/employee/all-employees/setting/${info?.id}`
                    );
                  } else {
                    navigate(`../setting/${info?.id}`);
                  }
                }}
              >
                Click here to update
              </Button>
              .
            </Alert>
          </Col>
        ):null}
        {!isActive&&info ?  (
          <Col className={"mb-4"} md={12}>
            <Alert severity="error">
              <AlertTitle>Blocked</AlertTitle>
              This account has been blocked.&nbsp;
              <Button
                size="small"
                startIcon={<ArrowRightAlt />}
                onClick={() => {
                  setConfirm(true);
                  setDocInfo({id:info?.user?.id,status: "active" });
                }}
              >
                Click re-activate
              </Button>
              .
            </Alert>
          </Col>
        ):null}
        <Col className={"mb-4"} md={12}>
          <Card className={"bg-light shadow-sm"}>
            <CardHeader
              subheader={
                <Typography
                  style={{ fontFamily: "inter" }}
                  className="fw-bolder color1"
                >
                  {openPermission
                    ? "User Permissions"
                    : openChangePassword
                    ? "Change Password"
                    : "Employee Profile"}
                </Typography>
              }
              action={
                openPermission || viewFile ? (
                  <>
                    <GeneralButton
                      icon={<KeyboardBackspaceIcon />}
                      onClick={() => {
                        setOpenPermission(false);
                        closeAddPermission();
                        setChangePassword(false);
                        setViewFile(false);
                      }}
                      name={" Back To Details"}
                    />
                  </>
                ) : isActive ? (
                  <>
                    {CheckHasPermission("employee", "create") && isActive ? (
                      <Button
                        autoFocus
                        color="error"
                        className="me-2"
                        size="small"
                        variant="outlined"
                        startIcon={<Block />}
                        onClick={() => {
                          setConfirm(true);
                          setDocInfo({id:info?.user?.id,status: "blocked" });

                        }}
                      >
                        Block
                      </Button>
                    ) : null}
                    {CheckHasPermission("settings-user", "create") ? (
                      <GeneralButton
                        icon={<Lock />}
                        onClick={() => {
                          setOpenPermission(true);
                          setChangePassword(false);
                        }}
                        name={"Permissions"}
                      />
                    ) : null}
                    {loggedUser?.employeeId === info?.id ||
                    CheckHasPermission("settings-user", "create") ? (
                      <>
                        <GeneralButton
                          icon={<Security />}
                          onClick={() => {
                            setChangePassword(true);
                            setOpenPermission(false);
                          }}
                          name={"Security"}
                        />
                        {info?.id ? (
                          <GeneralButton
                            icon={<BorderColorOutlined />}
                            onClick={() => {
                              if (loggedUser) {
                                navigate(
                                  `/console/employee/all-employees/setting/${info?.id}`
                                );
                              } else {
                                navigate(`../setting/${info?.id}`);
                              }
                            }}
                            name={"Update"}
                          />
                        ) : null}
                      </>
                    ) : null}
                  </>
                ) : null
              }
            />
            <CardContent>
              <div className="row mb-2">
                <div className="col-md-3 fw-bold text-nowrap">
                  <div className="d-flex justify-content-center">
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        {info.profile ? (
                          <OpenDocuments
                            // downloadable={false}
                            // card={true}
                            width="100px"
                            height="120px"
                            document={info?.profile}
                            mimeType={getFileMime(info?.profile)}
                            className="img-fluid"
                          />
                        ) : (
                          <img
                            className="rounded-pill img-fluid"
                            width="100px"
                            height="100px"
                            alt={`${info?.firstName}`}
                            src={profile}
                          />
                        )}
                        <BorderColorOutlined
                          color="primary"
                          fontSize="small"
                          role="button"
                          onClick={handleClick}
                        />
                      </>
                    )}
                  </div>
                  <input
                    style={{ display: "none" }}
                    ref={inputRef}
                    type={"file"}
                    accept="image/*"
                    onChange={(e: any) => {
                      onChangeProfile(e.target.files);
                    }}
                  />
                </div>
                <div className="col-md-4">
                  <div className="row mb-2">
                    <div className="col-md-4 fw-bold text-nowrap">
                      First Name
                    </div>
                    <div className="col-md-8">{info?.firstName}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-4 fw-bold text-nowrap">
                      Last Name
                    </div>
                    <div className="col-md-8">{info?.lastName}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-4 fw-bold text-nowrap">Phone</div>
                    <div className="col-md-8">{info?.phone}</div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-md-4 fw-bold text-nowrap">Email</div>
                    <div className="col-md-8">{info?.email}</div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="row mb-2">
                    <div className="col-md-4 fw-bold text-nowrap">
                      Civil Status
                    </div>
                    <div className="col-md-8">{info?.civilStatus}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-4 fw-bold text-nowrap">
                      Birth Date
                    </div>

                    <div className="col-md-8">
                      {moment(info?.birthDate).format("L")}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-4 fw-bold text-nowrap">
                      Country of Birth
                    </div>
                    <div className="col-md-8">{info?.birthCountry}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-4 fw-bold text-nowrap">
                      Place of Birth
                    </div>
                    <div className="col-md-8">{info?.birthPlace}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-4 fw-bold text-nowrap">Gender</div>
                    <div className="col-md-8 text-capitalize">
                      {info?.gender}
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Col>
        {openChangePassword ? (
          <UserPassword
            setChangePassword={setChangePassword}
            loggedUser={loggedUser}
            userId={info?.user?.id}
            isMyAccount={isMyAccount}
          />
        ) : openPermission ? (
          <UserPermission
            id={info?.user?.id}
            info={info?.user?.roles}
            laodInitialData={fetchInfoDetails}
            addPermission={addPermission}
            newPermission={newPermission}
            closeAddPermission={closeAddPermission}
            setNewPermission={setNewPermission}
          />
        ) : viewFile ? (
          <div>
            {fileData && viewFile ? (
              <Row className={" justify-content-center"}>
                <Col md={12}>
                  {fileData?.map((v: string) => (
                    <OpenDocuments
                      downloadable={true}
                      card={true}
                      document={v}
                      mimeType={getFileMime(v)}
                    />
                  ))}
                </Col>
              </Row>
            ) : null}
          </div>
        ) : (
          <>
            <Col className={"mb-4"} md={5}>
              <Card
                className={"bg-light shadow-sm mb-4"}
                style={{ height: 180 }}
              >
                <CardHeader
                  subheader={
                    <Typography
                      style={{ fontFamily: "inter" }}
                      className="fw-bold color1"
                    >
                      {"Family Information"}
                    </Typography>
                  }
                  action={
                    <Button
                      className="btn-primary"
                      size="small"
                      startIcon={<BorderColorOutlined />}
                      onClick={() => {
                        if (loggedUser) {
                          navigate(
                            `/console/employee/all-employees/setting/${info?.id}`
                          );
                        } else {
                          navigate(`../setting/${info?.id}`);
                        }
                      }}
                    />
                  }
                />
                <CardContent>
                  <div className="row mb-2">
                    <div className="col-md-4 fw-bold">Father's Name</div>
                    <div className="col-md-8">{info?.fatherName}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-4 fw-bold">Mother's Name</div>
                    <div className="col-md-8">{info?.motherName}</div>
                  </div>
                </CardContent>
              </Card>
              <Card className={"bg-light shadow-sm"} style={{ height: 180 }}>
                <CardHeader
                  subheader={
                    <Typography
                      style={{ fontFamily: "inter" }}
                      className="fw-bold color1"
                    >
                      {"System Information"}
                    </Typography>
                  }
                  action={
                    <Button
                      className="btn-primary"
                      size="small"
                      startIcon={<BorderColorOutlined />}
                      onClick={() => {
                        if (loggedUser) {
                          navigate(
                            `/console/employee/all-employees/setting/${info?.id}`
                          );
                        } else {
                          navigate(`../setting/${info?.id}`);
                        }
                      }}
                    />
                  }
                />
                <CardContent>
                  <div className="row mb-2">
                    <div className="col-md-4 fw-bold">User Role</div>
                    <div className="col-md-8 text-capitalize ">
                      {roles?.toString()}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-4 fw-bold">User Email</div>
                    <div className="col-md-8">{info?.email}</div>
                  </div>
                </CardContent>
              </Card>
            </Col>
            <Col className={"mb-4"} md={7}>
              <Card
                className={"bg-light shadow-sm mb-4"}
                style={{ height: 180 }}
              >
                <CardHeader
                  subheader={
                    <Typography
                      style={{ fontFamily: "inter" }}
                      className="fw-bold color1"
                    >
                      {"Bank & Medical Insurance Information"}
                    </Typography>
                  }
                  action={
                    <Button
                      className="btn-primary"
                      size="small"
                      startIcon={<BorderColorOutlined />}
                      onClick={() => {
                        if (loggedUser) {
                          navigate(
                            `/console/employee/all-employees/setting/${info?.id}`
                          );
                        } else {
                          navigate(`../setting/${info?.id}`);
                        }
                      }}
                    />
                  }
                />
                <CardContent className="row">
                  <div className="col-md-6">
                    <div className="row mb-2">
                      <div className="col-md-6 fw-bold text-nowrap">
                        Bank Name
                      </div>
                      <div className="col-md-6">{info?.bank?.name}</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-6 fw-bold text-nowrap">
                        Account Number
                      </div>
                      <div className="col-md-6">{info?.accountNumber}</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-6 fw-bold text-nowrap">
                        Medical Insurance
                      </div>
                      <div className="col-md-6">{info?.medicalInsurance}</div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row mb-2">
                      <div className="col-md-6 fw-bold text-nowrap">
                        Insurance Number
                      </div>
                      <div className="col-md-6">
                        {info?.medicalInsuranceNumber}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-6 fw-bold text-nowrap">
                        CRS Number
                      </div>
                      <div className="col-md-6">{info?.crsNumber}</div>
                    </div>
                  </div>
                </CardContent>
              </Card>
              <Card className={"bg-light shadow-sm"} style={{ height: 180 }}>
                <CardHeader
                  subheader={
                    <Typography
                      style={{ fontFamily: "inter" }}
                      className="fw-bold color1"
                    >
                      {"Working Hour & Attendance Device"}
                    </Typography>
                  }
                />
                <CardContent className="row">
                  <div className="col-md-6">
                    <div className="row mb-2">
                      <div className="col-md-4 fw-bold">Start Time</div>
                      <div className="col-md-8">
                        {info?.startTime
                          ? info?.startTime
                          : moment(startTime).format("h:mm A")}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-4 fw-bold">End Time</div>
                      <div className="col-md-8">
                        {info?.endTime
                          ? info?.endTime
                          : moment(endTime).format("h:mm A")}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row mb-2">
                      <div className="col-md-4 fw-bold text-nowrap">
                        Status
                      </div>
                      <div className="col-md-8">
                        {info?.attendanceDevice}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-4 fw-bold text-nowrap">
                        Leave EndAt
                      </div>
                      <div className="col-md-8">
                        {info?.spacialBreakEndTime}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Col>
            <Col className={"mb-4"} md={12}>
              <Card className={"bg-light shadow-sm"}>
                <CardHeader
                  subheader={
                    <Typography
                      style={{ fontFamily: "inter" }}
                      className="fw-bold color1"
                    >
                      {"Employment"}
                    </Typography>
                  }
                  action={
                    <span
                      className={
                        info?.status === "retired"
                          ? "badge bg-primary rounded-pill"
                          : info?.status === "exited"
                          ? "badge bg-danger rounded-pill"
                          : info?.status === "on leave"
                          ? "badge bg-secondary rounded-pill"
                          : "badge bg-success rounded-pill"
                      }
                    >
                      {info?.status}
                    </span>
                  }
                />
                <CardContent className="row">
                  <div className="col-md-6">
                    <div className="row mb-2">
                      <div className="col-md-4 fw-bold">Position</div>
                      <div className="col-md-8">{info?.position?.name}</div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-md-4 fw-bold">Echelon</div>
                      <div className="col-md-8">{info?.echlon?.name}</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-4 fw-bold">Level</div>
                      <div className="col-md-8">{info?.level?.name}</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-4 fw-bold">Probation</div>
                      <div className="col-md-8">
                        {info?.probation ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-4 fw-bold text-nowrap">
                        Employee Group
                      </div>
                      <div className="col-md-8">{info?.category}</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-4 fw-bold text-nowrap">
                        Employment Date
                      </div>
                      <div className="col-md-8">
                        {moment(info?.employmentDate).format("L")}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row mb-3">
                      <div className="col-md-4 fw-bold text-nowrap">
                        Last Updated At
                      </div>
                      <div className="col-md-4">
                        {moment(info?.updatedAt).format("lll")}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-4 fw-bold text-nowrap">
                        Card Number
                      </div>
                      <div className="col-md-4">{info?.cardNumber}</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-4 fw-bold">Branches</div>
                      <div className="col-md-12 mt-2">
                        <ul className={"list-group ms-0"}>
                          {info?.employeeBranches?.map((branch: any) => (
                            <li className={"list-group-item ms-0"}>
                              <div>
                                <b>Branch:</b> {branch?.branch?.name}
                              </div>
                              <div>
                                <b>Department:</b> {branch?.department?.name}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                  {info?.status === "on leave" &&
                  CheckHasAnyPermission("cancel-leave") ? (
                    <div className="row mb-2">
                      <button
                        className="col-md-12 btn btn-danger"
                        onClick={() => {
                          setConfirm(true);
                          getEmployeeLeave();
                        }}
                      >
                        Cancel Leave
                      </button>
                    </div>
                  ) : null}
                </CardContent>
              </Card>
            </Col>
            <Col className={"mb-4"} md={12}>
              <Card className={"bg-light shadow-sm"}>
                <CardHeader
                  subheader={
                    <Typography
                      style={{ fontFamily: "inter" }}
                      className="fw-bold color1"
                    >
                      {"Attachments"}
                    </Typography>
                  }
                  action={
                    isOwner && value === 0 ? (
                      // <Button
                      //   className="btn-primary"
                      //   size="small"
                      //   variant="outlined"
                      //   startIcon={<Add />}
                      //   onClick={() => {
                      //     setAddFile(1);
                      //     setDocInfo({});
                      //   }}
                      // >
                      //   Add New
                      // </Button>
                      <GeneralButton
                        icon={<Add />}
                        onClick={() => {
                          setAddFile(1);
                          setDocInfo({});
                        }}
                        name={" Add New"}
                      />
                    ) : null
                  }
                />
                <CardContent>
                  <Box sx={{ borderBottom: 0.5, Update: "divider" }}>
                    <StyledTabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <StyledTab label="My Files" {...a11yProps(0)} />
                      {/* <StyledTab label="Leave Plan" {...a11yProps(1)} /> */}
                    </StyledTabs>
                  </Box>{" "}
                  <TabPanel value={value} index={0}>
                    <TableContainer className="border">
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Type of File</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {info?.EmployeeDocuments?.length > 0 ? (
                            info?.EmployeeDocuments?.map((doc: any) => (
                              <TableRow
                                role="button"
                                onClick={() => {
                                  setViewFile(true);
                                  setFileData(doc.document);
                                }}
                              >
                                <TableCell>{doc.name}</TableCell>
                                <TableCell>{doc.type}</TableCell>
                                <TableCell
                                  className={
                                    doc.status === "verified"
                                      ? "text-success fw-bold"
                                      : doc.status === "rejected"
                                      ? "text-danger fw-bold"
                                      : "text-warning fw-bold"
                                  }
                                >
                                  {doc.status}
                                </TableCell>
                                {doc.status !== "verified" && isOwner ? (
                                  <TableCell>
                                    <CustomizedMenus
                                      onView={() => {
                                        setViewFile(true);
                                        setFileData(doc.document);
                                      }}
                                      onEdit={() => {
                                        setAddFile(1);
                                        setDocInfo({ ...doc, update: true });
                                      }}
                                      onDelete={() => {
                                        setDeleteOpen(1);
                                        setDocInfo({
                                          url: `/employee/delete-fileDocument/${doc.id}`,
                                        });
                                      }}
                                      needEdit={true}
                                      needDelete={true}
                                    />
                                  </TableCell>
                                ) : null}
                                {doc.status === "pending" && hasHrRole ? (
                                  <TableCell>
                                    <Button
                                      color="error"
                                      size="small"
                                      variant="contained"
                                      className="me-1"
                                      onClick={() => {
                                        setConfirm(true);
                                        setDocInfo({
                                          ...doc,
                                          status: "rejected",
                                          update: true,
                                        });
                                      }}
                                    >
                                      Reject
                                    </Button>
                                    <Button
                                      color="success"
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        setConfirm(true);
                                        setDocInfo({
                                          ...doc,
                                          status: "verified",
                                          update: true,
                                        });
                                      }}
                                    >
                                      Verify
                                    </Button>
                                  </TableCell>
                                ) : (
                                  <TableCell>
                                    {doc.status === "pending" ? (
                                      <Warning color="warning" />
                                    ) : doc.status === "rejected" ? (
                                      <Cancel color="error" />
                                    ) : (
                                      <Verified color="success" />
                                    )}
                                  </TableCell>
                                )}
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={3} className="text-center">
                                {"No file found"}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TabPanel>
                  {/* <TabPanel value={value} index={1}>
                    <div className="mb-4">
                      <span className="fw-bolder text-capitalize badge bg-secondary rounded me-2">
                        {"Days Taken:"}
                      </span>
                      <span className="fw-bolder text-capitalize me-2">
                        <strong className="ms-1">{info?.leaveDaysTaken}</strong>
                      </span>
                    </div>
                    <TableContainer className="border">
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              className="fw-bold"
                              colSpan={3}
                            ></TableCell>
                            <TableCell className="fw-bold">Moved To</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell>Cancelation Date</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {LeaveDetail?.map((details: any) => (
                            <TableRow>
                              <TableCell>
                                {" "}
                                {moment(details.startDate).format(
                                  "YYYY-MMM-DD"
                                )}
                              </TableCell>
                              <TableCell>
                                {" "}
                                {moment(details.endDate).format("YYYY-MMM-DD")}
                              </TableCell>
                              <TableCell>
                                {" "}
                                {details.canceledDate
                                  ? moment(details.canceledDate).format(
                                      "YYYY-MMM-DD"
                                    )
                                  : null}
                              </TableCell>
                              <TableCell>
                                {" "}
                                {details.movedToStartDate
                                  ? moment(details.movedToStartDate).format(
                                      "YYYY-MMM-DD"
                                    )
                                  : null}
                              </TableCell>
                              <TableCell>
                                {" "}
                                {details.movedToEndDate
                                  ? moment(details.movedToEndDate).format(
                                      "YYYY-MMM-DD"
                                    )
                                  : null}
                              </TableCell>
                              <TableCell
                                className={
                                  details.status === "approved"
                                    ? "text-info fw-bold"
                                    : details.status === "canceled"
                                    ? "text-danger fw-bold"
                                    : "fw-bold text-success"
                                }
                              >
                                {details.status}
                              </TableCell>

                              <TableCell>
                                <CustomizedMenus
                                  onEdit={() => {
                                    setUpdateLeave(1);
                                    setLeaveInfo(details);
                                  }}
                                  needEdit={
                                    (canRetake &&
                                      details.status === "canceled") ||
                                    canSubmitLeave
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TabPanel> */}
                </CardContent>
              </Card>
            </Col>
          </>
        )}
      </Row>
      <AddForm
        title="Attachment"
        maxWidth="lg"
        mainFields={[
          {
            title: "",
            fields: [
              {
                label: "employeeId",
                name: "employeeId",
                col: 12,
                hidden: true,
                value: info?.id,
              },
              {
                label: "Name",
                name: "name",
                col: 12,
                required: true,
                value: docInfo?.name,
              },
              {
                label: "File Type",
                name: "type",
                type: "select",
                col: 12,
                values: [
                  {
                    label: "CV",
                    value: "CV",
                  },
                  {
                    label: "ID",
                    value: "ID",
                  },
                  {
                    label: "Degree",
                    value: "degree",
                  },
                  {
                    label: "Professional certificate",
                    value: "professionalCertificate",
                  },
                  {
                    label: "Criminal record",
                    value: "criminalRecord",
                  },
                ],
                required: true,
                value: docInfo?.type,
              },
              {
                label: "Attachment",
                name: "supportingDocument",
                type: "file",
                multiple: true,
                col: 12,
                value: files,
                render: () => {
                  return <FileUpload setFiles={setFiles} files={files} />;
                },
              },
            ],
          },
        ]}
        url={
          docInfo?.update
            ? `employee/update-fileDocument/${docInfo?.id}`
            : "/employee/add-file/"
        }
        method={docInfo?.update ? "PUT" : "POST"}
        onSuccess={() => {
          setAddFile(0);
          setDocInfo({});
          fetchInfoDetails(info.id);
        }}
        open={addFile}
        setOpen={() => setAddFile(0)}
      />
      {/* postpone leave */}

      <UpdateLeave
        onSuccess={() => {
          setUpdateLeave(0);
          fetchInfoDetails(info.id);
        }}
        updateLeave={updateLeave}
        setUpdateLeave={setUpdateLeave}
        leaveInfo={leaveInfo}
      />
      <SmallDialog
        open={confirm}
        setOpen={setConfirm}
        onSubmit={async () => {
          if (docInfo?.update) {
            const formData = new FormData();
            formData.append("body", JSON.stringify(docInfo));
            const resp = await submitGeneralForm(
              `employee/update-fileDocument/${docInfo?.id}`,
              formData,
              null,
              "PUT"
            );
            if (resp.status) {
              fetchInfoDetails(info.id);
              setConfirm(false);
              setDocInfo({});
            }
          } else if (
            docInfo?.status === "blocked" ||
            docInfo?.status === "active"
          ) {
            const resp = await submitGeneralForm(
              `/user/update/${docInfo?.id}`,
              { status: docInfo?.status },
              null,
              "PUT"
            );
            if (resp.status) {
              fetchInfoDetails(info.id);
              setConfirm(false);
              setDocInfo({});
            }
          } else {
            const resp = await submitGeneralForm(
              `/leave/update/leavePlan/${leaveInfo?.id}`,
              { status: "canceled", canceledDate: new Date() },
              null,
              "PUT"
            );
            if (resp.status) {
              fetchInfoDetails(info.id);
              setConfirm(false);
              setDocInfo({});
            }
          }
        }}
      >
        <div className="d-flex ">
          {docInfo?.status === "rejected" || docInfo?.status === "blocked" ? (
            <Cancel color="error" sx={{ fontSize: 50 }} />
          ) : (
            <CheckCircle color="success" sx={{ fontSize: 50 }} />
          )}
          <div className="mt-4">
            {docInfo?.status === "verified"
              ? "Are you sure you want to approved this document?"
              : docInfo?.status === "rejected"
              ? "Are you sure you want to reject this document?"
              : docInfo?.status === "blocked"
              ? "Are you sure you want to block this account?"
              : docInfo?.status === "active"
              ? "Are you sure you want to activate this account?"
              : "Are you sure you want to cancel this Leave?"}
          </div>
        </div>
      </SmallDialog>
      {/* end of leave */}
      <DeleteDialog
        open={deleteOpen}
        onSuccess={() => {
          fetchInfoDetails(info.id);
        }}
        setOpen={setDeleteOpen}
        url={docInfo?.url!}
      />
    </div>
  );
};

// export default EmployeeDetails;
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDetails);
