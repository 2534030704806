import * as React from "react";
import CardHeader from "@mui/material/CardHeader";
import ExploreIcon from "@mui/icons-material/Explore";
import { Card } from "@mui/material";

export const DashboardCard = (props: any) => {
  const {
    color,
    content,
    icon,
    onClick,
    className,
    height,
    footerIcon,
    footerText,
  } = props;


  return (
    <Card
      style={{
        // backgroundColor: color ? color : "#ffffff",
        color: color ? color : "",
        // borderRadius: "1rem",
        height: height ?? "90%",
        // width:width??"80%"
      }}
      role="button"
      onClick={onClick}
      className={`${className} shadow`}
    >
      <CardHeader
        title={
          <span
            className="fw-bolder text-capitalize dataTableTitle"
          >
            {content?.title}
          </span>
        }
        subheader={
        <>
          <h6
            // component="div"
            className="text-capitalize fw-bold"
          >
            {content?.number}
          </h6>
            {footerIcon || footerText ? (
              <div className="fw-bold data-table-header text-nowrap">
                {footerIcon} {footerText}
              </div>
            ) : null}
        </>
        }
        action={
          icon ? (
            icon
          ) : (
            <ExploreIcon
              fontSize="large"
              style={{ color: color ?? "#054D6F" }}
            />
          )
        }
      />
    
    </Card>
  );
};
// export default  DashboardCard
