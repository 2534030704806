import { useEffect, useState } from "react";
import { default as ReactSelect } from "react-select";
import {
  Add,
  CheckCircle,
  Delete,
  Download,
  FormatAlignCenter,
  Stairs,
  Visibility,
} from "@mui/icons-material";
import Breadcrumb from "../../components/Breadcrumb";
import GeneralButton from "../../components/Button";
import { DatatableComponent } from "../../components/DatatableComponent";
import {
  fetchDetails,
  getFileMime,
  submitGeneralForm,
  validateFileNotNull,
} from "../../services/common";
import { Col, Row } from "react-bootstrap";
import { TabPanel, a11yProps } from "../employee/leave/List";
import {
  Box,
  Button,
  CircularProgress,
  Tab,
  Tabs,
  styled,
} from "@mui/material";
import { AddForm } from "../employee/AddForm";
import FileUpload from "../../components/FileUploader";
import SmallDialog from "../../components/SmallModel";
import StatusComponent from "../../components/StatusComponent";
import { mapDispatchToProps } from "../../store/helpers/mapDispatch";
import { connect } from "react-redux";
import { mapStateToProps } from "../../store/helpers/mapState";
import { CustomizedMenus } from "../../components/customizeMenu";
import DeleteDialog from "../../components/DeleteDialog";
import { useNavigate } from "react-router-dom";
import OpenDocuments from "../../components/openDocument";

const PromotionList = (props: any) => {
  const { report, DownloadExcelFile } = props;

  const [cols, setCols] = useState<any>([]);
  const [viewDocument, setViewDocument] = useState<any>();
  const [fiscalYearList, setFiscalYearList] = useState<Array<any>>([]);
  const [fiscalYear, setFiscalYear] = useState<any>();
  const [info, setInfo] = useState<any>();
  const [value, setValue] = useState(0);
  const [list, setList] = useState<Array<any>>([]);
  const [change, setChange] = useState(0);
  const [open, setOpen] = useState(0);
  const [document, setDocument] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [openPromoteDialogue, setOpenPromoteDialogue] = useState<any>(false);
  const [openDialogueExport, setOpenDialogueExport] = useState<any>(false);
  const [deleteOpen, setDeleteOpen] = useState<any>(0);
  const [deleteActionUrl, setDeleteActionUrl] = useState<
    string | undefined | null
  >();
  const [position, setPosition] = useState<any>([]);

  const navigate = useNavigate();

  const getFiscalYear = async () => {
    const fiscalYear = await fetchDetails("/fiscal/view");
    const position = await fetchDetails(`/position/view`);

    if (position.status) setPosition(position?.data);

    if (fiscalYear.status) {
      // const currentFiscalYear = fiscalYear?.data?.find(
      //   (year: any) => year.status === "open"
      // );
      setFiscalYearList(fiscalYear?.data);
      // setFiscalYear(currentFiscalYear);
    }
  };
  useEffect(() => {
    //fetch all FiscalYear
    getFiscalYear().then();
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const StyledTabs = styled((props: any) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      backgroundColor: "#035c85",
    },
  });
  const StyledTab = styled((props: any) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      "&.Mui-selected": {
        color: "#035c85",
      },
      "&.Mui-focusVisible": {
        color: "#035c85",
      },
    })
  );
  const onSubmit = async () => {
    setLoading(true);

    const resp = await submitGeneralForm(
      "promotion-marks/confirmPromotion",
      {},
      null,
      "PUT"
    );

    if (resp.status) {
      setOpenPromoteDialogue(false);
      setChange(change + 1);
      setValue(0);
    }
    setLoading(false);
  };

  const getMarksColumns = async () => {
    let newList: any = [];
    newList = [
      {
        label: "Employee Names",
        name: "employee.firstName",
        options: {
          customBodyRenderLite: (v: any, index: number) => {
            return (
              <span className="text-capitalize">{list[index]?.names}</span>
            );
          },
        },
      },
    ];
    for (let i = 0; i < 5; i++) {
      const year = fiscalYearList[i];

      newList.push({
        label: year?.name,
        name: "marks",
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (row: any, index: any) => {
            const resp = list[index]?.promotionMarks?.find(
              (item: any) => item?.fiscalYear === year?.id
            );

            return (
              <div
                className={
                  resp?.marks > 50
                    ? "text-success fw-bold"
                    : "text-danger fw-bold"
                }
                onClick={() => {
                  setOpen(1);
                  setInfo({
                    ...resp,
                    employeeNumber: list[index]?.employeeNumber,
                  });
                }}
                role="button"
                title="Details"
              >
                {resp?.marks}
              </div>
            );
          },
        },
      });
    }
    // newList.push({
    //   name: "id",
    //   label: "Action",
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRenderLite: (id: any, index: any) => {
    //       return (
    //         <CustomizedMenus
    //           onEdit={() => {
    //             setOpen(1);
    //             setInfo({
    //               ...list[index],
    //               employeeNumber: list[index]?.employeeNumber,
    //             });
    //           }}
    //           onDelete={() => {
    //             setDeleteActionUrl(
    //               `/promotion-marks/delete/${list[index]?.promotionMarks?.id}`
    //             );
    //             setDeleteOpen(1);
    //           }}
    //           needEdit={
    //             list[index]?.promotionMarks?.status !== "used" && !report
    //           }
    //           needDelete={
    //             list[index]?.promotionMarks?.status !== "used" && !report
    //           }
    //         />
    //       );
    //     },
    //   },
    // });
    setCols(newList);
  };
  useEffect(() => {
    getMarksColumns();
  }, [fiscalYearList, list]);

  return (
    <div>
      {report ? null : (
        <Breadcrumb
          endElement={
            <>
              {value === 1 ? (
                <>
                  <GeneralButton
                    variant="outlined"
                    size="medium"
                    name={"Record New"}
                    icon={<Add />}
                    onClick={() => {
                      setOpen(1);
                    }}
                  />
                  <GeneralButton
                    variant="outlined"
                    size="medium"
                    name={"Use Template"}
                    icon={<FormatAlignCenter />}
                    onClick={() => {
                      setOpenDialogueExport(true);
                    }}
                  />
                </>
              ) : null}
              {value === 2 ? (
                loading ? (
                  <CircularProgress />
                ) : (
                  <GeneralButton
                    variant="outlined"
                    size="medium"
                    name={"Promote"}
                    icon={<Stairs />}
                    onClick={() => {
                      setOpenPromoteDialogue(true);
                    }}
                  />
                )
              ) : null}
            </>
          }
        />
      )}

      <div className="mb-3 align-items-end d-flex">
        <div className={"flex-grow-1"}>
          <label className="col-form-label text-capitalize">Fiscal Year</label>
          <ReactSelect
            className={"text-nowrap"}
            options={fiscalYearList?.map(({ label, id, name }: any) => ({
              value: id,
              label: label ?? name,
            }))}
            onChange={(v: any) => {
              setFiscalYear({ ...v, name: v?.label });
            }}
          />
        </div>
      </div>
      <Row className={"justify-content-center p-3"}>
        <Col md={12} className=" bg-white">
          <Box sx={{ borderBottom: 1, Update: "divider" }}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <StyledTab label="Promoted" {...a11yProps(0)} />
              <StyledTab label="Marks" {...a11yProps(1)} />
              <StyledTab label="Eligible For Promotion" {...a11yProps(2)} />
            </StyledTabs>
          </Box>
          {/* start=========employees promotion list according to promoted fiscal year */}

          <TabPanel value={value} index={0}>
            <DatatableComponent
              exportButtons={report}
              columns={[
                {
                  label: "Employee Names",
                  name: "employee.firstName",
                  options: {
                    customBodyRenderLite: (v: any, index: number) => {
                      return (
                        <span className="text-capitalize">
                          {list[index]?.employee?.firstName}&nbsp;
                          {list[index]?.employee?.lastName}
                        </span>
                      );
                    },
                  },
                },
                {
                  label: `1st Year(%)`,
                  name: "firstYear.marks",
                },
                {
                  label: `2nd Year(%)`,
                  name: "secondYear.marks",
                },
                {
                  label: `3rd year(%)`,
                  name: "thirdYear.marks",
                },
                {
                  label: "Promoted From",
                  name: "promoteFrom.name",
                },
                {
                  label: "Promoted To",
                  name: "promoteTo.name",
                },
                {
                  label: "Current Position",
                  name: "positionTo",
                  options: {
                    customBodyRenderLite: (id: any, index: any) => {
                      return list[index]?.positionTo ? (
                        list[index]?.Position?.name
                      ) : (
                        <ReactSelect
                          className={"text-nowrap"}
                          options={position?.map(
                            ({ label, id, name }: any) => ({
                              value: id,
                              label: label ?? name,
                            })
                          )}
                          onChange={(v: any) => {
                            setInfo({ positionId: v.id, id: list[index]?.id });
                          }}
                        />
                      );
                    },
                  },
                },
                {
                  name: "id",
                  label: "Action",
                  options: {
                    filter: true,
                    sort: false,
                    customBodyRenderLite: (id: any, index: any) => {
                      return loading && list[index]?.id === info?.id ? (
                        <CircularProgress />
                      ) : (
                        <CustomizedMenus
                          onSave={async () => {
                            setLoading(true);
                            const resp = await submitGeneralForm(
                              `/promotion-marks/history/${info?.id}`,
                              { positionTo: info?.positionId },
                              null,
                              "PUT"
                            );
                            if (resp.status) {
                              setInfo({});
                              setChange(change + 1);
                            }
                            setLoading(false);
                          }}
                          onView={() => {
                            navigate(
                              `/console/employee/all-employees/details/${list[index]?.employeeId}`
                            );
                          }}
                          needSave={
                            list[index]?.id === info?.id && info?.positionId
                          }
                        />
                      );
                    },
                  },
                },
              ]}
              url={`/promotion-marks/eligible/datatable?status=promoted&&fiscalYear=${fiscalYear?.value}&&change=${change}`}
              title={`Employees promoted in year of: ${fiscalYear?.name}`}
              processData={(data: any) => {
                setList(data.data);
                return data;
              }}
            />
          </TabPanel>
          {/* end=========employees promotion list according to promoted fiscal year */}

          {/* start=========list of marks got by employees */}
          <TabPanel value={value} index={1}>
            <DatatableComponent
              exportButtons={report}
              columns={cols}
              url={`/promotion-marks/datatable?${
                fiscalYear ? `fiscalYear=${fiscalYear?.value}` : undefined
              }&&change=${change}`}
              title={`Marks list of ${fiscalYear?.name}`}
              processData={(data: any) => {
                setList(data.data);
                return data;
              }}
            />
          </TabPanel>
          {/* end=========list of marks got by employees */}
          {/* start=========list of employees who are eligible for promotion */}

          <TabPanel value={value} index={2}>
            <DatatableComponent
              exportButtons={report}
              columns={[
                {
                  label: "Employee Names",
                  name: "employee.firstName",
                  options: {
                    customBodyRenderLite: (v: any, index: number) => {
                      return (
                        <span className="text-capitalize">
                          {list[index]?.employee?.names}
                        </span>
                      );
                    },
                  },
                },
                {
                  label: `1st Year(%)`,
                  name: "firstYear.marks",
                },
                {
                  label: `2nd Year(%)`,
                  name: "secondYear.marks",
                },
                {
                  label: `3rd year(%)`,
                  name: "thirdYear.marks",
                },
                {
                  label: "Current Scale",
                  name: "promoteFrom.name",
                },
                // {
                //   label: "Promoted To",
                //   name: "promoteTo.name",
                // },
                {
                  label: "Status",
                  name: "status",
                  options: {
                    customBodyRender: (status: any) => (
                      <StatusComponent status={status} badge={false} />
                    ),
                  },
                },
                // {
                //   name: "id",
                //   label: "Action",
                //   options: {
                //     filter: false,
                //     sort: false,
                //     customBodyRenderLite: (row: any, index: any) => {
                //       return <div className=""></div>;
                //     },
                //   },
                // },
              ]}
              url={`/promotion-marks/eligible/datatable`}
              title={`Employee's promotion status`}
              processData={(data: any) => {
                setList(data.data);
                return data;
              }}
            />
          </TabPanel>
          {/* end=========list of employees who are eligible for promotion */}
        </Col>
      </Row>
      <AddForm
        title={
          info?.id ? (
            <div className="row justify-content-between">
              <span className="me-2 col">{"Update Marks"}</span>{" "}
              {info?.status === "not used" ? (
                <div className="col">
                  <Button
                    size="small"
                    color="error"
                    variant="contained"
                    startIcon={<Delete fontSize="small" />}
                    onClick={() => {
                      setDeleteActionUrl(`/promotion-marks/delete/${info?.id}`);
                      setDeleteOpen(1);
                      setOpen(0);
                    }}
                  >
                    {"Delete"}
                  </Button>{" "}
                </div>
              ) : null}
            </div>
          ) : (
            "Record new marks"
          )
        }
        maxWidth="md"
        mainFields={[
          {
            title: "",
            fields: [
              {
                label: "Employee ID",
                name: "employeeNumber",
                col: 12,
                // type: "number",
                required: true,
                value: info?.employeeNumber,
                disabled: info?.status === "used",
              },
              {
                label: "Marks",
                name: "marks",
                col: 12,
                type: "number",
                required: true,
                disabled: info?.status === "used",
                value: info?.marks,
                min: 1,
              },
              {
                label: "Year",
                name: "fiscalYear",
                type: "select",
                // disabled:true,
                value: info?.fiscalYear ?? fiscalYear?.value,
                values: fiscalYearList.map((v: any) => ({
                  label: v.name,
                  value: v.id,
                })),
                required: true,
                disabled: info?.status === "used",
              },
              {
                label: "Evaluation report",
                name: "supportingDocument",
                type: "file",
                multiple: true,
                col: 12,
                value: document,
                disabled: info?.status === "used",
                validate: validateFileNotNull,
                render: () => {
                  return (
                    <FileUpload
                      setFiles={setDocument}
                      files={document}
                      title="Upload"
                      currentFile={document}
                    />
                  );
                },
              },
            ],
          },
        ]}
        url={
          info?.id
            ? `promotion-marks/create?id=${info?.id}`
            : "promotion-marks/create"
        }
        method={"POST"}
        onSuccess={() => {
          setChange(change + 1);
          setOpen(0);
        }}
        open={open}
        setOpen={() => {
          setOpen(0);
        }}
      />
      {/* //confirm promotion dialogue */}

      <SmallDialog
        open={openPromoteDialogue}
        setOpen={setOpenPromoteDialogue}
        onSubmit={() => {
          onSubmit();
        }}
      >
        <div className="d-flex ">
          <CheckCircle color="success" sx={{ fontSize: 50 }} />
          <div className="mt-4">
            {"Are you sure you want to confirm promotion?"}
          </div>
        </div>
      </SmallDialog>
      <SmallDialog
        maxWidth="lg"
        title={
          viewDocument ? "Evaluation report" : "Record Marks using template"
        }
        buttonTitle="Submit"
        needButton={true}
        open={openDialogueExport}
        setOpen={setOpenDialogueExport}
      >
        {viewDocument ? (
          <div className={"row justify-content-center"}>
            <div className={"col-md-7"}>
              {document?.map((v: string) => (
                <OpenDocuments
                  downloadable={true}
                  card={true}
                  document={v}
                  mimeType={getFileMime(v)}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12 mb-4 d-flex justify-content-end">
              <GeneralButton
                variant="outlined"
                size="medium"
                name={"Export template"}
                icon={<Download />}
                onClick={async () => {
                  await DownloadExcelFile(
                    `promotion-marks/download-marks-template`
                  );
                }}
              />
            </div>
            <div className="col-md-12">
              <AddForm
                title={""}
                inline={true}
                col={12}
                mainFields={[
                  {
                    title: "",
                    fields: [
                      {
                        label: "status",
                        name: "status",
                        value: "uploaded",
                        hidden: true,
                      },
                      {
                        label: "Year",
                        name: "fiscalYear",
                        type: "select",
                        // disabled:true,
                        // value: fiscalYear?.id,
                        values: fiscalYearList.map((v: any) => ({
                          label: v.name,
                          value: v.id,
                        })),
                        required: true,
                      },
                      {
                        label: "Marks file",
                        name: "templateFile",
                        type: "file",
                        multiple: true,
                        col: 12,
                        value: document,
                        required: true,
                        validate: validateFileNotNull,
                        render: () => {
                          return (
                            <FileUpload
                              setFiles={setDocument}
                              files={document}
                              title="Upload marks"
                              required={true}
                            />
                          );
                        },
                      },
                    ],
                  },
                ]}
                url={"promotion-marks/import/marks"}
                method={"POST"}
                onSuccess={() => {
                  setChange(change + 1);
                  setOpenDialogueExport(false);
                  setDocument([]);
                }}
              />
            </div>
          </div>
        )}
      </SmallDialog>
      {/* //delete marks dialogue? */}
      <DeleteDialog
        open={deleteOpen}
        onSuccess={() => {
          setChange(change + 1);
        }}
        setOpen={setDeleteOpen}
        url={deleteActionUrl!}
      />
    </div>
  );
};

// export default PromotionList;
export default connect(mapStateToProps, mapDispatchToProps)(PromotionList);
