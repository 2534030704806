import Breadcrumb from "../../../components/Breadcrumb";
import { Col, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import {
  fetchDetails,
  fetchPaidMembersFromIPPIS,
  getError,
  getSuccessMessage,
  monthList,
  submitGeneralForm,
} from "../../../services/common";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/helpers/mapState";
import { mapDispatchToProps } from "../../../store/helpers/mapDispatch";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import {
  Button,
  CircularProgress,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { default as ReactSelect } from "react-select";
import GeneralButton from "../../../components/Button";
import { Check, CheckCircle, Pending, Preview } from "@mui/icons-material";
import moment from "moment";
import SmallDialog from "../../../components/SmallModel";
import { toast } from "react-toastify";
import TabHeadersComponent from "../../../components/UI/Tabs";
import { TabPanel } from "../../employee/leave/List";
import StatusComponent from "../../../components/StatusComponent";

const CreateGeneralContribution = () => {
  const [confirm, setConfirm] = React.useState<any>(false);
  const [fiscal, setFiscal] = React.useState<any>();
  const [list, setList] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);
  const [fixed, setFixed] = React.useState(0);
  const [rate, setRate] = React.useState(0);
  const [selectedPeriod, setPeriod] = React.useState<any>();
  const [approveData, setApproveData] = React.useState<any>([]);
  const [contributionList, setContributionList] = React.useState<any>([]);
  const [loanList, setLoanList] = React.useState<any>([]);
  const [value, setValue] = React.useState(0);
  const [fixedLoanList, setFixedLoanList] = React.useState<any>([]);
  const [rateContributionList, setRateContributionList] = React.useState<any>(
    []
  );
  const [refresh, setRefresh] = React.useState(false);

  const onSubmit = async () => {
    setLoading(true);
    const resp = await submitGeneralForm(`approvalContribution/create`, {
      fiscal: fiscal?.name,
      month: currentMonth,
      list: approveData?.length>0?approveData:list,
    });

    if (resp.status) {
      setConfirm(false);
      await fetchFiscal();
      setRefresh(true)
      setApproveData([]);
      setPeriod('')
      toast.success(getSuccessMessage(resp));
    } else {
      toast.error(getError(resp));
    }
    setLoading(false);
  };

  const checkPaid = async () => {
    setLoading(true);
    const resp = await fetchPaidMembersFromIPPIS(selectedPeriod);

    if (resp.status) {
      setLoading(false);
      // setList(
      //   resp.data?.map((item: any) => ({
      //     amount: item?.amount,
      //     lastName: item?.lastName,
      //     firstName: item?.firstName,
      //     creditor: item?.creditor,
      //     period: item?.periodId,
      //     employeeId: item?.employeeId,
      //   }))
      // );

      const filterUnpaidContributions = resp?.data?.map((o1: any) => {
        let status
        const result= contributionList.some((o2: any) => {
          status=o2.status
          return (
            o1.employeeId === o2.employeeNumber && selectedPeriod === o2.period
          )

        });
        return {...o1,checkedStatus:result&&status!=='approved'?'Not Yet Confirmed':result&&status==='approved'?'Payment Confirmed':'Not Found'}
      });

      const filterUnpaidLoans = resp?.data?.map((o1: any) => {
        let status
        const result= loanList.some((o2: any) => {
          status=o2.status
          // const period = moment()
          // .set({ date: o2.period }) //default date of contribution is on 5th of each month
          // .format("YYYY-DD")
          return o1.employeeId === o2.employeeNumber&& selectedPeriod === o2.period ;
        });
        return {...o1,checkedStatus:result&&status!=='approved'?'Not Yet Confirmed':result&&status==='approved'?'Payment Confirmed':'Not Found'}
 
      });
      const combinedList = filterUnpaidContributions.concat(filterUnpaidLoans);
      const filterPending=combinedList?.filter((item:any)=>item?.checkedStatus==='Not Yet Confirmed')
      setList(
        filterPending?.map((item: any) => ({
          amount: item?.amount,
          lastName: item?.lastName,
          firstName: item?.firstName,
          creditor: item?.creditor,
          period: item?.periodId,
          employeeId: item?.employeeId,
          checkedStatus:item?.checkedStatus
        }))
      );
      const fixed = filterUnpaidLoans?.filter(
        (items: any) => items?.creditor.toLowerCase().indexOf("fixed") !== -1
      ); // monthly payment of loan

      const rate = filterUnpaidContributions?.filter(
        (items: any) => items?.creditor.toLowerCase().indexOf("rate") !== -1
      ); // monthly payment of contribution
      let totalFixed = fixed?.reduce(
        (prev: any, next: any) => prev + next.amount,
        0
      );
      let totalRate = rate?.reduce(
        (prev: any, next: any) => prev + next.amount,
        0
      );
      setFixed(totalFixed);
      setRate(totalRate);
      setRateContributionList(
        rate?.map((item: any) => ({
          amount: item?.amount,
          lastName: item?.lastName,
          firstName: item?.firstName,
          creditor: item?.creditor,
          period: item?.periodId,
          employeeId: item?.employeeId,
          checkedStatus:item?.checkedStatus
        }))
      );
      setFixedLoanList(
        fixed?.map((item: any) => ({
          amount: item?.amount,
          lastName: item?.lastName,
          firstName: item?.firstName,
          creditor: item?.creditor,
          period: item?.periodId,
          employeeId: item?.employeeId,
          checkedStatus:item?.checkedStatus
        }))
      );
      setRefresh(false)

    }
  };

  const fetchFiscal = async () => {
    const resp = await fetchDetails(`approvalContribution/fiscal/active`);
    if (resp.status) {
      setFiscal(resp.data);
    }
    // const unPaid = await fetchDetails(`approvalContribution/pending`);
    // if (unPaid.status) {
    //   const contr = unPaid.data?.contribution;
    //   const member = unPaid.data?.member;
    //   setContributionList(
    //     contr?.map((item: any) => ({
    //       employeeNumber: item?.CaisseMember?.employee?.employeeNumber,
    //       period: item.period,
    //     }))
    //   );
    //   setLoanList(
    //     member?.map((item: any) => ({
    //       employeeNumber: item?.CaisseMember?.employee?.employeeNumber,
    //       period: item.currentPaidMonth,
    //     }))
    //   );
    // }
  };

  const fetchUnpaidPeriod = async () => {

    const unPaid = await fetchDetails(`approvalContribution/pending?period=${selectedPeriod}`);
    if (unPaid.status) {
      const contr = unPaid.data?.contribution;
      const member = unPaid.data?.member;
      setContributionList(
        contr?.map((item: any) => ({
          employeeNumber: item?.CaisseMember?.employee?.employeeNumber,
          period: item.period,
          status:item?.status
        }))
      );
      setLoanList(
        member?.map((item: any) => ({
          employeeNumber: item?.Loan?.CaisseMember?.employee?.employeeNumber,
          period: item.period,
          status:item?.status
        }))
      );
    }
  };
  useEffect(()=>{
    const regex = /^\d{4}-\d{2}$/;
    if(selectedPeriod &&regex.test(selectedPeriod)&&refresh){
      checkPaid().then()
    }
  },[refresh, selectedPeriod]);

  useEffect(()=>{
    const regex = /^\d{4}-\d{2}$/;
    if(selectedPeriod &&regex.test(selectedPeriod)){
      fetchUnpaidPeriod().then()
    }
  },[selectedPeriod]);

  useEffect(() => {
    setPeriod(moment().format("YYYY-MM"));
    fetchFiscal().then();
  }, []);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const labels = [
    { label: "Contributions", icon: <Pending /> },
    { label: "Loans", icon: <Pending /> },
  ];
  const currentMonth = fiscal?.CdeAccount?.currentMonth;
  const currentPeriod = moment().format("YYYY-MM");
  const months = monthList?.filter((list) => list?.id === currentMonth);
  const month = months.map((v: any) => ({
    label: v.name,
    value: v.id,
  }));
  const columns = [
    {
      label: "Last Name",
      name: "lastName",
    },
    {
      label: "First Name",
      name: "firstName",
    },
    {
      label: "Amount",
      name: "amount",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (amount: any) => amount?.toLocaleString(),
      },
    },
    {
      label: "Creditor",
      name: "creditor",
    },
    {
      label: "Period",
      name: "period",
    },
    {
      label: "Status",
      name: "checkedStatus",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (v: any) => <StatusComponent badge={true} status={v}/>,
      },
    },
  ];
  const options: MUIDataTableOptions = {
    filter: false,
    // filterType: "dropdown",
    responsive: "standard",
    enableNestedDataAccess: ".",
    elevation: 1,
    rowsPerPage:100,
    rowsPerPageOptions: [ 100, 200, 400, 600, 800, 1000],
    onRowSelectionChange: (
      currentRowsSelected: any[],
      allRowsSelected: any[],
      rowsSelected?: any[]
    ) => {
      setApproveData(
        list?.filter((v: any, index: any) => rowsSelected?.includes(index))
      );
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <div>
        {approveData?.length ? (
          <Button
            startIcon={<Check />}
            onClick={() => {
              setConfirm(true);
            }}
            size={"medium"}
            variant="contained"
            color="success"
          >
            Approve
          </Button>
        ) : null}
      </div>
    ),
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    download: false,
    print: false,
    expandableRowsHeader: false,
    searchPlaceholder: "Search here...",
  };
  const theme = () =>
    createTheme({
      components: {
        MuiTable: {
          styleOverrides: {
            root: {
              border: 1,
              // width:'10% !important'
              // backgroundColor: "#f8f9fa",
            },
          },
        },

        MuiTableCell: {
          styleOverrides: {
            head: {
              paddingLeft: "0px !important",
              backgroundColor: "#054D6F !important",
              color: "#F9FAFC !important",
              whiteSpace: "nowrap",
              "&:not(:last-child)": {
                borderRight: [[1, "solid", "#c0c0c0"]],
              },
            },
            body: {
              fontSize: "12px !important",
              whiteSpace: "nowrap",
              fontFamily: "Inter !important",
            },
          },
        },
        MuiTableSortLabel: {
          styleOverrides: {
            root: {
              // alignItems: "flex-start",
              // background: "white",
              color: "white !important",
              fontFamily: "Inter !important",
            },
            active: {
              // background: "white",
              // color: "white",
            },
          },
        },
        MuiTableFooter: {
          styleOverrides: {
            root: {
              background: "#f5f7f7 !important",
            },
          },
        },
      },
    });

  return (
    <div>
      <Breadcrumb />
      <Row className="mb-3">
        <Col md={3}>
          <label className="col-form-label text-capitalize">Fiscal Year</label>

          <input className="form-control" value={fiscal?.name} readOnly />
        </Col>
        <Col md={3}>
          <label className="col-form-label text-capitalize">Month</label>
          <ReactSelect
            options={month}
            value={month?.find((item: any) => item?.value === currentMonth)}
            className="text-nowrap"
            // onChange={(v: any) => {
            //   setFiscalYear(v?.value);
            // }}
          />
        </Col>
        <Col md={3}>
          <label className="col-form-label text-capitalize">
            Period(YYYY-MM)
          </label>
          <input
            className="form-control"
            value={selectedPeriod}
            onChange={(e: any) => setPeriod(e.target.value)}
          />
        </Col>
        <Col md={3}>
          <label className="col-form-label text-capitalize text-white">
            Month
          </label>

          <div>
            <GeneralButton
              icon={<Preview />}
              onClick={() => {
                checkPaid();
              }}
              name={"Preview"}
              size={"medium"}
              variant="outlined"
            />
            {list?.length &&
            // currentPeriod === selectedPeriod &&
            approveData?.length === 0 ? (
              <GeneralButton
                icon={<Check />}
                onClick={() => {
                  setConfirm(true);
                }}
                name={`Confirm Payment(${list?.length})`}
                size={"medium"}
                variant="outlined"
              />
            ) : null}
          </div>
        </Col>
      </Row>
      <Row className={"justify-content-center"}>
        <Col md={12}>
          {/* <AddForm
            inline={true}
            col={12}
            mainFields={[
              {
                title: "",
                fields: [
                  {
                    label: "Active Fiscal Year",
                    name: "fiscal",
                    type: "text",
                    value: fiscal?.name,
                    disabled: true,
                    required: true,
                    col: 3,
                  },
                  {
                    label: "Month",
                    name: "month",
                    type: "select",
                    value: currentMonth,
                    values: month.map((v: any) => ({
                      label: v.name,
                      value: v.id,
                    })),
                    disabled: true,
                    required: true,
                    col: 3,
                  },
                  {
                    label: "Token",
                    name: "token",
                    value: token,
                    disabled: true,
                    required: true,
                    col: 3,
                  },
                ],
              },
            ]}
            url={"approvalContribution/create"}
            method={id ? "PUT" : "POST"}
            title={"Approve contribution and loan monthly installment"}
            onSuccess={() => navigate(-1)}
          /> */}

          {loading ? (
            <div
              className={
                "position-absolute h-100 w-100 top-0 start-0 d-flex align-items-center justify-content-center"
              }
            >
              <div
                className={"bg-white p-3 rounded-4 shadow-sm text-center"}
                style={{ zIndex: 20000 }}
              >
                <div>
                  <CircularProgress color={"success"} />
                </div>
                <span>
                  <Typography style={{ fontFamily: "inter" }}>
                    Loading ....
                  </Typography>
                </span>
              </div>
            </div>
          ) : null}
          <ThemeProvider theme={theme}>
            <TabHeadersComponent
              handleChange={handleChange}
              value={value}
              labels={labels}
            />
            <TabPanel value={value} p={0} index={0}>
              <MUIDataTable
                title={
                  <div className="p-3">
                    <h6 className="fw-bold mb-2 text-muted">
                      PAYMENT LIST(CONTRIBUTIONS )
                    </h6>
                    <h6 className="fw-bold text-muted">
                      PERIOD:
                      <span className="text-info">
                        {selectedPeriod??moment().format("YYYY-MM")}
                      </span>
                    </h6>
                    {/* <h6 className="fw-bold text-muted">
                    TOTAL LOAN AMOUNT:<span className="text-info">{fixed?.toLocaleString()}</span>
                  </h6> */}
                    <h6 className="fw-bold text-muted">
                      TOTAL CONTRIBUTION AMOUNT:
                      <span className="text-info">
                        {rate?.toLocaleString()}
                      </span>
                    </h6>
                  </div>
                }
                data={rateContributionList}
                columns={columns}
                options={options}
              />
            </TabPanel>
            <TabPanel value={value} p={0} index={1}>
              <MUIDataTable
                title={
                  <div className="p-3">
                    <h6 className="fw-bold mb-2 text-muted">
                      PAYMENT LIST(LOAN INSTALLMENT)
                    </h6>
                    <h6 className="fw-bold text-muted">
                      PERIOD:
                      <span className="text-info">
                        {moment().format("YYYY-MM")}
                      </span>
                    </h6>
                    <h6 className="fw-bold text-muted">
                      TOTAL LOAN AMOUNT:
                      <span className="text-info">
                        {fixed?.toLocaleString()}
                      </span>
                    </h6>
                    {/* <h6 className="fw-bold text-muted">
                    TOTAL CONTRIBUTION AMOUNT:<span className="text-info">{rate?.toLocaleString()}</span>
                  </h6> */}
                  </div>
                }
                data={fixedLoanList}
                columns={columns}
                options={options}
              />
            </TabPanel>
          </ThemeProvider>
        </Col>
      </Row>

      <SmallDialog
        open={confirm}
        setOpen={setConfirm}
        onSubmit={() => {
          onSubmit();
        }}
      >
        <div className="d-flex ">
          <CheckCircle color="success" sx={{ fontSize: 50 }} />
          <div className="mt-4">
            {"Are you sure you want to confirm payment"}
          </div>
        </div>
      </SmallDialog>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateGeneralContribution);
