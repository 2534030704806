// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-alert {
  z-index: 100;
  position: absolute;
  width: auto;
  bottom: 10px;
  right: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/status-alert.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,WAAW;AACb","sourcesContent":[".status-alert {\n  z-index: 100;\n  position: absolute;\n  width: auto;\n  bottom: 10px;\n  right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
