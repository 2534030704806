import {useNavigate, useParams} from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import {Col, Row} from "react-bootstrap";
import {FormDialog} from "../../../components/FormDialog";
import React, {useEffect} from "react";
import {fetchDetails} from "../../../services/common";
import moment from "moment";

const CreateFiscalYear = ()=>{
    const navigate = useNavigate();
    const [info,setInfo] = React.useState<any>();
    const {id} = useParams();


    const fetchInfoDetails = async (id:string)=>{

        const resp = await fetchDetails(`fiscal/view/${id}`);
        if(resp.status){
            setInfo({...resp.data,end:moment(resp.data.end).format("YYYY-MM-DD"),
                start: moment(resp.data.start).format("YYYY-MM-DD")});
        }
    }


    useEffect(()=>{
        if (id){
            fetchInfoDetails(id).then();
        }
    },[id]);
    return <div>
        <Breadcrumb/>
        <Row className={'justify-content-center'}>
            <Col md={5}>
                <FormDialog inline={true} fields={[
                    {
                        label:"Start Date",
                        name:"startDate",
                        type:'date',
                        value:info?.start,
                        required:true,
                    },
                    {
                        label:"End Date",
                        name:"endDate",
                        type:'date',
                        value:info?.end,
                        required:true,

                    },
                    // {
                    //     label:"Status",
                    //     name:"status",
                    //     type:'select',
                    //     value:info?.status,
                    //     values:[
                    //         {
                    //             label:"Open",
                    //             value:"open",
                    //         },
                    //         {
                    //             label:"Closed",
                    //             value:"closed",
                    //         },
                    //     ],
                    //     required:true,

                    // }
                ]} url={id ? `fiscal/update/${id}` : 'fiscal/create'} method={id ? 'PUT' : 'POST'} title={id ? 'Update Fiscal Year' : 'Create New Fiscal Year'} onSuccess={()=>navigate('../')}/>
            </Col>
        </Row>
    </div>
}

export default CreateFiscalYear;
