import Breadcrumb from "../../../components/Breadcrumb";
import {Box, Card, Tab, Tabs} from "@mui/material";
import {a11yProps, TabPanel} from "../../employee/leave/List";
import React, {useState} from "react";
import PromotionList from "../../promotion/List";
import PromotedReport from "./PromotedReport";
import MarksReport from "./MarksReport";
// import Placeholder from "../../../components/Placeholder";
// import PromotionStat from "../../promotion/promotionStatus/List";
// import PromotionMarksList from "../../promotion/promotionMarks/List";

const PromotionReportIndex=()=>{
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return <div>
        <Breadcrumb/>
        <Card style={{overflow:"visible"}}>
            <Box sx={{ borderBottom: 0.5, Update: "divider" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <Tab label="Promotion" {...a11yProps(0)} />
                    <Tab label="Marks" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} p={2} index={0}>
                <PromotedReport/>
            </TabPanel>
            <TabPanel value={value} p={2} index={1}>
                <MarksReport/>
            </TabPanel>
        </Card>
    </div>
}

export default PromotionReportIndex;
