import {useNavigate} from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import {DatatableComponent} from "../../../components/DatatableComponent";
import IconButton from "@mui/material/IconButton";
import { BorderColorOutlined } from "@mui/icons-material";
import moment from "moment/moment";
import { Tooltip } from "@mui/material";

const EchelonList = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Breadcrumb
        addButton={{ label: "Add Echelon", onClick: () => navigate("create") }}
      />
      <DatatableComponent
        columns={[
          {
            label: "No",
            name: "order",
          },
          {
            label: "Name",
            name: "name",
          },
          {
            label: "Date",
            name: "createdAt",
            options: {
              customBodyRender: (v: any) =>
                moment(v).format("YYYY-MMM-DD HH:mm"),
            },
          },
        ]}
        url={"echlon/datatable"}
        actions={(id:string) => [
            <Tooltip title="delete" className={"border-0 bg-transparent"}>
            <BorderColorOutlined onClick={() => navigate(`update/${id}`)} fontSize="small" />
          </Tooltip>
          ,
        ]}
        title={"Echelon List"}
        // deleteUrl={(id:string) => `echlon/delete/${id}`}
      />
    </div>
  );
};

export default EchelonList;
