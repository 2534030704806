import { useEffect, useState } from "react";
import { AddForm } from "../../employee/AddForm";
import FileUpload from "../../../components/FileUploader";
import { validateFileNotNull } from "../../../services/common";

const PayBulk = (props: any) => {
  const { setAddPayment, onSuccess, addPayment, loan } = props;
  const [month, setMonth] = useState<any>(0);
  // const [amount, setAmount] = useState<any>(0);
  const [info, setInfo] = useState<any>();
  const [paymentProof, setPaymentProof] = useState<any>([]);

  useEffect(() => {
    if (loan) {
      setInfo(loan);
      setMonth(0)
      setPaymentProof([])
    }
  }, [loan]);

  useEffect(() => {
      setPaymentProof([])
  }, [month]);

const amount=info?.monthlyAmount*month
const minMonth=info?.terms-info?.totalMonthsPaid
  return (
    <AddForm
      title={"Multiple installment payment"}
      maxWidth="md"
      formAlert={
        <div>
          <strong>
            N.B : Amount paid must be above monthly installment
          </strong>
        </div>
      }
      mainFields={[
        {
          title: "",
          fields: [
            {
              label: "Month to pay(s)",
              name: "months",
              type: "number",
              col: 12,
              required: true,
              value:month,
              max: minMonth,
              min:2,
              setValue:setMonth
            },
            {
              label: "Amount(RWF)",
              name: "amount",
              type: "number",
              col: 12,
              // required: true,
              disabled:true,
              value:info?.monthlyAmount*month,
              // min: info?.monthlyAmount,
              // setValue:setAmount
            },
    
            {
              label: "Proof of payment",
              name: "supportingDocument",
              type: "file",
              hidden:amount<=info?.monthlyAmount,
              multiple: true,
              required: true,
              col: 12,
              value: paymentProof,
              validate: validateFileNotNull,
              render: () => {
                return (
                  <FileUpload
                    setFiles={setPaymentProof}
                    files={paymentProof}
                    required={true}
                    title="Upload"
                  />
                );
              },
            },
            {
              label: "Reason",
              name: "reason",
              type: "textarea",
              hidden:amount<=info?.monthlyAmount,
              value: info?.reason,
              required: true,
              col: 12,
            },
          ],
        },
      ]}
      url={`loan/pay-bulk-installment/${info?.id}`}
      method={"PUT"}
      onSuccess={onSuccess}
      open={addPayment}
      setOpen={() => setAddPayment(0)}
    />
  );
};
export default PayBulk;
