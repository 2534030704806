import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import Breadcrumb from "../../../components/Breadcrumb";
import { DatatableComponent } from "../../../components/DatatableComponent";
import { CustomizedMenus } from "../../../components/customizeMenu";
import DeleteDialog from "../../../components/DeleteDialog";
import StatusComponent from "../../../components/StatusComponent";
import { Col, Row } from "react-bootstrap";
import { mapDispatchToProps } from "../../../store/helpers/mapDispatch";
import { mapStateToProps } from "../../../store/helpers/mapState";
import { connect } from "react-redux";
import GeneralButton from "../../../components/Button";
import { Add } from "@mui/icons-material";

const SocialFundList = (props: any) => {
  const navigate = useNavigate();
  const [list, setList] = useState<Array<any>>([]);
  const [deleteOpen, setDeleteOpen] = useState(0);
  const [deleteActionUrl, setDeleteActionUrl] = useState<
    string | undefined | null
  >();
  const [change, setChange] = useState(0);
  const pendingRequest = list?.find((item: any) => item?.status === "pending");

  const reload = () => {
    setChange((v) => v + 1);
  };

  return (
    <div>
      <Breadcrumb
        endElement={
          pendingRequest ?null: (
            <GeneralButton
              variant="outlined"
              size="medium"
              name={"New Request"}
              icon={<Add />}
              onClick={async () => {
                navigate("request/new");
              }}
            />
          ) 
        }
      />
      <Row>
        <Col md={12}>
          <DatatableComponent
            columns={[
              {
                label: "Reason",
                name: "SocialFundType.name",
                options: {
                  filter: true,
                  sort: false,
                  customBodyRender: (v: any) => (
                    <span className="text-capitalize">{v}</span>
                  ),
                },
              },
              {
                label: "Amount",
                name: "amount",
                options: {
                  filter: true,
                  sort: false,
                  customBodyRender: (v: any) => v?.toLocaleString(),
                },
              },
              {
                label: "Date",
                name: "createdAt",
                options: {
                  filter: true,
                  sort: false,
                  customBodyRender: (v: any) => moment(v).format("DD/MM/YYYY"),
                },
              },
              {
                label: "Status",
                name: "status",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (status: any) => (
                    <StatusComponent status={status} badge={false} />
                  ),
                },
              },
              {
                name: "id",
                label: "Action",
                options: {
                  filter: true,
                  sort: false,
                  customBodyRenderLite: (id: any, index: any) => {
                    const canUpdate =
                      list[index]?.status === "request action" ||
                      list[index]?.status === "pending";
                    const canDelete = list[index]?.status === "pending";
                    return (
                      <CustomizedMenus
                        onView={() => {
                          navigate(`details/${list[index].id}`);
                        }}
                        onEdit={() => {
                          navigate(`request/${list[index].id}`);
                        }}
                        onDelete={() => {
                          setDeleteOpen(1);
                          setDeleteActionUrl(
                            `/social-fund/${list[index].id}`
                          );
                        }}
                        needEdit={canUpdate}
                        needDelete={canDelete}
                      />
                    );
                  },
                },
              },
            ]}
            url={`social-fund/datatable?change=${change}`}
            title={"Social Funds"}
            processData={(data: any) => {
              setList(data.data);
              return data;
            }}
          />
        </Col>
      </Row>
      {deleteActionUrl ? (
        <DeleteDialog
          open={deleteOpen}
          onSuccess={reload}
          setOpen={setDeleteOpen}
          url={deleteActionUrl!}
        />
      ) : null}
    </div>
  );
};

// export default SocialFundList;
export default connect(mapStateToProps, mapDispatchToProps)(SocialFundList);
