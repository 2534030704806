import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import React, {useEffect} from "react";
import BlueNavBar from "../layouts/BlueNavBar";
import {connect} from "react-redux";
import {mapStateToProps} from "../store/helpers/mapState";
import {mapDispatchToProps} from "../store/helpers/mapDispatch";
import useStyles from "./style";

function SavingApprovalLayout(props:any) {
  const title = "Caisse de entraide";
  const {GetActiveCdeInfo} = props;

    useEffect(() => {
        GetActiveCdeInfo();
    }, [GetActiveCdeInfo]);

    const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {/* <BlueNavBar
        baseUrl={"/console/approveSaving/"}
        items={[
          {
            label: "Summary",
            href: "",
            icon: "bi bi-speedometer",
          },
          {
            label: "Members/Applications",
            href: "applications",
            icon: "bi bi-bank",
          },
          {
            label: "Contributions",
            href: "contributions",
            icon: "bi bi-wallet2",
          },
          {
            label: "Loans",
            href: "loans",
            icon: "bi bi-bank",
          },
          {
            label: "Share Increment",
            href: "increments",
            icon: "bi bi-arrow-up",
          },
          {
            label: "Exit CDE",
            href: "exit",
            icon: "bi bi-escape",
          },
          {
            label: "Social Funds",
            href: "funds",
            icon: "bi bi-people",
          },
          //    {
          //     label:"Penalty",
          //     href:"penaltyPayments",
          //     icon:"bi bi-cash"
          // },
          {
            label: "Expenses",
            href: "expenses",
            icon: "bi bi-files",
          },
          {
            label: "Settings",
            href: "settings",
            icon: "bi bi-cash",
          },
        ]}
      /> */}
       <div className="container-fluid">
      <div className="row">
          <div className='col-md-12'>
          <Outlet />
          </div>
          </div>
      </div>
    </>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(SavingApprovalLayout);
