import React, { JSX, useEffect, useState } from "react";
import moment from "moment";
import { Chip } from "@mui/material";
import ReportEngineComponent from "../../../components/ReportEngineComponent";
import { fetchDetails } from "../../../services/common";

function InvestmentReport(): JSX.Element {
  const [fiscalYears, setFiscalYears] = useState([]);
  const [value, setValue] = useState<any>({});

  const loadInitialData = async () => {
    // const employeeList = await fetchDetails("report/caisse/members");
    const fiscalYears = await fetchDetails(`fiscal/view`);
    if (fiscalYears.status) {
      setFiscalYears(
        fiscalYears.data.map(({ name, id }: any) => ({
          label: name,
          value: id,
        }))
      );
    }
    // if (employeeList.status) setRequesters(employeeList?.data);
  };

  useEffect(() => {
    loadInitialData().then();
  }, []);
  return (
    <ReportEngineComponent
    setValue={setValue}
    value={value}
      title={`Investment Report`}
      url={`cde-report/investment`}
      columns={[
        {
          label: "Fiscal Year",
          name: "FiscalYear.name",
          data: "FiscalYear.name",
        },
        {
          label: "Title",
          name: "title",
        },
        {
          label: "Description",
          name: "description",
        },
        {
          label: "Amount",
          name: "amount",
          type: "number",
          options: {
            customBodyRender: (v: any) => v?.toLocaleString(),
          },
        },
        {
          label: "Date",
          name: "createdAt",
          //   type: "date",
          options: {
            customBodyRender: (v: any) => moment(v).format("YYYY-MMM-DD"),
          },
        },
      ]}
      filters={[
        {
          label: "Fiscal Year",
          name: "fiscal",
          values: fiscalYears,
        },
        {
          label: "Title",
          name: "titleName",
          type: "text",
        },
        {
          label: "Start Date",
          name: "startDate",
          type: "date",
        },
        {
          label: "End Date",
          name: "endDate",
          type: "date",
        },
      ]}
    />
  );
}

export default InvestmentReport;
