import Breadcrumb from "../../../components/Breadcrumb";
import { DatatableComponent } from "../../../components/DatatableComponent";
import { useState } from "react";
import { AddForm } from "../../employee/AddForm";
import { BorderColorOutlined } from "@mui/icons-material";
import { Col, Row } from "react-bootstrap";
import { Tooltip } from "@mui/material";

function ShareCapital() {
  const [open, setOpen] = useState(0);
  const [count, setCount] = useState(0);
  const [list, setList] = useState<Array<any>>([]);
  const [info, setInfo] = useState<any>();
  return (
    <div>
      <Breadcrumb
        addButton={{
          label: "Add New",
          onClick: () => setOpen(1),
        }}
      />
      <Row className={"justify-content-center p-3 bg-white"}>
        <Col md={12}>
          <DatatableComponent
            columns={[
              {
                label: "Description",
                name: "description",
              },
              {
                label: "Amount",
                name: "amount",
                options: {
                  customBodyRender: (v: number) => v?.toLocaleString(),
                },
              },
            ]}
            actions={(id) => [
              <Tooltip title="delete" className={"border-0 bg-transparent"}>
                <BorderColorOutlined
                  onClick={() => {
                    setOpen(1);
                    setInfo(list?.find((v) => v.id === id));
                  }}
                  fontSize="small"
                  className={"cursor-pointer"}
                />
              </Tooltip>,
            ]}
            deleteUrl={(id) => `cde/grant/delete/${id}`}
            url={`cde/grants?count=${count}`}
            title={"CDE Share Capital Grants"}
            processData={(data: any) => {
              setList(data?.data);
              return data;
            }}
          />
        </Col>
      </Row>
      <AddForm
        open={open}
        mainFields={[
          {
            title: "",
            fields: [
              {
                name: "description",
                label: "Description",
                value: info?.description ?? null,
                required: true,
              },
              {
                name: "amount",
                label: "Amount",
                type: "number",
                value: info?.amount ?? null,
                required: true,
              },
            ],
          },
        ]}
        url={`cde/grant/create${info?.id ? `?id=${info.id}` : ""}`}
        title={info?.id ? "Update grant" : "Record New Grant"}
        onSuccess={() => {
          setCount((c) => c + 1);
          setOpen(0);
        }}
        setOpen={() => {
          setOpen(0);
          setInfo(null);
        }}
      />
    </div>
  );
}

export default ShareCapital;
