import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import {  useState } from "react";
import moment from "moment/moment";
import { CustomizedMenus } from "../../../components/customizeMenu";
import DeleteDialog from "../../../components/DeleteDialog";
import { DatatableComponent } from "../../../components/DatatableComponent";
import StatusComponent from "../../../components/StatusComponent";
import GeneralButton from "../../../components/Button";
import { Add } from "@mui/icons-material";

const IncomingTransferList = () => {
  const navigate = useNavigate();
  const [list, setList] = useState<Array<any>>([]);
  const [deleteOpen, setDeleteOpen] = useState(0);
  const [deleteActionUrl, setDeleteActionUrl] = useState<
    string | undefined | null
  >();
  const [change, setChange] = useState(0);
  const [info, setInfo] = useState<any>({});

  const reload = () => {
    setChange((v) => v + 1);
  };

  const pendingRequest = list?.find((item: any) => item?.status !== "rejected"&&item?.status !== "approved");


  return (
    <div>
      <Breadcrumb
        endElement={
          pendingRequest? null:(
            <GeneralButton
              variant="outlined"
              size="medium"
              name={"New Request"}
              icon={<Add />}
              onClick={async () => {
                navigate("transfer/new");
              }}
            />
          )
        }
      />
      {/* <Row className="mt-4">
        <Col className={"mb-4"} md={3}>
          <DashboardCard
            content={{
              title: "Pending",
              number: pending[0]?.request ?? 0,
            }}
            className="justify-content-center"
            icon={<Pending fontSize="large" />}
          />
        </Col>
        <Col className={"mb-4"} md={3}>
          <DashboardCard
            content={{
              title: "Request action",
              number: clarification[0]?.request ?? 0,
            }}
            icon={<Info fontSize="large" />}
            className="justify-content-center"
          />
        </Col>
        <Col className={"mb-4"} md={3}>
          <DashboardCard
            content={{
              title: "Approved",
              number: approved[0]?.request ?? 0,
            }}
            icon={<CheckCircle fontSize="large" />}
            className="justify-content-center"
          />
        </Col>
        <Col className={"mb-4"} md={3}>
          <DashboardCard
            content={{
              title: "Rejected",
              number: rejected[0]?.request ?? 0,
            }}
            icon={<Cancel fontSize="large" />}
            className="justify-content-center"
          />
        </Col>
      </Row>
      <Row className="mb-3 ">
        <Col md={2}>
          <label className="col-form-label text-capitalize">Status</label>
          <ReactSelect
            options={[
              { value: "pending", label: "pending" },
              { value: "approved", label: "approved" },
              { value: "request action", label: "Request for action" },
              { value: "rejected", label: "rejected" },
              { value: "all", label: "All" },
            ]}
            onChange={(v) => {
              setInfo({ ...info, status: v?.value });
            }}
          />
        </Col>
        <Col md={3}>
          <label className="col-form-label text-capitalize">From</label>
          <ReactSelect
             options={branch?.map(({ label, id, name }:any) => ({
              id,
              label: label ?? name,
            }))}
            onChange={(v: any) => {
              setInfo({ ...info, branchFrom: v?.id });
            }}
          />
        </Col>
        <Col md={3}>
          <label className="col-form-label text-capitalize">To</label>
          <ReactSelect
             options={branch?.map(({ label, id, name }:any) => ({
              id,
              label: label ?? name,
            }))}
            onChange={(v: any) => {
              setInfo({ ...info, branchTo: v?.id });
            }}
          />
        </Col>
        <Col md={2}>
          <label className="col-form-label text-capitalize">Date</label>
          <input
            className="form-control"
            type="date"
            onChange={(e: any) => {
              setInfo({ ...info, createdAt: e.target.value });
            }}
          />
        </Col>
        <Col md={2}>
          <label className="col-form-label text-capitalize mt-3"></label>
          <div className="d-flex justify-content-end ">
            <Button
              autoFocus
              className="btn-primary"
              variant="outlined"
              startIcon={<Download />}
            >
              Export
            </Button>
          </div>
        </Col>
      </Row> */}
      <DatatableComponent
        columns={[
          // {
          //   label: "Employee",
          //   name: "names",
          //   options: {
          //     filter: true,
          //     sort: true,
          //     customBodyRenderLite: (id: any, index: any) => {
          //       return (
          //         <span className="text-capitalize  ">{`${list[index]?.Employee?.firstName} ${list[index]?.Employee?.lastName}`}</span>
          //       );
          //     },
          //   },
          // },
          // {
          //   label: "Position",
          //   name: "toPosition.name",
          // },
          // {
          //   label: "Transferred From",
          //   name: "fromBranch.name",
          // },
          {
            label: "Transferred To",
            name: "toBranch.name",
          },

          // {
          //   label: "Department",
          //   name: "toDepartment.name",
          // },
          {
            label: "Date",
            name: "createdAt",
            options: {
              customBodyRender: (v: any) => moment(v).format("YYYY-MMM-DD"),
            },
          },
          {
            label: "Status",
            name: "status",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (status: any) => (
                <StatusComponent status={status} badge={false}/>

              ),
            },
          },
          {
            name: "id",
            label: "Action",
            options: {
              filter: true,
              sort: false,
              customBodyRenderLite: (id: any, index: any) => {
                const canUpdate =
                  list[index]?.status === "request action"||list[index]?.status === "pending" ;
                  const canDelete =
                  list[index]?.status === "pending" 
                return (
                  <CustomizedMenus
                    onView={() => {
                      navigate(`details/${list[index].id}`);
                    }}
                    onEdit={() => {
                      navigate(`transfer/${list[index].id}`);
                    }}
                    onDelete={() => {
                      setDeleteOpen(1);
                      setDeleteActionUrl(`/transfer/delete/${list[index].id}`);
                    }}
                    needEdit={canUpdate}
                    needDelete={canDelete}
                  />
                );
              },
            },
          },
        ]}
        url={`/transfer/datatable?change=${change}&info=${JSON.stringify(
          info
        )}`}
        title={"Transfer List"}
        processData={(data: any) => {
          setList(data.data);
          return data;
        }}
      />
      {deleteActionUrl ? (
        <DeleteDialog
          open={deleteOpen}
          onSuccess={reload}
          setOpen={setDeleteOpen}
          url={deleteActionUrl!}
        />
      ) : null}
    </div>
  );
};

export default IncomingTransferList;
