import { Add, Delete } from "@mui/icons-material";
import {
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { CustomizedMenus } from "../../../components/customizeMenu";
import { useNavigate } from "react-router";
import { default as ReactSelect } from "react-select";
import { submitGeneralForm } from "../../../services/common";
import moment from "moment";
import { Button } from "react-bootstrap";

const WorkPlan = (props: any) => {
  const { plan, setPlan, addNew, planInfo, onSuccess } = props;
  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newData, setData] = useState<any>();

  const navigate = useNavigate();

  const onSubmit = async () => {
    const formData = new FormData();
    formData.append("body", JSON.stringify(newData));
    const resp = await submitGeneralForm(
      `/mission-plan/update/${newData?.id}`,
      newData,
      null,
      "PUT"
    );
    if (resp.status) {
      setData({});
      setOpenEdit(false);
      if (typeof onSuccess === "function") {
        onSuccess();
      }
    } else {
      // setError(getError(resp));
      // setSuccess(null);
    }
    setLoading(false);
  };
  return (
    <>
  {
            addNew ? (
              <Button
                className="btn-primary mb-2 "
                variant="outlined"
                onClick={() => {
                  setPlan([...plan, { plan: "", date: "" }]);
                }}
              >
               Add Plan
              </Button>
            ) : null
          }
        {addNew ? (
          <TableContainer className="border">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
              <TableRow  className="bg-light">
              <TableCell>Plan</TableCell>
                  <TableCell>Day</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {plan?.map((row: any, index: any) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <textarea
                        className="form-control form-control-sm"
                        value={row.plan}
                        required={true}
                        onChange={(e) => {
                          row.plan = e.currentTarget.value;
                          setPlan([...plan]);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {" "}
                      <input
                        className="form-control form-control-sm"
                        value={row.day}
                        required={true}
                        onChange={(e) => {
                          row.day = e.currentTarget.value;
                          setPlan([...plan]);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color={"error"}
                        onClick={() => {
                          plan.splice(index, 1);
                          setPlan([...plan]);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer className="border">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
              <TableRow  className="bg-light">
              <TableCell>Plan</TableCell>
                  <TableCell>Day</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Reason of Delay</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {planInfo?.map((row: any, index: any) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {openEdit && newData?.id === row.id ? (
                        <textarea
                          className="form-control form-control-sm"
                          value={newData.plan}
                          onChange={(e: any) => {
                            setData({
                              ...newData,
                              plan: e.currentTarget.value,
                            });
                          }}
                        />
                      ) : (
                        row.plan
                      )}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {openEdit && newData?.id === row.id ? (
                        <input
                          className="form-control form-control-sm"
                          value={newData.day}
                          onChange={(e: any) => {
                            setData({ ...newData, day: e.currentTarget.value });
                          }}
                        />
                      ) : (
                        row.day
                      )}
                    </TableCell>
                    <TableCell
                      className={
                        row?.status === "pending"
                          ? "text-primary fw-bold"
                          : "text-success fw-bold"
                      }
                    >
                      {openEdit && newData?.id === row.id ? (
                        <ReactSelect
                          className={"text-nowrap"}
                          options={[
                            { value: "pending", label: "Pending" },
                            { value: "done", label: "Done" },
                          ]}
                          onChange={(v: any) => {
                            setData({ ...newData, status: v?.value });
                          }}
                        />
                      ) : (
                        row.status
                      )}
                    </TableCell>

                    <TableCell>
                      {openEdit &&
                      newData?.id === row.id &&
                      newData?.status === "pending" ? (
                        <input
                          className="form-control form-control-sm"
                          type="date"
                          value={newData.startDate}
                          onChange={(e: any) => {
                            setData({
                              ...newData,
                              startDate: e.currentTarget.value,
                            });
                          }}
                        />
                      ) : row.startDate ? (
                        moment(row.startDate).format("MMM Do YY, h:mm a")
                      ) : null}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {openEdit &&
                      newData?.id === row.id &&
                      newData?.status === "pending" ? (
                        <input
                          className="form-control form-control-sm"
                          type="date"
                          value={newData.endDate}
                          onChange={(e: any) => {
                            setData({
                              ...newData,
                              endDate: e.currentTarget.value,
                            });
                          }}
                        />
                      ) : row.endDate ? (
                        moment(row.endDate).format("MMM Do YY, h:mm a")
                      ) : null}
                    </TableCell>
                    <TableCell>
                      {openEdit &&
                      newData?.id === row.id &&
                      newData?.status === "pending" ? (
                        <textarea
                          className="form-control form-control-sm"
                          value={newData.reasonNotDone}
                          onChange={(e: any) => {
                            setData({
                              ...newData,
                              reasonNotDone: e.currentTarget.value,
                            });
                          }}
                        />
                      ) : (
                        row.reasonNotDone
                      )}
                    </TableCell>

                    <TableCell>
                      {openEdit && newData?.id === row.id ? (
                        <>
                          <Button
                            onClick={() => {
                              setOpenEdit(false);
                            }}
                            className={"me-1"}
                          >
                            <i className={"bi bi-x-lg"}></i> Cancel
                          </Button>
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            <Button
                              variant="contained"
                              className={"btn btn-primary"}
                              onClick={() => {
                                onSubmit();
                              }}
                            >
                              <i className={"bi bi-send-fill"}></i> Submit
                            </Button>
                          )}
                        </>
                      ) : (
                        <CustomizedMenus
                          onEdit={() => {
                            setOpenEdit(true);
                            setData(row);
                          }}
                          onDelete={() => {
                            // setDeleteOpen(1);
                            // setDeleteActionUrl(`/mission/delete/${list[index].id}`);
                          }}
                          needEdit={true}
                          needDelete={true}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>
  );
};

export default WorkPlan;
