import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import { Col, Row } from "react-bootstrap";
import Breadcrumb from "../../../components/Breadcrumb";
import { DatatableComponent } from "../../../components/DatatableComponent";
import { CustomizedMenus } from "../../../components/customizeMenu";
import StatusComponent from "../../../components/StatusComponent";
import { fetchDetails } from "../../../services/common";
import { default as ReactSelect } from "react-select";

const SocialFundListIncoming = () => {
  // const { loggedUser } = props;
  const navigate = useNavigate();
  const [list, setList] = useState<Array<any>>([]);
  // const [deleteOpen, setDeleteOpen] = useState(0);
  // const [addFund, setAddFund] = useState(0);

  const [change, setChange] = useState(0);
  // const [fundInfo, setFundInfo] = useState<any>({});
  const [fiscalYearList, setFiscalYearList] = useState<Array<any>>([]);
  const [fiscalYear, setFiscalYear] = useState<any>();

  const getFiscalYear = async () => {
    const fiscalYear = await fetchDetails("/fiscal/view");

    if (fiscalYear.status) {
      const currentFiscalYear = fiscalYear?.data?.find(
        (year: any) => year.status === "open"
      );
      setFiscalYearList(fiscalYear?.data);
      setFiscalYear(currentFiscalYear?.id);
    }
  };
  useEffect(() => {
    //fetch all FiscalYear
    getFiscalYear().then();
  }, []);
  // const reload = () => {
  //   setChange((v) => v + 1);
  // };

  return (
    <div>
      <Breadcrumb />

      <Row>
        <Col md={3}>
          <label className="col-form-label text-capitalize">Fiscal Year</label>
          <ReactSelect
            className={"text-nowrap"}
            options={fiscalYearList?.map(({ label, id, name }: any) => ({
              value: id,
              label: label ?? name,
            }))}
            onChange={(v: any) => {
              setFiscalYear(v?.value);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <DatatableComponent
            columns={[
              {
                label: "Requester",
                name: "requestedBy.Employee.names",
                data: "requester",

                // options: {
                //   customBodyRender: (v: any) => (
                //     <span className="text-capitalize">
                //       {`${v?.Employee?.firstName ?? ""} ${
                //         v?.Employee?.lastName ?? ""
                //       }`}
                //     </span>
                //   ),
                // },
              },
              {
                label: "Type",
                name: "SocialFundType.name",
              },
              {
                label: "Amount",
                name: "amount",
                options: {
                  customBodyRender: (v: any) => v?.toLocaleString(),
                },
              },
              {
                label: "Date",
                name: "createdAt",
                options: {
                  customBodyRender: (v: any) => moment(v).format("DD/MM/YYYY"),
                },
              },
              {
                label: "Status",
                name: "status",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (status: any) => (
                    <StatusComponent status={status} badge={false} />
                  ),
                },
              },
              {
                name: "id",
                label: "Action",
                options: {
                  filter: true,
                  sort: false,
                  customBodyRenderLite: (id: any, index: any) => {
                    return (
                      <CustomizedMenus
                        onView={() => {
                          navigate(`details/${list[index].id}`);
                        }}
                      />
                    );
                  },
                },
              },
            ]}
            url={`social-fund/datatable/incoming?change=${change}&&fiscalYear=${fiscalYear}`}
            title={"Social Funds"}
            processData={(data: any) => {
              setList(data?.data);
              return data;
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SocialFundListIncoming;
