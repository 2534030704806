import Breadcrumb from "../../../components/Breadcrumb";
import {Box, Card, Tab, Tabs} from "@mui/material";
import {a11yProps, TabPanel} from "../../employee/leave/List";
import React, {useState} from "react";
import ContributionReport from "./ContributionReport";
import LoanReport from "./LoanReport";
import SocialFundReport from "./SocialFundReport";
import CaisseMemberReport from "./CaisseMemberReport";
import InvestmentReport from "./InvestmentReport";
import ExpenseReport from "./ExpenseReport";

function CaisseDentraideReportIndex(){
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return <div className={'px-3'}>
        <Breadcrumb/>
        <Card style={{overflow:"visible"}}>
            <Box sx={{ borderBottom: 0.5, Update: "divider" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <Tab label="Contributions" {...a11yProps(0)} />
                    <Tab label="Loans" {...a11yProps(1)} />
                    <Tab label="Social Funds" {...a11yProps(2)} />
                    <Tab label="Members" {...a11yProps(3)} />
                    <Tab label="Investment" {...a11yProps(4)} />
                    <Tab label="Expenses" {...a11yProps(5)} />
                </Tabs>
            </Box>
            <TabPanel value={value} p={2} index={0}>
                <ContributionReport/>
            </TabPanel>
            <TabPanel value={value} p={2} index={1}>
                <LoanReport/>
            </TabPanel>
            <TabPanel value={value} p={2} index={2}>
                <SocialFundReport/>
            </TabPanel>
            <TabPanel value={value} p={2} index={3}>
                <CaisseMemberReport/>
            </TabPanel>
            <TabPanel value={value} p={2} index={4}>
                <InvestmentReport/>
            </TabPanel>
            <TabPanel value={value} p={2} index={5}>
                <ExpenseReport/>
            </TabPanel>
        </Card>
    </div>
}

export default CaisseDentraideReportIndex;
