import {
  Alert,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Breadcrumb from "../../components/Breadcrumb";
import profile from "../../assets/un-profile.png";
import { Card, Col, Row } from "react-bootstrap";
import moment from "moment";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import OpenDocuments from "../../components/openDocument";
import { mapDispatchToProps } from "../../store/helpers/mapDispatch";
import { mapStateToProps } from "../../store/helpers/mapState";
import { connect } from "react-redux";
import {
  attendanceStatusList,
  CheckHasAnyPermission,
  getError,
  getFileMime,
  submitGeneralForm,
} from "../../services/common";
import { useLocation } from "react-router-dom";
import StatusComponent from "../../components/StatusComponent";

const CreateAttendance = (props: any) => {
  const { loggedUser } = props;
  const location = useLocation();

  // const [entrances, setEntrances] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [error, setError] = useState<string | undefined | null>(null);
  const [info, setInfo] = useState<any>();
  const [date, setDate] = useState<any>( location?.state?.date??new Date());
  const employee = location?.state ?? loggedUser?.Employee;
  const viewDataStatus = attendanceStatusList?.find(
    (st: any) => st?.value === info?.status
  )?.label; // const getEntrancies = async () => {
  //   try {
  //     const url = `/entrancy/view`;
  //     const response = await fetchDetails(url);
  //
  //     if (response.status) {
  //       setEntrances(response?.data);
  //     }
  //   } catch (error: any) {}
  // };
  //handle submit
  const onLoad = async () => {
    let data = {
      employeeNumber: employee?.employeeNumber,
      employeeId: employee?.id,
      startDate: date,
    };
    if (employee?.id) {
      setLoading(true);

      const url = `/attendance/create`;
      const response = await submitGeneralForm(url, data, null, "POST");

      if (response.status) {
        setLoading(false);
        setInfo(response?.data?.data);
        // toast.success(getSuccessMessage(response));
        setError("");
      } else {
        setLoading(false);
        setError(getError(response));
        setInfo(null);
      }
    }
  };
  useEffect(() => {
    onLoad().then();
  }, [date]);
  const attendanceLogs=info?.AttendanceLogs?.sort(
    (a: any, b: any) =>
      new Date(b.eventTime).getTime() - new Date(a.eventTime).getTime()
  )
  return (
    <div>
      <ToastContainer />
      <Breadcrumb activeLabel={"Daily Attendance"} />
      <Row className="mb-3 mt-3">
        <Col className="bg-white p-3 rounded">
          {error ? (
            <div className="row justify-content-center">
              <Alert
                style={{ width: "100%" }}
                className={"col-md-12 mb-3"}
                severity={"error"}
                onClose={() => setError(null)}
              >
                {!info ? "No attendance records available" : error}
              </Alert>
            </div>
          ) : null}

          <div className="row justify-content-center mb-3">
            <Col lg={4}>
              <label className="col-form-label text-capitalize">
                Emp Number/Pin
              </label>
              <input
                name="cardNumber"
                className="form-control"
                type={"text"}
                value={employee?.employeeNumber}
                disabled={true}
              />
            </Col>
            <Col lg={4}>
              <label className="col-form-label text-capitalize">Date</label>
              <input
                name="date"
                className="form-control"
                value={moment(date).format("YYYY-MM-DD")}
                type={"date"}
                onChange={(e: any) => {
                  setDate(e.target.value);
                }}
                max={moment().format("YYYY-MM-DD")}
                disabled={!CheckHasAnyPermission("attendance-report")}
              />
            </Col>
          </div>
        </Col>
      </Row>
      <Row>
        <Card className="col">
          <CardHeader
            action={
              <StatusComponent status={viewDataStatus ?? ""} badge={true} />
            }
          />
          <CardContent className="">
            {loading ? (
              <LinearProgress />
            ) : (
              <div className="row justify-content-center">
                <div className="col-md-4">
                  {/* <img
                    className="rounded img-fluid"
                    width="200px"
                    height="200px"
                    alt={`${info?.firstName}`}
                    src={profile} /> */}
                  {info?.Employee?.profile ? (
                    <OpenDocuments
                      // downloadable={false}
                      // card={true}
                      width="200px"
                      height="200px"
                      document={info?.Employee?.profile}
                      mimeType={getFileMime(info?.Employee?.profile)}
                      className="img-fluid"
                    />
                  ) : (
                    <img
                      className="rounded-pill img-fluid"
                      width="200px"
                      height="200px"
                      alt={`${info?.Employee?.firstName}`}
                      src={profile}
                    />
                  )}
                </div>

                <div className="col-md-7">
                  <div className="row mb-2">
                    <div className="col-3 fw-bold">First Name</div>
                    <div className="col-5">{info?.Employee?.firstName}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-3 fw-bold">Last Name</div>
                    <div className="col-8">{info?.Employee?.lastName}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-3 fw-bold">Phone</div>
                    <div className="col-8">{info?.Employee?.phone}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-3 fw-bold">Email</div>
                    <div className="col-8">{info?.Employee?.email}</div>
                  </div>
                  {info?.Employee?.employeeBranches?.map((branch: any) => (
                    <>
                      <div className="row mb-2">
                        <div className="col-3 fw-bold">Branch</div>
                        <div className="col-8">{branch?.branch?.name}</div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-3 fw-bold">Department</div>
                        <div className="col-8">{branch?.department?.name}</div>
                      </div>
                    </>
                  ))}
                  {info ? (
                    <>
                      <div className="row mb-2">
                        <div className="col-3 fw-bold">Punch In </div>
                        <div className="col-8">
                          {info?.checkInTime
                            ? moment(info?.checkInTime).format(
                                "DD-MM-yyyy HH:mm:ss A"
                              )
                            : null}
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-3 fw-bold">Punch Out </div>
                        <div className="col-8">
                          {info?.checkOutTime
                            ? moment(info?.checkOutTime).format(
                                "DD-MM-yyyy HH:mm:ss A"
                              )
                            : null}
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-3 fw-bold">Entrance </div>
                        <div className="col-8">
                          {info?.AttendanceArea?.name}
                        </div>
                      </div>
                    </>
                  ) : null}
                  {attendanceLogs?.length > 0 ? (
                    <div className="row mt-3">
                      <hr/>
                      <Typography className="bw-bold" variant="h5">Transactions(Logs)</Typography>
                      <Table>
                        <TableHead>
                          <TableCell>No</TableCell>
                          <TableCell>Punch Time</TableCell>
                          <TableCell>Device</TableCell>
                        </TableHead>
                        <TableBody>
                          {info?.AttendanceLogs?.map((log: any, key: any) => (
                            <TableRow key={key}>
                              <TableCell>{key + 1}</TableCell>
                              <TableCell>
                                {moment(log?.eventTime).format(
                                  "YYYY-MM-DD HH:mm:ss A"
                                )}
                              </TableCell>
                              <TableCell>{log?.devSn}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </CardContent>
        </Card>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAttendance);
