import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import moment from "moment/moment";
import { DatatableComponent } from "../../../components/DatatableComponent";
import { useEffect, useState } from "react";
import { CustomizedMenus } from "../../../components/customizeMenu";
import DeleteDialog from "../../../components/DeleteDialog";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import GeneralButton from "../../../components/Button";
import {
  Add,
  Cached,
  FormatLineSpacing,
  Settings,
} from "@mui/icons-material";
import { mapDispatchToProps } from "../../../store/helpers/mapDispatch";
import { mapStateToProps } from "../../../store/helpers/mapState";
import FileUpload from "../../../components/FileUploader";
import { AddForm } from "../AddForm";
import { connect } from "react-redux";
import SelectDepartment from "../../../components/SelectDepartment";
import SmallDialog from "../../../components/SmallModel";
import {
  CheckHasPermission,
  fetchDetails,
  getError,
  submitGeneralForm,
} from "../../../services/common";
import StatusComponent from "../../../components/StatusComponent";
import { toast } from "react-toastify";
import TabHeadersComponent from "../../../components/UI/Tabs";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  p?: number | undefined | null;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, p, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: p ?? 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const LeaveList = (props: any) => {
  const [addFile, setAddFile] = useState<number>(0);
  const [openDialogueExport, setOpenDialogueExport] = useState<boolean>(false);
  const [departmentId, setDepartmentId] = useState<any>();
  const [files, setFiles] = useState<any>([]);
  const [list, setList] = useState<Array<any>>([]);
  const [info, setInfo] = useState<any>();
  const [change, setChange] = useState(0);
  const [deleteOpen, setDeleteOpen] = useState(0);
  const [deleteActionUrl, setDeleteActionUrl] = useState<
    string | undefined | null
  >();
  const [value, setValue] = useState(0);
  const [genrating, setGenerating] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigate = useNavigate();

  const reload = () => {
    setChange((v) => v + 1);
  };

 
  const fetchSettingDetails = async () => {
    const resp = await fetchDetails(`settings/view/`);
    if (resp.status) {
      setInfo(resp.data);
    }
  };
  useEffect(() => {
    fetchSettingDetails().then();
  }, []);
  // const fiscalYear = moment().set({ month: 9, date: 31 }).format("YYYY-MM-DD"); //deadline to submit leave plan is 31st of july,but according to moment js it's takes previous month
  const startLeavePlan =
    moment(info?.startLeavePlanDate).diff(
      moment().format("YYYY-MM-DD"),
      "days"
    ) >= 0;

  const endLeavePlanDate =
    moment(info?.endLeavePlanDate).diff(
      moment().format("YYYY-MM-DD"),
      "days"
    ) >= 0;
  const canSubmitLeave = startLeavePlan || endLeavePlanDate;
  const labels = [
    { label: "My Leave", icon: <Settings /> },
    { label: "Annual Leave Plan", icon: <FormatLineSpacing /> },
    // { label: "editor", icon: <PeopleAlt /> },
  ];
  return (
    <div>
      <Breadcrumb
        // addButton={{
        //   label: "New Leave",
        //   onClick: () => navigate("create/new"),
        // }}
        endElement={
          <>
            {
            value === 1 &&
            canSubmitLeave &&
            CheckHasPermission("approval-leave", "create") ? (
              <div className="d-flex justify-content-end">
                <GeneralButton
                  variant="outlined"
                  size="medium"
                  name={"Create Leave Plan"}
                  icon={<Add />}
                  onClick={async () => {
                    setOpenDialogueExport(true);
                    setValue(1);
                    // await DownloadExcelFile("leave/exportLeavePlan");
                  }}
                />
                {/* <GeneralButton
                  variant="outlined"
                  size="medium"
                  name={"Import Annual Plan"}
                  icon={<Upload />}
                  onClick={() => {
                    setAddFile(1);
                    setValue(1);
                  }}
                /> */}
              </div>
            ) : null}
            {value === 0 ? (
              <GeneralButton
                variant="outlined"
                size="medium"
                name={"New Leave"}
                icon={<Add />}
                onClick={() => {
                  navigate("create/new");
                }}
              />
            ) : null}
          </>
        }
      />
      <Row className={"justify-content-center p-3"}>
        <Col md={12} className=" bg-white">
          {/* <Box sx={{ borderBottom: 1, Update: "divider" }}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <StyledTab label="My Leave" {...a11yProps(0)} />
              <StyledTab label="Annual Leave Plan" {...a11yProps(1)} />
            </StyledTabs>
          </Box> */}
          <TabHeadersComponent
            handleChange={handleChange}
            value={value}
            labels={labels}
          />
          <TabPanel value={value} p={0} index={0}>
            <DatatableComponent
              columns={[
                // {
                //   label: "Reason",
                //   name: "reason",
                //   options: {
                //     filter: true,
                //     sort: true,
                //   },
                // },
                {
                  label: "Type",
                  name: "LeaveType.name",
                },
                // {
                //   label: "No of Days",
                //   name: "numberOfDays",
                // },
                {
                  label: "Start Date",
                  name: "startDate",
                  options: {
                    customBodyRender: (v: any) =>
                      v ? moment(v).format("YYYY-MMM-DD") : "",
                  },
                },
                {
                  label: "End Date",
                  name: "endDate",
                  options: {
                    customBodyRender: (v: any) =>
                      v ? moment(v).format("YYYY-MMM-DD") : "",
                  },
                },
                {
                  label: "Status",
                  name: "status",
                  options: {
                    customBodyRender: (status: any) => (
                      <StatusComponent status={status} badge={false} />
                    ),
                  },
                },
                {
                  name: "id",
                  label: "Action",
                  options: {
                    filter: true,
                    sort: false,
                    customBodyRenderLite: (id: any, index: any) => {
                      const canUpdate =
                        list[index]?.status === "request action" ||
                        list[index]?.status === "pending";
                      const canDelete = list[index]?.status === "pending";
                      return (
                        <CustomizedMenus
                          onView={() => {
                            navigate(`details/${list[index].id}`);
                          }}
                          // onEdit={() => {
                          //   navigate(`create/${list[index].id}`);
                          // }}
                          onDelete={() => {
                            setDeleteOpen(1);
                            setDeleteActionUrl(
                              `/leave/delete/${list[index].id}`
                            );
                          }}
                          needEdit={canUpdate}
                          needDelete={canDelete}
                        />
                      );
                    },
                  },
                },
              ]}
              url={`/leave/datatable?change=${change}`}
              title={"Leave Requests"}
              processData={(data: any) => {
                setList(data.data);
                return data;
              }}
            />
          </TabPanel>
          <TabPanel value={value} p={0} index={1}>
            <DatatableComponent
              columns={[
                // {
                //   label: "Reason",
                //   name: "reason",
                //   options: {
                //     filter: true,
                //     sort: true,
                //   },
                // },
                {
                  label: "Supervisor",
                  name: "requester",
                  options: {
                    customBodyRenderLite: (id: any, index: any) => {
                      return (
                        <span className="text-capitalize">{`${list[index]?.requestedBy?.Employee?.firstName} ${list[index]?.requestedBy?.Employee?.lastName}`}</span>
                      );
                    },
                  },
                },
                // {
                //   label: "No of Days",
                //   name: "numberOfDays",
                // },
                {
                  label: "Status",
                  name: "status",
                  options: {
                    customBodyRender: (status: any) => (
                      <StatusComponent status={status} badge={false} />
                    ),
                  },
                },
                {
                  label: "Created Date",
                  name: "createdAt",
                  options: {
                    customBodyRender: (v: any) =>
                      v ? moment(v).format("YYYY-MMM-DD") : "",
                  },
                },
                {
                  name: "id",
                  label: "Action",
                  options: {
                    filter: true,
                    sort: false,
                    customBodyRenderLite: (id: any, index: any) => {
                   
                      const canDelete =
                        list[index]?.status === "pending" ||
                        list[index]?.status === "rejected";
                      return (
                        <CustomizedMenus
                          onView={() => {
                            navigate(`details/${list[index].id}`);
                          }}
                          onDelete={() => {
                            setDeleteOpen(1);
                            setDeleteActionUrl(
                              `/leave/delete/${list[index].id}`
                            );
                          }}
                          needDelete={canDelete}
                        />
                      );
                    },
                  },
                },
              ]}
              url={`/leave/datatable/my-annualLeave?change=${change}`}
              title={"Annual Leave Plan"}
              processData={(data: any) => {
                setList(data.data);
                return data;
              }}
            />
          </TabPanel>
          {/* <TabPanel value={value} p={0} index={2}>
          <h5>DocumentViewer</h5>
      <DocumentViewer/>
          </TabPanel> */}
        </Col>
      </Row>

      <AddForm
        title="Import Annual Leave Plan"
        maxWidth="lg"
        mainFields={[
          {
            title: "",
            fields: [
              {
                label: "",
                name: "templateFile",
                type: "file",
                multiple: true,
                col: 12,
                value: files,
                required: true,
                render: () => {
                  return (
                    <FileUpload
                      setFiles={setFiles}
                      files={files}
                      required={true}
                      title={"Choose"}
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                  );
                },
              },
            ],
          },
        ]}
        url={`/leave/import/leavePlan?change=${change}`}
        method={"POST"}
        onSuccess={() => {
          setAddFile(0);
          setChange(change + 1);
        }}
        onError={(error) => {
          setFiles([]);
        }}
        open={addFile}
        setOpen={() => setAddFile(0)}
      />
      {deleteActionUrl ? (
        <DeleteDialog
          open={deleteOpen}
          onSuccess={reload}
          setOpen={setDeleteOpen}
          url={deleteActionUrl!}
        />
      ) : null}
      <SmallDialog
        maxWidth="lg"
        title=""
        buttonTitle="Submit"
        needButton={true}
        open={openDialogueExport}
        setOpen={setOpenDialogueExport}
      >
        <Card>
        
          <CardContent>
            <div className="row ">
              <div className="col-md-12 ">
                <Alert severity="warning">
                  <AlertTitle>Warning</AlertTitle>

                  <strong>
                    {
                      "Make sure you select a Department/Unit you are supervising"
                    }
                  </strong>
                </Alert>
                <SelectDepartment
                  onSuccess={(department?: any) => setDepartmentId(department)}
                  singleSelection={true}
                />
              </div>
              <div className="col-md-12 p-4">
                <div className="row justify-content-center bg-light ">
                  <div className="col-md-12 p-3 text-center">
                    {genrating ? (
                      <CircularProgress />
                    ) : (
                      <>
                        {/* {link ? <a href={link} target={'_blank'} rel="noreferrer">{link}</a> : null} */}
                        {departmentId ? (
                          <div className={"text-center mt-2"}>
                            <Button
                              variant={"outlined"}
                              onClick={async () => {
                                setGenerating(true);
                                const resp = await submitGeneralForm(
                                  `leave/exportLeavePlan/${departmentId?.id}`,
                                  null
                                );
                                setGenerating(false);
                                if (resp.status) {
                                  setOpenDialogueExport(false);
                                  setChange(change + 1);
                                } else {
                                  toast.error(getError(resp));
                                }
                              }}
                              startIcon={<Cached />}
                            >
                              Generate Excel File
                            </Button>
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </SmallDialog>
    </div>
  );
};

// export default LeaveList;
export default connect(mapStateToProps, mapDispatchToProps)(LeaveList);
