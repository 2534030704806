import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import {  useState } from "react";
import moment from "moment/moment";
import { CustomizedMenus } from "../../../components/customizeMenu";
import DeleteDialog from "../../../components/DeleteDialog";
import { DatatableComponent } from "../../../components/DatatableComponent";
import StatusComponent from "../../../components/StatusComponent";

const MissionList = () => {
  const navigate = useNavigate();
  const [list, setList] = useState<Array<any>>([]);
  const [deleteOpen, setDeleteOpen] = useState(0);
  const [deleteActionUrl, setDeleteActionUrl] = useState<
    string | undefined | null
  >();
  const [change, setChange] = useState(0);

  const reload = () => {
    setChange((v) => v + 1);
  };


  return (
    <div>
      <Breadcrumb
        addButton={{
          label: "New Request",
          onClick: () => navigate("mission/new"),
        }}
      />

      <DatatableComponent
        columns={[
          {
            label: "First Destination",
            name: "destination",
          },
          {
            label: "Start Date",
            name: "startDate",
            options: {
              customBodyRender: (v: any) => moment(v).format("YYYY-MMM-DD"),
            },
          },
          {
            label: "End Date",
            name: "endDate",
            options: {
              customBodyRender: (v: any) => moment(v).format("YYYY-MMM-DD"),
            },
          },
          {
            label: "Status",
            name: "status",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (status: any) => (
                <StatusComponent status={status} badge={false}/>

              ),
            },
          },
          {
            name: "id",
            label: "Action",
            options: {
              filter: true,
              sort: false,
              customBodyRenderLite: (id: any, index: any) => {
                const canUpdate =
                  list[index]?.status === "request action"||list[index]?.status === "pending" ;
              const canDelete =
                list[index]?.status === "pending" 
                return (
                  <CustomizedMenus
                    onView={() => {
                      navigate(`details/${list[index].id}`);
                    }}
                    onEdit={() => {
                      navigate(`mission/${list[index].id}`);
                    }}
                    onDelete={() => {
                      setDeleteOpen(1);
                      setDeleteActionUrl(`/mission/delete/${list[index].id}`);
                    }}
                    needEdit={canUpdate}
                    needDelete={canDelete}
                  />
                );
              },
            },
          },
        ]}
        url={`/mission/datatable?change=${change}`}
        title={"Mission List"}
        processData={(data: any) => {
          setList(data.data);
          return data;
        }}
      />
      {deleteActionUrl ? (
        <DeleteDialog
          open={deleteOpen}
          onSuccess={reload}
          setOpen={setDeleteOpen}
          url={deleteActionUrl!}
        />
      ) : null}
    </div>
  );
};

export default MissionList;
