import {Delete, Edit } from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import moment from "moment";
// import { AddForm } from "../AddForm";
// import { Provinces, Districts } from "rwanda";

const MissionDestination = (props: any) => {
  const {
    destination,
    setDestination,
    allDistrict,
    setAddNew,
    editable,
    onEdit,
  } = props;
  const missionCost = destination?.reduce(
    (prev:number, next:any) => prev + next.missionCost,
    0
  );
  // const [openEdit, setOpenEdit] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [newData, setData] = useState<any>();
  // const [addNew, setAddNew] = useState(0);

  // const [zones, setZones] = useState<Array<any>>([]);
  // const [provinces, setProvinces] = useState<Array<any>>([]);
  // const [district, setDistrict] = useState<Array<any>>([]);
  // const [supervisor, setSupervisor] = useState<any>();
  // const [info, setInfo] = useState<any>({});
  // const [missionType, setMissionType] = useState<any>();
  // const [startDate, setStartDate] = useState<any>();
  // const [endDate, setEndDate] = useState<any>();
  // const [missionCost, setMissionCost] = useState<number | undefined>();
  // const [districtId, setDistrictId] = useState<string | undefined>();
  // const [provinceId, setProvinceId] = useState<string | undefined>();
  // const [transportMethod, setTransportMethod] = useState<any>();
  // const [transportPrice, setTransportPrice] = useState<any>();
  // const [kilometer, setKilometer] = useState<any>();
  // const [includeNight, setIncludeNight] = useState<any>(false);
  // const [initiator, setUnitiator] = useState<any>();
  // const navigate = useNavigate();
  // const [allowance, setAllowance] = useState<any>(null);
  // const isInitiator =
  //   (loggedUser?.id === initiator?.id && id !== "new") ||
  //   (loggedUser && id === "new");
  // const hasMissionApproverRole =
  //   loggedUser?.roles?.filter(
  //     (item: any) => item.role?.name === "mission-approver"
  //   )?.length > 0;
  // const missionTransportPerKm =
  //   initiator?.Employee?.level?.MissionTransport?.amount;

  // const financeAccess = hasMissionApproverRole && id !== "new";

  // const fetchUnitiator = async (id: string) => {
  //   const resp = await fetchDetails(`/user/view/${id}`);
  //   if (resp.status) {
  //     setUnitiator(resp.data);
  //   }
  // };

  // const fetchDistrict = async (search?: any) => {
  //   const district = await fetchDetails(
  //     `zone/district/${search ?? provinceId}`
  //   );
  //   if (district.status && !search) {
  //     setDistrict(district.data);
  //   }
  //   if (search && district.data) {
  //     return district.data;
  //   }
  // };
  // const fetchZones = async () => {
  //   const provinces = await Provinces();
  //   setProvinces(provinces);
  //   const resp = await fetchDetails(`zone/view`);
  //   if (resp.status) {
  //     setZones(resp.data);
  //   }
  // };
  // const fetchAllowance = async () => {
  //   const zoneInfo = district?.find((dist: any) => dist.id === districtId);

  //   const resp = await fetchDetails(
  //     `mission-allowance/viewByZone/${zoneInfo?.zoneId}`
  //   );

  //   if (resp.status) {
  //     setAllowance(resp.data);
  //   } else {
  //     setAllowance(null);
  //   }
  // };

  // useEffect(() => {
  //   if (info?.id && loggedUser.id !== info?.requester) {
  //     fetchUnitiator(info?.requester).then();
  //   } else {
  //     setUnitiator(loggedUser);
  //   }
  // }, [info?.id, info?.requester, loggedUser]);

  // useEffect(() => {
  //   if (districtId) {
  //     fetchAllowance().then();
  //   }
  // }, [districtId, district]);

  // useEffect(() => {
  //   if (provinceId) {
  //     fetchDistrict().then();
  //   }
  // }, [provinceId]);

  // useEffect(() => {
  //   if (missionType && allowance) {
  //     const night =
  //       missionType - 1 > 0 && includeNight
  //         ? (missionType - 1) * allowance.night
  //         : 0;
  //     let cost = night + missionType * allowance.day;
  //     if (kilometer && missionTransportPerKm) {
  //       cost += Number(kilometer) * missionTransportPerKm;
  //       setTransportPrice(kilometer * missionTransportPerKm);
  //     }
  //     if (transportPrice && transportMethod === "public") {
  //       cost += Number(transportPrice);
  //     }
  //     setMissionCost(cost);
  //   }
  // }, [
  //   allowance,
  //   missionType,
  //   kilometer,
  //   missionTransportPerKm,
  //   transportPrice,
  //   includeNight,
  // ]);

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     let days = moment(endDate).diff(moment(startDate), "days");
  //     days = days + 1;
  //     setMissionType(days);
  //   }
  // }, [startDate, endDate]);

  // useEffect(() => {
  //   if (transportMethod !== "private") {
  //     setKilometer(null);
  //   }
  //   setInfo({ ...info, transportMeans: transportMethod });
  // }, [transportMethod]);

  // useEffect(() => {
  //   if (editable) {
  //     fetchZones().then();
  //   }
  // }, []);

  // const onSubmit = async () => {
  //   const formData = new FormData();
  //   formData.append("body", JSON.stringify(newData));
  //   const resp = await submitGeneralForm(
  //     `/mission-plan/update/${newData?.id}`,
  //     newData,
  //     null,
  //     "PUT"
  //   );
  //   if (resp.status) {
  //     setData({});
  //     setOpenEdit(false);
  //     if (typeof onSuccess === "function") {
  //       onSuccess();
  //     }
  //   } else {
  //     // setError(getError(resp));
  //     // setSuccess(null);
  //   }
  //   setLoading(false);
  // };
  return (
    // <Row>
    //   <Col>
    //     <CardHeader
    //       className="fw-bold"
    //       subheader={<strong>Destinations</strong>}
    //       action={
    //         <Button
    //           className="btn-primary "
    //           variant="outlined"
    //           size="small"
    //           onClick={() => {
    //             setAddNew(1);
    //           }}
    //         >
    //           <Add /> Add New
    //         </Button>
    //       }
    //     />
    //     <TableContainer className="border">
    //       <Table sx={{ minWidth: 650 }} aria-label="simple table">
    //         <TableHead>
    //           <TableRow>
    //             <TableCell>Province</TableCell>
    //             <TableCell>District</TableCell>
    //             <TableCell>Start Date</TableCell>
    //             <TableCell>End Date</TableCell>
    //             <TableCell>Days</TableCell>
    //             <TableCell>Transport Means</TableCell>
    //             <TableCell>Transport Cost</TableCell>
    //             <TableCell>Allowance</TableCell>
    //             <TableCell>Action</TableCell>
    //           </TableRow>
    //         </TableHead>
    //         <TableBody>
    //           {destination?.map((row: any, index: any) => {
    //             const distr = allDistrict?.find(
    //               (item: any) => item?.id === row.destination
    //             );
    //             const days = moment(row?.endDate).diff(
    //               moment(row?.startDate),
    //               "days"
    //             );
    //             return (
    //               <TableRow
    //                 key={row.name}
    //                 sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    //               >
    //                 <TableCell>{distr?.province}</TableCell>

    //                 <TableCell component="th" scope="row">
    //                   {distr?.name}
    //                 </TableCell>
    //                 <TableCell>
    //                   {moment(row.startDate).format("YYYY-MM-DD")}
    //                 </TableCell>
    //                 <TableCell>
    //                   {moment(row.endDate).format("YYYY-MM-DD")}
    //                 </TableCell>
    //                 <TableCell>{days}</TableCell>
    //                 <TableCell>Transport Means</TableCell>
    //                 <TableCell>Transport Cost</TableCell>
    //                 <TableCell>{row.missionCost?.toLocaleString()}</TableCell>
    //                 <TableCell>
    //                   {editable ? (
    //                     <Tooltip
    //                       title={"Edit"}
    //                       onClick={() => {
    //                         setAddNew(1);
    //                         const startDate = moment(row.startDate).format(
    //                           "YYYY-MM-DD"
    //                         );
    //                         const endDate = moment(row.endDate).format(
    //                           "YYYY-MM-DD"
    //                         );
    //                         setStartDate(startDate);
    //                         setEndDate(endDate);
    //                         setDistrictId(row.destination);
    //                         setProvinceId(distr?.province);
    //                         setTransportMethod(row?.transportMeans);
    //                         setKilometer(row?.kilometer ?? kilometer);
    //                         setTransportPrice(row?.transportPrice);
    //                       }}
    //                     >
    //                       <Edit />
    //                     </Tooltip>
    //                   ) : null}
    //                   <Tooltip
    //                     title={"Delete"}
    //                     color={"error"}
    //                     onClick={() => {
    //                       destination.splice(index, 1);
    //                       setDestination([...destination]);
    //                     }}
    //                   >
    //                     <Delete />
    //                   </Tooltip>
    //                 </TableCell>
    //               </TableRow>
    //             );
    //           })}
    //         </TableBody>
    //       </Table>
    //     </TableContainer>
    //   </Col>

    //   <AddForm
    //     maxWidth="md"
    //     mainFields={[
    //       {
    //         title: "",
    //         fields: [
    //           {
    //             label: "Start Date",
    //             name: "startDate",
    //             type: "date",
    //             required: true,
    //             col: 4,
    //             value: startDate,
    //             setValue: setStartDate,
    //             minDate: moment().startOf("day"),
    //             disabled: !isInitiator,
    //           },
    //           {
    //             label: "End Date",
    //             name: "endDate",
    //             type: "date",
    //             required: true,
    //             col: 4,
    //             value: endDate,
    //             setValue: setEndDate,
    //             // validate: validateEndDate,
    //             minDate: moment(startDate).startOf("day"),
    //             disabled: !isInitiator,
    //           },
    //           {
    //             name: "days",
    //             value: missionType,
    //             label: "Number of days",
    //             disabled: true,
    //             col: 4,
    //           },
    //           {
    //             label: "Province",
    //             name: "provinceId",
    //             required: true,
    //             type: "select",
    //             values: provinces.map((v) => ({
    //               label: `${v}`,
    //               value: v,
    //             })),
    //             col: 4,
    //             value: provinceId,
    //             setValue: setProvinceId,
    //             disabled: !isInitiator,
    //           },
    //           {
    //             label: "District",
    //             name: "destination",
    //             required: true,
    //             type: "select",
    //             values: district.map((v) => ({
    //               label: v.name,
    //               value: v.id,
    //             })),
    //             col: 4,
    //             value: districtId,
    //             setValue: setDistrictId,
    //             disabled: !isInitiator,
    //           },
    //           {
    //             label: "Transport Method",
    //             name: "transportMeans",
    //             type: "select",
    //             hidden: !financeAccess,
    //             col: 4,
    //             setValue: setTransportMethod,
    //             values: [
    //               {
    //                 label: "Private",
    //                 value: "private",
    //               },
    //               {
    //                 label: "Public",
    //                 value: "public",
    //               },
    //               {
    //                 label: "Group",
    //                 value: "group",
    //               },
    //               {
    //                 label: "NPPA",
    //                 value: "nppa",
    //               },
    //             ],
    //             required: true,
    //             value: info?.transportMeans,
    //           },
    //           {
    //             label: "Kilometers",
    //             name: "kilometer",
    //             required: true,
    //             value: kilometer,
    //             hidden: transportMethod !== "private",
    //             type: "number",
    //             col: 6,
    //             setValue: setKilometer,
    //           },
    //           {
    //             label: "Transport(Cost in RWF)",
    //             name: "transportPrice",
    //             value: transportPrice,
    //             //   transportMethod !== "private"
    //             //     ? info?.transportPrice
    //             //     : kilometer * missionTransportPerKm,
    //             hidden: !kilometer && transportMethod !== "public",
    //             disabled: transportMethod !== "public",
    //             col: 6,
    //             setValue: setTransportPrice,
    //           },
    //           {
    //             name: "missionCost",
    //             value: missionCost,
    //             hidden: allowance === null,
    //             col: 12,
    //             render: () => {
    //               const night = missionType - 1;
    //               const zone = district.find(
    //                 (value) => value.id === districtId
    //               );
    //               return (
    //                 <div className={"mb-3"}>
    //                   <Alert severity={"success"}>
    //                     <AlertTitle>
    //                       Your Allowance{" "}
    //                       <FormControlLabel
    //                         hidden={!financeAccess}
    //                         className="ms-2"
    //                         control={
    //                           <Switch
    //                             checked={includeNight}
    //                             onChange={(event: any) => {
    //                               setIncludeNight(event.target.checked);
    //                             }}
    //                           />
    //                         }
    //                         label="Include night"
    //                       />
    //                     </AlertTitle>
    //                     <div>
    //                       Day : <b>{allowance?.day?.toLocaleString()} RWF</b>
    //                     </div>
    //                     {includeNight ? (
    //                       <div>
    //                         Night :{" "}
    //                         <b>{allowance?.night?.toLocaleString()} RWF</b>
    //                       </div>
    //                     ) : null}

    //                     {startDate && endDate ? (
    //                       <div>
    //                         Total :{" "}
    //                         <b>
    //                           {missionCost?.toLocaleString()} RWF for{" "}
    //                           {missionType?.toLocaleString()} {"Day(s)"}
    //                           &nbsp;
    //                           {missionType > 1 && includeNight
    //                             ? `and ${night} Night(s)`
    //                             : null}
    //                         </b>
    //                       </div>
    //                     ) : null}
    //                     <div>
    //                       <b>Destination:</b> {zone?.name}
    //                     </div>
    //                   </Alert>
    //                 </div>
    //               );
    //             },
    //           },
    //         ],
    //       },
    //     ]}
    //     url={"/"}
    //     beforeSubmit={(e) => {
    //       const arrayList = [...destination];
    //       arrayList.push(e);
    //       setDestination(arrayList);
    //       setAddNew(0);
    //       return false;
    //     }}
    //     onSuccess={() => setAddNew(0)}
    //     open={addNew}
    //     setOpen={() => setAddNew(0)}
    //   />
    // </Row>
    <TableContainer className="border">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead >
          <TableRow  className="bg-light">
            <TableCell>Province</TableCell>
            <TableCell>District</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Days</TableCell>
            <TableCell>Transport Means</TableCell>
            <TableCell>Transport Cost</TableCell>
            <TableCell>Allowance</TableCell>
            {editable?<TableCell>Action</TableCell>:null}
            
          </TableRow>
        </TableHead>
        <TableBody>
          {destination?.map((row: any, index: any) => {
            const distr = allDistrict?.find(
              (item: any) => item?.id === row.destination
            );
            let days = moment(row?.endDate).diff(
              moment(row?.startDate),
              "days"
            );
            days = days + 1;
            return (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{distr?.province}</TableCell>

                <TableCell component="th" scope="row">
                  {distr?.name}
                </TableCell>
                <TableCell>
                  {moment(row.startDate).format("YYYY-MM-DD")}
                </TableCell>
                <TableCell>
                  {moment(row.endDate).format("YYYY-MM-DD")}
                </TableCell>
                <TableCell>{days}</TableCell>
                <TableCell>{row?.transportMeans}</TableCell>
                <TableCell>{row?.transportPrice?.toLocaleString()}</TableCell>
                <TableCell>{row.missionCost?.toLocaleString()}</TableCell>
                {editable ? (
                  <TableCell>
                    <Tooltip
                      title={"Edit"}
                      onClick={() => {
                        setAddNew(1);
                        onEdit({ ...row, province: distr?.province, index });
                      }}
                    >
                      <Edit />
                    </Tooltip>
                    <Tooltip
                      title={"Delete"}
                      color={"error"}
                      onClick={() => {
                        destination.splice(index, 1);
                        setDestination([...destination]);
                      }}
                    >
                      <Delete />
                    </Tooltip>
                  </TableCell>
                ) : null}
              </TableRow>
            );
          })}
            <TableRow className="bg-light">
            <TableCell colSpan={7} className="fw-bold">Total Allowance</TableCell>
            <TableCell className="fw-bold">{missionCost?.toLocaleString()}</TableCell>

            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MissionDestination;
