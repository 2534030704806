import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { Col, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import { fetchDetails, monthList } from "../../../services/common";
import FileUpload from "../../../components/FileUploader";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/helpers/mapState";
import { mapDispatchToProps } from "../../../store/helpers/mapDispatch";
import { AddForm } from "../../employee/AddForm";

const CreateContribution = (props: any) => {
  const { loggedUser } = props;
  const navigate = useNavigate();
  const [info, setInfo] = React.useState<any>();
  const { id } = useParams();
  const [files, setFiles] = React.useState<any>([]);
  const [error, setError] = React.useState<any>();
  const [unPaidContribution, setUnPaidContribution] = React.useState<any>([]);
  const [selectedMonth, setSelectedMonth] = React.useState<any>();

  // console.log(selectedMonth);
  // let months = month?.split(",") ?? [];
  const memberId = loggedUser?.Employee?.CaisseMember?.id;

  // const isMultiple = months.length > 1;
  const fetchInfoDetails = async (id: string) => {
    const resp = await fetchDetails(`contribution/view/${id}`);
    if (resp.status) {
      setInfo(resp.data);
    }
  };

  const getAllUnpaidContribution = async () => {
    const resp = await fetchDetails(
      `contribution/search?info=${JSON.stringify({
        memberId,
        status: "payment pending",
      })}`
    );
    if (resp.status) {
      setUnPaidContribution(resp.data);
    }
  };

  useEffect(() => {
    if (id) {
      fetchInfoDetails(id).then();
    }
    if (selectedMonth) {
      setInfo(
        unPaidContribution?.find((item: any) => item.id === selectedMonth)
      );
    }
  }, [id, selectedMonth]);

  // useEffect(() => {
  //   if (loggedUser) {
  //     setShare(loggedUser?.Employee?.CaisseMember?.share);
  //   }
  // }, [loggedUser]);

  useEffect(() => {
    getAllUnpaidContribution();
  }, []);

  // const d = new Date();
  const AllUnPaidContribution = unPaidContribution?.map((v: any) => {
    const customMonth = monthList?.find((item: any) => item?.id === v.month);
    return { label: customMonth?.name, value: v.id };
  });
  return (
    <div>
      <Breadcrumb />
      <Row className={"justify-content-center"}>
        <Col md={12}>
          <AddForm
            formAlert={
              error
                ? "You need to contribute by starting to pay the previously unpaid amount."
                : null
            }
            inline={true}
            col={12}
            mainFields={[
              {
                title: "",
                fields: [
                  // {
                  //   label: "Month",
                  //   name: isMultiple ? "months" : "month",
                  //   type: isMultiple ? "multi-select" : "select",
                  //   disabled: true,
                  //   value: isMultiple
                  //     ? months.map((v) => Number(v))
                  //     : info?.month ??
                  //       Number(month) ??
                  //       loggedUser?.currentMonth,
                  //   values: monthList.map((v: any) => ({
                  //     label: v.name,
                  //     value: v.id,
                  //   })),
                  //   required: true,
                  // },
                  {
                    label: "Status",
                    name: "status",
                    value: info?.status,
                    hidden: true,
                  },
                  {
                    label: "Month",
                    name: "id",
                    type: "select",
                    values: AllUnPaidContribution,
                    required: true,
                    hidden:info?.id,
                    value:info?.id,
                    setValue: setSelectedMonth,
                  },
                  {
                    label: "Amount",
                    name: "amount",
                    type: "number",
                    value: info?.amount,
                    // disabled: true,
                    required: true,
                  },
                  {
                    label: "Reference No",
                    name: "referenceNumber",
                    type: "text",
                    value: info?.referenceNumber,
                    required: true,
                  },
                  {
                    label: "Attach Proof of Payment",
                    name: "supportingDocument",
                    type: "file",
                    multiple: true,
                    required:!info?.supportDocument,
                    value: files,
                    render: () => {
                      return (
                        <FileUpload
                          setFiles={setFiles}
                          files={files}
                          required={!info?.supportDocument}
                          accept='application/pdf'
                          currentFile={info?.supportDocument}
                        />
                      );
                    },
                  },
                ],
              },
            ]}
            beforeSubmit={() => {
              let value = unPaidContribution?.map((item: any) => item.month)
              value = Math.min(...value);
              if (value !== info?.month &&info?.status==='payment pending') {
                setError("You need to contribute by starting to pay the previously unpaid amount.");
                return false;
              } else {
                setError(null);
                return true;
              }
            }}
            url={ "contribution/create"}
            method={ "POST"}
            title={
              id ? "Update Monthly Installment" : "Make Monthly Installment Payment"
            }
            onSuccess={() => navigate("../")}
          />
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateContribution);
