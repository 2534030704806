import { submitGeneralForm } from "./common";
import secureLocalStorage from "react-secure-storage";

export const keyUser = "authx.user";
const registeredUsers = new Map([
  [
    "admin",
    {
      id: "uid:0",
      username: "admin",
      email: "admin@example.com",
      password: "qwerty",
      firstname: "App",
      lastname: "Admin",
    },
  ],
  [
    "lee",
    {
      id: "uid:973236115",
      username: "lee",
      email: "lee@acme.com",
      password: "12345",
      firstname: "Steve",
      lastname: "Lee",
    },
  ],
]);

function newUID() {
  const epoch = Math.floor(new Date().getTime() / 1000).toString();
  return `uid:${epoch}`;
}

export function setSession(user: any, token: any) {

  // Remove the password property.
  const { password, ...rest } = user;

  // Merge token to the final object.
  const merged = {
    ...rest,
    token,
  };

  secureLocalStorage.setItem(keyUser, JSON.stringify(merged));//store encrypted token in local storage
  setPermission(user);
}

export const setPermission = (user:any)=>{
  const win: any = window;
  win.permissions=user.permission
}

function getSession() {
  const user = secureLocalStorage.getItem(keyUser);

  if (typeof user === "string") {
    return JSON.parse(user);
  } else {
    return null;
  }
}

function isAuth() {
  return !!getSession();
}

async function login(email: any, password: any,otpCode:any) {
  return await submitGeneralForm("auth/signin", {
    email:email.replace(/^\s+|\s+$/g, ''),
    password,
    otpCode
  });
}

async function logout() {
  // await submitGeneralForm('user/logout',{},'PUT')
  secureLocalStorage.removeItem('ippisHeader');
  return secureLocalStorage.removeItem(keyUser);
}

async function sendPasswordReset() {
  return new Promise((resolve) => {
    // Using setTimeout to simulate network latency.
    setTimeout(() => {
      resolve(null);
    }, 1000);
  });
}

async function addUser(user: any) {
  return new Promise((resolve) => {
    // Using setTimeout to simulate network latency.
    const id = newUID();
    setTimeout(() => {
      const merged = {
        ...user,
        id,
      };

      registeredUsers.set(user.username, merged);
      resolve(merged);
    }, 1000);
  });
}

async function getUsers() {
  return new Promise((resolve) => {
    // Using setTimeout to simulate network latency.
    setTimeout(() => {
      const users = Array.from(registeredUsers.values());
      resolve(users);
    }, 1000);
  });
}

// The useAuth hook is a wrapper to this service, make sure exported functions are also reflected
// in the useAuth hook.
export {
  getSession,
  isAuth,
  login,
  logout,
  sendPasswordReset,
  addUser,
  getUsers,
};
