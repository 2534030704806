import * as React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import JoditEditor from 'jodit-react';
import DialogContent from '@mui/material/DialogContent';
import { FileUploader } from 'react-drag-drop-files';
import { default as ReactSelect } from 'react-select';
import {
  Alert,
  Breakpoint,
  CircularProgress,
  FormControl,
  Switch,
  Typography
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import ListTile from './ListTile';
import { useForm, Controller } from 'react-hook-form';
import { useEffect } from 'react';
import moment, { Moment } from 'moment';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import {getError, getSuccessMessage, submitGeneralForm,fetchDetails} from '../services/common';
import { toast } from 'react-toastify';
import {Card} from "react-bootstrap";

const cheerio = require('cheerio');
const fileTypes = ['PDF', 'JPG', 'JPEG', 'PNG', 'GIF'];

export function CheckBoxItem(props: any) {
  const { item } = props;

  const col = item.checkCol ?? 6;

  return (
    <div className={'flex flex-wrap'}>
      {item.values?.map((v: any) => (
        <div className={`w-full lg:w-${col}/12 xl:w-${col}/12 px-4`}>
          <ListTile
            selected={item.value?.filter((i: any) => i === v.value)?.length > 0}
            handleToggle={(it: any) => {
              if (item.setValue && typeof item.setValue === 'function') {
                if (item.value?.filter((i: any) => i === v.value)?.length > 0) {
                  item.setValue(item.value.filter((i: any) => i !== v.value));
                } else if (item.value?.length) {
                  item.value.push(v.value);
                  item.setValue([...item.value]);
                } else {
                  item.setValue([v.value]);
                }
              }
            }}
            item={{ label: v.label }}
          />
        </div>
      ))}
    </div>
  );
}

export type FieldType =
  | 'tel'
  | 'email'
  | 'textarea'
  | 'text'
  | 'select'
  | 'editor'
  | 'file'
  | 'file-drag'
  | 'switch'
  | 'date'
  | 'datetime-local'
  | 'checkbox'
  | 'name'
  | 'number'
  |'time';

export type ValueItem = { label: string; value: string };

export type FieldItem = {
  name: string;
  label?: string;
  value?: any;
  type?: FieldType | undefined | null;
  required?: boolean | undefined;
  hidden?: boolean | undefined;
  setValue?: (val: any) => void | undefined | null;
  uploadUrl?: string | undefined | null;
  fileKey?: string | undefined | null;
  submitAsString?: boolean | undefined | null;
  multiple?: boolean | undefined | null;
  disabled?: boolean | undefined | null;
  path?: string | undefined | null;
  dir?: string | undefined | null;
  minLength?: number | undefined | null;
  maxLength?: number | undefined | null;
  min?: number | undefined | null;
  max?: number | undefined | null;
  maxDate?: Moment | undefined | null;
  minDate?: Moment | undefined | null;
  validate?: (val: any) => boolean;
  render?: () => ReactJSXElement;
  col?: number | undefined;
  id?: string | undefined;
  placeholder?: string | undefined | null;
  rows?: number | undefined | null;
  values?: Array<ValueItem> | undefined | null;
  fileTypes?: Array<any> | undefined | null;
};

type IProps = {
  open?: number | undefined;
  formAlert?: string | undefined;
  setOpen?: (status: number) => void;
  title?: string | undefined;
  fields: Array<FieldItem>;
  url: string;
  backPath?: string | undefined;
  onSuccess: (data: any) => void;
  col?: number | undefined;
  maxWidth?: Breakpoint | undefined;
  useMaterial?: boolean | undefined;
  buttonColor?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
  buttonTitle?: string | undefined;
  method?: 'POST' | 'PUT' | 'DELETE' | 'GET' | undefined;
  inline?: boolean | undefined;
  locationFields?: boolean | undefined;
  footer?: ReactJSXElement | undefined;
};




export function FormDialog(props: IProps) {
  const {
    open,
    formAlert,
    setOpen,
    title,
    fields,
    url,
    onSuccess,
    backPath,
    col,
    maxWidth,
    useMaterial,
    buttonColor,
    buttonTitle,
    method,
    inline,
    footer,
    locationFields
  } = props;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    clearErrors,
    control
  } = useForm();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | undefined | null>(null);
  const [provinceId, setProvinceId] = React.useState<string | undefined>();
  const [districtId, setDistrictId] = React.useState<string | undefined>();
  const [sectorId, setSectorId] = React.useState<string | undefined>();
  const [cellId, setCellId] = React.useState<string | undefined>();
  const [villageId, setVillageId] = React.useState<string | undefined>();
  const [provinces, setProvinces] = React.useState([]);
  const [districts, setDistricts] = React.useState([]);
  const [sectors, setSectors] = React.useState([]);
  const [cells, setCells] = React.useState([]);
  const [villages, setVillages] = React.useState([]);
  const [value,setFormValue] = React.useState<any>({});

  const setValue0 = (name: string, val: any) => {
    if (!name) {
      return null;
    }

    setFormValue((v: any) => {
      v[name] = val;
      return { ...v };
    });
  };


  const locations: Array<FieldItem> = [
    {
      name: 'provinceId',
      type: 'select',
      value: provinceId,
      values: provinces.map((v: any) => ({
        label: v.label,
        value: v.value
      })),
      label: 'Province',
      required: true,
      setValue: setProvinceId
    },
    {
      name: 'districtId',
      type: 'select',
      required: true,
      label: 'District',
      values: districts.map((v: any) => ({
        label: v.label,
        value: v.value
      })),
      value: districtId,
      setValue: setDistrictId
    },
    {
      name: 'sectorId',
      type: 'select',
      required: true,
      label: 'Sector',
      values: sectors.map((v: any) => ({
        label: v.label,
        value: v.value
      })),
      value: sectorId,
      setValue: setSectorId
    },
    {
      name: 'cellId',
      type: 'select',
      required: true,
      label: 'Cell',
      values: cells.map((v: any) => ({
        label: v.label,
        value: v.value
      })),
      value: cellId,
      setValue: setCellId
    },
    {
      name: 'village',
      type: 'select',
      required: true,
      label: 'Village',
      values: villages.map((v: any) => ({
        label: v.label,
        value: v.value
      })),
      value: villageId,
      setValue: setVillageId
    }
  ];

  useEffect(() => {
    watch((value, { name, type }) => {
      if (type === 'change') {
        fields.forEach((v) => {
          if(name && name === v.name){
            setValue0(name,value[name]);
          }
          if (name && name === v.name && v.setValue && typeof v.setValue === 'function') {
            v.setValue(value[name]);
          }
        });
        if (locationFields) {
          locations.forEach((v) => {
            if (name && name === v.name && v.setValue && typeof v.setValue === 'function') {
              v.setValue(value[name]);
            }
          });
        }
      }
    });
  }, [watch, fields]);

  useEffect(() => {
    fields.forEach((v) => {
      const checkedValue = v.value ?? value[v.name];
      setValue(v.name, checkedValue);

      setValue0(v.name,checkedValue);
    });
  }, [fields]);

  const loadProvinces = async () => {
    const resp = await fetchDetails('admin/dropdowns/provinces');
    if (resp.status) {
      setProvinces(resp.data.data);
    }
  };

  const loadDistricts = async (provinceId: string) => {
    const resp = await fetchDetails(`admin/dropdowns/districts?province=${provinceId}`);
    if (resp.status) {
      setDistricts(resp.data.data);
    }
  };

  const loadSectors = async (districtId: string) => {
    const resp = await fetchDetails(`admin/dropdowns/sectors?district=${districtId}`);
    if (resp.status) {
      setSectors(resp.data.data);
    }
  };

  const loadCells = async (sectorId: string) => {
    const resp = await fetchDetails(`admin/dropdowns/cells?sector=${sectorId}`);
    if (resp.status) {
      setCells(resp.data.data);
    }
  };

  const loadVillages = async (cellId: string) => {
    const resp = await fetchDetails(`admin/dropdowns/villages?cell=${cellId}`);
    if (resp.status) {
      setVillages(resp.data.data);
    }
  };

  useEffect(() => {
    if (locationFields) {
      loadProvinces().then();
    }
  }, [locationFields]);

  useEffect(() => {
    if (provinceId) {
      setValue('districtId', undefined);
      setValue('sectorId', undefined);
      setValue('cellId', undefined);
      setValue('village', undefined);
      loadDistricts(provinceId).then();
    }
  }, [provinceId]);

  useEffect(() => {
    if (districtId) {
      setValue('sectorId', undefined);
      setValue('cellId', undefined);
      setValue('village', undefined);
      loadSectors(districtId).then();
    }
  }, [districtId]);

  useEffect(() => {
    if (sectorId) {
      setValue('cellId', undefined);
      setValue('village', undefined);
      loadCells(sectorId).then();
    }
  }, [sectorId]);

  useEffect(() => {
    if (cellId) {
      setVillageId(undefined);
      setValue('village', undefined);
      loadVillages(cellId).then();
    }
  }, [cellId]);

  const onSubmit = async (event: any) => {
    fields?.map((v) => {
        if (event[v.name]) {
          v.value = event[v.name];
        }
    });

    setLoading(true);
    let formData: FormData | undefined;

    for (let item of fields) {
      if (item.uploadUrl && typeof item.value !== 'string') {
        let form = new FormData();
        if (item.dir) {
          form.append('dir', item.dir);
        }
        if (item.path) {
          form.append('path', item.path);
        }
        let name = item.fileKey ?? item.name;
        if (item.multiple) {
          Array.from(item.value).forEach((file: any) => {
            form.append(name, file);
          });
        } else {
          form.append(name, item.value);
        }
        const response = await submitGeneralForm(item.uploadUrl, form);
        if (response.status) {
          item.value = response.data.data[0].path;
        }
      }
    }

    const handleFieldMap = (prev: any, c: FieldItem) => {
      let { value } = c;

      if (c.submitAsString && value) {
        value = JSON.stringify(value);
      }

      if (!c.uploadUrl && (c.type === 'file' || c.type === 'file-drag')) {
        formData = formData ?? new FormData();
        if (value) {
          if (c.multiple) {
            Array.from(c.value).forEach((file: any) => {
              if (formData) {
                formData.append(c.name, file);
              }
            });
          } else {
            formData.append(c.name, value);
          }
        }
      } else {
        prev[c.name] = value;
      }
      return prev;
    };

    let map = fields.reduce(handleFieldMap, {});
    if (locationFields) {
      map = locations.reduce(handleFieldMap, map);
    }
    if (formData) {
      formData.append('body', JSON.stringify(map));
    }
    const resp = await submitGeneralForm(url, formData ?? map, null, method);
    if (resp.status) {
      toast.success(getSuccessMessage(resp));
      handleClose();
      if (onSuccess && typeof onSuccess === 'function') {
        onSuccess(resp.data);
      }
    } else {
      setError(getError(resp));
    }
    setLoading(false);
  };
  const handleClose = () => {
    fields?.map((v: any) => {
      if (v.setValue && typeof v.setValue === 'function') {
        v.setValue(null);
      }
    });
    clearErrors();
    if (typeof setOpen === 'function') {
      setOpen(0);
    }
    setError(null);
  };

  const renderFieldItem = (v: FieldItem) => {
    if (v.hidden) {
      return null;
    }

    let requiredRule: any = { required: v.required ? `${v.label} is required !!!` : false };

    if (v.maxDate || v.minDate || typeof v.validate === 'function') {
      requiredRule = {
        ...requiredRule,
        validate: (val: any) => {
          let resp: any = true;
          if (v.maxDate) {
            resp = moment(val).isSameOrBefore(v.maxDate)
              ? true
              : `Date can't be greater than ${v.maxDate.format('YYYY-MMM-DD')}`;
          }

          if (resp === true && v.minDate) {
            resp = moment(val).isSameOrAfter(v.minDate)
              ? true
              : `Date can't be less than ${v.minDate.format('YYYY-MMM-DD')}`;
          }

          if (typeof v.validate === 'function') {
            return v.validate(val) ?? resp;
          }
          return resp;
        }
      };
    }

    // if(v.minDate){
    //     requiredRule = {
    //         ...requiredRule,
    //         validate:(val)=>
    //     }
    // }

    const validationProps = register(v.name, {
      ...requiredRule,
      minLength: v.minLength
        ? {
            value: v.minLength,
            message: `Minimum text length is ${v.minLength?.toLocaleString()}`
          }
        : undefined,
      maxLength: v.maxLength
        ? {
            value: v.maxLength,
            message: `Maximum text length is ${v.maxLength?.toLocaleString()}`
          }
        : undefined,
      max: v.hasOwnProperty('max')
        ? {
            value: v.max,
            message: `Maximum number is ${v.max?.toLocaleString()}`
          }
        : undefined,
      pattern:
        v.type === 'email'
          ? {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'invalid email address'
            }
          : v.type === 'name'
          ? {
              value: /^[a-zA-Z0-9_ \-=@,.;]+$$/i,
              message: 'Only Alphabet,space,number allowed'
            }
          : v.type === 'tel'
          ? {
              value: /^(^\+?(?:[+0]9)?[0-9]{8,14}$)$/i,
              message: 'Invalid Phone number'
            }
          : null
    });

    const type = v.type === 'email' ? 'text' : v.type ?? 'text';

    const errorClass = errors[v.name] ? 'border border-danger' : '';
    const errorClass2 = errors[v.name] ? 'select-error' : '';
    const className = `${
      v.disabled ? 'bg-gray-500 disabled:opacity-75' : ''
    } ${errorClass} form-control`;

    const label = (
      <label
        className={`block uppercase text-blueGray-600 text-xs font-bold ${
          v.type === 'switch' ? '' : 'mb-2'
        }`}
        htmlFor={v.id}
      >
        {v.label}
        {v.required ? <span className={'text-red-500 font-bold ml-1'}>*</span> : null}
      </label>
    );

    if (typeof v.render === 'function') {
      return (
        <div>
          {label}
          {v.render()}
        </div>
      );
    }

    return (
      <div
        className={`mb-3 w-full col-md-${v.col ?? col ?? 12} col-lg-${
          v.col ?? col ?? 12
        } xl:w-1/${v.col ?? col ?? 12} px-2`}
      >
        {v.type === 'switch' ? (
          <div className={'flex items-center'}>
            {/* {label} */}
            {value[v.name]?label:null}
            <Controller
              control={control}
              rules={{
                ...requiredRule
              }}
              name={v.name}
              render={({ field, fieldState: { error } }) => {
                return (
                  <Switch
                    className={error?.message ? 'border-danger' : undefined}
                    value={field.value ?? ''}
                    name={v.name}
                    checked={field.value === '1'}
                    onChange={(event, checked) => {
                      if (v.setValue && typeof v.setValue === 'function') {
                        setValue(v.name, `${checked ? 1 : 0}`);
                        field.onChange(`${checked ? 1 : 0}`);
                      }
                    }}
                  />
                );
              }}
            />
          </div>
        ) : v.type === 'editor' ? (
          <div>
            {/* {label} */}
            {value[v.name]?label:null}
            <Controller
              control={control}
              rules={{
                ...requiredRule,
                validate: v.required
                  ? (v) => {
                      if (!v) {
                        return requiredRule.required;
                      }
                      const $ = cheerio.load(v);
                      return $.text()?.trim()?.length ? true : requiredRule.required;
                    }
                  : undefined
              }}
              name={v.name}
              render={({ field, fieldState: { error } }) => {
                return (
                  <JoditEditor
                    className={error?.message ? 'border-red-500' : undefined}
                    value={field.value ?? ''}
                    onChange={(newContent: any) => {
                      if (v.setValue && typeof v.setValue === 'function') {
                        setValue(v.name, newContent);
                        v.setValue(newContent);
                      }
                    }}
                  />
                );
              }}
            />
          </div>
        ) : v.type === 'file-drag' ? (
          <div>
            {/* {label} */}
            {value[v.name]?label:null}
            <FileUploader
              multiple={v.multiple ?? false}
              classes={`${
                errors[v.name] ? 'border-red-500' : ''
              } w-full flex-grow-1 max-width-full-i`}
              style={{ maxWidth: 'auto !important' }}
              id={v.id}
              handleChange={(e: any) => {
                if (v.setValue && typeof v.setValue === 'function') {
                  v.setValue(e);
                }
              }}
              types={v.fileTypes ?? fileTypes}
              {...register(v.name, requiredRule)}
            />
          </div>
        ) : v.type === 'checkbox' ? (
          <div>
            {label}
            <CheckBoxItem item={v} />
          </div>
        ) : v.type === 'select' ? (
          useMaterial ? (
            <FormControl fullWidth size={'small'}>
              <InputLabel id="demo-simple-select-label">{v.label}</InputLabel>
              <Select
                native={true}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={v.value}
                label={v.label}
                {...register(v.name, requiredRule)}
              >
                <option value=""></option>
                {v.values?.map((item: any) => (
                  <option value={item.value}>{item.label}</option>
                ))}
              </Select>
            </FormControl>
          ) : (
            <div className="relative w-full">
              {/* {label} */}
              {value[v.name]?label:null}
              <Controller
                control={control}
                rules={{
                  ...requiredRule
                }}
                name={v.name}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <ReactSelect
                      placeholder={v.placeholder ?? v.label}
                      className={`text-nowrap ${errorClass2}`}
                      value={
                        !field.value ? null : v.values?.find((item) => item.value === field.value)
                      }
                      options={v.values?.map(({ label, value }) => ({ value, label }))}
                      onChange={(v) => field.onChange(v?.value)}
                    />
                  );
                }}
              />
            </div>
          )
        ) : useMaterial ? (
          <TextField
            autoFocus
            id={v.id}
            label={v.label}
            minRows={v.type === 'textarea' ? 5 : undefined}
            type={type}
            fullWidth
            InputLabelProps={
              v.type === 'date' || v.type === 'file' || v.type === 'datetime-local'
                ? {
                    shrink: true
                  }
                : {}
            }
            size={'small'}
            variant="outlined"
            {...validationProps}
          />
        ) : (
          <div className="relative w-full">
            {/* {label} */}
            {value[v.name]?label:null}
            {v.type === 'textarea' ? (
              <textarea
                rows={v.rows ?? 3}
                placeholder={v.placeholder ?? v.label}
                className={className}
                {...validationProps}
              ></textarea>
            ) : (
              <input
                type={type === 'name' ? 'text' : type}
                placeholder={v.placeholder ?? v.label}
                className={className}
                disabled={v.disabled ?? false}
                {...validationProps}
              />
            )}
          </div>
        )}

        {errors[v.name] && (
          <div role="alert" className={'text-danger text-xs'}>
            {errors[v.name]?.message?.toString()}
          </div>
        )}
      </div>
    );
  };

  const dialogContent = (
    <>
      {formAlert ? (
        <Alert style={{ width: '100%' }} className={'mb-3'} severity={'warning'}>
          {formAlert}
        </Alert>
      ) : null}

      {error ? (
        <Alert
          style={{ width: '100%' }}
          className={'mb-3'}
          severity={'error'}
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      ) : null}
      {/*<DialogContentText>*/}
      {/*</DialogContentText>*/}
      <div className={'row'}>
        {fields?.map(renderFieldItem)}
        {locationFields ? <>{locations.map(renderFieldItem)}</> : null}
      </div>
      {footer}
    </>
  );

  const content = (
    <>
      <form action="/x" onSubmit={handleSubmit(onSubmit)}>
        <Card className={'shadow-sm position-relative overflow-hidden'}>
          <div className={'card-body'}>
            <h4>{title}</h4>
            {loading? (
              <div
                className={
                  'position-absolute h-100 w-100 top-0 start-0 d-flex z-50 justify-content-center align-items-center'
                }
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
              >
                <div
                  className={'bg-white p-3 rounded shadow text-center'}
                  style={{ zIndex: 20000 }}
                >
                  <div>
                    <CircularProgress color={'success'} />
                  </div>
                  <span>
                    <Typography style={{fontFamily:'inter'}}>Submitting ....</Typography>
                  </span>
                </div>
              </div>
            ) : null}
            {inline ? (
              dialogContent
            ) : (
              <DialogContent className={'pt-2'}>{dialogContent}</DialogContent>
            )}
            <div className={'clearfix mt-3'}>
              <div className={'float-end'}>
                <button type={'button'} onClick={onSuccess} className={'btn btn-secondary me-1'}><i className={'bi bi-x-lg'}></i> Cancel</button>
                <button type={'submit'} className={'btn btn-primary'}><i className={'bi bi-send-fill'}></i> Submit</button>
              </div>
            </div>
          </div>

        </Card>
      </form>
    </>
  );

  return (
    <div>
      {inline ? (
        <div className={'relative'}>{content}</div>
      ) : (
        <Dialog fullWidth={true} maxWidth={maxWidth ?? 'sm'} open={open !== undefined && open > 0} onClose={handleClose}>
          {content}
        </Dialog>
      )}
    </div>
  );
}
