import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { DatatableComponent } from "../../../components/DatatableComponent";
import moment from "moment";
import { useState } from "react";
import { CustomizedMenus } from "../../../components/customizeMenu";
import StatusComponent from "../../../components/StatusComponent";
import { Col, Row } from "react-bootstrap";

function ExitRequestList() {
  const navigate = useNavigate();
  const [list, setList] = useState<Array<any>>([]);
  const [change, setChange] = useState(0);

  return (
    <div>
      <Breadcrumb/>
      <Row>
        <Col md={12}>
      <DatatableComponent
        columns={[
          // {
          //   label: "Reason",
          //   name: "reason",
          //   options: {
          //     customBodyRender: (v: any) => v?.toLocaleString(),
          //   },
          // },
          {
            label: "Employee",
            name: "memberId",
            options: {
              // filter: true,
              // sort: false,
              customBodyRenderLite: (id: any, index: any) => list[index]?.CaisseMember?.employee?.names
          },
        },
          {
            label: "Date",
            name: "createdAt",
            options: {
              customBodyRender: (v: any) =>
                moment(v).format("YYYY-MMM-DD HH:mm"),
            },
          },
          {
            label: "Status",
            name: "status",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (status: any) => <StatusComponent status={status} badge={false}/>
              ,
            },
          },
          {
            name: "id",
            label: "Action",
            options: {
              filter: true,
              sort: false,
              customBodyRenderLite: (id: any, index: any) => {
                return (
                  <CustomizedMenus
                    onView={() => {
                      navigate(`details/${list[index].id}`);
                    }}
                  />
                );
              },
            },
          },
        ]}
        url={`approvalExits/datatable?change=${change}`}
        title={"Exit Requests"}
        processData={(data: any) => {
            setList(data.data);
            return data;
          }}
      />
</Col>
</Row>
    </div>
  );
}

export default ExitRequestList;
