import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { DatatableComponent } from "../../../components/DatatableComponent";
import { BorderColorOutlined } from "@mui/icons-material";
import moment from "moment/moment";
import {Chip, Tooltip} from "@mui/material";

const FiscalYearList = () => {
  const navigate = useNavigate();
  return (
    <div>
      {/* <Breadcrumb
        addButton={{ label: "Add Fiscal Year", onClick: () => navigate("create") }}
      /> */}
      <DatatableComponent
        columns={[
          {
            label: "Start",
            name: "startDate",
              options: {
                  customBodyRender: (v: any) =>
                      moment(v).format("YYYY-MMM-DD"),
              },
          },
          {
            label: "End",
            name: "endDate",
              options: {
                  customBodyRender: (v: any) =>
                      moment(v).format("YYYY-MMM-DD"),
              },
          },
          {
            label: "Status",
            name: "status",
              options: {
                customBodyRender:(v:any)=><Chip color={v === "open" ? 'success' : 'default'} label={v === "open" ? "Open" : "Closed"}/>
              }
          },
          {
            label: "Date",
            name: "createdAt",
            options: {
              customBodyRender: (v: any) =>
                moment(v).format("YYYY-MMM-DD HH:mm"),
            },
          },
        ]}
        url={"fiscal/datatable"}
        // actions={(id) => [
        //   <Tooltip title="update" className={"border-0 bg-transparent"}>
        //     <BorderColorOutlined onClick={() => navigate(`update/${id}`)} fontSize="small" />
        //   </Tooltip>,
        // ]}
        title={"Fiscal Years List"}
        // deleteUrl={(id) => `fiscal/delete/${id}`}
      />
    </div>
  );
};

export default FiscalYearList;
