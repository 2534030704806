import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { DatatableComponent } from "../../../components/DatatableComponent";
import { BorderColorOutlined } from "@mui/icons-material";
import { TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import DeleteDialog from "../../../components/DeleteDialog";

function ExpandedRows(props: any) {
    const {list}=props
  const navigate = useNavigate();
  return (
    <>

      {list?.map((row: any) => (
        <TableRow key={row.id}>
          <TableCell component="th" scope="row" colSpan={2}>
            {row.name}
          </TableCell>
          <TableCell>{row.location}</TableCell>
          <TableCell>
          <Tooltip title="delete" className={"border-0 bg-transparent"}>
            <BorderColorOutlined onClick={() => navigate(`update/${row?.id}`)} fontSize="small" />
          </Tooltip>
          {/* <button
                  title="delete"
                  className={"border-0 bg-transparent"}>
                  <Delete
                  onClick={() => {
                    setDeleteOpen(1);
                    setDeleteActionUrl(`/branch/delete/${row.id}`);
                  }}
                    fontSize="small"
                    className={"text-danger cursor-pointer"} />
                </button> */}
         
          </TableCell>
        </TableRow>
      ))}
    </>
  );
}
const BranchList = () => {
  const navigate = useNavigate();
  const [list, setList] = useState<any>();
  const [deleteOpen, setDeleteOpen] = useState(0);
  const [deleteActionUrl, setDeleteActionUrl] = useState<
  string | undefined | null
>();
const [change, setChange] = useState(0);

const reload = () => {
  setChange((v) => v + 1);
};
  return (
    <div>
      <Breadcrumb
        addButton={{ label: "Add Branch", onClick: () => navigate("create") }}
      />
      <DatatableComponent
        columns={[
          {
            label: "Name",
            name: "name",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (name: any) => (
                <Typography style={{fontFamily:'inter'}} className="fw-bold">{name}</Typography>
              ),
            },
          },
          {
            
            label: "Location",
            name: "Location",
            // name: "name",
            options: {
              // filter: true,
              sort: false,
            }
            
          },
          {
            
            label: "Action",
            name: "Action",
            // name: "name",
            options: {
              // filter: true,
              sort: false,
            }
            
          },
        ]}
        url={`branch/datatable?change=${change}`}
        processData={(data: any) => {
          setList(data.data);
          return data;
        }}
        // actions={(id) => [
        //   <IconButton onClick={() => navigate(`update/${id}`)}>
        //     <Update />
        //   </IconButton>,
        // ]}
        title={"Branches List"}
        // deleteUrl={(id) => `branch/delete/${id}`}
        ExpandedRows={(rowData: any, rowMeta: any) => {
          let index2 = rowMeta?.dataIndex;

          return (
            <ExpandedRows index={index2} list={list[index2]?.branches} setDeleteActionUrl={setDeleteActionUrl} setDeleteOpen={setDeleteOpen} />
          );
        }}
      />
      {deleteActionUrl ? (
        <DeleteDialog
          open={deleteOpen}
          onSuccess={reload}
          setOpen={setDeleteOpen}
          url={deleteActionUrl!}
        />
      ) : null}
    </div>
  );
};

export default BranchList;
