import Breadcrumb from "../../../components/Breadcrumb";
import { AddForm } from "../../employee/AddForm";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { fetchDetails, getError } from "../../../services/common";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/helpers/mapState";
import { mapDispatchToProps } from "../../../store/helpers/mapDispatch";
import moment from "moment";

const RequestShareIncrement = (props: any) => {
  const { loggedUser, cdeInfo } = props;
  const navigate = useNavigate();
  const [salary, setSalary] = React.useState(0);
  const [info, setInfo] = useState<any>();
  const [rate, setRate] = useState<any>();
  const [currentRate, setCurrentRate] = useState<any>();

  const { id } = useParams();
  const loadUserInfo = async () => {
    const resp = await fetchDetails(
      `caise/view-member/${loggedUser?.employeeId}`
    );
    if (resp?.status) {
      setInfo(resp.data);
      setSalary(resp.data?.currentSalary ?? resp.data?.employee?.salary);
      // setCurrentRate(resp.data?.shareRate);
    }
  };

  const loadInfo = async () => {
    const resp = await fetchDetails(`/increment/view/${id}`);
    if (resp?.status) {
      const v = resp.data;
      setInfo({
        ...v,
        startDate: moment(v.startDate).format("YYYY-MM-DD"),
        endDate: moment(v.endDate).format("YYYY-MM-DD"),
      });
      setCurrentRate(resp.data?.currentShareRate);
      setRate(resp.data?.shareRate - resp.data?.currentShareRate);
    }
  };
  useEffect(() => {
    if (id) {
      loadInfo().then();
    }
  }, [id]);

  useEffect(() => {
    if (loggedUser) {
      loadUserInfo().then();
      // setSalary(loggedUser?.Employee?.salary)
    }
  }, [loggedUser]);

  // const salary=loggedUser?.Employee?.salary
  const canUpdateSalary =
    info?.employee?.status !== "retired" && info?.employee?.status !== "exited";
  const currentRates = (cdeInfo?.shareRate * salary) / 100;
  return (
    <div>
      <Breadcrumb />
      <Row className={"justify-content-center"}>
        <Col md={12}>
          <AddForm
            inline={true}
            title={"Request monthly contribution increment"}
            // formAlert={(value) => {
            //   let cdeRate = (salary * cdeInfo?.shareRate) / 100;
            //   let share = value["share"];
            //   return (
            //     <span>
            //       {!isNaN(share) && (
            //         <span>
            //           Share <b>{share?.toLocaleString()}</b> of{" "}
            //           <b>{value["shareRate"]}</b>% and
            //         </span>
            //       )}{" "}
            //       {!isNaN(cdeRate) && (
            //         <span>
            //           Fixed 5%=<b>{cdeRate?.toLocaleString()}</b>
            //         </span>
            //       )}
            //       {!isNaN(share) && !isNaN(cdeRate) && (
            //         <span>
            //           {" "}
            //           = Grand Total <b>{(share + cdeRate)?.toLocaleString()}</b>
            //         </span>
            //       )}
            //     </span>
            //   );
            // }}
            col={6}
            mainFields={[
              {
                title: "",
                fields: [
                  {
                    label: "Net Salary",
                    name: "currentSalary",
                    value: salary,
                    col: 12,
                    disabled: canUpdateSalary,
                    setValue: setSalary,
                  },
                  {
                    label: "Fixed Rate(%)",
                    type: "number",
                    disabled: true,
                    value: cdeInfo?.shareRate,
                    name: "currentShareRate",
                  },
                  {
                    label: "Current monthly contribution",
                    name: "currentShare",
                    type: "number",
                    value: currentRates,
                    disabled: true,
                    // setValue:setCurrentRate
                  },
                  {
                    label: "Additional increment(%)",
                    required: true,
                    type: "number",
                    name: "additional",
                    value: rate,
                    setValue: setRate,
                    min: 1,
                  },
                  {
                    label: "Additional Amount",
                    required: true,
                    type: "number",
                    name: "incrementAmount",
                    value: rate > 0 ? (salary * rate) / 100 : 0,
                    disabled: true,
                  },
                  {
                    label: "New Share(%)",
                    disabled: true,
                    type: "number",
                    name: "shareRate",
                    value: Number(rate) + Number(cdeInfo?.shareRate),
                  },
                  {
                    label: "New monthly contribution",
                    name: "share",
                    type: "number",
                    value: (_val, values) =>
                      isNaN(values["shareRate"]) ||
                      isNaN(values["currentSalary"])
                        ? ""
                        : (values["currentSalary"] * values["shareRate"]) / 100,
                    disabled: true,
                  },
                  {
                    label: "Start Date",
                    name: "startDate",
                    type: "date",
                    value: info?.startDate,
                    minDate: moment().startOf("day"),
                    required: true,
                  },
                  {
                    label: "End Date",
                    name: "endDate",
                    type: "date",
                    value: info?.endDate,
                    required: true,
                    minDate: moment().startOf("day"),
                  },
                ],
              },
            ]}
            url={id ? `/increment/update/${id}` : `/increment/request`}
            onSuccess={() => navigate(-1)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestShareIncrement);
