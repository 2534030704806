import Breadcrumb from "../../../components/Breadcrumb";
import Typography from "@mui/material/Typography";
import { Box, Button, Tab, Tabs, styled } from "@mui/material";
import { AddForm } from "../../employee/AddForm";
import { useNavigate } from "react-router-dom";
import { DatatableComponent } from "../../../components/DatatableComponent";
import { useEffect, useState } from "react";
import { fetchDetails } from "../../../services/common";
import { default as ReactSelect } from "react-select";
import GeneralButton from "../../../components/Button";
import { Add } from "@mui/icons-material";
import { TabPanel, a11yProps } from "../../employee/leave/List";
import { Col, Row } from "react-bootstrap";

/** dividend discussed on Annual general meeting if will be added on members shares ,
   to be invested in  profitable business or be distributed on bank account of members 
   ( ndumva aha mwashyiramo izo option zose noneho hakazajya hafatwa umwanzuro yicyo twayakoraho 
    nyuma y'inama nk'uko twari twabivuze formula kuri izo nyungu ni ugufata 85% by'inyungu zabonetse tugakuba umugabane w'umunyamuryango tukagabanya imigabane yose y'abanyamuryango.*/
const DividendHomepage = () => {
  const navigate = useNavigate();

  const [totalAmount, setTotalAmount] = useState<any>();
  const [list, setList] = useState<Array<any>>([]);
  const [dividend, setDividend] = useState<any>(0);
  const [totalShares, setShares] = useState<any>();
  const [category, setCategory] = useState<any>();
  const [preview, setPreview] = useState<any>(false);
  const [change, setChange] = useState(0);
  const [fiscalYearList, setFiscalYearList] = useState<Array<any>>([]);
  const [fiscalYear, setFiscalYear] = useState<any>();
  const [open, setOpen] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [value, setValue] = useState(0);

  const getFiscalYear = async () => {
    const fiscalYear = await fetchDetails("/fiscal/view");

    if (fiscalYear.status) {
      const currentFiscalYear = fiscalYear?.data?.find(
        (year: any) => year.status === "open"
      );
      setFiscalYearList(fiscalYear?.data);
      setFiscalYear(currentFiscalYear?.id);
    }
  };
  useEffect(() => {
    //fetch all FiscalYear
    getFiscalYear().then();
  }, []);

  const StyledTabs = styled((props: any) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      backgroundColor: "#035c85",
    },
  });
  const StyledTab = styled((props: any) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      "&.Mui-selected": {
        color: "#035c85",
      },
      "&.Mui-focusVisible": {
        color: "#035c85",
      },
    })
  );
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div>
      <Breadcrumb
        endElement={
          dividend > 0 ? (
            <>
              {preview ? (
                <GeneralButton
                  variant="outlined"
                  size="medium"
                  name={"Cancel Preview"}
                  icon={<Add />}
                  onClick={() => {
                    setOpen(1);
                    setPreview(false);
                  }}
                />
              ) : (
                <GeneralButton
                  variant="outlined"
                  size="medium"
                  name={"Allocate Dividend"}
                  icon={<Add />}
                  onClick={() => {
                    setOpen(1);
                  }}
                />
              )}
            </>
          ) : null
        }
      />

      <div className="mb-3 align-items-end d-flex">
        <div className={"flex-grow-1"}>
          <label className="col-form-label text-capitalize">Fiscal Year</label>
          <ReactSelect
            className="text-nowrap"
            options={fiscalYearList?.map(({ label, id, name }: any) => ({
              value: id,
              label: label ?? name,
            }))}
            onChange={(v: any) => {
              setFiscalYear(v?.value);
            }}
          />
        </div>
      </div>
      {preview ? (
        <DatatableComponent
          columns={[
            {
              label: "Employee",
              name: "employee.firstName",
              options: {
                customBodyRenderLite: (id: any, index: any) => {
                  return (
                    <span className="text-capitalize  ">
                      {list[index]?.employee?.names}
                    </span>
                  );
                },
              },
            },

            {
              label: "Rate(%)",
              name: "shareRate",
              options: {
                customBodyRenderLite: (id: any, index: any) => {
                  return <span>{list[index]?.shareRate}</span>;
                },
              },
            },
            {
              label: "Shares(RWF)",
              name: "totalShare",
              options: {
                customBodyRenderLite: (id: any, index: any) => {
                  return <span>{list[index]?.totalShare}</span>;
                },
              },
            },
            {
              label: "To be received(Rwf)",
              name: "totalShare",
              options: {
                customBodyRenderLite: (id: any, index: any) => {
                  const percent = (dividend * percentage) / 100;
                  const newShares =
                    (list[index]?.shareRate * percent) / totalShares;
                  // console.log(list[index]?.shareRate, dividend, totalShares)
                  return <span>{newShares}</span>;
                },
              },
            },
          ]}
          url={
            preview
              ? `cde/dividend/datatable?category=${category?.id}`
              : `cde/datatable-shared-dividend/?change=${change}&&fiscalYear=${fiscalYear}`
          }
          // actions={(id) => [
          //   <button title="update" className={"border-0 bg-transparent"}>
          //     <Update onClick={() => navigate(`update/${id}`)} fontSize="small" />
          //   </button>,
          // ]}
          processData={(data: any) => {
            setList(data?.data);
            setDividend(data?.dividend);
            setShares(data?.totalShares);
            return data;
          }}
          title={category?.label}
        />
      ) : (
        <Row className={"p-3"}>
          <Col md={12} className=" bg-white">
            {" "}
            <Box sx={{ borderBottom: 1, Update: "divider" }}>
              <StyledTabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <StyledTab label="Cash/Share" {...a11yProps(1)} />

                <StyledTab label="Method" {...a11yProps(0)} />
              </StyledTabs>
            </Box>
            <TabPanel value={value} index={0}>
              <DatatableComponent
                columns={[
                  {
                    label: "Employee",
                    name: "memberId",
                    options: {
                      filter: true,
                      sort: false,
                      customBodyRenderLite: (id: any, index: any) => {
                        return (
                          <span>
                            {list[index]?.CaisseMember?.employee?.names}
                          </span>
                        );
                      },
                    },
                  },
                  {
                    label: "Method",
                    name: "method",
                    options: {
                      filter: true,
                      sort: false,
                      customBodyRender: (v: any) => v,
                    },
                  },
                  {
                    label: "Amount(RWF)",
                    name: "amount",
                    options: {
                      filter: true,
                      sort: false,
                      customBodyRender: (v: any) => v?.toLocaleString(),
                    },
                  },
                  {
                    label: "Proof",
                    name: "supportDocument",
                    options: {
                      filter: true,
                      sort: false,
                      customBodyRender: (v: any) => "None",
                    },
                  },
                ]}
                url={`cde/datatable-shared-dividend/?change=${change}&&fiscalYear=${fiscalYear}`}
                processData={(data: any) => {
                  setTotalAmount(data?.totalAmount);
                  setList(data?.data);
                  setDividend(data?.dividend);
                  setShares(data?.totalShares);
                  return data;
                }}
                title={
                  <div className="mb-4 row">
                    <Typography
                    style={{fontFamily:'Inter'}}
                      className="fw-bolder text-capitalize col-md-4 "
                      variant="h6"
                    >
                      {"Dividend allocation list"}
                    </Typography>
                    <span className="fw-bolder text-capitalize  col-md-4">
                      {"Amount shared:"}
                      <strong className="text-success ms-1">
                        {totalAmount
                          ? `${totalAmount?.toLocaleString()} RWF`
                          : 0}
                      </strong>
                    </span>
                    <span className="fw-bolder text-capitalize  col-md-4">
                      {"Available Dividend:"}
                      <strong className="text-success ms-1">
                        {dividend ? `${dividend?.toLocaleString()} RWF` : 0}
                      </strong>
                    </span>
                  </div>
                }
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <DatatableComponent
                columns={[
                  {
                    label: "Method",
                    name: "method",
                    options: {
                      filter: true,
                      sort: false,
                    },
                  },
                  {
                    label: "Percentage(%)",
                    name: "percent",
                    options: {
                      filter: true,
                      sort: false,
                    },
                  },
                  {
                    label: "Amount(RWF)",
                    name: "amount",
                    options: {
                      filter: true,
                      sort: false,
                      customBodyRender: (v: any) => v?.toLocaleString(),
                    },
                  },
                ]}
                url={`cde/datatable-dividend-allocation?fiscalYear=${fiscalYear}`}
                title={"DIVIDEND Allocation Methods"}
              />
            </TabPanel>
          </Col>
        </Row>
      )}
      {/* dividend allocation  form */}
      <AddForm
        maxWidth="lg"
        title={"Dividend allocation"}
        formAlert={
          <div>
            <strong className="me-3">
              N.B :Dividend allocate in 3 options: investment, adding to share
              capital, and distributing to members
            </strong>
            {category && category !== "investment" ? (
              <Button
                size="small"
                onClick={() => {
                  setPreview(true);
                  setOpen(0);
                }}
              >
                {/* <Edit fontSize="small" /> */}
                Preview
              </Button>
            ) : null}
          </div>
        }
        mainFields={[
          {
            title: "",
            fields: [
              {
                label: "Select option",
                name: "category",
                type: "select",
                setValue: setCategory,
                required: true,
                values: [
                  {
                    value: "investment",
                    label: "Investment",
                  },
                  {
                    value: "cash",
                    label: "Distribute cash or on bank account of members ",
                  },
                  {
                    value: "on share",
                    label: "Add on members shares ",
                  },
                ],
              },
              {
                label: "Percentage to be deducted on dividend",
                name: "percentage",
                type: "number",
                required: true,
                min: 1,
                max: 100,
                setValue: setPercentage,
              },
            ],
          },
        ]}
        url={`cde/dividend/use`}
        onSuccess={() => {
          setChange(change + 1);
          setOpen(0);
        }}
        open={open}
        setOpen={() => {
          setOpen(0);
        }}
      />
    </div>
  );
};

export default DividendHomepage;
