import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { DatatableComponent } from "../../../components/DatatableComponent";
import { BorderColorOutlined } from "@mui/icons-material";
import moment from "moment";
import { useEffect, useState } from "react";
import { fetchDetails } from "../../../services/common";
import { Tooltip, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { default as ReactSelect } from "react-select";

const DepartmentList = () => {
  const [list, setList] = useState<any>([]);
  const [header, setHeader] = useState<any>(null);
  const [parentId, setParentId] = useState<any>("all");
  const [branchId, setBranchId] = useState<any>("all");
  const navigate = useNavigate();
  const [branch, setBranch] = useState<any>([]);

  let branchList = branch?.map(({ label, id, name }: any) => ({
    value: id,
    label: label ?? name,
  }));

  const dataSummary = async () => {
    const branchList = await fetchDetails("/branch/view");
    if (branchList.status) {
      setBranch(branchList?.data);
      const hq = branchList?.data?.find((name: any) => name?.level === "hq");

      setBranchId(hq?.id);
    }
  };
  useEffect(() => {
    //fetch dataSummary
    dataSummary().then();
  }, []);
  const getChildren = async (structId: string) => {
    try {
      if (structId === null) {
        setParentId("all");
        setHeader(null);
      } else {
        setParentId(structId);
        const response = await fetchDetails(`/department/view/${structId}`);
        if (response.status) setHeader(response?.data);
      }
    } catch (error) {}
  };
  const departmentLevel = list[0]?.level;
  return (
    <div>
      <Breadcrumb
        addButton={{
          label: "Add Department",
          onClick: () => navigate("create"),
        }}
      />

      <Row className="mb-3 mt-3">
        <Col md={12}>
          {/* <label className="col-form-label text-capitalize">Branch</label> */}
          <ReactSelect
            className={"text-nowrap"}
            placeholder={"Select Branch..."}
            options={branchList}
            value={branchList?.find((option: any) => {
              return option?.value === branchId;
            })}
            onChange={(v: any) => {
              setBranchId(v?.value);
              setParentId("all");
            }}
          />
        </Col>
      </Row>
      <DatatableComponent
        columns={[
          {
            label: "Name",
            name: "name",
          },
          {
            label: "Level",
            name: "level",
          },
          // {
          //   name: "",
          //   label: "Unit",
          //   options: {
          //     //   filter: true,
          //     //   sort: false,
          //     customBodyRenderLite: (id: any, index: any) => {
          //       return (
          //         <span className="text-capitalize fw-bold">
          //           {list[index]?.children?.length}
          //         </span>
          //       );
          //     },
          //   },
          // },
          {
            label: "Date",
            name: "createdAt",
            options: {
              customBodyRender: (v: any) =>
                moment(v).format("YYYY-MMM-DD HH:mm"),
            },
          },
        ]}
        url={`department/datatable?parent=${parentId}&&branchId=${branchId}`}
        processData={(data: any) => {
          setList(data?.data);
          return data;
        }}
        actions={(id) => [
          // <button title="view details" className="border-0 bg-transparent">
          //   <Info
          //     onClick={() => {
          //       let head = list?.filter((item: any) => item?.id === id);
          //       setParentId(id);
          //       setHeader(head[0]);
          //       setBranchId(branch[0]?.id);
          //     }}
          //     fontSize="small"
          //     className="text-info cursor-pointer mr-4"
          //   />
          // </button>,
          <Tooltip title="update" className={"border-0 bg-transparent"}>
            <BorderColorOutlined
              onClick={() => navigate(`update/${id}`)}
              fontSize="small"
              className={"color cursor-pointer"}
            />
          </Tooltip>,
        ]}
        // title={"Departments List"}
        title={
          header ? (
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <Typography
                style={{fontFamily:'inter'}}
                  variant="h6"
                  className="breadcrumb-item pageTitle"
                  role="button"
                  onClick={() => {
                    // navigate("/");
                    getChildren(header?.parent);
                    setBranchId(branch[0]?.id);
                  }}
                >
                  {header?.name}
                </Typography>
                <Typography
                style={{fontFamily:'inter'}}
                  variant="h6"
                  className="breadcrumb-item active pageTitle text-capitalize"
                  aria-current="page"
                >
                  {header?.children[0]?.level
                    ? `${header?.children[0]?.level}(s)`
                    : ""}
                </Typography>
              </ol>
            </nav>
          ) : (
            `${departmentLevel}(s)`
          )
        }
        // deleteUrl={(id) => `department/delete/${id}`}
      />
    </div>
  );
};

export default DepartmentList;
