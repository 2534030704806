import React, { useState, useEffect } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import { Col, Row } from "react-bootstrap";
import { default as ReactSelect } from "react-select";
import {
  fetchDetails,
  getError,
  submitGeneralForm,
} from "../../../services/common";
import GeneralButton from "../../../components/Button";
import DeleteDialog from "../../../components/DeleteDialog";
import SmallDialog from "../../../components/SmallModel";
import ExpensesList from "./List";
import { CheckCircle } from "@mui/icons-material";
import { toast } from "react-toastify";

function ExpensesIndex() {
  const [value, setValue] = useState(0);
  const [change, setChange] = useState(0);
  const [open, setOpen] = useState(0);
  const [fiscalYearList, setFiscalYearList] = useState<Array<any>>([]);
  const [fiscalYear, setFiscalYear] = useState<any>();
  const [info, setInfo] = useState<any>();
  const [createdAt, setCreatedAt] = useState<any>();
  const [deleteOpen, setDeleteOpen] = useState(0);
  const [error, setError] = useState<any>();
  const [confirm, setConfirm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDialogue, setOpenDialogue] = useState<boolean>(false);
  const [deleteActionUrl, setDeleteActionUrl] = useState<
    string | undefined | null
  >();
  const [categories, setCategories] = useState<Array<any>>([]);
  const [newData, setData] = useState<any>();

  const getCategories = async () => {
    const categories = await fetchDetails("/cde/expense-categories");

    if (categories.status) {
      setCategories(categories?.data);
    }
  };
  const getFiscalYear = async () => {
    const fiscalYear = await fetchDetails("/fiscal/view");

    if (fiscalYear.status) {
      const currentFiscalYear = fiscalYear?.data?.find(
        (year: any) => year.status === "open"
      );
      setFiscalYearList(fiscalYear?.data);
      setFiscalYear(currentFiscalYear?.id);
    }
  };
  useEffect(() => {
    //fetch all FiscalYear
    getFiscalYear().then();
    getCategories().then();
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    setConfirm(false);
    const formData = new FormData();
    formData.append(
      "body",
      JSON.stringify({ status: "approved", id: newData?.id })
    );
    const url = `cde/record-expenses?id=${newData?.id}`;
    const resp = await submitGeneralForm(url, formData, null, "POST");
    if (resp.status) {
      setData({});
      setError(null);
      setOpenDialogue(false);
      setChange(change + 1);
    } else {
      toast.error(getError(resp));
    }
    setLoading(false);
  };
  return (
    <div>
      <Breadcrumb
        endElement={
          <>
            <GeneralButton
              name="record new"
              onClick={() => {
                setOpen(1);
                setInfo({});
              }}
            />
            {/* <GeneralButton
              name="Approve All"
              onClick={() => {
                setOpen(1);
                setInfo({});
              }}
            /> */}
          </>
        }
      />
      <Row>
        <Col md={3}>
          <label className="col-form-label text-capitalize">Fiscal Year</label>
          <ReactSelect
            className="text-nowrap"
            options={fiscalYearList?.map(({ label, id, name }: any) => ({
              value: id,
              label: label ?? name,
            }))}
            onChange={(v: any) => {
              setFiscalYear(v?.value);
            }}
          />
        </Col>
        <Col md={3}>
          <label className="col-form-label text-capitalize">Created At</label>
          <input
            type="date"
            className="form-control"
            value={info?.createdAt}
            onChange={(e: any) => {
              setCreatedAt(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {/* <Box sx={{ borderBottom: 1, Update: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Expenses" {...a11yProps(0)} />
              <Tab label="Expense Categories" {...a11yProps(1)} />
            </Tabs>
          </Box> */}
          <ExpensesList
            fiscalYear={fiscalYear}
            createdAt={createdAt}
            open={open}
            setOpen={setOpen}
            setDeleteOpen={setDeleteOpen}
            setDeleteActionUrl={setDeleteActionUrl}
            setChange={setChange}
            change={change}
            fiscalYearList={fiscalYearList}
            setOpenDialogue={setOpenDialogue}
            setData={setData}
          />
        </Col>
      </Row>
      {/* Add new expenses form */}

      {deleteActionUrl ? (
        <DeleteDialog
          open={deleteOpen}
          onSuccess={() => {
            setChange(change + 1);
            setDeleteOpen(0);
            setValue(value);
          }}
          setOpen={setDeleteOpen}
          url={deleteActionUrl!}
        />
      ) : null}
      {openDialogue ? (
        <SmallDialog
          open={openDialogue}
          setOpen={setOpenDialogue}
          onSubmit={onSubmit}
        >
          <div className="d-flex ">
            <CheckCircle color="success" sx={{ fontSize: 50 }} />
            <div className="mt-4">{"Are you sure you want to submit?"}</div>
          </div>
        </SmallDialog>
      ) : null}
    </div>
  );
}

export default ExpensesIndex;
