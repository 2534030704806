import Breadcrumb from "../../components/Breadcrumb";
import React, { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { AddForm } from "./AddForm";
import {
  CheckHasAnyPermission,
  fetchDataFromIPPIS,
  fetchDetails,
} from "../../services/common";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import { Delete, BorderColorOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";

const CreateEmployee = () => {
  const navigate = useNavigate();
  const [error, setError] = React.useState<any>();
  const [editRole, setEditRole] = React.useState<any>(false);
  const [national, setNationalId] = React.useState<any>();
  const [echelonId, setEchelonId] = React.useState<any>();
  const [roles, setRole] = React.useState<any>([]);
  const [department, setDepartment] = React.useState<any>([]);
  const [echelon, setEchelon] = React.useState<any>([]);
  const [level, setLevel] = React.useState<any>([]);
  const [position, setPosition] = React.useState<any>([]);
  const [positionList, setPositionList] = React.useState<any>([]);
  const [branch, setBranch] = React.useState<any>([]);
  const [bank, setBank] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<any>(false);
  const [units, setUnit] = React.useState<any>([]);
  const [branches, setBranches] = React.useState<Array<any>>([]);
  const [info, setInfo] = React.useState<any>({});
  const [addBranch, setAddBranch] = React.useState<number>(0);
  const { id }: any = useParams();
  const [userRoles, setUserRoles] = React.useState<any>([]);
  const [addRole, setAddRole] = React.useState(0);
  
  const fetchInfoDetails = async (id: string) => {
    const resp = await fetchDetails(`employee/view/${id}`);
    if (resp?.data) {
      setInfo({
        ...resp.data,
        probation: resp.data.probation ? "yes" : "no",
        birthDate: moment(resp.data.birthDate).format("YYYY-MM-DD"),
        employmentDate: moment(resp.data.employmentDate).format("YYYY-MM-DD"),
        branchId: null,
      });
      setNationalId(resp?.data?.nationalId);
      setEchelonId(resp?.data?.echlonId);
    }
    if (resp?.data?.employeeBranches?.length) {
      setBranches(
        resp.data.employeeBranches?.map((v: any) => ({
          branchLabel: v?.branch?.name,
          departmentLabel: v?.department?.name,
          branchId: v.branchId,
          departmentId: v.departmentId,
        }))
      );
    }
    if (resp?.data?.user?.roles?.length) {
      setUserRoles(
        resp?.data?.user?.roles?.map((role: any) => ({
          label: role.role.label,
          name: role.role.name,
          main: role.main,
          id: role.roleId,
        }))
      );
    }
  };

  useEffect(() => {
    if (id !== "new") {
      fetchInfoDetails(id).then();
    }
  }, [id]);

  useEffect(() => {
    laodInitialData();
  }, []);
  const laodInitialData = async () => {
    const roles = await fetchDetails("/role/view");
    const positions = await fetchDetails("/position/view");
    const level = await fetchDetails("/level/view");
    const echelon = await fetchDetails("/echlon/view");
    const branch = await fetchDetails("/branch/view");
    const bank = await fetchDetails("/bank/view");
    if (roles.status) setRole(roles?.data);
    if (positions.status){ setPosition(positions?.data)
       setPositionList(positions?.data)};
    if (echelon.status) setEchelon(echelon?.data);
    if (level.status) setLevel(level?.data);
    if (branch.status) setBranch(branch?.data);
    if (bank.status) setBank(bank?.data);
  };
  const onChangeDepartment = async (url: any, levelType: string) => {
    const department = await fetchDetails(url);

    let list = department?.data;
    if (levelType === "department"&&department.status) {
      setDepartment(list);
    }
    if (levelType === "unit") {
      setUnit(list);
    }
    // if (levelType === "level") {
    //   setLevels(list);
    // }
  };

  useEffect(() => {
    if (echelonId) {
      setPosition(positionList?.filter((item: any) => item.echlonId === echelonId));
      setInfo({ ...info, echlonId: echelonId });
    }
  }, [echelonId]);

  const hasAttendanceVerifier =
    userRoles?.filter((v: any) => v.name === "attendance-verifier")?.length > 0; 

  return (
    <div>
      <Breadcrumb activeLabel={id !== "new" ? "Edit" : "Create"} />
      <Row className={"justify-content-center"}>
        <Col md={12}>
          <AddForm
            incomingError={error}
            inline={true}
            title={id !== "new" ? "Edit Employee" : "Create New Employee"}
            col={6}
            mainFields={[
              {
                title: "",
                fields: [
                  {
                    label: "Enter National ID",
                    name: "search",
                    type: "number",
                    hidden: !CheckHasAnyPermission("settings-user"),
                    col: 4,
                    render: () => {
                      return loading ? (
                        <CircularProgress />
                      ) : (
                        <div className="input-group mb-3">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="National ID"
                            // required={true}
                            value={national}
                            onChange={(e: any) => {
                              setNationalId(e.target.value);
                            }}
                          />
                          <Button
                            size="sm"
                            onClick={async () => {
                              setLoading(true);
                              const resp = await fetchDataFromIPPIS(
                                `nppa-employee/${national ?? info?.nationalId}`
                              );
                              if (resp.status) {
                                let value = resp.data?.position?.toLowerCase();
                                let empPosition: any = position?.filter(
                                  (item: any) =>
                                    item.name?.toLowerCase().indexOf(value) !==
                                    -1
                                );
                                let empScale: any = echelon?.find(
                                  (item: any) => item.name === resp.data?.scale
                                );
                                let empLevel: any = level?.find(
                                  (item: any) => item.name === resp.data?.level
                                );
                                setEchelonId(empScale?.id);
                                setInfo({
                                  ...resp.data,
                                  positionId: empPosition[0]?.id,
                                  levelId: empLevel?.id,
                                  echlonId: empScale?.id,
                                  status:
                                    resp.data?.status === "Active"
                                      ? "in service"
                                      : resp.data?.status,
                                  salary: resp.data?.netSalary,
                                  category:
                                    resp.data?.employeeGroup?.toLowerCase(),
                                  phone: resp.data?.phoneNumber,
                                  civilStatus:
                                    resp.data?.civilStatus?.toLowerCase(),
                                  gender: resp.data?.gender?.toLowerCase(),
                                  birthDate: moment(resp.data.birthDate).format(
                                    "YYYY-MM-DD"
                                  ),
                                  employmentDate: moment(
                                    resp.data.employmentDate
                                  ).format("YYYY-MM-DD"),
                                });

                                setLoading(false);
                              } else {
                                setError(resp.data);
                                setLoading(false);
                              }
                            }}
                            disabled={!CheckHasAnyPermission("settings-user")}
                          >
                            Search from IPPIS
                          </Button>
                        </div>
                      );
                    },
                  },
                ],
              },
              {
                title: "Employee",
                fields: [
                  {
                    label: "First Name",
                    name: "firstName",
                    required: true,
                    col: 6,
                    value: info?.firstName,
                  },
                  {
                    label: "Last Name",
                    name: "lastName",
                    required: true,
                    col: 6,
                    value: info?.lastName,
                  },
                  {
                    label: "National ID",
                    name: "nationalId",
                    required: true,
                    disabled: !CheckHasAnyPermission("settings-user"),
                    col: 6,
                    value: info?.nationalId,
                    minLength: 16,
                    maxLength: 16,
                    type: "number",
                  },
                  {
                    label: "Father's Name",
                    name: "fatherName",
                    required: true,
                    col: 6,
                    value: info?.fatherName,
                  },
                  {
                    label: "Mother's Name",
                    name: "motherName",
                    required: true,
                    col: 6,
                    value: info?.motherName,
                  },
             
                  {
                    label: "Civil Status",
                    name: "civilStatus",
                    type: "select",
                    col: 6,
                    values: [
                      {
                        label: "Widow",
                        value: "widow",
                      },
                      {
                        label: "Married",
                        value: "married",
                      },
                      {
                        label: "Single",
                        value: "single",
                      },
                      {
                        label: "Divorced",
                        value: "divorced",
                      },
                    ],
                    required: true,
                    value: info?.civilStatus,
                  },
                  {
                    label: "Birth Date (Should be above 18)",
                    name: "birthDate",
                    // maxDate: moment(new Date()),
                    type: "date",
                    required: true,
                    col: 6,
                    value: info?.birthDate,
                    maxDate: moment().subtract(18, "years"),
                  },
                  {
                    label: "Country of Birth",
                    name: "birthCountry",
                    // type: "country",
                    required: true,
                    col: 6,
                    value: info?.birthCountry,
                  },
                  {
                    label: "Place of Birth",
                    name: "birthPlace",
                    type: "text",
                    required: true,
                    col: 6,
                    value: info?.birthPlace,
                  },
                  {
                    label: "Gender",
                    name: "gender",
                    type: "select",
                    col: 6,
                    values: [
                      {
                        label: "Male",
                        value: "male",
                      },
                      {
                        label: "Female",
                        value: "female",
                      },
                    ],
                    required: true,
                    value: info?.gender,
                  },
                  {
                    label: "Signature",
                    name: "signature",
                    type: "file-drag",
                    uploadUrl: "employee/upload/signature",
                    fileKey: "signature",
                  },
                ],
              },
              {
                title: "Contact Information ",
                fields: [
                  {
                    label: "Email",
                    name: "email",
                    type: "email",
                    required: true,
                    disabled: !CheckHasAnyPermission("settings-user"),
                    col: 6,
                    value: info?.email,
                  },
                  {
                    label: "Phone Number",
                    name: "phone",
                    type: "tel",
                    required: true,
                    col: 6,
                    value: info?.phone,
                  },
                ],
              },
              {
                title: "Employment",
                fields: CheckHasAnyPermission("settings-user")
                  ? [
                      {
                        label: "Scale",
                        name: "echlonId",
                        type: "select",
                        required: true,
                        values: echelon?.map((v: any) => ({
                          label: v.name,
                          value: v.id,
                        })),
                        col: 6,
                        value: info?.echlonId,
                        setValue: setEchelonId,
                      },
                      {
                        label: "Position",
                        name: "positionId",
                        type: "select",
                        // required: true,
                        values: position?.map((v: any) => ({
                          label: v.name,
                          value: v.id,
                        })),
                        col: 6,
                        value: info?.positionId,
                      },

                      {
                        label: "Unit",
                        name: "unit",
                        type: "select",
                        hidden: !info?.specialLevel,
                        values: units?.map((v: any) => ({
                          label: v.name,
                          value: v.id,
                        })),
                        col: 6,
                        setValue: (val) => {
                          setInfo({ ...info, departmentId: val });
                          onChangeDepartment(
                            `/department/search?info=${JSON.stringify({
                              parent: val,
                            })}`,
                            "level"
                          );
                        },
                      },
                      {
                        label: "Level",
                        name: "levelId",
                        type: "select",
                        value: info?.levelId,
                        // hidden: info?.level !== "level",
                        values: level?.map((v: any) => ({
                          label: v.name,
                          value: v.id,
                        })),
                        col: 4,
                        // setValue: (val) => {
                        //   setInfo({ ...info, departmentId: val });
                        // },
                      },
                      {
                        label: "Employee Number",
                        name: "employeeNumber",
                        type: "number",
                        col: 4,
                        required: true,
                        value: info?.employeeNumber,
                      },
                      {
                        label: "Employee Group",
                        name: "category",
                        type: "select",
                        col: 4,
                        values: [
                          {
                            label: "Contractual",
                            value: "contractual",
                          },
                          {
                            label: "Under Statute",
                            value: "under statute",
                          },
                          {
                            label: "Political appointee",
                            value: "political appointee",
                          },
                        ],
                        required: true,
                        value: info?.category,
                      },

                      // {
                      //     label: "Level",
                      //     name: "levelId",
                      //     type: "select",
                      //     required: true,
                      //     col: 6,
                      //     value: info?.levelId,
                      // },
                      // {
                      //   label: "Employee Group",
                      //   name: "employeeGroup",
                      //   required: true,
                      //   col: 6,
                      //   value: info?.employeeGroup,
                      // },
                      {
                        label: "Employment Date",
                        name: "employmentDate",
                        type: "date",
                        required: true,
                        col: 6,
                        value: info?.employmentDate,
                        maxDate: moment().startOf("day"),
                      },
                      {
                        label: "Status",
                        name: "status",
                        type: "select",
                        col: 6,
                        values: [
                          {
                            label: "Retired",
                            value: "retired",
                          },
                          {
                            label: "In service",
                            value: "in service",
                          },
                          {
                            label: "Exited",
                            value: "exited",
                          },
                        ],
                        required: true,
                        value: info?.status,
                      },
                      {
                        label: "In Probation?",
                        name: "probation",
                        type: "select",
                        col: 6,
                        values: [
                          {
                            label: "Yes",
                            value: "yes",
                          },
                          {
                            label: "No",
                            value: "no",
                          },
                        ],
                        required: true,
                        value: info?.probation,
                      },
                      {
                        label: "Card Number",
                        name: "cardNumber",
                        // required: true,
                        disabled: !CheckHasAnyPermission("settings-user"),
                        col: 6,
                        value: info?.cardNumber,
                        minLength: 10,
                        maxLength: 10,
                        type: "number",
                      },
                      // {
                      //   label: "Member in Caisse D'entrade?",
                      //   name: "caisseEntradeStatus",
                      //   type: "select",
                      //   col: 6,
                      //   values: [
                      //     {
                      //       label: "Active",
                      //       id: "active",
                      //     },
                      //     {
                      //       label: "Inactive",
                      //       id: "inactive",
                      //     }
                      //   ],
                      //   required: true,
                      //   value: info?.caisseEntradeStatus,
                      // },
                    ]
                  : [
                      {
                        label: "Position",
                        name: "positionId",
                        type: "select",
                        required: true,
                        values: position?.map((v: any) => ({
                          label: v.name,
                          value: v.id,
                        })),
                        col: 6,
                        value: info?.positionId,
                      },
                    ],
              },
              {
                title: CheckHasAnyPermission("settings-user")
                  ? "Branch Information"
                  : "",
                fields: CheckHasAnyPermission("settings-user")
                  ? [
                      {
                        name: "branches",
                        value: branches.map(({ departmentId, branchId }) => ({
                          departmentId,
                          branchId,
                        })),
                        col: 12,
                        render: () => {
                          return (
                            <>
                              <Button
                                onClick={() => setAddBranch(1)}
                                disabled={!CheckHasAnyPermission("settings-user")}
                              >
                                Add Branch
                              </Button>
                              {branches.length > 0 && (
                                <table className={"table table-striped"}>
                                  <thead>
                                    <tr>
                                      <th>Branch</th>
                                      <th>Department</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {branches.map((v, index) => (
                                      <tr>
                                        <td>{v.branchLabel}</td>
                                        <td>{v.departmentLabel}</td>
                                        <td>
                                          <IconButton
                                            color={"error"}
                                            onClick={() => {
                                              branches.splice(index, 1);
                                              setBranches([...branches]);
                                            }}
                                            // disabled={!CheckHasAnyPermission("settings")}
                                          >
                                            <Delete />
                                          </IconButton>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </>
                          );
                        },
                      },
                    ]
                  : [],
              },
              {
                title: CheckHasAnyPermission("settings-user")
                  ? "Banking Information"
                  : "",
                fields: CheckHasAnyPermission("settings-user")
                  ? [
                      {
                        label: "Bank Name",
                        name: "bankId",
                        type: "select",
                        required: true,
                        values: bank?.map((v: any) => ({
                          label: v.name,
                          value: v.id,
                        })),
                        col: 4,
                        value: info?.bankId,
                      },
                      {
                        label: "Account Number",
                        name: "accountNumber",
                        required: true,
                        col: 4,
                        value: info?.accountNumber,
                      },
                      {
                        label: "Net Salary",
                        name: "salary",
                        required: true,
                        col: 4,
                        type: "number",
                        value: info?.salary,
                      },
                    ]
                  : [],
              },
              {
                title: CheckHasAnyPermission("settings-user")
                  ? "Medical Insurance Information"
                  : "",
                fields: CheckHasAnyPermission("settings-user")
                  ? [
                      {
                        label: "Medical Insurance",
                        name: "medicalInsurance",
                        required: true,
                        col: 4,
                        value: info?.medicalInsurance,
                      },
                      {
                        label: "Insurance Number",
                        name: "medicalInsuranceNumber",
                        required: true,
                        col: 4,
                        value: info?.medicalInsuranceNumber,
                      },
                      {
                        label: "CRS Number",
                        name: "crsNumber",
                        required: true,
                        col: 4,
                        value: info?.crsNumber,
                      },
                    ]
                  : [],
              },
              {
                title: CheckHasAnyPermission("settings-user") ? "Roles" : "",
                fields: CheckHasAnyPermission("settings-user")
                  ? [
                      {
                        name: "roles",
                        value: userRoles,
                        col: 12,
                        render: () => {
                          return (
                            <>
                              <Button
                                onClick={() => setAddRole(1)}
                                disabled={!CheckHasAnyPermission("settings-user")}
                              >
                                Add Role
                              </Button>
                              {userRoles?.length > 0 && (
                                <table className={"table table-striped"}>
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Main</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {userRoles.map((v: any, index: any) => (
                                      <tr>
                                        <td>{v.label}</td>
                                        <td>
                                          {editRole ? (
                                            <select
                                              className="form-select form-select-sm"
                                              value={v.main ? "yes" : "no"}
                                              required={true}
                                              onChange={(e) => {
                                                v.main =
                                                  e.currentTarget.value ===
                                                  "yes";
                                                setUserRoles([...userRoles]);
                                              }}
                                            >
                                              <option>select</option>
                                              {["yes", "no"].map((item) => (
                                                <option value={item}>
                                                  {item}
                                                </option>
                                              ))}
                                            </select>
                                          ) : v.main ? (
                                            "yes"
                                          ) : (
                                            "no"
                                          )}
                                        </td>
                                        <td>
                                          <IconButton
                                          title="Update"
                                            color={"info"}
                                            onClick={() => {
                                              setEditRole(true);
                                              setUserRoles([...userRoles]);
                                            }}
                                            // disabled={!CheckHasAnyPermission("settings")}
                                          >
                                            <BorderColorOutlined />
                                          </IconButton>
                                          <IconButton
                                          title="Delete"
                                            color={"error"}
                                            onClick={() => {
                                              userRoles.splice(index, 1);
                                              setUserRoles([...userRoles]);
                                            }}
                                            // disabled={!CheckHasAnyPermission("settings")}
                                          >
                                            <Delete />
                                          </IconButton>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </>
                          );
                        },
                      }
                    ]
                  : [],
              },
            ]}
            // title={"Create New Employee"}
            url={id !== "new" ? `employee/update/${id}` : "/employee/create"}
            method={id !== "new" ? "PUT" : "POST"}
            beforeSubmit={() => {
              if (branches?.length <= 0) {
                toast.error("Employee branch cannot be empty.");
                return false;
              }
              if (userRoles?.length <= 0) {
                toast.error("Role cannot be empty.");
                return false;
              }

              const checkMainRole = userRoles?.filter(
                (type: any) => type?.main === true
              );

              if (!checkMainRole.length || checkMainRole?.length > 1) {
                toast.error("Please select one role as main.");
                return false;
              }
              return true;
            }}
            onSuccess={() => navigate(-1)}
          />
        </Col>
      </Row>
      <AddForm
        mainFields={[
          {
            title: "",
            fields: [
              {
                label: "Branch",
                name: "branchId",
                type: "select",
                required: true,
                values: branch?.map((v: any) => ({
                  label: v.name,
                  value: v.id,
                })),
                col: 12,
                value: info?.branchId,
                setValue: (val) => {
                  setInfo({ ...info, branchId: val, departmentId: null });
                  onChangeDepartment(
                    `/department/search?info=${JSON.stringify({
                      branchId: val,
                      employeeId: info?.transferee,
                    })}`,
                    "department"
                  ).then();
                },
              },
              {
                label: "Department",
                name: "departmentId",
                type: "select",
                required: true,
                values: department?.map((v: any) => ({
                  label: v.name,
                  value: v.id,
                })),
                value: info?.departmentId,
                setValue: (val) => {
                  setInfo({ ...info, departmentId: val });
                  onChangeDepartment(
                    `/department/search?info=${JSON.stringify({
                      parent: val,
                    })}`,
                    "unit"
                  ).then();
                },
              },
            ],
          },
        ]}
        url={"/"}
        beforeSubmit={(e) => {
          setInfo({ ...info, branchId: null, departmentId: null });
          setBranches((branches) => [
            ...branches,
            {
              ...e,
              branchLabel: branch?.find((v: any) => v.id === e.branchId)?.name,
              departmentLabel: department?.find(
                (v: any) => v.id === e.departmentId
              )?.name,
            },
          ]);
          setAddBranch(0);
          return false;
        }}
        onSuccess={() => setAddBranch(0)}
        open={addBranch}
        setOpen={() => setAddBranch(0)}
      />
      <AddForm
        maxWidth="md"
        mainFields={[
          {
            title: "",
            fields: [
              {
                label: "Roles",
                name: "role",
                type: "select",
                required: true,
                values: roles?.map((v: any) => ({
                  label: v.label,
                  value: v.id,
                })),
                value: info?.roleId,
                // setValue: (val) => {
                //   // onChangeDepartment(
                //   //   `/role/search?info=${JSON.stringify({
                //   //     parent: val,
                //   //   })}`,
                //   //   "unit"
                //   // ).then();
                // },
              },
            ],
          },
        ]}
        url={"/"}
        beforeSubmit={(e) => {
          const role = roles?.find((role: any) => role.id === e.role);
          setUserRoles([...userRoles, role]);
          setAddRole(0);
          return false;
        }}
        onSuccess={() => setAddRole(0)}
        open={addRole}
        setOpen={() => setAddRole(0)}
      />
    </div>
  );
};

export default CreateEmployee;
