import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { DatatableComponent } from "../../../components/DatatableComponent";
import StatusComponent from "../../../components/StatusComponent";
import moment from "moment";
import { Circle,  } from "@mui/icons-material";
import { a11yProps, TabPanel } from "../../employee/leave/List";
import React, { useState } from "react";
import { CheckHasPermission, getQuery } from "../../../services/common";
import { CustomizedMenus } from "../../../components/customizeMenu";
import TabHeadersComponent from "../../../components/UI/Tabs";
import { Col, Row } from "react-bootstrap";

function CaiseApplicationList() {
  const navigate = useNavigate();
  const location = useLocation();
  const [list, setList] = useState<Array<any>>([]);

  const tab = getQuery("tab") === "members";
  const [value, setValue] = useState(tab ? 1 : 0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const labels = [
    { label: "Applications", icon: <Circle /> },
    { label: "Members", icon: <Circle /> },
  ];
  return (
    <div>
      <Breadcrumb />
      <Row >
        <Col md={12}>
          <TabHeadersComponent
            value={value}
            handleChange={handleChange}
            labels={labels}
            width={409}
          />
          <TabPanel value={value} p={0} index={0}>
            <DatatableComponent
              elevation={0}
              componentClass={""}
              titleClass={"p-0"}
              columns={[
                {
                  label: "Amount",
                  name: "share",
                  options: {
                    customBodyRender: (v: any) => v?.toLocaleString(),
                  },
                },
                {
                  label: "Employee",
                  name: "employee.names",
                  data: "employee.firstName",
                },
                {
                  label: "Status",
                  name: "status",
                  options: {
                    customBodyRender: (v: any) => (
                      <StatusComponent status={v} badge={false} />
                    ),
                  },
                },
                {
                  label: "Date",
                  name: "createdAt",
                  options: {
                    customBodyRender: (v: any) =>
                      moment(v).format("YYYY-MMM-DD HH:mm"),
                  },
                },
                {
                  name: "id",
                  label: "Action",
                  options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (id: any) => {
                      return (
                        <CustomizedMenus
                          onView={() => {
                            navigate(`details/${id}`);
                          }}
                        />
                      );
                    },
                  },
                },
              ]}
              url={"caise/datatable"}
              title={"Applications List"}
            />
          </TabPanel>
          <TabPanel value={value} p={0} index={1}>
            <DatatableComponent
              elevation={0}
              componentClass={""}
              titleClass={"p-0"}
              columns={[
                {
                  label: "Employee",
                  name: "employee.firstName",
                  options: {
                    customBodyRenderLite: (id: any, index: any) => {
                      return (
                        <span className="text-capitalize  ">
                          {list[index]?.employee?.names}
                        </span>
                      );
                    },
                  },
                },
                {
                  label: "Has Loan",
                  name: "hasLoan",
                  options: {
                    customBodyRender: (v: any) => (v ? "Yes" : "No"),
                  },
                },
                {
                  label: "Monthly(RWF)",
                  name: "share",
                  options: {
                    customBodyRender: (v: any) => v?.toLocaleString(),
                  },
                },
                {
                  label: "Shares(RWF)",
                  name: "totalShare",
                  options: {
                    customBodyRender: (v: any) => v?.toLocaleString(),
                  },
                },
                // {
                //     label: "Rate(%)",
                //     name: "shareRate",
                //     options: {
                //         customBodyRender:(v:any)=>v?.toLocaleString()
                //     }
                // },
                // {
                //     label: "Joined",
                //     name: "joinedAt",
                //     options: {
                //         customBodyRender: (v: any) =>
                //             moment(v).format("YYYY-MMM-DD"),
                //     },
                // },
                {
                  label: "Status",
                  name: "status",
                  options: {
                    customBodyRender: (v: any) => (
                      <StatusComponent
                        status={v === "inactive" ? "exited" : v}
                        badge={false}
                      />
                    ),
                  },
                },
                {
                  name: "id",
                  label: "Action",
                  options: {
                    filter: true,
                    sort: false,
                    customBodyRenderLite: (id: any, index: any) => {
                      return (
                        <CustomizedMenus
                          onView={() => {
                            navigate(`member/${list[index]?.employeeId}`);
                          }}
                          onEdit={() => {
                            navigate(
                              `member/update/${list[index]?.employeeId}`
                            );
                          }}
                          needEdit={CheckHasPermission('approval-saving','create')&&list[index]?.status!=='inactive'}
                        />
                      );
                    },
                  },
                },
              ]}
              url={`caise/approvedMembers${
                location.state?.loan ? "?loan=1" : ""
              }`}
              title={"CDE Members"}
              processData={(data: any) => {
                setList(data.data);
                return data;
              }}
            />
          </TabPanel>
        </Col>
      </Row>
    </div>
  );
}

export default CaiseApplicationList;
