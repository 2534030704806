import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { DatatableComponent } from "../../../components/DatatableComponent";
import { BorderColorOutlined, Delete, Add } from "@mui/icons-material";
import { Box, Tab, TableCell, TableRow, Tabs, Tooltip, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import DeleteDialog from "../../../components/DeleteDialog";
import { TabPanel, a11yProps } from "../../employee/leave/List";
import { Col, Row } from "react-bootstrap";
import { AddForm } from "../../employee/AddForm";
import GeneralButton from "../../../components/Button";
import { fetchDetails } from "../../../services/common";
import { CustomizedMenus } from "../../../components/customizeMenu";

function ExpandedRows(props: any) {
  const { setDeleteActionUrl, list, setDeleteOpen } = props;
  const navigate = useNavigate();
  return (
    <>
      {list?.map((row: any) => {
        const zone=row?.zone?.name
        let district=row?.zone?.Districts?.map(({ name }: any) => name)??[]
        return (
          <TableRow key={row.id} >
            <TableCell component="th" scope="row" colSpan={2}>
              <span className="fw-bold">{zone}:</span>
              <span>{`(${district})`}</span>
            </TableCell>
            <TableCell>{row.day?.toLocaleString()}</TableCell>
            <TableCell>{row.night?.toLocaleString()}</TableCell>
            <TableCell>
              <Tooltip title="update" >
                <BorderColorOutlined
                  onClick={() => navigate(`create/${row.id}`)}
                  fontSize="small"
                />
              </Tooltip>
              <Tooltip title="delete" >
                <Delete
                  className={"text-danger cursor-pointer"}
                  onClick={() => {
                    setDeleteOpen(1);
                    setDeleteActionUrl(`/mission-allowance/delete/${row.id}`);
                  }}
                  fontSize="small"
                />
              </Tooltip>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
}
const MissionSettingList = () => {
  const navigate = useNavigate();
  const [list, setList] = useState<any>();
  const [deleteOpen, setDeleteOpen] = useState(0);
  const [value, setValue] = useState(0);
  const [addTransport, setAddTransport] = useState(0);
  const [transportInfo, setTransportInfo] = useState<any>({});
  const [levels, setLevel] = useState<any>([]);
  const [deleteActionUrl, setDeleteActionUrl] = useState<
    string | undefined | null
  >();
  const [change, setChange] = useState(0);

  const reload = () => {
    setChange((v) => v + 1);
  };
  
  useEffect(() => {
    laodInitialData();
  }, []);
  const laodInitialData = async () => {
    const level = await fetchDetails("/level/view");
    if(level.status)setLevel(level?.data);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const StyledTabs = styled((props: any) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      backgroundColor: "#035c85",
    },
  });
  const StyledTab = styled((props: any) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      "&.Mui-selected": {
        color: "#035c85",
      },
      "&.Mui-focusVisible": {
        color: "#035c85",
      },
    })
  );
  return (
    <div>
      <Breadcrumb
        // addButton={{ label: "Add New", onClick: () => navigate("create/new") }}
        endElement={
            <div className="d-flex justify-content-end">
              <GeneralButton
                variant="outlined"
                size="medium"
                name={value === 1?"New Transport":"New Fund"}
                icon={<Add />}
                onClick={() => {
                  if(value === 1){
                    setTransportInfo({})
                    setAddTransport(1);
                  }else{
                    navigate("create/new")
                  }
                }}
              />
            </div>
         
        }
      />
       <Row className={"justify-content-center p-3"}>
        <Col md={12} className=" bg-white">
        <Box sx={{ borderBottom: 1, Update: "divider" }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <StyledTab label="Allowance" {...a11yProps(0)} />
          <StyledTab label="Transport" {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <TabPanel value={value} index={0}>
      <DatatableComponent
        columns={[
          {
            label: "Level",
            name: "name",
            options: {
              customBodyRender: (name: any) => (
                <Typography style={{fontFamily:'inter'}} className="fw-bold">{name}</Typography>
              ),
            },
          },
          {
            label: "Day(Rwf)",
            name: "day",
          },
          {
            label: "Night(Rwf)",
            name: "Night",
          },
          {
            label: "Action",
            name: "Action",
          },
        ]}
        url={`mission-allowance/datatable?change=${change}`}
        processData={(data: any) => {
          setList(data.data);
          return data;
        }}
        title={"Mission Allowance"}
        ExpandedRows={(rowData: any, rowMeta: any) => {
          let index2 = rowMeta?.dataIndex;

          return (
            <ExpandedRows
              index={index2}
              list={list[index2]?.MissionAllowances}
              setDeleteActionUrl={setDeleteActionUrl}
              setDeleteOpen={setDeleteOpen}
            />
          );
        }}
      />
      </TabPanel>
      <TabPanel value={value} index={1}>
      <DatatableComponent
        columns={[
          {
            label: "Level",
            name: "Level.name",
            // options: {
           
            //   customBodyRender: (v: any) => (
            //     <Typography className="fw-bold">{v?.name}</Typography>
            //   ),
            // },
          },
          {
            label: "Amount/Km(Rwf)",
            name: "amount",
          },
          {
            name: "id",
            label: "Action",
            options: {
              filter: true,
              sort: false,
              customBodyRenderLite: (id: any, index: any) => {
                return (
                  <CustomizedMenus
                    onEdit={() => {
                      setAddTransport(1);
                    setTransportInfo(list[index])
                    }}
                    onDelete={() => {
                      setDeleteOpen(1);
                      setDeleteActionUrl(
                        `/mission-allowance/delete-transport/${list[index].id}`
                      );
                    }}
                    needEdit={true}
                    needDelete={true}
                  />
                );
              },
            },
          },
        ]}
        url={`mission-allowance/datatable-transport?change=${change}`}
        processData={(data: any) => {
          setList(data.data);
          return data;
        }}
        title={"Mission Transport"}
      />
      </TabPanel>
      </Col>
      </Row>
      <AddForm
        title={transportInfo?.id?"Update Transport":"Add New Transport"}
        maxWidth="md"
        mainFields={[
          {
            title: "",
            fields: [
         
              {
                label: "Level",
                name: "levelId",
                type: "select",
                required: true,
                values: levels?.map((v: any) => ({
                  label: v.name,
                  value: v.id,
                })),
                value: transportInfo?.levelId,
              },
              {
                label: "Amount(per km)",
                name: "amount",
                type:'number',
                required: true,
                col: 12,
                value:transportInfo?.amount
              },
            ],
          },
        ]}
        url={transportInfo?.id?`mission-allowance/update-transport/${transportInfo?.id}`:"mission-allowance/create-transport/"}
        method={transportInfo?.id?"PUT":"POST"}
        onSuccess={() => {
          setAddTransport(0);
          setTransportInfo({})
          reload()
        }}
        open={addTransport}
        setOpen={() => setAddTransport(0)}
      />
      {deleteActionUrl ? (
        <DeleteDialog
          open={deleteOpen}
          onSuccess={reload}
          setOpen={setDeleteOpen}
          url={deleteActionUrl!}
        />
      ) : null}
    </div>
  );
};

export default MissionSettingList;
