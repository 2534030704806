import { Add } from "@mui/icons-material";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import SmallDialog from "./SmallModel";
import { fetchDetails } from "../services/common";
import GeneralButton from "./Button";
import { mapStateToProps } from "../store/helpers/mapState";
import { connect } from "react-redux";

// type IProps = {
//   onSuccess?: (val: any) => void | undefined;
//   plan?: any;
//   setPlan?: any;
//   addNew?: any;
//   planInfo?: any;
//   title?: string;
//   allBranch?: boolean;
//   singleSelection?: boolean | undefined;
//   listCol?: number | undefined;
//   buttonName?: string | undefined;
// };
type DProps = {
  list: Array<any>;
  parent: (elem: any) => any;
  expandable: boolean;
  onSearch: (elem: any) => void;
  GetAllChildren: (structureId: string) => Promise<any>;
  onChange: any;
  branch: any;
};

type LProps = {
  list: Array<any>;
  parent: (elem: any) => any;
  expandable: boolean;
  GetAllChildren: (structureId: string) => Promise<any>;
};
type RProps = {
  structure: any;
  parent: (elem: any) => any;
  expandable: boolean;
  GetAllChildren: (structureId: string) => Promise<any>;
};

const DepartmentRow = (props: RProps) => {
  const { structure, GetAllChildren, parent, expandable } = props;

  const [children, setChildren] = useState<Array<any>>(structure.list ?? []);
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(true);

  return (
    <div>
      <div className={"d-flex justify-content-center align-items-center"}>
        {parent(structure) ? (
          <div style={{ width: 15, borderBottom: "1px dotted black" }}></div>
        ) : null}
        <div className={"flex-grow-1"}>
          <ListItemButton
            // selected={active === index}
            onClick={async () => {
              if (!children.length && expandable) {
                setLoading(true);
                let response = await GetAllChildren(structure.id);
                if (response) {
                  structure.list = response;
                  setChildren(response);
                }
                setLoading(false);
              } else {
                if (children.length) {
                  setShow(!show);
                }
                // GetUsersByStructure(structure.id);
              }
            }}
          >
            {expandable ? <Add /> : null}{" "}
            <div className={"ms-1"}>
              <ListItemText primary={structure.name} />
            </div>
          </ListItemButton>
        </div>
      </div>
      {children.length && show ? (
        <div className={"d-flex"}>
          <div style={{ borderLeft: "1px dotted black", marginLeft: 15 }}></div>
          <div className={"flex-grow-1"}>
            <DepartmentList
              expandable={expandable}
              list={structure.list}
              parent={() => structure}
              GetAllChildren={GetAllChildren}
            />
          </div>
        </div>
      ) : loading ? (
        <div className={"text-center"}>
          <CircularProgress size={12} />
        </div>
      ) : null}
    </div>
  );
};

const DepartmentList = (props: LProps) => {
  const { list, GetAllChildren, parent, expandable } = props;
  return (
    <List>
      {list?.map((struct: any) => (
        <DepartmentRow
          expandable={expandable}
          parent={parent}
          structure={struct}
          GetAllChildren={GetAllChildren}
        />
      ))}
    </List>
  );
};
const DepartmentCard = (props: DProps) => {
  const { list, GetAllChildren, expandable, onSearch, onChange, branch } =
    props;


  return (
    <Card variant={"outlined"}>
      <CardHeader
        title={
          <select
            className="form-control text-nowrap"
            onChange={(e: any) => {
              onChange(
                `/department/branch/${e.target.value}`,
                "department"
              );
            }}
          >
            <option>{"Select Branch"}</option>
            {branch?.map((value: any) => (
              <option value={value.id}>{value?.name}</option>
            ))}
          </select>
        }
      />
      <CardContent>
        <DepartmentList
          expandable={expandable}
          list={list}
          parent={() => null}
          GetAllChildren={GetAllChildren}
        />
      </CardContent>
    </Card>
  );
};
const Approver = (props: any) => {
  const {
    onSuccess,
    title,
    allBranch,
    singleSelection,
    listCol,
    buttonName,
    setSelect,
    loggedUser,
    onSelectUser,
    supervisor
  } = props;
  const [openDialogue, setOpenDialogue] = useState(false);
  const [employee, setEmployee] = useState<any>([]);
  const [branch, setBranch] = useState<any>([]);
  const [info, setInfo] = useState<any>();

  const [checked, setChecked] = useState<any>([]);
  const [searchStructure, setSearchStructure] = useState<any>([]);
  const [searchUsers, setSearchUsers] = useState<Array<any> | null | undefined>(
    null
  );

  let filterEmployees = searchUsers ?? info?.employee;
  let filterStructure = searchStructure?.length
    ? searchStructure
    : info?.department;
  useEffect(() => {
    laodInitialData().then();
  }, []);

  const laodInitialData = async () => {
    const branchList = await fetchDetails(
      allBranch ? "/branch/view" : "/branch/viewByEmployee"
    );
   if(branchList?.status){
    setBranch( Array.isArray(branchList?.data) ? branchList?.data : []);
   }
  };
  const onChange = async (url: string, type: string) => {
    const response = await fetchDetails(url);
    if (response.status) {
      if (type === "department") {
        setInfo({ ...info, department: response?.data });
      }
      if (type === "unit") {
        setInfo({
          ...info,
          unit: response?.data?.departments,
          employee: response?.data?.employees,
        });
      }
      if (type === "employee") {
        setInfo({ ...info, employee: response?.data });
      }
    }
  };
  const checkedUser = (user: any) => {
    let temp: any[] = [...checked];
    if (!temp.includes(user)) {
      temp.push(user);
      setChecked(temp);
    }
  };
  const unCheckUser = (user: any) => {
    let array = [...checked];
    let index = array.indexOf(user);
    if (index !== -1) {
      array.splice(index, 1);
      setChecked(array);
    }
  };
  const onCheck = (user: any) => {
    if(singleSelection){
      setChecked([user]);
    }else {
      if (!checked.includes(user)) {
        checkedUser(user);
      } else {
        unCheckUser(user);
      }
    }
  };
  const onCheckAll = (user: any[]) => {
    if (checked !== info?.employee) {
      setChecked(user);
    } else {
      setChecked([]);
    }
  };
  const handleOnkeyUp = (e: any) => {
    let value = e.currentTarget.value.toLowerCase();
    let newArray: Array<any> | null | undefined = info?.employee?.filter(
      (item: any) =>
        item?.firstName.toLowerCase().indexOf(value) !== -1 ||
        item?.lastName.toLowerCase().indexOf(value) !== -1
    );
    setSearchUsers(newArray);
  };
  const handleOnkeyUpStrucrure = (e: any) => {
    let value = e.currentTarget.value.toLowerCase();
    let newArray: any = info?.department?.filter(
      (item: any) => item.name.toLowerCase().indexOf(value) !== -1
    );
    setSearchStructure(newArray);
  };
  return (
    <Row className="mt-4">
      <Col>
        <Divider />
        <CardHeader
          className="fw-bold"
          subheader={<strong>{title ?? "Approver Section"}</strong>}
          action={
            <GeneralButton
              icon={<Add />}
              onClick={() => {
                setOpenDialogue(true);
              }}
              name={buttonName ?? "Select Approver"}
              variant="outlined"
            />
          }
        />
        <div className="row">
          {employee?.map((emp: any, index: any) => (
            <div className={`col-md-${listCol ?? 3}`}>
              <List key={index}>
                <ListItem alignItems="flex-start" className="col-md-3">
                  <ListItemAvatar>
                    <Avatar />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${emp?.firstName ?? ""} ${emp?.lastName ?? ""}`}
                    secondary={emp?.position?.name ?? ""}
                  />
                </ListItem>
              </List>
            </div>
          ))}
        </div>
      </Col>
      {openDialogue ? (
        <SmallDialog
          maxWidth="lg"
          title=""
          buttonTitle="Submit"
          needButton={true}
          open={openDialogue}
          setOpen={setOpenDialogue}
        >

          <Card>
            <CardHeader
            style={{fontFamily:'inter'}}
              title={title??"Select Approver"}
              titleTypographyProps={{ variant: "h6" }}
            />
            <form
              onSubmit={(e: any) => {
                e.preventDefault();
                onSelectUser(checked);
                setSelect(false);
              }}
            >
              <CardContent>
                <div className="row ">
                  <div className="col ">
                    <DepartmentCard
                      onSearch={handleOnkeyUpStrucrure}
                      onChange={onChange}
                      branch={branch}
                      expandable={true}
                      parent={() => null}
                      list={filterStructure}
                      GetAllChildren={async (id) => {
                        const response = await fetchDetails(
                          `/department/employee/${id}`
                        );
                        setInfo({
                          ...info,
                          employee: response?.data,
                        });
                        return response?.data?.departments;
                      }}
                    />
                  </div>
                  <div className="col">
                    <Card variant={"outlined"}>
                      <CardHeader
                      style={{fontFamily:'inter'}}
                        title={"Employees"}
                        titleTypographyProps={{ variant: "subtitle1" }}
                        action={
                          <input
                            placeholder="Search here.."
                            className="form-control form-control-sm"
                            type="text"
                            onKeyUp={(e) => handleOnkeyUp(e)}
                          />
                        }
                      />
                      <CardContent>
                        <List>
                          {!singleSelection && <>
                            <ListItem
                                onClick={() => {
                                  onCheckAll(info?.employee);
                                }}
                            >
                              <ListItemAvatar>
                                <Checkbox checked={checked === info?.employee}/>{" "}
                              </ListItemAvatar>
                              <ListItemText primary={`Check All`}/>
                            </ListItem>
                            <hr/>
                          </>}
                          {filterEmployees
                            ?.filter(
                              (user: any) => user.id !== loggedUser?.employeeId
                            )
                            ?.map((user: any, index: any) => (
                              <ListItem
                                key={index}
                                onClick={() => {
                                  onCheck(user);
                                }}
                              >
                                <ListItemAvatar>
                                  <Checkbox checked={checked?.includes(user)} />{" "}
                                </ListItemAvatar>
                                <ListItemText
                                  primary={`${user?.firstName} ${user?.lastName}`}
                                />
                              </ListItem>
                            ))}
                        </List>
                        {/* </ul> */}
                        {/* </div> */}
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </CardContent>
              <CardActions className={"float-end"}>
                <button
                  type={"button"}
                  onClick={() => {
                    setOpenDialogue(false);
                  }}
                  className={"btn btn-secondary me-1"}
                >
                  <i className={"bi bi-x-lg"}></i> Cancel
                </button>
                <button
                  type={"button"}
                  onClick={() => {
                    setOpenDialogue(false);
                    if(typeof onSelectUser === 'function'){
                      onSelectUser(checked);
                    }
                    setEmployee(singleSelection ? [checked[0]] : checked);
                    if (typeof onSuccess === "function") {
                      onSuccess(checked[0]);
                      // const reviewer = employee;
                      // reviewer.push(tempEmployee);
                    }
                  }}
                  className={"btn btn-primary"}
                >
                  <i className={"bi bi-send-fill"}></i> Add
                </button>
              </CardActions>
            </form>
          </Card>
        </SmallDialog>
      ) : null}
    </Row>
  );
};

// export default Approver;
export default connect(mapStateToProps)(Approver);
