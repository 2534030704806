import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import { CustomizedMenus } from "../../../components/customizeMenu";
import { DatatableComponent } from "../../../components/DatatableComponent";
import {
  Cancel,
  EventAvailableOutlined,
  PeopleAltOutlined,
  UpdateOutlined,
} from "@mui/icons-material";
import { Col, Row } from "react-bootstrap";
import { default as ReactSelect } from "react-select";
import { DashboardCard } from "../../../components/DashboardCard";
import { fetchDetails } from "../../../services/common";
import StatusComponent from "../../../components/StatusComponent";
import { mapStateToProps } from "../../../store/helpers/mapState";
import { mapDispatchToProps } from "../../../store/helpers/mapDispatch";
import { connect } from "react-redux";

const MissionListIncoming = (props: any) => {
  const { loggedUser } = props;
  const navigate = useNavigate();
  const [list, setList] = useState<Array<any>>([]);
  const [info, setInfo] = useState<any>({});
  const [dashboardData, setDashboardData] = useState<any>();
  const [department, setDepartment] = useState<any>([]);
  const hasAdminRole =
    loggedUser?.roles?.filter((item: any) => item.role?.name === "admin")
      ?.length > 0;

  const dataSummary = async () => {
    const resp = await fetchDetails(
      hasAdminRole ? `dashboard/mission-dashboard/` : `mission/all-dashboard/`
    );
    const departmentList = await fetchDetails("/department/view");
    if (departmentList.status) {
      setDepartment(departmentList?.data);
    }
    if (resp.status) {
      setDashboardData(resp.data);
    }
  };
  useEffect(() => {
    //fetch dataSummary
    dataSummary().then();
  }, [hasAdminRole]);
  //dashboard data
  const pending =
    dashboardData?.filter((items: any) => items?.status === "pending") ?? [];
  const approved =
    dashboardData?.filter((items: any) => items?.status === "approved") ?? [];
  const rejected =
    dashboardData?.filter((items: any) => items?.status === "rejected") ?? [];
  const clarification =
    dashboardData?.filter((items: any) => items?.status === "request action") ??
    [];

  return (
    <div>
      <Breadcrumb/>
      <Row className="mt-2">
        <Col md={3}>
          <DashboardCard
            content={{
              title: "Pending",
              number: pending[0]?.request ?? 0,
            }}
            className="justify-content-center"
            icon={
              <PeopleAltOutlined
                sx={{ color: "#054D6F", width: "25px", height: "25px" }}
              />
            }
          />
        </Col>
        <Col md={3}>
          <DashboardCard
            content={{
              title: "Request action",
              number: clarification[0]?.request ?? 0,
            }}
            icon={
              <UpdateOutlined
                sx={{ color: "#054D6F", width: "25px", height: "25px" }}
              />
            }
            className="justify-content-center"
          />
        </Col>
        <Col md={3}>
          <DashboardCard
            content={{
              title: "Approved",
              number: approved[0]?.request ?? 0,
            }}
            icon={
              <EventAvailableOutlined
                sx={{ color: "#054D6F", width: "25px", height: "25px" }}
              />
            }
            className="justify-content-center"
          />
        </Col>
        <Col md={3}>
          <DashboardCard
            content={{
              title: "Rejected",
              number: rejected[0]?.request ?? 0,
            }}
            icon={
              <Cancel
                sx={{ color: "#054D6F", width: "25px", height: "25px" }}
              />
            }
            className="justify-content-center"
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={3}>
          <label className="col-form-label text-capitalize">Status</label>
          <ReactSelect
            className={"text-nowrap"}
            options={[
              { value: "pending", label: "pending" },
              { value: "approved", label: "approved" },
              { value: "request action", label: "Request for action" },
              { value: "rejected", label: "rejected" },
              { value: "all", label: "All" },
            ]}
            onChange={(v) => {
              setInfo({ ...info, status: v?.value });
            }}
          />
        </Col>
        <Col md={3}>
          <label className="col-form-label text-capitalize">Department</label>
          <ReactSelect
            className={"text-nowrap"}
            options={department?.map(({ label, id, name }: any) => ({
              value: id,
              label: label ?? name,
            }))}
            onChange={(v: any) => {
              setInfo({ ...info, departmentFrom: v?.value });
            }}
          />
        </Col>
        <Col md={3}>
          <label className="col-form-label text-capitalize">Start Date</label>
          <input
            className="form-control"
            type="date"
            onChange={(e: any) => {
              setInfo({ ...info, startDate: e.target.value });
            }}
          />
        </Col>
        <Col md={3}>
          <label className="col-form-label text-capitalize">End Date</label>
          <input
            className="form-control"
            type="date"
            onChange={(e: any) => {
              setInfo({ ...info, endDate: e.target.value });
            }}
          />
        </Col>
      </Row>
      <DatatableComponent
        columns={[
          {
            label: "Employee Name",
            name: "missionaryEmployee.names",
            data: "missionaryEmployee.firstName",

          },
          {
            label: "First Destination",
            name: "destination",
          },
          {
            label: "Start Date",
            name: "startDate",
            options: {
              customBodyRender: (v: any) => moment(v).format("YYYY-MMM-DD"),
            },
          },
          {
            label: "End Date",
            name: "endDate",
            options: {
              customBodyRender: (v: any) => moment(v).format("YYYY-MMM-DD"),
            },
          },
          {
            label: "Status",
            name: "status",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (status: any) => (
                <StatusComponent status={status} badge={false} />
              ),
            },
          },
          {
            name: "id",
            label: "Action",
            options: {
              filter: true,
              sort: false,
              customBodyRenderLite: (id: any, index: any) => {
                return (
                  <CustomizedMenus
                    onView={() => {
                      navigate(`details/${list[index].id}`);
                    }}
                  />
                );
              },
            },
          },
        ]}
        url={
          hasAdminRole
            ? `/dashboard/mission/view/?info=${JSON.stringify(info)}`
            : `/mission/datatable/incoming?info=${JSON.stringify(
                info
              )}`
        }
        title={"Mission List"}
        processData={(data: any) => {
          setList(data.data);
          return data;
        }}
      />

    </div>
  );
};

// export default MissionListIncoming;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MissionListIncoming);
