import Breadcrumb from "../../../components/Breadcrumb";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { AddForm } from "../AddForm";
import { fetchDetails } from "../../../services/common";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/helpers/mapState";
import { mapDispatchToProps } from "../../../store/helpers/mapDispatch";
import FileUpload from "../../../components/FileUploader";

const CreateTransfer = (props: any) => {
  const { loggedUser } = props;

  const navigate = useNavigate();
  const [files, setFiles] = React.useState<any>([]);
  // const [department, setDepartment] = React.useState<any>([]);
  const [position, setPosition] = React.useState<any>([]);
  const [branch, setBranch] = React.useState<any>([]);
  const [branchListFrom, setBranchFromList] = React.useState<any>([]);
  const [supervisor, setSupervisor] = React.useState<any>();
  const [initialBranch, setInitialBranch] = React.useState<any>();
  const [branchFrom, setBranchFrom] = React.useState<any>();
  const [branchTo, setBranchTo] = React.useState<any>();
  const [info, setInfo] = React.useState<any>({});
  const { id }: any = useParams();

  const fetchInfoDetails = async (id: string) => {
    const resp = await fetchDetails(`transfer/view/${id}`);
    if (resp.status) {
      // setInfo(resp.data);
      setInfo({
        ...resp.data,
        firstName: resp.data?.Employee?.firstName,
        lastName: resp.data?.Employee?.lastName,
        departmentFrom: resp.data?.departmentFrom,
        positionFrom: resp.data?.positionFrom,
        branchFrom: resp.data?.branchFrom,
        requestFor: "self",
        transferee: resp.data?.transferee,
      });
      setBranchFrom(resp.data?.branchFrom)
      setBranchTo(resp.data?.branchTo)

    }
  };
  const [departmentListFrom, setDepartmentListFrom] = useState([]);
  const [departmentListTo, setDepartmentListTo] = useState([]);

  useEffect(() => {
    //laod transfer details to be edited or updated
    if (id !== "new") {
      fetchInfoDetails(id).then();
    }
  }, [id]);

  // useEffect(() => {
  //   laodInitialData();
  // }, []);
  useEffect(() => {
    if (loggedUser) {
      setBranchFromList(
        loggedUser?.Employee?.employeeBranches?.map((v: any) => ({
          label: v?.branch?.name,
          value: v?.branch?.id,
        }))
      );

      setInfo({
        ...info,
        firstName: loggedUser?.Employee?.firstName,
        lastName: loggedUser?.Employee?.lastName,
        positionFrom: loggedUser?.Employee?.positionId,
        transferee: loggedUser?.Employee?.id,
        requester: loggedUser?.id,
        departmentFrom:
          loggedUser?.Employee?.employeeBranches[0]?.department?.id,
        branchFrom:
          loggedUser?.Employee?.employeeBranches[0]?.department?.branchId,
      });
      setBranchFrom(loggedUser?.Employee?.employeeBranches[0]?.department?.branchId)
      setInitialBranch(loggedUser?.Employee?.employeeBranches[0]?.branch?.id);
    }
    laodInitialData();
    onChange(
      `/employee-branch/search?info=${JSON.stringify({
        branchId:
          loggedUser?.Employee?.employeeBranches[0]?.department?.branchId,
        employeeId: loggedUser?.employeeId,
      })}`
    );
  }, [loggedUser]);

  const laodInitialData = async () => {
    // const departmentList = await fetchDetails("/department/view");
    const positionList = await fetchDetails("/position/view");
    const branchList = await fetchDetails("/branch/view");

    // if (departmentList.status) setDepartment(departmentList?.data);
    if (positionList.status) setPosition(positionList?.data?.data?.map((v: any) => ({
      label: v?.name,
      value: v?.id,
    })));
    if (branchList.status)
      setBranch(
        branchList?.data?.map((v: any) => ({
          label: v?.name,
          value: v?.id,
        }))
      );
  };
  const onChange = async (url: string) => {
    const response = await fetchDetails(url);
    if (response.status) {
      setDepartmentListFrom(
        response?.data?.map((v: any) => ({
          label: v?.department?.name,
          value: v?.department?.id,
        }))
      );
      setInfo({
        ...info,
        departmentFrom: response?.data[0]?.departmentId,
      });
    }
  };

  const onChangeTo = async (url: string) => {
    const response = await fetchDetails(url);
    if (response.status) {
      setDepartmentListTo(
        response?.data?.map((v: any) => ({
          label: v?.name,
          value: v?.id,
        }))
      );
    }
  };
  // useEffect(() => {
  //   if (initialBranch) {
  //     onChange(
  //       `/employee-branch/search?info=${JSON.stringify({
  //         branchId: initialBranch,
  //         employeeId: info?.transferee,
  //       })}`
  //     )
  //   }
  // }, [initialBranch]);
  useEffect(() => {
      if(branchFrom&&loggedUser?.employeeId){
        onChange(
          `/employee-branch/search?info=${JSON.stringify({
            branchId: branchFrom,
            employeeId: loggedUser?.employeeId,
          })}`
        );
      }
    
  }, [branchFrom]);
  useEffect(() => {
    if(branchTo){
      onChangeTo(
        `/department/branch/${branchTo}`
      );
    }
  
}, [branchTo]);
 
  return (
    <div>
      <Breadcrumb activeLabel={id !== "new" ? "Edit" : "Create"} />
      <Row className={"justify-content-center"}>
        <Col md={12}>
          <AddForm
            title={id !== "new" ? "Edit Mutation Request11" : "Mutation Request"}
            inline={true}
            col={6}
            mainFields={[
              {
                title: "",
                fields: [
                  {
                    label: "approver",
                    name: "approver",
                    type: "text",
                    hidden: true,
                    required: true,
                    disabled: true,
                    value: supervisor?.id,
                    col: 6,
                  },
                  {
                    label: "status",
                    name: "status",
                    hidden: true,
                    value: "pending",
                  },
                  {
                    label: "transferee",
                    name: "transferee",
                    hidden: true,
                    col: 4,
                    value: info?.transferee,
                  },
                  {
                    label: "Requestor",
                    name: "requester",
                    hidden: true,
                    col: 4,
                    value: info?.requester,
                  },
                  {
                    label: "First Name",
                    name: "firstName",
                    disabled: true,
                    col: 6,
                    value: info?.firstName,
                  },
                  {
                    label: "Last Name",
                    name: "lastName",
                    disabled: true,
                    col: 6,
                    value: info?.lastName,
                  },
                ],
              },
              {
                title: "Transferred From",
                fields: [
                  {
                    label: "Branch",
                    name: "branchFrom",
                    type: "select",
                    required: true,
                    values: branchListFrom,
                    col: 6,
                    value: branchFrom,
                    setValue:setBranchFrom,
                  },
                  {
                    label: "Department",
                    name: "departmentFrom",
                    type: "select",
                    required: true,
                    values: departmentListFrom,
                    col: 6,
                    value: info?.departmentFrom,
                  },
                  {
                    label: "Position",
                    name: "positionFrom",
                    type: "select",
                    hidden: true,
                    values: position,
                    col: 4,
                    value: info?.positionFrom,
                  },
                ],
              },
              {
                title: "Transferred To",
                fields: [
                  {
                    label: "Branch",
                    name: "branchTo",
                    type: "select",
                    required: true,
                    values: branch,
                    col: 6,
                    value: branchTo,
                    setValue:setBranchTo,
                  },
                  {
                    label: "Department",
                    name: "departmentTo",
                    type: "select",
                    required: true,
                    values: departmentListTo,
                    col: 6,
                    value: info?.departmentTo,
                  },
                  // {
                  //   label: "Position",
                  //   name: "positionTo",
                  //   type: "select",
                  //   required: true,
                  //   values: position,
                  //   col: 4,
                  //   value: info?.positionTo,
                  // },
                ],
              },
              {
                title: "Reason & Support document",
                fields: [
                  {
                    label: "Reason of Mutation",
                    name: "reason",
                    type: "textarea",
                    required: true,
                    col: 12,
                    value: info?.reason,
                  },
                  {
                    label: "Support document",
                    name: "supportingDocument",
                    type: "file",
                    multiple: true,
                    col: 12,
                    value: files,
                    render: () => {
                      return <FileUpload setFiles={setFiles} files={files} />;
                    },
                  },
                ],
              },
            ]}
            // appendForm={id === "new" ?<Approver onSuccess={(val)=>setSupervisor(val)}/>:<div></div>}
            url={id !== "new" ? `transfer/update/${id}` : "/transfer/create"}
            method={id !== "new" ? "PUT" : "POST"}
            onSuccess={() => {
              // navigate("/console/employee/transfers")
              navigate(-1);
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

// export default CreateTransfer;
export default connect(mapStateToProps, mapDispatchToProps)(CreateTransfer);
