import { AppThunk } from "../configureStore";
import { dispatchHandler } from "../helpers/dispatchHandler";
import {ERRORS, GET_CDE_ACTIVE_INFO, GET_USER, SET_SOCKET} from "./type";
import http from "../../services/http-common";
import authHeader from "../../services/auth-header";

const header: any = {
    headers: authHeader(),
  };
export const getLoggedUser =
(id:string): AppThunk =>
async (dispatch: any) => {
  try {
    dispatchHandler({ type: ERRORS, data: null, dispatch });
    const URL = `/user/view/${id}`;
    const {data} =await http.get(URL, { headers: authHeader() })
    if (data) {
      dispatchHandler({
        type: GET_USER,
        data: {...data.data,
            currentMonth:data.currentMonth,
            hasPendingPaymentContribution:data.hasPendingPaymentContribution,
        },
        dispatch,
      });
    }
    return data
  } catch (error: any) {
    if (error?.response?.status === 409||error?.response?.status === 403) {
      return error?.response?.data?.message;
    } else {
      return error?.response?.data.message??'Network Error'
    }
  }
};
export const getActiveCdeInfo =
(): AppThunk =>
async (dispatch: any) => {
  try {
    dispatchHandler({ type: ERRORS, data: null, dispatch });
    const URL = `cde/active/summary`;
    const {data} =await http.get(URL, { headers: authHeader() })
    if (data) {
      dispatchHandler({
        type: GET_CDE_ACTIVE_INFO,
        data: data,
        dispatch,
      });
    }
    return data
  } catch (error: any) {
    if (error?.response?.status === 409||error?.response?.status === 403) {
      return error?.response?.data?.message;
    } else {
      return error?.response?.data.message??'Network Error'
    }
  }
};
export const getBasicActiveCdeInfo =
(): AppThunk =>
async (dispatch: any) => {
  try {
    dispatchHandler({ type: ERRORS, data: null, dispatch });
    const URL = `caise/active/summary`;
    const {data} =await http.get(URL, { headers: authHeader() })
    if (data) {
      dispatchHandler({
        type: GET_CDE_ACTIVE_INFO,
        data: data,
        dispatch,
      });
    }
    return data
  } catch (error: any) {
    if (error?.response?.status === 409||error?.response?.status === 403) {
      return error?.response?.data?.message;
    } else {
      return error?.response?.data.message??'Network Error'
    }
  }
};
export const setSocket =
  (data: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatchHandler({
        type: SET_SOCKET,
        data,
        dispatch,
      });
    } catch (error: any) {
      dispatchHandler({
        type: ERRORS,
        data: error.response.data.message || "Network Error",
        dispatch,
      });
      return null;
    }
  };

  export const downloadExcelFile =
  (url:string): AppThunk =>
  async (dispatch: any) => {
    try {
            dispatchHandler({ type: ERRORS, data: null, dispatch });

            const { data } = await http.post(url,{}, {...header, responseType: "blob"});

            if (data) {
              const file = new Blob([data], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
                });

              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);
            }
            return data;
          } catch (error: any) {

            return null;
          }
        };
