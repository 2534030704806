import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { DatatableComponent } from "../../../components/DatatableComponent";
import {
  Add,
  Cancel,
  CheckBox,
  CreditCard,
  Help,
  Info,
} from "@mui/icons-material";
import moment from "moment/moment";
import StatusComponent from "../../../components/StatusComponent";
import { CustomizedMenus } from "../../../components/customizeMenu";
import { useState } from "react";
import DeleteDialog from "../../../components/DeleteDialog";
import GeneralButton from "../../../components/Button";
import {
  Alert,
  AlertTitle,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import TabHeadersComponent from "../../../components/UI/Tabs";
import { TabPanel } from "../../employee/leave/List";
import { Col, Row } from "react-bootstrap";
import SmallDialog from "../../../components/SmallModel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getError, submitGeneralForm } from "../../../services/common";
import { mapStateToProps } from "../../../store/helpers/mapState";
import { mapDispatchToProps } from "../../../store/helpers/mapDispatch";
import { connect } from "react-redux";

const LoanList = (props: any) => {
  const { loggedUser } = props;

  const navigate = useNavigate();
  const [list, setList] = useState<Array<any>>([]);
  const [change, setChange] = useState(0);
  const [deleteOpen, setDeleteOpen] = useState(0);
  const [value, setValue] = useState(0);
  const [deleteActionUrl, setDeleteActionUrl] = useState<
    string | undefined | null
  >();
  const [otherInfo, setOtherLoanInfo] = useState<any>();
  const [newData, setData] = useState<any>();
  const [error, setError] = useState<any>();
  const [confirm, setConfirm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDialogue, setOpenDialogue] = useState<boolean>(false);
  const pendingLoan = list?.find((loan: any) => loan?.status === "pending");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const labels = [
    { label: "My Loans", icon: <CreditCard /> },
    { label: "Loans I Insured", icon: <Help /> },
  ];
  const onSubmit = async (data?: any) => {
    setLoading(true);
    setConfirm(false);

    const url = `/loanInsurance/update/${data?.id}`;
    const resp = await submitGeneralForm(url, data, null, "PUT");
    if (resp.status) {
      setData({});
      setError(null);
      setOpenDialogue(false);
      setChange(change + 1);
    } else {
      setError(getError(resp));
    }
    setLoading(false);
  };
  const isActive = loggedUser?.Employee?.CaisseMember?.status === "active";

  return (
    <div>
      <Breadcrumb
        endElement={
          value === 0 && isActive ? (
            <GeneralButton
              variant="outlined"
              size="medium"
              name={"Apply For Loan"}
              icon={<Add />}
              onClick={async () => {
                navigate("create");
              }}
            />
          ) : null
        }
      />{" "}
      <Alert
        severity="info"
        style={{ width: "100%" }}
        hidden={!pendingLoan || otherInfo?.totalPending === 0}
      >
        <AlertTitle>
          <strong>Important Notice</strong>
        </AlertTitle>
        <span className="mt-3">
          Please understand that the approval of your loan is contingent upon
          the review and processing of applications submitted prior to yours.
          While we are committed to assisting you, we want to ensure a fair and
          thorough evaluation process for all applicants.
        </span>
        <div className="d-flex  mt-3">
          <strong className="me-3">
            Pending request: {otherInfo?.totalPending}
          </strong>
          <strong className="ms-3">
            Amount requested: {otherInfo?.totalAmount?.toLocaleString()} RWF
          </strong>
        </div>
      </Alert>
      <Row>
        <Col md={12}>
          <TabHeadersComponent
            handleChange={handleChange}
            value={value}
            labels={labels}
          />
          <TabPanel value={value} p={0} index={0}>
            <DatatableComponent
              printable={true}
              columns={[
                {
                  label: "Type of Loan",
                  name: "loanType",
                  options: {
                    customBodyRender: (v: any) => (
                      <span className="text-capitalize">{v}</span>
                    ),
                  },
                },
                {
                  label: "Rate(%)",
                  name: "interestRate",
                  options: {
                    customBodyRender: (v: any) => v?.toLocaleString(),
                  },
                },
                {
                  label: "Take(RWF)",
                  name: "amountReceived",
                  options: {
                    customBodyRender: (v: any) => v?.toLocaleString(),
                  },
                },

                {
                  label: "Interest(RWF)",
                  name: "interestAmount",
                  options: {
                    customBodyRender: (v: any) => v?.toLocaleString(),
                  },
                },
                {
                  label: "To be Paid(RWF)",
                  name: "amountToPay",
                  options: {
                    customBodyRender: (v: any) => v?.toLocaleString(),
                  },
                },
                // {
                //   label: "Monthly Payment(RWF)",
                //   name: "monthlyAmount",
                //   options: {
                //     customBodyRender: (v: any) => v?.toLocaleString(),
                //   },
                // },
                // {
                //     label: "Payment Date",
                //     name: "endDate",
                //     options: {
                //         customBodyRender:(v:any)=>moment(v).format("YYY-MMM-DD")
                //     }
                // },

                {
                  label: "Request Date",
                  name: "createdAt",
                  options: {
                    customBodyRender: (v: any) =>
                      moment(v).format("YYYY-MMM-DD"),
                  },
                },
                {
                  label: "Status",
                  name: "status",
                  options: {
                    customBodyRender: (v: any) => (
                      <StatusComponent status={v} badge={false} />
                    ),
                  },
                },
                {
                  name: "id",
                  label: "Action",
                  options: {
                    filter: true,
                    sort: false,
                    customBodyRenderLite: (id: any, index: any) => {
                      const canUpdate =
                        list[index]?.status === "request action" ||
                        list[index]?.status === "pending" ||
                        list[index]?.status === "cancel";
                      const canDelete =
                        list[index]?.status === "pending" ||
                        list[index]?.status === "cancel";
                      return (
                        <CustomizedMenus
                          onView={() => {
                            navigate(`details/${list[index].id}`);
                          }}
                          onEdit={() => {
                            navigate(`update/${list[index].id}`);
                          }}
                          onDelete={() => {
                            setDeleteOpen(1);
                            setDeleteActionUrl(
                              `/loan/delete/${list[index].id}`
                            );
                          }}
                          needEdit={canUpdate}
                          needDelete={canDelete}
                        />
                      );
                    },
                  },
                },
              ]}
              url={`loan/datatable?change=${change}`}
              // actions={(id) => [
              //   <button title="update" className={"border-0 bg-transparent"}>
              //     <Update onClick={() => navigate(`update/${id}`)} fontSize="small" />
              //   </button>,
              // ]}
              title={"Loans List"}
              processData={(data: any) => {
                setList(data.data);
                setOtherLoanInfo({
                  totalPending: data?.totalPending,
                  totalAmount: data?.totalAmount,
                });
                return data;
              }}
              // deleteUrl={(id) => `loan/delete/${id}`}
            />
          </TabPanel>
          <TabPanel value={value} p={0} index={1}>
            <DatatableComponent
              columns={[
                {
                  label: "Requester",
                  name: "CaisseMember?.employee?.names",
                  options: {
                    customBodyRenderLite: (id: any, index: any) => {
                      return (
                        <span className="text-capitalize">
                          {list[index]?.Loan?.CaisseMember?.employee?.names}
                        </span>
                      );
                    },
                  },
                },
                {
                  label: "Loan Type",
                  name: "loanId",
                  options: {
                    customBodyRenderLite: (id: any, index: any) => {
                      return (
                        <span className="text-capitalize">
                          {list[index]?.Loan?.LoanType?.label}
                        </span>
                      );
                    },
                  },
                },
                {
                  label: "Comment",
                  name: "comment",
                },
                {
                  label: "Status",
                  name: "status",
                  options: {
                    customBodyRender: (v: any) => (
                      <StatusComponent
                        status={v ? v : "pending"}
                        badge={false}
                      />
                    ),
                  },
                },
                {
                  name: "id",
                  label: "Action",
                  options: {
                    filter: true,
                    sort: false,
                    customBodyRenderLite: (id: any, index: any) => {
                      return (
                        <>
                          <Tooltip title={"Loan Detail"}>
                            <Info
                              role="button"
                              color={"info"}
                              className="me-1"
                              onClick={() => {
                                navigate(`details/${list[index]?.loanId}`);
                              }}
                            />
                          </Tooltip>
                          {list[index]?.status === "pending" ? (
                            <>
                              <Tooltip title={"Deny"}>
                                <Cancel
                                  role="button"
                                  color={"error"}
                                  className="me-1"
                                  onClick={() => {
                                    setOpenDialogue(true);
                                    setData({
                                      id: list[index]?.id,
                                      status: "denied",
                                    });
                                  }}
                                />
                              </Tooltip>
                              <Tooltip title={"Approve"}>
                                <CheckBox
                                  color={"success"}
                                  role="button"
                                  onClick={() => {
                                    setOpenDialogue(true);
                                    setData({
                                      id: list[index]?.id,
                                      status: "approved",
                                    });
                                  }}
                                />
                              </Tooltip>
                            </>
                          ) : null}
                        </>
                      );
                    },
                  },
                },
              ]}
              url={`loanInsurance/dataTable-Insurance/?change=${change}`}
              // actions={(id) => [
              //   <button title="update" className={"border-0 bg-transparent"}>
              //     <Update onClick={() => navigate(`update/${id}`)} fontSize="small" />
              //   </button>,
              // ]}
              title={"My Insured Loans"}
              processData={(data: any) => {
                setList(data.data);
                return data;
              }}
              // deleteUrl={(id) => `loan/delete/${id}`}
            />
          </TabPanel>
        </Col>
      </Row>
      {deleteActionUrl ? (
        <DeleteDialog
          open={deleteOpen}
          onSuccess={() => {
            setChange(change + 1);
          }}
          setOpen={setDeleteOpen}
          url={deleteActionUrl!}
        />
      ) : null}
      {/* ===========================================approve or deny loan insurance request========================= */}
      {openDialogue ? (
        <SmallDialog
          maxWidth="md"
          title={
            newData?.status === "approved"
              ? "Approve Insurance"
              : "Deny Insurance"
          }
          buttonTitle="Submit"
          needButton={true}
          open={openDialogue}
          setOpen={setOpenDialogue}
        >
          {error ? (
            <Alert
              style={{ width: "100%" }}
              className={"mb-3"}
              severity={"error"}
              onClose={() => setError(null)}
            >
              {error}
            </Alert>
          ) : null}
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              setConfirm(true);
            }}
          >
            <div>
              <label className="col-form-label text-capitalize">
                {newData?.status === "denied" ? "why?" : "Comment"}{" "}
                <span className={"text-danger"}>*</span>
              </label>
              <textarea
                placeholder="Type here..."
                className="form-control form-control mb-3"
                required={true}
                value={newData?.comment}
                rows={5}
                onChange={(e: any) => {
                  setData({
                    ...newData,
                    comment: e.currentTarget.value,
                  });
                }}
              />
            </div>
            <div className={"float-end"}>
              <button
                type={"button"}
                onClick={() => {
                  setOpenDialogue(false);
                }}
                className={"btn btn-secondary me-1"}
              >
                <i className={"bi bi-x-lg"}></i> Cancel
              </button>
              {loading ? (
                <CircularProgress />
              ) : (
                <button type={"submit"} className={"btn btn-primary"}>
                  <i className={"bi bi-send-fill"}></i> Submit
                </button>
              )}
            </div>
          </form>
        </SmallDialog>
      ) : null}
      {/* =====================================end====================================================== */}
      {openDialogue ? (
        <SmallDialog
          open={confirm}
          setOpen={setConfirm}
          onSubmit={() => {
            onSubmit(newData);
          }}
        >
          <div className="d-flex ">
            <CheckCircleIcon color="success" sx={{ fontSize: 50 }} />
            <div className="mt-4">{"Are you sure you want to submit?"}</div>
          </div>
        </SmallDialog>
      ) : null}
    </div>
  );
};

// export default LoanList;
export default connect(mapStateToProps, mapDispatchToProps)(LoanList);
