import { Helmet } from 'react-helmet';
import { Outlet } from "react-router-dom";
import React from "react";
import BlueNavBar from "../layouts/BlueNavBar";
import useStyles from './style';

function EmployeeLayout() {
  const title = 'Employee';
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {/* <BlueNavBar baseUrl={'/console/employee/'} 
      items={[
        {
          label: "All Employees",
          href: "all-employees",
          icon: "bi bi-people-fill",
          module: 'settings-user'
        },
        // {
        //   label: "Promotions",
        //   href: "promotions/year",
        //   icon: "bi bi-bar-chart-fill",
        //   module: 'promotion'
        // },
        {
          label: "Mutation",
          href: "",
          icon: "bi bi-sign-turn-right-fill",
          module: 'mutation'
        },
        {
          label: "Leave",
          href: "leaves",
          icon: "bi bi-house-heart-fill",
          module: 'leave'
        },
        {
          label: "Mission",
          href: "missions",
          icon: "bi bi-briefcase-fill",
          module: 'mission'
        },
        {
          label: "Self Service",
          href: "requests",
          icon: "bi bi-send-plus-fill",
          module: 'service-request'
        },
        {
          label: "Attendance",
          href: "attendance",
          icon: "bi bi-check-circle-fill",
          module: 'view-attendance'
        },
      ]} /> */}
       <div className="container-fluid">
      <div className="row">
          <div className='col-md-12'>
          <Outlet />
          </div>
          </div>
      </div>
    </>
  );
}

export default EmployeeLayout;
