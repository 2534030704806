import Breadcrumb from "../../../components/Breadcrumb";
import { DatatableComponent } from "../../../components/DatatableComponent";
import moment from "moment";
import { useEffect, useState } from "react";
import { CustomizedMenus } from "../../../components/customizeMenu";
import { default as ReactSelect } from "react-select";
import FileUpload from "../../../components/FileUploader";
import { AddForm } from "../../employee/AddForm";
import {
  fetchDetails,
  getFileMime,
  validateFileNotNull,
} from "../../../services/common";
import { Button, Col, Row } from "react-bootstrap";
import { Add, Visibility, VisibilityOff } from "@mui/icons-material";
import SmallDialog from "../../../components/SmallModel";
import OpenDocuments from "../../../components/openDocument";
import { Collapse, Typography } from "@mui/material";
import DeleteDialog from "../../../components/DeleteDialog";
import GeneralButton from "../../../components/Button";

const InvestmentList = () => {
  const [list, setList] = useState<Array<any>>([]);
  const [change, setChange] = useState(0);
  const [open, setOpen] = useState(0);
  const [document, setDocument] = useState<any>([]);
  const [fiscalYearList, setFiscalYearList] = useState<Array<any>>([]);
  const [fiscalYear, setFiscalYear] = useState<any>();
  const [info, setInfo] = useState<any>();
  const [account, setAccount] = useState<any>();
  const [viewDocument, setViewDocument] = useState<any>(false);
  const [totalAmount, setTotalInvestment] = useState<any>(null);
  const [deleteOpen, setDeleteOpen] = useState(0);
  const [deleteActionUrl, setDeleteActionUrl] = useState<
    string | undefined | null
  >();

  const getFiscalYear = async () => {
    const fiscalYear = await fetchDetails("/fiscal/view");

    if (fiscalYear.status) {
      const currentFiscalYear = fiscalYear?.data?.find(
        (year: any) => year.status === "open"
      );
      setFiscalYearList(fiscalYear?.data);
      setFiscalYear(currentFiscalYear?.id);
    }
  };
  useEffect(() => {
    //fetch all FiscalYear
    getFiscalYear().then();
  }, []);
  return (
    <div>
      <Breadcrumb
        endElement={
          <GeneralButton
            variant="outlined"
            size="medium"
            name={"Record New"}
            icon={<Add />}
            onClick={() => {
              setOpen(1);
              setInfo({});
            }}
          />
        }
      />
      <div className="mb-3 align-items-end d-flex">
        <div className={"flex-grow-1"}>
          <label className="col-form-label text-capitalize">Fiscal Year</label>
          <ReactSelect
            className="text-nowrap"
            options={fiscalYearList?.map(({ label, id, name }: any) => ({
              value: id,
              label: label ?? name,
            }))}
            onChange={(v: any) => {
              setFiscalYear(v?.value);
            }}
          />
        </div>
      </div>
      <DatatableComponent
        columns={[
          {
            label: "Date",
            name: "createdAt",
            options: {
              customBodyRenderLite: (id: any, index: any) => (
                <span>{moment(list[index]?.createdAt).format("L")}</span>
              ),
            },
          },
          {
            label: "Title",
            name: "title",
            options: {
              customBodyRenderLite: (id: any, index: any) => {
                return <span>{list[index]?.title}</span>;
              },
            },
          },
          {
            label: "Amount(RWF)",
            name: "amount",
            options: {
              customBodyRenderLite: (id: any, index: any) => (
                <span>{list[index]?.amount?.toLocaleString()}</span>
              ),
            },
          },
          {
            name: "id",
            label: "Action",
            options: {
              customBodyRenderLite: (id: any, index: any) => {
                return (
                  <CustomizedMenus
                    onView={() => {
                      setInfo(list[index]);
                      setViewDocument(true);
                    }}
                    onEdit={() => {
                      setOpen(1);
                      setInfo(list[index]);
                    }}
                    onDelete={() => {
                      setDeleteOpen(1);
                      setDeleteActionUrl(`cde/investment/${list[index].id}`);
                    }}
                    needEdit={true}
                    needDelete={true}
                  />
                );
              },
            },
          },
        ]}
        url={`cde/datatable-investment/?change=${change}&&fiscalYear=${fiscalYear}`}
        title={
          <div className="mb-4 row">
            <Typography 
            style={{fontFamily:'Inter'}}
              className="fw-bolder text-capitalize col-md-4 "
              variant="h6"
            >
              {"CDE Investments:"}
            </Typography>
            <span className="fw-bolder text-capitalize  col-md-4">
              {"Total Amount:"}
              <strong className="text-success ms-1">
                {totalAmount ? `${totalAmount?.toLocaleString()} RWF` : 0}
              </strong>
            </span>
            <span className="fw-bolder text-capitalize  col-md-4">
              {"Remaining:"}
              <strong className="text-success ms-1">
                {account?.investment
                  ? `${account?.investment?.toLocaleString()} RWF`
                  : 0}
              </strong>
            </span>
          </div>
        }
        processData={(data: any) => {
          setList(data?.data);
          setAccount(data?.accountStat);
          setTotalInvestment(data?.totalAmount);
          return data;
        }}
      />
      {/* Add new expenses form */}

      <AddForm
        title={info?.id ? "Update" : "Record new investment"}
        maxWidth="lg"
        mainFields={[
          {
            title: "",
            fields: [
              {
                label: "Title",
                name: "title",
                col: 12,
                required: true,
                value: info?.title,
              },
              {
                label: "Amount(RWF)",
                name: "amount",
                col: 12,
                type: "number",
                required: true,
                value: info?.amount,
                disabled: info?.id,
                max: info?.id ? null : account?.investment,
              },

              {
                label: "Description",
                name: "description",
                type: "editor",
                col: 12,
                required: true,
                value: info?.description,
              },
              {
                label: "Supporting Document",
                name: "supportingDocument",
                type: "file",
                multiple: true,
                col: 12,
                value: document,
                validate: validateFileNotNull,
                render: () => {
                  return (
                    <FileUpload
                      setFiles={setDocument}
                      files={document}
                      title="Upload"
                    />
                  );
                },
              },
            ],
          },
        ]}
        url={
          info?.id
            ? `cde/record-investment?id=${info?.id}`
            : "cde/record-investment/"
        }
        method={"POST"}
        onSuccess={() => {
          setChange(change + 1);
          setOpen(0);
        }}
        open={open}
        setOpen={() => {
          setOpen(0);
          setInfo({});
        }}
      />
      {/* view details model */}
      <SmallDialog
        maxWidth="lg"
        title="Investment Details"
        buttonTitle="Close"
        needButton={true}
        open={viewDocument}
        setOpen={setViewDocument}
        onSubmit={() => {
          setViewDocument(false);
        }}
      >
        <Row className={"mb-3"}>
          <Col md={4}>
            <div className={"fw-bold"}>Date</div>
            <div>{moment(info?.createdAt).format("L") ?? "---"}</div>
          </Col>
          <Col md={4}>
            <div className={"fw-bold"}>Title</div>
            <div>{`${info?.title?.toLocaleString()} RWF` ?? "---"}</div>
          </Col>
          <Col md={4}>
            <div className={"fw-bold"}>Amount</div>
            <div>{`${info?.amount?.toLocaleString()} RWF` ?? "---"}</div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className={"fw-bold mb-2"}>Description</div>
            <div dangerouslySetInnerHTML={{ __html: info?.description }} />
          </Col>
        </Row>
        {/* {viewDocument ? ( */}
        <div className={"row justify-content-center"}>
          <div className={"col-md-7"}>
            {info?.supportDocument?.map((v: string) => (
              <OpenDocuments
                downloadable={true}
                card={true}
                document={v}
                mimeType={getFileMime(v)}
              />
            ))}
          </div>
        </div>
        {/* // ) : null} */}
      </SmallDialog>
      {deleteActionUrl ? (
        <DeleteDialog
          open={deleteOpen}
          onSuccess={() => {
            setChange(change + 1);
            setDeleteOpen(0);
          }}
          setOpen={setDeleteOpen}
          url={deleteActionUrl!}
        />
      ) : null}
    </div>
  );
};

export default InvestmentList;
