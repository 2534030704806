// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardHeader {
  font-family: "Anton";
  font-size: x-large;
}
.cardContent {
  font-family: "Anton";
}
`, "",{"version":3,"sources":["webpack://./src/pages/saving/settings/setting.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,kBAAkB;AACpB;AACA;EACE,oBAAoB;AACtB","sourcesContent":[".cardHeader {\n  font-family: \"Anton\";\n  font-size: x-large;\n}\n.cardContent {\n  font-family: \"Anton\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
