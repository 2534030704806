import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import React, { useEffect, useState } from "react";
import BlueNavBar from "../layouts/BlueNavBar";
import { connect } from "react-redux";
import { mapStateToProps } from "../store/helpers/mapState";
import { mapDispatchToProps } from "../store/helpers/mapDispatch";
import PageLoading from "../components/PageLoading";
import AcceptCaiseTermConditions from "../components/AcceptCaiseTermConditions";
import useAuth from "../hooks/useAuth";
import { Col, Row } from "react-bootstrap";
import useStyles from "./style";

function SavingLayout(props: any) {
  const { loggedUser,GetBasicActiveCdeInfo,cdeInfo } = props;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(loggedUser?.Employee === undefined);
  }, [loggedUser]);
  useEffect(()=>{
    if(!cdeInfo){
      GetBasicActiveCdeInfo();
    }
  },[cdeInfo,GetBasicActiveCdeInfo]);
  const title = "Caisse d'entraide";
  const status = loggedUser?.Employee?.caisseEntradeStatus;
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {loading ? (
        <PageLoading />
      ) : status === "exited" ? (
        <div>
          <Row className={"justify-content-center"}>
            <Col
              md={6}
              className="p-5 bg-white mt-4 rounded-4 text-center shadow"
            >
              <i
                className="bi bi-escape text-danger"
                style={{ fontSize: "120px" }}
              ></i>
              <div style={{ fontSize: "22px" }}>
                You have exited caisse d'entraide
              </div>
            </Col>
          </Row>
        </div>
      ) : status === "active" ? (
        <>
          {/* <BlueNavBar
            baseUrl={"/console/saving/"}
            items={[
              {
                label: "Member Profile",
                href: "",
                icon: "bi bi-settings",
              },
              {
                label: "Contributions",
                href: "contributions",
                icon: "bi bi-wallet2",
              },
              {
                label: "Increment/Decrement",
                href: "increment",
                icon: "bi bi-settings",
              },
              {
                label: "Loans",
                href: "loans",
                icon: "bi bi-bank",
              },
              {
                label: "Social Funds",
                href: "funds",
                icon: "bi bi-cash",
              },
              {
                label:"Exit CDE",
                href:"exit/request",
                icon:"bi bi-escape"
            },
            ]}
          /> */}
      <div>
            <Outlet />
          </div>
        </>
      ) : (
        <>
          {/* <BlueNavBar
            baseUrl={"/console/saving/"}
            items={[
              {
                label: "My Application",
                href: "",
                icon: "bi bi-files",
              },
            ]}
          /> */}
    
          <div className="container-fluid">
      <div className="row">
          <div className='col-md-12'>
          <AcceptCaiseTermConditions />
          </div>
          </div>
      </div>
          {/* <AcceptCaiseTermConditions/> */}
        </>
      )}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SavingLayout);
