import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { DatatableComponent } from "../../../components/DatatableComponent";
import { BorderColorOutlined } from "@mui/icons-material";
import moment from "moment";
import { Tooltip } from "@mui/material";

const UsersList = () => {
  const navigate = useNavigate();
  return (
    <div>
      {/* <Breadcrumb addButton={{label:"Add User",onClick:()=>navigate('create')}}/> */}
      <Breadcrumb />

      <DatatableComponent
        columns={[
          {
            label: "Username",
            name: "username",
          },
          {
            label: "Email Address",
            name: "email",
          },
          {
            label: "Role",
            name: "Role.name",
          },
          {
            label: "Status",
            name: "status",
          },
          {
            label: "Date",
            name: "createdAt",
            options: {
              customBodyRender: (v: any) =>
                moment(v).format("YYYY-MMM-DD HH:mm"),
            },
          },
        ]}
        url={"user/datatable"}
        actions={(id) => [
        //   <IconButton onClick={() => navigate(`update/${id}`)}>
        //     <Update />
        //   </IconButton>,
        <Tooltip title="update" >
        <BorderColorOutlined onClick={() => navigate(`update/${id}`)} fontSize="small" />
      </Tooltip>,
        ]}
        title={"Users List"}
        deleteUrl={(id) => `user/delete/${id}`}
      />
    </div>
  );
};

export default UsersList;
