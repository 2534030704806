import React, { JSX, useEffect, useState } from "react";
import moment from "moment";
import ReportEngineComponent from "../../../components/ReportEngineComponent";
import { fetchDetails } from "../../../services/common";

function ExpenseReport(): JSX.Element {
  const [fiscalYears, setFiscalYears] = useState([]);
  const [categories, setCategories] = useState([]);
  const [value, setValue] = useState<any>({});

  const loadInitialData = async () => {
    const categoryList = await fetchDetails("cde/expense-categories");
    const fiscalYears = await fetchDetails(`fiscal/view`);
    if (fiscalYears.status) {
      setFiscalYears(
        fiscalYears.data.map(({ name, id }: any) => ({
          label: name,
          value: id,
        }))
      );
    }
    if (categoryList.status) {
      setCategories(
        categoryList.data.map(({ name, id }: any) => ({
          label: name,
          value: id,
        }))
      );
    }
    // if (employeeList.status) setRequesters(employeeList?.data);
  };

  useEffect(() => {
    loadInitialData().then();
  }, []);
  const isIncomeStatement:any = value?.incomeStatement?.label?.toLowerCase();
  return (
    <ReportEngineComponent
      setValue={setValue}
      value={value}
      title={`Expenses Report`}
      url={`cde-report/expenses`}
      columns={[
        {
          label: "No",
          name: "index",
          type: "integer",
        },
        {
          label: "Fiscal Year",
          name: "FiscalYear.name",
          data: "FiscalYear.name",
        },
        {
          label: "Category",
          name: "ExpenseCategory.name",
          data: "ExpenseCategory.name",
        },
        {
          label: "Description",
          name: "description",
        },
        {
          label: "Amount",
          name: "amount",
          type: "number",
          options: {
            customBodyRender: (v: any) => v?.toLocaleString(),
          },
        },
        {
          label: "Date",
          name: "createdAt",
            type: "date",
          options: {
            customBodyRender: (v: any) => moment(v).format("YYYY-MMM-DD"),
          },
        },
      ]}
      filters={[
        {
          label: "Fiscal Year",
          name: "fiscal",
          values: fiscalYears,
          required:isIncomeStatement==='yes'

        },
        {
          label: "Category",
          name: "categoryId",
          values: categories,
          hidden:isIncomeStatement==='yes'

        },
        {
          label: "Start Date",
          name: "startDate",
          type: "date",
          hidden:isIncomeStatement==='yes'

        },
        {
          label: "End Date",
          name: "endDate",
          type: "date",
          hidden:isIncomeStatement==='yes'
        },
        {
          label: "Income Statement",
          name: "incomeStatement",
          values: [{label:'Yes',value:'yes'},{label:'No',value:'no'}],
        },
      ]}
    />
  );
}

export default ExpenseReport;
