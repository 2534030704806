import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { Col, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import { fetchDetails } from "../../../services/common";
import { AddForm } from "../../employee/AddForm";
import { Alert, AlertTitle } from "@mui/material";
import FileUpload from "../../../components/FileUploader";

const RequestSocialFund = (props: any) => {
  // const { loggedUser } = props;
  const navigate = useNavigate();
  const [info, setInfo] = React.useState<any>();
  const [type, setType] = React.useState<any>();
  const [selectedType, setSelectedType] = React.useState<any>();
  const { id } = useParams();
  const [files, setFiles] = React.useState<any>([]);

  const fetchInfoDetails = async (id: string) => {
    const resp = await fetchDetails(`social-fund/view/${id}`);
    if (resp.status) {
      setInfo(resp.data);
      setSelectedType(resp.data?.fundTypeId);
    }
  };
  //load social fund data if exist
  useEffect(() => {
    if (id) {
      fetchInfoDetails(id).then();
    }
  }, [id]);

  const fetchSocialFundType = async () => {
    const typeAll = await fetchDetails(`/social-fund/view`);
    if (typeAll.status) {
      setType(typeAll.data);
    }
  };
  useEffect(() => {
    if (selectedType) {
      const fundTypeInfo = type?.find((typ: any) => typ?.id === selectedType);
      setInfo({ ...info, amount: fundTypeInfo?.amount, fundTypeInfo });
    }
  }, [selectedType]);
  //load social fund type
  useEffect(() => {
    fetchSocialFundType();
  }, []);

  return (
    <div>
      <Breadcrumb />
      <Row className={"justify-content-center"}>
        <Col md={8}>
          <AddForm
            inline={true}
            col={12}
            mainFields={[
              {
                title: "",
                fields: [
                  {
                    label: "status",
                    name: "status",
                    hidden: true,
                    value: "pending",
                  },
                  {
                    name: "fundTypeId",
                    required: true,
                    col: 6,
                    value: selectedType,
                    render: () => {
                      return (
                        <div className={"mb-3 row"}>
                          {selectedType ? (
                            <div className="col-md-12 mb-4 p-3">
                              <Alert severity="warning">
                                <AlertTitle>Note</AlertTitle>
                                <strong>
                                  <p style={{ whiteSpace: "pre-line" }}>
                                    {info?.fundTypeInfo?.description}
                                  </p>
                                </strong>
                              </Alert>
                            </div>
                          ) : null}
                        </div>
                      );
                    },
                  },
                  {
                    label: "Reason For Request",
                    name: "type",
                    type: "select",
                    value: selectedType,
                    values: type?.map(({ name, id }: any) => ({
                      label: name,
                      value: id,
                    })),
                    required: true,
                    setValue: setSelectedType,
                  },
                  {
                    label: "Amount",
                    name: "amount",
                    type: "number",
                    value: info?.amount,
                    disabled: true,
                  },
                  {
                    label: info?.fundTypeInfo?.docName ?? "Support document",
                    name: "supportingDocument",
                    type: "file",
                    multiple: true,
                    col: 12,
                    value: files,
                    render: () => {
                      return (
                        <FileUpload
                          setFiles={setFiles}
                          files={files}
                          required={id === "new"}
                          currentFile={info?.supportingDocument}
                          accept={'application/pdf,image/*'}
                        />
                      );
                    },
                  },
                  {
                    label: "Any comment?",
                    name: "comment",
                    type: "textarea",
                    hidden: id === "new",
                  },
                ],
              },
            ]}
            url={
              id !== "new" ? `social-fund/update/${id}` : "social-fund/create"
            }
            method={id !== "new" ? "PUT" : "POST"}
            title={id !== "new" ? "Update Social Fund" : "Request Social Fund"}
            onSuccess={() => navigate(-1)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default RequestSocialFund;
