import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { Col, Row } from "react-bootstrap";
import { FormDialog } from "../../../components/FormDialog";
import React, { useEffect } from "react";
import { fetchDetails } from "../../../services/common";
import { AddForm } from "../../employee/AddForm";

const CreatePosition = () => {
  const navigate = useNavigate();
  const [info, setInfo] = React.useState<any>();
  const { id } = useParams();
  const [echelon, setEchelon] = React.useState<any>([]);
  const [level, setLevel] = React.useState<any>([]);

  const fetchInfoDetails = async (id: string) => {
    const resp = await fetchDetails(`position/view/${id}`);
    if (resp.status) {
      setInfo(resp.data);
    }
  };

  useEffect(() => {
    if (id) {
      fetchInfoDetails(id).then();
    }
  }, [id]);
  useEffect(() => {
    laodInitialData();
  }, []);
  const laodInitialData = async () => {
    const level = await fetchDetails("/level/view");
    const echelon = await fetchDetails("/echlon/view");
    if (echelon.status) setEchelon(echelon?.data);
    if (level.status) setLevel(level?.data);
  };
  return (
    <div>
      <Breadcrumb />
      <Row className={"justify-content-center"}>
        <Col md={5}>
          <AddForm
            inline={true}
            mainFields={[
              {
                title: "",
                fields: [
                  
              {
                label: "Name",
                name: "name",
                type: "name",
                value: info?.name,
                required: true,
              },
              {
                label: "Scale",
                name: "echlonId",
                type: "select",
                required: true,
                values: echelon?.map(({ name, id }: any) => ({
                  label: name,
                  value: id,
                })),
                value: info?.echlonId,
              },
              {
                label: "Level",
                name: "levelId",
                type: "select",
                // hidden: info?.level !== "level",
                values: level?.map(({ name, id }: any) => ({
                  label: name,
                  value: id,
                })),
                value: info?.levelId,

              },
            ]
          }]}
            url={id ? `position/update/${id}` : "position/create"}
            method={id ? "PUT" : "POST"}
            title={id ? "Update Position" : "Create New Position"}
            onSuccess={() => navigate(-1)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CreatePosition;
