import { Button, Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { mapStateToProps } from "../store/helpers/mapState";
import { mapDispatchToProps } from "../store/helpers/mapDispatch";
import { useEffect, useState } from "react";
import { AddForm } from "../pages/employee/AddForm";
import {
  Alert,
  Chip,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@mui/material";
import {
  fetchDetails,
  getFileMime,
  relationshipList,
} from "../services/common";
import RequestHistory from "./RequestHistory";
import moment from "moment";
import OpenDocuments from "../components/openDocument";
import {
  ReadMore,
  RemoveRedEye,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

const AcceptCaiseTermConditions = (props: any) => {
  const { loggedUser } = props;
  const [open, setOpen] = useState(0);
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [openStatuteFile, setOpenStatuteFile] = useState(false);
  const [rate, setRate] = useState<any>(5);
  const [cDEAccount, setCDEAccount] = useState<any>();
  const [relationship, setRelationship] = useState<any>();
  const [readTerms, setReadTerms] = useState(false);
  const CurrentFile =
    cDEAccount?.statuteFile && typeof cDEAccount?.statuteFile === "string"
      ? JSON.parse(cDEAccount?.statuteFile)
      : cDEAccount?.statuteFile;

  const memberInfo = info?.employee?.CaisseMember;
  const checkMyApp = async () => {
    setLoading(true);
    const resp = await fetchDetails("caise/myApplication");
    if (resp.status) {
      setInfo(resp.data);
      setRate(resp.data?.shareRate);
      setData({
        firstName: resp.data?.employee?.firstName,
        lastName: resp.data?.employee?.lastName,
        motherName: resp.data?.employee?.motherName,
        fatherName: resp.data?.employee?.fatherName,
        civilStatus: resp.data?.employee?.civilStatus,
        employeeId: resp.data?.employee?.id,
        cdeNumber: resp.data?.employee?.CaisseMember?.cdeNumber,
        nextOfKinAddress: resp.data?.nextOfKinAddress,
        nextOfKinEmail: resp.data?.nextOfKinEmail,
        nextOfKinNames: resp.data?.nextOfKinNames,
        nextOfKinPhone: resp.data?.nextOfKinPhone,
        nextOfKinRelationShip: resp.data?.nextOfKinRelationShip,
        identity: resp.data?.employee?.CaisseMember ? true : false,
        salary: resp.data?.employee?.salary,
        nextOfKinNationalId: resp.data?.nextOfKinNationalId,
        status: resp.data?.status,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    checkMyApp().then();
  }, []);

  const fetchCdeInfo = async () => {
    const resp = await fetchDetails(`public/active/summary`);
    if (resp.status) {
      setCDEAccount(resp.data);
    }
  };

  useEffect(() => {
    fetchCdeInfo().then();
  }, []);

  const branch = info?.employee?.employeeBranches?.map(
    (bran: any) => bran?.branch?.name
  );
  useEffect(() => {
    if (loggedUser) {
      setData({
        ...data,
        firstName: loggedUser?.Employee?.firstName,
        lastName: loggedUser?.Employee?.lastName,
        motherName: loggedUser?.Employee?.motherName,
        fatherName: loggedUser?.Employee?.fatherName,
        civilStatus: loggedUser?.Employee?.civilStatus,
        employeeId: loggedUser?.Employee?.id,
        salary: loggedUser?.Employee?.salary,
      });
    }
  }, [loggedUser]);

  return (
    <div>
      <AddForm
        maxWidth={"lg"}
        formAlert={
          "N.B :Amafaranga ava ku mushahara wawe buri kwezi akajya muri caisse d'entraide y'abakozi b'UBUSHINJA CYAHA"
        }
        open={open}
        setOpen={setOpen}
        onSuccess={(data) => {
          setOpen(0);
          setInfo(data?.data);
          checkMyApp().then();
        }}
        mainFields={[
          {
            title: "",
            fields: [
              {
                label: "",
                name: "status",
                value: "pending",
                hidden: true,
              },
              {
                label: "Net Salary",
                name: "currentSalary",
                type: "number",
                value: data?.salary,
                disabled: true,
                // setValue:setSalary
              },
              {
                type: "number",
                label: "Monthly contribution(Not less than 5%)",
                name: "shareRate",
                min: 5,
                value: 5,
                // value: rate,
                disabled: true,
                setValue: setRate,
              },
              {
                type: "number",
                label: "Monthly contribution",
                name: "share",
                value: (data?.salary * rate) / 100,
                disabled: true,
              },
            ],
          },

          {
            title: "Next of Kin Information ",
            fields: [
              {
                label: "Names",
                name: "nextOfKinNames",
                required: true,
                col: 6,
                value: data?.nextOfKinNames,
              },
              {
                label: "Relationship",
                name: "nextOfKinRelationShip",
                required: true,
                col: 6,
                value: data?.nextOfKinRelationShip,
                type: "select",
                values: relationshipList,
                setValue: setRelationship,
              },
              {
                label: "Specify other relationship",
                name: "otherNextofKin",
                required: true,
                hidden: relationship !== "other",
                col: 6,
                value: data?.nextOfKinRelationShip,
              },
              {
                label: "National ID or Passport",
                name: "nextOfKinNationalId",
                // type: "number",
                required: true,
                col: 6,
                minLength: 4,
                maxLength: 16,
                value: data?.nextOfKinNationalId,
              },
              {
                label: "Address",
                name: "nextOfKinAddress",
                required: true,
                col: 6,
                value: data?.nextOfKinAddress,
              },
              {
                label: "Email",
                name: "nextOfKinEmail",
                type: "email",
                // required: true,
                col: 6,
                value: data?.nextOfKinEmail,
              },
              {
                label: "Phone Number",
                name: "nextOfKinPhone",
                type: "tel",
                required: true,
                col: 6,
                value: data?.nextOfKinPhone,
              },
              // {
              //   label: "Comment",
              //   name: "comment",
              //   hidden: data?.status !== "request action",
              //   type: "textarea",
              //   required: true,
              //   col: 12,
              // },
              {
                label: "Comment",
                name: "comment",
                hidden: data?.status !== "request action",
                type: "textarea",
                required: true,
                col: 12,
              },
            ],
          },
        ]}
        url={`caise/apply`}
        title={`Apply to join caisse d'entraide(Saba kuba Umunyamuryango)`}
      />
      <Row className={"justify-content-center"}>
        <Col md={12} className="p-3 bg-white">
          {info?.status === "need-identification" ||
          info?.status === "request action" ? (
            info?.status === "request action" && !memberInfo ? (
              <Alert severity="warning">
                You are requested to take some action on your request,please
                read it in the comment below.{" "}
                <Button size="sm" onClick={() => setOpen(1)}>
                  Update
                </Button>
              </Alert>
            ) : null
          ) : (
            <Card className={"m-5"}>
              {readTerms || !info ? (
                <div className={"card-header"}>
                  <h2>Terms & Conditions to join Caisse d'entraide</h2>
                </div>
              ) : null}

              {/* {loading && <LinearProgress  />} */}
              <div className={"card-body"}>
                {info?.status === "pending" ? (
                  <div className="row justify-content-center mb-3 mt-4">
                    <div className="col-md-9 border">
                      {" "}
                      <Typography style={{fontFamily:'inter'}} className="fw-bold mb-4">
                        GUSABA KUBA UMUNYAMURYANGO WA CAISSE D’ENTRAIDE
                      </Typography>
                      <p>
                        NJYEWE,{" "}
                        <strong>{`${info?.employee?.firstName} ${info?.employee?.lastName}`}</strong>
                        , NEMEYE KO BURI KWEZI NZAJYA NTANGA UMUGABANE WA :{" "}
                        {info?.share?.toLocaleString()}(Ishobora guhinduka)
                      </p>
                      <p>
                        {" "}
                        AVUYE KU MUSHAHARA WANJYE BURI KWEZI AKAJYA MURI CAISSE
                        D’ENTRAIDE Y’ABAKOZI B’UBUSHINJACYAHA NIFUZA KUBERA
                        UMUNYAMURYANGO.
                      </p>
                      <p>
                        AMAZINA :{" "}
                        <strong>{`${info?.employee?.firstName} ${info?.employee?.lastName}`}</strong>
                      </p>
                      <p>
                        ICYO AKORA :{" "}
                        <strong>{info?.employee?.position?.name}</strong>
                      </p>
                      <p>
                        AHO AKORERA (Prosecution Office) :
                        <strong>{branch?.toString()}</strong>
                      </p>
                      <p>
                        {" "}
                        TARIKI:
                        <strong>{moment(info?.createdAt).format("L")}</strong>
                      </p>
                    </div>
                    <div className={"col-md-9"}>
                      <h3 className={"mt-3"}>Next of kin Information</h3>
                      <div className={"row mt-2"}>
                        <div className={"col-md-4"}>
                          <div>
                            <span>Next of kin</span>
                          </div>
                          <div>
                            <span className={"fw-bold"}>
                              {info?.nextOfKinNames}
                            </span>
                          </div>
                        </div>
                        <div className={"col-md-4"}>
                          <div>
                            <span>Address</span>
                          </div>
                          <div>
                            <span className={"fw-bold"}>
                              {info?.nextOfKinAddress}
                            </span>
                          </div>
                        </div>
                        <div className={"col-md-4"}>
                          <div>
                            <span>National ID or Passport</span>
                          </div>
                          <div>
                            <span className={"fw-bold"}>
                              {info?.nextOfKinNationalId}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={"row mt-2"}>
                        <div className={"col-md-4"}>
                          <div>
                            <span>Relationship</span>
                          </div>
                          <div>
                            <span className={"fw-bold"}>
                              {info?.nextOfKinRelationShip}
                            </span>
                          </div>
                        </div>
                        <div className={"col-md-4"}>
                          <div>
                            <span>Phone Number</span>
                          </div>
                          <div>
                            <span className={"fw-bold"}>
                              {info?.nextOfKinPhone}
                            </span>
                          </div>
                        </div>
                        <div className={"col-md-4"}>
                          <div>
                            <span>Email Address</span>
                          </div>
                          <div>
                            <span className={"fw-bold"}>
                              {info?.nextOfKinEmail}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {!info ? (
                  <>
                    <Button
                      className="mb-2"
                      onClick={() => {
                        setOpenStatuteFile(!openStatuteFile);
                      }}
                    >
                      {openStatuteFile ? <VisibilityOff /> : <Visibility />}{" "}
                      Read Notified Statute
                    </Button>
                    {openStatuteFile ? (
                      <div className="col-md-12">
                        {CurrentFile?.map((v: string) => (
                          <OpenDocuments
                            downloadable={true}
                            card={true}
                            document={v}
                            mimeType={getFileMime(v)}
                          />
                        ))}
                      </div>
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: cDEAccount?.statute,
                        }}
                      />
                    )}
                  </>
                ) : null}
              </div>
              <div className={"card-footer d-flex justify-content-end"}>
                {info ? (
                  <Chip label={info.status} />
                ) : loading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Button onClick={() => setOpen(1)}>
                      Accept Terms & Conditions
                    </Button>
                  </>
                )}
              </div>
            </Card>
          )}
          {info?.id ? (
            <>
              <Typography style={{fontFamily:'inter'}} className="mb-3 fw-bold">Request History</Typography>

              <RequestHistory RequestProcesses={info?.RequestProcesses} />
            </>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcceptCaiseTermConditions);
