import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../../components/Breadcrumb";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import { CustomizedMenus } from "../../../../components/customizeMenu";
import DeleteDialog from "../../../../components/DeleteDialog";
import { DatatableComponent } from "../../../../components/DatatableComponent";
import {
  Cancel,
  EventAvailableOutlined,
  PeopleAltOutlined,
  UpdateOutlined,
} from "@mui/icons-material";
import { Col, Row } from "react-bootstrap";
import { DashboardCard } from "../../../../components/DashboardCard";
import { default as ReactSelect } from "react-select";
import { CheckHasPermission, fetchDetails } from "../../../../services/common";
import StatusComponent from "../../../../components/StatusComponent";
import { mapDispatchToProps } from "../../../../store/helpers/mapDispatch";
import { mapStateToProps } from "../../../../store/helpers/mapState";
import { connect } from "react-redux";

const IncomingTransferList = (props: any) => {
  const { loggedUser } = props;
  const navigate = useNavigate();
  const [list, setList] = useState<Array<any>>([]);
  const [deleteOpen, setDeleteOpen] = useState(0);
  const [deleteActionUrl, setDeleteActionUrl] = useState<
    string | undefined | null
  >();
  const [change, setChange] = useState(0);
  const [info, setInfo] = useState<any>({});
  const [dashboardData, setDashboardData] = useState<any>();
  const [branch, setBranch] = useState<any>([]);

  const hasAdminRole =
    loggedUser?.roles?.filter((item: any) => item.role?.name === "admin")
      ?.length > 0;

  const reload = () => {
    setChange((v) => v + 1);
  };
  const dataSummary = async () => {
    const resp = await fetchDetails(
      hasAdminRole ? `dashboard/transfer-dashboard/` : `transfer/all-dashboard/`
    );
    const branchList = await fetchDetails("/branch/view");
    if (resp.status) {
      setDashboardData(resp.data);
    }
    if (branchList.status) {
      setBranch(branchList?.data);
    }
  };
  useEffect(() => {
    //fetch dataSummary
    dataSummary().then();
  }, [hasAdminRole]);
  //dashboard data
  const pending =
    dashboardData?.filter((items: any) => items?.status === "pending") ?? [];
  const approved =
    dashboardData?.filter((items: any) => items?.status === "approved") ?? [];
  const rejected =
    dashboardData?.filter((items: any) => items?.status === "rejected") ?? [];
  const clarification =
    dashboardData?.filter((items: any) => items?.status === "request action") ??
    [];

  return (
    <div>
      <Breadcrumb
        addButton={
          CheckHasPermission("employee", "create")
            ? {
                label: "New Request",
                onClick: () => navigate("transfer/new"),
              }
            : undefined
        }
      />
      <Row className="mt-2">
        <Col md={3}>
          <DashboardCard
            content={{
              title: "Pending",
              number: pending[0]?.request ?? 0,
            }}
            className="justify-content-center"
            icon={
              <PeopleAltOutlined
                sx={{ color: "#054D6F", width: "25px", height: "25px" }}
              />
            }
          />
        </Col>
        <Col md={3}>
          <DashboardCard
            content={{
              title: "Request action",
              number: clarification[0]?.request ?? 0,
            }}
            icon={
              <UpdateOutlined
                sx={{ color: "#054D6F", width: "25px", height: "25px" }}
              />
            }
            className="justify-content-center"
          />
        </Col>
        <Col md={3}>
          <DashboardCard
            content={{
              title: "Approved",
              number: approved[0]?.request ?? 0,
            }}
            icon={
              <EventAvailableOutlined
                sx={{ color: "#054D6F", width: "25px", height: "25px" }}
              />
            }
            className="justify-content-center"
          />
        </Col>
        <Col md={3}>
          <DashboardCard
            content={{
              title: "Rejected",
              number: rejected[0]?.request ?? 0,
            }}
            icon={
              <Cancel
                sx={{ color: "#054D6F", width: "25px", height: "25px" }}
              />
            }
            className="justify-content-center"
          />
        </Col>
      </Row>

      <Row>
        <Col md={2}>
          <label className="col-form-label text-capitalize">Status</label>
          <ReactSelect
            className={"text-nowrap"}
            options={[
              { value: "pending", label: "pending" },
              { value: "approved", label: "approved" },
              { value: "request action", label: "request action" },
              { value: "rejected", label: "rejected" },
              { value: "all", label: "All" },
            ]}
            onChange={(v) => {
              setInfo({ ...info, status: v?.value });
            }}
          />
        </Col>
        <Col md={3}>
          <label className="col-form-label text-capitalize">From</label>
          <ReactSelect
            className={"text-nowrap"}
            options={branch?.map(({ label, id, name }: any) => ({
              value: id,
              label: label ?? name,
            }))}
            onChange={(v: any) => {
              setInfo({ ...info, branchFrom: v?.value });
            }}
          />
        </Col>
        <Col md={3}>
          <label className="col-form-label text-capitalize">To</label>
          <ReactSelect
            className={"text-nowrap"}
            options={branch?.map(({ label, id, name }: any) => ({
              value: id,
              label: label ?? name,
            }))}
            onChange={(v: any) => {
              setInfo({ ...info, branchTo: v?.value });
            }}
          />
        </Col>
        <Col md={2}>
          <label className="col-form-label text-capitalize">Start Date</label>
          <input
            className="form-control"
            type="date"
            onChange={(e: any) => {
              setInfo({ ...info, startDate: e.target.value });
            }}
          />
        </Col>
        <Col md={2}>
          <label className="col-form-label text-capitalize">End Date</label>
          <input
            className="form-control"
            type="date"
            onChange={(e: any) => {
              setInfo({ ...info, endDate: e.target.value });
            }}
          />
        </Col>
      </Row>
      <DatatableComponent
        columns={[
          {
            label: "Employee Name",
            name: "Employee.names",
            data: "Employee.firstName",
            // options: {
            //   filter: true,
            //   sort: true,
            //   customBodyRenderLite: (id: any, index: any) => {
            //     return (
            //       <span className="text-capitalize  ">{`${list[index]?.Employee?.firstName} ${list[index]?.Employee?.lastName}`}</span>
            //     );
            //   },
            // },
          },
          // {
          //   label: "Position",
          //   name: "toPosition.name",
          // },
          // {
          //   label: "Transferred From",
          //   name: "fromBranch.name",
          // },
          {
            label: "Transferred To",
            name: "toBranch.name",
          },

          // {
          //   label: "Department",
          //   name: "toDepartment.name",
          // },
          {
            label: "Request Date",
            name: "createdAt",
            options: {
              customBodyRender: (v: any) => moment(v).format("YYYY-MMM-DD"),
            },
          },
          {
            label: "Status",
            name: "status",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (status: any) => (
                <StatusComponent status={status} badge={false} />
              ),
            },
          },
          {
            name: "id",
            label: "Action",
            options: {
              filter: true,
              sort: false,
              customBodyRenderLite: (id: any, index: any) => {
                return (
                  <CustomizedMenus
                    onView={() => {
                      navigate(`details/${list[index].id}`);
                    }}
                    // onEdit={() => {
                    //   navigate(`transfer/${list[index].id}`);
                    // }}
                    // onDelete={() => {
                    //   setDeleteOpen(1);
                    //   setDeleteActionUrl(`/transfer/delete/${list[index].id}`);
                    // }}
                    // needEdit={false}
                    // needDelete={false}
                  />
                );
              },
            },
          },
        ]}
        url={
          hasAdminRole
            ? `dashboard/transfer/view?info=${JSON.stringify(info)}`
            : `/transfer/datatable/incoming?change=${change}&info=${JSON.stringify(
                info
              )}`
        }
        title={"Transfer List"}
        processData={(data: any) => {
          setList(data.data);
          return data;
        }}
      />
      {deleteActionUrl ? (
        <DeleteDialog
          open={deleteOpen}
          onSuccess={reload}
          setOpen={setDeleteOpen}
          url={deleteActionUrl!}
        />
      ) : null}
    </div>
  );
};

// export default IncomingTransferList;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IncomingTransferList);
