import {
  Alert,
  Box,
  Breakpoint,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import * as React from "react";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Search } from "@mui/icons-material";
import { approvalNavItem, approveSavings, dashboardNavItem, employeeNavItem, newCdeMemberNavItem, reportNavItem, savingNavItem, settingsNavItems } from "../../services/common";
import { useLocation } from "react-router-dom";
// import {successToast} from "./Notification";

//   type IProps = {
//     open: boolean;
//     setOpen: (i: boolean) => void;
//     onSuccess?: (res: any) => void;
//     url: string;
//     info?: boolean;
//     title?: string | undefined;
//     bodyText?: string | ReactJSXElement | undefined;
//     maxWidth?: Breakpoint | undefined;
//     body?: any | undefined;
//     buttonTitle?: string | undefined;
//     usePost?: boolean | undefined;
//     fullWidth?: boolean | undefined;
//     handleAction: (res: any) => void;
//   };

const SearchDialog = (props: any) => {
  const {
    open,
    setOpen,
    info,
    loggedUser,
    bodyText,
    search,
    setSearch,
    fullWidth,
    handleAction,
  } = props;
  const [deleting, setDeleting] = React.useState(false);
  const [error, setError] = React.useState<string | null>();

  const [items, setItems] = React.useState<any>([]);
  const [baseUrl, setBaseUrl] = React.useState<any>();
  const status = loggedUser?.Employee?.caisseEntradeStatus;

  const { pathname } = useLocation();
  const newPath = pathname?.split("/") ?? [];
  const path = `/${newPath[1]}/${newPath[2]}/`;
  React.useEffect(() => {
    if (path === "/console/employee/") {
      setItems(employeeNavItem);
      setBaseUrl("/console/employee/");
    } else if (path === "/console/approvals/") {
      setItems(approvalNavItem);
      setBaseUrl("/console/approvals/");
    } else if (path === "/console/saving/") {
      setItems(
        status === "active"
          ? savingNavItem
          : status === "exited"
          ? null
          : newCdeMemberNavItem
      );
      setBaseUrl("/console/saving/");
    } else if (path === "/console/approveSaving/") {
      setItems(approveSavings);
      setBaseUrl("/console/approveSaving/");
    } else if (path === "/console/settings/") {
      setItems(settingsNavItems);
      setBaseUrl("/console/settings/");
    } else if (path === "/console/dashboard/") {
      setItems(dashboardNavItem);
      setBaseUrl("/console/dashboard");
    } else if (path === "/console/report/") {
      setItems(reportNavItem);
      setBaseUrl("/console/report/");
    } else if (path === "/console/caisse-report/") {
      setItems([]);
      setBaseUrl("/console/caisse-report/");
    } else if (path === "/console/logs/") {
      setItems([]);
      setBaseUrl("/console/logs/");
    } else {
      setItems([]);
      setBaseUrl(path);
    }
  }, [path, status]);

  const handleClose = () => {
    setError(null);
      setOpen(false);
  };
  const StyledToolbar = styled(DialogContent)(({ theme }) => ({
    // paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(2),
    // Override media queries injected by theme.mixins.toolbar
    "@media all": {
      minHeight: 500,
      maxHeight: 1200,
    },
  }));
  return (
    <Dialog
      open={open}
      maxWidth={"lg"}
      fullWidth={fullWidth}
      onClose={handleClose}
    >
      <StyledToolbar>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Search sx={{ color: "action.active", mr: 1, my: 0.5 }} />
          <TextField
            id="input-with-sx"
            label="Search"
            variant="standard"
            onChange={(e: any) => {
              setSearch(e.target.value);
            }}
            value={search}
          />
        </Box>
   
          <DialogContentText>
            {items?.length?
           items?.map((item:any,index:any)=>
            <List key={index}>
           <ListItem alignItems="flex-start" className="col-md-3">
             {/* <ListItemAvatar>
               <Avatar />
             </ListItemAvatar> */}
             <ListItemText
               primary={item?.label}
            //    secondary={emp?.position?.name ?? ""}
             />
           </ListItem>
         </List>
        )
            :null}
          </DialogContentText>
      </StyledToolbar>
    </Dialog>
  );
};

export default SearchDialog;
