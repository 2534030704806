import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import {
  Delete,
  FileCopy,
  FilterList,
  PictureAsPdf,
  Refresh,
} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  IconButton,
  ThemeProvider,
  Typography,
  createTheme,
  Button,
  TableRow,
  TableCell,
  TableFooter,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
// import { AxiosError } from "axios";
import MUIDataTable, {
  DisplayData,
  MUIDataTableOptions,
  SelectableRows,
} from "mui-datatables";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import http from "../services/http-common";
import authHeader from "../services/auth-header";
import DeleteDialog from "./DeleteDialog";
// import { downloadBase64 } from "../services/common";
import { saveAs } from "file-saver";
import { makeStyles } from "@material-ui/core";
import TextField from "@mui/material/TextField";

export type FilterDropdownItem = { label: string; value: string };

export type FilterType = "text" | "select" | "number" | "date";

export type FilterItem = {
  name: string;
  label: string;
  value?: string | undefined;
  column?: object | undefined;
  type?: FilterType;
  values?: Array<FilterDropdownItem> | undefined;
  hidden?: boolean;
  required?: boolean;
};

type IProps = {
  columns: Array<any>;
  url: string;
  exportButtons?: boolean | undefined | null;
  notPdf?: boolean | undefined | null;
  notExcel?: boolean | undefined | null;
  titleClass?: string | undefined | null;
  componentClass?: string | undefined | null;
  processData?: (data: any) => any;
  title?: any;
  summaryFooter?: boolean | undefined | null;
  rowsSelected?: any[] | undefined;
  onRowSelectionChange?: (
    currentRowsSelected: any[],
    allRowsSelected: any[],
    rowsSelected?: any[]
  ) => void;
  customToolbarSelect?: (
    selectedRows: {
      data: Array<{ index: number; dataIndex: number }>;
      lookup: { [key: number]: boolean };
    },
    displayData: DisplayData,
    setSelectedRows: (rows: number[]) => void
  ) => React.ReactNode;
  elevation?: number;
  useMeetingUrl?: boolean;
  selectableRowsHideCheckboxes?: boolean;
  onRowClick?: (
    rowData: string[],
    rowMeta: { dataIndex: number; rowIndex: number }
  ) => void;
  actions?: (
    id: string,
    meta?: any,
    row?: any
  ) => Array<ReactJSXElement | null>;
  deleteUrl?: (id: string, row?: any) => string | undefined;
  pk?: string;
  selectableRows?: SelectableRows | undefined;
  addRowNumber?: boolean | undefined;
  filters?: Array<FilterItem> | undefined;
  filteredValues?: Array<any> | undefined;
  refresh?: boolean;
  isLocationFilter?: boolean;
  emitFilterUrl?: (url: string) => void;
  ExpandedRows?: any;
  originData?: any[];
  printable?: boolean | undefined | null;
};

export const SerializeObject = (
  obj: any,
  prefix: any = null
): string | null | undefined => {
  let str: Array<any> = [],
    p;

  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? prefix + "[" + p + "]" : p;
      const v = obj[p];
      if (typeof v === "object") {
        str.push(SerializeObject(v, k));
      } else if (typeof v !== "function") {
        str.push(
          encodeURIComponent(k) + "=" + encodeURIComponent(v?.toString() ?? "")
        );
      }
    }
  }

  return str.join("&");
};

const makeId = (length: number): string => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

const uniqueId = () => makeId(10);

const useStyles = makeStyles((theme) => ({
  footerCell: {
    backgroundColor: "#054D6F",
    borderBottom: "none",
    color: "white !important",
    fontSize: "14px !important",
    fontWeight: "bolder",
  },
}));

export const DatatableComponent = (props: IProps) => {
  const {
    columns,
    filters,
    actions,
    addRowNumber,
    pk,
    selectableRows,
    url,
    title,
    deleteUrl,
    processData,
    elevation,
    onRowClick,
    refresh,
    isLocationFilter,
    emitFilterUrl,
    ExpandedRows,
    originData,
    customToolbarSelect,
    rowsSelected,
    onRowSelectionChange,
    titleClass,
    componentClass,
    exportButtons,
    summaryFooter,
    notExcel,
    notPdf,
    printable,
  } = props;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<Array<any>>([]);
  const [page, setPage] = React.useState<number>(0);
  const [sortOrder, setSortOrder] = React.useState<any>();
  const [count, setCount] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<any>(100);
  const [error, setError] = React.useState<string | null | undefined>();
  const [errorStatus, setErrorStatus] = React.useState<
    string | number | null | undefined
  >();
  const [columns0, setColumns0] = React.useState<Array<any>>([]);
  const [tableId, setTableId] = React.useState<string>(uniqueId());

  const [tableState, setTableState] = React.useState(null);
  const [deleteOpen, setDeleteOpen] = React.useState(0);
  const [change, setChange] = React.useState<number>(0);
  const [deleteActionUrl, setDeleteActionUrl] = React.useState<
    string | undefined | null
  >();
  const [filters0, setFilters0] = useState<Array<FilterItem>>([]);
  const [filter, setFilter] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = React.useState<string>("");
  const [titleText, setTitleText] = React.useState<string>("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (filters) {
      setFilters0(filters);
    }
  }, [filters]);

  useEffect(() => {
    const primary = pk ?? "id";
    let realColumns = columns;
    if (addRowNumber) {
      realColumns = [
        {
          name: "#",
          label: "",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value: any, tableMeta: any) => (
              <Chip label={Number(tableMeta.rowIndex) + 1} />
            ),
          },
        },
        ...realColumns,
      ];
    }

    if (deleteUrl || actions) {
      realColumns = [
        ...realColumns,
        {
          name: primary,
          label: "Action",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (id: any, meta: any, row: any) => {
              let newActions: Array<ReactJSXElement | null> = actions
                ? actions(id, meta, row)
                : [];

              if (deleteUrl && deleteUrl(id, row)) {
                newActions = [
                  ...newActions,
                  <button title="delete" className={"border-0 bg-transparent"}>
                    <Delete
                      onClick={() => {
                        setDeleteOpen(1);
                        setDeleteActionUrl(deleteUrl(id, row));
                      }}
                      fontSize="small"
                      className={"text-danger cursor-pointer"}
                    />
                  </button>,
                ];
              }

              return <div className={"flex justify-start"}>{newActions}</div>;
            },
          },
        },
      ];
    }

    realColumns = realColumns.map((v) => {
      const label = (
        <span className={"fw-bolder text-uppercase data-table-header"}>
          {v.label}
        </span>
      );
      if (v.options?.customBodyRender && data) {
        const render = v.options?.customBodyRender;
        const { options } = v;
        return {
          ...v,
          label,
          options: {
            ...options,
            customBodyRender: (value: any, tableMeta: any) => {
              const info =
                data?.length > tableMeta.rowIndex
                  ? data[tableMeta.rowIndex]
                  : undefined;
              return info ? render(value, tableMeta, info) : "";
            },
            tableId,
          },
        };
      }
      return { ...v, label };
    });
    setTableId(uniqueId());
    setColumns0(realColumns);
  }, [columns, pk, deleteUrl, addRowNumber, actions, data]);

  const preSetData = (reqData: any) => {
    if (reqData) {
      reqData = reqData.data;
      if (processData) {
        const { data, recordsFiltered, page } = processData(reqData);
        setError(null);
        setData(data);
        setCount(recordsFiltered);
        setPage(page);
      } else {
        setError(null);
        setData(reqData.data);
        setCount(reqData.recordsFiltered);
        setPage(reqData.page);
      }
    }
  };

  const fetchData = async (
    rows?: number | null | undefined,
    newPage?: number | null | undefined
  ) => {
    setLoading(true);
    const res = await xhrRequest(newPage ?? page, null, null, rows);
    preSetData(res);
    setLoading(false);
    return res;
  };

  const reload = () => {
    setChange((v) => v + 1);
  };

  useEffect(() => {
    setPage(0);
    if (tableState) {
      tableChange(tableState);
    } else {
      fetchData(undefined, 0).then();
    }
  }, [url, change, refresh]);

  // mock async function
  const xhrRequest = async (
    page: number,
    sortOrder: any = null,
    append: any = null,
    rows?: number | null | undefined
  ) => {
    let isFiltered = false;

    if (filters0.length) {
      const filter = filters0.filter(
        (v) => v.value && v.value.trim().length > 0
      );
      if (filter?.length) {
        isFiltered = true;
        append = `${append ?? ""}&${filter
          ?.map(
            (v) =>
              `${encodeURIComponent(v.name)}=${encodeURIComponent(
                v.value ?? ""
              )}`
          )
          .join("&")}`;
      }
    }

    let char = url.includes("?") ? "&" : "?";
    const rows2 = rows ?? rowsPerPage ?? 0;
    const URL = `${url}${char}start=${(page ?? 0) * rows2}&length=${rows2}${
      append ? `&${append}` : ""
    }`;

    if (isFiltered) {
      emitFilterUrl?.(URL);
    }

    const filename = titleText.replace(/[^a-z\d\s]+/gi, "-");
    try {
      const isDoc = URL.includes("excel=1") || URL.includes("pdf=1");
      let config: any = { headers: authHeader() };
      if (isDoc) {
        config = { ...config, responseType: "blob" };
      }
      const { data } = await http.get(URL, config);
      if (isDoc&&data) {
        const file = new Blob([data], {
          type: URL.includes("pdf=1")
            ? "application/pdf"
            : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });

        saveAs(
          file,
          URL.includes("pdf=1") ? `${filename}.pdf` : `${filename}.xlsx`
        );
        return null;
      }
      setError(null);
      return data;
    } catch (err: any) {
      // const err: AxiosError = e as AxiosError;
      if (err.response) {
        setErrorStatus(err.response.status);
        setError(err.response.data?.message);
      } else {
        setErrorStatus(500);
        setError("Connection refused");
      }
      setLoading(false);
      return null;
    }
  };

  const changePage = (page: number, sortOrder: any, append: any) => {
    setLoading(true);
    xhrRequest(page, sortOrder, append).then((res: any) => {
      setLoading(false);
      preSetData(res);
      setLoading(false);
      setSortOrder(sortOrder);
    });
  };

  const onTableChange = (action: any, tableState0: any) => {
    switch (action) {
      case "propsUpdate": {
        if (!tableState) {
          setTableState(tableState0);
        }
        break;
      }
      case "search":
      case "sort":
      case "changePage":
        setTableState(tableState0);
        tableChange(tableState0);
        break;
    }
  };

  const tableChange = (
    tableState: any,
    newAppend: string | undefined = undefined
  ) => {
    let cols: any = {};
    for (let o in columns) {
      let obj = columns[o];
      let n = obj.data ?? obj.name;
      if (n && obj?.options?.filter !== false) {
        let newObj: any = {
          data: n,
          label: obj.label,
          name: obj.name,
        };
        if (obj.type) {
          newObj["type"] = obj.type;
        }
        cols[n] = newObj;
      }
    }

    let object: any = {
      columns: cols,
    };

    if (tableState.searchText) {
      object.search = {
        value: tableState.searchText,
      };
    }

    if (tableState.sortOrder.name) {
      const obs = columns.find((v) => v.name === tableState.sortOrder.name);
      object.order = [
        {
          column:
            obs?.data ?? tableState.sortOrder.data ?? tableState.sortOrder.name,
          dir: tableState.sortOrder.direction,
        },
      ];
    }

    let append = SerializeObject(object);

    if (newAppend) {
      append += `&${newAppend}`;
    }

    changePage(tableState.page, tableState.sortOrder, append);
  };
  // const ExpandedRowsList=()=>{
  // return {}
  // }

  const classes = useStyles();
  const tableStyle = {
    width: '80%'
  };
  const options: MUIDataTableOptions = {
    filter: false,
    responsive: "standard",
    enableNestedDataAccess: ".",
    customToolbarSelect,
    // selectableRowsHideCheckboxes:selectableRowsHideCheckboxes??false,
    elevation: elevation ?? 1,
    customTableBodyFooterRender: summaryFooter
      ? (opts) => {
          // const startIndex = page * rowsPerPage;
          // const endIndex = (page + 1) * rowsPerPage;

          const makeTotal = (name: string) => {
            const split = name.split(".");
            return data
              .reduce((previousValue, currentValue) => {
                let val: any = currentValue;
                for (const item of split) {
                  val = val ? val[item] : val;
                }
                return previousValue + val;
              }, 0)
              ?.toLocaleString();
          };
          return (
            <>
              {opts.data?.length > 0 && (
                <TableFooter className={classes.footerCell}>
                  <TableRow>
                    {columns0.map((col, index) => {
                      return (
                        <TableCell key={index} className={classes.footerCell}>
                          {col.type === "number" &&
                            makeTotal(col.totalCol ?? col.name)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableFooter>
              )}
            </>
          );
        }
      : undefined,
    selectableRows,
    serverSide: true,
    count: count,
    onRowClick,
    download: false,
    print: printable ?? false,
    page: page,
    rowsPerPage: rowsPerPage,
    onChangeRowsPerPage: (numberOfRows: any) => {
      setRowsPerPage(numberOfRows);
      fetchData(numberOfRows).then();
    },
    rowsSelected,
    onRowSelectionChange,
    customToolbar: () => (
      <span>
        <IconButton onClick={() => setFilter((prev) => !prev)}>
          <FilterList />
        </IconButton>
      </span>
    ),
    rowsPerPageOptions: [100,200,300,400,500],
    sortOrder: sortOrder,
    setTableProps: () => {
      return {
        // material ui v4 only
        size: "small",
      };
    },
    textLabels: {
      body: error
        ? {
            noMatch: (
              <div className={"text-left"}>
                <Alert
                  severity="error"
                  action={
                    <Button
                      endIcon={<Refresh />}
                      color="inherit"
                      onClick={() => fetchData()}
                    >
                      Refresh{" "}
                    </Button>
                  }
                >
                  <AlertTitle>Error ({errorStatus ?? ""})</AlertTitle>
                  {error} — <strong>try again!</strong>
                </Alert>
              </div>
            ),
          }
        : {
            noMatch: "No data available",
          },
    },
    onTableChange,
    expandableRows: ExpandedRows ? true : false,
    expandableRowsHeader: false,
    // expandableRowsOnClick: false,
    searchPlaceholder: "Search here...",
    rowsExpanded: [0, 1, 2, 3, 4],
    renderExpandableRow: (rowData, rowMeta) => {
      return ExpandedRows(rowData, rowMeta);
    },
  };
  const theme = (outerTheme:any) =>
    createTheme({
      ...outerTheme,
      components: {
        MuiTable: {
          styleOverrides: {
            root: {
              border: 1,
              // width:'10% !important'
              // backgroundColor: "#f8f9fa",
            },
          },
        },

        MuiTableCell: {
          styleOverrides: {
            head: {
              paddingLeft: "0px !important",
              backgroundColor: "#054D6F !important",
              color: "#F9FAFC !important",
              whiteSpace: "nowrap",
              "&:not(:last-child)": {
                borderRight: [[1, "solid", "#c0c0c0"]],
              },
            },
            body: {
              fontSize: "12px !important",
              whiteSpace: "nowrap",
              fontFamily:'Inter !important',
            },
          },
        },
        MuiTableSortLabel: {
          styleOverrides: {
            root: {
              // alignItems: "flex-start",
              // background: "white",
              color: "white !important",
              fontFamily:'Inter !important',
            },
            active: {
              // background: "white",
              // color: "white",
            },
          },
        },
        MuiTableFooter: {
          styleOverrides: {
            root: {
              background: "#f5f7f7 !important",
            },
          },
        },
      },
    });

  return (
    <div className={componentClass ?? "mt-3"} >
      {(filters0?.length > 0 || isLocationFilter) && filter && (
        <Card className="mb-3" sx={{ overflow: "visible" }}>
          <CardContent>
            {isLocationFilter && (
              <>
                {/*<div className="grid grid-cols-6 gap-4">*/}
                {/*  <div className="col-span-2 sm:col-span-2">*/}
                {/*    <label className="block mb-1 text-sm font-medium">Province</label>*/}
                {/*    <Select*/}
                {/*      placeholder="Select Province"*/}
                {/*      options={provinces}*/}
                {/*      onChange={(e: any) => onProvinceChange(e)}*/}
                {/*      value={*/}
                {/*        !!selectedProvince &&*/}
                {/*        provinces.find((x: any) => x.value === selectedProvince.value)*/}
                {/*      }*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*  <div className="col-span-6 sm:col-span-2">*/}
                {/*    <label className="block mb-1 text-sm font-medium">District</label>*/}
                {/*    <Select*/}
                {/*      placeholder="Select District"*/}
                {/*      options={districts}*/}
                {/*      onChange={(e:any) => onDistrictChange(e)}*/}
                {/*      value={*/}
                {/*        !!selectedDistrict &&*/}
                {/*        districts.find((x: any) => x.value === selectedDistrict.value)*/}
                {/*      }*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*  <div className="col-span-6 sm:col-span-2">*/}
                {/*    <label className="block mb-1 text-sm font-medium">Sector</label>*/}
                {/*    <Select*/}
                {/*      placeholder="Select Sector"*/}
                {/*      options={sectors}*/}
                {/*      onChange={(e: any) => onSectorChange(e)}*/}
                {/*      value={*/}
                {/*        !!selectedSector &&*/}
                {/*        sectors.find((x: any) => x.value === selectedSector.value)*/}
                {/*      }*/}
                {/*    />*/}
                {/*  </div>*/}

                {/*  <div className="col-span-6 sm:col-span-2">*/}
                {/*    <label className="block mb-1 text-sm font-medium">Cell</label>*/}
                {/*    <Select*/}
                {/*      placeholder="Select Cell"*/}
                {/*      options={cells}*/}
                {/*      onChange={(e: any) => onCellChange(e)}*/}
                {/*      value={*/}
                {/*        !!selectedCell && cells.find((x: any) => x.value === selectedCell.value)*/}
                {/*      }*/}
                {/*    />*/}
                {/*  </div>*/}

                {/*  <div className="col-span-6 sm:col-span-2">*/}
                {/*    <label className="block mb-1 text-sm font-medium">Village</label>*/}
                {/*    <Select*/}
                {/*      placeholder="Select Village"*/}
                {/*      options={villages}*/}
                {/*      onChange={(e: any) => onVillageChange(e)}*/}
                {/*      value={*/}
                {/*        !!selectedVillage &&*/}
                {/*        villages.find((x: any) => x.value === selectedVillage.value)*/}
                {/*      }*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*  <div className="col-span-6 sm:col-span-2">*/}
                {/*    <button className={'flex mt-6 px-3 py-2 text-sm font-semibold text-center text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'} onClick={resetLocation}>Reset</button>*/}
                {/*  </div>*/}
                {/*</div>*/}

                <hr className="my-4" />
              </>
            )}

            <div className="grid grid-cols-6 gap-4">
              {filters0.map((v) => (
                <div className="col-span-6 sm:col-span-2">
                  <label className="block mb-1 text-sm font-medium ">
                    {v.label}
                  </label>
                  {v.type === "select" ? (
                    <Select
                      className="text-nowrap"
                      onChange={(e: any) => {
                        v.value = e?.value;
                        setFilters0([...filters0]);
                        reload();
                      }}
                      value={
                        !v.value
                          ? null
                          : v.values?.find((item) => item.value === v.value)
                      }
                      options={v.values?.map(({ label, value }) => ({
                        value,
                        label,
                      }))}
                    />
                  ) : (
                    <input
                      onBlur={reload}
                      placeholder={v.label}
                      className="filter-field"
                      value={v.value}
                      onChange={(e) => {
                        v.value = e.target.value;
                        setFilters0([...filters0]);
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          reload();
                          // e.currentTarget.blur();
                        }
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      )}
      {deleteActionUrl ? (
        <DeleteDialog
          open={deleteOpen}
          onSuccess={reload}
          setOpen={setDeleteOpen}
          url={deleteActionUrl!}
        />
      ) : null}
      <div className={"position-relative"}>
        {loading ? (
          <div
            className={
              "position-absolute h-100 w-100 top-0 start-0 d-flex align-items-center justify-content-center"
            }
          >
            <div
              className={"bg-white p-3 rounded-4 shadow-sm text-center"}
              style={{ zIndex: 20000 }}
            >
              <div>
                <CircularProgress color={"success"} />
              </div>
              <span>
                <Typography style={{fontFamily:'inter'}}>Loading ....</Typography>
              </span>
            </div>
          </div>
        ) : null}
        <ThemeProvider theme={theme}>
        <div style={{ minWidth: "500px", maxWidth: "100%"}}>
          <MUIDataTable
          // style={{ minWidth: "500px", maxWidth: "700px" }}
            title={
              (
                <>
                  {!exportButtons &&
                    (typeof title === "string" ? (
                      <span className="text-uppercase fw-bolder dataTableTitle">
                        {title}
                      </span>
                    ) : (
                      title
                    ))}
                  {exportButtons && (
                    <div>
                      {!notPdf ? (
                        <button
                          onClick={() => {
                            setAction("pdf=1");
                            handleClickOpen();
                          }}
                          className={"me-2 btn btn-sm btn-primary"}
                        >
                          <PictureAsPdf fontSize="small" /> PDF
                        </button>
                      ) : null}
                      {!notExcel ? (
                        <button
                          onClick={() => {
                            setAction("excel=1");
                            handleClickOpen();
                          }}
                          className={"me-2 btn btn-sm btn-primary"}
                        >
                          <FileCopy fontSize="small" /> Excel
                        </button>
                      ) : null}
                    </div>
                  )}
                </>
              ) ?? (
                <Typography style={{fontFamily:'inter'}} variant="h6">
                  List
                  {loading && (
                    <CircularProgress
                      size={24}
                      style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                  )}
                </Typography>
              )
            }
            data={originData ?? data}
            columns={columns0}
            options={options}
          />
          </div>
        </ThemeProvider>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Generate Report</DialogTitle>
          <form
            onSubmit={(e) => {
              e.preventDefault();

              tableChange(
                tableState,
                `${action}&title=${encodeURIComponent(titleText)}`
              );
              handleClose();
            }}
          >
            <DialogContent style={{ minWidth: "500px", maxWidth: "700px" }}>
              {/* <DialogContentText>
                Add report title .
              </DialogContentText> */}
              <TextField
                autoFocus
                margin="dense"
                id="name"
                name={"name"}
                value={titleText}
                onChange={(e) => setTitleText(e.target.value)}
                label="Customize your report title"
                type="text"
                fullWidth={true}
                required={true}
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type={"submit"}>Generate</Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    </div>
  );
};
