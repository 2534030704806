import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { DatatableComponent } from "../../../components/DatatableComponent";
import IconButton from "@mui/material/IconButton";
import { BorderColorOutlined } from "@mui/icons-material";
import moment from "moment/moment";
import { Tooltip } from "@mui/material";

const LevelsList = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Breadcrumb
        addButton={{ label: "Add Level", onClick: () => navigate("create") }}
      />
      <DatatableComponent
        columns={[
          {
            label: "No",
            name: "order",
          },
          {
            label: "Name",
            name: "name",
          },
          {
            label: "Created At",
            name: "createdAt",
            options: {
              customBodyRender: (v: any) =>
                moment(v).format("YYYY-MMM-DD HH:mm:ss"),
            },
          },
        ]}
        url={"level/datatable"}
        actions={(id) => [
        //   <IconButton onClick={() => navigate(`update/${id}`)}>
        //     <Update />
        //   </IconButton>,
             <Tooltip title="update" >
             <BorderColorOutlined onClick={() => navigate(`update/${id}`)} fontSize="small" />
           </Tooltip>,
        ]}
        title={"Levels List"}
        // deleteUrl={(id) => `level/delete/${id}`}
      />
    </div>
  );
};

export default LevelsList;
