import { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Card, Spinner } from "react-bootstrap";
import logo from "../assets/coat2.jpg";
import StatusAlert from "../components/StatusAlert";
import "./auth.css";
import {
  fetchDetails,
  getError,
  getSuccessMessage,
  submitGeneralForm,
} from "../services/common";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert,OutlinedInput } from "@mui/material";

function ForgotPassword() {
  const title = "Forgot Password";

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState();
  const [info, setInfo] = useState<any>();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [valid, setValid] = useState(false);

  const { id }: any = useParams();
  const navigate = useNavigate();

  const alertOpts = useRef({ isShow: false, variant: "success", message: "" });

  const handleDismiss = () => {
    alertOpts.current.isShow = false;
  };

  const handleSend = async () => {
    try {
      setIsLoading(true);
      const resp = await fetchDetails(`auth/reset-password?email=${email}`);
      if (resp.status) {
        setSuccess(getSuccessMessage(resp));
      } else {
        setError(getError(resp));
      }
      if (resp) {
        setIsLoading(false);
      }
    } catch (err: any) {
      alertOpts.current = {
        isShow: true,
        variant: "failure",
        message: err.message,
      };
      setIsLoading(false);
    }
  };

  const checkPassword = () => {
    // validate password
    if (
      info?.newPassword &&
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/.test(
        info?.newPassword
      )
    ) {
      setError("password must contain(upper/lower case, number & symbol)!");
      setValid(false);
    } else if (
      info?.newPassword &&
      info?.newPassword !== info?.confirmNewPassword
    ) {
      setError("Re-Enter your new password to confirm");
      setValid(false);
    } else {
      setError("");
      setValid(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="container-auth">
        <Card className={"shadow-sm mt-5"}>
          <div className={"card-body"}>
            <div className="col-md-12">
              <div className="text-center py-5">
                <img src={logo} alt={"National Logo"} height={80} width={80} />
              </div>
              <p className="mb-3 fw-normal text-center">
                {id === "checkEmail"
                  ? "Enter your email so that we can send a password reset to you"
                  : "Change password"}
              </p>
              {error ? <Alert severity="error">{error}</Alert> : null}
              {success ? <Alert severity="success">{success}</Alert> : null}

              {id !== "checkEmail" ? (
                <form
                  onSubmit={async (e: any) => {
                    e.preventDefault();
                    try {
                      setIsLoading(true);
                      const resp = await submitGeneralForm(
                        `/auth/reset-password`,
                        { ...info, accessToken: id },
                        null,
                        "POST"
                      );
                      if (resp.status) {
                        navigate("/login");
                      }else {
                        setError(getError(resp));
                      }
                      setIsLoading(false);
                    } catch (err: any) {
                      setError(err.message);
                      setValid(false);
                      setIsLoading(false);
                    }
                  }}
                >
                  {/* <div>
                    <label className="col-form-label text-capitalize">
                      New Password{" "}
                    </label>
                    <input
                      value={info?.newPassword}
                      className="form-control"
                      type={"password"}
                      required={true}
                      onChange={(e: any) => {
                        setInfo({ ...info, newPassword: e.target.value });
                      }}
                    />
                  </div>
                  <div>
                    <label className="col-form-label text-capitalize">
                      Confirm Password{" "}
                    </label>
                    <input
                      value={info?.confirmNewPassword}
                      required={true}
                      onChange={(e: any) => {
                        setInfo({
                          ...info,
                          confirmNewPassword: e.target.value,
                        });
                      }}
                      className="form-control"
                      type={"password"}
                    />
                  </div> */}
                  <div className={"row  my-4"}>
                    <div className={"col-md-12"}>
                      <div className="mb-2">
                        <label className="col-form-label fw-bold text-capitalize">
                          New Password
                        </label>
                        {/* <input
                          className="form-control form-control"
                          placeholder="New Password"
                          required={true}
                          value={info?.newPassword}
                          type="password"
                          onKeyUp={checkPassword}
                          onChange={(e) => {
                            setInfo({
                              ...info,
                              newPassword: e.currentTarget.value,
                            });
                          }}
                        /> */}
                           <OutlinedInput
                          fullWidth
                          className="mb-2"
                          type={"password"}
                          size="small"
                          // endAdornment={
                          //   <InputAdornment position="end">
                          //     <IconButton
                          //       aria-label="toggle password visibility"
                          //       onClick={() => {
                          //         setShowPass(!showPass);
                          //       }}
                          //       // onMouseDown={handleMouseDownPassword}
                          //       edge="end"
                          //       size="small"
                          //     >
                          //       {showPass ? (
                          //         <VisibilityOff fontSize="small" />
                          //       ) : (
                          //         <Visibility fontSize="small" />
                          //       )}
                          //     </IconButton>
                          //   </InputAdornment>
                          // }
                          required
                          onKeyUp={checkPassword}
                          value={info?.newPassword}
                          onChange={(e: any) => {
                            setInfo({
                              ...info,
                              newPassword: e.currentTarget.value,
                            });
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <label className="col-form-label fw-bold text-capitalize">
                          Confirm Password
                        </label>
                        {/* <input
                      className="form-control form-control "
                      type="password"
                      required={true}
                      value={info?.confirmNewPassword}
                      onKeyUp={checkPassword}
                      onChange={(e) => {
                        setInfo({
                          ...info,
                          confirmNewPassword: e.currentTarget.value,
                        });
                      }}
                    /> */}
                        <OutlinedInput
                          fullWidth
                          className="mb-2"
                          type={"password"}
                          size="small"
                          // endAdornment={
                          //   <InputAdornment position="end">
                          //     <IconButton
                          //       aria-label="toggle password visibility"
                          //       onClick={() => {
                          //         setShowPass(!showPass);
                          //       }}
                          //       // onMouseDown={handleMouseDownPassword}
                          //       edge="end"
                          //       size="small"
                          //     >
                          //       {showPass ? (
                          //         <VisibilityOff fontSize="small" />
                          //       ) : (
                          //         <Visibility fontSize="small" />
                          //       )}
                          //     </IconButton>
                          //   </InputAdornment>
                          // }
                          required
                          onKeyUp={checkPassword}
                          value={info?.confirmNewPassword}
                          onChange={(e: any) => {
                            setInfo({
                              ...info,
                              confirmNewPassword: e.currentTarget.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <Button
                    className="w-100 btn btn-lg btn-primary mt-3"
                    type="submit"
                    disabled={isLoading || !valid}
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      hidden={!isLoading}
                    />
                    <span className="px-2">Submit</span>
                  </Button>
                </form>
              ) : (
                <form
                  onSubmit={(e: any) => {
                    e.preventDefault();
                    handleSend();
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    required={true}
                    onChange={(e: any) => {
                      setEmail(e.target.value);
                    }}
                  />

                  <Button
                    className="w-100 btn btn-lg btn-primary mt-3"
                    type="submit"
                    disabled={isLoading}
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      hidden={!isLoading}
                    />
                    <span className="px-2">Reset Password</span>
                  </Button>
                </form>
              )}
              <div className="mb-3">
                <Link to="/login" className="color">
                  Back to login
                </Link>
              </div>
            </div>
            <div className="col-md-3" />
          </div>
        </Card>
      </main>
      <StatusAlert
        show={alertOpts.current.isShow}
        variant={alertOpts.current.variant}
        message={alertOpts.current.message}
        onDismiss={handleDismiss}
      />
    </>
  );
}

export default ForgotPassword;
