import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { Col, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import {
  fetchDetails,
  guaranteeTypes,
  validateFileNotNull,
} from "../../../services/common";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/helpers/mapState";
import { mapDispatchToProps } from "../../../store/helpers/mapDispatch";
import { AddForm } from "../../employee/AddForm";
import moment from "moment";
import { Alert, AlertTitle, CircularProgress } from "@mui/material";
import FileUpload from "../../../components/FileUploader";

const CreateLoan = (props: any) => {
  const { loggedUser, GetLoggedUser } = props;
  const navigate = useNavigate();
  const [info, setInfo] = React.useState<any>();
  const [loanType, setLoanType] = React.useState<any>([]);
  const [type, setType] = React.useState<any>();
  const [allMembers, setAllMembers] = React.useState<any>([]);
  const [inPayment, setInPayment] = React.useState<any>();
  const [terms, setTerm] = React.useState<any>();
  const [contractFile, setContractFile] = React.useState<any>([]);
  const [partial, setPartial] = React.useState<any>("no");
  const [outStandingInterest, setOutStandingInterest] = React.useState<any>();
  const [myLoan, setMyLoan] = React.useState<any>(0);
  const [amount, setAmount] = React.useState<any>(0);
  const [eligibleForTopUp, setEligibleForTopUp] = React.useState<any>(false);
  const [month, setMonth] = React.useState<any>(0);
  const [collateral, setCollateral] = React.useState<any>();
  const [hasPendingLoan, setHasPendingLoan] = React.useState<any>(false);
  const [loading, setLoading] = React.useState<any>(false);

  const isInService =
    loggedUser?.Employee?.status !== "exited" &&
    loggedUser?.Employee?.status !== "retired";
  const selectedLoanType = loanType?.find(
    (category: any) => category?.value === type
  );

  const { id } = useParams();
  // const currentSalary = loggedUser?.Employee?.CaisseMember?.currentSalary;
  const hasLoan = loggedUser?.Employee?.CaisseMember?.hasLoan;
  const hasIngoboka = loggedUser?.Employee?.CaisseMember?.hasIngoboka;
  const hasTopUpLoan = loggedUser?.Employee?.CaisseMember?.hasTopUpLoan;
  // const hasPendingLoan = loggedUser?.Employee?.CaisseMember?.hasPendingLoan;

  // inital checks===================================
  const eligibleForLoan = moment().diff(
    moment(loggedUser?.Employee?.CaisseMember?.joinedAt),
    "months"
  );
  const hasIncreaseShare =
    selectedLoanType?.shareIncreaseTime > 0 &&
    moment().diff(
      moment(loggedUser?.Employee?.CaisseMember?.increaseShareDate),
      "months"
    ) >= selectedLoanType?.shareIncreaseTime
      ? true
      : false; //can increase share in given months

  // let maxAmountToTake = loggedUser?.Employee?.CaisseMember?.totalShare * 4;
  let myShare = loggedUser?.Employee?.CaisseMember?.totalShare;

  let share = loggedUser?.Employee?.CaisseMember?.totalShare;
  share =
    selectedLoanType?.name === "long_term" || selectedLoanType?.name === "topup"
      ? myShare * 4
      : selectedLoanType?.maxAmount !== 0
      ? selectedLoanType?.maxAmount
      : null;
  // end checks============================================
  const fetchInfoDetails = async () => {
    const resp = await fetchDetails(`loan/view/${id}`);
    if (resp.status) {
      setInfo({ ...resp.data, insuredBy: resp.data?.LoanInsurer?.insuredBy });
      setType(resp.data?.loanTypeId);
      setAmount(resp.data?.amount);
      setTerm(resp.data?.terms);
      setPartial(resp.data?.partial);
      setOutStandingInterest(resp.data?.outStandingInterest);
      setCollateral(resp.data?.collateralType);
      setInPayment(info?.inPayment);
      setLoanType([resp.data?.LoanType]);
    }
  };
  const fetchLoanType = async () => {
    setLoading(true);
    const resp = await fetchDetails(`loan/view-loan-type`);

    //get pending loans
    const response = await fetchDetails(
      `loan/search?info=${JSON.stringify({
        status: "pending",
        memberId: loggedUser?.Employee?.CaisseMember?.id,
      })}`
    );

    if (response.status) setHasPendingLoan(response?.data);
    if (resp.status) {
      let loanAllowed =
        hasLoan && !hasTopUpLoan && !hasIngoboka
          ? resp.data?.filter(
              (item: any) => item?.name === "topup" || item?.name === "ingoboka"
            )
          : hasLoan && !hasTopUpLoan && hasIngoboka
          ? resp.data?.filter((item: any) => item?.name === "topup")
          : hasLoan && hasTopUpLoan && !hasIngoboka
          ? resp.data?.filter((item: any) => item?.name === "ingoboka")
          : resp.data?.filter(
              (item: any) => item?.name !== "ingoboka" && item?.name !== "topup"
            );
      // loanAllowed=loanAllowed?.filter ((item:any)=>item.name!=='long-term'&&hasIncreaseShare<month)
      setLoanType(
        loanAllowed?.map((item: any) => ({
          ...item,
          // label:item,
          value: item?.id,
        }))
      );

      setMonth(loanAllowed[0]?.loanEligible);

      const longTerm = resp.data?.find(
        (category: any) => category.name === "long_term"
      );

      const topUp = resp.data?.find(
        (category: any) => category.name === "topup"
      );
      const loan = await fetchDetails(
        `loan/search?info=${JSON.stringify({
          loanTypeId: longTerm?.id,
          isApproved: true,
          status: true,
          memberId: loggedUser?.Employee?.CaisseMember.id
        })}`
      );

      if (loan?.data?.length) {
        const remaining =
          loan?.data[0]?.remainingAmount;
        const eligible =
          (loan?.data[0]?.amountToPay * topUp?.eligibleForTopUp) / 100; //who is eligible for topup must have to pay at least 50% of long-term loan

          setEligibleForTopUp(loan?.data[0]?.amountPaid >= eligible);

        setMyLoan(remaining);
      }
      setLoading(false);
    }
  };
  const getAllCDEMember = async () => {
    const resp = await fetchDetails(`caise/view/`);
    if (resp.status) {
      setAllMembers(
        resp.data?.map((item: any) => ({
          label: `${item?.employee?.names} - ID : ${item?.employee?.nationalId}`,
          value: item?.id,
        }))
      );
    }
  };
  useEffect(() => {
    // if (id) {
    GetLoggedUser(loggedUser?.id);
    fetchLoanType().then();
    // }
  }, []);

  useEffect(() => {
    if (id) {
      fetchInfoDetails().then();
    }
  }, [id]);

  useEffect(() => {
    if (selectedLoanType?.name === "topup" && info?.id) {
      const amt = info?.amount ?? 0;
      setAmount(Number(amt) - Number(myLoan));
    }
  }, [info?.id, selectedLoanType, myLoan]);

  useEffect(() => {
    if (collateral === "cdeMember") {
      getAllCDEMember();
    }
  }, [collateral]);

  useEffect(() => {
    if (type || amount || terms || selectedLoanType) {
      let termsInyear = terms / 12; //year in terms
      termsInyear = Math.ceil(termsInyear * Math.pow(10, 0)) / Math.pow(10, 0);

      const interestRate = selectedLoanType?.interestRate * termsInyear;
      const loanSecurity = selectedLoanType?.loanSecurity; //loan security
      const newAmount =
        selectedLoanType?.name === "topup"
          ? Number(amount) + Number(myLoan)
          : Number(amount);
      const interest = (newAmount * interestRate) / 100;
      const amount2 = newAmount + interest;
      const securityAmount =
        newAmount <= 10000000 ? (newAmount * loanSecurity) / 100 : 100000;
      const charges = interest + securityAmount;
      const monthlyPayment = amount2 / terms;
      const monthlyPayment2 = newAmount / terms;

      setInfo({
        ...info,
        interestRate: interestRate,
        monthlyAmount:
          Math.ceil(monthlyPayment * Math.pow(10, 0)) / Math.pow(10, 0),
        amountToPay: newAmount,
        interestAmount: interest,
        amountReceived: newAmount - charges,
        securityAmount,
        newMonthlyAmount:
          Math.ceil(monthlyPayment2 * Math.pow(10, 0)) / Math.pow(10, 0), //after all deduction
      });
      if (
        (selectedLoanType?.needCollateral && amount <= myShare) ||
        (!selectedLoanType?.needCollateral&&!info?.id)
      ) {
        setCollateral(null);
      }
    }
  }, [amount, type, terms, selectedLoanType]);

  useEffect(() => {
    if (outStandingInterest) {
      const outStandingPayment =
        (Number(info?.newMonthlyAmount) * Number(outStandingInterest)) / 100;
      const inPayment = info?.newMonthlyAmount - outStandingPayment;

      // setTotalAmount(amount2);
      setInfo({
        ...info,
        outStandingInterest,
        outStandingPayment:
          Math.ceil(outStandingPayment * Math.pow(10, 0)) / Math.pow(10, 0),
        inPayment: Math.ceil(inPayment * Math.pow(10, 0)) / Math.pow(10, 0),
      });
      setInPayment(Math.ceil(inPayment * Math.pow(10, 0)) / Math.pow(10, 0));
    }
  }, [info, outStandingInterest]);

  return (
    <div>
      <Breadcrumb />
      <Row className={"justify-content-center"}>
        {loading ? (
          <CircularProgress size={12} />
        ) : (
          <Col md={12}>
            {eligibleForLoan < month ||
            (!hasIncreaseShare && selectedLoanType?.shareIncreaseTime > 0) ||
            (hasPendingLoan?.length > 0 && !id) ||
            (hasLoan && !id && hasIngoboka && hasTopUpLoan) ? (
              <Alert severity="warning" style={{ marginTop: "10vh" }}>
                <AlertTitle>Pre-requisite conditions</AlertTitle>
                <ul>
                  <li>
                    Your are not allowed to request for loan at this moment,You need to contribute for at least {month} months in CDE.{" "}
                  </li>
                  <li>
                  If you're requesting a long-term loan, you should have increased your shares over at least {selectedLoanType?.shareIncreaseTime}{" "}
                    months.
                  </li>
                  <li>You should not have any unpaid loans (ordinary or long-term)</li>
                  <li>You should not have any pending loans.</li>
                </ul>
                <strong
                  role="button"
                  onClick={() => {
                    if (selectedLoanType?.shareIncreaseTime > 0) {
                      navigate(0);
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  {"Click here to Go Back"}
                </strong>
              </Alert>
            ) : myLoan === 0 && selectedLoanType?.name === "topup" ? (
              <Alert severity="warning" style={{ marginTop: "10vh" }}>
                <AlertTitle>Pre-requisite conditions</AlertTitle>
                <ul>
                  <li>
                    You are not allowed to request a top-up loan at this moment.
                    You need to have an unpaid long-term loan.
                  </li>
                </ul>
                <strong role="button" onClick={() => setType(undefined)}>
                  {"Back to form"}
                </strong>
              </Alert>
            ) : (
              <AddForm
                formAlert={
                  selectedLoanType ? (
                    <div>
                      <strong className="mb-3">
                        NB:{selectedLoanType?.label}
                      </strong>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: selectedLoanType?.description,
                        }}
                      />
                    </div>
                  ) : null
                }
                inline={true}
                col={6}
                mainFields={[
                  {
                    title: "",
                    fields: [
                      {
                        label: "Status",
                        name: "status",
                        value:
                          info?.status === "request action"
                            ? info?.previousStatus
                            : info?.status,
                        hidden: true,
                      },
                      {
                        label: "Type",
                        name: "loanTypeId",
                        type: "select",
                        value: info?.loanTypeId,
                        setValue: setType,
                        values:
                          !eligibleForTopUp && hasTopUpLoan
                            ? loanType?.filter((v: any) => v?.name !== "topup")
                            : loanType,
                        required: true,
                      },

                      {
                        // label: "Loan Amount(RWF)",
                        label: `Loan Amount ${
                          share
                            ? `(Do not exceed ${share?.toLocaleString()} RWF)`
                            : "RWF"
                        }`,
                        name: "amount2",
                        type: "number",
                        value: amount,
                        disabled:
                          info?.id &&
                          info?.status !== "pending" &&
                          info?.status !== "request action",
                        required: true,
                        setValue: setAmount,
                        max: share,
                        min: selectedLoanType?.name === "topup" ? myLoan : null,
                      },
                      {
                        label:
                          "Total amount include unpaid amount of your loan",
                        name: "amount",
                        // setValue: setAmount2,
                        value:
                          selectedLoanType?.name === "topup"
                            ? Number(amount) + Number(myLoan)
                            : amount, //when is topup,add remaining on amount requested
                        disabled: true,
                        hidden: selectedLoanType?.name !== "topup",
                        max: share,
                      },

                      {
                        label: `Period in months ${
                          selectedLoanType?.period
                            ? `(Not exceed-${selectedLoanType?.period} month(s))`
                            : ""
                        }`,
                        name: "terms",
                        type: "number",
                        value: terms,
                        disabled:
                          info?.id &&
                          info?.status !== "pending" &&
                          info?.status !== "request action",
                        col: 6,
                        max: selectedLoanType?.period,
                        setValue: setTerm,
                      },
                      {
                        label: "Interest rate(%)",
                        name: "interestRate",
                        type: "number",
                        value: info?.interestRate,
                        disabled: true,
                        col: 6,
                      },
                      // {
                      //   label: "First Payment",
                      //   name: "firstPayment",
                      //   type: "date",
                      //   value: firstPayment,
                      //   required: true,
                      //   disabled:
                      //     info?.id &&
                      //     info?.status !== "pending" &&
                      //     info?.status !== "request action",
                      //   col: 6,
                      //   minDate: moment().startOf("day"),
                      //   setValue: setFirstPayment,
                      // },
                      // {
                      //   label: "Last Payment",
                      //   name: "lastPayment",
                      //   type: "date",
                      //   value: lastPayment,
                      //   disabled: true,
                      //   col: 6,
                      //   setValue: setLastPayment,
                      // },
                      {
                        label: "Partial Payment",
                        name: "partial",
                        hidden: !isInService,
                        type: "select",
                        values: [
                          {
                            label: "yes",
                            value: "yes",
                          },
                          {
                            label: "no",
                            value: "no",
                          },
                        ],
                        required: true,
                        value: partial,
                        col: partial === "yes" ? 6 : 12,
                        setValue: setPartial,
                      },
                      {
                        label: "Outstanding rate%(of monthly amount)",
                        name: "outStandingInterest",
                        type: "number",
                        required: true,
                        value: info?.outStandingInterest,
                        hidden: partial === "no",
                        col: 6,
                        max: 80,
                        setValue: setOutStandingInterest,
                      },
                      {
                        label: "Monthly Installment(RWF)",
                        name: "newMonthlyAmount",
                        type: "number",
                        value: info?.monthlyAmount,
                        hidden: !terms,
                        disabled: true,
                        col: 12,
                      },

                      {
                        label: "GuaranteeTypes",
                        name: "collateralType",
                        hidden:
                          (selectedLoanType?.needCollateral &&
                            amount <= myShare) ||
                          !selectedLoanType?.needCollateral,
                        type: "select",
                        values: guaranteeTypes,
                        required: true,
                        value: collateral,
                        col: 12,
                        setValue: setCollateral,
                      },
                      {
                        label: "Specify other collateral",
                        name: "otherCollateral",
                        hidden: collateral !== "other",
                        type: "editor",
                        required: true,
                        value:
                          collateral === "other" ? info?.otherCollateral : null,
                        col: 12,
                      },
                      {
                        label: "Insurer Name",
                        name: "insuredBy",
                        hidden: collateral !== "cdeMember",
                        type: "select",
                        values: allMembers,
                        required: true,
                        value:
                          collateral === "cdeMember"
                            ? info?.insuredBy: null,
                        col: 12,
                      },
                      {
                        label: "UPI",
                        name: "landUpi",
                        value:
                          collateral === "collateral" ? info?.landUpi : null,
                        hidden: collateral !== "collateral",
                        required: true,
                        col: 6,
                      },
                      {
                        label: "UPI owners",
                        name: "landUpiOwner",
                        value:
                          collateral === "collateral"
                            ? info?.landUpiOwner
                            : null,
                        hidden: collateral !== "collateral",
                        required: true,
                        col: 6,
                      },
                      {
                        label: "UPI address",
                        name: "landUpiAddress",
                        value:
                          collateral === "collateral"
                            ? info?.landUpiAddress
                            : null,
                        hidden: collateral !== "collateral",
                        required: true,
                        col: 6,
                      },
                      {
                        label: "Land Usage",
                        name: "landUsage",
                        value:
                          collateral === "collateral" ? info?.landUsage : null,
                        hidden: collateral !== "collateral",
                        required: true,
                        col: 6,
                      },
                    ],
                  },
                  {
                    title:
                      "Loan amount description after removing loan security",
                    fields: [
                      {
                        label: "Total Amount To be paid(RWF)",
                        name: "amountToPay",
                        type: "number",
                        value: info?.amountToPay,
                        hidden: !terms,
                        disabled: true,
                        col: 6,
                      },
                      {
                        label: `Security Amount(${selectedLoanType?.loanSecurity}% of total loan not greater than 10 Million)`,
                        name: "securityAmount",
                        type: "number",
                        value: info?.securityAmount,
                        hidden: !selectedLoanType?.loanSecurity,
                        disabled: true,
                        col: 6,
                      },
                      {
                        label: "Total Interest amount",
                        name: "interestAmount",
                        type: "number",
                        value: info?.interestAmount,
                        hidden: !terms,
                        disabled: true,
                        col: 6,
                      },
                      {
                        label: "Take home",
                        name: "amountReceived",
                        type: "number",
                        value: info?.amountReceived,
                        hidden: !terms,
                        disabled: true,
                        col: 6,
                      },
                      {
                        label: "Monthly Installment(RWF)",
                        name: "monthlyAmount",
                        type: "number",
                        value: info?.newMonthlyAmount,
                        hidden: !terms,
                        disabled: true,
                        col: 12,
                        // max:partial === "no"?currentSalary:inPayment
                      },
                      {
                        label: "From Salary",
                        name: "inPayment",
                        type: "number",
                        value: partial === "yes" ? info?.inPayment : null,
                        hidden: partial === "no",
                        disabled: true,
                        col: 6,
                        setValue: setInPayment,
                      },
                      {
                        label: "Out Payment",
                        name: "outStandingPayment",
                        type: "number",
                        value:
                          partial === "yes" ? info?.outStandingPayment : null,
                        hidden: partial === "no",
                        disabled: true,
                        col: 6,
                      },
                    ],
                  },
                  {
                    title:
                      info?.status === "contract drafting"
                        ? "Upload Signed contract  "
                        : "",
                    fields: [
                      {
                        label: "Loan Contract",
                        name: "contractFile",
                        type: "file",
                        multiple: true,
                        required: !info?.contractFile,
                        hidden: info?.status !== "contract drafting",
                        col: 12,
                        value: contractFile,
                        validate: validateFileNotNull,
                        render: () => {
                          return (
                            <FileUpload
                              setFiles={setContractFile}
                              files={contractFile}
                              required={!info?.contractFile}
                              title="Upload"
                              currentFile={info?.contractFile}
                            />
                          );
                        },
                      },
                      {
                        label: "Reason",
                        name: "reason",
                        type: "textarea",
                        value: info?.reason,
                        required: true,
                        col: 12,
                      },
                      {
                        label: "Any comment on the action requested?",
                        name: "comment",
                        type: "textarea",
                        hidden: info?.status !== "request action",
                        col: 12,
                        value: info?.comment,
                      },
                    ],
                  },
                ]}
                url={id ? `loan/update/${id}` : "loan/create"}
                method={id ? "PUT" : "POST"}
                title={id ? "Update Loan" : "Request For Loan"}
                onSuccess={() => {
                  navigate(-1);
                  GetLoggedUser(loggedUser?.id);
                }}
              />
            )}
          </Col>
        )}
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateLoan);
