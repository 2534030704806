import { JSX, useState } from "react";
import { statusList } from "../../../services/common";
import moment from "moment";
import { Chip } from "@mui/material";
import ReportEngineComponent from "../../../components/ReportEngineComponent";

function ServiceRequestReport(): JSX.Element {
  const [value, setValue] = useState<any>({});

  // const loadInitialData = async () => {
  //   const employeeList = await fetchDetails("/employee/view");
  //   if (employeeList.status) setRequesters(employeeList?.data);
  // };

  // useEffect(() => {
  //   loadInitialData().then();
  // }, []);

  return (
    <ReportEngineComponent
      setValue={setValue}
      value={value}
      title={"Self Service Report"}
      url={`report/service`}
      columns={[
        {
          label: "Emp. ID",
          name: "requestedBy.Employee.employeeNumber",
          data: "requester",
        },
        {
          label: "Emp. Names",
          name: "requestedBy.Employee.names",
          data: "requester",
        },
        {
          label: "Document Requested",
          name: "requestType",
          type: "array",
          options: {
            customBodyRender: (v: any) => (
              <span className="text-capitalize  ">
                {v?.map((v: any) => v).join(", ")}
              </span>
            ),
          },
        },
        {
          label: "Reason",
          name: "reason",
          options: {
            customBodyRender: (v: any) => (
              <span className="text-capitalize  ">{v}</span>
            ),
          },
        },
        // {
        //   label: "From Date",
        //   name: "fromDate",
        //   type: "date",
        //   options: {
        //     customBodyRender: (v: any) =>
        //       v ? moment(v).format("YYYY-MMM-DD") : "",
        //   },
        // },
        // {
        //   label: "To Date",
        //   name: "toDate",
        //   type: "date",
        //   options: {
        //     customBodyRender: (v: any) =>
        //       v ? moment(v).format("YYYY-MMM-DD") : "",
        //   },
        // },
        {
          label: "Status",
          name: "status",
          options: {
            customBodyRender: (v: any) => <Chip label={v} size={"small"} />,
          },
        },
        {
          label: "Date",
          name: "createdAt",
          type: "date",
          options: {
            customBodyRender: (v: any) => moment(v).format("YYYY-MMM-DD"),
          },
        },
      ]}
      filters={[
        // {
        //   label: "Requester",
        //   name: "requester",
        //   values: requesters.map(({ names, user }: any) => ({
        //     label: names,
        //     value: user?.id,
        //   })),
        // },
        {
          label: "Employee Name/ID",
          name: "empName",
          type: "text",
        },
        {
          label: "Status",
          name: "status",
          values: statusList,
        },

        {
          label: "Start Date",
          name: "startDate",
          type: "date",
          required: true,
        },
        {
          label: "End Date",
          name: "endDate",
          type: "date",
          required: true,
        },
      ]}
    />
  );
}

export default ServiceRequestReport;
