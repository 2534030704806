import { Link } from "@mui/material";

function Footer() {
  return (
    <footer className="my-4 text-center text-muted small" >
      Copyright&nbsp;©{new Date().getFullYear()}&nbsp;<Link href="https://qtglobal.rw/" target="_blank" className="text-muted" underline="hover">QT Global Software</Link>.
    </footer>
  );
}

export default Footer;
