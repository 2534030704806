import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {useEffect, useState} from "react";
import {ValueItem} from "../pages/employee/AddForm";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

type IProps = {
    values:Array<ValueItem> | undefined | null,
    value:Array<any> | string,
    disabled:boolean | undefined | null,
    onChange:(value:string)=>void,
}
function MultipleSelectCheckmarks(props:IProps) {
    const {values,value,onChange,disabled} = props;
    const [list,setList] = useState<Array<any>>([]);

    useEffect(()=>{
        if(value){
            if(typeof value === 'string'){
                setList(JSON.parse(value));
            }else if(Array.isArray(value)){
                setList([...value]);
            }
        }
    },[value]);
    const handleChange = (event:any) => {
        const {
            target: { value },
        } = event;
        setList(value);

        if(typeof onChange === 'function'){
            onChange(JSON.stringify(value));
        }
    };

    return (
        <div>
            <FormControl fullWidth={true} size={'small'}>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={list}
                    disabled={disabled??undefined}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.map(v=>values?.find(item=>item.value === v)?.label).join(",")}
                    MenuProps={MenuProps}
                >
                    {values?.map(({label,value}) => (
                        <MenuItem key={value} value={value}>
                            <Checkbox checked={list.indexOf(value) > -1} />
                            <ListItemText primary={label} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

export default MultipleSelectCheckmarks;