import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { fetchDetails, monthList } from "../../../services/common";
import Breadcrumb from "../../../components/Breadcrumb";
import { Button, Col, Row } from "react-bootstrap";
import { AddForm } from "../../employee/AddForm";
import { TabPanel } from "../../employee/leave/List";
import TabHeadersComponent from "../../../components/UI/Tabs";
import {
  Add,
  BorderColor,
  FormatLineSpacing,
  PeopleAlt,
  Settings,
  SpeedRounded,
} from "@mui/icons-material";
import { Card, CardContent, CardHeader } from "@mui/material";
import { CustomizedMenus } from "../../../components/customizeMenu";
import { DatatableComponent } from "../../../components/DatatableComponent";
import GeneralButton from "../../../components/Button";
import DeleteDialog from "../../../components/DeleteDialog";
import ExpenseCategoryList from "../expenses/Categories";
import FileUpload from "../../../components/FileUploader";

const UpdateGeneralSettings = () => {
  const navigate = useNavigate();
  const [info, setInfo] = React.useState<any>();
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const [edit, setEdit] = useState<boolean>(false);
  const [addLoanType, setAddLoanType] = useState(0);
  const [type, setType] = useState<any>();
  const [loanInfo, setLoanInfo] = useState<any>({});
  const [list, setList] = useState<Array<any>>([]);
  const [change, setChange] = useState(0);
  const [canTopUp, setCanTopUp] = useState<any>("no");
  const [needShareIncrease, setNeedShareIncrease] = useState<any>("no");
  const [openCategory, setOpenCategory] = useState(0);
  const [deleteOpen, setDeleteOpen] = useState(0);
  const [addFund, setAddFund] = useState(0);
  const [fundInfo, setFundInfo] = useState<any>({});
  const [files, setFiles] = React.useState<any>([]);
  const [deleteActionUrl, setDeleteActionUrl] = useState<
    string | undefined | null
  >();
  const reload = () => {
    setChange((v) => v + 1);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const fetchCdeInfo = async () => {
    const resp = await fetchDetails(`cde/active/summary`);
    if (resp.status) {
      setInfo(resp.data);
    }
  };

  useEffect(() => {
    fetchCdeInfo().then();
  }, []);
  const labels = [
    { label: "General", icon: <Settings /> },
    { label: "CDE Statute", icon: <FormatLineSpacing /> },
    { label: "Loan", icon: <Settings /> },
    { label: "Social Fund", icon: <PeopleAlt /> },
    { label: "Expense Categories", icon: <SpeedRounded /> },

  ];

  return (
    <div>
      <Breadcrumb
        endElement={
          <>
            {value === 2 ? (
              <GeneralButton
                variant="outlined"
                size="medium"
                name={"New Type"}
                icon={<Add />}
                onClick={async () => {
                  setAddLoanType(1);
                }}
              />
            ) : null}
            {value === 3 ? (
              <GeneralButton
                variant="outlined"
                size="medium"
                name={"New Fund"}
                icon={<Add />}
                onClick={() => {
                  setAddFund(1);
                }}
              />
            ) : null}
            {value===4?
                <GeneralButton
                name="record new"
                onClick={() => {
                  setOpenCategory(1);
                  setInfo({});
                }}
              />
            :null}
          </>
        }
      />{" "}
      <Row>
        <Col md={12}>
          <TabHeadersComponent
            handleChange={handleChange}
            value={value}
            labels={labels}
          />
          <TabPanel value={value} p={0} index={0}>
            <AddForm
              inline={true}
              col={12}
              mainFields={[
                {
                  title: "Contribution",
                  fields: [
                    {
                      label: "Start Date",
                      name: "generalContributionDate",
                      type: "number",
                      value: info?.generalContributionDate,
                      min: 1,
                      max: 31,
                      required: true,
                    },
                    {
                      label: "End Date",
                      name: "generalContributionEndDate",
                      type: "number",
                      value: info?.generalContributionEndDate,
                      min: 1,
                      max: 31,
                      required: true,
                    },
                  ],
                },
              ]}
              url={"cde/settings/update"}
              method={id ? "PUT" : "POST"}
              title={"General Settings"}
              onSuccess={() => navigate(-1)}
            />
          </TabPanel>
          <TabPanel value={value} p={0} index={1}>
            {edit ? (
              <AddForm
                inline={true}
                col={12}
                mainFields={[
                  {
                    title: "",
                    fields: [
                      {
                        label: "Statute File",
                        name: "supportingDocument",
                        type: "file",
                        multiple: true,
                        required:!info?.statuteFile,
                        col: 12,
                        value: files,
                        render: () => {
                          return (
                            <FileUpload
                              setFiles={setFiles}
                              files={files}
                              required={!info?.statuteFile}
                              currentFile={info?.statuteFile}
                            />
                          );
                        },
                      },
                      {
                        label: "",
                        name: "statute",
                        type: "editor",
                        value: info?.statute,
                        required: true,
                      },
                    ],
                  },
                ]}
                url={"cde/statute/update"}
                method={id ? "PUT" : "POST"}
                title={"CDE Statute"}
                onSuccess={() => {setEdit(false)}}
              />
            ) : (
              <Card className="bg-white">
                <CardHeader
                  subheader={
                    <Button onClick={() => {setEdit(true);fetchCdeInfo().then()}}>
                      <BorderColor className="me-2" />
                      Update
                    </Button>
                  }
                />
                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                    <div dangerouslySetInnerHTML={{ __html: info?.statute }} />
                    </div>
                  </div>
                </CardContent>
              </Card>
            )}
          </TabPanel>
          <TabPanel value={value} p={0} index={2}>
            <DatatableComponent
              columns={[
                {
                  label: "Type",
                  name: "label",
                  // options: {
                  //   customBodyRender: (v: any) => (
                  //     <span className="text-capitalize">{v}</span>
                  //   ),
                  // },
                },
                {
                  label: "Rate(%)",
                  name: "interestRate",
                  // options: {
                  //   customBodyRender: (v: any) => (
                  //     <span >{v}</span>
                  //   ),
                  // },
                },
                {
                  label: "Period(mths)",
                  name: "period",
                  options: {
                    customBodyRender: (v: any) => <span>{v}</span>,
                  },
                },
                {
                  label: "Max Amount",
                  name: "maxAmount",
                  options: {
                    //   filter: true,
                    //   sort:true,
                    customBodyRender: (v: any) => v?.toLocaleString(),
                  },
                },
                {
                  label: "Eligible to request(mths)",
                  name: "loanEligible",
                },
                {
                  label: "Eligible for top-up(%)",
                  name: "eligibleForTopUp",
                  options: {
                    //   filter: true,
                    //   sort:true,
                    customBodyRender: (v: any) => v?.toLocaleString(),
                  },
                },
                {
                  label: "Security(%)",
                  name: "loanSecurity",
                },
                {
                  label: "Share increase(mths)",
                  name: "shareIncreaseTime",
                },
                {
                  name: "id",
                  label: "Action",
                  options: {
                    filter: true,
                    sort: false,
                    customBodyRenderLite: (id: any, index: any) => {
                      return (
                        <CustomizedMenus
                          onEdit={() => {
                            setAddLoanType(1);
                            setLoanInfo(list[index]);
                            setNeedShareIncrease(
                              loanInfo?.shareIncreaseTime > 0 ? "yes" : "no"
                            );
                            setCanTopUp(
                              loanInfo?.eligibleForTopUp > 0 ? "yes" : "no"
                            );
                          }}
                          needEdit={true}
                        />
                      );
                    },
                  },
                },
              ]}
              url={`approvalLoan/datatable/types?change=${change}`}
              title={"Loans Settings"}
              processData={(data: any) => {
                setList(data.data);
                return data;
              }}
            />
          </TabPanel>
          <TabPanel value={value} p={0} index={3}>
            <DatatableComponent
              columns={[
                {
                  label: "Type",
                  name: "name",
                  options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (v: any) => (
                      <span className="text-capitalize">{v}</span>
                    ),
                  },
                },
                {
                  label: "Amount",
                  name: "amount",
                  options: {
                    customBodyRender: (v: any) => v?.toLocaleString(),
                  },
                },
                {
                  label: "Description",
                  name: "description",
                  options: {
                    customBodyRender: (v: any) => v?.toLocaleString(),
                  },
                },
                {
                  name: "id",
                  label: "Action",
                  options: {
                    filter: true,
                    sort: false,
                    customBodyRenderLite: (id: any, index: any) => {
                      return (
                        <CustomizedMenus
                          onEdit={() => {
                            setFundInfo(list[index]);
                            setAddFund(1);
                          }}
                          onDelete={() => {
                            setDeleteOpen(1);
                            setDeleteActionUrl(
                              `social-fund/delete-type-of-fund/${list[index]?.id}`
                            );
                          }}
                          needEdit={true}
                          needDelete={true}
                        />
                      );
                    },
                  },
                },
              ]}
              url={`social-fund/datatable/types?change=${change}`}
              title={"Types of Social Funds "}
              processData={(data: any) => {
                setList(data.data);
                return data;
              }}
            />
          </TabPanel>
          <TabPanel value={value} p={0} index={4}>
            <ExpenseCategoryList
              open={openCategory}
              setOpen={setOpenCategory}
              setDeleteOpen={setDeleteOpen}
              setDeleteActionUrl={setDeleteActionUrl}
              setChange={setChange}
              change={change}
              setValue={setValue}
            />
          </TabPanel>
        </Col>
      </Row>
      {/* ==========================loan configurations=============================== */}
      <AddForm
        title={loanInfo?.id ? "Update Loan Type" : "Add New Loan Type"}
        maxWidth="lg"
        mainFields={[
          {
            title: "",
            fields: [
              {
                label: "NewType",
                name: "newType",
                col: 12,
                hidden: true,
                value: "yes",
              },
              {
                label: "Name",
                name: "name",
                col: 12,
                required: true,
                value: loanInfo?.name,
                setValue: setType,
              },
              {
                label: "Label",
                name: "label",
                col: 12,
                required: true,
                value: loanInfo?.label,
              },
              {
                label: "Interest Rate(%)",
                name: "interestRate",
                type: "number",
                required: true,
                col: 12,
                value: loanInfo?.interestRate,
                max: 100,
              },
              {
                label: "Maximum Period(mths)",
                name: "period",
                type: "number",
                required: true,
                col: 12,
                value: loanInfo?.period,
              },
              {
                label: "Maximum Amount",
                name: "maxAmount",
                type: "number",
                col: 12,
                value: loanInfo?.maxAmount,
              },
              {
                label: "Loan Security(%)",
                name: "loanSecurity",
                type: "number",
                col: 12,
                value: loanInfo?.loanSecurity,
                max: 100,
                min: 0,
              },
              {
                label: "Borrower need to Contribute(in mths)",
                name: "loanEligible",
                type: "number",
                col: 12,
                value: loanInfo?.loanEligible,
                min: 1,
              },
              {
                label: "With this loan borrower Can take a top-up?",
                name: "canTopUp",
                type: "select",
                col: 12,
                value: canTopUp,
                values: [
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ],
                setValue: setCanTopUp,
              },
              {
                label: "Enter max amount need to pay for long term loan (in %)",
                name: "eligibleForTopUp",
                type: "number",
                col: 12,
                value: loanInfo?.eligibleForTopUp,
                hidden: type === "topup" || canTopUp === "no",
                max: 100,
                min: 50,
              },
              {
                label: "Need share increase?",
                name: "needShareIncrease",
                type: "select",
                col: 12,
                value: needShareIncrease,
                values: [
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ],
                setValue: setNeedShareIncrease,
              },
              {
                label: "Min months of share increase required(mths)",
                name: "shareIncreaseTime",
                type: "number",
                col: 12,
                value: loanInfo?.shareIncreaseTime,
                hidden: needShareIncrease === "no",
                min: 1,
              },
              {
                label: "Need Collateral",
                name: "needCollateral",
                type: "select",
                col: 12,
                value: loanInfo?.needCollateral?'yes':'no',
                values: [
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ],
              },
              {
                label: "Description",
                name: "description",
                type: "editor",
                col: 12,
                required: true,
                value: loanInfo?.description,
              },
            ],
          },
        ]}
        url={`approvalLoan/loan-type/${loanInfo?.id}`}
        method={"PUT"}
        onSuccess={() => {
          setAddLoanType(0);
          setLoanInfo({});
          setValue(2);
          reload();
        }}
        open={addLoanType}
        setOpen={() => setAddLoanType(0)}
      />
      <AddForm
        title={fundInfo?.id ? "Update Social Funds" : "Add New Social Funds"}
        maxWidth="md"
        mainFields={[
          {
            title: "",
            fields: [
              {
                label: "Name",
                name: "name",
                col: 12,
                required: true,
                value: fundInfo?.name,
              },
              {
                label: "Amount",
                name: "amount",
                type: "number",
                required: true,
                col: 12,
                value: fundInfo?.amount,
              },
              {
                label: "Description",
                name: "description",
                type: "textarea",
                col: 12,
                required: true,
                value: fundInfo?.description,
              },
            ],
          },
        ]}
        url={
          fundInfo?.id
            ? `social-fund/update-type-of-fund/${fundInfo?.id}`
            : "social-fund/create-type-of-fund/"
        }
        method={fundInfo?.id ? "PUT" : "POST"}
        onSuccess={() => {
          setAddFund(0);
          setFundInfo({});
          reload();
        }}
        open={addFund}
        setOpen={() => setAddFund(0)}
      />
      {deleteActionUrl ? (
        <DeleteDialog
          open={deleteOpen}
          onSuccess={reload}
          setOpen={setDeleteOpen}
          url={deleteActionUrl!}
        />
      ) : null}
    </div>
  );
};

export default UpdateGeneralSettings;
