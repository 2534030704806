import { Typography } from "@mui/material";

const PdfPreview = (props: any) => {
    const { document, mimeType,height,width } = props;

    return (
      <div id={document}>
        {mimeType?.split("/")[0] === "video" ? (
          <video width="80%" height="80%" controls>
            <source src={`${document}`} />
          </video>
        ) : mimeType?.split("/")[0] === "image" ? (
          <img
            src={`${document}`}
            height={height??"1600"}
            width={width?? "1400"}
            alt={""}
          />
        ) :mimeType?.split("/")[1] === "pdf"?  (
                <div className="text-center">
                  <object data={`${document}#view=Fit&toolbar=0&statusbar=0&messages=0&navpanes=0&scrollbar=0`} type="application/pdf" style={{display:"inline-block"}} width={width??"60%"} height={height??"842"}>
                    <embed src={`${document}`} type="application/pdf" />
                  </object>
                </div>
        )
        :<Typography className="text-center">Cannot preview this type of file.</Typography>
      //   (  <Button
      //     onClick={() => {
      //       window.location.href = `${document}`;
      //     }}
      // >
      //   {" "}
      //   <i className={"fa fa-download me-1"}></i> Download Document
      // </Button>)
        }
      </div>
    );
  };
  export default PdfPreview