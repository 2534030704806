import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { Col, Row } from "react-bootstrap";
import { FormDialog } from "../../../components/FormDialog";
import React, { useEffect } from "react";
import { fetchDetails } from "../../../services/common";

const CreateUser = () => {
  const [roles, setRoles] = React.useState<Array<any>>([]);
  const [info, setInfo] = React.useState<any>();
  const navigate = useNavigate();
  const { id } = useParams();

  const fetchRoles = async () => {
    const resp = await fetchDetails("role/view");
    if (resp.status) {
      setRoles(resp.data.data);
    }
  };

  const fetchInfoDetails = async (id: string) => {
    const resp = await fetchDetails(`user/view/${id}`);
    if (resp.status) {
      setInfo(resp.data.data);
    }
  };

  useEffect(() => {
    fetchRoles().then();
  }, []);

  useEffect(() => {
    if (id) {
      fetchInfoDetails(id).then();
    }
  }, [id]);

  return (
    <div>
      <Breadcrumb />
      <Row className={"justify-content-center"}>
        <Col md={5}>
          <FormDialog
            inline={true}
            fields={[
              {
                label: "Username",
                name: "username",
                type: "name",
                value: info?.username,
                required: true,
              },
              {
                label: "Email Address",
                name: "email",
                type: "email",
                value: info?.email,
                required: true,
              },
              {
                label: "Role",
                name: "roleId",
                type: "select",
                value: info?.roleId,
                values: roles,
                required: true,
              },
            ]}
            url={id ? `user/update/${id}` : "user/create"}
            method={id ? "PUT" : "POST"}
            title={id ? "Update User" : "Create New User"}
            onSuccess={() => navigate(-1)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CreateUser;
