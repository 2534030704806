import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { DatatableComponent } from "../../../components/DatatableComponent";
import { useEffect, useState } from "react";
import DeleteDialog from "../../../components/DeleteDialog";
import { CustomizedMenus } from "../../../components/customizeMenu";
import { fetchDetails } from "../../../services/common";
import { Button, Col, Row } from "react-bootstrap";
import StatusComponent from "../../../components/StatusComponent";
import { default as ReactSelect } from "react-select";
import TabHeadersComponent from "../../../components/UI/Tabs";
import { Menu, Pending } from "@mui/icons-material";
import { TabPanel } from "../../employee/leave/List";

function ApprovalContributionList() {
  const navigate = useNavigate();
  const [list, setList] = useState<Array<any>>([]);
  const [change, setChange] = useState(0);
  const [approve, setApprove] = useState<boolean>(false);
  const [approveData, setApproveData] = useState<any>(null);
  const [fiscalYearList, setFiscalYearList] = useState<Array<any>>([]);
  const [fiscalYearId, setFiscalYear] = useState<any>();
  const [value, setValue] = useState(0);

  const getFiscalYear = async () => {
    const fiscalYear = await fetchDetails("/fiscal/view");

    if (fiscalYear.status) {
      const currentFiscalYear = fiscalYear?.data?.find(
        (year: any) => year.status === "open"
      );
      setFiscalYearList(fiscalYear?.data);
      setFiscalYear(currentFiscalYear?.id);
    }
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  useEffect(() => {
    //fetch all FiscalYear
    getFiscalYear().then();
  }, []);
  const labels = [
    { label: "Pending(In Service)", icon: <Pending /> },
    { label: "Pending(Exited from NPPA)", icon: <Pending /> },
    { label: "History", icon: <Menu /> },
  ];
  return (
    <div>
      <Breadcrumb
        endElement={value===1?
          <div>
            {/* <Button onClick={() => navigate("create")} className={"me-2"}>
              <i className={"bi bi-plus"}></i> Click this button to record
              monthly contributions and loan(for active members)
            </Button> */}
            <Button
              variant={approve ? "danger" : "success"}
              onClick={() => setApprove((approve) => !approve)}
            >
              <i
                className={approve ? "bi bi-x-circle" : "bi bi-check-circle"}
              ></i>{" "}
              {approve ? "Cancel Selection" : "Select Pending"}
            </Button>
          </div>:null
        }
      />
      {approveData !== null && (
        <DeleteDialog
          onSuccess={() => {
            setChange((change) => change + 1);
            setApprove(false);
            setApproveData(null);
          }}
          title={"Approve selected contributions"}
          buttonTitle={"Approve"}
          body={{
            contributions: approveData,
          }}
          bodyText={
            "Are you sure you want to approve selected pending contributions ?"
          }
          info={true}
          open={1}
          setOpen={() => setApproveData(null)}
          url={`/approvalContribution/multiple/${fiscalYearId}`}
          usePost={true}
        />
      )}
      <Row>
        <Col md={3}>
          <label className="col-form-label text-capitalize">Fiscal Year</label>
          <ReactSelect
            options={fiscalYearList?.map(({ label, id, name }: any) => ({
              value: id,
              label: label ?? name,
            }))}
            className="text-nowrap"
            onChange={(v: any) => {
              setFiscalYear(v?.value);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <TabHeadersComponent
            handleChange={handleChange}
            value={value}
            labels={labels}
          />
          <TabPanel value={value} p={0} index={0}>
            <DatatableComponent
              selectableRows={"none"}
              // rowsSelected={approve ? undefined : []}
              columns={[
                {
                  label: "Employee",
                  name: "names",
                },
                {
                  label: "Required Amount",
                  name: "supposedToPay",
                  type: "number",
                  totalCol: "requiredAmount",
                },
                {
                  label: "Paid Amount",
                  name: "fmtAmount",
                  type: "number",
                  totalCol: "amount",
                },
             
                {
                  label: "Penalty",
                  name: "fmtPenalty",
                  type: "number",
                  totalCol: "penalty",
                },
                {
                  label: "Total",
                  name: "fmtTotal",
                  type: "number",
                  totalCol: "total",
                },
                {
                  label: "Period",
                  name: "period",
                },
                // {
                //   label: "Month",
                //   name: "fmtMonth",
                // },
                {
                  label: "Monthly Pay Date",
                  name: "fmtDate",
                },
                {
                  label: "Status",
                  name: "status",
                  options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (status: any) => (
                      <StatusComponent status={status} badge={false} />
                    ),
                  },
                },
                {
                  name: "id",
                  label: "Action",
                  options: {
                    filter: true,
                    sort: false,
                    customBodyRenderLite: (id: any, index: any) => {
                      return (
                        <CustomizedMenus
                          onView={() => {
                            navigate(`details/${list[index].id}`);
                          }}
                        />
                      );
                    },
                  },
                },
              ]}
              url={`approvalContribution/datatable?change=${change}&fiscalYearId=${fiscalYearId}&notApproved=yes`}
              title={"Contributions List"}
              summaryFooter={true}
              processData={(data: any) => {
                setList(data.data);
                return data;
              }}
            />
          </TabPanel>
          <TabPanel value={value} p={0} index={1}>
            <DatatableComponent
              selectableRows={approve ? "multiple" : "none"}
              rowsSelected={approve ? undefined : []}
              columns={[
                {
                  label: "Employee",
                  name: "names",
                },
                {
                  label: "Required Amount",
                  name: "supposedToPay",
                  type: "number",
                  totalCol: "requiredAmount",
                },
                {
                  label: "Paid Amount",
                  name: "fmtAmount",
                  type: "number",
                  totalCol: "amount",
                },
             
                {
                  label: "Penalty",
                  name: "fmtPenalty",
                  type: "number",
                  totalCol: "penalty",
                },
                {
                  label: "Total",
                  name: "fmtTotal",
                  type: "number",
                  totalCol: "total",
                },
                {
                  label: "Period",
                  name: "period",
                },
                // {
                //   label: "Month",
                //   name: "fmtMonth",
                // },
                {
                  label: "Monthly Pay Date",
                  name: "fmtDate",
                },
                {
                  label: "Status",
                  name: "status",
                  options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (status: any) => (
                      <StatusComponent status={status} badge={false} />
                    ),
                  },
                },
                {
                  name: "id",
                  label: "Action",
                  options: {
                    filter: true,
                    sort: false,
                    customBodyRenderLite: (id: any, index: any) => {
                      return (
                        <CustomizedMenus
                          onView={() => {
                            navigate(`details/${list[index].id}`);
                          }}
                        />
                      );
                    },
                  },
                },
              ]}
              url={`approvalContribution/datatable?change=${change}&inPayment=yes&fiscalYearId=${fiscalYearId}&notApproved=yes`}
              title={"Contributions List"}
              summaryFooter={true}
              customToolbarSelect={(rows) => (
                <Button
                  onClick={() => {
                    setApproveData(rows.data.map((v) => list[v.dataIndex].id));
                  }}
                  className={"me-2"}
                  variant={"success"}
                >
                  <i className={"bi bi-check-circle"}></i> Approve Selected
                </Button>
              )}
              processData={(data: any) => {
                setList(data.data);
                return data;
              }}
            />
          </TabPanel>
          <TabPanel value={value} p={0} index={2}>
            <DatatableComponent
              columns={[
                {
                  label: "Employee",
                  name: "names",
                },
                {
                  label: "Amount Paid",
                  name: "fmtAmount",
                  type: "number",
                  totalCol: "amount",
                },
                {
                  label: "Required Amount",
                  name: "supposedToPay",
                  type: "number",
                  totalCol: "requiredAmount",
                },
                {
                  label: "Penalty",
                  name: "fmtPenalty",
                  type: "number",
                  totalCol: "penalty",
                },
                {
                  label: "Total",
                  name: "fmtTotal",
                  type: "number",
                  totalCol: "total",
                },
                // {
                //   label: "Month",
                //   name: "fmtMonth",
                // },
                {
                  label: "Period",
                  name: "period",
                },
                {
                  label: "Monthly Pay Date",
                  name: "fmtDate",
                },
                {
                  label: "Status",
                  name: "status",
                  options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (status: any) => (
                      <StatusComponent status={status} badge={false} />
                    ),
                  },
                },
                {
                  name: "id",
                  label: "Action",
                  options: {
                    filter: true,
                    sort: false,
                    customBodyRenderLite: (id: any, index: any) => {
                      return (
                        <CustomizedMenus
                          onView={() => {
                            navigate(`details/${list[index].id}`);
                          }}
                        />
                      );
                    },
                  },
                },
              ]}
              url={`approvalContribution/datatable?change=${change}&notPending=yes&fiscalYearId=${fiscalYearId}`}
              title={"Contributions List"}
              summaryFooter={true}
              processData={(data: any) => {
                setList(data.data);
                return data;
              }}
            />
          </TabPanel>
        </Col>
      </Row>
    </div>
  );
}

export default ApprovalContributionList;
