import { DatatableComponent } from "../../../components/DatatableComponent";
import moment from "moment";
import { useState } from "react";
import { AddForm } from "../../employee/AddForm";
import { Update } from "@mui/icons-material";
import { CustomizedMenus } from "../../../components/customizeMenu";
import DeleteDialog from "../../../components/DeleteDialog";

function ExpenseCategoryList( props:any) {
 const {
    setOpen,
    open,
    setDeleteActionUrl,
    setDeleteOpen,
    change,
    setChange,
    setValue
  }=props
  const [list, setList] = useState<Array<any>>([]);
  // const [change, setChange] = useState(0);
  const [info, setInfo] = useState<any>();

  return (
    <>
      <DatatableComponent
        columns={[
          {
            label: "Date",
            name: "createdAt",
            options: {
              customBodyRender: (v: any) => moment(v).format("L"),
            },
          },
          {
            label: "Name",
            name: "name",
          },
          {
            name: "id",
            label: "Action",
            options: {
              filter: true,
              sort: false,
              customBodyRenderLite: (id: any, index: any) => {
                return (
                  <CustomizedMenus
                    onEdit={() => {
                      setOpen(1);
                      setInfo(list[index]);
                    }}
                    onDelete={() => {
                      setDeleteOpen(1);
                      setDeleteActionUrl(
                        `cde/delete-category/${list[index].id}`
                      );
                    }}
                    needEdit={true}
                    needDelete={true}
                  />
                );
              },
            },
          },
        ]}
        // deleteUrl={(id) => `cde/delete-category/${id}`}
        // actions={(id) => [
        //   <button title="delete" className={"border-0 bg-transparent"}>
        //     <Update
        //       onClick={() => {
        //         setOpen(1);
        //         setInfo(list?.find((v) => v.id === id));
        //       }}
        //       fontSize="small"
        //       className={"cursor-pointer"}
        //     />
        //   </button>,
        // ]}
        url={`cde/datatable-expense-categories/?change=${change}`}
        title={`Expense Categories`}
        processData={(data: any) => {
          setList(data?.data);
          return data;
        }}
      />
      {/* Add new expenses form */}

      <AddForm
        // title={"Record new expenses"}
        title={info?.id ? "Update category" : "Record new category"}
        maxWidth="sm"
        mainFields={[
          {
            title: "",
            fields: [
              {
                label: "Name",
                name: "name",
                col: 12,
                required: true,
                value: info?.name,
              },
            ],
          },
        ]}
        url={
          info?.id
            ? `cde/record-expense-category?id=${info?.id}`
            : "cde/record-expense-category/"
        }
        method={"POST"}
        onSuccess={() => {
          setChange(change + 1);
          setOpen(0);
          setValue(4)
        }}
        open={open}
        setOpen={() => {
          setOpen(0);
          setInfo({});
        }}
      />
    </>
  );
}

export default ExpenseCategoryList;
