// import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
type IProps = {
  status: string;
  badge: boolean;
};
function StatusComponent(props: IProps) {
  const { status, badge } = props;
  return (
    <>
      {badge ? (
        <span
          className={
            status === "inactive" ||
            status === "rejected" ||
            status === "exited" ||
            status === "canceled" ||
            status === "not registered" ||
            status === "not yet eligible"||status === "Not Found"||status === "Absent"
              ? "badge bg-danger rounded-pill text-nowrap"
              : status === "pending" ||
                status === "request-suspension" ||
                status === "payment pending" ||
                status === "suspended" ||
                status === "eligible"||status === "Not Yet Confirmed"||status === "Late"
              ? "badge bg-warning rounded-pill text-nowrap"
              : status === "request action" ||
                status === "retired" ||
                status === "need-identification"
              ? "badge bg-primary rounded-pill text-nowrap"
              : status === "Contract drafting" || status === "under review"
              ? "badge bg-secondary rounded-pill text-nowrap"
              : status === "forwarded"
              ? "badge bg-info rounded-pill text-nowrap"
              : "badge bg-success rounded-pill text-nowrap"
          }
        >
          {status}
        </span>
      ) : (
        <span
          className={
            status === "inactive" ||
            status === "rejected" ||
            status === "exited" ||
            status === "canceled" ||
            status === "not yet eligible"
              ? "status-danger fw-bold rounded-pill text-capitalize  p-1 text-nowrap"
              : status === "pending" ||
                status === "payment pending" ||
                status === "suspended" ||
                status === "eligible"
              ? "fw-bold rounded-pill text-capitalize  p-1 text-nowrap status-warning"
              : status === "request action" ||
                status === "retired" ||
                status === "need-identification"
              ? "status-primary fw-bold rounded-pill text-capitalize  p-1 text-nowrap"
              : status === "Contract drafting" || status === "under review"
              ? "status-secondary fw-bold rounded-pill text-capitalize  p-1 text-nowrap"
              : status === "forwarded"
              ? "status-info fw-bold rounded-pill text-capitalize  p-1 text-nowrap"
              : " fw-bold rounded-pill text-capitalize  p-1 text-nowrap status-green"
          }
        >
          <FiberManualRecordIcon
            className={
              status === "inactive" ||
              status === "rejected" ||
              status === "exited" ||
              status === "canceled" ||
              status === "not yet eligible"
                ? "me-1 status-danger text-nowrap"
                : status === "pending" ||
                  status === "payment pending" ||
                  status === "suspended" ||
                  status === "eligible"
                ? "me-1 status-warning text-nowrap"
                : status === "request action" ||
                  status === "retired" ||
                  status === "need-identification"
                ? "me-1 status-primary text-nowrap"
                : status === "Contract drafting" || status === "under review"
                ? "me-1 status-secondary text-nowrap"
                : status === "forwarded"
                ? "me-1 status-info text-nowrap"
                : "me-1 status-green-text text-nowrap"
            }
            sx={{ fontSize: 10 }}
          />
          {status}
        </span>
      )}
    </>
  );
}

export default StatusComponent;
