import React, { JSX, useEffect, useState } from "react";
import {
  fetchDetails,
  guaranteeTypes,
  loanStatusList,
} from "../../../services/common";
import moment from "moment";
import ReportEngineComponent from "../../../components/ReportEngineComponent";

function ContributionReport(): JSX.Element {
  const [loanTypes, setLoanTypes] = useState([]);
  const [value, setValue] = useState<any>({});
  const typeName = value?.type?.label?.toLowerCase();
  const [list, setList] = useState<any>();
  // const data = list?.data??[];

  const loadInitialData = async () => {
    // const employeeList = await fetchDetails("report/caisse/members");
    const types = await fetchDetails("loan/view-loan-type");
    // if (employeeList.status) setRequesters(employeeList?.data);
    if (types.status) setLoanTypes(types?.data);
  };

  useEffect(() => {
    loadInitialData().then();
  }, []);
  const regular = [
    {
      label: "No",
      name: "index",
      type: "integer",
    },
    {
      label: "Employee ID",
      name: "empNumber",
    },
    {
      label: "Names",
      name: "names",
      data: "names",
    },
    {
      label: "Requested Loan",
      name: "amount",
      type: "number",
      options: {
        customBodyRender: (v: any) => v?.toLocaleString(),
      },
    },
    {
      label: "Approved Loan",
      name: "amountReceived",
      type: "number",
      options: {
        customBodyRender: (v: any) => v?.toLocaleString(),
      },
    },
    {
      label: "Interest Rate",
      name: "interestRate",
      type: "integer",
    },
    {
      label: "Loan Security",
      name: "securityAmount",
      type: "number",
      options: {
        customBodyRender: (v: any) => v?.toLocaleString(),
      },
    },
    {
      label: "Monthly installment",
      name: "monthlyAmount",
      type: "number",
      options: {
        customBodyRender: (v: any) => v?.toLocaleString(),
      },
    },
    {
      label: "Duration",
      name: "terms",
      type: "integer",
    },
    {
      label: "Start",
      name: "firstPayment",
      type: "date",
      options: {
        customBodyRender: (v: any) => moment(v).format("YYYY-MMM-DD"),
      },
    },
    {
      label: "End",
      name: "lastPayment",
      type: "date",
      options: {
        customBodyRender: (v: any) => moment(v).format("YYYY-MMM-DD"),
      },
    },
    {
      label: "Outstanding",
      name: "remainingAmount",
      type: "number",
      options: {
        customBodyRender: (v: any) => v?.toLocaleString(),
      },
    },
    {
      label: "Remaining Term",
      name: "totalMonthsPaid",
      type: "integer",
      options: {
        customBodyRender: (v: any) => v?.toLocaleString(),
      },
    },
    {
      label: "Non Performing",
      name: "nonPerforming",
      type: "number",
      options: {
        customBodyRender: (v: any) => v?.toLocaleString(),
      },
    },
  ];
  const longTerm = [
    {
      label: "No",
      name: "index",
      type: "integer",
    },
    {
      label: "Employee ID",
      name: "empNumber",
    },
    {
      label: "Names",
      name: "names",
      data: "names",
    },
    {
      label: "Guarantee Member",
      name: "insurer",
      data: "insurer",
      options: {
        customBodyRender: (v: any) => v?.toLocaleString(),
      },
    },
    {
      label: "Collateral(UPI)",
      name: "landUpi",
      // type: "number",
      options: {
        customBodyRender: (v: any) => v?.toLocaleString(),
      },
    },
    {
      label: "Approved Loan",
      name: "amountReceived",
      type: "number",
      options: {
        customBodyRender: (v: any) => v?.toLocaleString(),
      },
    },
    {
      label: "Interest Rate",
      name: "interestRate",
      type: "integer",
    },
    {
      label: "Loan Security",
      name: "securityAmount",
      type: "number",
      options: {
        customBodyRender: (v: any) => v?.toLocaleString(),
      },
    },
    {
      label: "Monthly installment",
      name: "monthlyAmount",
      type: "number",
      options: {
        customBodyRender: (v: any) => v?.toLocaleString(),
      },
    },
    {
      label: "Duration",
      name: "terms",
      type: "integer",
    },
    {
      label: "Start",
      name: "firstPayment",
      type: "date",
      options: {
        customBodyRender: (v: any) => moment(v).format("YYYY-MMM-DD"),
      },
    },
    {
      label: "End",
      name: "lastPayment",
      type: "date",
      options: {
        customBodyRender: (v: any) => moment(v).format("YYYY-MMM-DD"),
      },
    },
    {
      label: "Outstanding",
      name: "remainingAmount",
      type: "number",
      options: {
        customBodyRender: (v: any) => v?.toLocaleString(),
      },
    },
    {
      label: "Remaining Term",
      name: "totalMonthsPaid",
      type: "integer",
      options: {
        customBodyRender: (v: any) => v?.toLocaleString(),
      },
      
    },
    {
      label: "Non Performing",
      name: "nonPerforming",
      type: "number",
      options: {
        customBodyRender: (v: any) => v?.toLocaleString(),
      },
    },
  ];
  return (
    <ReportEngineComponent
      setList={setList}
      setValue={setValue}
      value={value}
      title={"Loan Report"}
      url={`cde-report/loan`}
      columns={typeName?.includes("long") ? longTerm : regular}
      filters={[
        {
          label: "Loan Type",
          name: "type",
          values: loanTypes?.map(({ label, id }: any) => ({
            label,
            value: id,
          })),
        },

        {
          label: "Employee Name/ID",
          name: "empName",
          type: "text",
        },
        {
          label: "Status",
          name: "status",
          values: loanStatusList,
        },
        {
          label: "Guarantee",
          name: "collateralType",
          values: guaranteeTypes,
        },
        {
          label: "Has Top-up",
          name: "hasTopUp",
          hidden: !typeName?.includes("long"),
          values: [
            {
              label: "Yes",
              value: "",
            },
            {
              label: "No",
              value: "0",
            },
          ],
        },
        {
          label: "Start Date",
          name: "startDate",
          type: "date",
        },
        {
          label: "End Date",
          name: "endDate",
          type: "date",
        },
      ]}
    />
  );
}

export default ContributionReport;
