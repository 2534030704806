import React from 'react';

import {
  getSession, isAuth, login, logout, sendPasswordReset, addUser, getUsers,
} from '../services/MockAuthService';

const AuthContext = React.createContext({
  getSession,
  isAuth,
  login,
  logout,
  sendPasswordReset,
  addUser,
  getUsers,
});

function AuthProvider(props:any) {
  const { children , ...rest } = props;
  // AuthContext to encapsulate these functions, which are wrappers to the services service.
  const auth  = {
    getSession,
    isAuth,
    login,
    logout,
    sendPasswordReset,
    addUser,
    getUsers,
  };

  return (
    <AuthContext.Provider value={auth} {...rest}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext };
export default AuthProvider;
