import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { DatatableComponent } from "../../../components/DatatableComponent";
import moment from "moment";
import { useState } from "react";
import { CustomizedMenus } from "../../../components/customizeMenu";
import StatusComponent from "../../../components/StatusComponent";
import { Col, Row } from "react-bootstrap";

function ShareIncrementList() {
  const navigate = useNavigate();
  const [list, setList] = useState<Array<any>>([]);
  const [change, setChange] = useState(0);

  return (
    <div>
      <Breadcrumb />
      <Row>
        <Col md={12}>
          <DatatableComponent
            columns={[
              {
                label: "Employee",
                name: "memberId",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRenderLite: (id: any, index: any) =>
                    list[index]?.CaisseMember?.employee?.names,
                },
              },
              {
                label: "Current Share",
                name: "CaisseMember.share",
                options: {
                  customBodyRender: (v: any) => v?.toLocaleString(),
                },
              },
              {
                label: "Increment Of",
                name: "share",
                options: {
                  customBodyRender: (v: any) => v?.toLocaleString(),
                },
              },
            
              {
                label: "Date",
                name: "createdAt",
                options: {
                  customBodyRender: (v: any) =>
                    moment(v).format("DD/MM/YYYY"),
                },
              },
              {
                label: "Status",
                name: "status",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (status: any) => (
                    <StatusComponent status={status} badge={false} />
                  ),
                },
              },
              {
                name: "id",
                label: "Action",
                options: {
                  filter: true,
                  sort: false,
                  customBodyRenderLite: (id: any, index: any) => {
                    return (
                      <CustomizedMenus
                        onView={() => {
                          navigate(`details/${list[index].id}`);
                        }}
                      />
                    );
                  },
                },
              },
            ]}
            url={`approvalIncrements/datatable?change=${change}`}
            title={"Share Increment Requests"}
            processData={(data: any) => {
              setList(data.data);
              return data;
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default ShareIncrementList;
