/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import "./sidebar.css";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MuiListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/core/styles";
import logo from "../assets/coat2.jpg";
import {
  CircularProgress,
  Hidden,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useMediaQuery } from "react-responsive";
import useStyles from "../layouts/style";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  CheckHasAnyPermission,
  downloadBase64,
  getFileMime,
  inArray,
} from "../services/common";
import unProfile from "../assets/un-profile.png";
import { Help } from "@mui/icons-material";

const SideBar = (props: any) => {
  const classes = useStyles();
  const {
    open,
    handleDrawerOpen,
    window,
    openDrawer,
    setOpenDrawer,
    handleDrawerClose,
    loggedUser,
  } = props;

  const ListItem = withStyles({
    root: {
      "&$selected": {
        backgroundColor: "#ffffff",
        color: "#3b3b3b",
        "& .MuiListItemIcon-root": {
          color: "white",
        },
      },
      "&:hover": {
        // backgroundColor: "#ffffff !important",
        color: "#3b3b3b !important",
        "& .MuiListItemIcon-root": {
          color: "white",
        },
      },
    },
    selected: {},
  })(MuiListItem);

  const { pathname } = useLocation();
  const inActive = inArray(["exited", "retired"], loggedUser?.Employee?.status);

  const [data, setData] = useState<any>();
  const navigate = useNavigate();

  const profile = loggedUser?.Employee?.profile;
  const downloadData = async () => {
    const res = await downloadBase64(
      profile,
      profile,
      "",
      getFileMime(profile)
    );
    if (res) {
      setData(res);
    }
  };

  useEffect(() => {
    if (profile) {
      downloadData().then();
    }
  }, [profile]);

  const items = [
    {
      path: "/console/dashboard",
      exact: true,
      title: "Dashboard",
      icon: "bi-house-door",
      hidden: inActive,
    },
    {
      path: "/console/employee",
      title: "Employee Management",
      icon: "bi-people",
      module: "employee",
      hidden: inActive,
    },
    {
      path: "/console/approvals",
      title: "Approvals",
      icon: "bi-layers",
      module: "approval-request",
      hidden: inActive,
    },
    {
      path: "/console/saving",
      title: "Caisse D'entraide",
      icon: "bi bi-basket-fill",
      // module: "saving",
      hidden: loggedUser?.Employee?.caisseEntradeStatus === "exited",
    },
    {
      path: "/console/approveSaving",
      title: "Caisse D'entraide Approver",
      icon: "bi bi-journal-check",
      module: "approval-saving",
      hidden: inActive,
    },
    {
      path: "/console/caisse-report",
      title: "Caisse D'entraide Report",
      icon: "bi bi-box-fill",
      module: "approval-saving",
      hidden: inActive,
    },
    {
      path: "/console/report",
      title: "Report",
      icon: "bi-database-fill-gear",
      module: "reports",
      hidden: inActive,
    },
    {
      path: "/console/settings",
      title: "Settings",
      icon: "bi-gear",
      module: "settings",
      hidden: inActive,
    },
    {
      path: "/console/logs",
      title: "Logs",
      icon: "bi bi-box2-fill",
      module: "dashboard",
      hidden: inActive,
    },
  ];
  const drawer = (
    <div>
      <div className={classes.drawerHeader}>
        <div className={classes.logo}>
          <Link
            to="/console/dashboard"
            className="text-white text-decoration-none "
          >
            <img
              src={logo}
              alt="logo"
              className="mx-3"
              height={59}
              width={58}
            />{" "}
            {openDrawer ? (
              <h5 className="text-center text-nowrap"> NPPA IPSS</h5>
            ) : null}
          </Link>
        </div>
        <IconButton
          onClick={() => {
            if (open) {
              handleDrawerOpen();
            } else {
              setOpenDrawer(!openDrawer);
            }
          }}
          className="text-white fw-bolder"
        >
          {openDrawer ? <ChevronLeftIcon /> : <MenuIcon />}
        </IconButton>
      </div>
      <Divider />
      {openDrawer ? (
        <>
          <div className={classes.sideBarProfile}>
            <Link
              to="/console/dashboard"
              className="text-white text-decoration-none fw-bolder"
            >
              <img
                src={loggedUser?.Employee?.profile && data ? data : unProfile}
                alt="profile"
                height={35}
                width={35}
                className="rounded-pill"
              />
            </Link>
          </div>
          <ListItemText
            className="text-center text-nowrap"
            style={{
              fontSize: 12,
              color: "#ffffff",
              fontWeight: "bolder",
            }}
            primary={
              <span className={classes.sideBarProfileText}>
                {loggedUser?.Employee?.names}
              </span>
            }
            secondary={
              <span className={classes.sideBarProfileText2}>
                {loggedUser?.Employee?.position?.name}
              </span>
            }
          />
        </>
      ) : null}

      <div>
        {!loggedUser ? (
          <CircularProgress />
        ) : (
          //   <ul className="nav flex-column justify-content-center">
          <List className="text-white fw-bold">
            {items.map((item, i) =>
              (item.module &&
                !CheckHasAnyPermission(item.module) &&
                item.module !== "myRequest") ||
              item.hidden ? null : (
                <Tooltip title={item.title} key={i}>
                  <ListItem
                    button
                    key={i}
                    onClick={() => {
                      navigate(item.path);
                    }}
                    className={`nav-link px-3 ${
                      (item.exact &&
                        (`${item.path}/` === `${pathname}` ||
                          item.path === pathname)) ||
                      (pathname.startsWith(item.path) && !item.exact)
                        ? "active border-start border-5 border-info "
                        : ""
                    }`}
                  >
                    <ListItemIcon
                      style={{
                        fontSize: 12,
                        color: "#ffffff",
                        fontWeight: "bolder",
                      }}
                    >
                      <i className={`bi ${item.icon}`} />
                    </ListItemIcon>
                    <ListItemText
                      className={`text-capitalize fw-bolder text-white`}
                      secondary={
                        <span className={classes.menuItem}>
                          {!CheckHasAnyPermission("settings") &&
                          item.module === "myRequest"
                            ? "My Request"
                            : item.title}
                        </span>
                      }
                    />
                  </ListItem>
                </Tooltip>
              )
            )}
            <Tooltip title={"Guide"}>
              <ListItem
                button
                onClick={() => {
                  navigate("/console/user-guide");
                }}
                className={`nav-link px-3 ${
                  `/console/user-guide` === `${pathname}` ||
                  "/console/user-guide" === pathname ||
                  pathname.startsWith("/console/user-guide")
                    ? "active border-start border-5 border-info "
                    : ""
                }`}
              >
                <ListItemIcon
                  style={{
                    fontSize: 12,
                    color: "#ffffff",
                    fontWeight: "bolder",
                  }}
                >
                  <i className={`bi bi-question-circle-fill`} />
                </ListItemIcon>
                <ListItemText
                  className={`text-capitalize fw-bolder text-white`}
                  secondary={<span className={classes.menuItem}>User Guide</span>}
                />
              </ListItem>
            </Tooltip>
          </List>
        )}
      </div>
    </div>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const isSm = useMediaQuery({ maxWidth: 992 });
  return isSm ? (
    <Hidden smDown implementation="css">
      <Drawer
        container={container}
        variant="temporary"
        anchor="left"
        open={open}
        onClose={() => {
          handleDrawerClose();
          setOpenDrawer(true);
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </Drawer>
    </Hidden>
  ) : (
    <Hidden smDown implementation="css">
      <Drawer
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: openDrawer,
          [classes.drawerClose]: !openDrawer,
        })}
        style={!openDrawer ? { width: "80px" } : undefined}
        classes={{
          paper:
            clsx({
              [classes.drawerOpen]: openDrawer,
              [classes.drawerClose]: !openDrawer,
            }) + " shadow border-right",
        }}
        variant="permanent"
        anchor="left"

        // open={open}
      >
        {drawer}
      </Drawer>
    </Hidden>
  );
  //   </div>
  // </ThemeProvider>
};
// SideBar.propTypes = {
//   window: PropTypes.func,
// };
export default SideBar;
