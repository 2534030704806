import { Button } from "@mui/material";

const GeneralButton = (props:any) => {
    const {onClick,icon,name,size,className}=props
  return (
    <Button
      autoFocus
      sx={{
        bgcolor: "#054D6F",
        fontFamily:'Inter',
        color: "white",
        "&:hover": { bgcolor: "#035c85" },
      }}
      className="me-2 general-font"
      size={size??"small"}
      variant="outlined"
      startIcon={icon}
      onClick={onClick}
    >
      {name}
    </Button>
  );
};
export default GeneralButton