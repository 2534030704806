import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import calendar from "../../assets/vs_calendar-alt.png";
import Rectangle1 from "../../assets/Rectangle 598.png";
import Rectangle2 from "../../assets/Rectangle 599.png";
import Rectangle3 from "../../assets/Rectangle 600.png";
import Rectangle4 from "../../assets/Rectangle4.png";
import {
  attendanceStatusList,
  fetchDetails,
  getError,
  submitGeneralForm,
} from "../../services/common";
import moment from "moment";
import { mapStateToProps } from "../../store/helpers/mapState";
import { connect } from "react-redux";
import PageLoading from "../../components/PageLoading";
import { DashboardCard } from "../../components/DashboardCard";
import {
  Add,
  Balance,
  BusinessCenter,
  EditNote,
  MoveUp,
} from "@mui/icons-material";
import ProgressBarComponent from "../../components/UI/ProgressBar";
import Logout from "@mui/icons-material/Logout";
import CalendarComponent from "../../components/CalendarComponent";
import StatusComponent from "../../components/StatusComponent";

const EmployeeDashboard = (props: any) => {
  const { loggedUser } = props;
  // const title = "Dashboard";
  const [error, setError] = useState<any>();
  const navigate = useNavigate();
  const [info, setInfo] = useState<any>();
  const [setting, setSetting] = useState<any>();
  const [dashboardData, setDashboard] = useState<any>({});
  const [statusAttend, setStatusAttends] = useState([0, 0, 0, 0, 0, 0]);
  const leaveTaken = info?.leaveDaysTaken ? info?.leaveDaysTaken : 0;
  const remainLeave =
    info?.category === "contractual"
      ? setting?.contractual - leaveTaken
      : setting?.underStatute - leaveTaken;
  //upcoming holidays
  const holidays = dashboardData?.holiday
    ? dashboardData?.holiday?.sort(
        (a: any, b: any) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      )
    : [];

 
  const fetchInfoDetails = async (id: string) => {
    const resp = await fetchDetails(`employee/view/${loggedUser?.employeeId}`);
    const dashboard = await fetchDetails(`dashboard/employee/view/`);
    const setting = await fetchDetails(`settings/view/`);
    if (setting.status) {
      setSetting(setting.data);
    }
    if (resp.status) {
      setInfo(resp.data);
    }
    if (dashboard.status) {
      setDashboard(dashboard.data);
    } else {
      setError(getError(resp));
    }

  };
  useEffect(() => {
    if (loggedUser?.employeeId) {
      fetchInfoDetails(loggedUser?.employeeId).then();
    }
    if (!loggedUser) {
      setError("Network Error");
    }
  }, [loggedUser?.employeeId]);

  useEffect(() => {
    if (dashboardData?.statusAttendance) {
      const getAllStatus = (attend: any) => {
        let statusAttendCopy: any = [...statusAttend];
        for (let i = 0; i < attend?.length; i++) {
          if (attend[i]?.status === "h") {
            statusAttendCopy[0] = attend[i]?.count;
          }
          if (attend[i].status === "p") {
            statusAttendCopy[1] = attend[i]?.count;
          }
          if (attend[i].status === "l") {
            statusAttendCopy[2] = attend[i]?.count;
          }
          if (attend[i].status === "a") {
            statusAttendCopy[3] = attend[i]?.count;
          }
          if (attend[i].status === "v") {
            statusAttendCopy[4] = attend[i]?.count;
          }
          if (attend[i].status === "h") {
            statusAttendCopy[5] = attend[i]?.count;
          }
        }
        setStatusAttends(statusAttendCopy);
      };
      getAllStatus(dashboardData?.statusAttendance);
    }
  }, [dashboardData]);

  const date = moment().format("YYYY-MM-DD");

  const workStartTime = new Date(date + " " + setting?.workStartTime);
  const workEndTime = new Date(date + " " + setting?.workEndTime);
const myAttendanceStatus=attendanceStatusList?.find(
  (st: any) =>
    st?.value === info?.attendances[0]?.status
)?.label
  const inTime = info?.attendances[0]?.checkInTime;
  const outTime = info?.attendances[0]?.checkOutTime;
  const now = moment().diff(inTime, "hours");
  const production =
    outTime && now < 10
      ? moment(outTime).diff(inTime, "hours")
      : moment().diff(inTime, "hours");
  //dashboard color

  return (
    <div>
      {!loggedUser ? (
        <PageLoading error={error} />
      ) : (
        <>
          {/* <Breadcrumb /> */}
          <Typography
            style={{ fontFamily: "Inter" }}
            className="blue-color mb-4"
          >
            Hello <strong>{info?.lastName}</strong>,Welcome Back!
          </Typography>
          <Row className="mb-3">
            {/* <Col md={6}> */}
            {/* <Card className={"shadow mainDashColor p-3"}>
                <Row> */}
            <Col md={4}>
              <DashboardCard
                className="shadow mb-3"
                content={{
                  title: "Mission",
                  number: dashboardData?.mission ?? 0,
                }}
                icon={<BusinessCenter />}
                onClick={() => {
                  navigate("/console/employee/missions");
                }}
              />
            </Col>
            {/* <Col md={6}>
                    <Card
                      className="background p-2 shadow d-flex justify-content-center "
                      style={{
                        backgroundColor: "#054D6F",
                        height: "40%",
                        borderRadius: "12px",
                      }}
                      role="button"
                      onClick={() => {
                        navigate("/console/employee/");
                      }}
                    >
                      <h6 className="text-white text-center">
                        Initiate New
                        <Add fontSize="small" />
                      </h6>
                    </Card>
                  </Col> */}
            <Col md={4}>
              <DashboardCard
                className="shadow mb-3"
                content={{
                  title: "Mutation",
                  number: dashboardData?.mutation ?? 0,
                }}
                icon={<MoveUp />}
                onClick={() => {
                  navigate("/console/employee/");
                }}
              />
            </Col>
            <Col md={4}>
              <DashboardCard
                className="mb-4 shadow"
                content={{
                  title: "Self Service",
                  number: dashboardData?.request ?? 0,
                }}
                icon={<EditNote />}
                onClick={() => {
                  navigate("/console/employee/requests");
                }}
              />
            </Col>
            {/* </Row>
              </Card> */}
            {/* </Col> */}
            {/* <Col md={6}>
              <Card className={"shadow mainDashColor p-3"}>
                <Row>
                  <Col md={6}>
                    <DashboardCard
                      className=" shadow mb-3"
                      content={{
                        title: "Leave taken",
                        number: leaveTaken ?? 0,
                      }}
                      icon={<Logout />}
                      onClick={() => {
                        navigate("/console/employee/leaves");
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <Card
                      className="background p-2 shadow d-flex justify-content-center"
                      style={{
                        backgroundColor: "#054D6F",
                        height: "40%",
                        borderRadius: "12px",
                      }}
                      role="button"
                      onClick={() => {
                        navigate("/console/employee/leaves");
                      }}
                    >
                      <h6 className="text-white">
                        Apply Leave
                        <Add fontSize="small" />
                      </h6>
                    </Card>
                  </Col>
                  <Col md={6}>
                    <DashboardCard
                      className="shadow mb-3"
                      content={{
                        title: "Remaining",
                        number: remainLeave ?? 0,
                      }}
                      icon={<Balance />}
                      onClick={() => {
                        navigate("/console/employee/leaves");
                      }}
                    />
                  </Col>

                  <Col md={6}>
                    <DashboardCard
                      className="mb-4 shadow mb-3"
                      content={{
                        title: "Remaining in %",
                        number: remainLeave / 100 ?? 0,
                      }}
                      icon={<Balance />}
                      onClick={() => {
                        navigate("/console/employee/leaves");
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col> */}
          </Row>
          {/* =============================attendance details================================== */}
          <Row className="mb-3">
            <Col md={3}>
              <CardActionArea>Attendance</CardActionArea>
              <Card className="shadow p-2" style={{ height: "90%" }}>
                <div className="bg-white d-flex p-2 me-2 ">
                  <div className="me-3 light-blue p-4">
                    <Avatar
                      alt="alt"
                      src={Rectangle4}
                      sx={{ width: 24, height: 24 }}
                    />
                  </div>
                  <div>
                    <Typography
                      style={{ fontFamily: "Inter" }}
                      className="fw-bold mt-1 text-nowrap"
                    >
                      {" "}
                      {inTime ? moment(inTime).format("h:mm A") : ""}{" "}
                    </Typography>
                    <span className="text-nowrap">Check-In</span>
                  </div>
                </div>
                <div className="bg-white d-flex p-2 me-2 ">
                  <div className="me-2 light-blue p-4">
                    {/* <HourglassBottom  color='info' /> */}
                    <Avatar
                      alt="alt"
                      src={Rectangle2}
                      sx={{ width: 24, height: 24 }}
                    />
                  </div>
                  <div>
                    <Typography
                      style={{ fontFamily: "Inter" }}
                      className="fw-bold mt-1 text-nowrap"
                    >
                      {" "}
                      {outTime ? moment(outTime).format("h:mm A") : ""}{" "}
                    </Typography>
                    <span className="text-nowrap">Check-Out</span>
                  </div>
                </div>
              </Card>
            </Col>
            <Col md={3}>
              <CardActionArea className="d-flex float-end">
                {" "}
                <span className="text-nowrap">
                  Working hours:
                  {setting?.workStartTime
                    ? moment(workStartTime).format("h:mm A")
                    : null}
                  -
                  {setting?.workEndTime
                    ? moment(workEndTime).format("h:mm A")
                    : null}
                </span>
              </CardActionArea>
              <Card className="shadow p-2" style={{ height: "90%" }}>
                <div className="bg-white d-flex p-2  me-2 ">
                  <div className="me-2 light-blue p-4">
                    {/* <HourglassBottom  color='info' /> */}
                    <Avatar
                      alt="alt"
                      src={Rectangle1}
                      sx={{ width: 24, height: 24 }}
                    />
                  </div>
                  <div>
                    <Typography
                      style={{ fontFamily: "Inter" }}
                      className="fw-bold mt-1 text-nowrap"
                    >
                      {production?`${production}hr(s)`:null}
                    </Typography>
                    <span className="text-nowrap">Worked hours</span>
                  </div>
                </div>
                <div className="bg-white d-flex p-2 me-2 ">
                  <div className="me-2 light-blue p-4">
                    <Avatar
                      alt="alt"
                      src={Rectangle3}
                      sx={{ width: 24, height: 24 }}
                    />
                  </div>
                  <div>
                    <Typography
                      style={{ fontFamily: "Inter" }}
                      className="fw-bold mt-4 text-nowrap"
                    >
                      <StatusComponent
                        status={myAttendanceStatus??""}
                        badge={true}
                      />
                    </Typography>
                  </div>
                </div>
              </Card>
            </Col>

            <Col md={6}>
              <CardActionArea>Upcoming Holidays</CardActionArea>
              <Card className={"shadow p-2"} style={{ height: "90%" }}>
                {holidays?.map((holiday: any) => (
                  <div
                    className="d-flex bg-secondary1 rounded mb-4 p-3"
                    key={holiday?.id}
                  >
                    <div
                      className="rounded calendarIcon p-1 me-3"
                      style={{ backgroundColor: "#AED6F1" }}
                    >
                      <span>
                        {" "}
                        <Avatar alt="alt" src={calendar} />
                      </span>
                    </div>
                    <div>
                      <Typography
                        style={{ fontFamily: "Inter" }}
                        className="text-nowrap"
                      >
                        {holiday?.name}
                      </Typography>
                      <span className="text-muted text-nowrap">
                        {moment(holiday?.date).format("LLL")}
                      </span>
                    </div>
                  </div>
                ))}
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={8}>
              <CardActionArea className="text-capitalize">
                {"Annual Statistics"}
              </CardActionArea>
              <Card className={"shadow"}>
                <CardContent>
                  <Typography style={{ fontFamily: "Inter" }} variant="h6">
                    {""}
                  </Typography>
                  <CardHeader
                    subheader={
                      <div>
                        <span className="mb-2">Working </span>{" "}
                        {/* <ProgressBar
                          variant="primary"
                          now={(dashboardData?.production * 100) / 2340}
                          // animated
                        /> */}
                        <ProgressBarComponent
                          color="primary"
                          now={(dashboardData?.production * 100) / 2340}
                        />
                      </div>
                    }
                    action={<span>{dashboardData?.production} hrs</span>}
                    role="button"
                    onClick={() => {
                      navigate("/console/employee/attendance");
                    }}
                  />

                  <CardHeader
                    subheader={
                      <div>
                        <span className="mb-2">Present</span>{" "}
                        {/* <ProgressBar
                          variant="success"
                          now={(statusAttend[1] * 100) / 260}
                          // animated
                        /> */}
                        <ProgressBarComponent
                          color="success"
                          now={(statusAttend[1] * 100) / 260}
                        />
                      </div>
                    }
                    action={<span>{statusAttend[1]} time(s)</span>}
                    role="button"
                    onClick={() => {
                      navigate("/console/employee/attendance");
                    }}
                  />
                  <CardHeader
                    subheader={
                      <div>
                        <span className="mb-2">Late</span>{" "}
                        {/* <ProgressBar
                          variant="warning"
                          now={(statusAttend[2] * 100) / 260}
                          // animated
                        /> */}
                        <ProgressBarComponent
                          color="warning"
                          now={(statusAttend[2] * 100) / 260}
                        />
                      </div>
                    }
                    action={<span>{statusAttend[2]} time(s)</span>}
                    role="button"
                    onClick={() => {
                      navigate("/console/employee/attendance");
                    }}
                  />
                  <CardHeader
                    subheader={
                      <div>
                        <span className="mb-2">Absent</span>{" "}
                        {/* <ProgressBar
                          variant="danger"
                          now={(statusAttend[3] * 100) / 260}
                          // animated
                        /> */}
                        <ProgressBarComponent
                          color="danger"
                          now={(statusAttend[3] * 100) / 260}
                        />
                      </div>
                    }
                    action={<span>{statusAttend[3]} time(s)</span>}
                    role="button"
                    onClick={() => {
                      navigate("/console/employee/attendance");
                    }}
                  />
                  <CardHeader
                    subheader={
                      <div>
                        <span className="mb-2">Leave</span>{" "}
                        {/* <ProgressBar
                          variant="info"
                          now={(statusAttend[4] * 100) / 260}
                          // animated
                        /> */}
                        <ProgressBarComponent
                          color="info"
                          now={(statusAttend[4] * 100) / 260}
                        />
                      </div>
                    }
                    action={<span>{statusAttend[4]} day(s)</span>}
                    role="button"
                    onClick={() => {
                      navigate("/console/employee/leaves");
                    }}
                  />
                  <CardHeader
                    subheader={
                      <div>
                        <span className="mb-2">Holidays</span>{" "}
                        {/* <ProgressBar
                          variant="muted"
                          now={(statusAttend[5] * 100) / 260}
                          // animated
                        /> */}
                        <ProgressBarComponent
                          color="muted"
                          now={(statusAttend[5] * 100) / 260}
                        />
                      </div>
                    }
                    action={<span>{statusAttend[5]} day(s)</span>}
                    role="button"
                    onClick={() => {
                      navigate("/console/employee/attendance");
                    }}
                  />
                </CardContent>
              </Card>
            </Col>
            <Col md={4}>
              <CardActionArea>Calendar</CardActionArea>
              <Card className={"shadow"}>
                <CalendarComponent />
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

// export default EmployeeDashboard;
export default connect(mapStateToProps)(EmployeeDashboard);
