import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { Col, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import { fetchDetails } from "../../../services/common";
import { AddForm } from "../../employee/AddForm";

const CreateRole = () => {
  const navigate = useNavigate();
  const [info, setInfo] = React.useState<any>();
  const { id } = useParams();

  const fetchInfoDetails = async (id: string) => {
    const resp = await fetchDetails(`role/view/${id}`);
    if (resp.status) {
      setInfo(resp.data);
    }
  };

  useEffect(() => {
    if (id) {
      fetchInfoDetails(id).then();
    }
  }, [id]);
  return (
    <div>
      <Breadcrumb />
      <Row className={"justify-content-center"}>
        <Col md={5}>
          <AddForm
            inline={true}
            mainFields={[
              {
                title: "",
                fields: [
              {
                label: "Name",
                name: "name",
                type: "name",
                value: info?.name,
                required: true,
              },
              {
                label: "Label",
                name: "label",
                type: "text",
                required: true,
                value: info?.label,
              },
              {
                label: "Main",
                name: "main",
                type: "select",
                required: true,
                value: info?.main?'yes':'no',
                values: [
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]
              },
            ]}]}
            url={id ? `role/update/${id}` : "role/create"}
            method={id ? "PUT" : "POST"}
            title={id ? "Update Role" : "Create New Role"}
            onSuccess={() => navigate(-1)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CreateRole;
