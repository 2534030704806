import { useNavigate, useParams } from "react-router-dom";
import {
  CheckHasPermission,
  fetchDetails,
  getError,
  getFileMime,
} from "../../../services/common";
import React, { useEffect, useRef, useState } from "react";
import "./setting.css";
import Breadcrumb from "../../../components/Breadcrumb";
import PageLoading from "../../../components/PageLoading";
import { Button, Card, Col, Row } from "react-bootstrap";
import {
  Alert,
  AlertTitle,
  CardContent,
  CardHeader,
  Collapse,
  Link,
  Typography,
} from "@mui/material";
import { DoDisturb, BorderColorOutlined, MoreHoriz } from "@mui/icons-material";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/helpers/mapState";
import { mapDispatchToProps } from "../../../store/helpers/mapDispatch";
// import profile from "../../../assets/images.jpg";
// import PayLoan from "../loans/PayLoan";
import moment from "moment";
import { AddForm } from "../../employee/AddForm";
import FileUpload from "../../../components/FileUploader";
import StatusComponent from "../../../components/StatusComponent";
import OpenDocuments from "../../../components/openDocument";
import SmallDialog from "../../../components/SmallModel";

const MemberSettings = (props: any) => {
  const { loggedUser } = props;
  const navigate = useNavigate();
  const [info, setInfo] = useState<any>();
  const [suspendInfo, setSuspendInfo] = useState<any>();
  const [error, setError] = useState<any>();
  const [openSuspend, setOpenSuspend] = useState<any>(0);
  const [fiscalYear, setFiscalYear] = useState<any>();
  const [statistic, setStatistic] = useState<Array<any>>([]);
  const [unPaidLoan, setUnPaidLoan] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [startDate, setStartDate] = useState<any>();
  const [files, setFiles] = React.useState<any>([]);
  const [index, setIndex] = useState<any>(null);
  const [document, setDocument] = useState<any>();
  const [openDocument, setOpenDocument] = useState<any>(false);

  const { id }: any = useParams();
  const currentSuspension = info?.MemberSuspensionHistories?.find(
    (item: any) => item?.status === "suspended" && !item?.closed
  );
  const MemberSuspensionHistory = info?.MemberSuspensionHistories;
  const isOwner = loggedUser?.employeeId === id || id === undefined;
  // const inputRef: any = useRef();

  // const isInService = loggedUser?.Employee?.status === "retired"||loggedUser?.Employee?.status === "exited";
  // const totalLoan = unPaidLoan?.find(
  //   (loan: any) =>loan?.status !== "paid"
  // );

  const loadInfo = async () => {
    const resp = await fetchDetails(
      `caise/view-member/${id ?? loggedUser?.employeeId}`
    );
    const loan = await fetchDetails(`loan/get-unpaid-loan/`);
    if (loan.status) {
      setUnPaidLoan(loan.data);
      setStatistic(loan.data?.statistics);
    }
    if (resp.status) {
      setInfo(resp.data);
    } else {
      setError(getError(resp));
    }
  };

  useEffect(() => {
    loadInfo().then();
  }, [id]);

  const getFiscalYear = async () => {
    const fiscalYear = await fetchDetails("/fiscal/active");
    if (fiscalYear.status) setFiscalYear(fiscalYear.data);
  };
  const fiscalYearDaysRemain = moment(fiscalYear?.endDate).diff(
    moment().format("YYYY-MM-DD"),
    "days"
  );
  useEffect(() => {
    //fetch all FiscalYear
    getFiscalYear().then();
  }, []);

  useEffect(() => {
    if (startDate) {
      const end = moment(startDate).add(6, "months");
      setEndDate(moment(end).format("YYYY-MM-DD"));
    }
  }, [startDate]);

  const contributionInfo = [
    {
      title: "Monthly Contribution",
      amount: `${info?.share?.toLocaleString()}RWF`,
    },
    {
      title: "Total Shares",
      amount: `${info?.totalShare?.toLocaleString()} RWF`,
    },
    { title: "Share rate", amount: `${info?.shareRate?.toLocaleString()}%` },
    {
      title: "Total Penalties",
      amount: `${info?.totalPenalties?.toLocaleString()} RWF`,
    },
  ];
  const pendingLoan = statistic?.find(
    (item: any) => item?.status === "pending"
  );
  const loanInfo1 = [
    {
      title: "Pending for approval",
      amount: pendingLoan?.request,
    },
    {
      title: "Outstanding Loans",
      amount: `${unPaidLoan?.unPaidLoanStatistics?.toLocaleString() ?? 0} RWF`,
    },
  ];
  const loanInfo = [
    {
      title: "Loan",
      amount: `${info?.loanPenalties?.toLocaleString() ?? 0} RWF`,
    },
    {
      title: "Contribution",
      amount: `${info?.contributionPenalities?.toLocaleString() ?? 0} RWF`,
    },
  ];

  return (
    <div>
      <Breadcrumb />
      {!info ? (
        <PageLoading error={error} />
      ) : (
        <>
          <Row>
            {contributionInfo?.map((item) => (
              <Col className={"mb-1"} md={3}>
                <Card className={"shadow mb-2"}>
                  <CardHeader
                    subheader={
                      <Typography style={{fontFamily:'Inter'}} className="fw-bold text-nowrap">
                        {item?.title}
                        <h6 className={"mt-2"}>{item?.amount ?? 0}</h6>
                      </Typography>
                    }
                    action={<MoreHoriz />}
                  />
                </Card>
              </Col>
            ))}
          </Row>
          {/* //loan details summary */}
          <Row>
            <Col className={"mb-2"} md={6}>
              <Card className={"bg-light mb-2"}>
                <CardHeader
                  subheader={
                    <Typography style={{fontFamily:'Inter'}} className="text-nowrap fw-bold">
                      Loan Details
                    </Typography>
                  }
                />
                <CardContent className="row">
                  {loanInfo1?.map((item) => (
                    <Col className={"mb-2"} md={6}>
                      <Card className={"shadow mb-2"}>
                        <CardHeader
                          subheader={
                            <Typography style={{fontFamily:'Inter'}} className="text-nowrap fw-bold">
                              {item?.title}
                              <h6 className={"mt-2"}>{item?.amount ?? 0}</h6>
                            </Typography>
                          }
                          action={<MoreHoriz />}
                        />
                      </Card>
                    </Col>
                  ))}
                </CardContent>
              </Card>
            </Col>
            <Col className={"mb-2"} md={6}>
              <Card className={"bg-light mb-2"}>
                <CardHeader
                  subheader={
                    <Typography style={{fontFamily:'Inter'}} className="text-nowrap fw-bold">
                      Penalty
                    </Typography>
                  }
                />
                <CardContent className="row">
                  {loanInfo?.map((item) => (
                    <Col className={"mb-2"} md={6}>
                      <Card className={"shadow mb-2"}>
                        <CardHeader
                          subheader={
                            <Typography style={{fontFamily:'Inter'}} className="text-nowrap fw-bold">
                              {item?.title}
                              <h6 className={"mt-2"}>{item?.amount ?? 0}</h6>
                            </Typography>
                          }
                          action={<MoreHoriz />}
                        />
                      </Card>
                    </Col>
                  ))}
                </CardContent>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className={"mb-2"} md={12}>
              <Card className={"border-0"}>
                <CardHeader
                  subheader={
                      <Typography style={{fontFamily:'Inter'}} className="fw-bold">
                        Member Infos{" "}
                        <span
                          className={
                            info?.status === "inactive"
                              ? "badge bg-danger rounded-pill"
                              : "badge bg-success rounded-pill"
                          }
                        >
                          {info?.status}
                        </span>
                      </Typography>
                  }
                  action={
                    <div>
                      {(isOwner && fiscalYearDaysRemain > 0) ||
                      CheckHasPermission("approval-saving", "update") ? (
                        <Button
                          className="me-2"
                          onClick={() => {
                            if (isOwner) {
                              navigate(`/console/saving/update/${info?.employeeId}`);
                            } else {
                              navigate(
                                `/console/approveSaving/applications/member/update/${info?.employeeId}`
                              );
                            }
                          }}
                        >
                          <BorderColorOutlined /> Update Info
                        </Button>
                      ) : null}
                      {CheckHasPermission("approval-saving", "update") &&
                      info?.status === "active" ? (
                        <Button
                          className="me-2 bg-danger border-danger"
                          onClick={() => {
                            setOpenSuspend(1);
                          }}
                        >
                          <DoDisturb /> Suspend membership
                        </Button>
                      ) : null}
                    </div>
                  }
                />
                <CardContent>
                  {/* //fiscal year end alert */}
                  {/* {fiscalYearDaysRemain<7? */}
                  {fiscalYearDaysRemain < 7 ? (
                    <Row className={" px-3"}>
                      <Col md={12}>
                      <Alert severity="warning" className="mb-2">
                        <AlertTitle className="fw-bold">Fiscal year alert</AlertTitle>
                          Fiscal year is about to reach its end. As of&nbsp;
                          {moment().format("L")}, there are only{" "}
                          {fiscalYearDaysRemain} days left in the current fiscal
                          year, which is set to conclude on &nbsp;
                          {moment(fiscalYear?.endDate).format("L")}.
                        </Alert>
                      </Col>
                    </Row>
                  ) : null}
                  {info?.contributionSuspensionDate ? (
                    <Row className={" px-3"}>
                      <Col md={12}>
                      <Alert severity="warning" className="mb-2">
                        <AlertTitle className="fw-bold">Bulk Payment</AlertTitle>
                          {isOwner?"You":"This member"} will resume to pay monthly contribution after&nbsp;
                          {moment(info?.contributionSuspensionDate).format("L")}
                          .
                        </Alert>
                      </Col>
                    </Row>
                  ) : null}
                  {info?.status === "suspended" ?(
                         <Row className={" px-3"}>
                         <Col md={12}>
                      <Alert severity="warning" className="mb-2 me-2">
                        <AlertTitle className="fw-bold">
                          Membership suspended
                        </AlertTitle>
                       {isOwner?"Your membership suspended from":"This membership has been suspended from"}
                       &nbsp;{moment(currentSuspension?.suspensionDateFrom).format(
                          "L"
                        )}{" "}
                        to{" "}
                        {moment(currentSuspension?.suspensionDateTo).format(
                          "L"
                        )}{" "}
                        During this suspension, access to our services will be
                        unavailable. If you have any questions, please reach out
                        to CDE Secretary or President&nbsp;{" "}
                        {CheckHasPermission("approval-saving", "update") ? (
                          <Link
                            role="button"
                            // className="text-info"
                            onClick={() => {
                              setOpenSuspend(1);
                              setSuspendInfo(currentSuspension);
                            }}
                          >
                            Click here to re-activate
                          </Link>
                        ) : null}
                        .
                      </Alert>
                      </Col>
                      </Row>
                    ):null}
                  <Row>
                    <Col md={6}>
                      <Card className={"border-0 mb-2"}>
                        <CardHeader
                          subheader={
                            <Typography style={{fontFamily:'Inter'}} className="fw-bold cardHeader">
                              {"Member Identification"}
                            </Typography>
                          }
                        />
                        <CardContent className="cardContent">
                          <div className="row mb-2">
                            <div className="col-md-4 fw-bold">
                              CDE membership number{" "}
                            </div>
                            <div className="col-md-8">{info?.cdeNumber}</div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-4 fw-bold">Firstname</div>
                            <div className="col-md-8">
                              {info?.employee?.firstName}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-4 fw-bold">Civil Status</div>
                            <div className="col-md-8">
                              {info?.employee?.lastName}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-4 fw-bold">National ID</div>
                            <div className="col-md-8">
                              {info?.employee?.nationalId}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-4 fw-bold">
                              Father’s name
                            </div>
                            <div className="col-md-8">
                              {info?.employee?.fatherName}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-4 fw-bold">
                              Mother’s name
                            </div>
                            <div className="col-md-8">
                              {info?.employee?.motherName}
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card className={"border-0 mb-4"}>
                        <CardHeader
                          subheader={
                            <Typography style={{fontFamily:'Inter'}} className="fw-bold cardHeader">
                              {"Next Of Kin Details"}
                            </Typography>
                          }
                        />
                        <CardContent className="cardContent">
                          <div className="row mb-2">
                            <div className="col-md-4 fw-bold">Names</div>
                            <div className="col-md-8">
                              {info?.nextOfKinNames}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-4 fw-bold">Address</div>
                            <div className="col-md-8">
                              {info?.nextOfKinAddress}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-4 fw-bold">Relationship</div>
                            <div className="col-md-8">
                              {info?.nextOfKinRelationShip}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-4 fw-bold">Email</div>
                            <div className="col-md-8">
                              {info?.nextOfKinEmail}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-4 fw-bold">Phone Number</div>
                            <div className="col-md-8">
                              {info?.nextOfKinPhone}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-4 fw-bold">Net Salary</div>
                            <div className="col-md-8">
                              {info?.currentSalary?.toLocaleString()}
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Col>
                  </Row>
                </CardContent>
              </Card>
            </Col>
          </Row>
          {MemberSuspensionHistory?.length > 0 ? (
            <div className={"mb-4 p-3 bg-light"}>
              <Typography style={{fontFamily:'Inter'}} className={"mb-2 fw-bold"}>
                Suspension History
              </Typography>
              <Row className="mb-2 fw-bolder">
                <Col md={2}>Created Date</Col>
                <Col md={2}>Period</Col>
                <Col md={4}>Reason/Comment</Col>
                <Col md={1}>Minutes</Col>
                <Col md={1}>Status</Col>
                <Col md={2}>Done By</Col>
              </Row>
              {MemberSuspensionHistory?.map((item: any) => {
                const v = item?.reason;
                const shortString =
                  index !== item?.id
                    ? v?.substring(0, 60)
                    : v?.substring(0, v?.length);
                return (
                  <Row className="mb-2 border-bottom">
                    <Col md={2}>{moment(item?.createdAt).format("L")}</Col>
                    <Col md={2}>
                      {moment(item?.suspensionDateFrom).format("L")}-
                      {moment(item?.suspensionDateTo).format("L")}
                    </Col>
                    <Col md={4}>
                      {" "}
                      {index === item?.id ? (
                        <Collapse
                          in={index === item?.id}
                          timeout="auto"
                          unmountOnExit
                        >
                          {`${shortString}...`}

                          <span
                            className="text-primary"
                            role="button"
                            onClick={() => {
                              setIndex(null);
                            }}
                          >
                            less
                          </span>
                        </Collapse>
                      ) : (
                        <>
                          {`${shortString}...`}
                          <span
                            className="text-primary"
                            role="button"
                            onClick={() => {
                              setIndex(item?.id);
                            }}
                          >
                            more
                          </span>
                        </>
                      )}
                    </Col>
                    <Col md={1}>
                      {item?.minutes ? (
                        <Link
                          role="button"
                          onClick={() => {
                            setOpenDocument(true);
                            setDocument(item?.minutes);
                          }}
                        >
                          View file
                        </Link>
                      ) : null}
                    </Col>
                    <Col md={1}>
                      <StatusComponent status={item?.status} badge={true} />
                    </Col>
                    <Col md={2}>{item?.User?.Employee?.names}</Col>
                  </Row>
                );
              })}
            </div>
          ) : null}
          <AddForm
            title={
              suspendInfo?.id ? "Membership activate" : "Request suspension"
            }
            maxWidth="lg"
            formAlert={
              <div>
                <strong>
                  {suspendInfo?.id
                    ? "Are you sure you want to activate this membership?"
                    : "N.B :Suspension period must be 6 months or less."}
                </strong>
              </div>
            }
            mainFields={[
              {
                title: "",
                fields: [
                  {
                    label: "Date from",
                    name: "suspensionDateFrom",
                    type: "date",
                    required: suspendInfo?.status !== "suspended",
                    hidden: suspendInfo?.status === "suspended",
                    col: 12,
                    minDate: moment().startOf("day"),
                    setValue: setStartDate,
                  },
                  {
                    label: "Date to",
                    name: "suspensionDateTo",
                    type: "date",
                    required: suspendInfo?.status !== "suspended",
                    hidden: suspendInfo?.status === "suspended",
                    col: 12,
                    minDate: moment(startDate).startOf("day"),
                    maxDate: moment(endDate).endOf("day"),
                    setValue: setEndDate,
                  },
                  {
                    label: "Minutes",
                    name: "supportingDocument",
                    type: "file",
                    multiple: true,
                    // required: !suspendInfo?.id,
                    col: 12,
                    value: files,
                    render: () => {
                      return (
                        <FileUpload
                          setFiles={setFiles}
                          files={files}
                          // required={!suspendInfo?.id}
                          accept={"application/pdf"}
                        />
                      );
                    },
                  },
                  {
                    label: "Inform a member",
                    name: "reason",
                    type: "textarea",
                    required: true,
                    col: 12,
                    // value: info?.reason,
                  },
                  {
                    label: "id",
                    name: "id",
                    col: 12,
                    hidden: true,
                    value:
                      suspendInfo?.status === "suspended"
                        ? suspendInfo?.id
                        : null,
                  },
                ],
              },
            ]}
            url={`caise/suspend-member/${info?.id}`}
            method={"PUT"}
            onSuccess={() => {
              setOpenSuspend(0);
              setSuspendInfo(null);
              loadInfo().then();
            }}
            open={openSuspend}
            setOpen={() => setOpenSuspend(0)}
          />
        </>
      )}
      {/* ====================================view document================= */}
      <SmallDialog
        maxWidth="lg"
        title={"Minutes"}
        buttonTitle="Close"
        needButton={true}
        open={openDocument}
        setOpen={setOpenDocument}
        onSubmit={() => {
          setOpenDocument(false);
        }}
      >
        <div className={"row justify-content-center"}>
          <div className={"col-md-7"}>
            {document?.map((v: string) => (
              <OpenDocuments
                downloadable={true}
                card={true}
                document={v}
                mimeType={getFileMime(v)}
              />
            ))}
          </div>
        </div>
      </SmallDialog>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberSettings);
