import {Alert, CircularProgress, IconButton} from "@mui/material";
import * as React from "react";
import {Refresh} from "@mui/icons-material";


function PageLoading({error,onRefresh} : any){
    return <div className={'row align-items-center justify-content-center'} style={{minHeight:"70vh"}}>
        {error ? <div className={'col-md-6'}>
                <Alert
                    style={{width: "100%"}}
                    severity={"error"}
                    action={<IconButton onClick={onRefresh}>
                        <Refresh/>
                    </IconButton>}
                >
                    {error}
                </Alert>
        </div> : <CircularProgress size={70}/>}
    </div>
}

export default PageLoading;
