/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import useStyles from "./style";
// import NavigationBar from "../NavBar2";
import myTheme from "./theme";
import { ToastContainer } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import SideBar from "../components/SideBar2";
import NavigationBar from "../components/NavigationBar2";
import { mapStateToProps } from "../store/helpers/mapState";
import { mapDispatchToProps } from "../store/helpers/mapDispatch";
import { connect } from "react-redux";
import useAuth from "../hooks/useAuth";
import { io } from "socket.io-client";
import jwt_decode from "jwt-decode";
import { logout, setPermission } from "../services/MockAuthService";
import { useIdleTimer } from "react-idle-timer";

const LayoutDraft = (props: any) => {
  const { GetLoggedUser, socket, loggedUser, SetSocket } = props;
  const [isTimedOut, setIsTimedOut] = React.useState(false);
  // const [remaining, setRemaining] = React.useState<number>(0);

  const navigate = useNavigate();


  const handleOnIdle = async (event: any) => {
    if (isTimedOut) {
      await logout();
      navigate("/login");
    } else {
      reset();
      setIsTimedOut(true);
    }
  };

  const handleOnActive = (event: any) => {
    setIsTimedOut(false);
    reset();
  };

  const handleOnAction = (e: any) => {
    setIsTimedOut(false);
    reset();
  };

  const {  reset } = useIdleTimer({
    timeout: 1800000, //idle for 30 min
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    // debounce: 500
  });
  const [showBlueNav, setShowBlueNav] = React.useState<any>(true);
  const [open, setOpen] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(true);
  const auth = useAuth();
  const { pathname } = useLocation();
  const user = auth.getSession();

  const token: any = user ? jwt_decode(user?.token) : null;
  const expiresIn = token?.exp;

  const getLoggedUser = async (userId: string) => {
    await GetLoggedUser(userId);
  };

  const socketIO = io(`${process.env.REACT_APP_SOCKET_URL}`, {
    path: "/ws",
  });

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setRemaining(getRemainingTime());
  //   }, 500);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // });

  useEffect(() => {
    if (user?.token) {
      const token: any = user ? jwt_decode(user?.token) : null;
      const win: any = window;
      const { permission } = token;
      win.permissions = permission;
      getLoggedUser(token.userId).then();
      setPermission(user);
    }
  }, []);

  useEffect(() => {
    if (socket === null) {
      SetSocket(socketIO);
    }
    // if (socket && loggedUser?.id) {
    //   socket?.emit("newUser", loggedUser?.id);
    // }
  }, [SetSocket, socket]);

  useEffect(() => {
    if (expiresIn < Math.floor(Date.now() / 1000)) {
      logout().then();
      navigate("/login");
    }
  }, [expiresIn]);

  useEffect(() => {
    if (socket && loggedUser?.id) {
      socket?.emit("newUser", loggedUser?.id);
    }
  }, [socket, loggedUser?.id]);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  const isSm = useMediaQuery({ maxWidth: 992 });
  if (auth?.isAuth()) {
    return (
      <ThemeProvider theme={myTheme}>
        <div
          className={classes.root}
          style={isSm ? { display: "block" } : undefined}
        >
          <CssBaseline />
          <ToastContainer />
          <NavigationBar
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            openDrawer={openDrawer}
            loggedUser={loggedUser}
            GetLoggedUser={GetLoggedUser}
            socket={socket}
            showBlueNav={showBlueNav}
            setShowBlueNav={setShowBlueNav}
          />
          <SideBar
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            loggedUser={loggedUser}
          />
          {/* <main className={classes.content+" bg-dark"} >
            <div className={classes.toolbar} />
            <div
                style={{
                    marginTop: "15vh",
                  }}
            > */}
          {/* <Outlet /> */}
          {/* </div> */}
          {/* <Footer /> */}
          {/* </main> */}
          <main className={classes.content} style={{ overflow: "hidden" }}>
            <div className={classes.toolbar} />
            <div
              style={{
                marginTop: showBlueNav ? "12vh" : "0vh",
              }}
            >
              <Outlet />
            </div>
            <Footer />
          </main>
        </div>
      </ThemeProvider>
    );
  }

  return (
    <Navigate to={`/login?redirect=${encodeURIComponent(pathname)}`} replace />
  );
};
// export default LayoutDraft;
export default connect(mapStateToProps, mapDispatchToProps)(LayoutDraft);
