import {
  DatatableComponent,
  FilterItem,
  SerializeObject,
} from "./DatatableComponent";
import { Col, FormGroup, Row } from "react-bootstrap";
import Select from "react-select";
import { useEffect, useState } from "react";
import { inArray } from "../services/common";
import { Button } from "@mui/material";
import { Clear, Search, Send } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

type IProps = {
  url: string;
  columns?: Array<any>;
  filters: Array<FilterItem>;
  title: string;
  value: any;
  setValue: (value: any) => void;
  setList?: (value: any) => void;
};
function ReportEngineComponent(props: IProps) {
  const { filters, url, columns, title, setValue, value, setList } = props;

  const [newFilters, setNewFilters] = useState<Array<any>>([]);
  const [newColumns, setNewColumns] = useState<Array<any>>([]);

  const [full, setFull] = useState(url);
  const navigate = useNavigate();

  useEffect(() => {
    if (columns?.length) {
      setNewColumns([...columns]);
    } else {
      setNewColumns(
        filters.filter((v) => v.column && !value[v.name]).map((v) => v.column)
      );
    }

    setNewFilters([...filters]);
  }, [columns, filters, value]);

  // useEffect(() => {
  //   let newUrl = url;

  //   const keys = Object.keys(value);

  //   if (keys.length) {
  //     const and = newUrl.includes("?") ? "&" : "?";
  //     newUrl =
  //       newUrl +
  //       and +
  //       keys
  //         .map(
  //           (value1) =>
  //             `${encodeURIComponent(value1)}=${encodeURIComponent(
  //               value[value1].value
  //             )}`
  //         )
  //         .join("&");
  //     newUrl =
  //       newUrl +
  //       `&${SerializeObject({
  //         fObjects: keys.map((v) => ({
  //           name: newFilters.find((it) => it.name === v)?.label ?? v,
  //           value: value[v].label,
  //         })),
  //       })}`;
  //   }
  //   setFull(newUrl);
  // }, [url, value]);
  const isIncomeStatement: any = value?.incomeStatement?.label?.toLowerCase();

  return (
    <div>
      <form
        onSubmit={(e: any) => {
          e.preventDefault();
          let newUrl = url;

          const keys = Object.keys(value);

          if (keys.length) {
            const and = newUrl.includes("?") ? "&" : "?";
            newUrl =
              newUrl +
              and +
              keys
                .map(
                  (value1) =>
                    `${encodeURIComponent(value1)}=${encodeURIComponent(
                      value[value1].value
                    )}`
                )
                .join("&");
            newUrl =
              newUrl +
              `&${SerializeObject({
                fObjects: keys.map((v) => ({
                  name: newFilters.find((it) => it.name === v)?.label ?? v,
                  value: value[v].label,
                })),
              })}`;
          }
          setFull(newUrl);
        }}
      >
        <Row className={"align-items-md-center"}>
          {newFilters.map((v) =>
            v.hidden ? null : (
              <Col md={3}>
                <FormGroup className={"mb-3"}>
                  <label>
                    {v.label}
                    {v.required ? <span className="text-danger">*</span> : null}
                  </label>
                  {inArray(["text", "number", "date"], v.type) ? (
                    <input
                      className={"form-control"}
                      value={value[v.name]?.value ?? ""}
                      onChange={(e) =>
                        setValue((old: any) => ({
                          ...old,
                          [v.name]: { value: e.target.value },
                        }))
                      }
                      type={v.type}
                      required={v.required}
                    />
                  ) : (
                    <Select
                    className={"text-nowrap"}
                      value={value[v.name] ?? null}
                      onChange={(newValue) =>
                        setValue((old: any) => ({ ...old, [v.name]: newValue }))
                      }
                      options={v.values}
                      required={v.required}
                    />
                  )}
                </FormGroup>
              </Col>
            )
          )}
          <Col md={3} className="d-flex">
            <Button
              startIcon={<Search />}
              type="submit"
              color="inherit"
              variant="contained"
              // size="small"
              className="me-3 mt-2"
            >
              Search
            </Button>
            <Button
              color={"error"}
              startIcon={<Clear />}
              // size="small"
              className="mt-2"
              variant={"contained"}
              onClick={() => {
                setValue({});
                navigate(0);
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>
      </form>
      <hr />
      <DatatableComponent
        notExcel={isIncomeStatement === "yes"}
        filteredValues={value}
        columns={newColumns}
        url={full}
        title={title}
        exportButtons={true}
        summaryFooter={true}
        processData={(data: any) => {
          if (setList) setList(data);
          return data;
        }}
      />
    </div>
  );
}

export default ReportEngineComponent;
