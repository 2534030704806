import { Menu, MenuProps, Tooltip, alpha, styled } from "@mui/material";
import { useState } from "react";
import {
  BorderColorOutlined,
  DeleteOutline,
  InfoOutlined,
  Save,
} from "@mui/icons-material";
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    id="long-menu"
    MenuListProps={{
      "aria-labelledby": "long-button",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 100,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        // marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const CustomizedMenus = (props: any) => {
  const { onDelete, onView, onEdit, onSave, needSave, needEdit, needDelete } =
    props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="d-inline-block">
      {onSave && needSave ? (
        <Tooltip title="Save">
          <Save
            color="success"
            fontSize="small"
            role="button"
            onClick={() => {
              handleClose();
              onSave();
            }}
          />
        </Tooltip>
      ) : null}
      {onEdit && needEdit ? (
        <Tooltip title="Update" className={"border-0 bg-transparent"}>
          <BorderColorOutlined
            onClick={() => {
              handleClose();
              onEdit();
            }}
            role="button"
            fontSize="small"
            className={"text-secondary cursor-pointer"}
          />
        </Tooltip>
      ) : null}

      {onView ? (
        // <button title="view details" className="border-0 bg-transparent">
        <Tooltip title="Details" className={"border-0 bg-transparent"}>
          <InfoOutlined
            onClick={() => {
              handleClose();
              onView();
            }}
            role="button"
            fontSize="small"
            className="text-secondary cursor-pointer mr-4"
          />
        </Tooltip>
      ) : null}
      {onDelete && needDelete ? (
        <Tooltip title="Delete" className={"border-0 bg-transparent"}>
          <DeleteOutline
            onClick={() => {
              handleClose();
              onDelete();
            }}
            fontSize="small"
            role="button"
            className={"text-secondary cursor-pointer"}
          />
        </Tooltip>
      ) : null}
    </div>
  );
};
