import Breadcrumb from "../../../../components/Breadcrumb";
import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FormDialog } from "../../../../components/FormDialog";
import { useNavigate, useParams } from "react-router-dom";
import { AddForm } from "../../AddForm";
import { fetchDetails } from "../../../../services/common";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../../store/helpers/mapState";
import { mapDispatchToProps } from "../../../../store/helpers/mapDispatch";
// import { Button } from "@mui/material";
// import { Add } from "@mui/icons-material";
import FileUpload from "../../../../components/FileUploader";
import GeneralButton from "../../../../components/Button";

const CreateTransfer = (props: any) => {
  const { loggedUser } = props;

  const navigate = useNavigate();
  // const [requestFor, setRequestFor] = React.useState<string>("other");
  const [employeeId, setEmployeeId] = React.useState<string>("");
  const [employee, setEmployee] = React.useState<any>([]);
  const [department, setDepartment] = React.useState<any>([]);
  const [departmentToList, setDepartmentToList] = React.useState<any>([]);
  const [departmentFrom, setDepartmentFrom] = React.useState<any>();
  // const [position, setPosition] = React.useState<any>([]);
  const [branch, setBranch] = React.useState<any>([]);
  const [files, setFiles] = React.useState<any>([]);
  const [info, setInfo] = React.useState<any>({});
  const [branchFrom, setBranchFrom] = React.useState<any>();
  const [branchTo, setBranchTo] = React.useState<any>();

  const { id }: any = useParams();
  // const isApprover = loggedUser?.id === info?.approver; //the transfer approver
  const fetchInfoDetails = async (id: string) => {
    const resp = await fetchDetails(`transfer/view/${id}`);
    if (resp.status) {
      // setInfo(resp.data);
      setInfo({
        ...resp.data,
        firstName: resp.data?.Employee?.firstName,
        lastName: resp.data?.Employee?.lastName,
        transferee: resp?.data?.transferee,
      });
      setBranchFrom(resp.data?.branchFrom);
      setBranchTo(resp.data?.branchTo);
      setEmployeeId(resp?.data?.transferee);
      setDepartmentFrom(resp.data?.departmentFrom);
      // await GetDepartment(resp.data?.branchFrom, "from");
      // await GetDepartment(resp.data?.branchTo, "to");
      // await GetEmployee(resp.data?.departmentFrom);
    }
  };

  useEffect(() => {
    //laod transfer details to be edited or updated
    if (id !== "new") {
      fetchInfoDetails(id).then();
    }
  }, []);

  useEffect(() => {
    laodInitialData();
  }, []);
  const GetEmployee = async (departmentId: string) => {
    //get all users
    const list = await fetchDetails(`/department/employee/${departmentId}`);

    let newEmployee = [];
    let data = list?.data;
    for (let emp = 0; emp < data?.length; emp++) {
      newEmployee.push({
        id: data[emp]?.id,
        value: data[emp]?.id,
        label: `${data[emp]?.firstName} ${data[emp]?.lastName}`,
        firstName: data[emp]?.firstName,
        lastName: data[emp]?.lastName,
        departmentFrom: departmentId,
        positionFrom: data[emp]?.positionId,
        employeeId: data[emp]?.id,
      });
    }
    setEmployee(newEmployee);
  };
  const GetDepartment = async (branchId: string, type: string) => {
    //get all users

    const list = await fetchDetails(`/department/branch/${branchId}`);
    let data = list?.data;
    if (list.status) {
      if (type === "from") {
        setDepartment(data);
      } else {
        setDepartmentToList(data);
      }
    }
  };

  useEffect(() => {
    if (departmentFrom) {
      GetEmployee(departmentFrom).then();
    }
  }, [departmentFrom]);

  useEffect(() => {
    if (branchFrom) {
      GetDepartment(branchFrom, "from").then();
    }
  }, [branchFrom]);
  useEffect(() => {
    if (branchTo) {
      GetDepartment(branchTo, "to").then();
    }
  }, [branchTo]);

  useEffect(() => {
    if (employeeId) {
      // auto file employee field
      const user = employee.filter((emp: any) => emp.id === employeeId);
      setInfo({
        ...info,
        firstName: user[0]?.firstName,
        lastName: user[0]?.lastName,
        positionFrom: user[0]?.positionFrom,
        transferee: user[0]?.employeeId,
      });
    }
  }, [employeeId]);

  const laodInitialData = async () => {
    // const positionList = await fetchDetails("/position/view");
    const branchList = await fetchDetails("/branch/view");

    // setPosition(
    //   positionList?.data?.map((v: any) => ({ label: v.name, value: v.id }))
    // );
    if (branchList.status) {
      setBranch(
        branchList?.data?.map((v: any) => ({ label: v.name, value: v.id }))
      );
    }
  };
  return (
    <div>
      <Breadcrumb activeLabel={id !== "new" ? "Edit" : "Create"} />
      <Row className={"justify-content-center"}>
        <Col md={12}>
          <AddForm
            title={id !== "new" ? "Edit Mutation Request" : "Mutation Request"}
            inline={true}
            col={6}
            mainFields={[
              {
                title: "Transferred From",
                fields: [
                  {
                    label: "status",
                    name: "status",
                    hidden: true,
                    value: "pending",
                  },
                  {
                    label: "Branch",
                    name: "branchFrom",
                    type: "select",
                    required: true,
                    values: branch,
                    col: 4,
                    value: branchFrom,
                    setValue: setBranchFrom,
                    //  (val) => {
                    //   setInfo({
                    //     ...info,
                    //     branchFrom: val,
                    //   });
                    //   GetDepartment(val, "from");
                    // },
                  },
                  {
                    label: "Department",
                    name: "departmentFrom",
                    type: "select",
                    required: true,
                    values: department?.map((v: any) => ({
                      label: v.name,
                      value: v.id,
                    })),
                    col: 4,
                    value: departmentFrom,
                    setValue: setDepartmentFrom,
                  },
                  {
                    label: "Employee Name",
                    name: "transferee",
                    required: true,
                    type: "select",
                    col: 4,
                    value: info?.transferee,
                    values: employee,
                    setValue: setEmployeeId,
                    // render: () => {
                    //   return (
                    //     <div>
                    //       <div className="input-group mb-3">
                    //         <input type="text" className="form-control" />
                    //         <GeneralButton name="Search" onClick={() => {}} />
                    //       </div>
                    //     </div>
                    //   );
                    // },
                  },
                ],
              },
              {
                title: "",
                fields: [
                  // {
                  //   label: "transferee",
                  //   name: "transferee",
                  //   hidden: true,
                  //   col: 4,
                  //   value: info.transferee,
                  // },
                  {
                    label: "Requestor",
                    name: "requestor",
                    hidden: true,
                    col: 4,
                    value: loggedUser?.id,
                  },

                  {
                    label: "First Name",
                    name: "firstName",
                    disabled: true,
                    col: 6,
                    value: info?.firstName,
                  },
                  {
                    label: "Last Name",
                    name: "lastName",
                    disabled: true,
                    col: 6,
                    value: info?.lastName,
                  },
                  // {
                  //   label: "Position",
                  //   name: "positionFrom",
                  //   type: "select",
                  //   disabled: true,
                  //   values: position,
                  //   col: 4,
                  //   value: info?.positionFrom,
                  // },
                ],
              },

              {
                title: "Transferred To",
                fields: [
                  {
                    label: "Branch",
                    name: "branchTo",
                    type: "select",
                    required: true,
                    values: branch,
                    col: 6,
                    value: branchTo,
                    setValue: setBranchTo,
                    //  (val) => {
                    //   setInfo({
                    //     ...info,
                    //     branchTo: val,
                    //   });
                    //   GetDepartment(val, "to");
                    // },
                  },
                  {
                    label: "Department",
                    name: "departmentTo",
                    type: "select",
                    required: true,
                    values: departmentToList?.map((v: any) => ({
                      label: v.name,
                      value: v.id,
                    })),
                    col: 6,
                    value: info?.departmentTo,
                  },
                  // {
                  //   label: "Position",
                  //   name: "positionTo",
                  //   type: "select",
                  //   required: true,
                  //   values: position,
                  //   col: 4,
                  //   value: info?.positionTo,
                  // },
                ],
              },
              {
                title: "Reason & Support document",
                fields: [
                  {
                    label: "Reason of Mutation",
                    name: "reason",
                    type: "textarea",
                    required: true,
                    col: 12,
                    value: info?.reason,
                  },
                  {
                    label: "Support document",
                    name: "supportingDocument",
                    type: "file",
                    multiple: true,
                    col: 12,
                    value: files,
                    render: () => {
                      return <FileUpload setFiles={setFiles} files={files} />;
                    },
                  },
                ],
              },
            ]}
            // title={"Create New Employee"}
            url={id !== "new" ? `transfer/update/${id}` : "/transfer/create"}
            method={id !== "new" ? "PUT" : "POST"}
            onSuccess={() => {
              navigate(-1);
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

// export default CreateTransfer;
export default connect(mapStateToProps, mapDispatchToProps)(CreateTransfer);
