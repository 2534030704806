import { connect } from "react-redux";
import { mapStateToProps } from "../../store/helpers/mapState";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import { Col, Row } from "react-bootstrap";
import { AddForm } from "../employee/AddForm";
import React, { useEffect } from "react";
import { fetchDetails, validateFileNotNull } from "../../services/common";
import moment from "moment";
import {
  Alert,
  AlertTitle,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import FileUpload from "../../components/FileUploader";

const CreateRequest = (props: any) => {
  const { loggedUser } = props;
  const [info, setInfo] = React.useState<any>({});
  const [hidden, setHidden] = React.useState<any>();
  const [position, setPosition] = React.useState<any>([]);
  const [requestType, setRequestType] = React.useState<any>([]);
  const [files, setFiles] = React.useState<any>([]);

  const { id }: any = useParams();
  const isApprover = loggedUser?.id === info?.approver&&loggedUser?.id!==info?.requester; //the approver

  const fetchInfoDetails = async (id: string) => {
    const resp = await fetchDetails(`service-request/view/${id}`);
    if (resp.status) {
      const a = moment(new Date());
      const b = moment(loggedUser?.Employee?.birthDate);
      // setInfo(resp.data);
      setInfo({
        ...resp.data,
        // firstName: loggedUser?.Employee?.firstName,
        // lastName: loggedUser?.Employee?.lastName,
        // positionId: loggedUser?.Employee?.positionId,
        // requester: loggedUser?.id,
        birthDate: moment(loggedUser?.Employee?.birthDate).format("DD/MM/YYYY"),
        age: a.diff(b, "years"),
        serviceCertLastDate: moment().diff(
          moment(loggedUser?.Employee?.serviceCertLastDate),
          "months"
        ),
      });
      setRequestType(resp.data?.requestType);
    }
  };
  useEffect(() => {
    if (id !== "new") {
      fetchInfoDetails(id).then();
    }
  }, [id]);
  useEffect(() => {
    laodInitialData();
  }, []);
  useEffect(() => {
    if (loggedUser) {
      const a = moment(new Date());
      const b = moment(loggedUser?.Employee?.birthDate);
      setInfo({
        ...info,
        firstName: loggedUser?.Employee?.firstName,
        lastName: loggedUser?.Employee?.lastName,
        positionId: loggedUser?.Employee?.positionId,
        requester: loggedUser?.id,
        birthDate: moment(loggedUser?.Employee?.birthDate).format("DD/MM/YYYY"),
        age: a.diff(b, "years"),
        serviceCertLastDate: moment().diff(
          moment(loggedUser?.Employee?.serviceCertLastDate),
          "months"
        ),
      });
    }
  }, [loggedUser]);

  const laodInitialData = async () => {
    const positionList = await fetchDetails("/position/view");
    if (positionList.status) setPosition(positionList?.data);
  };
  const navigate = useNavigate();

      return (
    <div>
      <Breadcrumb activeLabel={id !== "new" ? "Edit" : "Create"} />
      <Row className={"justify-content-center"}>
        <Col md={12}>
          <AddForm
            inline={true}
            title={id !== "new" ? "Edit Self Request" : "Self Service Request"}
            col={6}
            mainFields={[
              {
                title: "",
                fields: [
                  {
                    label: "Requestor",
                    name: "requester",
                    hidden: true,
                    col: 4,
                    value: info?.requester,
                  },
                  {
                    label: "status",
                    name: "status",
                    hidden: true,
                    col: 4,
                    value: isApprover ? info?.status : "pending",
                  },
                  {
                    label: "First Name",
                    name: "firstName",
                    disabled: true,
                    col: 4,
                    value: info?.firstName,
                  },
                  {
                    label: "Last Name",
                    name: "lastName",
                    disabled: true,
                    col: 4,
                    value: info?.lastName,
                  },
                  {
                    label: "Position",
                    name: "positionId",
                    disabled: true,
                    col: 4,
                    value: loggedUser?.Employee?.position?.name,
                  },
                  {
                    label: "Date of Birth",
                    name: "birthDate",
                    hidden: !hidden?.retirement,
                    disabled: true,
                    col: 6,
                    value: info?.birthDate,
                  },
                  {
                    label: "Age",
                    name: "age",
                    hidden: !hidden?.retirement,
                    disabled: true,
                    col: 6,
                    value: info?.age,
                  },
                  {
                    // label: "Request Type",
                    name: "requestType",
                    // type: "select",
                    required: true,
                    col: 6,
                    value: requestType,
                    render: () => {
                      return (
                        <div className={"mb-3 row bg-light  border"}>
                          {requestType?.includes("service certificate") &&
                          info?.serviceCertLastDate <= 6 ? (
                            <div className="col-md-12 mb-4 p-3">
                              <Alert severity="warning">
                                <AlertTitle>Warning</AlertTitle>
                                Your are not allowed to ask for service
                                certificate at this moment —{" "}
                                <strong>
                                  {"This Document issued once in 6 months"}
                                </strong>
                              </Alert>
                            </div>
                          ) : null}
                          <Typography style={{fontFamily:'inter'}} className="col-md-12 fw-bold ">
                            Which Document are you requesting?
                          </Typography>
                          {[
                            "lumpsum certificate",
                            "salary certificate",
                            "service certificate",
                            "employment certificate",
                            // "anticipative retirement",
                            "retirement certificate",
                          
                            // "loan form",
                            // "other document"
                          ].map((type: any) => (
                            <List className="col-md-3">
                              <ListItem
                                key={type}
                                onClick={() => {
                                  let requestCopy = [...requestType];
                                  if (requestCopy?.includes(type)) {
                                    const index = requestCopy.indexOf(type);
                                    requestCopy.splice(index, 1);
                                  } else {
                                    requestCopy.push(type);
                                  }

                                  setRequestType(requestCopy);
                                  if (
                                    requestCopy?.includes("retirement") ||
                                    requestCopy?.includes(
                                      "anticipative retirement"
                                    )
                                  ) {
                                    setHidden({ ...hidden, retirement: true });
                                  } else {
                                    setHidden({ ...hidden, retirement: false });
                                  }
                                }}
                              >
                                <ListItemAvatar>
                                  <Checkbox
                                    required={requestType.length === 0}
                                    checked={requestType?.includes(type)}
                                  />{" "}
                                </ListItemAvatar>
                                <ListItemText primary={type} className="text-nowrap"/>
                              </ListItem>
                            </List>
                          ))}
                        </div>
                      );
                    },
                  },
                ],
              },
              {
                title: requestType?.includes("service certificate")
                  ? "Specify which period(for service certificate)"
                  : "",
                fields: [
                  {
                    label: "From ",
                    name: "fromDate",
                    type: "date",
                    required: true,
                    hidden: !requestType?.includes("service certificate"),
                    col: 6,
                    value: info?.fromDate,
                  },
                  {
                    label: "To",
                    name: "toDate",
                    type: "date",
                    required: true,
                    hidden: !requestType?.includes("service certificate"),
                    col: 6,
                    value: info?.toDate,
                    maxDate: moment().startOf("day"),
                  },
                  {
                    label: "Reason",
                    name: "reason",
                    type: "textarea",
                    required: true,
                    col: 12,
                    value: info?.reason,
                  },
                  // {
                  //   label: "Loan form",
                  //   name: "bankForm",
                  //   type: "file",
                  //   multiple: true,
                  //   required: id === "new",
                  //   hidden: !requestType?.includes("loan form"),
                  //   col: 12,
                  //   value: bankFiles,
                  //   validate:validateFileNotNull,
                  //   render: () => {
                  //     return <FileUpload setFiles={setBankFiles} files={bankFiles} required={id === "new"} title="Upload"/>;
                  //   },
                  // },
                  {
                    label: "Document(ID copy and Appointment letter)",
                    name: "retirementDocument",
                    type: "file",
                    hidden:!requestType?.includes("retirement"),
                    multiple: true,
                    required: requestType?.includes("retirement") && !info?.id,
                    col: 12,
                    value: files,
                    render: () => {
                      return (
                        <FileUpload
                          setFiles={setFiles}
                          files={files}
                          title="Upload"
                          required={requestType?.includes("retirement") && !info?.id}
                          currentFile={info?.supportingDocument}
                        />
                      );
                    },
                  },
                  {
                    label:"Supporting document",
                    name: "supportingDocument",
                    type: "file",
                    multiple: true,
                    hidden:requestType?.includes("retirement"),
                    col: 12,
                    value: files,
                    render: () => {
                      return (
                        <FileUpload
                          setFiles={setFiles}
                          files={files}
                          title="Upload"
                          required={false}
                          currentFile={info?.supportingDocument}
                        />
                      );
                    },
                  },
                ],
              },
            ]}
            // appendForm={
            //   <div>
            //      <Approver onSuccess={(val) => setSupervisor(val)}/>
            //   </div>
            // }
            url={
              id !== "new"
                ? `service-request/update/${id}`
                : "/service-request/create"
            }
            method={id !== "new" ? "PUT" : "POST"}
            onSuccess={() => {
              navigate(-1);
            }}
          />
        </Col>
      </Row>
    </div>
  );
};
export default connect(mapStateToProps)(CreateRequest);
