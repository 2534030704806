import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/helpers/mapState";
import { mapDispatchToProps } from "../../../store/helpers/mapDispatch";
import {
  fetchDetails,
  getError,
  getFileMime,
  submitGeneralForm,
} from "../../../services/common";
import Breadcrumb from "../../../components/Breadcrumb";
import PageLoading from "../../../components/PageLoading";
import { Card, Col, Row } from "react-bootstrap";
import {
  Alert,
  Button,
  CardContent,
  CardHeader,
  CircularProgress,
  Skeleton,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import StatusComponent from "../../../components/StatusComponent";
import {
  Block,
  Check,
  Close,
  BorderColorOutlined,
  FileOpen,
  HighlightOff,
  Info,
  PaymentSharp,
  QuestionMark,
  Undo,
  Upload,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import RequestHistoryProcess from "../../../components/RequestHistory";
import SmallDialog from "../../../components/SmallModel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";
import FileUpload from "../../../components/FileUploader";
import GeneralButton from "../../../components/Button";
import OpenDocuments from "../../../components/openDocument";
import LoanPaymentHistory from "./LoanPaymentHistory";
import PayLoan from "../../saving/loans/PayLoan";
import PayBulk from "../../saving/loans/PayBulk";
import LoanCollateral from "./LoanCollateral";

function LoanDetails(props: any) {
  const { loggedUser } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [info, setInfo] = useState<any>();
  const [error, setError] = useState<any>();
  const [confirm, setConfirm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDialogue, setOpenDialogue] = useState<boolean>(false);
  const [needFile, setNeedFile] = useState<boolean>(false);
  const [viewDraftContract, setViewDraftContract] = useState<boolean>(false);
  const [viewFinalContract, setViewFinalContract] = useState(false);
  const [document, setDocument] = useState<any>();
  const [draftContractFile, setDraftContractFile] = useState<any>([]);
  const [openDocument, setOpenDocument] = useState<any>(false);
  const [newData, setData] = useState<any>();
  const [modalTitle, setModalTitle] = useState<any>();
  const [addPayment, setAddPayment] = useState(0);

  const loadInfo = async () => {
    const resp = await fetchDetails(`loan/view/${id}`);
    if (resp.status) {
      setInfo(resp.data);
    } else {
      setError(getError(resp));
    }
  };

  useEffect(() => {
    loadInfo().then();
  }, [id]);

  const onSubmit = async (data?: any) => {
    setLoading(true);
    setConfirm(false);
    let infoToSave = data ?? newData;
    const formData = new FormData();
    formData.append("body", JSON.stringify(infoToSave));

    if (draftContractFile && infoToSave.status !== "approved") {
      for (let i = 0; i < draftContractFile.length; i++) {
        formData.append("draftContract", draftContractFile[i]);
      }
    }
    if (draftContractFile && infoToSave.status === "approved") {
      for (let i = 0; i < draftContractFile.length; i++) {
        formData.append("finalDocument", draftContractFile[i]);
      }
    }
    const url =
      infoToSave?.status === "canceled" ||
      infoToSave?.status === "pending" ||
      infoToSave?.status === "request-suspension"
        ? `loan/update/${id}`
        : `/approvalLoan/change-status/${id}`;

        const resp = await submitGeneralForm(url, formData, null, "PUT");
    if (resp.status) {
      setData({});
      setError(null);
      setOpenDialogue(false);
      loadInfo().then();
    } else {
      setError(getError(resp));
    }
    setLoading(false);
  };

  const history = info?.LoanPaymentHistories ?? [];
  const isInitiator = loggedUser?.employeeId === info?.CaisseMember?.employeeId;
  const canApprove = loggedUser?.id === info?.approverId;

  const canUpdate =
    isInitiator &&
    (info?.status === "pending" ||
      info?.status === "request action" ||
      info?.status === "contract drafting");
  let steps =
    info?.status !== "rejected"
      ? [
          "Receive loan request",
          "Inform applicant that request received",
          "Inform applicant that request approved",
          "Share a draft contract with applicant to be signed",
          "Receive signed contract from applicant",
          "Signed contract & approve loan",
        ]
      : [
          "Receive loan request",
          "Inform applicant that request received",
          "Inform applicant that request rejected and why",
        ];
  const approverMessage = [
    "You have a new loan request,please inform applicant that request received",
    "Applicant has signed the contract,please review ",
  ];
  const requesterMessage = [
    "Your request is received",
    "We have shared the draft contract for your review",
  ];
  const showRequesterSkeleton =
    info?.contractFile === null && !canApprove && isInitiator;
  const showApproverSkeleton =
    info?.status === "pending"
      ? approverMessage[0]
      : info?.contractFile !== null
      ? approverMessage[1]
      : null;
  const isActiveForApproval =
    info?.status !== "canceled" &&
    info?.status !== "approved" &&
    info?.status !== "rejected" &&
    info?.status !== "paid" &&
    info?.status !== "payment pending" &&
    info?.status !== "request-suspension" &&
    info?.status !== "suspended" &&
    info?.status !== "paid";

  const canViewAllDocs =
    info?.status !== "approved" &&
    info?.status !== "payment pending" &&
    info?.status !== "paid";


  const canPayBulkInstallment =
  info?.remainingAmount > info?.monthlyAmount && info?.status !== "suspended";

  return (
    <div>
      <Breadcrumb />
      {!info ? (
        <PageLoading error={error} />
      ) : (
        <Row className={" p-3 "}>
          <Col className={"mb-4"} md={12}>
            <Card className={"bg-light shadow-sm"}>
              <CardHeader
                title={
                  <>
                    {isActiveForApproval ? (
                      <>
                        {canApprove && showApproverSkeleton ? (
                          <Skeleton
                            className="p-4 text-center"
                            style={{ backgroundColor: "#F8EBAD" }}
                          >
                            {showApproverSkeleton}
                          </Skeleton>
                        ) : null}
                        {showRequesterSkeleton ? (
                          <Skeleton
                            className="p-4"
                            style={{ backgroundColor: "#99C382" }}
                          >
                            {info?.status === "approved"
                              ? steps?.length
                              : info?.status === "received"
                              ? requesterMessage[0]
                              : info?.draftContract &&
                                info?.contractFile === null
                              ? requesterMessage[1]
                              : null}{" "}
                          </Skeleton>
                        ) : null}
                      </>
                    ) : null}

                    {info?.status === "request-suspension" ? (
                      <Skeleton
                        className="p-4 text-center"
                        style={{ backgroundColor: "#F8EBAD" }}
                      >
                        {`Request For Payment Suspension from ${moment(
                          info?.suspendStartAt
                        ).format("DD/MM/YYYY")} to ${moment(
                          info?.suspendEndAt
                        ).format("DD/MM/YYYY")}`}
                      </Skeleton>
                    ) : null}
                  </>
                }
                subheader={
                  info?.status === "suspended" ? (
                    <Alert variant="filled" severity="warning">
                      {`The monthly installment has been temporarily suspended from ${moment(
                        info?.suspendStartAt
                      ).format("DD/MM/YYYY")} to ${moment(
                        info?.suspendEndAt
                      ).format("DD/MM/YYYY")}`}
                    </Alert>
                  ) : null
                }
                action={
                  info?.status !== "suspended" ? (
                    <StatusComponent status={info?.status} badge={true} />
                  ) : null
                }
              />
              <CardContent>
                <div className={"row justify-content-end mb-3"}>
                  {info?.status === "approved" && isInitiator ? (
                    <div className="col-md-8 fw-bold d-flex justify-content-end">
                      <Button
                        className="me-2"
                        color="secondary"
                        size="small"
                        variant="outlined"
                        startIcon={<Block />}
                        onClick={() => {
                          setOpenDialogue(true);
                          setData({
                            id: info?.id,
                            status: "request-suspension",
                          });
                        }}
                      >
                        Request Payment suspension
                      </Button>
                    </div>
                  ) : null}
                  {(info?.status === "canceled" ||
                    info?.status === "suspended") &&
                  isInitiator ? (
                    <div className="col-md-8 fw-bold d-flex justify-content-end">
                      <Button
                        className="me-2"
                        color="secondary"
                        size="small"
                        variant="outlined"
                        startIcon={<Undo />}
                        onClick={() => {
                          setOpenDialogue(true);
                          setData({
                            id: info?.id,
                            status:
                              info?.status === "suspended"
                                ? "unSuspend"
                                : info?.previousStatus ?? "pending",
                          });
                        }}
                      >
                        Resume
                      </Button>
                    </div>
                  ) : null}
                  {info?.status === "request-suspension" && canApprove ? (
                    <div className="col-md-8 fw-bold d-flex justify-content-end">
                      <Button
                        className="me-2"
                        color="error"
                        size="small"
                        variant="outlined"
                        startIcon={<Close />}
                        onClick={() => {
                          setOpenDialogue(true);
                          setData({
                            id: info?.id,
                            status: "unSuspend",
                            suspend: false,
                          });
                        }}
                      >
                        Deny Suspension
                      </Button>
                      <Button
                        className="me-2"
                        color="success"
                        size="small"
                        variant="outlined"
                        startIcon={<Check />}
                        onClick={() => {
                          setOpenDialogue(true);
                          setData({ id: info?.id, status: "suspended" });
                        }}
                      >
                        Approve Suspension
                      </Button>
                    </div>
                  ) : null}
                  {isActiveForApproval ? (
                    <div className="col-md-8 fw-bold d-flex justify-content-end">
                      {info?.status !== "approved" && isInitiator ? (
                        <Button
                          className="me-2"
                          color="error"
                          size="small"
                          variant="outlined"
                          startIcon={<Close />}
                          onClick={() => {
                            setOpenDialogue(true);
                            setData({ id: info?.id, status: "canceled" });
                          }}
                        >
                          Canceled
                        </Button>
                      ) : null}
                      {canUpdate ? (
                        <Button
                          className="btn-primary me-2"
                          size="small"
                          variant="outlined"
                          startIcon={<BorderColorOutlined />}
                          onClick={() => {
                            navigate(
                              `/console/saving/loans/update/${info?.id}`
                            );
                          }}
                        >
                          Update
                        </Button>
                      ) : null}
                      {canApprove &&
                      isActiveForApproval &&
                      info?.status !== "request action" ? (
                        <>
                          <Button
                            className="me-1"
                            color="info"
                            size="small"
                            variant="outlined"
                            startIcon={<Info />}
                            hidden={info?.status !== "pending"}
                            onClick={() => {
                              setOpenDialogue(true);
                              setData({
                                id: info?.id,
                                status: "received",
                              });
                            }}
                          >
                            Received
                          </Button>
                          <Button
                            className="me-1"
                            color="warning"
                            size="small"
                            variant="outlined"
                            startIcon={<QuestionMark />}
                            onClick={() => {
                              setOpenDialogue(true);
                              setData({
                                id: info?.id,
                                status: "request action",
                              });
                            }}
                          >
                            Request for action
                          </Button>
                          <Button
                            className="me-1"
                            color="secondary"
                            size="small"
                            variant="outlined"
                            hidden={info?.status !== "received"}
                            startIcon={<FileOpen />}
                            onClick={() => {
                              setOpenDialogue(true);
                              setNeedFile(true);
                              setData({
                                id: info?.id,
                                status: "contract drafting",
                              });
                            }}
                          >
                            contract drafting
                          </Button>

                          <Button
                            className="me-2"
                            color="error"
                            size="small"
                            variant="outlined"
                            startIcon={<HighlightOff />}
                            onClick={() => {
                              setOpenDialogue(true);
                              setData({ id: info?.id, status: "rejected" });
                            }}
                          >
                            Reject
                          </Button>
                          {/* {!isReviewer && ( */}
                          <Button
                            className="me-2"
                            color="success"
                            size="small"
                            disabled={!canApprove}
                            variant="outlined"
                            hidden={info?.contractFile === null}
                            startIcon={<Check />}
                            onClick={() => {
                              setOpenDialogue(true);
                              setNeedFile(true);
                              setData({ ...info, status: "approved" });
                            }}
                          >
                            Approve
                          </Button>
                          {/* )} */}
                        </>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                {isActiveForApproval ? (
                  <Row className="mb-3">
                    <Col>
                      <Stepper
                        activeStep={
                          info?.status === "approved"
                            ? steps?.length
                            : info?.status === "received"
                            ? 2
                            : info?.status === "rejected"
                            ? steps?.length
                            : info?.draftContract !== null &&
                              info?.contractFile === null
                            ? 4
                            : info?.contractFile !== null && info?.draftContract
                            ? 5
                            : 1
                        }
                        alternativeLabel
                      >
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Col>
                  </Row>
                ) : null}

                <Typography style={{fontFamily:'Inter'}} variant="h5" className="mb-3">
                  Borrower Info
                </Typography>
                <Row className={"mb-3"}>
                  <Col md={4}>
                    <div>Names</div>
                    <div className={"fw-bold"}>
                      {info?.CaisseMember?.employee?.names ?? "---"}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>Phone Number</div>
                    <div className={"fw-bold"}>
                      {info?.CaisseMember?.employee?.phone ?? "---"}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>Monthly Contribution</div>
                    <div className={"fw-bold"}>
                      {info?.CaisseMember?.share?.toLocaleString() ?? "---"} RWF
                    </div>
                  </Col>
                </Row>
                {/* <Row className={"mb-3"}>
                  <Col md={4}>
                    <div>Employee Number</div>
                    <div className={"fw-bold"}>
                      {info?.CaisseMember?.employee?.employeeNumber ?? "---"}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>Email</div>
                    <div className={"fw-bold"}>
                      {info?.CaisseMember?.employee?.email ?? "---"}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>Category</div>
                    <div className={"fw-bold"}>
                      {info?.CaisseMember?.employee?.category ?? "---"}
                    </div>
                  </Col>
                </Row>
                <h5>Loan Details</h5> */}
                <Typography style={{fontFamily:'Inter'}} variant="h5" className="mb-3">
                  Loan Details
                </Typography>

                <Row className={"mb-3"}>
                  <Col md={4}>
                    <div>Loan Type</div>
                    <div className={"fw-bold"}>
                      {info?.LoanType?.name ?? "---"}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>Loan Amount(Principal)</div>
                    <div className={"fw-bold"}>
                      {`${info?.amount?.toLocaleString()} RWF` ?? "---"}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>Interest rate(%)</div>
                    <div className={"fw-bold"}>
                      {info?.interestRate ?? "---"}
                    </div>
                  </Col>
                </Row>
                <Row className={"mb-3"}>
                  <Col md={4}>
                    <div>Loan term</div>
                    <div className={"fw-bold"}>
                      {`${info?.terms} mths` ?? "---"}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>Interest Amount</div>
                    <div className={"fw-bold"}>
                      {`${info?.interestAmount?.toLocaleString()} RWF` ?? "---"}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>Monthly Installment</div>
                    <div className={"fw-bold"}>
                      {`${info?.monthlyAmount?.toLocaleString()} RWF` ?? "---"}
                    </div>
                  </Col>
                </Row>
                {info?.partial === "yes" ? (
                  <Row className={"mb-3"}>
                    <Col md={4}>
                      <div>Outstanding rate(of monthly amount)</div>
                      <div className={"fw-bold"}>
                        {`${info?.outStandingInterest}%` ?? "---"}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div>From Salary</div>
                      <div className={"fw-bold"}>
                        {`${info?.inPayment?.toLocaleString()} RWF` ?? "---"}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div>Own Payment</div>
                      <div className={"fw-bold"}>
                        {`${info?.outStandingPayment?.toLocaleString()} RWF` ??
                          "---"}
                      </div>
                    </Col>
                  </Row>
                ) : null}

                <Row className={"mb-3"}>
                  <Col md={4} className={"mb-3"}>
                    <div>Security Amount</div>
                    <div className={"fw-bold"}>
                      {`${info?.securityAmount?.toLocaleString()} RWF` ?? "---"}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>Total Amount To Be Paid</div>
                    <div className={"fw-bold"}>
                      {`${info?.amountToPay?.toLocaleString()} RWF` ?? "---"}
                    </div>
                  </Col>

                  {/* <Col md={4}>
                    <div>Total Amount To Be Paid</div>
                    <div className={"fw-bold"}>
                      {`${info?.amountToPay?.toLocaleString()} RWF` ?? "---"}
                    </div>
                  </Col> */}
                  <Col md={4}>
                    <div>Total Amount Received</div>
                    <div className={"fw-bold"}>
                      {`${info?.amountReceived?.toLocaleString()} RWF` ?? "---"}
                    </div>
                  </Col>
                  {info?.firstPayment ? (
                    <>
                      <Col md={4}>
                        <div>First Payment</div>
                        <div className={"fw-bold"}>
                          {moment(info?.firstPayment).format("L") ?? "---"}
                        </div>
                      </Col>
                      <Col md={4}>
                        <div>Last Payment</div>
                        <div className={"fw-bold"}>
                          {moment(info?.lastPayment).format("L") ?? "---"}
                        </div>
                      </Col>
                    </>
                  ) : null}
                </Row>

                {info?.collateralType ? (
                  <>
                    {/* ===============================collateral section===================== */}

                    <LoanCollateral
                      info={info}
                      approver={loggedUser?.Employee?.CaisseMember?.id}
                    />
                  </>
                ) : null}
                {/* =====================================end collateral==================== */}
                <Row className={"mb-3"}>
                  {canUpdate &&
                  info?.draftContract &&
                  info?.contractFile === null ? (
                    <Alert severity="warning" className="col-md-12">
                      After reviewing a contract,download it for signature and
                      upload it back to the System.
                      <GeneralButton
                        icon={<Upload />}
                        name="Click here to upload"
                        onClick={() => {
                          navigate(`/console/saving/loans/update/${info?.id}`);
                        }}
                      />
                    </Alert>
                  ) : null}
                  {canApprove &&
                  info?.contractFile &&
                  info?.finalContract === null ? (
                    <Alert severity="warning" className="col-md-12">
                      After reviewing a contract signed by applicant,download it
                      for your final signature and upload it back to the System.
                      <GeneralButton
                        icon={<Upload />}
                        name="Click here to upload"
                        onClick={() => {
                          setOpenDialogue(true);
                          setNeedFile(true)
                          setData({ ...info, status: "approved" });
                        }}
                      />
                    </Alert>
                  ) : null}
                  {/* draft contract shared to applicant */}
                  {info?.draftContract && canViewAllDocs ? (
                    <div className="col-md-4">
                      <div className="mb-3">
                        <Typography
                        style={{fontFamily:'Inter'}}
                          className="mb-3 fw-bold me-3"
                          role="button"
                          onClick={() => {
                            setViewDraftContract(!viewDraftContract);
                            setViewFinalContract(false);
                            setDocument(info?.draftContract);
                            setOpenDocument(true);
                            setModalTitle("Draft Contract");
                          }}
                        >
                          Draft Contract
                          <span className="ms-3">
                            {viewDraftContract ? (
                              <VisibilityOff fontSize="small" />
                            ) : (
                              <Visibility fontSize="small" />
                            )}
                          </span>
                        </Typography>
                      </div>
                    </div>
                  ) : null}
                  {/*  contract signed by applicant */}

                  {info?.contractFile && canViewAllDocs ? (
                    <div className="col-md-4">
                      <div className="mb-3">
                        <Typography
                        style={{fontFamily:'Inter'}}
                          className="mb-3 fw-bold me-3"
                          role="button"
                          onClick={() => {
                            setViewFinalContract(!viewFinalContract);
                            setViewDraftContract(false);
                            setDocument(info?.contractFile);
                            setOpenDocument(true);
                            setModalTitle("Applicant signed Contract");
                          }}
                        >
                          Applicant signed Contract
                          <span className="ms-3">
                            {viewFinalContract ? (
                              <VisibilityOff fontSize="small" />
                            ) : (
                              <Visibility fontSize="small" />
                            )}
                          </span>
                        </Typography>
                      </div>
                    </div>
                  ) : null}
                  {/*  final contract signed by both */}

                  {info?.finalContract ? (
                    <div className="col-md-4">
                      <div className="mb-3">
                        <Typography
                        style={{fontFamily:'Inter'}}
                          className="mb-3 fw-bold me-3"
                          role="button"
                          onClick={() => {
                            setViewFinalContract(!viewFinalContract);
                            setViewDraftContract(false);
                            setDocument(info?.finalContract);
                            setOpenDocument(true);
                            setModalTitle("Loan Contract");
                          }}
                        >
                          Contract
                          <span className="ms-3">
                            {viewFinalContract ? (
                              <VisibilityOff fontSize="small" />
                            ) : (
                              <Visibility fontSize="small" />
                            )}
                          </span>
                        </Typography>
                      </div>
                    </div>
                  ) : null}
                </Row>

                <hr />
                {history?.length ? (
                  <div className="row mb-3">
                    <Typography style={{fontFamily:'Inter'}} className="col-md-2 mb-3 fw-bold text-nowrap">
                      Payment History
                    </Typography>

                    <Typography style={{fontFamily:'Inter'}} className="col-md-3 mb-3 fw-bold text-nowrap">
                      Total Paid:{" "}
                      <span className="ms-2">
                        {info?.amountPaid?.toLocaleString()} RWF
                      </span>
                    </Typography>
                    <Typography style={{fontFamily:'Inter'}} className="col-md-3 mb-3 fw-bold text-nowrap">
                      Remaining
                      <span className="ms-2">
                        {info?.remainingAmount?.toLocaleString()} RWF
                      </span>
                    </Typography>
                    <Typography style={{fontFamily:'Inter'}} className="col-md-2 mb-3 fw-bold text-nowrap">
                      Total Months Paid
                      <span className="ms-2">
                        {info?.totalMonthsPaid?.toLocaleString()}
                      </span>
                    </Typography>
                    <Typography style={{fontFamily:'Inter'}} className="col-md-2 mb-3 fw-bold text-nowrap">
                      {isInitiator && canPayBulkInstallment ? (
                        <Button
                          size="small"
                          color="info"
                          variant="contained"
                          startIcon={<PaymentSharp />}
                          onClick={() => {
                            setAddPayment(1);
                            // setData(doc);
                          }}
                        >
                          Bulk payment
                        </Button>
                      ) : null}
                    </Typography>
                    <div className="col-md-12">
                      <LoanPaymentHistory
                        history={history}
                        isApprover={canApprove}
                        isInitiator={isInitiator}
                        setOpenDocument={setOpenDocument}
                        setDocument={setDocument}
                        reload={() => {
                          loadInfo().then();
                        }}
                      />
                    </div>
                  </div>
                ) : null}

                <div className="row">
                  <Typography style={{fontFamily:'Inter'}} className="col-md-12 mb-3 fw-bold">
                    Request History
                  </Typography>
                  <div className="col-md-12">
                    <RequestHistoryProcess
                      RequestProcesses={info?.RequestProcesses}
                    />
                  </div>
                </div>
                {openDialogue ? (
                  <SmallDialog
                    maxWidth="md"
                    title={
                      newData?.status === "request action"
                        ? "Request Action"
                        : newData?.status === "approved"
                        ? "Approve Loan"
                        : newData?.status === "received"
                        ? "Inform applicant that the application is received"
                        : newData?.status === "contract drafting"
                        ? "Share with applicant a draft contract"
                        : newData?.status === "canceled"
                        ? "Cancel your request"
                        : newData?.status === "pending"
                        ? "Resume your request"
                        : newData?.status === "request-suspension"
                        ? "Request payment suspension"
                        : newData?.status === "suspended"
                        ? "Approve payment suspension"
                        : newData?.status === "reject"
                        ? "Reject Loan"
                        : newData?.status === "unSuspend" && newData?.suspend
                        ? "Resume the monthly installment payment"
                        : !newData?.suspend
                        ? "Deny Suspension"
                        : "Inform applicant that the application is rejected"
                    }
                    buttonTitle="Submit"
                    needButton={true}
                    open={openDialogue}
                    setOpen={setOpenDialogue}
                  >
                    {error ? (
                      <Alert
                        style={{ width: "100%" }}
                        className={"mb-3"}
                        severity={"error"}
                        onClose={() => setError(null)}
                      >
                        {error}
                      </Alert>
                    ) : null}
                    <form
                      onSubmit={(e: any) => {
                        e.preventDefault();
                        setConfirm(true);
                      }}
                    >
                      {newData?.status === "approved" ||
                      newData?.status === "contract drafting" ? (
                        <>
                          <label className="col-form-label text-capitalize">
                            {newData?.status === "contract drafting"
                              ? "Attachment Draft Contract"
                              : "Final Contract"}
                          </label>
                          <FileUpload
                            setFiles={setDraftContractFile}
                            files={draftContractFile}
                            required={true}
                            title={"upload"}
                          />
                        </>
                      ) : null}
                      {newData?.status === "approved" ? (
                        <>
                          <div>
                            <label className="col-form-label text-capitalize">
                              {"First Payment Date"}
                              <span className={"text-danger"}>*</span>
                            </label>
                            <input
                              //  placeholder="Type here..."
                              className="form-control form-control mb-3"
                              type="date"
                              required={true}
                              min={moment().format("DD/MM/YYYY")}
                              value={newData?.firstPayment}
                              onChange={(e: any) => {
                                const end = moment(e.currentTarget.value).add(
                                  newData?.terms,
                                  "months"
                                );
                                setData({
                                  ...newData,
                                  firstPayment: e.currentTarget.value,
                                  lastPayment: moment(end).format("YYYY-MM-DD"),
                                });
                              }}
                            />
                          </div>
                          <div>
                            <label className="col-form-label text-capitalize">
                              {"Last Payment Date"}
                              <span className={"text-danger"}>*</span>
                            </label>
                            <input
                              //  placeholder="Type here..."
                              className="form-control form-control mb-3"
                              type="date"
                              disabled={true}
                              value={newData?.lastPayment}
                            />
                          </div>
                        </>
                      ) : null}

                      {/* =====================suspend payment==================== */}
                      {newData?.status === "request-suspension" ? (
                        <>
                          <div>
                            <label className="col-form-label text-capitalize">
                              {"Start Date"}
                              <span className={"text-danger"}>*</span>
                            </label>
                            <input
                              //  placeholder="Type here..."
                              className="form-control form-control mb-3"
                              type="date"
                              required={true}
                              // min={moment().format("DD/MM/YYYY")}
                              value={newData?.suspendStartAt}
                              onChange={(e: any) => {
                                // const start = moment(
                                //   e.currentTarget.value
                                // ).format("DD/MM/YYYY");
                                // const end = moment(start).add(6, "months");
                                setData({
                                  ...newData,
                                  suspendStartAt: e.currentTarget.value,
                                });
                              }}
                            />
                          </div>
                          <div>
                            <label className="col-form-label text-capitalize">
                              {"End Date"}
                              <span className={"text-danger"}>*</span>
                            </label>
                            <input
                              //  placeholder="Type here..."
                              className="form-control form-control mb-3"
                              type="date"
                              // min={moment(newData?.suspendStartAt).format(
                              //   "DD/MM/YYYY"
                              // )}
                              // max={moment(newData?.lastSuspendEndDate).format(
                              //   "DD/MM/YYYY"
                              // )}
                              value={newData?.suspendEndAt ?? ""}
                              onChange={(e: any) => {
                                setData({
                                  ...newData,
                                  suspendEndAt: e.currentTarget.value,
                                });
                              }}
                            />
                          </div>
                        </>
                      ) : null}
                      {/* ======================end suspend payment section========= */}
                      <div>
                        <label className="col-form-label text-capitalize">
                          {newData?.status === "canceled" ||
                          newData?.status === "request-suspension"
                            ? "why?"
                            : "Comment"}{" "}
                          <span className={"text-danger"}>*</span>
                        </label>
                        <textarea
                          placeholder="Type here..."
                          className="form-control form-control mb-3"
                          required={newData?.status !== "pending"}
                          value={newData?.comment}
                          rows={5}
                          onChange={(e: any) => {
                            setData({
                              ...newData,
                              comment: e.currentTarget.value,
                            });
                          }}
                        />
                      </div>
                      <div className={"float-end"}>
                        <button
                          type={"button"}
                          onClick={() => {
                            setOpenDialogue(false);
                          }}
                          className={"btn btn-secondary me-1"}
                        >
                          <i className={"bi bi-x-lg"}></i> Cancel
                        </button>
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <button
                            type={"submit"}
                            disabled={needFile && !draftContractFile?.length}
                            className={"btn btn-primary"}
                          >
                            <i className={"bi bi-send-fill"}></i> Submit
                          </button>
                        )}
                      </div>
                    </form>
                  </SmallDialog>
                ) : null}
                {openDialogue ? (
                  <SmallDialog
                    open={confirm}
                    setOpen={setConfirm}
                    onSubmit={() => {
                      onSubmit(newData);
                    }}
                  >
                    <div className="d-flex ">
                      <CheckCircleIcon color="success" sx={{ fontSize: 50 }} />
                      <div className="mt-4">
                        {"Are you sure you want to submit?"}
                      </div>
                    </div>
                  </SmallDialog>
                ) : null}
                {/* view supporting document model */}
                <SmallDialog
                  maxWidth="lg"
                  title={modalTitle ?? "PROOF OF PAYMENT"}
                  buttonTitle="Close"
                  needButton={true}
                  open={openDocument}
                  setOpen={setOpenDocument}
                  onSubmit={() => {
                    setOpenDocument(false);
                  }}
                >
                  <div className={"row justify-content-center"}>
                    <div className={"col-md-7"}>
                      {document?.map((v: string) => (
                        <OpenDocuments
                          downloadable={true}
                          card={true}
                          document={v}
                          mimeType={getFileMime(v)}
                        />
                      ))}
                    </div>
                  </div>
                </SmallDialog>
              </CardContent>
            </Card>
          </Col>
        </Row>
      )}
      <PayBulk
        setAddPayment={setAddPayment}
        addPayment={addPayment}
        loan={info}
        onSuccess={() => {
          loadInfo().then();
        }}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoanDetails);
