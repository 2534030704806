import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";

function SettingsLayout() {
  const title = "Settings";

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container-fluid">
      <div className="row">
          <div className='col-md-12'>
          <Outlet />
          </div>
          </div>
      </div>
      {/* <BlueNavBar
        // baseUrl={"/console/settings/"}
       
      />
      <div className={classes.content}>
        <Outlet />
      </div> */}
    </>
  );
}

export default SettingsLayout;
