import React, { JSX, useEffect, useState } from "react";
import { fetchDetails, statusList } from "../../../services/common";
import moment from "moment";
import { Chip } from "@mui/material";
import ReportEngineComponent from "../../../components/ReportEngineComponent";

function MutationReport(): JSX.Element {
  const [departments, setDepartments] = useState([]);
  const [branches, setBranches] = useState([]);
  const [value, setValue] = useState<any>({});
  // const [requesters, setRequesters] = useState([]);

  const loadInitialData = async () => {
    const departmentList = await fetchDetails("/department/view");
    const branchList = await fetchDetails("/branch/view");
    // const positionList = await fetchDetails("/position/view");
    // const employeeList = await fetchDetails("/employee/view");

    if (departmentList.status) setDepartments(departmentList?.data);
    if (branchList.status) setBranches(branchList?.data);
    // if (positionList.status) setPositions(positionList?.data);
    // if (employeeList.status) setRequesters(employeeList?.data);
  };

  useEffect(() => {
    loadInitialData().then();
  }, []);

  return (
    <ReportEngineComponent
    setValue={setValue}
    value={value}
      title={"Mutation Report"}
      url={`report/mutation`}
      columns={[
        {
          label: "Emp. ID",
          name: "Employee.employeeNumber",
        //   data: "Employee",
        },
        {
            label: "Emp. Names",
            name: "Employee.names",
            // data: "Employee",
          },
          {
            label: "Branch From",
            name: "fromBranch.name",
          },
          {
            label: "Branch To",
            name: "toBranch.name",
          },
          {
            label: "Department From",
            name: "fromDepartment.name",
          },
          {
            label: "Department To",
            name: "toDepartment.name",
          },
          {
            label: "Status",
            name: "status",
            options: {
              customBodyRender: (v: any) => <Chip label={v} size={"small"} />,
            },
          },
          {
            label: "Date",
            name: "createdAt",
            type: "date",
            options: {
              customBodyRender: (v: any) => moment(v).format("YYYY-MMM-DD"),
            },
          },
    ]}
      filters={[
       
        {
          label: "Branch From",
          name: "branchFrom",
          values: branches.map(({ name, id }: any) => ({
            label: name,
            value: id,
          })),
        //   column: {
        //     label: "Branch From",
        //     name: "fromBranch.name",
        //   },
        },
        {
          label: "Branch To",
          name: "branchTo",
          values: branches.map(({ name, id }: any) => ({
            label: name,
            value: id,
          })),
        //   column: {
        //     label: "Branch To",
        //     name: "toBranch.name",
        //   },
        },
        {
          label: "Department From",
          name: "departmentFrom",
          values: departments.map(({ name, id }: any) => ({
            label: name,
            value: id,
          })),
        //   column: {
        //     label: "Department From",
        //     name: "fromDepartment.name",
        //   },
        },
        {
          label: "Department To",
          name: "departmentTo",
          values: departments.map(({ name, id }: any) => ({
            label: name,
            value: id,
          })),
        //   column: {
        //     label: "Department To",
        //     name: "toDepartment.name",
        //   },
        },

        // {
        //     label: "Position From",
        //     name: "positionFrom",
        //     values: positions.map(({name, id}: any) => ({
        //         label: name,
        //         value: id
        //     })),
        //     column: {
        //         label: "From Position",
        //         name: "fromPosition.name",
        //     }
        // },
        // {
        //     label: "Position To",
        //     name: "positionTo",
        //     values: positions.map(({name, id}: any) => ({
        //         label: name,
        //         value: id
        //     })),
        //     column: {
        //         label: "To Position",
        //         name: "toPosition.name",
        //     }
        // },
        // {
        //     label: "Requester",
        //     name: "requester",
        //     values: requesters.map(({names, user}: any) => ({
        //         label: names,
        //         value: user?.id
        //     })),
        //     column: {
        //         label: "Requester",
        //         name: "Employee.names",
        //         data: "Employee.firstName",
        //     }
        // },
        {
          label: "Employee Name/ID",
          name: "empName",
          type: "text",
        },
        {
          label: "Status",
          name: "status",
          values: statusList,
        //   column: {
        //     label: "Status",
        //     name: "status",
        //     options: {
        //       customBodyRender: (v: any) => <Chip label={v} size={"small"} />,
        //     },
        //   },
        },

        {
          label: "Start Date",
          name: "startDate",
          type: "date",
          required:true
        //   column: {
        //     label: "Date",
        //     name: "createdAt",
        //     type: "date",
        //     options: {
        //       customBodyRender: (v: any) => moment(v).format("YYYY-MMM-DD"),
        //     },
        //   },
        },
        {
          label: "End Date",
          name: "endDate",
          type: "date",
          required:true
        },
      ]}
    />
  );
}

export default MutationReport;
