// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  font-size:100%;
  font-family: 'Inter';

}
p {
  font-family: "Inter";
}

.bg-blue{
  background-color: #054D6F;
}
.general-font {
  font-family: "Inter";
}
.nav-hover{
  transition: all 0.4s ease-in-out;
}

.holder-center{
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
}


.bi-icon-size{
  height: 20px;
  width: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;GACR;;cAEW;EACZ,mCAAmC;EACnC,kCAAkC;;AAEpC;;AAEA;EACE;aACW;AACb;AACA;EACE,cAAc;EACd,oBAAoB;;AAEtB;AACA;EACE,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,oBAAoB;AACtB;AACA;EACE,gCAAgC;AAClC;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB;;;AAGA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":["body {\n  margin: 0;\n   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n*{\n  font-size:100%;\n  font-family: 'Inter';\n\n}\np {\n  font-family: \"Inter\";\n}\n\n.bg-blue{\n  background-color: #054D6F;\n}\n.general-font {\n  font-family: \"Inter\";\n}\n.nav-hover{\n  transition: all 0.4s ease-in-out;\n}\n\n.holder-center{\n  height: 80vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 40px;\n}\n\n\n.bi-icon-size{\n  height: 20px;\n  width: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
