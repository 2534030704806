import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { Col, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import { fetchDetails } from "../../../services/common";
import moment from "moment";
import { AddForm } from "../../employee/AddForm";
import FileUpload from "../../../components/FileUploader";

const CreateSettings = () => {
  const navigate = useNavigate();
  const [info, setInfo] = React.useState<any>();
  const { id } = useParams();
  const [files, setFiles] = React.useState<any>([]);

  const fetchInfoDetails = async (id: string) => {
    const resp = await fetchDetails(`settings/view`);
    if (resp.status) {
      setInfo({
        ...resp.data,
        endLeavePlanDate: moment(resp.data.endLeavePlanDate).format(
          "YYYY-MM-DD"
        ),
        startLeavePlanDate: moment(resp.data.startLeavePlanDate).format(
          "YYYY-MM-DD"
        ),
      });
    }
  };

  useEffect(() => {
    if (id) {
      fetchInfoDetails(id).then();
    }
  }, [id]);
  return (
    <div>
      <Breadcrumb />
      <Row className={"justify-content-center"}>
        <Col md={10}>
          <AddForm
            inline={true}
            mainFields={[
              {
                title: "",
                fields: [
              {
                label: "Leave Plan Start",
                name: "startLeavePlanDate",
                type: "date",
                value: info?.startLeavePlanDate,
                required: true,
              },
              {
                label: "Leave Plan End",
                name: "endLeavePlanDate",
                type: "date",
                required: true,
                value: info?.endLeavePlanDate,
              },
              {
                label: "Work Start At",
                name: "workStartTime",
                type: "time",
                required: true,
                value: info?.workStartTime,
              },
              {
                label: "Work End At",
                name: "workEndTime",
                type: "time",
                required: true,
                value: info?.workEndTime,
              },
            
            ]},
            {
              title: "Annual leave days",
              fields: [
                {
                  label: "Under statute ",
                  name: "underStatute",
                  type: "number",
                  value: info?.underStatute,
                  required: true,
                  min:0
                },
                {
                  label: "Contractual ",
                  name: "contractual",
                  type: "number",
                  value: info?.contractual ,
                  required: true,
                  min:0
                },
                {
                  label: "User Guide",
                  name: "supportingDocument",
                  type: "file",
                  multiple: true,
                  col: 12,
                  value: files,
                  render: () => {
                    return (
                      <FileUpload
                        setFiles={setFiles}
                        files={files}
                        required={!info?.userManual}
                        currentFile={info?.userManual}
                      />
                    );
                  },
                },
              ]
            }
          ]}
            url={id ? `settings/update/${id}` : "settings/create"}
            method={id ? "PUT" : "POST"}
            title={id ? "General settings " : "Create"}
            onSuccess={() => navigate(-1)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CreateSettings;
