import { Box, Button, Tab, Tabs, styled } from "@mui/material";
import { a11yProps } from "../../pages/employee/leave/List";

type MainLabels = { label: String; icon?: any };
type IProps = {
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
  value: number;
  width?: number;
  height?: number;
  labels: Array<MainLabels>;
};
const TabHeadersComponent = (props: IProps) => {
  const { handleChange, value, width, height, labels } = props;
  const StyledTabs = styled((props: any) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      backgroundColor: "#035c85",
    },
  });
  const StyledTab = styled((props: any) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      fontFamily:'Inter',
      "&.Mui-selected": {
        color: "#035c85",
        fontFamily:'Inter'
      },
      "&.Mui-focusVisible": {
        color: "#035c85",
        fontFamily:'Inter'
      },
    })
  );
  return (
    <Box sx={{ borderBottom: 1, Update: "divider" }} >
      <StyledTabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        aria-label="basic tabs example"
        // style={{
        //   // backgroundColor: "#054D6F",
        //   // color: "ffffff",
        //   // borderRadius: "10px",
        //   color: "#054D6F",
        //   height:10,
        //   width: width ?? "100%",
        // }}
        
      >
        {labels?.map((label: any) => (
          // <Tab
          //   icon={label?.icon ?? null}
          //   iconPosition="start"
          //   label={label?.label}
          //   className="text-white text-uppercase "
          //   style={{ marginTop: '0px' }}
          // />
          <StyledTab label={label?.label} {...a11yProps(0)}  />
        ))}
      </StyledTabs>
    </Box>
  );
};
export default TabHeadersComponent;
