import axios, { AxiosError } from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import {keyUser} from "./MockAuthService";

const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + 'api/',
});

http.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error: AxiosError) => {
    const status = error?.response?.status!;
    switch (status) {
      case 400:
        break;
      case 401:
        if(secureLocalStorage.getItem(keyUser)) {
          // toast.error('Token expired, Please Log In again!', {
          //   position: 'top-right',
          //   autoClose: 200
          // });

          secureLocalStorage.removeItem('ippisHeader');
          secureLocalStorage.removeItem(keyUser);

          (window as Window).location = '/login';
        }
        break;
      case 404:
        // toast.error(error.message, {
        //   position: 'top-right',
        //   autoClose: 1000
        // });
        break;
      case 500:
        // toast.error('Network Error', {
        //   position: 'top-right',
        //   autoClose: 1000
        // });
        console.log('Network Error')
        break;
      default:
        // toast.error('Something wrong happened', {
        //   position: 'top-right',
        //   autoClose: 1000
        // });
        break;
    }
    return Promise.reject(error);
  }
);

export default http;
