import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { Col, Row } from "react-bootstrap";
import { FormDialog } from "../../../components/FormDialog";
import React, { useEffect } from "react";
import { fetchDetails } from "../../../services/common";
import { AddForm } from "../../employee/AddForm";

const CreateBranch = () => {
  const navigate = useNavigate();
  const [info, setInfo] = React.useState<any>();
  const [zones, setZone] = React.useState<any>([]);
  const { id } = useParams();

  const fetchInfoDetails = async (id: string) => {
    const resp = await fetchDetails(`branch/view/${id}`);
    if (resp.status) {
      setInfo(resp.data);
    }
  };

  useEffect(() => {
    if (id) {
      fetchInfoDetails(id).then();
    }
  }, [id]);

  useEffect(() => {
    laodInitialData();
  }, []);
  const laodInitialData = async () => {
    const zone = await fetchDetails("/zone/view");
    setZone(zone?.data);
  };
  return (
    <div>
      <Breadcrumb />
      <Row className={"justify-content-center"}>
        <Col md={5}>
          <AddForm
            inline={true}
            mainFields={[
              {
                title: "",
                fields: [
              {
                label: "Name",
                name: "name",
                value: info?.name,
                required: true,
              },
              {
                label: "Location",
                name: "location",
                type: "text",
                value: info?.location,
                required: true,
              },
              {
                label: "Zone",
                name: "zoneId",
                type: "select",
                required: true,
                values: zones?.map((v: any) => ({
                  label: v.name,
                  value: v.id,
                })),
                value: info?.zoneId,
              },
            ]}]}
            url={id ? `branch/update/${id}` : "branch/create"}
            method={id ? "PUT" : "POST"}
            title={id ? "Update Branch" : "Create New Branch"}
            onSuccess={() => navigate(-1)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CreateBranch;
