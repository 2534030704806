import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  CheckHasAnyPermission,
  approvalNavItem,
  approveSavings,
  dashboardNavItem,
  employeeNavItem,
  newCdeMemberNavItem,
  reportNavItem,
  savingNavItem,
  settingsNavItems,
} from "../services/common";
import { mapStateToProps } from "../store/helpers/mapState";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../store/helpers/mapDispatch";
import useStyles from "./style";
import { useMediaQuery } from "react-responsive";

type ActiveUrls = string | Array<string> | undefined;
type NavItem = {
  label: string;
  icon: string;
  href: string;
  hoverName?: string;
  module?: string;
  hidden?: boolean;
  activeUrls?: ActiveUrls;
};
type IProps = {
  // items: Array<NavItem>;
  // baseUrl?: string | undefined;
  socket?: any;
  loggedUser: any;
  GetLoggedUser: (id: string) => void;
  setShowBlueNav: (id: boolean) => void;
};

const BlueNavBar = (props: IProps) => {
  const { loggedUser,setShowBlueNav } = props;
  const status = loggedUser?.Employee?.caisseEntradeStatus;

  const [items, setItems] = useState<any>([]);
  const [baseUrl, setBaseUrl] = useState<any>();

  const { pathname } = useLocation();
  const newPath = pathname?.split("/") ?? [];
  const path = `/${newPath[1]}/${newPath[2]}/`;
  useEffect(() => {
    if (path === "/console/employee/") {
      setItems(employeeNavItem);
      setBaseUrl("/console/employee/");
    } else if (path === "/console/approvals/") {
      setItems(approvalNavItem);
      setBaseUrl("/console/approvals/");
    } else if (path === "/console/saving/") {
      setItems(
        status === "active"
          ? savingNavItem
          : status === "exited"
          ? null
          : newCdeMemberNavItem
      );
      setBaseUrl("/console/saving/");
    } else if (path === "/console/approveSaving/") {
      setItems(approveSavings);
      setBaseUrl("/console/approveSaving/");
    } else if (path === "/console/settings/") {
      setItems(settingsNavItems);
      setBaseUrl("/console/settings/");
    } else if (path === "/console/dashboard/") {
      setItems(dashboardNavItem);
      setBaseUrl("/console/dashboard");
    } else if (path === "/console/report/") {
      setItems(reportNavItem);
      setBaseUrl("/console/report/");
    } else if (path === "/console/caisse-report/") {
      setItems([]);
      setBaseUrl("/console/caisse-report/");
    } else if (path === "/console/logs/") {
      setItems([]);
      setBaseUrl("/console/logs/");
    } else {
      setItems([]);
      setBaseUrl(path);
    }
  }, [path, status]);

  const classes = useStyles();
  const isSm = useMediaQuery({ maxWidth: 992 });

  return (
    <div
      className={
        isSm?'':classes.blueNav
        // "p-3 shadow mb-3 d-flex justify-content-end blueNav"
      }
      
    >
      <ul className="nav">
        {items?.map((v: any, index: any) => {
          const url = `${baseUrl ?? ""}${v.href}`;
          const active =
            url === `${pathname}/` ||
            url === pathname ||
            `${url}/` === pathname;
          return (v?.module && !CheckHasAnyPermission(v?.module)) ||
            v?.hidden ? null : (
            <Tooltip title={v?.hoverName} key={index}>
              <li key={index} className={`nav-item `}>
                <Link
                  className={`nav-link ${
                    active ? " backgroundNav rounded" : "text-white"
                  }`}
                  to={url}
                  // style={{ color: "#CFD1D4" }}
                  // onClick={()=>setShowBlueNav(false)}
                >
                  <i className={`${v.icon}`}></i> {v.label}
                </Link>
              </li>
            </Tooltip>
          );
        })}
      </ul>
    </div>
  );
};

// export default BlueNavBar;
export default connect(mapStateToProps, mapDispatchToProps)(BlueNavBar);
