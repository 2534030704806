/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { ListItemText } from "@mui/material";

import unProfile from "../assets/un-profile.png";
import { downloadBase64, getFileMime, inArray } from "../services/common";
import { useNavigate } from "react-router-dom";
import { logout } from "../services/MockAuthService";
import { AccountCircle, ExpandMore, People } from "@mui/icons-material";
import useStyles from "../layouts/style";

const AccountMenu = (props: any) => {
  const { loggedUser,showProfile } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [data, setData] = React.useState<any>();
  const classes = useStyles();

  const profile = loggedUser?.Employee?.profile; //profile image
  const navigate = useNavigate();

  const handleLogout = async (e: any) => {
    e.preventDefault();

    await logout();
    navigate("/login");
  };

  const downloadData = async () => {
    const res = await downloadBase64(
      profile,
      profile,
      "",
      getFileMime(profile)
    );
    if (res) {
      setData(res);
    }
  };

  React.useEffect(() => {
    if (profile) {
      downloadData().then();
    }
  }, [profile]);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
     <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <Tooltip title={loggedUser?.user?.names ?? ""}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            className="text-light"
          >
            <Avatar
              alt="profile-picture"
              src={loggedUser?.Employee?.profile && data ? data : unProfile}
              className="me-2"
            />
            <ListItemText
              className="ms-2 me-2 text-center text-nowrap"
              primary={
                <span className={classes.sideBarProfileText}>
                  {loggedUser?.Employee?.names} <ExpandMore className="ms-2" />
                </span>
              }
              secondary={
                <span className={classes.sideBarProfileText2}>
                  {loggedUser?.Employee?.position?.name}
                </span>
              }
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <Tooltip title={loggedUser?.user?.names ?? ""}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            className="text-light"
          >
          <AccountCircle className="ms-2" />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* {!inArray(["exited", "retired"], loggedUser?.Employee?.status) && ( */}
          <MenuItem
            onClick={() => {
              if(inArray(["exited", "retired"], loggedUser?.Employee?.status) ){
                navigate(`/console/saving`);

              }else{
                navigate(`/console/employee/profile/${loggedUser?.employeeId}`);

              }
            }}
          >
            <ListItemIcon>
              <People fontSize="small" />
            </ListItemIcon>
            Profile
          </MenuItem>
        {/* )} */}

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
export default AccountMenu;
