import { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import logo from "../assets/coat2.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  Spinner,
} from "react-bootstrap";
import jwt_decode from "jwt-decode";

import StatusAlert from "../components/StatusAlert";
import useAuth from "../hooks/useAuth";
import useForm from "../hooks/useForm";

import "./auth.css";
import { setSession } from "../services/MockAuthService";
import { getError, submitGeneralForm } from "../services/common";
import {
  Alert,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import SmallDialog from "../components/SmallModel";
import { MailOutline, Visibility, VisibilityOff } from "@mui/icons-material";

function redirectPath(search: string, accessCDEOnly: boolean) {
  const match = search.match(/redirect=(.*)/);
  const redirect = match?.[1];
  return redirect
    ? decodeURIComponent(redirect)
    : accessCDEOnly
    ? "/console/saving"
    : "/console/dashboard";
}

function Login() {
  const title = "Login";

  const [isLoading, setIsLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [info, setInfo] = useState<any>();

  const [error, setError] = useState<any>(null);
  const [successMessage, setSuccessMessage] = useState<any>(null);

  const { login } = useAuth();
  const navigate = useNavigate();
  const { search } = useLocation();

  const alertOpts = useRef({ isShow: false, message: "" });

  const handleDismiss = () => {
    alertOpts.current.isShow = false;
  };

  const handleLogin = async () => {
    // eslint-disable-next-line no-console
    try {
      setIsLoading(true);
      const response: any = await login(
        info.username,
        info.password,
        info.otpCode
      );
      // eslint-disable-next-line no-console
      if (response.status) {
        const token = response?.data?.accessToken;
        const canJoin: any = token ? jwt_decode(token) : null;
        setSession(
          { email: data.username, permission: response.data.permission },
          response.data.accessToken
        );
        // navigate(redirectPath(search));
        window.location.replace(redirectPath(search, canJoin?.accessCDEOnly));
      } else {
        setError(getError(response));
        setSuccessMessage(null);
      }
      setIsLoading(false);
    } catch (err: any) {
      alertOpts.current = { isShow: true, message: err.message };
      setIsLoading(false);
    }
  };

  const validators = {
    username: {
      required: {
        value: true,
        message: "username is required",
      },
    },
    password: {
      required: {
        value: true,
        message: "password is required",
      },
    },
    otpCode: {
      required: {
        value: true,
        message: "Code is required",
      },
    },
  };

  // Using a custom hook to show how we can build out our own hook.
  const { data, handleChange, handleSubmit, errors } = useForm({
    onSubmit: handleLogin,
    validators,
  });
  //check credential 
  const sendOTPCode = async () => {
    const response = await submitGeneralForm(
      `/auth/send-otp/`,
      {
        username: info.username.replace(/^\s+|\s+$/g, ""),
        password: info?.password,
      },
      null,
      "POST"
    );
    if (response.status) {
      setSuccessMessage(response?.data?.message);
      setOpenDialogue(true);
      setError(null);
    } else {
      setSuccessMessage(null);
      setError(getError(response));
    }
  };
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="container mt-5">
        <div className="row justify-content-center">
          <Card className={"shadow col-md-5 p-4"}>
            {/* <div className={"card-body"}> */}
            <form
              className="p-4"
              onSubmit={(e: any) => {
                e.preventDefault();
                sendOTPCode();
              }}
            >
              <div className="p-4">
                <div className={"py-3 text-center"}>
                  <img
                    src={logo}
                    alt={"National Logo"}
                    height={80}
                    width={80}
                  />
                </div>
                <h4 className={"py-3 text-center"}>Sign In </h4>
                {error || successMessage ? (
                  <Alert
                    severity={successMessage ? "success" : "error"}
                    className="mb-3"
                  >
                    {successMessage !== null ? successMessage : error}
                  </Alert>
                ) : null}

                <label>Email</label>
                <OutlinedInput
                  fullWidth
                  className="mb-2"
                  type={"text"}
                  size="small"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end" size="small">
                        <MailOutline fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  }
                  required
                  value={info?.username}
                  onChange={(e:any)=>{setInfo({...info,username:e.target.value})}}
                />
                {/* </FormControl> */}
                {/* <FormControl fullWidth variant="outlined" className="mb-2"> */}
                <label>Password</label>
                <OutlinedInput
                  fullWidth
                  className="mb-2"
                  type={showPass ? "text" : "password"}
                  size="small"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPass(!showPass);
                        }}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="small"
                      >
                        {showPass ? (
                          <VisibilityOff fontSize="small" />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  required
                  value={info?.password}
                  onChange={(e:any)=>{setInfo({...info,password:e.target.value})}}
                />
                {/* {successMessage ? (
                  <Alert severity="warning" className="mb-2">
                    Enter an OTP sent to your email.
                  </Alert>
                ) : null}
                {data?.username ? (
                  <>
                    <label>OTP</label>
                    <OutlinedInput
                      fullWidth
                      className="mb-2"
                      type={"text"}
                      size="small"
                      endAdornment={
                        <InputAdornment position="end">
                          <Button
                            size="small"
                            disabled={!data?.username && !data?.otpCode}
                            onClick={sendOTPCode}
                          >
                            <u>Send Code</u>
                          </Button>
                        </InputAdornment>
                      }
                      required
                      value={data.otpCode}
                      onChange={handleChange("otpCode")}
                    />
                  </>
                ) : null} */}
                {/* {Object.keys(errors).map((key) => (
                  <div className="text-danger mb-2" key={key}>
                    {errors[key]}
                  </div>
                ))} */}

                <Button
                  sx={{
                    bgcolor: "#054D6F",
                    color: "white",
                    "&:hover": { bgcolor: "#035c85" },
                  }}
                  className="w-100  radius mb-3"
                  type="submit"
                  disabled={isLoading}
                  // onClick={handleSubmit}
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    hidden={!isLoading}
                    
                  />
                  <span className="px-2">Sign In</span>
                </Button>
                <div className="mb-3">
                  <Link to="/reset-password/checkEmail" className="color">
                    Forgot password
                  </Link>
                </div>
              </div>
            </form>
            {/* </div> */}
          </Card>
        </div>
      </main>
      <StatusAlert
        show={alertOpts.current.isShow}
        variant="failure"
        message={alertOpts.current.message}
        onDismiss={handleDismiss}
      />
      {openDialogue ? (
        <SmallDialog
          title={
            successMessage ? (
              <Alert severity="warning" className="mb-2">
                Enter an OTP Code sent to your email.
              </Alert>
            ) : null
          }
          buttonTitle="Submit"
          needButton={true}
          open={openDialogue}
          setOpen={setOpenDialogue}
        >
           <form
              className="p-4"
              onSubmit={(e: any) => {
                e.preventDefault();
                handleLogin();
              }}
            >
            {error ? (
              <Alert severity={"error"} className="mb-3">
                {error}
              </Alert>
            ) : null}
            <label>OTP</label>
            <OutlinedInput
              fullWidth
              className="mb-2"
              type={"text"}
              size="small"
              required
              value={info?.otpCode}
              onChange={(e:any)=>{setInfo({...info,otpCode:e.target.value})}}
            />
            <Button
              sx={{
                bgcolor: "#054D6F",
                color: "white",
                "&:hover": { bgcolor: "#035c85" },
              }}
              className="w-100  radius mb-3"
              type="submit"
              disabled={isLoading}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                hidden={!isLoading}
              />
              <span className="px-2">Submit</span>
            </Button>
          </form>
        </SmallDialog>
      ) : null}
    </>
  );
}

export default Login;
