import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { DatatableComponent } from "../../../components/DatatableComponent";
import { BorderColorOutlined } from "@mui/icons-material";
import moment from "moment/moment";
import { Tooltip } from "@mui/material";

const BanksList = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Breadcrumb
        addButton={{ label: "Add Bank", onClick: () => navigate("create") }}
      />
      <DatatableComponent
        columns={[
          {
            label: "Name",
            name: "name",
          },
          {
            label: "Date",
            name: "createdAt",
            options: {
              customBodyRender: (v: any) =>
                moment(v).format("YYYY-MMM-DD HH:mm"),
            },
          },
        ]}
        url={"bank/datatable"}
        actions={(id) => [
          <Tooltip title="update" className={"border-0 bg-transparent"}>
            <BorderColorOutlined onClick={() => navigate(`update/${id}`)} fontSize="small" />
          </Tooltip>,
        ]}
        title={"Banks List"}
        // deleteUrl={(id) => `bank/delete/${id}`}
      />
    </div>
  );
};

export default BanksList;
