import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import * as React from "react";
import { Check, Close } from "@mui/icons-material";
// import {successToast} from "./Notification";

type IProps = {
  open: boolean;
  setOpen: (i: boolean) => void;
  onSubmit?: () => void;
  title?: any;
  children: any;
  maxWidth?: Breakpoint | undefined;
  body?: any | undefined;
  buttonTitle?: string | undefined;
  needButton?: boolean;
};

const SmallDialog = (props: IProps) => {
  const {
    open,
    setOpen,
    title,
    children,
    maxWidth,
    needButton,
    buttonTitle,
    onSubmit,
  } = props;

  return (
    <Dialog
      fullWidth={true}
      open={open}
      maxWidth={maxWidth ?? "xs"}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle className="fw-bold dataTableTitle">
        {title ?? "Confirm Action"}{" "}
        <IconButton
          title="close"
          onClick={() => {
            setOpen(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      {!needButton ? (
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            className={" rounded-pill"}
            startIcon={<Close fontSize="small" />}
            onClick={() => {
              setOpen(false);
            }}
          >
            {buttonTitle ? "Cancel" : "No"}
          </Button>
          <Button
            size="small"
            onClick={onSubmit}
            variant={"outlined"}
            className={"btn-primary rounded-pill"}
            startIcon={<Check fontSize="small" />}
          >
            {buttonTitle ?? "Yes"}
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  );
};

export default SmallDialog;
