import Breadcrumb from "../../components/Breadcrumb";
import { DatatableComponent } from "../../components/DatatableComponent";
import moment from "moment/moment";
import { CustomizedMenus } from "../../components/customizeMenu";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { default as ReactSelect } from "react-select";

import SmallDialog from "../../components/SmallModel";
import { getFileMime } from "../../services/common";
import OpenDocuments from "../../components/openDocument";

const LogsList = () => {
  const [openDetails, setOpenDetails] = useState(false);
  const [list, setList] = useState<Array<any>>([]);
  const [info, setInfo] = useState<any>();
  const [data, setData] = useState<any>();
  const [empName, setEmpName] = useState<any>();

  const uuid =
    /[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}(?:\/.*)?$/i;

  const isValidDate = (name: any, str: any) => {
    let newName = name?.toLowerCase();
    let noSpace = typeof str === "string" ? str?.replace(/\s/g, "") : [];
    const isDate =
      newName?.indexOf("date") < 0 &&
      newName?.indexOf("createdat") < 0 &&
      newName?.indexOf("updatedat") < 0;

    if (noSpace?.length < 3) {
      return str;
    }
    return isDate ? str : moment(str).format("llll");
  };
  useEffect(() => {
    //initially it display logs of current month
    if (!info) {
      let newDate = new Date();
      setData({
        ...data,
        fromDate: new Date(newDate.getFullYear(), newDate.getMonth(), 1),
        toDate: new Date(newDate.getFullYear(), newDate.getMonth(), 31),
        category: "info",
      });
    }
  }, []);

  return (
    <div className={"px-3"}>
      <Breadcrumb />
      <Row className="mb-3 ">
        <Col md={4}>
          <label className="col-form-label text-capitalize">Action</label>
          <ReactSelect
            className={"text-nowrap"}
            options={[
              { value: "create", label: "create" },
              { value: "update", label: "update" },
              { value: "signin", label: "signin" },
              { value: "delete", label: "delete" },
              { value: undefined, label: "All" },
            ]}
            onChange={(v) => {
              setData({ ...data, action: v?.value });
            }}
          />
        </Col>
        <Col md={4}>
          <label className="col-form-label text-capitalize">Module</label>
          <ReactSelect
            className={"text-nowrap"}
            options={[
              { value: "Attendance", label: "Attendance" },
              { value: "Employee", label: "Employee" },
              { value: "Mission", label: "Mission" },
              { value: "MissionAllowance", label: "Mission Allowance" },
              { value: "Leave", label: "Leave" },
              { value: "ServiceRequest", label: "Self Service" },
              { value: "Branch", label: "Branch" },
              { value: "CdeAccount", label: "Cde Account" },
              { value: "Contribution", label: "Contribution" },
              {
                value: "CaiseApplication",
                label: "Caisse d'entraide application",
              },
              { value: "CaisseMember", label: "Caisse d'entraide member" },
              { value: "Role", label: "Role" },
              { value: "User", label: "User" },
              { value: "UserRole", label: "User Role" },
              { value: "Permission", label: "Permission" },
              { value: undefined, label: "All" },
            ]}
            onChange={(v) => {
              setData({ ...data, module: v?.value });
            }}
          />
        </Col>
        <Col md={4}>
          <label className="col-form-label text-capitalize">Category</label>
          <ReactSelect
            className={"text-nowrap"}
            options={[
              { value: "info", label: "Info" },
              { value: "error", label: "error" },
            ]}
            onChange={(v) => {
              setData({ ...data, category: v?.value });
            }}
          />
        </Col>
        <Col md={4}>
          <label className="col-form-label text-capitalize">
            Done By(Employee Name/ID)
          </label>
          <input
            className="form-control"
            value={empName}
            onChange={(e: any) => {
              setEmpName(e.target.value);
            }}
          />
        </Col>
        <Col md={4}>
          <label className="col-form-label text-capitalize">Created From</label>
          <input
            className="form-control"
            type="date"
            value={data?.fromDate}
            onChange={(e: any) => {
              setData({ ...data, fromDate: e.target.value });
            }}
          />
        </Col>
        <Col md={4}>
          <label className="col-form-label text-capitalize">Created To</label>
          <input
            className="form-control"
            type="date"
            value={data?.toDate}
            onChange={(e: any) => {
              setData({ ...data, toDate: e.target.value });
            }}
          />
        </Col>
      </Row>
      <Row className="mb-3 ">
        <Col md={12}>
          <DatatableComponent
            columns={[
              {
                label: "Module",
                name: "module",
                options: {
                  customBodyRender: (v: any) => (
                    <span className="text-capitalize">{v}</span>
                  ),
                },
              },
              {
                label: "Action",
                name: "action",
                options: {
                  customBodyRender: (v: any) => (
                    <span className="text-capitalize">{v}</span>
                  ),
                },
              },
              {
                label: "IP",
                name: "ip",
                options: {
                  customBodyRender: (v: any) => (
                    <span className="text-capitalize">{v}</span>
                  ),
                },
              },
              // {
              //   label: "Browser",
              //   name: "browser",
              //   options: {
              //     customBodyRender: (v: any) => <span>{v}</span>,
              //   },
              // },
              // {
              //     label:"Description",
              //     name:"description",
              //     options:{
              //         customBodyRender:(v:any)=><span >{v}</span>
              //     }
              // },
              {
                label: "Done By",
                name: "User.Employee.names",
                options: {
                  customBodyRender: (v: any) => (
                    <span className="text-capitalize">{v ?? "System"}</span>
                  ),
                },
              },
              {
                label: "Done At",
                name: "createdAt",
                options: {
                  customBodyRender: (v: any) => moment(v).format("llll"),
                },
              },
              {
                name: "id",
                label: "Action",
                options: {
                  filter: true,
                  sort: false,
                  customBodyRenderLite: (id: any, index: any) => {
                    return (
                      <CustomizedMenus
                        onView={() => {
                          setInfo(list[index]);
                          setOpenDetails(true);
                        }}
                      />
                    );
                  },
                },
              },
            ]}
            url={`logs/datatable?empName=${empName}&info=${JSON.stringify(
              data
            )}`}
            processData={(data: any) => {
              setList(data.data);
              return data;
            }}
            title={"System Logs"}
            // deleteUrl={(id) => `logs/delete/${id}`}
          />
        </Col>
        {/* <Col md={5} hidden={!openDetails}>
          <Card className={"bg-light shadow-sm mt-3"}>
            <CardHeader title="Log Details" />
            <CardContent>
              <div className="row mb-2">
                <div className="col-md-4 fw-bold">Done By</div>
                <div className="col-md-8">
                  {info?.User?.Employee?.names ?? "---"}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4 fw-bold">Done At</div>
                <div className="col-md-8">
                  {moment(info?.createdAt).format("llll")}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4 fw-bold">Browser Details</div>
                <div className="col-md-8">{info?.browser ?? "---"}</div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4 fw-bold">IP Address</div>
                <div className="col-md-8">{info?.ip ?? "---"}</div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4 fw-bold">Module</div>
                <div className="col-md-8">{info?.module ?? "---"}</div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4 fw-bold">Action</div>
                <div className="col-md-8">{info?.action ?? "---"}</div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4 fw-bold">Source Type</div>
                <div className="col-md-8">{info?.sourceType ?? "---"}</div>
              </div>
              <hr />
              <div className="row mb-2">
                <div className="col-md-4 fw-bold">Previous Values</div>
                <div className="col-md-8">
                  {info?.previousValues
                    ? Object.keys(info?.previousValues).map((key: any) => {
                        return typeof info?.previousValues[key] === "object" ||
                          uuid.test(info?.previousValues[key]) ? null : (
                          <div className="row mb-3">
                            <div className="col-md-4">
                              <label>{key}</label>
                            </div>
                            <div className="col-md-8 fw-bolder">
                              {isValidDate(key, info?.previousValues[key])}
                            </div>
                          </div>
                        );
                      })
                    : "----"}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4 fw-bold">Current Values</div>
                <div className="col-md-8">
                  {info?.currentValues
                    ? Object.keys(info?.currentValues).map((key: any) => {
                        return typeof info?.currentValues[key] === "object" ||
                          uuid.test(info?.currentValues[key]) ? null : (
                          <div className="row mb-3">
                            <div className="col-md-4">
                              <label>{key}</label>
                            </div>
                            <div className="col-md-8 fw-bolder">
                              <label>
                                {isValidDate(key, info?.currentValues[key])}
                              </label>
                            </div>
                          </div>
                        );
                      })
                    : "----"}
                </div>
              </div>
            </CardContent>
            <CardActions className="float-end">
              <GeneralButton
                icon={<Close />}
                onClick={() => {
                  setOpenDetails(false);
                }}
                name={"Cancel"}
                variant="outlined"
              />
            </CardActions>
          </Card>
        </Col> */}
      </Row>
      <SmallDialog
        maxWidth="lg"
        title="LOGS DETAILS"
        buttonTitle="Close"
        needButton={true}
        open={openDetails}
        setOpen={setOpenDetails}
        onSubmit={() => {
          setOpenDetails(false);
        }}
      >
        <div className="p-3">
          <div className="row mb-2">
            <div className="col-md-4 fw-bold">Category</div>
            <div className="col-md-8">{info?.category ?? "---"}</div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4 fw-bold">Done By</div>
            <div className="col-md-8">
              {info?.User?.Employee?.names ?? "---"}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4 fw-bold">Done At</div>
            <div className="col-md-8">
              {moment(info?.createdAt).format("llll")}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4 fw-bold">Browser Details</div>
            <div className="col-md-8">{info?.browser ?? "---"}</div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4 fw-bold">IP Address</div>
            <div className="col-md-8">{info?.ip ?? "---"}</div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4 fw-bold">Module</div>
            <div className="col-md-8">{info?.module ?? "---"}</div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4 fw-bold">Action</div>
            <div className="col-md-8">{info?.action ?? "---"}</div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4 fw-bold">Source Type</div>
            <div className="col-md-8">{info?.sourceType ?? "---"}</div>
          </div>
          <hr />
          <div className="row mb-2">
            <div className="col-md-4 fw-bold">Previous Values</div>
            <div className="col-md-8">
              {info?.previousValues
                ? Object.keys(info?.previousValues).map((key: any) => {
                    return typeof info?.previousValues[key] === "object" ||
                      uuid.test(info?.previousValues[key]) ? null : (
                      <div className="row mb-3">
                        <div className="col-md-4">
                          <label>{key}</label>
                        </div>
                        <div className="col-md-8 fw-bolder">
                          {isValidDate(key, info?.previousValues[key])}
                        </div>
                      </div>
                    );
                  })
                : "----"}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4 fw-bold">Current Values</div>
            <div className="col-md-8">
              {info?.currentValues
                ? Object.keys(info?.currentValues).map((key: any) => {
                    return typeof info?.currentValues[key] === "object" ||
                      uuid.test(info?.currentValues[key]) ? null : (
                      <div className="row mb-3">
                        <div className="col-md-4">
                          <label>{key}</label>
                        </div>
                        <div className="col-md-8 fw-bolder">
                          <label>
                            {isValidDate(key, info?.currentValues[key])}
                          </label>
                        </div>
                      </div>
                    );
                  })
                : "----"}
            </div>
          </div>
          {info?.description ? (
            <div className="row mb-2">
              <div className="col-md-4 fw-bold mb-2">Description</div>
              <div className="col-md-12">{info?.description ?? "---"}</div>
            </div>
          ) : null}
          {/* {info?.logFile? (
                      <OpenDocuments
                        downloadable={true}
                        card={true}
                        document={info?.logFile}
                        mimeType={getFileMime(info?.logFile)}
                      />
                    ):null} */}
        </div>
      </SmallDialog>
    </div>
  );
};

export default LogsList;
