import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { DatatableComponent } from "../../../components/DatatableComponent";
import { BorderColorOutlined } from "@mui/icons-material";
import moment from "moment";
import { Tooltip } from "@mui/material";

const PositionList = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Breadcrumb
        addButton={{ label: "Add Position", onClick: () => navigate("create") }}
      />
      <DatatableComponent
        columns={[
          {
            label: "Name",
            name: "name",
          },
          {
            label: "Scale",
            name: "Echlon.name",
          },
          {
            label: "Level",
            name: "Level.name",
          },
          {
            label: "Date",
            name: "createdAt",
            options: {
              customBodyRender: (v: any) =>
                moment(v).format("YYYY-MMM-DD HH:mm"),
            },
          },
        ]}
        url={"position/datatable"}
        actions={(id) => [
            <Tooltip title="Update" >
            <BorderColorOutlined onClick={() => navigate(`update/${id}`)} fontSize="small" />
          </Tooltip>,
        //   <IconButton onClick={() => navigate(`update/${id}`)}>
        //     <Update />
        //   </IconButton>,
        ]}
        title={"Positions List"}
        // deleteUrl={(id) => `position/delete/${id}`}
      />
    </div>
  );
};

export default PositionList;
