/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../store/helpers/mapDispatch";
import { Delete, Download, Warning } from "@mui/icons-material";
import PageLoading from "./PageLoading";
import { downloadBase64, generalDownload } from "../services/common";
import DeleteDialog from "./DeleteDialog";

type IProps = {
  document: string;
  name?: any;
  mimeType?: string;
  height?: any;
  downloadable?: boolean;
  card?: boolean;
  width?: any;
  type?: any;
  specialName?: any;
  className?: string;
  deletable?: boolean;
  special?: boolean;
  onDelete?:any
};

const OpenDocuments = (props: IProps) => {
  const {
    document,
    name,
    mimeType,
    downloadable,
    height,
    width,
    type,
    card,
    specialName,
    className,
    deletable,
    special,
    onDelete
  } = props;
  const [loading, setLoading] = useState(false);
  const [autoDownloading, setAutoDownloading] = useState(false);
  const [data, setData] = useState<any>();
  const [deleteOpen, setDeleteOpen] = useState(0);
  const [deleteActionUrl, setDeleteActionUrl] = useState<
    string | undefined | null
  >();
  const downloadData = async () => {
    setAutoDownloading(true);
    const res = await downloadBase64(document, name, type, mimeType);
    if (res) {
      setData(res);
    }
    setAutoDownloading(false);
  };

  useEffect(() => {
    downloadData().then();
  }, [type, document]);

  const button = (
    <div className={"text-center p-3"}>
      {loading ? (
        <CircularProgress size={30} />
      ) : (
        <>
          {downloadable ? (
            <Button
              startIcon={<Download />}
              className="me-2"
              color="primary"
              onClick={async () => {
                setLoading(true);
                await generalDownload(document, mimeType, specialName, type);
                setLoading(false);
              }}
            >
              Download Document
            </Button>
          ) : null}
          {deletable ? (
            <Button
              startIcon={<Delete />}
              color="error"
              onClick={async () => {
                setLoading(true);
                setDeleteOpen(1);
                setDeleteActionUrl(`/download/file/${document}`);
              }}
            >
              Delete Document
            </Button>
          ) : null}
        </>
      )}
    </div>
  );

  const content = (
    <div>
      {mimeType?.split("/")[0] === "video" ? (
        <>
          <video width="100%" height="80%" controls>
            <source src={data} />
          </video>
          {button}
        </>
      ) : mimeType?.split("/")[0] === "image" ? (
        <>
          <img
            src={data}
            height={height}
            width={width ?? "100%"}
            alt={""}
            className={className}
          />
          {button}
        </>
      ) : mimeType?.split("/")[1] === "pdf" ? (
        data ? (
          <div className="text-center">
            { <object
                data={`${data}#view=Fit&toolbar=0&statusbar=0&messages=0&navpanes=0&scrollbar=0`}
                type="application/pdf"
                style={{display: "inline-block"}}
                width={width ?? "60%"}
                height={height ?? "842"}
            >
              <embed src={data} type="application/pdf"/>
            </object>}
            {button}
          </div>
        ) : (
          <div className={"text-center p-5"}>
            <Typography style={{fontFamily:'inter'}} variant={"h4"}>Document not found !!</Typography>
            <Warning color={"error"} fontSize={"large"} />
          </div>
        )
      ) : loading ? (
        <PageLoading height={"100px"} />
      ) : (
        <div className={"text-center p-6"}>
          <Warning color={"warning"} fontSize={"large"} />
          <Typography style={{fontFamily:'inter'}}>
            Preview is not available on this format, download it to view it on your device
          </Typography>
          <Button
            onClick={async () => {
              setLoading(true);
              await generalDownload(document, mimeType, specialName);
              setLoading(false);
            }}
            startIcon={<Download />}
          >
            Download Document
          </Button>
        </div>
      )}
            {deleteActionUrl ? (
        <DeleteDialog
          open={deleteOpen}
          onSuccess={() => {
            onDelete()
            setLoading(false);
          }}
          setOpen={setDeleteOpen}
          url={deleteActionUrl!}
        />
      ) : null}
    </div>
  );

  return autoDownloading ? (
    <PageLoading />
  ) : card ? (
    <Card className={"mb-3 shadow-sm"}>
      <CardContent>{content}</CardContent>
    </Card>
  ) : (
    <div className={"mb-3"}>{content}</div>
  );
};

export default connect(null, mapDispatchToProps)(OpenDocuments);
