import http from "./http-common";
import authHeader from "./auth-header";
import { saveAs } from "file-saver";
import moment from "moment/moment";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
export const getError = (resp: any) => {
  return (
    resp?.data?.response?.data?.message ??
    resp?.response?.data?.message ??
    resp?.data?.data?.message ??
    resp?.data?.message ??
    resp?.message ??
    COMMON_ERROR
  );
};
export const getSuccessMessage = (resp: any) => {
  return resp?.data?.message ?? "Success !";
};

export const isImage = (name: any) => {
  return isImageExt(name?.split(".")?.pop()?.toLowerCase());
};
export const isImageExt = (name: any) => {
  return inArray(["jpg", "png", "webp", "jpeg", "gif"], name);
};

export const inArray = (haystack: Array<any>, needle: any) => {
  if (!haystack) {
    return false;
  }
  return haystack.filter((v) => v === needle).length > 0;
};

export const getFileMime = (name: any) => {
  let lowerCase = name?.split(".")?.pop()?.toLowerCase();
  return isImageExt(lowerCase)
    ? `image/${lowerCase}`
    : inArray(["pdf"], lowerCase)
    ? "application/pdf"
    : inArray(["mov", "mp4", "mkv", "wmv", "avi", "webm"], lowerCase)
    ? `video/${lowerCase}`
    : "application/octet-stream";
};

type httpResponse = { data: any; status: boolean };
export const submitGeneralForm = async (
  url: string,
  body: any,
  onUploadProgress?: any,
  method?: string | undefined
): Promise<httpResponse> => {
  try {
    const { data } = await (method === "PUT"
      ? http.put(url, body, {
          headers: authHeader(),
          onUploadProgress,
        })
      : http.post(url, body, { headers: { ...authHeader() } }));
    return { data, status: true };
  } catch (error) {
    return { data: error, status: false };
  }
};

export const getQuery = (key: string) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  return params.get(key);
};
export const approveSavings = [
  {
    label: "Summary",
    href: "",
    icon: "bi bi-speedometer",
  },
  {
    label: "Members/Applications",
    href: "applications",
    icon: "bi bi-bank",
  },
  {
    label: "Contributions",
    href: "contributions",
    icon: "bi bi-wallet2",
  },
  {
    label: "Loans",
    href: "loans",
    icon: "bi bi-bank",
  },
  {
    label: "Share Increment",
    href: "increments",
    icon: "bi bi-arrow-up",
  },
  {
    label: "Exit CDE",
    href: "exit",
    icon: "bi bi-escape",
  },
  {
    label: "Social Funds",
    href: "funds",
    icon: "bi bi-people",
  },
  //    {
  //     label:"Penalty",
  //     href:"penaltyPayments",
  //     icon:"bi bi-cash"
  // },
  {
    label: "Expenses",
    href: "expenses",
    icon: "bi bi-files",
  },
  {
    label: "Settings",
    href: "settings",
    icon: "bi bi-cash",
  },
];
export const newCdeMemberNavItem = [
  {
    label: "My Application",
    href: "",
    icon: "bi bi-files",
  },
];
export const savingNavItem = [
  {
    label: "Member Profile",
    href: "",
    icon: "bi bi-settings",
  },
  {
    label: "Contributions",
    href: "contributions",
    icon: "bi bi-wallet2",
  },
  {
    label: "Increment/Decrement",
    href: "increment",
    icon: "bi bi-settings",
  },
  {
    label: "Loans",
    href: "loans",
    icon: "bi bi-bank",
  },
  {
    label: "Social Funds",
    href: "funds",
    icon: "bi bi-cash",
  },
  {
    label: "Exit CDE",
    href: "exit/request",
    icon: "bi bi-escape",
  },
];
export const reportNavItem = [
  {
    label: "Self Service",
    href: "service",
    icon: "bi bi-info-circle",
  },
  //  {
  //     label:"Caisse d'entraide",
  //     href:"caisse",
  //     icon:"bi bi-wallet2"
  // },
  {
    label: "Attendance",
    href: "attendance",
    icon: "bi bi-bank",
  },
  {
    label: "Promotion",
    href: "promotion",
    icon: "bi bi-bank",
  },
];
export const dashboardNavItem = [
  {
    label: "My Dashboard",
    hoverName: "My Dashboard",
    href: "/",
    icon: "bi bi-people-fill",
  },
  {
    label: "Admin Dashboard",
    hoverName: "Admin Dashboard",
    href: "/admin",
    icon: "bi bi-house-door-fill lg",
    module: "dashboard",
  },
];
export const approvalNavItem = [
  {
    label: "Promotions",
    href: "promotions",
    icon: "bi bi-bar-chart-fill",
    module: "promotion",
  },
  {
    label: "Mutation",
    href: "transfers",
    icon: "bi bi-sign-turn-right-fill",
  },
  // {
  //   label: "Leave",
  //   href: "leaves",
  //   icon: "bi bi-house-heart-fill",
  // },
  {
    label: "Mission",
    href: "missions",
    icon: "bi bi-briefcase-fill",
  },
  {
    label: "Self Service",
    href: "requests",
    icon: "bi bi-send-plus-fill",
  },
  {
    label: "Attendance",
    href: "attendance",
    icon: "bi bi-check-circle-fill",
    module: "attendance-report",
  },
];
export const settingsNavItems = [
  {
    label: "Settings",
    href: "/",
    icon: "bi bi-people-fill",
    module: "settings",
  },
  //      {
  //   label: "All Employees",
  //   href: "all-employees",
  //   icon: "bi bi-people-fill",
  //   module: 'settings'
  // },
  // {
  //   label: "Users",
  //   href: "users",
  //   icon: "bi bi-people-fill",
  //   module: "settings"
  // },
  {
    label: "Roles",
    href: "roles",
    icon: "bi bi-gear-fill",
    module: "settings",
  },
  {
    label: "Branches",
    href: "branches",
    icon: "bi bi-tree-fill",
    module: "settings",
  },
  {
    label: "Levels",
    href: "levels",
    icon: "bi bi-person-fill-up",
    module: "settings",
  },
  {
    label: "Echelons",
    href: "echelons",
    icon: "bi bi-capslock-fill",
    module: "settings",
  },
  {
    label: "Banks",
    href: "banks",
    icon: "bi bi-bank2",
    module: "settings",
  },
  {
    label: "Positions",
    href: "positions",
    icon: "bi bi-sort-alpha-up",
    module: "settings",
  },
  {
    label: "Departments",
    href: "departments",
    icon: "bi bi-building-fill",
    module: "settings",
  },
  // {
  //   label: "Leave",
  //   href: "leaveTypes",
  //   icon: "bi bi-house-heart-fill",
  //   module: "settings"
  // },
  {
    label: "Mission",
    href: "mission",
    icon: "bi bi-briefcase-fill",
    module: "settings",
  },
  {
    label: "Holidays",
    href: "holidays",
    icon: "bi bi-house-heart-fill",
    module: "settings",
  },
  // {
  //   label: "Scales",
  //   href: "scales",
  //   icon: "bi bi-sort-alpha-up",
  //   module: "settings"
  // },
  {
    label: "Fiscal Years",
    href: "fiscal",
    icon: "bi bi-clock",
    module: "settings",
  },
  {
    label: "Approve Hierarch",
    href: "hierarch",
    icon: "bi bi-sort-alpha-up",
    module: "settings",
  },
    {
    label: "Attendance",
    href: "attendance",
    icon: "bi bi-bank",
    module: "settings"
  },
];
export const employeeNavItem = [
  {
    label: "All Employees",
    href: "all-employees",
    icon: "bi bi-people-fill",
    module: "settings-user",
  },
  // {
  //   label: "Promotions",
  //   href: "promotions/year",
  //   icon: "bi bi-bar-chart-fill",
  //   module: 'promotion'
  // },
  {
    label: "Mutation",
    href: "",
    icon: "bi bi-sign-turn-right-fill",
    module: "mutation",
  },
  // {
  //   label: "Leave",
  //   href: "leaves",
  //   icon: "bi bi-house-heart-fill",
  //   module: "leave",
  // },
  {
    label: "Mission",
    href: "missions",
    icon: "bi bi-briefcase-fill",
    module: "mission",
  },
  {
    label: "Self Service",
    href: "requests",
    icon: "bi bi-send-plus-fill",
    module: "service-request",
  },
  {
    label: "Attendance",
    href: "attendance",
    icon: "bi bi-check-circle-fill",
    module: "view-attendance",
  },
];
export const monthList = [
  { name: "January", id: 1 },
  { name: "February", id: 2 },
  { name: "March", id: 3 },
  { name: "April", id: 4 },
  { name: "May", id: 5 },
  { name: "June", id: 6 },
  { name: "July", id: 7 },
  { name: "August", id: 8 },
  { name: "September", id: 9 },
  { name: "October", id: 10 },
  { name: "November", id: 11 },
  { name: "December", id: 12 },
];

export const statusList = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Under review",
    value: "under review",
  },
  {
    label: "Request Action",
    value: "request Action",
  },
  {
    label: "Approved",
    value: "approved",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
  {
    label: "Forwarded",
    value: "forwarded",
  },
];
export const leavePlanList = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Approved",
    value: "approved",
  },
  {
    label: "On Leave",
    value: "on leave",
  },
  {
    label: "Canceled",
    value: "canceled",
  },
  {
    label: "Done",
    value: "done",
  },
];
export const loanStatusList = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Received",
    value: "received",
  },
  {
    label: "Request Action",
    value: "request Action",
  },
  {
    label: "Approved",
    value: "approved",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
  {
    label: "Contract Drafting",
    value: "contract drafting",
  },
  {
    label: "Payment Pending",
    value: "payment pending",
  },
  {
    label: "Paid",
    value: "paid",
  },
  {
    label: "Canceled",
    value: "canceled",
  },
  {
    label: "All",
    value: "all",
  },
];
export const guaranteeTypes = [
  {
    label: "CDE Member",
    value: "cdeMember",
  },
  {
    label: "Land/House",
    value: "collateral",
  },
  {
    label: "other",
    value: "other",
  },
];
export const promotionStatusList = [
  {
    label: "Promoted",
    value: "promoted",
  },
  {
    label: "Eligible",
    value: "eligible",
  },
  {
    label: "Not yet eligible",
    value: "not yet eligible",
  },
];
export const incomeReasonList = [
  {
    label: "Penalty",
    value: "penalty",
  },
  {
    label: "Loan Interest",
    value: "loan_interest",
  },
  {
    label: "Expenses",
    value: "expenses",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const relationshipList = [
  {
    label: "Husband",
    value: "husband",
  },
  {
    label: "Wife",
    value: "wife",
  },
  {
    label: "Sister",
    value: "sister",
  },
  {
    label: "Brother",
    value: "brother",
  },
  {
    label: "Parent",
    value: "parent",
  },
  {
    label: "Child",
    value: "child",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const attendanceStatusList = [
  {
    label: "Absent",
    value: "a",
  },
  {
    label: "Late",
    value: "l",
  },
  {
    label: "Holiday",
    value: "h",
  },
  {
    label: "Leave",
    value: "v",
  },
  {
    label: "Present",
    value: "p",
  },
];
export const attendanceDeviceStatus = [
  {
    label: "Registered",
    value: "registered",
  },
  {
    label: "Not registered",
    value: "not registered",
  },
];
export const fetchDetails = async (url: string): Promise<httpResponse> => {
  try {
    const { data } = await http.get(url, { headers: authHeader() });
    return { data: data?.data ?? data, status: true };
  } catch (error) {
    return { data: error, status: false };
  }
};

export const signToIPPIS = async () => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_IPPIS_HOST}/api/authenticate`,
      {
        user: "nppa",
        secretKey:
          "9862ab48603984cf067f81264ee3859de28c86758a7df10bf74ea42ec4cee4b3",
      }
    );
    secureLocalStorage.setItem("ippisHeader", result?.data?.token);

    return result?.data?.token;
  } catch (error) {
    return false;
  }
};
export const fetchDataFromIPPIS = async (
  url: string
): Promise<httpResponse> => {
  try {
    let token: any = secureLocalStorage.getItem("ippisHeader");
    if (!token) {
      token = await signToIPPIS();
    }
    const response = await axios.get(
      `${process.env.REACT_APP_IPPIS_HOST}/api/${url}`,
      { headers: { Authorization: token } }
    );
    return { data: response?.data?.data ?? response?.data, status: true };
  } catch (error: any) {
    return { data:error?.response?.data?.message??'Error Try again' , status: false };
  }
};

export const fetchPaidMembersFromIPPIS = async (period: string) => {
  try {
    let token: any = secureLocalStorage.getItem("ippisHeader");
    if (!token) {
      token = await signToIPPIS();
    }
    const response = await axios.get(
      `${process.env.REACT_APP_IPPIS_HOST}/api/monthly-deductions?period=${period}`,
      { headers: { Authorization: token } }
    );
    return { data: response?.data?.data ?? response?.data, status: true };
  } catch (error: any) {
    return { data:error?.response?.data?.message??'Error Try again' , status: false };
  }
};

export const COMMON_ERROR =
  "Unexpected Error or Something went wrong, try again later!";

export const CheckHasPermission = (module: string, action: string): boolean => {
  const win: any = window;
  return (
    win.permissions?.filter((v: any) => v.name === module && v[action])
      ?.length > 0
  );
};

export const CheckHasAnyPermission = (
  module: string | null | undefined
): boolean => {
  const win: any = window;
  // console.log(  win.permissions?.filter(
  //   (v: any) =>
  //     v.name === module && (v.create || v.update || v.delete || v.read)
  // )?.length > 0)
  return (
    win.permissions?.filter(
      (v: any) =>
        v.name === module && (v.create || v.update || v.delete || v.read)
    )?.length > 0
  );
};

export const CheckSubMenu = (module: string | null | undefined): boolean => {
  const win: any = window;
  // console.log(  win.permissions?.filter(
  //   (v: any) =>
  //     v.name === module && (v.create || v.update || v.delete || v.read)
  // )?.length > 0)
  return (
    win.permissions?.filter(
      (v: any) =>
        v.name === module && (v.create || v.update || v.delete || v.read)
    )?.length > 0
  );
};

export const allowedTypes =
  ".pdf,image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export const generalDownload = async (
  id: any,
  mimeType: any,
  fileName: any,
  type = null
) => {
  try {
    const axios_URL = `download/file/${id}?type=${type}&key=${fileName}`;
    const { data, headers } = await http.get(axios_URL, {
      ...authHeader(),
      responseType: "blob",
    });
    if (data) {
      const file = new Blob([data], {
        type: mimeType,
      });
      let filename = headers["content-disposition"]?.split("filename=")?.pop();
      saveAs(file, filename ?? fileName);
    }
  } catch (error) {
    console.log(error);
  }
};
/**
 * ============================== blobToBase64 ===========================
 * @param {*} blob
 * @returns
 */
function blobToBase64(blob: any) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}
/**
 * =========================== downloadBase64 =======================
 * @param {*} id
 * @param {*} name
 * @param {*} type
 * @param {*} fileType
 * @returns
 */
export const downloadBase64 = async (
  id: any,
  name: any,
  type: any,
  fileType: any
) => {
  try {
    const axios_URL = `download/file/${id}?type=${type}&key=${name}`;
    const { data } = await http.get(axios_URL, {
      ...authHeader(),
      responseType: "blob",
    });
    if (data) {
      const file = new Blob([data], {
        type: fileType,
      });
      return await blobToBase64(file);
    }
  } catch (error) {
    return null;
  }
};
//download file
export const downloadFile = async (url: any) => {
  try {
    const axios_URL = url;
    const { data } = await http.get(axios_URL, {
      headers: { ...authHeader(), responseType: "blob" },
    });
    if (data) {
      const file = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  } catch (error) {
    return null;
  }
};
export const validateEndDate = (v: any, values: any) => {
  const s = values["startDate"];
  return s && v && moment(s).isBefore(moment(v))
    ? true
    : "End date can't be less than start date";
};

export const validateEndTime = (v: any, values: any) => {
  const s = values["startTime"];
  return s && v && moment(s, "HH:mm").isBefore(moment(v, "HH:mm"))
    ? true
    : "End time can't be less than Start time";
};
const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
};
export const validateFileNotNull = (v: any, values: any, params: any) => {
  return v?.length > 0 || !params?.required ? true : "This field is required";
};
