import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { Col, Row } from "react-bootstrap";
import { FormDialog } from "../../../components/FormDialog";
import React, { useEffect } from "react";
import { fetchDetails } from "../../../services/common";
import { AddForm } from "../../employee/AddForm";

const CreateDepartment = () => {
  const navigate = useNavigate();
  const [info, setInfo] = React.useState<any>();
  const [branchList, setBranch] = React.useState<any>([]);
  // const [department, setDepartment] = React.useState<any>([]);
  // const [unit, setUnit] = React.useState<any>([]);

  const { id } = useParams();

  const fetchInfoDetails = async (id: string) => {
    const resp = await fetchDetails(`department/view/${id}`);
    if (resp.status) {
      setInfo(resp.data);
    }
  };

  useEffect(() => {
    if (id) {
      fetchInfoDetails(id).then();
    }
  }, [id]);

  useEffect(() => {
    laodInitialData();
  }, []);
  const laodInitialData = async () => {
    const branch = await fetchDetails("/branch/view");
   if(branch?.status) setBranch(branch?.data);
  };
  // const laodDepartmentOfBranch = async (branchId: string) => {
  //   if (info?.level === "unit") {
  //     const depart = await fetchDetails(
  //       `/department/search?info=${JSON.stringify({
  //         branchId,
  //       })}`
  //     );
  //     setDepartment(depart?.data);
  //   }
  //   {
  //     const depart = await fetchDetails(
  //       `/department/search?info=${JSON.stringify({
  //         Parent: branchId,
  //       })}`
  //     );
  //     setUnit(depart?.data);
  //   }
  // };

  return (
    <div>
      <Breadcrumb />
      <Row className={"justify-content-center"}>
        <Col md={5}>
          <AddForm
            inline={true}
            mainFields={[
              {
                title: "",
                fields: [
             
              {
                label: "Branch",
                name: "branchId",
                type: "select",
                required: true,
                values: branchList?.map((v: any) => ({
                  label: v.name,
                  value: v.id,
                })),
                value: info?.branchId,
                // setValue: (value) => {
                //   if (info?.level === "unit") {
                //     laodDepartmentOfBranch(value);
                //   }
                // },
              },
              {
                label: "Category",
                name: "level",
                type: "select",
                required: true,
                values: [
                  { label: "Department", value: "department" },
                  // { label: "unit", value: "unit" },
                  { label: "Primary level", value: "primary level" },
                ],
                value: info?.level,
                // setValue: (value) => {
                //   setInfo({ ...info, level: value });
                // },
              },
              // {
              //   label: "Department",
              //   name: "parent",
              //   type: "select",
              //   required: true,
              //   // hidden: info?.level !== "unit" && info?.level !== "level",
              //   values: department?.map((v: any) => ({
              //     label: v.name,
              //     value: v.id,
              //   })),
              //   value: info?.parent,
              //   setValue: (value) => {
              //     if (info?.level === "level") {
              //       laodDepartmentOfBranch(value);
              //     }
              //   },
              // },
              // {
              //   label: "Unit",
              //   name: "parent",
              //   type: "select",
              //   required: true,
              //   hidden: info?.level !== "level",
              //   values: unit?.map((v: any) => ({
              //     label: v.name,
              //     value: v.id,
              //   })),
              //   value: info?.parent,
              // },
              {
                label: "Name",
                name: "name",
                // type: "name",
                value: info?.name,
                required: true,
              },
            ]}]}
            url={id ? `department/update/${id}` : "department/create"}
            method={id ? "PUT" : "POST"}
            title={"Create New Department"}
            onSuccess={() => navigate(-1)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CreateDepartment;
