import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { DatatableComponent } from "../../../components/DatatableComponent";
import moment from "moment";
import StatusComponent from "../../../components/StatusComponent";
import { Add, BorderColorOutlined } from "@mui/icons-material";
import { CustomizedMenus } from "../../../components/customizeMenu";
import { Box, Button, Tab, Tabs, styled } from "@mui/material";
import { TabPanel, a11yProps } from "../../employee/leave/List";
import { Col, ProgressBar, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import { AddForm } from "../../employee/AddForm";
import GeneralButton from "../../../components/Button";
import { default as ReactSelect } from "react-select";
import {
  fetchDetails,
  loanStatusList,
  statusList,
} from "../../../services/common";

const ApprovalLoanList = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [list, setList] = useState<Array<any>>([]);
  const [addLoanType, setAddLoanType] = useState(0);
  const [info, setInfo] = useState<any>({
    fiscalYear: "all",
    status: "all",
    loanTypeId: "all",
  });

  const [types, setType] = useState<any>();
  const [fiscalYearList, setFiscalYearList] = useState<Array<any>>([]);
  const [fiscalYear, setFiscalYear] = useState<any>();

  const getFiscalYear = async () => {
    let allYear = [];
    let allType = [];
    const fiscalYear = await fetchDetails("/fiscal/view");
    const resp = await fetchDetails(`loan/view-loan-type`);
    if (resp.status) {
      allType = resp?.data?.map(({ label, id, name }: any) => ({
        value: id,
        label: label ?? name,
      }));
      allType.push({ value: "all", label: "All" });
      setType(allType);
    }
    if (fiscalYear.status) {
      const currentFiscalYear = fiscalYear?.data?.find(
        (year: any) => year.status === "open"
      );
      allYear = fiscalYear?.data?.map(({ label, id, name }: any) => ({
        value: id,
        label: label ?? name,
      }));
      allYear.push({ value: "all", label: "All" });
      setFiscalYearList(allYear);
      setFiscalYear(currentFiscalYear?.id);
    }
  };
  useEffect(() => {
    //fetch all FiscalYear
    getFiscalYear().then();
  }, []);
  // const reload = () => {
  //   setChange((v) => v + 1);
  // };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const StyledTabs = styled((props: any) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      backgroundColor: "#035c85",
    },
  });
  const StyledTab = styled((props: any) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      "&.Mui-selected": {
        color: "#035c85",
      },
      "&.Mui-focusVisible": {
        color: "#035c85",
      },
    })
  );

  const infoToSearch = info ? `&info=${JSON.stringify(info)}` : ""; //info for search

  return (
    <div>
      <Row className="mb-">
        <Col md={2}>
          <label className="col-form-label text-capitalize">Fiscal Year</label>
          <ReactSelect
            className="text-nowrap"
            options={fiscalYearList}
            value={fiscalYearList?.find(
              (option: any) => option.value === info?.fiscalYear
            )}
            onChange={(v: any) => {
              setInfo({ ...info, fiscalYear: v?.value });
            }}
          />
        </Col>
        <Col md={2}>
          <label className="col-form-label text-capitalize">Status</label>
          <ReactSelect
            className="text-nowrap"
            options={loanStatusList}
            value={loanStatusList?.find(
              (option: any) => option.value === info?.status
            )}
            onChange={(v: any) => {
              setInfo({ ...info, status: v?.value });
            }}
          />
        </Col>
        <Col md={2}>
          <label className="col-form-label text-capitalize">Type</label>
          <ReactSelect
            className="text-nowrap"
            options={types}
            value={types?.find(
              (option: any) => option.value === info?.loanTypeId
            )}
            onChange={(v: any) => {
              setInfo({ ...info, loanTypeId: v?.value });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Box sx={{ borderBottom: 1, Update: "divider" }}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <StyledTab label="Loan Request" {...a11yProps(0)} />
              <StyledTab label="Loan Payment" {...a11yProps(1)} />
              {/* <StyledTab label="Loan Type Settings" {...a11yProps(2)} /> */}
            </StyledTabs>
          </Box>
          <TabPanel value={value} index={0} p={0}>
            <DatatableComponent
              columns={[
                {
                  label: "Employee",
                  name: "names",
                },
                {
                  label: "Type",
                  name: "loanType",
                  options: {
                    //   filter: true,
                    // sort:true,
                    customBodyRenderLite: (id: any, index: any) =>
                      list[index]?.LoanType?.label,
                  },
                },
                {
                  label: "Amount",
                  name: "amount",
                  options: {
                    //   filter: true,
                    //   sort:true,
                    customBodyRender: (v: any) => v?.toLocaleString(),
                  },
                },

                // {
                //   label: "Interest Rate",
                //   name: "interestRate",
                //   options: {
                //   //   filter: true,
                //   //   sort:true,
                //     customBodyRender: (v: any) => v?.toLocaleString(),
                //   },
                // },
                // {
                //   label: "Amount To Pay",
                //   name: "amountToPay",
                //   options: {
                //   //   filter: true,
                //   //   sort:true,
                //     customBodyRender: (v: any) => v?.toLocaleString(),
                //   },
                // },
                // {
                //   label: "Monthly Payment",
                //   name: "monthlyAmount",
                //   options: {
                //   //   filter: true,
                //   //   sort:true,
                //     customBodyRender: (v: any) => v?.toLocaleString(),
                //   },
                // },
                // {
                //   label: "First Payment",
                //   name: "firstPayment",
                //   options: {
                //     //   filter: true,
                //     //   sort:true,
                //     customBodyRender: (v: any) =>
                //       moment(v).format("YYY-MMM-DD"),
                //   },
                // },
                // {
                //   label: "Last Payment",
                //   name: "lastPayment",
                //   options: {
                //     //   filter: true,
                //     //   sort:true,
                //     customBodyRender: (v: any) =>
                //       moment(v).format("YYY-MMM-DD"),
                //   },
                // },

                {
                  label: "Request Date",
                  name: "createdAt",
                  options: {
                    //   filter: true,
                    //   sort:true,
                    customBodyRender: (v: any) =>
                      moment(v).format("YYYY-MMM-DD "),
                  },
                },
                {
                  label: "Status",
                  name: "status",
                  options: {
                    //   filter: true,
                    //   sort:true,
                    customBodyRender: (v: any) => (
                      <StatusComponent status={v} badge={false} />
                    ),
                  },
                },
                {
                  name: "id",
                  label: "Action",
                  options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (id: any) => {
                      return (
                        <CustomizedMenus
                          onView={() => {
                            navigate(`details/${id}`);
                          }}
                        />
                      );
                    },
                  },
                },
              ]}
              url={`approvalLoan/datatable?${infoToSearch}`}
              title={"Loan Requests List"}
              processData={(data: any) => {
                setList(data.data);
                return data;
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={1} p={0}>
            <DatatableComponent
              columns={[
                {
                  label: "Employee",
                  name: "names",

                  // options: {
                  //   //   filter: true,
                  //   // sort:true,
                  //   customBodyRenderLite: (id: any, index: any) =>
                  //     list[index]?.CaisseMember?.employee?.names,
                  //   // customBodyRender: (v: any) => v?.employee?.names,
                  // },
                },
                {
                  label: "Type",
                  name: "loanType",
                  options: {
                    //   filter: true,
                    // sort:true,
                    customBodyRenderLite: (id: any, index: any) =>
                      list[index]?.LoanType?.label,
                  },
                },
                {
                  label: "Principal(RWF)",
                  name: "amount",
                  options: {
                    //   filter: true,
                    //   sort:true,
                    customBodyRender: (v: any) => v?.toLocaleString(),
                  },
                },
                // {
                //   label: "To Pay(RWF)",
                //   name: "amountToPay",
                //   options: {
                //     //   filter: true,
                //     //   sort:true,
                //     customBodyRender: (v: any) => v?.toLocaleString(),
                //   },
                // },
                {
                  label: "Monthly(RWF)",
                  name: "monthlyAmount",
                  options: {
                    //   filter: true,
                    //   sort:true,
                    customBodyRender: (v: any) => v?.toLocaleString(),
                  },
                },
                {
                  label: "Paid",
                  name: "amountPaid",
                  options: {
                    //   filter: true,
                    //   sort:true,
                    customBodyRenderLite: (id: any, index: any) => {
                      const now =
                        (list[index]?.amountPaid * 100) /
                        list[index]?.amountToPay;
                      const color = now > 50 ? "success" : "info";
                      return (
                        <ProgressBar
                          variant={color}
                          min={10}
                          now={now}
                          label={`${now?.toFixed()}%`}
                        />
                      );
                    },
                  },
                },
                {
                  label: "Has Top-up",
                  name: "hasTopUp",
                  options: {
                    customBodyRender: (v: any) => (v ? "Yes" : "No"),
                  },
                },

                {
                  label: "Status",
                  name: "status",
                  options: {
                    //   filter: true,
                    //   sort:true,
                    customBodyRender: (v: any) => (
                      <StatusComponent status={v} badge={false} />
                    ),
                  },
                },
                {
                  name: "id",
                  label: "Action",
                  options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (id: any) => {
                      return (
                        <CustomizedMenus
                          onView={() => {
                            navigate(`details/${id}`);
                          }}
                        />
                      );
                    },
                  },
                },
              ]}
              url={`approvalLoan/datatable/loan-payment?${infoToSearch}`}
              title={"Loans Payment List"}
              processData={(data: any) => {
                setList(data.data);
                return data;
              }}
            />
          </TabPanel>

          {/* <TabPanel value={value} index={2}>
          
          </TabPanel> */}
        </Col>
      </Row>
    </div>
  );
};

export default ApprovalLoanList;
