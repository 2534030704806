import React from "react";
import Breadcrumb from "./Breadcrumb";

type IProps = {
    title:string
}

const Placeholder = (props:IProps)=>{
    const {title} = props;
    return <div>
        <Breadcrumb/>
        <div className={'holder-center'}>
            <div className={'text-center'}>
                <div><i className={'bi bi-info-circle'}></i></div>
                {title}
            </div>
        </div>
    </div>
}

export default Placeholder;
