import  { JSX, useState } from "react";
import moment from "moment";
import ReportEngineComponent from "../../../components/ReportEngineComponent";

function CaisseMemberReport(): JSX.Element {
  const [value, setValue] = useState<any>({});

  return (
    <ReportEngineComponent
      setValue={setValue}
      value={value}
      title={`Caisse D'entrade Members Report`}
      url={`cde-report/members`}
      columns={[
        {
          label: "No",
          name: "index",
          type:'integer',
        },
        {
          label: "Member",
          name: "employee.names",
          data: "employee.firstName",
        },
        // {
        //   label: " Loan",
        //   name: "totalLoan",
        //     type:'number',
        // //   type: "number",
        //   options: {
        //     customBodyRender: (v: any) => v?.toLocaleString(),
        //   },
        // },
        {
          label: "Shares(RWF)",
          name: "totalShare",
          type: "number",
          //   type: "number",
          options: {
            customBodyRender: (v: any) => v?.toLocaleString(),
          },
        },
        {
          label: "Monthly(RWF)",
          name: "share",
          type: "number",
          //   type: "number",
          options: {
            customBodyRender: (v: any) => v?.toLocaleString(),
          },
        },
        {
          label: "Increment Of(RWF)",
          name: "incrementAmount",
          type: "number",
          //   type: "number",
          options: {
            customBodyRender: (v: any) => v?.toLocaleString(),
          },
        },
        {
          label: "Increment Start",
          name: "shareIncrementStart",
            type: "date",
          options: {
            customBodyRender: (v: any) =>
              v ? moment(v).format("YYYY-MMM-DD") : null,
          },
        },
        {
          label: "Increment End",
          name: "shareIncrementEnd",
            type: "date",
          options: {
            customBodyRender: (v: any) =>
              v ? moment(v).format("YYYY-MMM-DD") : null,
          },
        },
        // {
        //   label: "Status",
        //   name: "status",
        //   options: {
        //     customBodyRender: (v: any) => (
        //       <Chip
        //         label={v}
        //         size={"small"}
        //         color={v === "inactive" ? "error" : "success"}
        //       />
        //     ),
        //   },
        // },
        {
          label: "Joined At",
          name: "joinedAt",
          type: "date",
          options: {
            customBodyRender: (v: any) => moment(v).format("YYYY-MMM-DD"),
          },
        },
      ]}
      filters={[
        {
          label: "Has Loan",
          name: "hasLoan",
          values: [
            {
              label: "Yes",
              value: "1",
            },
            {
              label: "No",
              value: "0",
            },
          ],
        },
        {
          label: "Employee Name/ID",
          name: "empName",
          type: "text",
        },
        {
          label: "CDE Number",
          name: "cdeNumber",
          type: "text",
        },
        {
          label: "Status",
          name: "status",
          values: [
            {
              label: "Active",
              value: "active",
            },
            {
              label: "Inactive",
              value: "inactive",
            },
          ],
        },
        {
          label: "Joined From",
          name: "startDate",
          type: "date",
        },
        {
          label: "Joined To",
          name: "endDate",
          type: "date",
        },
         {
          label: "Increased Share",
          name: "shareIncrease",
          values: [
            {
              label: "Yes",
              value: "yes",
            },
            {
              label: "No",
              value: "no",
            },
          ],
        },
      ]}
    />
  );
}

export default CaisseMemberReport;
