import React, { JSX, useEffect, useState } from "react";
import { fetchDetails, statusList } from "../../../services/common";
import moment from "moment";
import { Chip } from "@mui/material";
import ReportEngineComponent from "../../../components/ReportEngineComponent";

function MissionReport(): JSX.Element {
  const [districts, setDistricts] = useState([]);
  const [value, setValue] = useState<any>({});

  const loadInitialData = async () => {
    const district = await fetchDetails(`report/districts`);
    if (district.status) {
      setDistricts(district.data);
    }
  };

  useEffect(() => {
    loadInitialData().then();
  }, []);

  return (
    <ReportEngineComponent
    setValue={setValue}
    value={value}
      title={"Mission Report"}
      url={`report/mission`}
      columns={[
        {
          label: "Emp. ID",
          name: "missionaryEmployee.employeeNumber",
        //   data: "requesterUser.employeeId",
        //      options: {
        //         // sort: false,
        //         customBodyRender: (v: any) => <span>{v}</span>,

        // }
        },
        {
          label: "Emp. Names",
          name: "missionaryEmployee.names",
          data: "missionaryEmployee.firstName",
        },
        {
          label: "Destination",
          name: "District.name",
        },
        //   {
        //     label: "Requester",
        //     name: "requesterUser.Employee.names",
        //     data: "requesterUser.Employee",
        //   },

        {
          label: "Start Date",
          name: "startDate",
          type: "date",
          options: {
            customBodyRender: (v: any) =>
              v ? moment(v).format("YYYY-MMM-DD") : "",
          },
        },
        {
          label: "End Date",
          name: "endDate",
          type: "date",
          options: {
            customBodyRender: (v: any) =>
              v ? moment(v).format("YYYY-MMM-DD") : "",
          },
        },
        {
          label: "Status",
          name: "status",
          options: {
            customBodyRender: (v: any) => <Chip label={v} size={"small"} />,
          },
        },
      ]}
      filters={[
        {
          label: "Destination",
          name: "destination",
          values: districts?.map(({ name, id }: any) => ({
            label: name,
            value: id,
          })),
        },
        // {
        //   label: "Requester",
        //   name: "requester",
        //   values: requesters.map(({ names, user }: any) => ({
        //     label: names,
        //     value: user?.id,
        //   })),

        // },
        {
          label: "Employee Name/ID",
          name: "empName",
          type: "text",
        },
        {
          label: "Status",
          name: "status",
          values: statusList,
        },
        {
          label: "Start Date",
          name: "startDate",
          type: "date",
          required:true

        },
        {
          label: "End Date",
          name: "endDate",
          type: "date",
          required:true

        },
      ]}
    />
  );
}

export default MissionReport;
