import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.esm.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { configureStore } from "./store/configureStore";
// import { setEditorHandler, reportBuildError, dismissBuildError } from 'react-error-overlay';

// // Disable error overlay
// setEditorHandler(() => {});
// reportBuildError( () => {});
// dismissBuildError ( () => {});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const store = configureStore();
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();
