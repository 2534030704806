import {  useEffect, useState } from "react";
import { fetchDetails } from "../../../services/common";
import moment from "moment";
import { Chip } from "@mui/material";
import ReportEngineComponent from "../../../components/ReportEngineComponent";

const MarksReport = () => {
  const [fiscalYears, setFiscalYears] = useState([]);
  const [value, setValue] = useState<any>({});

  const loadInitialData = async () => {
    // const employeeList = await fetchDetails("report/caisse/members");
    const fiscalYears = await fetchDetails(`fiscal/view`);
    if (fiscalYears.status) {
      setFiscalYears(fiscalYears.data);
    }
    // if (employeeList.status) setRequesters(employeeList?.data);
  };

  useEffect(() => {
    loadInitialData().then();
  }, []);
  return (
    <ReportEngineComponent
    setValue={setValue}
    value={value}
      title={"Promoted list Report"}
      url={`report/promotion-marks`}
      columns={[
        {
          label: "Emp. ID",
          name: "employee.employeeNumber",
        },
        {
          label: "Emp. Names",
          name: "employee.names",
          data: "employee.firstName",
        },
        {
          label: `Marks(%)`,
          name: "marks",
          type:'number'
        },
        {
          label: "Year",
          name: "FiscalYear.name",
        },
        {
          label: "Created At",
          name: "createdAt",
          type:'dateTime',
          options: {
            customBodyRender: (v: any) =>moment(v).format('lll')
          }
        },
        {
          label: "Status",
          name: "status",
          options: {
            customBodyRender: (v: any) => (
              <Chip
                label={v}
                size={"small"}
                color={v === "used" ? "success" : "error"}
              />
            ),
          },
        },
      ]}
      filters={[
        {
          label: "Fiscal Years",
          name: "fiscal",
          values: fiscalYears.map(({ name, id }: any) => ({
            label: name,
            value: id,
          })),
        },
        {
          label: "Employee Name/ID",
          name: "empName",
          type: "text",
        },
        {
          label: "Status",
          name: "status",
          values: [
            { value: "used", label: "Used" },
            { value: "not used", label: "Not used" },
          ],
        },
        {
          label: "Start Date",
          name: "startDate",
          type: "date",
        },
        {
          label: "End Date",
          name: "endDate",
          type: "date",
        },
      ]}
    />
  );
};

export default MarksReport;
