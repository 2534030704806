import { CheckCircle, PaymentSharp, Visibility } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useState } from "react";
import SmallDialog from "../../../components/SmallModel";
import { monthList, submitGeneralForm } from "../../../services/common";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/helpers/mapState";
import GeneralButton from "../../../components/Button";
import PayLoan from "../../saving/loans/PayLoan";
type Iprops = {
  history: Array<any>;
  setOpenDocument: (open: boolean) => void;
  setDocument: (document: any) => void;
  reload: () => void;
  loggedUser: any;
  isInitiator: any;
  isApprover: any;
};

const LoanPaymentHistory = (props: Iprops) => {
  const {
    history,
    setDocument,
    setOpenDocument,
    reload,
    loggedUser,
    isInitiator,
    isApprover,
  } = props;
  const [approvePayment, setApprovePayment] = useState<boolean>(false);
  const [newData, setData] = useState<any>();
  const [addPayment, setAddPayment] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const resp = await submitGeneralForm(
      `/approvalLoan/approve-payment/${data?.id}`,
      data,
      null,
      "PUT"
    );
    if (resp.status) {
      reload();
      //   setData({});
      //   setError(null);
      //   setOpenDialogue(false);
      //   loadInfo().then();
      // } else {
      //   setError(getError(resp));
      setApprovePayment(false);
    }
    setLoading(false);
  };

  const isNotInService =
    (loggedUser?.Employee?.status === "retired" ||
      loggedUser?.Employee?.status === "exited") &&
    isInitiator;
  const isAnyPending=history?.find((v:any)=>v?.status==="payment pending"||v?.status==="pending")

  const sortedList=history?.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
  return (
    <>
      <TableContainer className="border">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Period</TableCell>
              <TableCell>Amount Paid(RWF)</TableCell>
              <TableCell>Required To Pay(RWF)</TableCell>
              <TableCell>Penalty(RWF)</TableCell>
              <TableCell>Payment Proof</TableCell>
              <TableCell>Status</TableCell>
              {isAnyPending?<TableCell>Action</TableCell>:null}
            </TableRow>
          </TableHead>
          <TableBody>
            {history?.length > 0 ? (
              sortedList?.map((doc: any) => {
                // const month = monthList?.find((v) => v?.id === doc?.month);
                const hasOutLoan =
                  doc?.outStandingPayment && doc?.status === "payment pending";
                const unPaidLoan = doc?.status === "payment pending";
                const canApprove = isApprover && doc?.supportDocument&&doc?.status === "pending";
                return (
                  <TableRow>
                    <TableCell>{doc?.period}</TableCell>
                    <TableCell>{doc.amount?.toLocaleString()}</TableCell>
                    <TableCell>{doc.requiredAmount?.toLocaleString()}</TableCell>
                    <TableCell>{doc.penalty?.toLocaleString()}</TableCell>
                    <TableCell>
                      {doc?.supportDocument ? (
                        <Visibility
                          role="button"
                          fontSize="small"
                          onClick={() => {
                            setDocument(doc?.supportDocument);
                            setOpenDocument(true);
                          }}
                        />
                      ) : (
                        "None"
                      )}
                    </TableCell>
                    <TableCell
                      className={
                        doc.status === "payment pending"||doc.status === "pending"
                          ? "fw-bold text-info"
                          : "fw-bold text-success"
                      }
                    >
                      {" "}
                      {doc.status}
                    </TableCell>
                    {isAnyPending?
                    <TableCell>
                      {(unPaidLoan && isNotInService) ||
                      (hasOutLoan && !isNotInService) ? (
                        <GeneralButton
                          variant="outlined"
                          size="small"
                          name={"Pay Loan"}
                          icon={<PaymentSharp />}
                          onClick={() => {
                            setAddPayment(1);
                            setData(doc);
                          }}
                        />
                      ) : null}
                      {canApprove?
                         <Button
                         variant="contained"
                         size="small"
                         color={'success'}
                         startIcon={<CheckCircle />}
                         onClick={() => {
                          setApprovePayment(true);
                          setData(doc);
                         }}
                       >{"Approve"}</Button>
                      :null}

                    </TableCell>:null}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={3} className="text-center">
                  {"No record found"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <SmallDialog
        open={approvePayment}
        setOpen={setApprovePayment}
        onSubmit={() => {
          onSubmit({ ...newData, status: "approved" });
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <div className="d-flex ">
            <CheckCircleIcon color="success" sx={{ fontSize: 50 }} />
            <div className="mt-4">
              {"Are you sure you want to approve loan payment?"}
            </div>
          </div>
        )}
      </SmallDialog>
      <PayLoan
        setAddPayment={setAddPayment}
        addPayment={addPayment}
        loan={newData}
        onSuccess={() => {
          setAddPayment(0);
          reload();
        }}
      />
    </>
  );
};
// export default LoanPaymentHistory;
export default connect(mapStateToProps)(LoanPaymentHistory);
