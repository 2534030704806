import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { DatatableComponent } from "../../../components/DatatableComponent";
import IconButton from "@mui/material/IconButton";
import { AccessAlarm, Accessibility, BorderColorOutlined, KeyOff, VpnKey } from "@mui/icons-material";
import moment from "moment/moment";
import { Tooltip } from "@mui/material";

const RoleList = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Breadcrumb
        addButton={{ label: "Add Role", onClick: () => navigate("create") }}
      />
      <DatatableComponent
        columns={[
          {
            label: "Label",
            name: "label",
          },
          {
            label: "Main",
            name: "main",
            options: {
              customBodyRender: (v: any) => (v ? "Yes" : "No"),
            },
          },
          {
            label: "Date",
            name: "createdAt",
            options: {
              customBodyRender: (v: any) =>
                moment(v).format("YYYY-MMM-DD HH:mm"),
            },
          },
        ]}
        url={"role/datatable"}
        title={"Roles List"}
        actions={(id) => [
          <Tooltip title={'Update'}>
            <IconButton onClick={() => navigate(`update/${id}`)}>
            <BorderColorOutlined />
          </IconButton>
          </Tooltip>,
          <Tooltip title={'Permissions'}>
             <IconButton
            className="text-info"
            onClick={() =>
              navigate(`permission/${id}`, { state: { roleId: id } })
            }
          >
            <VpnKey />
          </IconButton>
          </Tooltip>
         ,
        ]}
        // deleteUrl={(id) => `role/delete/${id}`}
      />
    </div>
  );
};

export default RoleList;
