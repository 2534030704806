import { DatatableComponent } from "../../../components/DatatableComponent";
import moment from "moment";
import { useEffect, useState } from "react";
import { CustomizedMenus } from "../../../components/customizeMenu";
import FileUpload from "../../../components/FileUploader";
import { AddForm } from "../../employee/AddForm";
import {
  CheckHasPermission,
  fetchDetails,
  getFileMime,
  validateFileNotNull,
} from "../../../services/common";
import {
  Approval,
  Check,
  CheckCircle,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import SmallDialog from "../../../components/SmallModel";
import OpenDocuments from "../../../components/openDocument";
import { Collapse } from "@mui/material";
import StatusComponent from "../../../components/StatusComponent";
import { mapDispatchToProps } from "../../../store/helpers/mapDispatch";
import { mapStateToProps } from "../../../store/helpers/mapState";
import { connect } from "react-redux";

function ExpensesList(props: any) {
  const {
    setOpen,
    open,
    setDeleteActionUrl,
    setDeleteOpen,
    change,
    setChange,
    fiscalYear,
    createdAt,
    fiscalYearList,
    setOpenDialogue,
    setData,
    loggedUser,
  } = props;
  const [list, setList] = useState<Array<any>>([]);
  const [document, setDocument] = useState<any>([]);
  const [info, setInfo] = useState<any>();
  // const [account, setAccount] = useState<any>();
  const [viewDocument, setViewDocument] = useState<any>(false);
  const [index, setIndex] = useState<any>(null);

  const [categories, setCategories] = useState<Array<any>>([]);
  const getCategories = async () => {
    const categories = await fetchDetails("/cde/expense-categories");

    if (categories.status) {
      setCategories(categories?.data);
    }
  };

  useEffect(() => {
    //fetch all FiscalYear
    getCategories().then();
  }, []);

  // const maxAmount = account?.expenses - account?.consumedExpenses;

  return (
    <>
      <DatatableComponent
        columns={[
          {
            label: "Date",
            name: "createdAt",
            options: {
              customBodyRender: (v: any) => moment(v).format("L"),
            },
          },
          {
            label: "Category",
            name: "ExpenseCategory.name",
          },
          {
            label: "Description",
            name: "description",
            options: {
              customBodyRenderLite: (id: any, key: any) => {
                const v = list[key]?.description;
                const shortString =
                  index !== id
                    ? v?.substring(0, 20)
                    : v?.substring(0, v?.length);
                return (
                  <div>
                    {index === id ? (
                      <Collapse in={index === id} timeout="auto" unmountOnExit>
                        {`${shortString}...`}

                        <span
                          className="text-primary"
                          role="button"
                          onClick={() => {
                            setIndex(null);
                          }}
                        >
                          less
                        </span>
                      </Collapse>
                    ) : (
                      <>
                        {`${shortString}...`}
                        <span
                          className="text-primary"
                          role="button"
                          onClick={() => {
                            setIndex(id);
                          }}
                        >
                          more
                        </span>
                      </>
                    )}
                  </div>
                );
              },
            },
          },
          {
            label: "Amount(RWF)",
            name: "amount",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (v: any) => v?.toLocaleString(),
            },
          },
          {
            label: "Attachment",
            name: "supportDocument",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (v: any) => {
                return v ? (
                  <span
                    className="ms-3"
                    role="button"
                    onClick={() => {
                      setViewDocument(!viewDocument);
                      setDocument(v);
                    }}
                  >
                    {viewDocument ? (
                      <VisibilityOff fontSize="small" />
                    ) : (
                      <Visibility fontSize="small" />
                    )}
                  </span>
                ) : (
                  "none"
                );
              },
            },
          },
          {
            label: "Status",
            name: "status",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (v: any) => (
                <StatusComponent status={v} badge={false} />
              ),
            },
          },
          {
            name: "id",
            label: "Action",
            options: {
              filter: true,
              sort: false,
              customBodyRenderLite: (id: any, index: any) => {
                const canApprove =
                  loggedUser.id === list[index]?.approver &&
                  CheckHasPermission("approval-saving", "create") &&
                  list[index]?.status === "pending";

                return (
                  <>
                    {canApprove ? (
                      <CheckCircle
                        role="button"
                        color="success"
                        onClick={() => {
                          setOpenDialogue(true);
                          setData(list[index]);
                        }}
                      />
                    ) : null}
                    <CustomizedMenus
                      onEdit={() => {
                        setOpen(1);
                        setInfo(list[index]);
                      }}
                      onDelete={() => {
                        setDeleteOpen(1);
                        setDeleteActionUrl(
                          `cde/record-expenses/${list[index]?.id}`
                        );
                      }}
                      needEdit={
                        CheckHasPermission("approval-saving", "create") &&
                        list[index]?.status === "pending"
                      }
                      needDelete={
                        CheckHasPermission("approval-saving", "create") &&
                        list[index]?.status === "pending"
                      }
                    />
                  </>
                );
              },
            },
          },
        ]}
        url={`cde/datatable-expenses/?change=${change}&fiscalYear=${fiscalYear}${
          createdAt ? `&createdAt=${createdAt}` : ""
        }`}
        title={`CDE Expenses`}
        processData={(data: any) => {
          setList(data?.data);
          // setAccount(data?.accountStat);
          return data;
        }}
      />

      <AddForm
        // title={"Record new expenses"}
        title={info?.id ? "Update expenses" : "Record new expenses"}
        maxWidth="md"
        mainFields={[
          {
            title: "",
            fields: [
              {
                label: "Amount(RWF)",
                name: "amount",
                col: 12,
                required: true,
                value: info?.amount,
                type:'number'
                // max: info?.id || maxAmount < 0 ? null : maxAmount,
              },
              {
                label: "Category",
                name: "categoryId",
                type: "select",
                col: 12,
                required: true,
                value: info?.categoryId,
                values: categories?.map((v) => ({
                  value: v.id,
                  label: v.name,
                })),
              },
              {
                label: "Fiscal Year",
                name: "fiscalYear",
                col: 12,
                required: true,
                type: "select",
                value: info?.fiscalYear,
                values: fiscalYearList?.map(({ label, id, name }: any) => ({
                  value: id,
                  label: label ?? name,
                })),
              },
              {
                label: "Date",
                name: "date",
                type: "date",
                col: 12,
                required: true,
                value: info?.date,
              },
              {
                label: "Description",
                name: "description",
                type: "textarea",
                col: 12,
                required: true,
                value: info?.description,
              },
              {
                label: "Supporting Document",
                name: "supportingDocument",
                type: "file",
                multiple: true,
                col: 12,
                value: document,
                required: !info?.id,
                validate: validateFileNotNull,
                render: () => {
                  return (
                    <FileUpload
                      setFiles={setDocument}
                      files={document}
                      title="Upload"
                      required={!info?.id}
                      accept={"application/pdf,image/*"}
                      currentFile={info?.supportDocument}
                    />
                  );
                },
              },
            ],
          },
        ]}
        url={
          info?.id
            ? `cde/record-expenses?id=${info?.id}`
            : "cde/record-expenses/"
        }
        method={"POST"}
        onSuccess={() => {
          setChange(change + 1);
          setOpen(0);
        }}
        open={open}
        setOpen={() => {
          setOpen(0);
          setInfo(null);
          setDocument([])
        }}
      />
      {/* view supporting document model */}
      <SmallDialog
        maxWidth="lg"
        title="SUPPORTING DOCUMENT"
        buttonTitle="Close"
        needButton={true}
        open={viewDocument}
        setOpen={setViewDocument}
        onSubmit={() => {
          setViewDocument(false);
        }}
      >
        {viewDocument ? (
          <div className={"row justify-content-center"}>
            <div className={"col-md-7"}>
              {document?.map((v: string) => (
                <OpenDocuments
                  downloadable={true}
                  card={true}
                  document={v}
                  mimeType={getFileMime(v)}
                />
              ))}
            </div>
          </div>
        ) : null}
      </SmallDialog>
    </>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ExpensesList);

// export default ExpensesList;
