import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";

function ApprovalLayout() {
  const title = "Approvals";

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {/* <BlueNavBar
        baseUrl={"/console/approvals/"}
        items={[
          {
            label: "Promotions",
            href: "promotions",
            icon: "bi bi-bar-chart-fill",
            module: "promotion",
          },
          {
            label: "Mutation",
            href: "transfers",
            icon: "bi bi-sign-turn-right-fill",
          },
          {
            label: "Leave",
            href: "leaves",
            icon: "bi bi-house-heart-fill",
          },
          {
            label: "Mission",
            href: "missions",
            icon: "bi bi-briefcase-fill",
          },
          {
            label: "Self Service",
            href: "requests",
            icon: "bi bi-send-plus-fill",
          },
          {
            label: "Attendance",
            href: "attendance",
            icon: "bi bi-check-circle-fill",
            module: "attendance",
          },
        ]}
      /> */}
       <div className="container-fluid">
      <div className="row">
          <div className='col-md-12'>
          <Outlet />
          </div>
          </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default ApprovalLayout;
