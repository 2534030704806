import { Helmet } from 'react-helmet';
import {Outlet} from "react-router-dom";
import React, {useEffect, useState} from "react";
import BlueNavBar from "../layouts/BlueNavBar";
import {connect} from "react-redux";
import {mapStateToProps} from "../store/helpers/mapState";
import {mapDispatchToProps} from "../store/helpers/mapDispatch";
import PageLoading from "../components/PageLoading";
import AcceptCaiseTermConditions from "../components/AcceptCaiseTermConditions";
import useAuth from "../hooks/useAuth";
import {Col, Row} from "react-bootstrap";
import useStyles from './style';

function ReportingLayout() {
  const title = "Reporting";
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
        <>
            {/* <BlueNavBar baseUrl={'/console/report/'} items={[
               {
                label: "Self Service",
                href: "service",
                icon: "bi bi-info-circle",
              },
            //  {
            //     label:"Caisse d'entraide",
            //     href:"caisse",
            //     icon:"bi bi-wallet2"
            // },
            {
                label:"Attendance",
                href:"attendance",
                icon:"bi bi-bank"
            },
            {
                label:"Promotion",
                href:"promotion",
                icon:"bi bi-bank"
            },
            ]}/> */}
      {/* <div className={classes.content}> */}
      <div className="container-fluid">
      <div className="row">
          <div className='col-md-12'>
          <Outlet />
          </div>
          </div>
      </div>
      {/* </div> */}
        </>
    </>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(ReportingLayout);
