import React from "react";
import "./App.css";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";

import MainLayout from "./layouts/MainLayout";
// import ConsoleLayout from "./layouts/ConsoleLayout";
// import Home from "./pages/Home";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Signup from "./pages/Signup";
import ForgotPassword from "./pages/ForgotPassword";
import EmployeeLayout from "./layouts/EmployeeLayout";
import AuthProvider from "./hooks/AuthProvider";
import EmployeeList from "./pages/employee/List";
import SavingLayout from "./layouts/SavingLayout";
import Placeholder from "./components/Placeholder";
import SettingsLayout from "./layouts/SettingLayout";
import CreateEmployee from "./pages/employee/Create";
import UsersList from "./pages/settings/users/UsersList";
import CreateUser from "./pages/settings/users/Create";
import RoleList from "./pages/settings/roles/List";
import CreateRole from "./pages/settings/roles/Create";
import Permission from "./pages/settings/roles/permissions/List";
import BranchList from "./pages/settings/branches/List";
import CreateBranch from "./pages/settings/branches/Create";
import PositionList from "./pages/settings/positions/List";
import CreatePosition from "./pages/settings/positions/Create";
import DepartmentList from "./pages/settings/departments/List";
import CreateDepartment from "./pages/settings/departments/Create";
import LevelsList from "./pages/settings/levels/List";
import CreateLevel from "./pages/settings/levels/Create";
import EchelonList from "./pages/settings/echelon/List";
import CreateEchelon from "./pages/settings/echelon/Create";
import BanksList from "./pages/settings/banks/List";
import CreateBank from "./pages/settings/banks/Create";
import EmployeeDetails from "./pages/employee/Details";
import TransferList from "./pages/employee/transfer/List";
import CreateTransfer from "./pages/employee/transfer/Create";
// import LeaveList from "./pages/employee/leave/List";
// import CreateLeave from "./pages/employee/leave/Create";
import CreateAttendance from "./pages/attendance/Create";
import MissionList from "./pages/employee/mission/List";
import CreateMission from "./pages/employee/mission/Create";
import TransferDetails from "./pages/employee/transfer/Details";
import ApprovalLayout from "./layouts/ApprovalLayout";
import IncomingTransferList from "./pages/employee/transfer/incoming/List";
import LogsList from "./pages/logs/List";
// import LeaveTypeList from "./pages/settings/leave/List";
// import CreateLeaveType from "./pages/settings/leave/Create";
import RequestSocialFund from "./pages/saving/funds/Create";
// import LeaveApprovalList from "./pages/approval/leave/LeaveApprovalList";
// import ApprovalLeaveDetails from "./pages/approval/leave/ApprovalLeaveDetails";
import CreateForOther from "./pages/employee/transfer/incoming/CreateForOther";
import Details from "./pages/employee/mission/Details";
import MissionListIncoming from "./pages/employee/mission/incomingList";
import ServiceRequestList from "./pages/serviceRequest/List";
import IncomingServiceRequestList from "./pages/serviceRequest/ListIncoming";
import Create from "./pages/serviceRequest/Create";
import ServiceRequestDetails from "./pages/serviceRequest/Details";
import HolidaysList from "./pages/settings/holidays/List";
import CreateHoliday from "./pages/settings/holidays/Create";
import MissionSettingList from "./pages/settings/mission/List";
import CreateAllowance from "./pages/settings/mission/Create";
import SettingsList from "./pages/settings/settings/List";
import CreateSettings from "./pages/settings/settings/Create";
// import PromotionYearList from "./pages/promotion/promotionYear/List";
// import PromotionMarksList from "./pages/promotion/promotionMarks/List";
// import PromotionStatusList from "./pages/promotion/promotionStatus/List";
// import CreatePromotionMarks from "./pages/promotion/promotionMarks/Create";
// import UpdatePromotionMarks from "./pages/promotion/promotionMarks/Update";
// import CreatePromotionYear from "./pages/promotion/promotionYear/Create";
// import ScalesList from "./pages/settings/scales/List";
// import CreateScale from "./pages/settings/scales/Create";
import CreateFiscalYear from "./pages/saving/fiscal/Create";
import FiscalYearList from "./pages/saving/fiscal/List";
import ContributionList from "./pages/saving/contributions/List";
import CreateContribution from "./pages/saving/contributions/Create";
import LoanList from "./pages/saving/loans/List";
import CreateLoan from "./pages/saving/loans/Create";
import SavingApprovalLayout from "./layouts/SavingApprovalLayout";
import ApprovalContributionList from "./pages/approveSaving/contributions/List";
import ApprovalLoanList from "./pages/approveSaving/loans/List";
import ContributionDetails from "./pages/approveSaving/contributions/Details";
import LoanDetails from "./pages/approveSaving/loans/Details";
import CreateGeneralContribution from "./pages/approveSaving/contributions/Create";
import SocialFundList from "./pages/saving/funds/List";
import FundRequestDetails from "./pages/saving/funds/Details";
import SocialFundListIncoming from "./pages/saving/funds/IncomingList";

import CaiseApplicationList from "./pages/approveSaving/applications/List";
import ApplicationDetails from "./pages/approveSaving/applications/Details";
import MemberSettings from "./pages/saving/settings/Details";
import RequestShareIncrement from "./pages/saving/increment/RequestShareIncrement";
import ShareIncrementDetails from "./pages/approveSaving/increments/Details";
import ShareIncrementList from "./pages/approveSaving/increments/List";
// import PenaltiesList from "./pages/saving/penalties/List";
import RequestSocialExit from "./pages/saving/exit/RequestSocialExit";
import ExitRequestList from "./pages/approveSaving/exit/List";
import ExitRequestDetails from "./pages/approveSaving/exit/Details";
import CdeSummaryDashboard from "./pages/approveSaving/dashboard/Dashboard";
// import CreatePaymentRequest from "./pages/saving/penalties/CreatePaymentRequest";
// import PaymentRequestDetails from "./pages/approveSaving/penalties/PaymentRequestDetails";
// import ApprovalPenaltyPaymentList from "./pages/approveSaving/penalties/List";
// import ApprovalPenaltyList from "./pages/approveSaving/penalties/ApprovalPenaltiesList";
import ReportingLayout from "./layouts/ReportingLayout";
import ServiceRequestReportIndex from "./pages/reports/service_request";
// import ExpensesList from "./pages/approveSaving/expenses/List";
import CaisseDentraideReportIndex from "./pages/reports/caisse";
import UpdateMember from "./pages/approveSaving/applications/UpdateMember";
import AttendanceReportIndex from "./pages/reports/attendance";
import PromotionReportIndex from "./pages/reports/promotion";
import MyAttendanceList from "./pages/attendance/lists/EmployeeAttendanceList";
import AllAttendanceList from "./pages/attendance/lists/AllAttendanceList";
import EmployeeDashboard from "./pages/employee/EmployeeDashboard";
import AdminDashboard from "./pages/AdminDashboard";
import DashboardLayout from "./layouts/DashboardLayout";
import ExpensesIndex from "./pages/approveSaving/expenses";
import ShareCapital from "./pages/approveSaving/capital/ShareCapital";
import DividendHomepage from "./pages/approveSaving/dividend/DividendHomepage";
import UpdateGeneralSettings from "./pages/approveSaving/dashboard/UpdateGeneralSettings";
import RequestSocialFundOnBehalf from "./pages/approveSaving/funds/RequestSocialOnBehalf";
import InvestmentList from "./pages/approveSaving/investment/List";
import PromotionList from "./pages/promotion/List";
import MyShareIncrementList from "./pages/saving/increment/List";
import LayoutDraft from "./layouts/ConsoleLayoutDraft";
// import PayBulkPayment from "./pages/saving/contributions/BulkPayment";
import HierarchList from "./pages/settings/settings/ApproveHierarch";
import Help from "./pages/settings/settings/Help";
import AttendanceSetting from "./pages/attendance/lists/AttendanceSetting";

function App() {
  return (
    <div className='app-container'>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route path="" element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="logout" element={<Logout />} />
            <Route path="signup" element={<Signup />} />
            <Route path="reset-password/:id" element={<ForgotPassword />} />
            {/* <Route path="not-found" element={<NotFound />} /> */}
            {/* <Route path="*" element={<Navigate to="/not-found" />} /> */}
          </Route>
          <Route path="console" element={<LayoutDraft />}>
            {/* <Route path="" element={<DashboardLayout />} /> */}
            <Route path="dashboard" element={<DashboardLayout />}>
              <Route path="" element={<EmployeeDashboard />} />
              <Route path="admin" element={<AdminDashboard />} />
            </Route>
            {/* <Route path="profile" element={<Profile />} /> */}
            <Route path="employee" element={<EmployeeLayout />}>
              <Route path="all-employees">
                <Route path="" element={<EmployeeList />} />
                <Route path="setting/:id" element={<CreateEmployee />} />

                <Route path="details/:id" element={<EmployeeDetails />} />

                <Route
                  path="social/request/new"
                  element={<RequestSocialFundOnBehalf />}
                />
              </Route>
              <Route path="">
                <Route path="" element={<TransferList />} />
                <Route path="transfer/:id" element={<CreateTransfer />} />
                <Route
                  path="update/:id"
                  element={<Placeholder title={"Update Transfers"} />}
                />
                <Route path="details/:id" element={<TransferDetails />} />
                <Route path="profile/:id" element={<EmployeeDetails />} />
              </Route>

              {/* <Route path="leaves">
                <Route path="" element={<LeaveList />} />
                <Route path="create/:id" element={<CreateLeave />} />
                <Route path="details/:id" element={<ApprovalLeaveDetails />} />
              </Route> */}

              <Route path="missions">
                <Route path="" element={<MissionList />} />
                <Route path="mission/:id" element={<CreateMission />} />
                <Route
                  path="update/:id"
                  element={<Placeholder title={"Update Mission"} />}
                />
                <Route path="details/:id" element={<Details />} />
              </Route>

              <Route path="requests">
                <Route path="" element={<ServiceRequestList />} />
                <Route path="request/:id" element={<Create />} />
                <Route
                  path="update/:id"
                  element={<Placeholder title={"Update Request"} />}
                />
                <Route path="details/:id" element={<ServiceRequestDetails />} />
              </Route>

              <Route path="attendance">
                <Route path="" element={<MyAttendanceList />} />
                <Route path="create" element={<CreateAttendance />} />
                <Route
                  path="update/:id"
                  element={<Placeholder title={"Update Attendance"} />}
                />
                <Route
                  path="details/:id"
                  element={<Placeholder title={"Attendance Details"} />}
                />
              </Route>
            </Route>
            {/* approvals routes */}
            <Route path="approvals" element={<ApprovalLayout />}>
              <Route
                path=""
                element={<Navigate to="transfers" replace={true} />}
              />
              <Route path="">
                <Route path="transfers" element={<IncomingTransferList />} />
                <Route
                  path="transfers/transfer/:id"
                  element={<CreateForOther />}
                />

                <Route
                  path="transfers/details/:id"
                  element={<TransferDetails />}
                />
                <Route path="transfer/:id" element={<CreateTransfer />} />
              </Route>
              {/* ------------------------ promotion --------------------- */}
              <Route path="promotions">
                <Route path="" element={<PromotionList />} />
                {/* <Route path="year" element={<PromotionList />} /> */}
                {/* <Route path="year/create" element={<CreatePromotionYear />} />
                <Route
                  path="year/update/:id"
                  element={<CreatePromotionYear />}
                />
                <Route path="marks" element={<PromotionMarksList />} />
                <Route
                  path="marks/create/:id"
                  element={<CreatePromotionMarks />}
                />
                <Route
                  path="marks/update/:id"
                  element={<UpdatePromotionMarks />}
                />
                <Route path="promotion" element={<PromotionStatusList />} /> */}
              </Route>
              {/* <Route path="leaves">
                <Route path="" element={<LeaveApprovalList />} />
                <Route path="details/:id" element={<ApprovalLeaveDetails />} />
                <Route path="create/:id" element={<CreateLeave />} />

              </Route> */}

              <Route path="missions">
                <Route path="" element={<MissionListIncoming />} />
                <Route path="details/:id" element={<Details />} />
                <Route path="mission/:id" element={<CreateMission />} />
              </Route>

              <Route path="requests">
                <Route path="" element={<IncomingServiceRequestList />} />
                <Route
                  path="update/:id"
                  element={<Placeholder title={"Update Request"} />}
                />
                <Route path="details/:id" element={<ServiceRequestDetails />} />
              </Route>

              <Route path="attendance">
                <Route path="" element={<AllAttendanceList />} />
                <Route path="create" element={<CreateAttendance />} />
              </Route>
            </Route>
            {/* end approvals routes */}
            <Route path="saving" element={<SavingLayout />}>
              <Route path="">
                <Route path="" element={<MemberSettings />} />
                <Route path="update/:id" element={<UpdateMember />} />
              </Route>
              <Route path="contributions">
                <Route path="" element={<ContributionList />} />
                <Route path="details/:id" element={<ContributionDetails />} />
                <Route path="create" element={<CreateContribution />} />
                <Route path="update/:id" element={<CreateContribution />} />
              </Route>
              {/* <Route path="penalties">
                <Route path="" element={<PenaltiesList />} />
                <Route path="pay" element={<CreatePaymentRequest />} />
                <Route
                  path="payment/details/:id"
                  element={<PaymentRequestDetails />}
                />
              </Route> */}
              <Route path="loans">
                <Route path="" element={<LoanList />} />
                <Route path="create" element={<CreateLoan />} />
                <Route path="details/:id" element={<LoanDetails />} />
                <Route path="update/:id" element={<CreateLoan />} />
              </Route>
              <Route path="funds">
                <Route path="" element={<SocialFundList />} />
                <Route path="request/:id" element={<RequestSocialFund />} />
                <Route path="details/:id" element={<FundRequestDetails />} />
              </Route>

              <Route path="increment">
                <Route path="request" element={<RequestShareIncrement />} />
                <Route path="" element={<MyShareIncrementList />} />
                <Route path="details/:id" element={<ShareIncrementDetails />} />
                <Route path="update/:id" element={<RequestShareIncrement />} />
              </Route>
              <Route path="exit">
                <Route path="request" element={<RequestSocialExit />} />
                <Route path="details/:id" element={<ExitRequestDetails />} />
                <Route path="update/:id" element={<RequestSocialExit />} />
              </Route>
              <Route
                path=""
                element={<Navigate to="contributions" replace={true} />}
              />
            </Route>
            {/*Saving Approval*/}
            <Route path="approveSaving" element={<SavingApprovalLayout />}>
              <Route path="">
                <Route path="" element={<CdeSummaryDashboard />} />
                <Route path="create" element={<CreateGeneralContribution />} />
                <Route path="settings" element={<UpdateGeneralSettings />} />
              </Route>
              <Route path="contributions">
                <Route path="" element={<ApprovalContributionList />} />
                <Route path="details/:id" element={<ContributionDetails />} />
              </Route>
              <Route path="increments">
                <Route path="details/:id" element={<ShareIncrementDetails />} />
                <Route path="" element={<ShareIncrementList />} />
              </Route>
              <Route path="exit">
                <Route path="details/:id" element={<ExitRequestDetails />} />
                <Route path="" element={<ExitRequestList />} />
              </Route>
              <Route path="funds">
                <Route path="" element={<SocialFundListIncoming />} />
                <Route path="details/:id" element={<FundRequestDetails />} />
              </Route>
              <Route path="applications">
                <Route path="" element={<CaiseApplicationList />} />
                <Route path="details/:id" element={<ApplicationDetails />} />
                <Route path="member/:id" element={<MemberSettings />} />
                <Route path="member/update/:id" element={<UpdateMember />} />
              </Route>
              <Route path="loans">
                <Route path="" element={<ApprovalLoanList />} />
                <Route path="details/:id" element={<LoanDetails />} />
              </Route>
              {/* <Route path="penalties" element={<ApprovalPenaltyList />} /> */}
              {/* <Route path="penaltyPayments">
                <Route path="" element={<ApprovalPenaltyPaymentList />} />
                <Route path="details/:id" element={<PaymentRequestDetails />} />
              </Route> */}
              <Route path="expenses">
                <Route path="" element={<ExpensesIndex />} />
              </Route>
              <Route path="investment">
                <Route path="" element={<InvestmentList />} />
              </Route>
              <Route path="capitals">
                <Route path="" element={<ShareCapital />} />
              </Route>
              <Route path="dividend">
                <Route path="" element={<DividendHomepage />} />
              </Route>
            </Route>
            <Route path="settings" element={<SettingsLayout />}>
              <Route path="">
                <Route path="" element={<SettingsList />} />
                <Route path="create" element={<CreateSettings />} />
                <Route path="update/:id" element={<CreateSettings />} />
                <Route
                  path="details/:id"
                  element={<Placeholder title={"Attendance Details"} />}
                />
              </Route>
              <Route path="fiscal">
                <Route path="" element={<FiscalYearList />} />
                <Route path="create" element={<CreateFiscalYear />} />
                <Route path="update/:id" element={<CreateFiscalYear />} />
              </Route>
              <Route path="users">
                <Route path="" element={<UsersList />} />
                <Route path="create" element={<CreateUser />} />
                <Route path="update/:id" element={<CreateUser />} />
                <Route
                  path="details/:id"
                  element={<Placeholder title={"Attendance Details"} />}
                />
              </Route>
              <Route path="roles">
                <Route path="" element={<RoleList />} />
                <Route path="create" element={<CreateRole />} />
                <Route path="update/:id" element={<CreateRole />} />
                <Route path="permission/:id" element={<Permission />} />
                <Route
                  path="details/:id"
                  element={<Placeholder title={"Role Details"} />}
                />
              </Route>
              <Route path="branches">
                <Route path="" element={<BranchList />} />
                <Route path="create" element={<CreateBranch />} />
                <Route path="update/:id" element={<CreateBranch />} />
                <Route
                  path="details/:id"
                  element={<Placeholder title={"Branch Details"} />}
                />
              </Route>
              <Route path="positions">
                <Route path="" element={<PositionList />} />
                <Route path="create" element={<CreatePosition />} />
                <Route path="update/:id" element={<CreatePosition />} />
                <Route
                  path="details/:id"
                  element={<Placeholder title={"Position Details"} />}
                />
              </Route>
              <Route path="hierarch">
                <Route path="" element={<HierarchList />} />
                {/* <Route path="create" element={<CreatePosition />} />
                <Route path="update/:id" element={<CreatePosition />} /> */}
              </Route>
              <Route path="attendance">
                <Route path="" element={<AttendanceSetting />} />
              </Route>
              <Route path="departments">
                <Route path="" element={<DepartmentList />} />
                <Route path="create" element={<CreateDepartment />} />
                <Route path="update/:id" element={<CreateDepartment />} />
                <Route
                  path="details/:id"
                  element={<Placeholder title={"Departments Details"} />}
                />
              </Route>
              <Route path="levels">
                <Route path="" element={<LevelsList />} />
                <Route path="create" element={<CreateLevel />} />
                <Route path="update/:id" element={<CreateLevel />} />
                <Route
                  path="details/:id"
                  element={<Placeholder title={"Departments Details"} />}
                />
              </Route>
              {/*<Route path="scales">*/}
              {/*  <Route path="" element={<ScalesList />} />*/}
              {/*  <Route path="create" element={<CreateScale />} />*/}
              {/*  <Route path="update/:id" element={<CreateScale />} />*/}
              {/*  /!* <Route*/}
              {/*    path="details/:id"*/}
              {/*    element={<Placeholder title={"Departments Details"} />}*/}
              {/*  /> *!/*/}
              {/*</Route>*/}
              <Route path="holidays">
                <Route path="" element={<HolidaysList />} />
                <Route path="create" element={<CreateHoliday />} />
                <Route path="update/:id" element={<CreateHoliday />} />
                <Route
                  path="details/:id"
                  element={<Placeholder title={"Holiday Details"} />}
                />
              </Route>
              <Route path="echelons">
                <Route path="" element={<EchelonList />} />
                <Route path="create" element={<CreateEchelon />} />
                <Route path="update/:id" element={<CreateEchelon />} />
                <Route
                  path="details/:id"
                  element={<Placeholder title={"Departments Details"} />}
                />
              </Route>
              <Route path="banks">
                <Route path="" element={<BanksList />} />
                <Route path="create" element={<CreateBank />} />
                <Route path="update/:id" element={<CreateBank />} />
                <Route
                  path="details/:id"
                  element={<Placeholder title={"Departments Details"} />}
                />
              </Route>
              {/* <Route path="leaveTypes">
                <Route path="" element={<LeaveTypeList />} />
                <Route path="update/:id" element={<CreateLeaveType />} />
                <Route
                  path="details/:id"
                  element={<Placeholder title={"Departments Details"} />}
                />
              </Route> */}
              <Route path="mission">
                <Route path="" element={<MissionSettingList />} />
                <Route path="create/:id" element={<CreateAllowance />} />
              </Route>
              {/* <Route
                path="*"
                element={<Navigate to="users" replace={true} />}
              /> */}
              <Route path="" element={<Navigate to="users" replace={true} />} />
            </Route>
            <Route path="logs">
              <Route path="" element={<LogsList />} />
            </Route>
            <Route path="user-guide">
              <Route path="" element={<Help />} />
            </Route>
            <Route
              path="caisse-report"
              element={<CaisseDentraideReportIndex />}
            />
            <Route path="report" element={<ReportingLayout />}>
              <Route path="service" element={<ServiceRequestReportIndex />} />
              <Route path="attendance" element={<AttendanceReportIndex />} />
              <Route path="promotion" element={<PromotionReportIndex />} />
              <Route path="" element={<Navigate to={"service"} />} />
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
    </div>
  );
}

export default App;
