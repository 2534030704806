import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { DatatableComponent } from "../../../components/DatatableComponent";
import { BorderColorOutlined } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import ConfirmDialog from "../../../components/ConfirmDialog";
import { useState } from "react";
import axios from "../../../store/helpers/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "@mui/material";

const HolidaysList = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(0);
    const [confirmId, setConfirmId] = useState("");
    const [change, setChange] = useState(0);

    const onConfirm = (id: string) => {
        setOpen(1);
        setConfirmId(id);
    }
    // handle confirm
    const handleConfirm = async () => {
        try {
            const url = `/api/holiday/confirm/${confirmId}`;
            const headers = {
                "Content-Type": "application/json",
            };
            const method = "put";
            const { data } = await axios({ method, headers, url });
            toast.success(data?.message);
            setOpen(0);
            setChange(change + 1)
            navigate('../holidays');
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    }
    return <div>
        <ToastContainer />
        <ConfirmDialog open={open}
            setOpen={setOpen}
            url={""}
            fullWidth
            handleAction={handleConfirm} />
        <Breadcrumb addButton={{ label: "Add Holiday", onClick: () => navigate('create') }} />
        <DatatableComponent
            columns={[
                {
                    label: "Name",
                    name: "name",
                },
                {
                    label: "Date",
                    name: "date",
                    options: {
                        customBodyRender: (v: any) => moment(v).format("YYYY-MMM-DD HH:mm")
                    }
                },
                {
                    label: "Confirmed",
                    name: "confirmed"
                },
            ]}
            url={`holiday/datatable?change=${change}`}
            actions={(id) => [
                // <IconButton onClick={() => onConfirm(id)}><CheckCircleOutlineSharpIcon className="text-success" /></IconButton>,
                <Tooltip title="Confirm holiday" className={"border-0 bg-transparent"}>
                    <CheckCircleOutlineSharpIcon className="text-success" onClick={() => onConfirm(id)} fontSize="small" />
                </Tooltip>,
                <Tooltip title="update">
                    <BorderColorOutlined onClick={() => navigate(`update/${id}`)} fontSize="small" />
                </Tooltip>
            ]}
            title={'Holidays List'}
            // deleteUrl={(id) => `holiday/delete/${id}`}
        />
    </div>
}

export default HolidaysList;
