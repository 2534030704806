/* eslint-disable react-hooks/exhaustive-deps */
import {useLocation } from "react-router-dom";
import Breadcrumb from "../../../../components/Breadcrumb";
import { DatatableComponent } from "../../../../components/DatatableComponent";
import { useEffect, useState } from "react";
import axios from "../../../../store/helpers/axiosConfig";
import { toast } from "react-toastify";
import Create from "./Create";
import { fetchDetails } from "../../../../services/common";

const Permissions = () => {
  const [list, setList] = useState<any>([]);
  const [change, setChange] = useState(0);
  const [open, setOpen] = useState(0);
  const [permission, setPermission] = useState({});
  const location = useLocation();
  const [role, setRole] = useState<any>();

  const roleId = location?.state?.roleId;

  const handleChange = async (permission: any) => {
    await handleSubmit(permission);
    reload();
  };

  // handle submit
  const handleSubmit = async (permission: any) => {
    const { id, ...info } = permission;
    try {
      const url = `/api/permission/update/${id}`;
      const headers = {
        "Content-Type": "application/json",
      };
      const method = "put";
      const { data } = await axios({ method, headers, url, data: info });
      toast.success(data?.message);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };
  const reload = () => {
    setChange((v) => v + 1);
  };
  const permissions = [
    {
      id: 1,
      name: "approval-service-request",
      create: true,
      update: true,
      read: true,
      delete: false,
      roleId,
    },
    {
      id: 2,
      name: "approval-promotion",
      create: true,
      update: true,
      read: true,
      delete: false,
      roleId,
    },
    {
      id: 3,
      name: "approval-leave",
      create: true,
      update: true,
      read: true,
      delete: false,
      roleId,
    },
    {
      id: 4,
      name: "approval-mission",
      create: true,
      update: true,
      read: true,
      delete: false,
      roleId,
    },
    {
      id: 5,
      name: "approval-mutation",
      create: true,
      update: true,
      read: true,
      delete: false,
      roleId,
    },
    {
      id: 6,
      name: "service-request",
      create: true,
      update: true,
      read: true,
      delete: false,
      roleId,
    },
    {
      id: 7,
      name: "promotion",
      create: true,
      update: true,
      read: true,
      delete: false,
      roleId,
    },
    {
      id: 8,
      name: "leave",
      create: true,
      update: true,
      read: true,
      delete: false,
      roleId,
    },
    {
      id: 9,
      name: "mission",
      create: true,
      update: true,
      read: true,
      delete: false,
      roleId,
    },
    {
      id: 10,
      name: "mutation",
      create: true,
      update: true,
      read: true,
      delete: false,
      roleId,
    },
    {
      id: 11,
      name: "saving",
      create: true,
      update: true,
      read: true,
      delete: false,
      roleId,
    },
    {
      id: 13,
      name: "approval-saving",
      create: true,
      update: true,
      read: true,
      delete: false,
      roleId,
    },
    {
      id: 12,
      name: "settings",
      create: true,
      update: true,
      read: true,
      delete: false,
      roleId,
    },
    // { id: 14, name: "settings-branch", create: true, update: true, read: true, delete: false, roleId },
    // { id: 15, name: "settings-department", create: true, update: true, read: true, delete: false, roleId },
    // { id: 16, name: "settings-echelon", create: true, update: true, read: true, delete: false, roleId },
    // { id: 17, name: "settings-holiday", create: true, update: true, read: true, delete: false, roleId },
    // { id: 18, name: "settings-level", create: true, update: true, read: true, delete: false, roleId },
    // { id: 19, name: "settings-position", create: true, update: true, read: true, delete: false, roleId },
    // { id: 20, name: "settings-role", create: true, update: true, read: true, delete: false, roleId },
    {
      id: 21,
      name: "settings-user",
      create: true,
      update: true,
      read: true,
      delete: false,
      roleId,
    },
    {
      id: 22,
      name: "employee",
      create: true,
      update: true,
      read: true,
      delete: false,
      roleId,
    },
    { id: 103, name: "settings-leave", create: true, update: true, read: true, delete: false, roleId },
    // { id: 24, name: "settings-fiscal-year", create: true, update: true, read: true, delete: false, roleId },
    // { id: 25, name: "settings-scale", create: true, update: true, read: true, delete: false, roleId },
    // { id: 26, name: "settings-mission", create: true, update: true, read: true, delete: false, roleId },
    {
      id: 27,
      name: "approval-request",
      create: true,
      update: true,
      read: true,
      delete: false,
      roleId,
    },
    {
      id: 28,
      name: "dashboard",
      create: true,
      update: true,
      read: true,
      delete: false,
      roleId,
    },
    {
      id: 29,
      name: "reports",
      create: true,
      update: true,
      read: true,
      delete: false,
      roleId,
    },
    {
      id: 30,
      name: "attendance-report",
      create: true,
      update: true,
      read: true,
      delete: false,
      roleId,
    },
    {
      id: 31,
      name: "view-attendance",
      create: true,
      update: true,
      read: true,
      delete: false,
      roleId,
    },
  ];
  const fetchInfoDetails = async () => {
    const resp = await fetchDetails(`role/view/${roleId}`);
    if (resp.status) {
      setRole(resp.data);
    }
  };
useEffect(()=>{fetchInfoDetails().then()},[roleId])
  //handle submit
  const handleSubmitCreate = async (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    try {
      const url = `/api/permission/create`;
      const headers = {
        "Content-Type": "application/json",
      };
      const method = "post";

      const { data } = await axios({
        method,
        headers,
        url,
        data: [permission],
      });
      toast.success(data?.message);
      reload();
      setOpen(0);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div>
      <Breadcrumb
        addButton={{ label: "Add Permission", onClick: () => setOpen(1) }}
      />
      <Create
        open={open}
        setOpen={setOpen}
        permissions={permissions}
        onSuccess={reload}
        // permission={permission}
        // setPermission={setPermission}
        // handleSubmit={handleSubmitCreate}
      />
      <DatatableComponent
        columns={[
          {
            label: "Name",
            name: "name",
          },
          {
            label: "Create",
            name: "create",
            options: {
              customBodyRender: (create: any, index: any) => {
                return (
                  <div
                    className="text-bold"
                    onClick={() =>
                      handleChange({
                        create: !create,
                        id: list[index?.rowIndex]?.id,
                      })
                    }
                  >
                    <input checked={create} type="checkbox" name="" id="" />
                  </div>
                );
              },
            },
          },
          {
            label: "Update",
            name: "update",
            options: {
              customBodyRender: (update: any, index: any) => {
                return (
                  <div
                    className="text-bold"
                    onClick={() =>
                      handleChange({
                        update: !update,
                        id: list[index?.rowIndex]?.id,
                      })
                    }
                  >
                    <input checked={update} type="checkbox" name="" id="" />
                  </div>
                );
              },
            },
          },
          {
            label: "Read",
            name: "read",
            options: {
              customBodyRender: (read: any, index: any) => {
                return (
                  <div
                    className="text-bold"
                    onClick={() =>
                      handleChange({
                        read: !read,
                        id: list[index?.rowIndex]?.id,
                      })
                    }
                  >
                    <input checked={read} type="checkbox" name="" id="" />
                  </div>
                );
              },
            },
          },
          {
            label: "Delete",
            name: "delete",
            options: {
              customBodyRender: (delet: any, index: any) => {
                return (
                  <div
                    className="text-bold"
                    onClick={() =>
                      handleChange({
                        delete: !delet,
                        id: list[index?.rowIndex]?.id,
                      })
                    }
                  >
                    <input checked={delet} type="checkbox" name="" id="" />
                  </div>
                );
              },
            },
          },
        ]}
        url={`permission/datatable??change=${change}&roleId=${roleId}`}
        processData={(data: any) => {
          setList(data?.data);
          return data;
        }}
        title={`${role?.label}: Permissions List`}
        actions={(id) => [
          // <IconButton onClick={() => navigate(`update/${id}`)}>
          //   <Update />
          // </IconButton>,
        ]}
        deleteUrl={(id) => `permission/delete/${id}`}
      />
    </div>
  );
};

export default Permissions;
