import Chart from "react-apexcharts";
import GroupsIcon from "@mui/icons-material/Groups";
import { Card, Col, Row } from "react-bootstrap";
import { DashboardCard } from "../components/DashboardCard";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchDetails, monthList } from "../services/common";
import Breadcrumb from "../components/Breadcrumb";
import { default as ReactSelect } from "react-select";
import { Add, FiberManualRecord, Terrain } from "@mui/icons-material";
import PageLoading from "../components/PageLoading";
import { mapStateToProps } from "../store/helpers/mapState";
import { connect } from "react-redux";
import CalendarComponent from "../components/CalendarComponent";

function AdminDashboard(props: any) {
  const { loggedUser } = props;
  const navigate = useNavigate();
  const [error, setError] = useState<any>();
  const [info, setInfo] = useState<any>();
  const [fiscalYearList, setFiscalYearList] = useState<Array<any>>([]);
  const [fiscalYear, setFiscalYear] = useState<any>();
  const [dateAttend, setDateAttends] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [statusAttend, setStatusAttends] = useState([0, 0, 0, 0, 0]);

  useEffect(() => {
    if (info?.attendanceByDate) {
      const getAllDate = (attend: any) => {
        let dateAttendCopy: any = [...dateAttend];
        for (let i = 0; i < attend?.length; i++) {
          if (attend[i]?.createMonth === 1) {
            dateAttendCopy[0] = attend[i]?.count;
          }
          if (attend[i].createMonth === 2) {
            dateAttendCopy[1] = attend[i]?.count;
          }
          if (attend[i].createMonth === 3) {
            dateAttendCopy[2] = attend[i]?.count;
          }
          if (attend[i].createMonth === 4) {
            dateAttendCopy[3] = attend[i]?.count;
          }
          if (attend[i].createMonth === 5) {
            dateAttendCopy[4] = attend[i]?.count;
          }
          if (attend[i].createMonth === 6) {
            dateAttendCopy[5] = attend[i]?.count;
          }
          if (attend[i].createMonth === 7) {
            dateAttendCopy[6] = attend[i]?.count;
          }
          if (attend[i].createMonth === 8) {
            dateAttendCopy[7] = attend[i]?.count;
          }
          if (attend[i].createMonth === 9) {
            dateAttendCopy[8] = attend[i]?.count;
          }
          if (attend[i].createMonth === 10) {
            dateAttendCopy[9] = attend[i]?.count;
          }
          if (attend[i].createMonth === 11) {
            dateAttendCopy[10] = attend[i]?.count;
          }
          if (attend[i].createMonth === 12) {
            dateAttendCopy[11] = attend[i]?.count;
          }
        }
        setDateAttends(dateAttendCopy);
      };
      getAllDate(info?.attendanceByDate);
    }
    if (info?.statusAttendance) {
      const getAllStatus = (attend: any) => {
        let statusAttendCopy: any = [...statusAttend];
        for (let i = 0; i < attend?.length; i++) {
          if (attend[i]?.status === "h") {
            statusAttendCopy[0] = attend[i]?.count;
          }
          if (attend[i].status === "p") {
            statusAttendCopy[1] = attend[i]?.count;
          }
          if (attend[i].status === "l") {
            statusAttendCopy[2] = attend[i]?.count;
          }
          if (attend[i].status === "a") {
            statusAttendCopy[3] = attend[i]?.count;
          }
          if (attend[i].status === "v") {
            statusAttendCopy[4] = attend[i]?.count;
          }
        }
        setStatusAttends(statusAttendCopy);
      };
      getAllStatus(info?.statusAttendance);
    }
  }, [info]);

  const getDashboardData = async () => {
    const resp = await fetchDetails(`dashboard/view?fiscalYear=${fiscalYear}`);
    if (resp.status) {
      setInfo(resp.data);
    }
  };
  const getFiscalYear = async () => {
    const fiscalYear = await fetchDetails("/fiscal/view");

    if (fiscalYear.status) {
      const currentFiscalYear = fiscalYear?.data?.find(
        (year: any) => year.status === "open"
      );
      setFiscalYearList(fiscalYear?.data);
      setFiscalYear(currentFiscalYear?.id);
    }
  };

  useEffect(() => {
    //fetch service-request details
    if (fiscalYear) {
      setDateAttends([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
      setStatusAttends([0, 0, 0, 0, 0]);
      getDashboardData().then();
    }
  }, [fiscalYear]);
  useEffect(() => {
    //fetch service-request details
    getFiscalYear().then();
  }, []);
  const newTransfer = info?.newTransfer;
  const newMission = info?.newMission;
  const newSelfRequest = info?.newSelfRequest;

  return (
    <div>
      {!loggedUser ? (
        <PageLoading error={error} />
      ) : (
        <>
          {" "}
          <Breadcrumb
            endElement={
              <ReactSelect
                className="text-nowrap"
                options={fiscalYearList?.map(({ label, id, name }: any) => ({
                  value: id,
                  label: label ?? name,
                }))}
                onChange={(v: any) => {
                  setFiscalYear(v?.value);
                }}
              />
            }
          />
          <Row>
            <Col className={"mb-4"} md={3}>
              <DashboardCard
                color="#054D6F"
                height="100%"
                content={{
                  title: "Total Employees",
                  number: info?.inService,
                }}
                icon={<GroupsIcon fontSize="large" />}
                footerText={`${info?.exited} employees exited!`}
                footerIcon={
                  <Add
                    fontSize="small"
                    className="rounded-circle me-1"
                    style={{ backgroundColor: "#97CE71" }}
                  />
                }
                onClick={() => {
                  navigate("/console/employee/all-employees", {
                    state: { fromEmployee: true },
                  });
                }}
              />
            </Col>
            <Col className={"mb-4"} md={3}>
              <DashboardCard
                color="#054D6F"
                height="100%"
                content={{
                  title: "Mutation",
                  number: info?.transfer,
                }}
                icon={<GroupsIcon fontSize="large" />}
                footerText={`${newTransfer ?? 0} pending request!`}
                footerIcon={
                  <Terrain
                    fontSize="small"
                    className="rounded-circle me-1"
                    style={{ backgroundColor: "#E6B7CD" }}
                  />
                }
                onClick={() => {
                  navigate("/console/approvals/transfers");
                }}
              />
            </Col>
            <Col className={"mb-4"} md={3}>
              <DashboardCard
                color="#054D6F"
                height="100%"
                content={{
                  title: "Mission",
                  number: info?.mission,
                }}
                icon={<EditNoteIcon fontSize="large" />}
                footerText={`${newMission ?? 0} pending request!`}
                footerIcon={
                  <Terrain
                    fontSize="small"
                    className="rounded-circle me-1"
                    style={{ backgroundColor: "#C2D7F4" }}
                  />
                }
                onClick={() => {
                  navigate("/console/approvals/missions");
                }}
              />
            </Col>
            <Col className={"mb-4"} md={3}>
              <DashboardCard
                color="#054D6F"
                height="100%"
                content={{
                  title: "Self Service",
                  number: info?.request,
                }}
                icon={<GroupsIcon fontSize="large" />}
                footerText={`${newSelfRequest ?? 0} pending request!`}
                footerIcon={
                  <Terrain
                    fontSize="small"
                    className="rounded-circle me-1"
                    style={{ backgroundColor: "#97CE71" }}
                  />
                }
                onClick={() => {
                  navigate("/console/approvals/requests");
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col className={"mb-4"} md={6}>
              <Card className={"border-0 shadow-md"}>
                <div className={"card-body"}>
                  <div className="d-flex">
                    <h6>Monthly Attendance </h6>(
                    <span>
                      <FiberManualRecord
                        color="success"
                        sx={{ fontSize: 10 }}
                      />{" "}
                      Present
                    </span>
                    )
                  </div>
                  {/* <span>Graphical representation of most attended employees</span> */}
                  <Chart
                    options={{
                      colors: ["#054D6F"],
                      chart: {
                        id: "basic-bar",
                        // toolbar: {
                        //   show: false,
                        // },
                      },
                      xaxis: {
                        categories: [
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "May",
                          "June",
                          "Jul",
                          "Aug",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dec",
                        ],
                      },
                    }}
                    series={[
                      {
                        name: "series-1",
                        data: dateAttend,
                      },
                    ]}
                    type="bar"
                    height={350}
                  />
                </div>
              </Card>
            </Col>
            <Col className={"mb-4"} md={6}>
              <Card className={"border-0 shadow-md"}>
                <div className={"card-body"}>
                  <h6> Attendance</h6>
                  <Chart
                    options={{
                      labels: ["Holiday", "Present", "Late", "Absent", "Leave"],
                      fill: {
                        colors: [
                          "#0d6efd",
                          "#198754",
                          "#FFA84A",
                          "#dc3545",
                          "#6f42c1",
                          //"#DE3163",
                          // "#DE3163",
                          // "#DE3163",
                          // "#DE3163",
                        ],
                      },
                      chart: {
                        toolbar: {
                          show: false,
                        },
                      },
                    }}
                    series={statusAttend}
                    type="pie"
                    height={360}
                  />
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className={"mb-4"} md={6}>
              <Card className={"border-0 shadow-md"}>
                <div className={"card-body"}>
                  <h6>Caisse d'entraide Report</h6>
                  {/* <span>Graphical representation of Contributions per months</span> */}
                  <Chart
                    options={{
                      chart: {
                        id: "basic-bar",
                        toolbar: {
                          show: false,
                        },
                      },
                      dataLabels: {
                        formatter: (value:any) => value?.toLocaleString(),
                      },
                      xaxis: {
                        categories:
                          info?.contributions?.map(
                            (v: any) =>
                              monthList.find((month) => month.id === v.month)
                                ?.name
                          ) ?? [],
                      },
                      yaxis: {
                        labels: {
                          formatter: (value:any) => value?.toLocaleString(),
                        },
                      },
                      tooltip: {
                        y: {
                          formatter: (value:any) => value?.toLocaleString(),
                        },
                      },
                    }}
                    series={[
                      {
                        name: "series-1",
                        data: info?.contributions?.map((v: any) => v.sum) ?? [],
                      },
                    ]}
                    type="area"
                    height={360}
                  />
                </div>
              </Card>
            </Col>
            <Col className={"mb-4"} md={6}>
              <Card className={"border-0 shadow-md"}>
                <div className={"card-body"}>
                  {/* <h6>Monthly Leave</h6> */}

                  {/* <Chart
                    options={{
                      labels:
                        info?.leaves?.map((v: any) => v.LeaveType?.name) ?? [],
                      colors: [
                        "#9C27B0",
                        "#198754",
                        "#FFA84A",
                        "#9B88ED",
                        "#054D6F",
                      ],
             
                    }}
                    series={info?.leaves?.map((v: any) => v.count) ?? []}
                    type="donut"
                    height={360}
                  /> */}
                  <CalendarComponent />
                </div>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

// export default AdminDashboard;
export default connect(mapStateToProps)(AdminDashboard);
