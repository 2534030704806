import {useNavigate, useParams} from "react-router-dom";
import {
    CheckHasAnyPermission,
    fetchDetails,
    getError,
    submitGeneralForm
} from "../../../services/common";
import  {useEffect, useState} from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import PageLoading from "../../../components/PageLoading";
import {Card, Col, Row} from "react-bootstrap";
import {
    Alert,
    Button,
    CardContent,
    CardHeader,
    CircularProgress,
    Typography
} from "@mui/material";
import StatusComponent from "../../../components/StatusComponent";
import {Check, BorderColorOutlined, HighlightOff, Info} from "@mui/icons-material";
import moment from "moment";
import RequestHistoryProcess from "../../../components/RequestHistory";
import {mapStateToProps} from "../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../store/helpers/mapDispatch";
import SmallDialog from "../../../components/SmallModel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Reviewers from "../../../components/Reviewer";
import { connect } from "react-redux";

const ShareIncrementDetails = (props:any)=>{
    const {loggedUser} = props;
    const {id} = useParams();
    const navigate = useNavigate();
    const [info,setInfo] = useState<any>();
    const [error,setError] = useState<any>();
    const [confirm, setConfirm] = useState<boolean>(false);
    const [loading,setLoading] = useState<boolean>(false);
    const [openDialogue, setOpenDialogue] = useState<boolean>(false);
    const [newData, setData] = useState<any>();
    // const [viewDocument, setViewDocument] = useState(false);
    const canApprove = loggedUser?.id === info?.approverId;
const shareRate=info?.shareRate-info?.currentShareRate
    const loadInfo = async ()=>{
        const resp = await fetchDetails(`increment/view/${id}`);
        if(resp.status){
            setInfo(resp.data);
        }else{
            setError(getError(resp));
        }
    }

    const onSubmit = async (data?: any) => {
        setLoading(true);
        setConfirm(false);
        let infoToSave = data ?? newData;
        const resp = await submitGeneralForm(
            `/approvalIncrements/change-status/${id}`,
            infoToSave,
            null,
            "PUT"
        );
        if (resp.status) {
            setData({});
            setError(null);
            setOpenDialogue(false);
            loadInfo().then();
        } else {
            setError(getError(resp));
        }
        setLoading(false);
    };

    useEffect(()=>{
        loadInfo().then();
    },[id]);

    useEffect(() => {
        if (canApprove && info?.status === "pending") {
          onSubmit({
            id: info?.id,
            status: "under review",
            comment: "request is under review",
          });
        }
      }, [info?.id, info?.status]);

    const isInitiator = loggedUser?.employeeId === info?.CaisseMember?.employeeId;
    const canUpdate =
        isInitiator &&
        info?.status === "request action";



    return <div>
        <Breadcrumb/>
        {!info ? <PageLoading error={error}/> :  <Row className={" p-3 "}>
        <Col className={"mb-4"} md={12}>
                <Card className={"bg-light shadow-sm"}>
                    <CardHeader
                        subheader={<span className="fw-bold">Share Increments Details</span>}
                        action={<StatusComponent status={info?.status} badge={true}/>}
                    />
                <CardContent>
                    <div className={'row justify-content-end'}>
                        {info?.status !== "approved" && info?.status !== "rejected" ? (
                            <div className="col-md-8 fw-bold d-flex justify-content-end">
                                {canUpdate ? (
                                    <Button
                                        className="btn-primary me-2"
                                        size="small"
                                        variant="outlined"
                                        startIcon={<BorderColorOutlined />}
                                        onClick={() => {
                                            navigate(`/console/saving/increment/update/${info?.id}`);
                                        }}
                                    >
                                        Update
                                    </Button>
                                ) : null}
                                {canApprove &&
                                (info?.status === "under review" ||
                                    info?.status === "forwarded" || info?.status === 'pending') ? (
                                    <>
                                        <Button
                                            className="me-1"
                                            color="warning"
                                            size="small"
                                            variant="outlined"
                                            startIcon={<Info />}
                                            onClick={() => {
                                                setOpenDialogue(true);
                                                setData({
                                                    id: info?.id,
                                                    status: "request action",
                                                });
                                            }}
                                        >
                                            Request for action
                                        </Button>
                                        <Button
                                            className="me-2"
                                            color="error"
                                            size="small"
                                            variant="outlined"
                                            startIcon={<HighlightOff />}
                                            onClick={() => {
                                                setOpenDialogue(true);
                                                setData({ id: info?.id, status: "rejected" });
                                            }}
                                        >
                                            Reject
                                        </Button>
                                        {/* {!isReviewer && ( */}
                                        <Button
                                            className="me-2"
                                            color="success"
                                            size="small"
                                            disabled={!canApprove}
                                            variant="outlined"
                                            startIcon={<Check />}
                                            onClick={() => {
                                                setOpenDialogue(true);
                                                setData({ ...info, status: "approved" });
                                            }}
                                        >
                                            Approve
                                        </Button>
                                        {/* )} */}
                                    </>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                    <h6>Employee Details</h6>
                    <Row className={"mb-3"}>
                        <Col md={3}>
                            <div>Names</div>
                            <div className={"fw-bold"}>
                                {info?.CaisseMember?.employee?.names ?? "---"}
                            </div>
                        </Col>
                        <Col md={3}>
                            <div>Phone Number</div>
                            <div className={"fw-bold"}>
                                {info?.CaisseMember?.employee?.phone ?? "---"}
                            </div>
                        </Col>
                        <Col md={3}>
                            <div>Current Shares</div>
                            <div className={"fw-bold"}>
                                {info?.CaisseMember?.share?.toLocaleString() ?? "---"} RWF
                            </div>
                        </Col>
                        <Col md={3}>
                            <div>Share Rate</div>
                            <div className={"fw-bold"}>
                                {info?.CaisseMember?.shareRate?.toLocaleString() ?? "---"}%
                            </div>
                        </Col>
                    </Row>
                    <Row className={"mb-3"}>
                        <Col md={3}>
                            <div>Employee Number</div>
                            <div className={"fw-bold"}>
                                {info?.CaisseMember?.employee?.employeeNumber ?? "---"}
                            </div>
                        </Col>
                        <Col md={3}>
                            <div>Email</div>
                            <div className={"fw-bold"}>
                                {info?.CaisseMember?.employee?.email ?? "---"}
                            </div>
                        </Col>
                        <Col md={3}>
                            <div>Category</div>
                            <div className={"fw-bold"}>
                                {info?.CaisseMember?.employee?.category ?? "---"}
                            </div>
                        </Col>
                    </Row>
                    <h6>Current monthly contribution</h6>
                    <Row className={"mb-3"}>
                    <Col md={3}>
                            <div>{'Rate(%)'}</div>
                            <div className={"fw-bold"}>
                                {info?.currentShareRate ? `${info?.currentShareRate.toLocaleString()}%` : "---"}
                            </div>
                        </Col>
                        <Col md={3}>
                            <div>{'Contribution amount'}</div>
                            <div className={"fw-bold"}>
                                {info?.currentShare ? `${info?.currentShare.toLocaleString()} RWF` : "---"}
                            </div>
                        </Col>
                    </Row>
                    <h6>New monthly contribution</h6>

                    <Row className={"mb-3"}>
                        <Col md={3}>
                            <div>{'Rate(%)'}</div>
                            <div className={"fw-bold"}>
                                {info?.shareRate ? `${shareRate.toLocaleString()}%` : "---"}
                            </div>
                        </Col>
                        <Col md={3}>
                            <div>{'Increment amount'}</div>
                            <div className={"fw-bold"}>
                                {info?.incrementAmount ? `${info?.incrementAmount.toLocaleString()} RWF` : "---"}
                            </div>
                        </Col>
                        <Col md={3}>
                            <div>New monthly contribution</div>
                            <div className={"fw-bold"}>
                                {info?.share?.toLocaleString() ?? "---"}
                            </div>
                        </Col>
                        <Col md={3}>
                            <div>Due Date</div>
                            <div className={"fw-bold"}>
                                {info?.startDate ? `${moment(info?.startDate).format('DD/MM/YYYY')} - ${moment(info?.endDate).format('DD/MM/YYYY')}` :null}
                            </div>
                        </Col>
                    </Row>

                    <Reviewers
                        loggedUser={loggedUser}
                        RequestApprovers={info?.RequestApprovers}
                    />
                    <hr />
                    <div className="row">
                        <Typography style={{fontFamily:'Inter'}} className="col-md-12 mb-3 fw-bold">
                            Request History
                        </Typography>
                        <div className="col-md-12">
                            <RequestHistoryProcess
                                RequestProcesses={info?.RequestProcesses}
                            />
                        </div>
                    </div>


                    {openDialogue ? (
                        <SmallDialog
                            maxWidth="md"
                            title={newData?.status === 'request action' ? 'Request Action' : newData?.status === "approved" ? "Approve Share Increment" : "Reject Share Increment"}
                            buttonTitle="Submit"
                            needButton={true}
                            open={openDialogue}
                            setOpen={setOpenDialogue}
                        >
                            {error ? (
                                <Alert
                                    style={{ width: "100%" }}
                                    className={"mb-3"}
                                    severity={"error"}
                                    onClose={() => setError(null)}
                                >
                                    {error}
                                </Alert>
                            ) : null}
                            <form
                                onSubmit={(e: any) => {
                                    e.preventDefault();
                                    setConfirm(true);
                                }}
                            >
                                    <div>

                                        <label className="col-form-label text-capitalize">
                                            Comment
                                        </label>
                                        <textarea
                                            placeholder="Type here..."
                                            className="form-control form-control mb-3"
                                            required={true}
                                            value={newData?.comment}
                                            rows={5}
                                            onChange={(e: any) => {
                                                setData({
                                                    ...newData,
                                                    comment: e.currentTarget.value,
                                                });
                                            }}
                                        />
                                    </div>
                                {/*{newData?.status === "approved" && (*/}
                                {/*    <div className={"mb-3"}>*/}
                                {/*        <Approver*/}
                                {/*            title={"Copy This Share Request To"}*/}
                                {/*            buttonName={"CC Member"}*/}
                                {/*            onSelectUser={(val: any) => {*/}
                                {/*                let reviewer;*/}
                                {/*                reviewer =*/}
                                {/*                    val?.map((e: any) => ({*/}
                                {/*                        approver: e?.user?.id,*/}
                                {/*                        category: "reviewer",*/}
                                {/*                        shareIncrementId: info?.id,*/}
                                {/*                    })) ??*/}
                                {/*                    info.reviewer ??*/}
                                {/*                    [];*/}
                                {/*                setData({*/}
                                {/*                    ...newData,*/}
                                {/*                    reviewer,*/}
                                {/*                });*/}
                                {/*            }}*/}
                                {/*            allBranch={true}*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*)}*/}
                                <div className={"float-end"}>
                                    <button
                                        type={"button"}
                                        onClick={() => {
                                            setOpenDialogue(false);
                                        }}
                                        className={"btn btn-secondary me-1"}
                                    >
                                        <i className={"bi bi-x-lg"}></i> Cancel
                                    </button>
                                    {loading ? (
                                        <CircularProgress />
                                    ) : (
                                        <button type={"submit"} className={"btn btn-primary"}>
                                            <i className={"bi bi-send-fill"}></i> Submit
                                        </button>
                                    )}
                                </div>
                            </form>
                        </SmallDialog>
                    ) : null}
                    {openDialogue ? (
                        <SmallDialog
                            open={confirm}
                            setOpen={setConfirm}
                            onSubmit={() => {
                                onSubmit(newData);
                            }}
                        >
                            <div className="d-flex ">
                                <CheckCircleIcon color="success" sx={{ fontSize: 50 }} />
                                <div className="mt-4">{"Are you sure you want to submit?"}</div>
                            </div>
                        </SmallDialog>
                    ) : null}
                </CardContent>
                </Card>
            </Col>
        </Row>}
    </div>;
}

export default connect(mapStateToProps,mapDispatchToProps)(ShareIncrementDetails);
