import {
  Alert,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { fetchDetails } from "../../services/common";
import { submitGeneralForm } from "../../services/common";
import { getError } from "../../services/common";
import { getSuccessMessage } from "../../services/common";
import { Delete } from "@mui/icons-material";
import { Col } from "react-bootstrap";
import { default as ReactSelect } from "react-select";
import { DatatableComponent } from "../../components/DatatableComponent";

function ExpandedRows(props: any) {
  const { list, onChange } = props;

  return (
    <>
      {list?.map((row: any, index: string) => {
        return (
          <TableRow
            key={row.id}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell scope="row" colSpan={2}></TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>
              <Checkbox
                checked={row.create}
                // onChange={() => {
                //   onChange({ ...row, create: !row.create });
                // }}
              />
            </TableCell>
            <TableCell>
              <Checkbox
                checked={row.read}
                // onChange={() => {
                //   onChange({ ...row, read: !row.read });
                // }}
              />
            </TableCell>
            <TableCell>
              <Checkbox
                checked={row.update}
                // onChange={() => {
                //   onChange({ ...row, update: !row.update });
                // }}
              />
            </TableCell>
            <TableCell>
              <Checkbox
                checked={row.delete}
                // onChange={() => {
                //   onChange({ ...row, delete: !row.delete });
                // }}
              />
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
}

const UserPermission = (props: any) => {
  let {
    id,
    addPermission,
    newPermission,
    closeAddPermission,
    setNewPermission,
    info,
    laodInitialData,
  } = props;
  // const [info, setInfo] = useState<any>([]);

  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      laodInitialData();
    }
  }, [id, success]);

  const onChange = async (row: any) => {
    const resp = await submitGeneralForm(
      `/permission/update/${row.id}`,
      row,
      null,
      "PUT"
    );
    if (resp.status) {
      setSuccess(getSuccessMessage(resp));
      await laodInitialData();
      setSuccess("");
    } else {
      setError(getError(resp));
    }
  };

  const onSubmit = async () => {
    const resp = await submitGeneralForm(
      `/permission/create`,
      newPermission,
      null,
      "POST"
    );
    if (resp.status) {
      closeAddPermission();
      laodInitialData();
    } else {
      setError(getError(resp));
    }
    setLoading(false);
  };
  return (
    <Col md={12}>
      <Card className={"bg-light shadow-sm"}>
        <CardContent>
          {addPermission ? (
            <form
              onSubmit={(e: any) => {
                e.preventDefault();
                setLoading(true);
                onSubmit();
              }}
            >
              <TableContainer>
                {success ? (
                  <Alert
                    style={{ width: "100%" }}
                    className={"mb-3"}
                    severity={"success"}
                  >
                    {success}
                  </Alert>
                ) : null}

                {error ? (
                  <Alert
                    style={{ width: "100%" }}
                    className={"mb-3"}
                    severity={"error"}
                    onClose={() => setError(null)}
                  >
                    {error}
                  </Alert>
                ) : null}
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="fw-bold">Module</TableCell>
                      <TableCell className="fw-bold">Create</TableCell>
                      <TableCell className="fw-bold">Read</TableCell>
                      <TableCell className="fw-bold">Update</TableCell>
                      <TableCell className="fw-bold">Delete</TableCell>
                      {newPermission?.length > 1 ? (
                        <TableCell className="fw-bold">Action</TableCell>
                      ) : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {newPermission?.map((row: any, index: any) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <ReactSelect
                            className={"text-nowrap"}
                            options={[
                              { value: "settings", label: "Settings" },
                              { value: "employee", label: "Employee" },
                              { value: "promotion", label: "Promotion" },
                              {
                                value: "saving",
                                label: "Savings",
                              },
                              { value: "dashboard", label: "Dashboard" },
                              { value: "logs", label: "Logs" },
                            ]}
                            onChange={(v: any) => {
                              row.name = v?.value;
                              setNewPermission([...newPermission]);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            checked={row.create}
                            onChange={(e: any) => {
                              row.create = !row.create;
                              setNewPermission([...newPermission]);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            checked={row.read}
                            onChange={(e: any) => {
                              row.read = !row.read;
                              setNewPermission([...newPermission]);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            checked={row.update}
                            onChange={(e: any) => {
                              row.update = !row.update;
                              setNewPermission([...newPermission]);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            checked={row.delete}
                            onChange={(e: any) => {
                              row.delete = !row.delete;
                              setNewPermission([...newPermission]);
                            }}
                          />
                        </TableCell>
                        {newPermission?.length > 1 ? (
                          <TableCell>
                            <IconButton
                              color={"error"}
                              onClick={() => {
                                newPermission.splice(index, 1);
                                setNewPermission([...newPermission]);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {newPermission.length ? (
                  <div className={"float-end"}>
                    <button
                      type={"button"}
                      onClick={() => {
                        closeAddPermission();
                      }}
                      className={"btn btn-secondary me-1"}
                    >
                      <i className={"bi bi-x-lg"}></i> Cancel
                    </button>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <button type={"submit"} className={"btn btn-primary"}>
                        <i className={"bi bi-send-fill"}></i> Submit
                      </button>
                    )}
                  </div>
                ) : null}
              </TableContainer>
            </form>
          ) : (
            <TableContainer>
              {success ? (
                <Alert
                  style={{ width: "100%" }}
                  className={"mb-3"}
                  severity={"success"}
                >
                  {success}
                </Alert>
              ) : null}

              {error ? (
                <Alert
                  style={{ width: "100%" }}
                  className={"mb-3"}
                  severity={"error"}
                  onClose={() => setError(null)}
                >
                  {error}
                </Alert>
              ) : null}
              {/* <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="fw-bold">Module</TableCell>
                    <TableCell className="fw-bold">Create</TableCell>
                    <TableCell className="fw-bold">Read</TableCell>
                    <TableCell className="fw-bold">Update</TableCell>
                    <TableCell className="fw-bold">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {info?.map((row: any) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={row.create}
                          onChange={() => {
                            onChange({ ...row, create: !row.create });
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={row.read}
                          onChange={() => {
                            onChange({ ...row, read: !row.read });
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={row.update}
                          onChange={() => {
                            onChange({ ...row, update: !row.update });
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={row.delete}
                          onChange={() => {
                            onChange({ ...row, delete: !row.delete });
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table> */}
              <DatatableComponent
                columns={[
                  {
                    label: "Role",
                    name: "role.label",
                  },
                  {
                    label: "Module",
                    name: "Module",
                    options: {
                      filter: true,
                      sort: true,
                    },
                  },

                  {
                    label: "Create",
                    name: "Create",
                  },
                  {
                    label: "Read",
                    name: "Read",
                  },
                  {
                    label: "Update",
                    name: "Update",
                  },
                  {
                    label: "Delete",
                    name: "Delete",
                  },
                ]}
                url={""}
                originData={info}
                title={""}
                ExpandedRows={(rowData: any, rowMeta: any) => {
                  let index2 = rowMeta?.dataIndex;

                  return (
                    <ExpandedRows
                      index={index2}
                      list={info[index2]?.role?.permissions}
                      onChange={onChange}
                    />
                  );
                }}
              />
            </TableContainer>
          )}
        </CardContent>
      </Card>
    </Col>
  );
};

export default UserPermission;
