import { Col, Row } from "react-bootstrap";
import Breadcrumb from "../../components/Breadcrumb";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Snackbar,
  Typography,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchDetails,
  getError,
  getFileMime,
  getSuccessMessage,
  inArray,
  submitGeneralForm,
} from "../../services/common";
import React, { useEffect, useState } from "react";
import {
  Check,
  BorderColorOutlined,
  Forward,
  HighlightOff,
  Info,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import moment from "moment";
import SmallDialog from "../../components/SmallModel";
import DeleteDialog from "../../components/DeleteDialog";
import { mapStateToProps } from "../../store/helpers/mapState";
import { connect } from "react-redux";
import OpenDocuments from "../../components/openDocument";
import RequestHistoryProcess from "../../components/RequestHistory";
import GeneralButton from "../../components/Button";
import FileUpload from "../../components/FileUploader";
import Reviewers from "../../components/Reviewer";
import { LinearProgress } from "@material-ui/core";

const ServiceRequestDetails = (props: any) => {
  const { loggedUser } = props;
  const navigate = useNavigate();
  const { id }: any = useParams(); //request id
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const [openSnack, setOpenSnack] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewDocument, setViewDocument] = useState(false);
  const [viewBankDocument, setViewBankDocument] = useState(false);
  const [document, setDocument] = useState<any>();
  const [viewFinal, setViewFinal] = useState(false);
  const [info, setInfo] = useState<any>();
  const [finalDocument, setFinalDocument] = useState<any>([]);
  const [newData, setData] = useState<any>();
  const [openDialogue, setOpenDialogue] = useState<any>(false);
  const [deleteOpen, setDeleteOpen] = useState(0);
  const [deleteActionUrl, setDeleteActionUrl] = useState<
    string | undefined | null
  >();
  const isInitiator = loggedUser?.id === info?.requester; //the requestor
  const isApprover = loggedUser?.id === info?.approver; //the approver
  const canUpdate =
    isInitiator &&
    (info?.status === "pending" || info?.status === "request action");
  const isDocRequired =
    (info?.requestType.includes("pay slip") ||
      info?.requestType.includes("bank form")) &&
    newData?.status === "approved";

  // let approver = info?.RequestApprovers ?? []; //the request approvers

  let approver =
  info?.RequestApprovers?.filter(
    (approver: any) => approver?.category === "approver"
  ) ?? []; 
  approver = approver?.sort((a: any, b: any) => a.index - b.index);

  const lastApprover =
    approver[approver.length - 1]?.approver === info?.approver;

  let canApprove =
    isApprover &&
    info?.RequestApprovers?.filter(
      (v: any) => v.approver === loggedUser?.id && v.category === "approver"
    )?.length > 0;
  const fetchServiceRequestDetails = async () => {
    const resp = await fetchDetails(`service-request/view/${id}`);
    if (resp.status) {
      setInfo(resp.data);
      setViewFinal(false);
    }
  };
  useEffect(() => {
    if (id) {
      //fetch service-request details
      fetchServiceRequestDetails().then();
    }
  }, [id]);

  useEffect(() => {
    if (isApprover && info?.status === "pending") {
      onSubmit({
        id: info?.id,
        status: "under review",
        comment: "request is under review",
      });
    }
  }, [info?.id, info?.status, isApprover]);

  const onSubmit = async (data?: any) => {
    setLoading(true);
    setConfirm(false);
    let infoToSave = data ?? newData;

    const formData = new FormData();
    formData.append("body", JSON.stringify(infoToSave));

    if (finalDocument) {
      for (let i = 0; i < finalDocument.length; i++) {
        formData.append("finalDocument", finalDocument[i]);
      }
    }
    const resp = await submitGeneralForm(
      `/service-request/update/${info?.id}`,
      formData,
      null,
      "PUT"
    );
    if (resp.status) {
      setData({});
      fetchServiceRequestDetails().then();
      setOpenDialogue(false);
      setLoading(false);
      setSuccess(getSuccessMessage(resp));
      setError(null);
    } else {
      setError(getError(resp));
      setSuccess(null);
      setLoading(false);
    }
    setOpenSnack(true);
  };
  return (
    <div>
      <Breadcrumb />
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={() => {
          setOpenSnack(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {error ? (
          <Alert
            style={{ width: "100%" }}
            className={"mb-3"}
            severity={"error"}
            onClose={() => setError(null)}
          >
            {error}
          </Alert>
        ) : success ? (
          <Alert
            style={{ width: "100%" }}
            className={"mb-3"}
            severity={"success"}
            onClose={() => setSuccess(null)}
          >
            {success}
          </Alert>
        ) : (
          <></>
        )}
      </Snackbar>

      <Row>
        <Col md={12}>
        {!info?.id?<LinearProgress/>:null }
         <Card className={"bg- shadow-sm p-3"}>
            <CardHeader
              subheader={
                <span className="fw-bold text-capitalize">{`Request for:${info?.requestType}`}</span>
              }
              action={
                <span
                  className={
                    info?.status === "pending"
                      ? "badge bg-primary rounded-pill"
                      : info?.status === "rejected"
                      ? "badge bg-danger rounded-pill"
                      : info?.status === "request action"
                      ? "badge bg-warning rounded-pill"
                      : info?.status === "forwarded"
                      ? "badge bg-info rounded-pill"
                      : info?.status === "under review"
                      ? "badge bg-secondary rounded-pill"
                      : "badge bg-success rounded-pill"
                  }
                >
                  {info?.status}
                </span>
              }
            />
            <CardContent>
              <div className="row mb-3 justify-content-end">
                {info?.status !== "approved" && info?.status !== "rejected" ? (
                  <div className="col-md-8 fw-bold d-flex justify-content-end">
                    {/* {isInitiator ? (
                      <Button
                        className="me-2"
                        size="small"
                        color="error"
                        variant="outlined"
                        startIcon={<Delete />}
                        onClick={() => {
                          setDeleteOpen(1);
                          setDeleteActionUrl(
                            `/service-request/delete/${info?.id}`
                          );
                        }}
                      >
                        Delete
                      </Button>
                    ) : null} */}
                    {canUpdate ? (
                      <Button
                        className="btn-primary me-1"
                        size="small"
                        variant="outlined"
                        startIcon={<BorderColorOutlined />}
                        onClick={() => {
                          if (isApprover) {
                            navigate(
                              `../../../employee/requests/request/${info?.id}`
                            );
                          } else {
                            navigate(`../request/${info?.id}`);
                          }
                        }}
                      >
                        Update
                      </Button>
                    ) : null}
                    {isApprover &&
                    inArray(["under review", "forwarded"], info?.status) ? (
                      <>
                        <Button
                          className="me-1"
                          color="error"
                          size="small"
                          variant="outlined"
                          startIcon={<HighlightOff />}
                          onClick={() => {
                            setOpenDialogue(true);
                            setData({ id: info?.id, status: "rejected" });
                          }}
                        >
                          Reject
                        </Button>
                        <Button
                          className="me-1 text-nowrap"
                          color="secondary"
                          size="small"
                          variant="outlined"
                          startIcon={<Info />}
                          onClick={() => {
                            setOpenDialogue(true);
                            setData({
                              id: info?.id,
                              status: "request action",
                            });
                          }}
                        >
                          Request for action
                        </Button>
                        {approver?.length>1?
                        <Button
                          className="me-1 text-nowrap"
                          color="info"
                          size="small"
                          variant="outlined"
                          startIcon={<Forward />}
                          onClick={() => {
                            setOpenDialogue(true);
                            setData({ ...info, status: "forwarded" });
                          }}
                        >
                          Review & Forward
                        </Button>
                        :null}
                        {lastApprover ? (
                          <Button
                            className="me-1 text-nowrap"
                            color="success"
                            size="small"
                            variant="outlined"
                            disabled={!canApprove}
                            startIcon={<Check />}
                            onClick={() => {
                              setOpenDialogue(true);
                              setData({ id: info?.id, status: "approved" });
                            }}
                          >
                            Approve
                          </Button>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                ) : null}

                {info?.status === "approved" ? (
                  <div className="col-md-8 fw-bold d-flex justify-content-end">
                    {/* <Button
                      className="btn-primary me-2"
                      size="small"
                      variant="outlined"
                      startIcon={viewFinal ? <VisibilityOff /> : <Visibility />}
                      onClick={() => {
                        setViewFinal(!viewFinal);
                      }}
                    >
                      Approved Document
                    </Button> */}
                    <GeneralButton
                      variant="outlined"
                      icon={viewFinal ? <VisibilityOff /> : <Visibility />}
                      onClick={() => {
                        setViewFinal(!viewFinal);
                      }}
                      name={"Approved Document"}
                    />
                  </div>
                ) : null}
              </div>
              {viewFinal ? (
                <div>
                  {info?.finalDocument && viewFinal ? (
                    <div className={"row justify-content-center"}>
                      <div className={"col-md-8"}>
                        {info?.finalDocument?.map((v: string) => (
                          <OpenDocuments
                            downloadable={true}
                            card={true}
                            document={v}
                            mimeType={getFileMime(v)}
                          />
                        ))}
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                <>
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <div className="fw-bold mb-2">First Name</div>
                      <div>{info?.requestedBy?.Employee?.firstName}</div>
                    </div>
                    <div className="col-md-4">
                      <div className="fw-bold mb-2">Last Name</div>
                      <div>{info?.requestedBy?.Employee?.lastName}</div>
                    </div>
                    <div className="col-md-4">
                      <div className="fw-bold mb-2">Position</div>
                      <div>{info?.requestedBy?.Employee?.position?.name}</div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <div className="fw-bold mb-2">Birth Date</div>
                      <div>
                        {moment(info?.requestedBy?.Employee?.birthDate).format(
                          "DD/MM/YYYY"
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="fw-bold mb-2">Joined At</div>
                      <div>
                        {moment(
                          info?.requestedBy?.Employee?.employmentDate
                        ).format("DD/MM/YYYY")}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="fw-bold mb-2">Civil Status</div>
                      <div>
                        {info?.requestedBy?.Employee?.civilStatus ?? "---"}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <div className="fw-bold mb-2">Phone</div>
                      <div>{info?.requestedBy?.Employee?.phone ?? "---"}</div>
                    </div>
                    {info?.salary ? (
                      <div className="col-md-4">
                        <div className="fw-bold mb-2">
                          Net Salary or Lumpsum
                        </div>
                        <div>
                          {`${
                            info?.salaryWord
                          }(=${info?.salary?.toLocaleString()}=)` ?? "---"}{" "}
                          RWF
                        </div>
                      </div>
                    ) : null}

                    <div className="col-md-4 mb-4">
                      <div className="fw-bold mb-2">Reason</div>

                      <div>{info?.reason ?? "---"}</div>
                    </div>

                    {info?.period ? (
                      <div className="col-md-7">
                        <div className="fw-bold mb-2">Period</div>

                        <div>{info?.period ?? "---"}</div>
                      </div>
                    ) : null}
                  </div>
                  <div className="row mb-4">
                    {info?.bankForm ? (
                      <div className="col-md-4">
                        <div className="input-group mb-3">
                          <Typography style={{fontFamily:'inter'}} className="mb-3 fw-bold me-3">
                            Bank Form
                          </Typography>
                          <GeneralButton
                            variant="outlined"
                            icon={
                              viewBankDocument ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )
                            }
                            onClick={() => {
                              setViewBankDocument(!viewBankDocument);
                              setViewDocument(false);
                              setDocument(info?.bankForm);
                            }}
                            name={"View Document"}
                          />
                        </div>
                      </div>
                    ) : null}
                    {info?.supportingDocument ? (
                      <div className="col-md-4">
                        <div className="input-group mb-3">
                          <Typography style={{fontFamily:'inter'}} className="mb-3 fw-bold me-3">
                            Supporting Document
                          </Typography>
                          <GeneralButton
                            variant="outlined"
                            icon={
                              viewDocument ? <VisibilityOff /> : <Visibility />
                            }
                            onClick={() => {
                              setViewDocument(!viewDocument);
                              setViewBankDocument(false);
                              setDocument(info?.supportingDocument);
                            }}
                            name={"View Document"}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {document && (viewDocument || viewBankDocument) ? (
                    <div className={"row justify-content-center"}>
                      <div className={"col-md-8"}>
                        {document?.map((v: string) => (
                          <OpenDocuments
                            downloadable={true}
                            deletable={
                              (info?.status === "pending" ||
                                info?.status === "request action") &&
                              isInitiator
                            }
                            onDelete={() => {
                              let documentCopy = [...document];
                              const index = documentCopy.indexOf(v);
                              documentCopy.splice(index, 1);
                              if (viewBankDocument) {
                                onSubmit({
                                  id: info.id,
                                  bankForm: JSON.stringify(documentCopy),
                                  deleteDoc: true,
                                });
                              } else {
                                onSubmit({
                                  id: info.id,
                                  supportingDocument:
                                    JSON.stringify(documentCopy),
                                  deleteDoc: true,
                                });
                              }
                            }}
                            card={true}
                            document={v}
                            mimeType={getFileMime(v)}
                          />
                        ))}
                      </div>
                    </div>
                  ) : null}
                  <div className="row mb-4">
                    {/* <Typography className="col-md-12 mb-3 fw-bold">
                      Approvers Section
                    </Typography>

                    {approver?.map((appr: any) => (
                      <div className="col-md-3">
                        <List>
                          <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                              {loggedUser?.id === appr?.approver ? (
                                <Avatar>Me</Avatar>
                              ) : (
                                <Avatar
                                  {...stringAvatar(
                                    `${appr?.User?.Employee?.firstName ?? ""} ${
                                      appr?.User?.Employee?.lastName ?? ""
                                    }`
                                  )}
                                />
                              )}
                            </ListItemAvatar>
                            <Badge
                                badgeContent={
                                  appr?.status === "active" ? "active" : null
                                }
                                color={
                                  appr?.status === "active"
                                      ? "success"
                                      : "primary"
                                }
                            >
                            <ListItemText
                              primary={`${
                                appr?.User?.Employee?.firstName ?? ""
                              } ${appr?.User?.Employee?.lastName ?? ""}`}
                              secondary={
                                appr?.User?.Employee?.position?.name ?? ""
                              }
                            />
                            </Badge>
                          </ListItem>
                        </List>
                      </div>
                    ))} */}
                    <Reviewers
                      loggedUser={loggedUser}
                      RequestApprovers={info?.RequestApprovers}
                    />
                  </div>
                  <hr />
                  <div className="row">
                    <Typography style={{fontFamily:'inter'}} className="col-md-12 mb-3 fw-bold">
                      Request History
                    </Typography>
                    <div className="col-md-12">
                      <RequestHistoryProcess
                        RequestProcesses={info?.RequestProcesses}
                      />
                    </div>
                  </div>
                </>
              )}
            </CardContent>
          </Card>
        </Col>
      </Row>
      {openDialogue ? (
        <SmallDialog
          maxWidth="md"
          title="Add Response"
          buttonTitle="Submit"
          needButton={true}
          open={openDialogue}
          setOpen={setOpenDialogue}
        >
          {error ? (
            <Alert
              style={{ width: "100%" }}
              className={"mb-3"}
              severity={"error"}
              onClose={() => setError(null)}
            >
              {error}
            </Alert>
          ) : null}
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              setConfirm(true);
            }}
          >
            {(info?.requestType.includes("lumpsum certificate") ||
              info?.requestType.includes("salary certificate")) &&
            !info?.salaryWord ? (
              <>
                <label className="col-form-label text-capitalize text-nowrap">
                  Net Salary or Lumpsum(Rwandan Francs In Figures)
                </label>
                <input
                  className="form-control"
                  type={"number"}
                  disabled={true}
                  value={loggedUser?.Employee?.salary}
                  onChange={(e: any) => {
                    setData({
                      ...newData,
                      salary: e.currentTarget.value,
                    });
                  }}
                />
                <label className="col-form-label text-capitalize text-nowrap">
                  Net Salary or Lumpsum(In Words)
                </label>
                <input
                  className="form-control"
                  required={true}
                  value={newData?.salaryWord}
                  onChange={(e: any) => {
                    setData({
                      ...newData,
                      salaryWord: e.currentTarget.value,
                    });
                  }}
                />
              </>
            ) : null}

            {/* //add reference and article on retirement docs */}
            {info?.requestType.includes("retirement certificate") &&
            newData?.status === "approved" ? (
              <div className="row">
                <div className="col">
                  <label className="col-form-label text-capitalize text-nowrap">
                    Reference
                  </label>
                  <input
                    className="form-control"
                    required={true}
                    onChange={(e: any) => {
                      setData({
                        ...newData,
                        reference: e.currentTarget.value,
                      });
                    }}
                  />
                </div>
                <div className="col">
                  <label className="col-form-label text-capitalize text-nowrap">
                    Article(Ingingo)
                  </label>
                  <input
                    className="form-control"
                    required={true}
                    onChange={(e: any) => {
                      setData({
                        ...newData,
                        article: e.currentTarget.value,
                      });
                    }}
                  />
                </div>
                <div className="col">
                  <label className="col-form-label text-capitalize text-nowrap">
                    Category(Igice)
                  </label>
                  <input
                    className="form-control"
                    required={true}
                    onChange={(e: any) => {
                      setData({
                        ...newData,
                        articleCategory: e.currentTarget.value,
                      });
                    }}
                  />
                </div>
                <div className="col">
                  <label className="col-form-label text-capitalize text-nowrap ">
                    Law No(Itegeko)
                  </label>
                  <input
                    className="form-control"
                    required={true}
                    onChange={(e: any) => {
                      setData({
                        ...newData,
                        lawNo: e.currentTarget.value,
                      });
                    }}
                  />
                </div>
                <div className="col">
                  <label className="col-form-label text-capitalize text-nowrap">
                    Issued Date (Ryo ku wa)
                  </label>
                  <input
                    className="form-control"
                    required={true}
                    type="date"
                    onChange={(e: any) => {
                      setData({
                        ...newData,
                        lawDate: e.currentTarget.value,
                      });
                    }}
                  />
                </div>
                <div className="col">
                  <label className="col-form-label text-capitalize text-nowrap ">
                    Previous Law No(rihindura kandi ryuzuza itegeko No)
                  </label>
                  <input
                    className="form-control"
                    required={true}
                    onChange={(e: any) => {
                      setData({
                        ...newData,
                        prevLaw: e.currentTarget.value,
                      });
                    }}
                  />
                </div>
                <div className="col">
                  <label className="col-form-label text-capitalize text-nowrap">
                    Issued Date (Ryo ku wa)
                  </label>
                  <input
                    className="form-control"
                    required={true}
                    type="date"
                    onChange={(e: any) => {
                      setData({
                        ...newData,
                        prevLawDate: e.currentTarget.value,
                      });
                    }}
                  />
                </div>
              </div>
            ) : null}
            {newData?.status === "forwarded" ? (
              <>
                <label className="col-form-label text-capitalize text-nowrap">
                  {isDocRequired
                    ? "Attach Requested Document(s)"
                    : "Attachment"}
                </label>
                <FileUpload
                  setFiles={setFinalDocument}
                  files={finalDocument}
                  required={isDocRequired}
                  title={"upload"}
                  currentFile={info?.finalDocument}
                />
              </>
            ) : null}
            <label className="col-form-label text-capitalize text-nowrap">
              comment
            </label>
            <textarea
              placeholder="Type here..."
              className="form-control form-control mb-3"
              required={true}
              value={newData?.comment}
              rows={5}
              onChange={(e: any) => {
                setData({
                  ...newData,
                  comment: e.currentTarget.value,
                });
              }}
            />
            {newData?.status === "forwarded" && (
              <div className={"mt-2"}>
                <Typography style={{fontFamily:'inter'}} className="fw-bold">Forward To </Typography>{" "}
                <List>
                  {approver
                    ?.filter((notMe: any) => notMe?.approver !== loggedUser?.id)
                    ?.map((appr: any) => (
                      <ListItem
                        alignItems="flex-start"
                        key={appr.id}
                        onClick={() => {
                          setData({
                            ...newData,
                            approver: appr?.approver,
                          });
                        }}
                      >
                        <ListItemAvatar>
                          <Checkbox
                            required={true}
                            checked={appr?.approver === newData?.approver}
                          />{" "}
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${appr?.User?.Employee?.firstName ?? ""} ${
                            appr?.User?.Employee?.lastName ?? ""
                          }`}
                          secondary={appr?.User?.Employee?.position?.name ?? ""}
                        />
                      </ListItem>
                    ))}
                </List>
              </div>
            )}
            <div className={"float-end"}>
              <button
                type={"button"}
                onClick={() => {
                  setOpenDialogue(false);
                }}
                className={"btn btn-secondary me-1"}
              >
                <i className={"bi bi-x-lg"}></i> Cancel
              </button>
              {loading ? (
                <CircularProgress />
              ) : (
                <button
                  type={"submit"}
                  className={"btn btn-primary"}
                  disabled={isDocRequired && finalDocument?.length === 0}
                >
                  <i className={"bi bi-send-fill"}></i> Submit
                </button>
              )}
            </div>
          </form>
        </SmallDialog>
      ) : null}
      {/* {openDialogue ? ( */}
      <SmallDialog
        open={confirm}
        setOpen={setConfirm}
        onSubmit={() => {
          onSubmit(newData);
        }}
      >
        <div className="d-flex ">
          <CheckCircleIcon color="success" sx={{ fontSize: 50 }} />
          <div className="mt-4">{"Are you sure you want to submit?"}</div>
        </div>
      </SmallDialog>
      {/* ) : null} */}
      {deleteActionUrl ? (
        <DeleteDialog
          open={deleteOpen}
          onSuccess={() => {
            navigate(`../`);
          }}
          setOpen={setDeleteOpen}
          url={deleteActionUrl!}
        />
      ) : null}
    </div>
  );
};
export default connect(mapStateToProps)(ServiceRequestDetails);
