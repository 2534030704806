import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import { CustomizedMenus } from "../../components/customizeMenu";
import DeleteDialog from "../../components/DeleteDialog";
import { DatatableComponent } from "../../components/DatatableComponent";
import { Col, Row } from "react-bootstrap";
import { DashboardCard } from "../../components/DashboardCard";
import { default as ReactSelect } from "react-select";
import {
  CheckCircle,
  Info,
  Cancel,
  Pending,
  PeopleAltOutlined,
  UpdateOutlined,
  EventAvailableOutlined,
} from "@mui/icons-material";
import { fetchDetails } from "../../services/common";
import StatusComponent from "../../components/StatusComponent";
import { mapDispatchToProps } from "../../store/helpers/mapDispatch";
import { mapStateToProps } from "../../store/helpers/mapState";
import { connect } from "react-redux";
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const IncomingServiceRequestList = (props: any) => {
  const { loggedUser } = props;
  const navigate = useNavigate();
  const [list, setList] = useState<Array<any>>([]);
  const [deleteOpen, setDeleteOpen] = useState(0);
  const [deleteActionUrl, setDeleteActionUrl] = useState<
    string | undefined | null
  >();
  const [change, setChange] = useState(0);
  const [info, setInfo] = useState<any>({});
  const [dashboardData, setDashboardData] = useState<any>();
  const [empName, setEmpName] = useState<any>();
  const hasAdminRole =
    loggedUser?.roles?.filter((item: any) => item.role?.name === "admin")
      ?.length > 0;
  const requestType = [
    {
      label: "service certificate",
      value: "service certificate",
    },
    {
      label: "employment certificate",
      value: "employment certificate",
    },
    {
      label: "lumpsum certificate",
      value: "lumpsum certificate",
    },
    {
      label: "salary certificate",
      value: "salary certificate",
    },
    {
      label: "pay slip",
      value: "pay slip",
    },
    // {
    //   label: "furniture form",
    //   value: "furniture form",
    // },
    // {
    //   label: "refraichisment form",
    //   value: "refraichisment form",
    // },
    {
      label: "anticipative retirement",
      value: "anticipative retirement",
    },
    {
      label: "retirement",
      value: "retirement",
    },
  ];
  const pending =
    dashboardData?.filter((items: any) => items?.status === "pending") ?? [];
  const approved =
    dashboardData?.filter((items: any) => items?.status === "approved") ?? [];
  const rejected =
    dashboardData?.filter((items: any) => items?.status === "rejected") ?? [];
  const clarification =
    dashboardData?.filter((items: any) => items?.status === "request action") ??
    [];

  const dataSummary = async () => {
    const resp = await fetchDetails(
      hasAdminRole
        ? `dashboard/service-request-dashboard`
        : `service-request/all-dashboard/`
    );
    if (resp.status) {
      setDashboardData(resp.data);
    }
  };
  useEffect(() => {
    //fetch dataSummary
    dataSummary().then();
  }, [hasAdminRole]);

  const reload = () => {
    setChange((v) => v + 1);
  };

  return (
    <div>
      <Breadcrumb
      // addButton={{
      //   label: "New Request",
      //   onClick: () => navigate("request/new"),
      // }}
      />
      <Row className="mt-2">
        <Col md={3}>
          <DashboardCard
            content={{
              title: "Pending",
              number: pending[0]?.request ?? 0,
            }}
            className="justify-content-center"
            icon={
              <PeopleAltOutlined
                sx={{ color: "#054D6F", width: "25px", height: "25px" }}
              />
            }
          />
        </Col>
        <Col md={3}>
          <DashboardCard
            content={{
              title: "Request action",
              number: clarification[0]?.request ?? 0,
            }}
            icon={
              <UpdateOutlined
                sx={{ color: "#054D6F", width: "25px", height: "25px" }}
              />
            }
            className="justify-content-center"
          />
        </Col>
        <Col md={3}>
          <DashboardCard
            content={{
              title: "Approved",
              number: approved[0]?.request ?? 0,
            }}
            icon={
              <EventAvailableOutlined
                sx={{ color: "#054D6F", width: "25px", height: "25px" }}
              />
            }
            className="justify-content-center"
          />
        </Col>
        <Col md={3}>
          <DashboardCard
            content={{
              title: "Rejected",
              number: rejected[0]?.request ?? 0,
            }}
            icon={
              <Cancel
                sx={{ color: "#054D6F", width: "25px", height: "25px" }}
              />
            }
            className="justify-content-center"
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={3}>
          <label className="col-form-label text-capitalize">Status</label>
          <ReactSelect
            className={"text-nowrap"}
            options={[
              { value: "pending", label: "pending" },
              { value: "approved", label: "approved" },
              { value: "request action", label: "Request for action" },
              { value: "rejected", label: "rejected" },
              { value: "all", label: "All" },
            ]}
            onChange={(v) => {
              setInfo({ ...info, status: v?.value });
            }}
          />
        </Col>
        <Col md={3}>
          <label className="col-form-label text-capitalize">
            Employee Name/ID
          </label>
          <input
            className="form-control"
            onChange={(e: any) => {
              setEmpName(e.target.value);
            }}
          />
        </Col>
        <Col md={3}>
          <label className="col-form-label text-capitalize">From</label>
          <input
            className="form-control"
            type="date"
            onChange={(e: any) => {
              setInfo({ ...info, fromDate: e.target.value });
            }}
          />
        </Col>
        <Col md={3}>
          <label className="col-form-label text-capitalize">To</label>
          <input
            className="form-control"
            type="date"
            onChange={(e: any) => {
              setInfo({ ...info, toDate: e.target.value });
            }}
          />
        </Col>
      </Row>
      <DatatableComponent
        columns={[
          {
            label: "Employee Name",
            name: "requestedBy.Employee.names",
            data: "requester",
          },
          {
            label: "Document Requested",
            name: "requestType",
            options: {
              customBodyRender: (v: any) => (
                <span className="text-capitalize  ">
                  {v?.map((v: any) => v).join(", ")}
                </span>
              ),
            },
          },
          // {
          //   label: "Reason",
          //   name: "reason",
          //   options: {
          //     customBodyRender: (v: any) => (
          //       <span className="text-capitalize  ">{v}</span>
          //     ),
          //   },
          // },
          {
            label: "Created Date",
            name: "createdAt",
            options: {
              customBodyRender: (v: any) => moment(v).format("YYYY-MMM-DD"),
            },
          },
          {
            label: "Status",
            name: "status",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (status: any) => (
                <StatusComponent status={status} badge={false} />
              ),
            },
          },
          {
            name: "id",
            label: "Action",
            options: {
              filter: true,
              sort: false,
              customBodyRenderLite: (id: any, index: any) => {
                return (
                  <CustomizedMenus
                    onView={() => {
                      navigate(`details/${list[index].id}`);
                    }}
                  />
                );
              },
            },
          },
        ]}
        url={
          hasAdminRole
            ? `/dashboard/service-request/view?empName=${empName}&info=${JSON.stringify(
                info
              )}`
            : `/service-request/datatable/incoming?change=${change}&empName=${empName}&info=${JSON.stringify(
                info
              )}`
        }
        title={"Self Service Requests"}
        processData={(data: any) => {
          setList(data.data);
          return data;
        }}
      />
      {deleteActionUrl ? (
        <DeleteDialog
          open={deleteOpen}
          onSuccess={reload}
          setOpen={setDeleteOpen}
          url={deleteActionUrl!}
        />
      ) : null}
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IncomingServiceRequestList);
