import {Navigate, Outlet} from 'react-router-dom';

import Footer from '../components/Footer';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useAuth from "../hooks/useAuth";

function MainLayout() {
    const auth = useAuth();

    if(auth.isAuth()){
        return  <Navigate to={'/console/dashboard'}/>
    }

    return (
    <>
      {/* <MainNavbar /> */}
        <ToastContainer/>
      <Outlet />
      <Footer />
    </>
  );
}

export default MainLayout;
