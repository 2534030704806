import {
  Approval,
  Check,
  CheckCircle,
  Forward,
  HighlightOff,
  Info,
  Pending,
} from "@mui/icons-material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  timelineOppositeContentClasses,
} from "@mui/lab";
import { Collapse } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import StatusComponent from "./StatusComponent";
type IProps = {
  RequestProcesses: Array<any>;
};
const RequestHistoryProcess = (props: IProps) => {
  const { RequestProcesses } = props;
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState();
  const processList = RequestProcesses?.sort(
    (a: any, b: any) =>
      new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2,
        },
      }}
    >
      {processList?.map((process: any, key: any) => (
        <TimelineItem key={key}>
          <TimelineOppositeContent color="textSecondary">
            {moment(process.createdAt).format("MMM Do YY, h:mm a")}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot
              color={
                process?.status === "approved"
                  ? "success"
                  : process?.status === "rejected"
                  ? "error"
                  : process?.status === "pending"
                  ? "primary"
                  : process?.status === "forwarded"
                  ? "info"
                  : "warning"
              }
            >
              {process?.status === "approved" ? (
                <CheckCircle />
              ) : process?.status === "rejected" ? (
                <HighlightOff />
              ) : process?.status === "pending" ? (
                <Pending />
              ) : process?.status === "forwarded" ? (
                <Forward />
              ) : (
                <Info />
              )}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            role="button"
            onClick={() => {
              setOpen(!open || key !== index);
              setIndex(key);
            }}
          >
            {process?.createdBy ? (
              <span className="text-capitalize">
                {process?.createdBy?.Employee?.firstName}&nbsp;
                {process?.createdBy?.Employee?.lastName}
              </span>
            ) : (
              <span className="text-capitalize">{"Created By System"}</span>
            )}

            <div>
              {" "}
              {!open || index !== key ? (
                <em className="text-info">more</em>
              ) : (
                <em className="text-info">less</em>
              )}
            </div>
            <Collapse in={open && index === key} className="p-3">
              <div className="mb-3">
                {process?.suspendStartAt ? (
                  <span>Suspend From:&nbsp;{moment(process?.suspendStartAt).format('DD/MM/YYYY')}</span>
                ) : null}
              </div>
              <div className="mb-3">
                {process?.suspendEndAt ? (
                  <span>Suspend To:&nbsp;{moment(process?.suspendEndAt).format('DD/MM/YYYY')}</span>
                ) : null}
              </div>
              <div className="mb-3">

                <span>Comment:&nbsp;{process?.comment}</span>
              </div>
              <div>
                {" "}
                <span className="text-capitalize">
                  Status:&nbsp; <StatusComponent status={process?.status} badge={true} />
                </span>
              </div>
            </Collapse>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};
export default RequestHistoryProcess;
