import { useEffect, useState } from "react";
import { AddForm } from "../../employee/AddForm";
import FileUpload from "../../../components/FileUploader";
import { fetchDetails, monthList, validateFileNotNull } from "../../../services/common";

const PayBulkPayment = (props: any) => {
  const { setAddPayment, onSuccess, addPayment, share, id, loggedUser } = props;
  const memberId = loggedUser?.Employee?.CaisseMember?.id;
  const [month, setMonth] = useState<any>(0);
  const [postAmount, setPostAmount] = useState<any>(0);
  const [info, setInfo] = useState<any>();
  const [paymentProof, setPaymentProof] = useState<any>([]);
  const [previousUnpaid, setPreviousUnpaid] = useState<any>(0);
  const [includedUnpaidContribution, setIncludedUnpaidContribution] =
    useState<any>([]);

  const loadInfo = async () => {
    const resp = await fetchDetails(`contribution/view/${id}`);
    if (resp.status) {
      setInfo(resp.data);
      setMonth(resp.data?.previousUnpaidMonths + resp.data?.prePaidMonths);
    }
  };

  const getAllUnpaidContribution = async () => {
    const resp = await fetchDetails(
      `contribution/search?info=${JSON.stringify({
        memberId,
        status: "payment pending",
      })}`
    );
    if (resp.status) {
      let value = resp.data?.sort((a: any, b: any) => a.month - b.month);
      setPostAmount(value);
      setPreviousUnpaid(value?.length);
      setInfo({ ...info, previousUnpaidMonths: value?.length });
    }
  };
  const contributionCalculator = async (
    sum: number,
    previousUnpaidMonths: number,
    penalty: number,
    bukMonths: any
  ) => {
    const preMonths = month - previousUnpaid;
    const totalContribution = share * preMonths;
    const lastUnpaidMonth: any =
      bukMonths?.length > 0 ? Math.max(...bukMonths) : 0;

    const numberPreMonths = preMonths <= 0 ? 0 : preMonths;
    const monthContributed = [];
    for (let i = 1; i <= numberPreMonths; i++) {
      monthContributed.push(lastUnpaidMonth + i>12? lastUnpaidMonth + i - 12:lastUnpaidMonth + i );
      // if (lastUnpaidMonth > 12) {
      //   monthContributed.push(lastUnpaidMonth + i - 12);
      // } else {
        
      // }
    }
    // setBukMonths(monthContributed)
    const AllPaidMonth = monthContributed?.map((v: any) => {
      const customMonth = monthList?.find((item: any) => item?.id === v);
      return customMonth?.name
    });

    setInfo({
      ...info,
      share,
      previousUnpaidMonths,
      prePaidMonths: preMonths <= 0 ? 0 : preMonths,
      amount: preMonths <= 0 ? sum : sum + Number(totalContribution),
      penalty,
      bulkMonths:AllPaidMonth?.toString(),
    });
    setPaymentProof([]);
  };
  useEffect(() => {
    if (!id && month > 0) {
      const previousUnpaidMonths =
        month > previousUnpaid || month === 0
          ? Number(previousUnpaid)
          : Number(month);
      let sum = 0;
      let penalty = 0;
      let contributionReady: any = [];
      let monthContributed: any = [];
      for (let i = 0; i < previousUnpaidMonths; i++) {
        sum = sum + postAmount[i].amount;
        penalty = penalty + postAmount[i].penalty;
        contributionReady.push(postAmount[i].id);
        monthContributed.push(postAmount[i].month);
      }
      setIncludedUnpaidContribution(contributionReady);
      // setBukMonths(monthContributed)
      contributionCalculator(
        sum,
        previousUnpaidMonths,
        penalty,
        monthContributed
      ).then();
    }
  }, [month]);

  useEffect(() => {
    getAllUnpaidContribution().then();
  }, []);
  useEffect(() => {
    if (id) {
      loadInfo().then();
    }
  }, []);

  return (
    <AddForm
      title={"Bulk payment"}
      maxWidth="md"
      formAlert={
        <div>
          <strong>N.B :Amount paid must be above monthly Contribution</strong>
        </div>
      }
      mainFields={[
        {
          title: "",
          fields: [
            {
              label: "includedUnpaidContribution",
              name: "includedUnpaidContribution",
              col: 12,
              hidden: true,
              value: id ? null : includedUnpaidContribution,
            },
            {
              label: "penalty",
              name: "penalty",
              col: 12,
              hidden: true,
              value: info?.penalty,
            },
            {
              label: "Month to pay(s)",
              name: "months",
              type: "number",
              col: 12,
              required: true,
              disabled: id,
              value: month,
              min: 2,
              setValue: setMonth,
            },
            {
              label: "Previous Unpaid Month(s)",
              name: "previousUnpaidMonths",
              type: "number",
              col: 12,
              disabled: true,
              hidden: info?.previousUnpaidMonths === 0,
              value: info?.previousUnpaidMonths,
            },
            {
              label: "Remaining Unpaid Month(s)",
              name: "remainingUnpaidMonths",
              type: "number",
              col: 12,
              disabled: true,
              hidden: info?.prePaidMonths >= 0 || month === 0,
              value:
                info?.prePaidMonths >= 0 ? 0 : Math.abs(info?.prePaidMonths),
            },
            {
              label: "Number Of Pre-Payment Month(s)",
              name: "prePaidMonths",
              type: "number",
              col: 12,
              disabled: true,
              hidden: info?.prePaidMonths <= 0,
              value: info?.prePaidMonths,
              max:12
            },
          
            {
              label: "Pre Paid Months",
              name: "bulkMonths",
              type: "text",
              value: info?.bulkMonths,
              disabled: true,
            },
            {
              label: "Amount(RWF)",
              name: "amount",
              type: "number",
              col: 12,
              // required: true,
              disabled: true,
              value: info?.amount,
              // min: info?.monthlyAmount,
              // setValue:setAmount
            },
            {
              label: "Proof of payment",
              name: "supportingDocument",
              type: "file",
              hidden: !info?.amount,
              multiple: true,
              required: !info?.supportDocument,
              col: 12,
              value: paymentProof,
              validate: validateFileNotNull,
              render: () => {
                return (
                  <FileUpload
                    setFiles={setPaymentProof}
                    files={paymentProof}
                    required={!info?.supportDocument}
                    title="Upload"
                    accept={"application/pdf,image/*"}
                    currentFile={info?.supportDocument}
                  />
                );
              },
            },
            {
              label: "Reference No",
              name: "referenceNumber",
              type: "text",
              value: info?.referenceNumber,
              required: true,
            },
            {
              label: "Comment",
              name: "comment",
              type: "textarea",
              hidden: info?.status !== "request action",
              required: info?.status === "request action",
              col: 12,
            },
          ],
        },
      ]}
      url={
        info?.id
          ? `contribution/bulk-payment/${info?.id}`
          : `contribution/bulk-payment`
      }
      method={info?.id ? "PUT" : "POST"}
      onSuccess={onSuccess}
      open={addPayment}
      setOpen={() => setAddPayment(0)}
    />
  );
};
export default PayBulkPayment;
