import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import { CustomizedMenus } from "../../components/customizeMenu";
import DeleteDialog from "../../components/DeleteDialog";
import { DatatableComponent } from "../../components/DatatableComponent";
import { fetchDetails } from "../../services/common";
import StatusComponent from "../../components/StatusComponent";
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ServiceRequestList = () => {
  const navigate = useNavigate();
  const [list, setList] = useState<Array<any>>([]);
  const [deleteOpen, setDeleteOpen] = useState(0);
  const [deleteActionUrl, setDeleteActionUrl] = useState<
    string | undefined | null
  >();
  const [change, setChange] = useState(0);
  const [info, setInfo] = useState<any>({});
  const [dashboardData, setDashboardData] = useState<any>();

  const requestType = [
    {
      label: "service certificate",
      value: "service certificate",
    },
    {
      label: "employment certificate",
      value: "employment certificate",
    },
    {
      label: "lumpsum certificate",
      value: "lumpsum certificate",
    },
    {
      label: "salary certificate",
      value: "salary certificate",
    },
    {
      label: "pay slip",
      value: "pay slip",
    },
    {
      label: "furniture form",
      value: "furniture form",
    },
    {
      label: "refraichisment form",
      value: "refraichisment form",
    },
    {
      label: "anticipative retirement",
      value: "anticipative retirement",
    },
    {
      label: "retirement",
      value: "retirement",
    },
  ];

  const dataSummary = async () => {
    const resp = await fetchDetails(`service-request/dashboard/`);
    if (resp.status) {
      setDashboardData(resp.data);
    }
  };
  useEffect(() => {
    //fetch dataSummary
    dataSummary().then();
  }, []);

  const reload = () => {
    setChange((v) => v + 1);
  };

  return (
    <div>
      <Breadcrumb
        addButton={{
          label: "New Request",
          onClick: () => navigate("request/new"),
        }}
      />
      <DatatableComponent
        columns={[
          {
            label: "Document Requested",
            name: "requestType",
            options: {
              customBodyRender: (v: any) => (
                <span className="text-capitalize">{v?.map((v: any) => v)
                  .join(", ")}</span>
              ),
            },
          },
          {
            label: "Requested At",
            name: "createdAt",
            options: {
              customBodyRender: (v: any) => moment(v).format("YYYY-MMM-DD hh:mm A"),
            },
          },
          {
            label: "Status",
            name: "status",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (status: any) =>
              <StatusComponent status={status} badge={false}/>

            },
          },
          {
            name: "id",
            label: "Action",
            options: {
              filter: true,
              sort: false,
              customBodyRenderLite: (id: any, index: any) => {
                const canUpdate =
                  list[index]?.status === "request action"||list[index]?.status === "pending" ;
                  const canDelete =
                list[index]?.status === "pending" 
                return (
                  <CustomizedMenus
                    onView={() => {
                      navigate(`details/${list[index].id}`);
                    }}
                    onEdit={() => {
                      navigate(`request/${list[index].id}`);
                    }}
                    onDelete={() => {
                      setDeleteOpen(1);
                      setDeleteActionUrl(`/service-request/delete/${list[index].id}`);
                    }}
                    needEdit={canUpdate}
                    needDelete={canDelete}
                  />
                );
              },
            },
          },
        ]}
        url={`/service-request/datatable?change=${change}&info=${JSON.stringify(
          info
      )}`}
        title={"Self Service Requests"}
        processData={(data: any) => {
          setList(data.data);
          return data;
        }}
      />
      {deleteActionUrl ? (
        <DeleteDialog
          open={deleteOpen}
          onSuccess={reload}
          setOpen={setDeleteOpen}
          url={deleteActionUrl!}
        />
      ) : null}
    </div>
  );
};

export default ServiceRequestList;
