import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { AddForm } from "../../../employee/AddForm";
import { Delete } from "@mui/icons-material";

const CreateRole = ({
  open,
  setOpen,
  permissions,
  // permission,
  // setPermission,
  onSuccess,
}: any) => {
  const [info, setInfo] = React.useState<any>([]);

  const [permission, setPermission] = useState(false);

  // const fetchInfoDetails = async (id: string) => {
  //   const resp = await fetchDetails(`permission/view/${id}`);
  //   if (resp.status) {
  //     setInfo(resp.data);
  //   }
  // };

  // const onChange = async (v: any) => {
  //   const permission = permissions.find(
  //     (permission: any) => permission.id === v.value
  //   );

  //   const { id, ...perm } = permission;
  //   setPermission(perm);
  // };
  // useEffect(() => {
  //   // If there is data, the form is valid
  //   setIsValid(permission && permission?.name ? true : false);
  // }, [permission]);

  useEffect(() => {
    if (permission) {
      let newArray = [...info];
      const newPermission = permissions?.find(
        (item: any) => item.id === permission
      );
      if (!newArray.includes(newPermission)) {
        newArray.push(newPermission);
      }
      setInfo(newArray);
    }
  }, [permission]);

  return (
    // <FormModel open={open} setOpen={setOpen} title={"Create new permission"}>
    //   <ToastContainer />
    //   <Row className={"justify-content-center"}>
    //     <Col className="bg-white pb-3">
    //       {/* <form method="post" onSubmit={handleSubmit}>
    //         <div className="row ">
    //           <Col lg={12}>
    //             <label className="col-form-label text-capitalize">
    //               Permissions <span className="text-danger">*</span>
    //             </label>
    //             <ReactSelect
    //               required
    //               name="promotionYearId"
    //               className={"text-nowrap"}
    //               styles={{
    //                 menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    //               }}
    //               value={info?.year}
    //               options={permissions?.map((p: any) => ({
    //                 value: p?.id,
    //                 label: p?.name,
    //               }))}
    //               onChange={(v: any) => onChange(v)}
    //             />
    //           </Col>
    //         </div>
    //         <div className={"clearfix mt-3"}>
    //           <div className={"float-end"}>
    //             <button
    //               type={"button"}
    //               onClick={() => setOpen(false)}
    //               className={"btn btn-light me-1"}
    //             >
    //               <i className={"bi bi-x-lg"}></i> Cancel
    //             </button>
    //             {loading ? (
    //               <CircularProgress />
    //             ) : (
    //               <button
    //                 disabled={!isValid}
    //                 type={"submit"}
    //                 name="submit"
    //                 className={"btn btn-primary"}
    //               >
    //                 <i className={"bi bi-send-fill"}></i> Submit
    //               </button>
    //             )}
    //           </div>
    //         </div>
    //       </form> */}
    //     </Col>
    //   </Row>
    // </FormModel>

    <AddForm
      maxWidth="md"
      mainFields={[
        {
          title: "",
          fields: [
            {
              label: "Permissions",
              name: "permissions",
              type: "select",
              required: true,
              values: permissions?.map((v: any) => ({
                label: v.name,
                value: v.id,
              })),
              // value: info?.departmentId,
              setValue: (val) => {
                setPermission(val);
              },
            },
            {
              label: "Selected Permissions",
              name: "permission",
              value: info,
              required: true,
              render: () => {
                return (
                  <TableContainer className="border">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell>Permissions</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {info?.length?info?.map((row: any, index: any) => (
                          <TableRow>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{row?.name}</TableCell>
                            <TableCell>
                              <Delete
                                role="button"
                                color="error"
                                onClick={() => {
                                  info.splice(index, 1);
                                  setInfo([...info]);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        )):<TableRow>
                          <TableCell colSpan={3} className="text-center">No permission selected</TableCell>
                          </TableRow>}
                      </TableBody>
                    </Table>
                  </TableContainer>
                );
              },
            },
          ],
        },
      ]}
      url={"/permission/create"}
      method={"POST"}
      onSuccess={() => {
        setOpen(0);
        onSuccess();
        setInfo([]);
      }}
      open={open}
      setOpen={() => setOpen(0)}
    />
  );
};

export default CreateRole;
