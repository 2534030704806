import Breadcrumb from "../../../components/Breadcrumb";
import { DatatableComponent } from "../../../components/DatatableComponent";
import { Add } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { AddForm } from "../../employee/AddForm";
import GeneralButton from "../../../components/Button";
import { fetchDetails } from "../../../services/common";
import { CustomizedMenus } from "../../../components/customizeMenu";
import DeleteDialog from "../../../components/DeleteDialog";
import { Col, Row } from "react-bootstrap";
import { default as ReactSelect } from "react-select";

const HierarchList = () => {
  const [list, setList] = useState<Array<any>>([]);
  const [deleteOpen, setDeleteOpen] = useState(0);
  const [deleteActionUrl, setDeleteActionUrl] = useState<
    string | undefined | null
  >();
  const [change, setChange] = useState(0);
  const [info, setInfo] = useState<any>();
  const [type, setType] = useState<any>("mission");
  const [addNew, setAddNew] = useState<any>(0);
  const [applyToAll, setApplyToAll] = useState<any>();
  // const [level, setLevel] = useState<any>([]);
  // const [position, setPosition] = useState<any>([]);
  const [roles, setRole] = useState<any>([]);
  const reload = () => {
    setChange((v) => v + 1);
  };

  useEffect(() => {
    laodInitialData();
  }, []);

  useEffect(() => {
    if (applyToAll === "yes") {
      setInfo({ ...info, position: undefined, applyToAll });
    }
  }, [applyToAll]);

  const laodInitialData = async () => {
    // const level = await fetchDetails("/level/view");
    // const echelon = await fetchDetails("/echlon/view");
    // const posit = await fetchDetails("/position/view");
    const role = await fetchDetails("/role/view");

    // if (posit.status) setPosition(posit?.data);
    // if (echelon.status) setEchelon(echelon?.data);
    // if (level.status) setLevel(level?.data);
    if (role.status) {
      const list = role?.data?.filter((item: any) => item?.main);
      const newArray = list?.map(({ label, id }: any) => ({
        label,
        value: id,
      }));
      newArray.push({ label: "None", value: null });
      setRole(newArray);
    }
  };

  const category = [
    {
      label: "Leave Plan",
      value: "leave-plan",
    },
    {
      label: "Other Leave",
      value: "leave",
    },
    {
      label: "Mutation/Transfer",
      value: "mutation",
    },
    {
      label: "Mission",
      value: "mission",
    },
    {
      label: "Retirement certificate",
      value: "retirement-certificate",
    },
    {
      label: "Service certificate",
      value: "service-certificate",
    },
    {
      label: "Other certificate",
      value: "other-certificate",
    },
  ];
  // const roleList = roles?.map(({ label, id }: any) => ({
  //   label,
  //   value: id,
  // }));
  return (
    <div>
      <Breadcrumb
        endElement={
          <div className="d-flex justify-content-end">
            <GeneralButton
              variant="outlined"
              size="medium"
              name={"Add New"}
              icon={<Add />}
              onClick={() => {
                setInfo({});
                setAddNew(1);
              }}
            />
          </div>
        }
      />
      <Row className="mb-3">
        <Col md={3}>
          <label className="col-form-label text-capitalize">Category</label>
          <ReactSelect
            className={"text-nowrap"}
            options={category}
            value={category?.find((option: any) => option.value === type)}
            onChange={(v: any) => {
              setType(v?.value);
            }}
          />
        </Col>
      </Row>
      <DatatableComponent
        columns={[
          {
            label: "Initiator",
            name: "Role.label",
            options: {
              customBodyRenderLite: (id: any, index: any) =>
                list[index]?.Role?.label ?? "General Flow",
            },
          },
          // {
          //   label: "Level",
          //   name: "Level.name",
          // },
          // {
          //   label: "Scale",
          //   name: "Echlon.name",
          // },
          {
            label: "1st Approve",
            name: "firstApprover.label",
          },
          {
            label: "2nd Approve",
            name: "secondApprover.label",
          },
          {
            label: "3rd Approve",
            name: "thirdApprover.label",
          },
          {
            label: "4th Approve",
            name: "fourthApprover.label",
          },
          {
            label: "5th Approve",
            name: "fifthApprover.label",
          },
          {
            label: "1st Reviewer",
            name: "firstReview.label",
          },
          {
            label: "2nd Reviewer",
            name: "secondReview.label",
          },
          {
            label: "3rd Reviewer",
            name: "thirdReview.label",
          },
          {
            name: "id",
            label: "Action",
            options: {
              filter: true,
              sort: false,
              customBodyRenderLite: (id: any, index: any) => {
                return (
                  <CustomizedMenus
                    onEdit={() => {
                      setInfo({
                        ...list[index],
                        applyToAll: list[index]?.applyToAll ? "yes" : "no",
                      });
                      setApplyToAll(list[index]?.applyToAll ? "yes" : "no");
                      setAddNew(1);
                    }}
                    onDelete={() => {
                      setDeleteOpen(1);
                      setDeleteActionUrl(
                        `settings/approve-hierarch/${list[index].id}`
                      );
                    }}
                    needEdit={true}
                    needDelete={true}
                  />
                );
              },
            },
          },
        ]}
        url={`settings/approve-hierarch/datatable/${type}?change=${change}`}
        title={"Approve Hierarch"}
        processData={(data: any) => {
          setList(data.data);
          return data;
        }}
      />

      <AddForm
        maxWidth={"md"}
        mainFields={[
          {
            title: "Approvers",
            fields: [
              {
                label: "Category",
                name: "type",
                type: "select",
                required: true,
                values: category,
                value: info?.type,
              },
              {
                label: "General Flow",
                name: "applyToAll",
                type: "select",
                required: true,
                values: [
                  { label: "Yes", value: "yes" },
                  { label: "No", value: "no" },
                ],
                value: info?.applyToAll,
                setValue: setApplyToAll,
              },
              {
                label: "Initiator",
                name: "position",
                type: "select",
                required: applyToAll === "no",
                hidden: applyToAll === "yes",
                values: roles,
                value: info?.position,
              },
              // {
              //   label: "Scale",
              //   name: "echlonId",
              //   type: "select",
              //   required: true,
              //   values: echelon?.map(({ name, id }: any) => ({
              //     label: name,
              //     value: id,
              //   })),
              //   value: info?.echlonId,
              // },
              // {
              //   label: "Level",
              //   name: "levelId",
              //   type: "select",
              //   // hidden: info?.level !== "level",
              //   values: level?.map(({ name, id }: any) => ({
              //     label: name,
              //     value: id,
              //   })),
              //   value: info?.levelId,
              // },
              {
                label: "1st Approve",
                name: "firstApprove",
                type: "select",
                required: true,
                values: roles,
                value: info?.firstApprove,
              },
              {
                label: "2nd Approve",
                name: "secondApprove",
                type: "select",
                // required: true,
                values: roles,
                value: info?.secondApprove,
              },
              {
                label: "3rd Approve",
                name: "thirdApprove",
                type: "select",
                // required: true,
                values: roles,
                value: info?.thirdApprove,
              },
              {
                label: "4th Approve",
                name: "fourthApprove",
                type: "select",
                // required: true,
                values: roles,
                value: info?.fourthApprove,
              },
              {
                label: "5th Approve",
                name: "fifthApprove",
                type: "select",
                // required: true,
                values: roles,
                value: info?.fifthApprove,
              },
            ],
          },
          {
            title: "Reviewers",
            fields: [
              {
                label: "First Reviewer",
                name: "firstReviewer",
                type: "select",
                values: roles,
                value: info?.firstReviewer,
              },
              {
                label: "Second Reviewer",
                name: "secondReviewer",
                type: "select",
                values: roles,
                value: info?.secondReviewer,
              },
              {
                label: "Third Reviewer",
                name: "thirdReviewer",
                type: "select",
                values: roles,
                value: info?.thirdReviewer,
              },
            ],
          },
        ]}
        url={
          info?.id
            ? `settings/approve-hierarch/${info?.id}`
            : "settings/approve-hierarch"
        }
        method={info?.id ? "PUT" : "POST"}
        title={info?.id ? "Update Hierarch" : "Create New"}
        onSuccess={() => {
          setAddNew(0);
          setChange(change + 1);
          setInfo({});
        }}
        open={addNew}
        setOpen={setAddNew}
      />

      {deleteActionUrl ? (
        <DeleteDialog
          open={deleteOpen}
          onSuccess={reload}
          setOpen={setDeleteOpen}
          url={deleteActionUrl!}
        />
      ) : null}
    </div>
  );
};

export default HierarchList;
