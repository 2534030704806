import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { DatatableComponent } from "../../../components/DatatableComponent";
import moment from "moment/moment";
import { CustomizedMenus } from "../../../components/customizeMenu";
import { useEffect, useState } from "react";
import DeleteDialog from "../../../components/DeleteDialog";
import StatusComponent from "../../../components/StatusComponent";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/helpers/mapState";
import { mapDispatchToProps } from "../../../store/helpers/mapDispatch";
import { monthList } from "../../../services/common";
import {
  Add,
  MultilineChart,
  Payment,
} from "@mui/icons-material";
import GeneralButton from "../../../components/Button";
import FileUpload from "../../../components/FileUploader";
import { AddForm } from "../../employee/AddForm";
import {  Col, Row } from "react-bootstrap";
import { Alert, AlertTitle } from "@mui/material";
import PayBulkPayment from "./BulkPayment";

const ContributionList = (props: any) => {
  const { loggedUser } = props;
  const navigate = useNavigate();
  const [list, setList] = useState<Array<any>>([]);
  const [deleteOpen, setDeleteOpen] = useState(0);
  const [change, setChange] = useState(0);
  const [open, setOpen] = useState(0);
  const [deleteActionUrl, setDeleteActionUrl] = useState<
    string | undefined | null
  >();

  const [info, setInfo] = useState<any>();
  // const { id } = useParams();
  const [files, setFiles] = useState<any>([]);
  const [share, setShare] = useState(0);
  const [addPayment, setAddPayment] = useState(0);


  useEffect(() => {
    if (loggedUser) {
      setShare(loggedUser?.Employee?.CaisseMember?.share);
    }
  }, [loggedUser]);
  const hasPendingPayment =
    list?.filter((item: any) => item?.status === "payment pending") ?? [];

  const isActive = loggedUser?.Employee?.CaisseMember?.status === "active";
  return (
    <div>
      <Breadcrumb
        endElement={
          isActive ? (
            <>
              {loggedUser?.Employee?.status === "exited" &&
              hasPendingPayment?.length ? (
                <GeneralButton
                  variant="outlined"
                  size="medium"
                  name={"Pay Monthly Installment"}
                  icon={<Payment />}
                  onClick={() => navigate("create")}
                />
              ) : null}
              <GeneralButton
                variant="outlined"
                size="medium"
                name={"Pay Bulk Installment"}
                icon={<MultilineChart />}
                onClick={() => setAddPayment(1)}
              />
              <GeneralButton
                variant="outlined"
                size="medium"
                name={"Increase savings"}
                icon={<Add />}
                onClick={() => setOpen(1)}
              />
            </>
          ) : null
        }
      />

      <Row className={""}>
        {hasPendingPayment?.length ? (
          <Alert severity="warning" className="mt-3 mb-3 col-md-12">
            <AlertTitle>Warning</AlertTitle>
            You have a pending contribution for payment
            <strong className="ms-2">please check it out!</strong>
          </Alert>
        ) : null}
        <Col md={12}>
          <DatatableComponent
            printable={true}
            columns={[
              {
                label: "Paid Amount",
                name: "amount",
                options: {
                  customBodyRender: (v: any) => v?.toLocaleString(),
                },
              },
              {
                label: "Required Amount",
                name: "requiredAmount",
                options: {
                  customBodyRender: (v: any) => v?.toLocaleString(),
                },
              },
              {
                label: "Penalty",
                name: "penalty",
                options: {
                  customBodyRender: (v: any) => v?.toLocaleString(),
                },
              },
              {
                label: "Period",
                name: "period",
              },
              // {
              //   label: "Month",
              //   name: "month",
              //   options: {
              //     customBodyRender: (month: any) =>
              //       monthList.find((v) => v.id === month)?.name,
              //   },
              // },
              // {
              //   label: "Fiscal Year",
              //   name: "fiscal.name",
              // },

              {
                label: "Monthly Pay Date",
                name: "createdAt",
                options: {
                  customBodyRender: (_v: any, _meta: any, data: any) =>
                    moment({
                      date: data?.CaisseMember?.monthlyContributionPaymentDate,
                    }).format("L"),
                },
              },
              {
                label: "Status",
                name: "status",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (status: any) => (
                    <StatusComponent status={status} badge={false} />
                  ),
                },
              },
            ]}
            url={`contribution/datatable?change=${change}`}
            actions={(id, meta, row) => {
              const canUpdate =
                (row?.status === "request action" ||
                  row?.status === "pending") &&
                !row.parentId;
              const canDelete = row?.status === "pending" && !row?.parentId;
              return [
                <CustomizedMenus
                  onEdit={() => {
                    setInfo(row);
                    if (
                      share !== row?.amount &&
                      !row?.isBulk
                    ) {
                      setOpen(1);
                      setInfo(row);
                    } else if (
                      row?.isBulk
                    ) {
                      setAddPayment(1);
                    } else {
                      navigate(`update/${row?.id}`);
                    }
                  }}
                  onView={() => {
                    navigate(`details/${row?.id}`);
                  }}
                  onDelete={() => {
                    setDeleteOpen(1);
                    setDeleteActionUrl(`contribution/delete/${row?.id}`);
                  }}
                  needEdit={canUpdate}
                  needDelete={canDelete}
                />,
                // !loggedUser ||
                // loggedUser.Employee?.status !== "exited" ||
                // !loggedUser.hasPendingPaymentContribution ||
                // row?.status !== "payment pending" ? null : (
                //   <IconButton onClick={() => navigate(`create/${row?.month}`)}>
                //     <PaymentsRounded />
                //   </IconButton>
                // ),
              ];
            }}
            title={"Contributions List"}
            processData={(data: any) => {
              setList(data?.data);
              return data;
            }}
          />
        </Col>
      </Row>
      {deleteActionUrl ? (
        <DeleteDialog
          open={deleteOpen}
          onSuccess={() => {
            setChange(change + 1);
          }}
          setOpen={setDeleteOpen}
          url={deleteActionUrl!}
        />
      ) : null}

      {/* contribution form */}
      <AddForm
        col={12}
        mainFields={[
          {
            title: "",
            fields: [
              {
                label: "Amount",
                name: "amount",
                type: "number",
                value: info?.amount,
                required: true,
                min: share,
              },
              {
                label: "Month",
                name: "month",
                type: "select",
                disabled: true,
                value: info?.month,
                values: monthList.map((v: any) => ({
                  label: v.name,
                  value: v.id,
                })),
                hidden: !info?.id,
              },

              {
                label: "Reference No",
                name: "referenceNumber",
                type: "text",
                value: info?.referenceNumber,
                required: true,
              },
              {
                label: "Attach Proof of Payment",
                name: "supportingDocument",
                type: "file",
                multiple: true,
                value: files,
                render: () => {
                  return (
                    <FileUpload
                      setFiles={setFiles}
                      files={files}
                      required={!info?.id}
                    />
                  );
                },
              },
            ],
          },
        ]}
        url={
          info?.id
            ? `contribution/update/${info?.id}`
            : "contribution/increase-savings"
        }
        method={info?.id ? "PUT" : "POST"}
        title={info?.id ? "Update" : " Increase Savings"}
        onSuccess={() => {
          setChange(change + 1);
          setOpen(0);
        }}
        open={open}
        setOpen={() => {
          setOpen(0);
        }}
      />

      {addPayment ? (
        <PayBulkPayment
          setAddPayment={setAddPayment}
          addPayment={addPayment}
          share={share}
          id={info?.id}
          previousUnpaid={hasPendingPayment?.length}
          loggedUser={loggedUser}
          onSuccess={() => {
            setChange(change + 1);
          }}
        />
      ) : null}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContributionList);
