import { useEffect, useState } from "react";
import moment from "moment/moment";
import { DatatableComponent } from "../../../components/DatatableComponent";
import { default as ReactSelect } from "react-select";
import { Col, Row } from "react-bootstrap";
import {
  attendanceDeviceStatus,
  attendanceStatusList,
  fetchDetails,
  monthList,
} from "../../../services/common";
import Breadcrumb from "../../../components/Breadcrumb";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Divider,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import "./attendance.css";
import SmallDialog from "../../../components/SmallModel";
import StatusComponent from "../../../components/StatusComponent";
import { Clear, Search } from "@mui/icons-material";

type IProps = {
  report?: boolean | undefined;
};
const AllAttendanceList = (props: IProps) => {
  const newDate = new Date();
  const initState = {
    month: newDate.getMonth(),
    year: newDate.getFullYear(),
    startDate: moment(moment().startOf("month")).format("YYYY-MM-DD"),
    endDate: moment(moment().endOf("month")).format("YYYY-MM-DD"),
    attendanceDevice: "registered",
  };

  const { report } = props;
  const [list, setList] = useState<Array<any>>([]);
  const [info, setInfo] = useState<any>(initState);
  const [dataToSubmit, setDataToSubmit] = useState<any>(initState);
  const [entrances, setEntrances] = useState<any>([]);
  const [open, setOpen] = useState<any>(false);
  const [cols, setCols] = useState<Array<any>>([]);
  const [attendanceList, setAttendanceList] = useState([]);
  // const [viewData, setViewData] = useState<any>(initState);
  const [branches, setBranches] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);

  const navigate = useNavigate();
  // get days of the month

  const getDaysInMonth = () => {
    let newDate = new Date();
    const month = info?.month ?? newDate.getMonth();
    const year = info?.year ?? newDate.getFullYear();
    let start = new Date(info?.startDate);

    let date = start ?? new Date(year, month, 1);
    let days: any = [
      {
        // label: moment(date).format("MMMM YYYY"),
        label: "Names",
        name: "firstName",
        options: {
          filter: true,
          sort: true,
          customBodyRenderLite: (id: any, index: any) => (
            <span className="text-capitalize text-nowrap">
              {list[index]?.names}
            </span>
          ),
        },
      },
      {
        label: "Attendance Device",
        name: "attendanceDevice",
        options: {
          filter: true,
          sort: true,
          customBodyRenderLite: (id: any, index: any) => (
            <StatusComponent
              status={list[index]?.attendanceDevice}
              badge={true}
            />
          ),
        },
      },
      {
        label: (
          <div>
            <div className="d-flex  text-center">
              <span className="text-white  fw-bold border card-width">P</span>
              <span className="text-white fw-bold border card-width">A</span>
              <span className="text-white fw-bold border card-width">L</span>
              <span className="text-white fw-bold border card-width">H</span>
            </div>
          </div>
        ),
        name: "firstName",
        options: {
          filter: true,
          sort: true,
          customBodyRenderLite: (id: any, index: any) => {
            const present = list[index]?.attendances?.filter(
              (item: any) => item?.status === "p"
            );
            const absent = list[index]?.attendances?.filter(
              (item: any) => item?.status === "a"
            );
            const late = list[index]?.attendances?.filter(
              (item: any) => item?.status === "l"
            );
            const leave = list[index]?.attendances?.filter(
              (item: any) => item?.status === "h"
            );

            return (
              <div>
                <div className="d-flex text-center">
                  <span
                    className="text-success fw-bold border card-width"
                    role="button"
                    onClick={() => {
                      setAttendanceList(present);
                      setOpen(true);
                    }}
                  >
                    {present?.length}
                  </span>
                  <span
                    className="text-danger fw-bold border card-width"
                    role="button"
                    onClick={() => {
                      setAttendanceList(absent);
                      setOpen(true);
                    }}
                  >
                    {absent?.length}
                  </span>
                  <span
                    className="text-warning fw-bold border card-width"
                    role="button"
                    onClick={() => {
                      setAttendanceList(late);
                      setOpen(true);
                    }}
                  >
                    {late?.length}
                  </span>
                  <span
                    className="text-secondary fw-bold border card-width"
                    role="button"
                    onClick={() => {
                      setAttendanceList(leave);
                      setOpen(true);
                    }}
                  >
                    {leave?.length}
                  </span>
                </div>
              </div>
            );
          },
        },
      },
    ];

    const endDate = info?.endDate ? new Date(info?.endDate) : info?.toDate;
    while (date.getMonth() === month) {
      const newDate = date.getDate();
      if (date.getDate() <= endDate.getDate()) {
        days.push({
          label: moment(date).format("ddd Do"),
          name: "date",
          options: {
            filter: true,
            sort: false,
            customBodyRenderLite: (id: any, index: any) => {
              const attendance = list[index]?.attendances?.find((v: any) => {
                const incomingDate = new Date(v?.date);
                return newDate === incomingDate.getDate();
              });
              const status = attendance ? attendance?.status : null;
              return (
                <div
                  role="button"
                  onClick={() => {
                    // setViewData({
                    //   ...attendance,
                    //   employee: list[index],
                    // });
                    navigate(`/console/employee/attendance/create`, {
                      state: {
                        id: list[index]?.id,
                        employeeNumber: list[index].employeeNumber,
                        date: attendance?.date,
                      },
                    });

                    // setOpen(true);
                  }}
                >
                  <Tooltip
                    title={
                      status === "a"
                        ? "Absent"
                        : status === "l"
                        ? "Late"
                        : status === "h"
                        ? "Holiday"
                        : status === "v"
                        ? "Leave"
                        : "Present"
                    }
                  >
                    <span
                      className={
                        status === "a"
                          ? "text-danger text-uppercase fw-bolder"
                          : status === "l"
                          ? "text-warning text-uppercase fw-bolder"
                          : status === "h"
                          ? "text-info text-uppercase fw-bolder"
                          : status === "v"
                          ? "text-secondary text-uppercase fw-bolder"
                          : "text-success text-uppercase fw-bolder"
                      }
                    >
                      {status}
                    </span>
                  </Tooltip>
                </div>
              );
            },
          },
        });
      }
      date.setDate(date.getDate() + 1);
    }
    setCols(days);
    return days;
  };

  const getEntrancies = async () => {
    try {
      const url = `/entrancy/view`;
      const response = await fetchDetails(url);

      const url2 = `/branch/view`;
      const response2 = await fetchDetails(url2);
     await fetchDetails("/employee/view");

      if (response.status) {
        setEntrances(
          response?.data?.map((v: any) => ({
            label: v.name,
            value: v.id,
          }))
        );
      }

      if (response2.status) {
        setBranches(
          response2?.data?.map((v: any) => ({
            label: v.name,
            value: v.id,
          }))
        );
      }
    } catch (error: any) {}
  };

  useEffect(() => {
    if (list?.length) {
      getDaysInMonth();
    }
  }, [
    list?.length,
    info?.fromDate,
    info?.toDate,
    info?.startDate,
    info?.endDate,
  ]);

  useEffect(() => {
    setLoading(true);
    getEntrancies().then();
  }, []);

  const months = monthList.map((v: any) => ({
    label: v.name,
    value: v.id,
  }));

  return (
    <>
      <Breadcrumb />
      {loading ? <LinearProgress /> : null}
      <form
        onSubmit={(e: any) => {
          e.preventDefault();
          setLoading(true);
          setDataToSubmit(info);
        }}
        className="bg-white shadow-sm p-2"
      >
        <Row className="mb-3">
          <Col md={2}>
            <label className="col-form-label text-capitalize">Branch</label>
            <ReactSelect
              options={branches}
              className="text-nowrap"
              onChange={(v: any) => {
                setInfo((oldInfo: any) => ({ ...oldInfo, branchId: v?.value }));
              }}
            />
          </Col>
          <Col md={2}>
            <label className="col-form-label text-capitalize">Entrance</label>
            <ReactSelect
              options={entrances}
              className="text-nowrap"
              onChange={(v: any) => {
                setInfo((oldInfo: any) => ({
                  ...oldInfo,
                  entranceId: v?.value,
                }));
              }}
            />
          </Col>
          <Col md={2}>
            <label className="col-form-label text-capitalize">Year</label>
            <input
              className="form-control"
              value={info?.year}
              required={true}
              type="number"
              maxLength={4}
              onChange={(e: any) => {
                setInfo({
                  ...info,
                  year: e.target.value,
                  startDate: moment(
                    moment({
                      month: info?.month,
                      year: e.target.value,
                    }).startOf("month")
                  ).format("YYYY-MM-DD"),
                  endDate: moment(
                    moment({ month: info?.month, year: e.target.value }).endOf(
                      "month"
                    )
                  ).format("YYYY-MM-DD"),
                });
              }}
            />
          </Col>
          <Col md={2}>
            <label className="col-form-label text-capitalize">Month</label>
            <ReactSelect
              className="text-nowrap"
              required={!info?.startDate}
              onChange={(v: any) => {
                setInfo({
                  ...info,
                  month: v?.value - 1,
                  startDate: moment(
                    moment({ month: v?.value - 1, year: info?.year }).startOf(
                      "month"
                    )
                  ).format("YYYY-MM-DD"),
                  endDate: moment(
                    moment({ month: v?.value - 1, year: info?.year }).endOf(
                      "month"
                    )
                  ).format("YYYY-MM-DD"),
                });
              }}
              options={months}
            />
          </Col>
          <Col md={2}>
            <label className="col-form-label text-capitalize">Start Date</label>
            <input
              className="form-control"
              value={info?.startDate}
              required={true}
              type="date"
              onChange={(e: any) => {
                let newDate = new Date(e.target.value);
                const month = newDate.getMonth();
                const year = newDate.getFullYear();
                setInfo({
                  ...info,
                  month,
                  year,
                  startDate: e.target.value,
                  endDate: moment(
                    moment({ month: month }).endOf("month")
                  ).format("YYYY-MM-DD"),
                });
              }}
            />
          </Col>
          <Col md={2}>
            <label className="col-form-label text-capitalize">End Date</label>
            <input
              className="form-control"
              value={info?.endDate}
              // disabled={true}
              required={true}
              type="date"
              onChange={(e: any) => {
                setInfo({
                  ...info,
                  endDate: moment(e.target.value).format("YYYY-MM-DD"),
                });
              }}
              max={moment(moment(info?.endDate).endOf("month")).format(
                "YYYY-MM-DD"
              )}
              // min={moment(info?.fromDate).format("YYYY-MM-DD")}
              // max={moment(info?.toDate).format("YYYY-MM-DD")}
            />
          </Col>
          <Col md={2}>
            <label className="col-form-label text-capitalize">Status</label>
            <ReactSelect
              className="text-nowrap"
              onChange={(v: any) => {
                setInfo({
                  ...info,
                  status: v?.value,
                });
              }}
              options={attendanceStatusList}
            />
          </Col>
          <Col md={2}>
            <label className="col-form-label text-capitalize">
              Attendance Device{" "}
            </label>
            <ReactSelect
              className="text-nowrap"
              onChange={(v: any) => {
                setInfo({
                  ...info,
                  attendanceDevice: v?.value,
                });
              }}
              options={attendanceDeviceStatus}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          {loading ? (
            <CircularProgress />
          ) : (
            <Col md={3}>
              <Button
                type="submit"
                color="inherit"
                variant="contained"
                size="small"
                className="me-3"
                startIcon={<Search />}
              >
                Search
              </Button>
              <Button
                onClick={() => navigate(0)}
                color="error"
                variant="contained"
                size="small"
                startIcon={<Clear />}
              >
                Reset
              </Button>
            </Col>
          )}
        </Row>
      </form>

      <Row>
        <Col md={12}>
          <SmallDialog
            setOpen={setOpen}
            open={open}
            title={<>{"Attendance details"}</>}
            needButton={true}
            maxWidth="md"
          >
            <div>
              {attendanceList?.length > 0 ? (
                <Table>
                  <TableHead>
                    <TableCell>No</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Punch In</TableCell>
                    <TableCell>Punch Out</TableCell>
                    <TableCell>Entrance</TableCell>
                    <TableCell>Device</TableCell>
                    <TableCell>Status</TableCell>
                  </TableHead>
                  <TableBody>
                    {attendanceList?.map((item: any, key: any) => (
                      <TableRow key={key}>
                        <TableCell>{key + 1}</TableCell>
                        <TableCell>
                          {" "}
                          {item?.date
                            ? moment(item?.date).format("YYYY-MM-DD")
                            : null}
                        </TableCell>
                        <TableCell>
                          {item?.checkInTime
                            ? moment(item?.checkInTime).format("HH:mm:ss A")
                            : null}
                        </TableCell>
                        <TableCell>
                          {item?.checkOutTime
                            ? moment(item?.checkOutTime).format("HH:mm:ss A")
                            : null}
                        </TableCell>
                        <TableCell>{item?.AttendanceArea?.name}</TableCell>
                        <TableCell>{item?.deviceSerialNumber}</TableCell>
                        <TableCell>
                          {
                            attendanceStatusList?.find(
                              (st) => st?.value === item?.status
                            )?.label
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : null}
            </div>
          </SmallDialog>
          <DatatableComponent
            exportButtons={report}
            title={
              report ? (
                "Attendance Report (Present: P,Late: L ,Absent: A ,Holidays: H ,Leave: V)"
              ) : (
                <div className="mb-4">
                  <span className="fw-bolder text-capitalize badge bg-secondary rounded me-2">
                    {"Legends:"}
                  </span>
                  <span className="fw-bolder text-capitalize me-2">
                    {"Present:"}
                    <strong className="text-success ms-1">P</strong>
                  </span>
                  <span className="fw-bolder text-capitalize me-2">
                    {"Late:"}
                    <strong className="text-warning ms-1">L</strong>
                  </span>
                  <span className="fw-bolder text-capitalize me-2">
                    {"Absent:"}
                    <strong className="text-danger ms-1">A</strong>
                  </span>
                  <span className="fw-bolder text-capitalize me-2">
                    {"Holiday:"}
                    <strong className="text-info ms-1">H</strong>
                  </span>
                  <span className="fw-bolder text-capitalize me-2">
                    {"Leave:"}
                    <strong className="text-secondary ms-1">V</strong>
                  </span>
                </div>
              )
            }
            columns={cols}
            url={`/attendance/datatable?info=${JSON.stringify(dataToSubmit)}`}
            processData={(data: any) => {
              if (data) {
                setLoading(false);
              }
              setList(data.data);
              return data;
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default AllAttendanceList;
