import { Card, CardContent, CardHeader } from "@mui/material";
import OpenDocuments from "../../../components/openDocument";
import { useEffect, useState } from "react";
import { fetchDetails, getFileMime } from "../../../services/common";
import { LinearProgress } from "@material-ui/core";

const Help = () => {
  const [setting, setSettings] = useState<any>();

  const fetchSettingDetails = async () => {
    const resp = await fetchDetails(`user_manual/`);
    if (resp.status) {
      setSettings(resp.data);
    }
  };
  useEffect(() => {
    fetchSettingDetails().then();
  }, []);
  return (
    <>
      {setting?.userManual ? null : <LinearProgress />}
      <Card>
        <CardHeader subheader="USER GUIDE"/>
        <CardContent>
          {setting?.userManual?.map((v: string) => (
            <OpenDocuments
              downloadable={true}
              card={true}
              document={v}
              mimeType={getFileMime(v)}
            />
          ))}
        </CardContent>
      </Card>
    </>
  );
};

export default Help;
