import {useEffect, useState } from "react";
import { fetchDetails, promotionStatusList } from "../../../services/common";
import { Chip } from "@mui/material";
import ReportEngineComponent from "../../../components/ReportEngineComponent";

const PromotedReport=()=>{

    const [fiscalYears, setFiscalYears] = useState([]);
    const [value, setValue] = useState<any>({});
    
      const loadInitialData = async () => {
        // const employeeList = await fetchDetails("report/caisse/members");
        const fiscalYears = await fetchDetails(`fiscal/view`);
        if (fiscalYears.status) {
          setFiscalYears(fiscalYears.data);
        }
        // if (employeeList.status) setRequesters(employeeList?.data);
      };
    
      useEffect(() => {
        loadInitialData().then();
      }, []);
  return (
    <ReportEngineComponent
    setValue={setValue}
    value={value}
      title={"Promoted list Report"}
      url={`report/promoted`}
      columns={[
        {
            label: "Emp. ID",
            name: "employee.employeeNumber",
          },
          {
            label: "Emp. Names",
            name: "employee.names",
            data: "employee.firstName",
          },
        {
          label: `1st Year(%)`,
          name: "firstYear.marks",
          type:'number'

        },
        {
          label: `2nd Year(%)`,
          name: "secondYear.marks",
          type:'number'

        },
        {
          label: `3rd year(%)`,
          name: "thirdYear.marks",
          type:'number'

        },
        {
          label: "Promoted From",
          name: "promoteFrom.name",
        },
        {
          label: "Promoted To",
          name: "promoteTo.name",
        },
        {
          label: "Current Position",
          name: "Position.name",
        },
        {
            label: "Status",
            name: "status",
            options: {
              customBodyRender: (v: any) => <Chip label={v} size={"small"} color={v==="promoted"?'success':'error'} />,
            },
          },
      ]}
      filters={[
        {
            label: "Fiscal Years",
            name: "fiscal",
            values: fiscalYears.map(({ name, id }: any) => ({
              label: name,
              value: id,
            })),
          },
        {
          label: "Employee Name/ID",
          name: "empName",
          type: "text",
        },
        {
          label: "Status",
          name: "status",
          values: promotionStatusList,
        },
        {
          label: "Start Date",
          name: "startDate",
          type: "date",
        },
        {
          label: "End Date",
          name: "endDate",
          type: "date",
        },
      ]}
    />
  );
}

export default PromotedReport;
