import Breadcrumb from "../../../components/Breadcrumb";
import React, { useEffect, useState } from "react";
// @ts-ignore
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { AddForm } from "../AddForm";
import { fetchDetails } from "../../../services/common";
import WorkPlan from "./WorkPlan";
import { mapStateToProps } from "../../../store/helpers/mapState";
import { connect } from "react-redux";
import { Alert, AlertTitle, FormControlLabel, Switch } from "@mui/material";
import moment from "moment";
import FileUpload from "../../../components/FileUploader";
import MissionDestination from "./MissionDestination";
import { Provinces, Districts } from "rwanda";
import { Add, Delete, Edit } from "@mui/icons-material";
import { toast } from "react-toastify";

const CreateMission = (props: any) => {
  const { loggedUser } = props;
  const navigate = useNavigate();
  const [plan, setPlan] = useState<Array<any>>([{ plan: "", date: "" }]);
  const [destination, setDestination] = useState<Array<any>>([]);
  const [position, setPosition] = React.useState<any>([]);
  const [files, setFiles] = React.useState<any>([]);
  const [allDistrict, setAllDistrict] = React.useState<Array<any>>([]);
  const [district, setDistrict] = React.useState<Array<any>>([]);
  const [supervisor, setSupervisor] = React.useState<any>();
  const [info, setInfo] = React.useState<any>({});
  const [missionType, setMissionType] = React.useState<any>();
  const [startDate, setStartDate] = React.useState<any>();
  const [endDate, setEndDate] = React.useState<any>();
  const [missionCost, setMissionCost] = React.useState<number | undefined>();
  const [districtId, setDistrictId] = React.useState<string | undefined>();
  const [provinceId, setProvinceId] = React.useState<string | undefined>();
  const [transportMethod, setTransportMethod] = React.useState<any>();
  const [transportPrice, setTransportPrice] = React.useState<any>();
  const [kilometer, setKilometer] = React.useState<any>();
  const [includeNight, setIncludeNight] = React.useState<any>(false);
  const [initiator, setUnitiator] = React.useState<any>();
  const [addNew, setAddNew] = useState(0);
  const [provinces, setProvinces] = useState<Array<any>>([]);
  const [selectedItem, setSelectedItem] = useState<any>();

  const { id }: any = useParams();
  const [allowance, setAllowance] = React.useState<any>(null);

  const isInitiator =
    (loggedUser?.id === initiator?.id && id !== "new") ||
    (loggedUser && id === "new");
  const hasMissionApproverRole =
    loggedUser?.roles?.filter(
      (item: any) => item.role?.name === "mission-approver"
    )?.length > 0;
  const missionTransportPerKm =
    initiator?.Employee?.level?.MissionTransport?.amount;
  const financeAccess = hasMissionApproverRole && id !== "new";

  const fetchUnitiator = async (id: string) => {
    const resp = await fetchDetails(`/user/view/${id}`);
    if (resp.status) {
      setUnitiator(resp.data);
    }
  };

  const fetchInfoDetails = async (id: string) => {
    const resp = await fetchDetails(`mission/view/${id}`);
    if (resp.status) {
      const startDate = moment(resp.data.startDate).format("YYYY-MM-DD");
      const endDate = moment(resp.data.endDate).format("YYYY-MM-DD");
      setStartDate(startDate);
      setEndDate(endDate);
      setDistrictId(resp.data.destination);
      setProvinceId(resp.data.District?.province);
      setTransportMethod(resp.data?.transportMeans);
      setInfo({
        ...resp.data,
        startDate,
        endDate,
        firstName: resp.data?.missionaryEmployee?.firstName,
        lastName: resp.data?.missionaryEmployee?.lastName,
        positionId:resp.data?.missionaryEmployee?.positionId
      });
      setKilometer(resp.data?.kilometer ?? kilometer);
      setPlan(resp.data?.MissionPlans);
      setTransportPrice(resp.data?.transportPrice);
      setDestination(resp.data?.MissionDestinations);
    }
  };

  const fetchDistrict = async () => {
    const district = await fetchDetails(`zone/district/${provinceId}`);
    if (district.status) {
      setDistrict(district.data);
    }
  };
  const fetchZones = async () => {
    const provinces = await Provinces();
    setProvinces(provinces);
    // const resp = await fetchDetails(`zone/view`);
    // if (resp.status) {
    //   setZones(resp.data);
    // }
  };
  const fetchAllowance = async () => {
    const zoneInfo = district?.find((dist: any) => dist.id === districtId);

    const resp = await fetchDetails(
      `mission-allowance/viewByZone/${zoneInfo?.zoneId}`
    );

    if (resp.status) {
      setAllowance(resp.data);
    } else {
      setAllowance(null);
    }
  };
  useEffect(() => {
    if (id !== "new") {
      fetchInfoDetails(id).then();
    }
  }, [id]);

  useEffect(() => {
    if (info?.id && loggedUser.id !== info?.requester) {
      fetchUnitiator(info?.requester).then();
    } else {
      setUnitiator(loggedUser);
    }
  }, [info?.id, info?.requester, loggedUser]);

  useEffect(() => {
    if (districtId) {
      fetchAllowance().then();
    }
  }, [districtId, district]);

  useEffect(() => {
    if (provinceId) {
      fetchDistrict().then();
    }
  }, [provinceId]);

  useEffect(() => {
    if (missionType && allowance) {
      const night =
        missionType - 1 > 0 && includeNight
          ? (missionType - 1) * allowance.night
          : 0;
      let cost = night + missionType * allowance.day;
      if (kilometer && missionTransportPerKm) {
        cost += Number(kilometer) * missionTransportPerKm;
        setTransportPrice(kilometer * missionTransportPerKm);
      }
      if (transportPrice && transportMethod === "public") {
        cost += Number(transportPrice);
      }
      setMissionCost(cost);
    }
  }, [
    allowance,
    missionType,
    kilometer,
    missionTransportPerKm,
    transportPrice,
    includeNight,
  ]);

  useEffect(() => {
    if (startDate && endDate) {
      let days = moment(endDate).diff(moment(startDate), "days");
      days = days + 1;
      setMissionType(days);
    }
  }, [startDate, endDate]);

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     const days = moment(endDate).diff(moment(startDate), "days");
  //     setMissionType(days);
  //   }
  // }, []);

  useEffect(() => {
    if (transportMethod !== "private") {
      setKilometer(null);
    }
    setInfo({ ...info, transportMeans: transportMethod });
  }, [transportMethod]);

  useEffect(() => {
    if (initiator) {
      setInfo({
        ...info,
        firstName: initiator?.Employee?.firstName,
        lastName: initiator?.Employee?.lastName,
        positionId: initiator?.Employee?.positionId,
        missionary: initiator?.Employee?.id,
        requester: initiator?.id,
      });
    }
  }, [initiator]);

  useEffect(() => {
    laodInitialData().then();
    fetchZones().then();
  }, []);

  const laodInitialData = async () => {
    const district = await fetchDetails(`report/districts`);
    if (district.status) {
      setAllDistrict(district.data);
    }
    const position = await fetchDetails("/position/view");
    if (position.status) {
      setPosition(position?.data);
    }
  };

  const isUpdate = id !== "new";
  // const needSupervisor = loggedUser?.roles?.filter(
  //   (role: any) =>
  //     role?.role?.name === "other-staff" || role?.role?.name === "support-staff"
  // );

  return (
    <div>
      <Breadcrumb activeLabel={isUpdate ? "Edit" : "Create"} />
      <Row className={"justify-content-center"}>
        <Col md={12}>
          <AddForm
            inline={true}
            title={
              isInitiator && id !== "new"
                ? "Edit Request"
                : id === "new"
                ? "Mission Request"
                : "Mission and Transport Allowance"
            }
            col={6}
            mainFields={[
              {
                title: "",
                fields: [
                  {
                    label: "approver",
                    name: "approver",
                    type: "text",
                    hidden: true,
                    required: true,
                    disabled: true,
                    value: supervisor?.user?.id,
                    col: 4,
                  },
                  {
                    label: "missionary",
                    name: "missionary",
                    hidden: true,
                    col: 4,
                    value: info?.missionary,
                  },
                  {
                    label: "Requestor",
                    name: "requester",
                    hidden: true,
                    col: 4,
                    value: info?.requester,
                  },
                  {
                    label: "First Name",
                    name: "firstName",
                    disabled: true,
                    col: 4,
                    value: info?.firstName,
                  },
                  {
                    label: "Last Name",
                    name: "lastName",
                    disabled: true,
                    col: 4,
                    value: info?.lastName,
                  },
                  {
                    label: "Position",
                    name: "positionId",
                    type: "select",
                    disabled: true,
                    values: position?.map((v: any) => ({
                      label: v.name,
                      value: v.id,
                    })),
                    col: 4,
                    value: info?.positionId,
                  },

                  {
                    label: "Departure Date",
                    name: "startDate",
                    type: "date",
                    required: true,
                    col: 4,
                    value: info?.startDate,
                    disabled: !isInitiator,
                  },
                  {
                    label: "Return Date",
                    name: "endDate",
                    type: "date",
                    required: true,
                    col: 4,
                    value: info?.endDate,
                    disabled: !isInitiator,
                  },
                  {
                    label: "First Destination",
                    name: "destination",
                    required: true,
                    col: 4,
                    value: info?.destination,
                    disabled: !isInitiator,
                  },
                  {
                    label: "Who cover the mission allowance",
                    name: "missionCoveredBy",
                    required: financeAccess,
                    col: 4,
                    value: info?.missionCoveredBy ?? "NPPA",
                    hidden: !financeAccess,
                  },
                  {
                    label: "Covered Allowance",
                    name: "coveredAllowance",
                    required: financeAccess,
                    col: 4,
                    value: info?.coveredAllowance,
                    hidden: !financeAccess,
                  },
                  // {
                  //   name: "days",
                  //   value: missionType,
                  //   label: "Number of days",
                  //   disabled: true,
                  //   col: 4,
                  // },
                  // {
                  //   label: "Province",
                  //   name: "provinceId",
                  //   required: true,
                  //   type: "select",
                  //   values: provinces.map((v) => ({
                  //     label: `${v}`,
                  //     value: v,
                  //   })),
                  //   col: 4,
                  //   value: provinceId,
                  //   setValue: setProvinceId,
                  //   disabled: !isInitiator,
                  // },
                  // {
                  //   label: "District",
                  //   name: "destination",
                  //   required: true,
                  //   type: "select",
                  //   values: district.map((v) => ({
                  //     label: v.name,
                  //     value: v.id,
                  //   })),
                  //   col: 4,
                  //   value: districtId,
                  //   setValue: setDistrictId,
                  //   disabled: !isInitiator,
                  // },

                  // {
                  //   label: "Transport Method",
                  //   name: "transportMeans",
                  //   type: "select",
                  //   hidden: !hasMissionApproverRole,
                  //   col: 4,
                  //   setValue: setTransportMethod,
                  //   values: [
                  //     {
                  //       label: "Private",
                  //       value: "private",
                  //     },
                  //     {
                  //       label: "Public",
                  //       value: "public",
                  //     },
                  //     {
                  //       label: "Group",
                  //       value: "group",
                  //     },
                  //     {
                  //       label: "NPPA",
                  //       value: "nppa",
                  //     },
                  //   ],
                  //   required: true,
                  //   value: info?.transportMeans,
                  // },
                  // {
                  //   label: "Kilometers",
                  //   name: "kilometer",
                  //   required: true,
                  //   value: kilometer,
                  //   hidden: transportMethod !== "private",
                  //   type: "number",
                  //   col: 6,
                  //   setValue: setKilometer,
                  // },
                  // {
                  //   label: "Transport(Cost in RWF)",
                  //   name: "transportPrice",
                  //   value: transportPrice,
                  //   //   transportMethod !== "private"
                  //   //     ? info?.transportPrice
                  //   //     : kilometer * missionTransportPerKm,
                  //   hidden: !kilometer && transportMethod !== "public",
                  //   disabled: transportMethod !== "public",
                  //   col: 6,
                  //   setValue: setTransportPrice,
                  // },
                  {
                    name: "destinationList",
                    value: destination,
                    col: 12,
                    render: () => {
                      return (
                        <>
                          <Button
                            onClick={() => setAddNew(1)}
                            disabled={!isInitiator}
                            className="mb-3"
                          >
                            Add Destination
                          </Button>
                          <MissionDestination
                            setDestination={setDestination}
                            destination={destination}
                            allDistrict={allDistrict}
                            setAddNew={setAddNew}
                            editable={true}
                            onEdit={(row: any) => {
                              const startDate = moment(row.startDate).format(
                                "YYYY-MM-DD"
                              );
                              const endDate = moment(row.endDate).format(
                                "YYYY-MM-DD"
                              );
                              setStartDate(startDate);
                              setEndDate(endDate);
                              setDistrictId(row.destination);
                              setProvinceId(row?.province);
                              setTransportMethod(row?.transportMeans);
                              setKilometer(row?.kilometer ?? kilometer);
                              setTransportPrice(row?.transportPrice);
                              setSelectedItem(row?.index);
                            }}
                          />
                        </>
                      );
                    },
                  },
                  // {
                  //   name: "missionCost",
                  //   value: missionCost,
                  //   hidden: allowance === null,
                  //   col: 12,
                  //   render: () => {
                  //     const night = missionType - 1;
                  //     const zone = district.find(
                  //       (value) => value.id === districtId
                  //     );
                  //     return (
                  //       <div className={"mb-3"}>
                  //         <Alert severity={"success"}>
                  //           <AlertTitle>
                  //             Your Allowance{" "}
                  //             <FormControlLabel
                  //               hidden={!hasMissionApproverRole}
                  //               className="ms-2"
                  //               control={
                  //                 <Switch
                  //                   checked={includeNight}
                  //                   onChange={(event: any) => {
                  //                     setIncludeNight(event.target.checked);
                  //                   }}
                  //                 />
                  //               }
                  //               label="Include night"
                  //             />
                  //           </AlertTitle>
                  //           <div>
                  //             Day :{" "}
                  //             <b>{allowance?.day?.toLocaleString()} RWF</b>
                  //           </div>
                  //           {includeNight ? (
                  //             <div>
                  //               Night :{" "}
                  //               <b>{allowance?.night?.toLocaleString()} RWF</b>
                  //             </div>
                  //           ) : null}

                  //           {startDate && endDate ? (
                  //             <div>
                  //               Total :{" "}
                  //               <b>
                  //                 {missionCost?.toLocaleString()} RWF for{" "}
                  //                 {missionType?.toLocaleString()} {"Day(s)"}
                  //                 &nbsp;
                  //                 {missionType > 1 && includeNight
                  //                   ? `and ${night} Night(s)`
                  //                   : null}
                  //               </b>
                  //             </div>
                  //           ) : null}
                  //           <div>
                  //             <b>Destination:</b> {zone?.name}
                  //           </div>
                  //           {/* <div>
                  //             <b>Branches :</b>{" "}
                  //             {zone?.branches
                  //               ?.map((v: any) => v.name)
                  //               .join(", ")}
                  //           </div> */}
                  //         </Alert>
                  //       </div>
                  //     );
                  //   },
                  // },
                  {
                    label: "Reason",
                    name: "reason",
                    type: "textarea",
                    required: true,
                    col: 12,
                    value: info?.reason,
                    hidden: !isInitiator,
                  },
                  {
                    label: "What is needed to achieve the goal",
                    name: "needs",
                    type: "textarea",
                    col: 12,
                    value: info?.needs,
                    hidden: !isInitiator,
                  },
                  {
                    label: "Support document",
                    name: "supportingDocument",
                    type: "file",
                    multiple: true,
                    hidden: !isInitiator,
                    col: 12,
                    value: files,
                    render: () => {
                      return <FileUpload setFiles={setFiles} files={files} />;
                    },
                  },
                  {
                    name: "plan",
                    value: plan,
                    hidden: true,
                  },
                ],
              },
            ]}
            appendForm={
              <div>
                {isInitiator ? (
                  <WorkPlan
                    setPlan={setPlan}
                    plan={plan}
                    addNew={id === "new"}
                    planInfo={info?.MissionPlans}
                  />
                ) : null}
              </div>
            }
            beforeSubmit={() => {
              if (destination?.length <= 0) {
                toast.error("Destination(s) cannot be empty.");
                return false;
              }
              return true;
            }}
            url={id !== "new" ? `mission/update/${id}` : "/mission/create"}
            method={id !== "new" ? "PUT" : "POST"}
            onSuccess={() => navigate(-1)}
          />
        </Col>
      </Row>

      <AddForm
        maxWidth="md"
        mainFields={[
          {
            title: "",
            fields: [
              {
                label: "Start Date",
                name: "startDate",
                type: "date",
                required: true,
                col: 4,
                value: startDate,
                setValue: setStartDate,
                minDate: id === "new" ? moment().startOf("day") : null,
                disabled: !isInitiator,
              },
              {
                label: "End Date",
                name: "endDate",
                type: "date",
                required: true,
                col: 4,
                value: endDate,
                setValue: setEndDate,
                // validate: validateEndDate,
                minDate: id === "new" ? moment(startDate).startOf("day") : null,
                disabled: !isInitiator,
              },
              {
                name: "days",
                value: missionType,
                label: "Number of days",
                disabled: true,
                col: 4,
              },
              {
                label: "Province",
                name: "provinceId",
                required: true,
                type: "select",
                values: provinces.map((v: any) => ({
                  label: `${v}`,
                  value: v,
                })),
                col: 4,
                value: provinceId,
                setValue: setProvinceId,
                disabled: !isInitiator,
              },
              {
                label: "District",
                name: "destination",
                required: true,
                type: "select",
                values: district.map((v) => ({
                  label: v.name,
                  value: v.id,
                })),
                col: 4,
                value: districtId,
                setValue: setDistrictId,
                disabled: !isInitiator,
              },
              {
                label: "Transport Method",
                name: "transportMeans",
                type: "select",
                hidden: !financeAccess,
                col: 4,
                setValue: setTransportMethod,
                values: [
                  {
                    label: "Private",
                    value: "private",
                  },
                  {
                    label: "Public",
                    value: "public",
                  },
                  {
                    label: "Group",
                    value: "group",
                  },
                  {
                    label: "NPPA",
                    value: "nppa",
                  },
                ],
                required: true,
                value: info?.transportMeans,
              },
              {
                label: "Kilometers",
                name: "kilometer",
                required: true,
                value: kilometer,
                hidden: transportMethod !== "private",
                type: "number",
                col: 6,
                setValue: setKilometer,
              },
              {
                label: "Transport(Cost in RWF)",
                name: "transportPrice",
                value: transportPrice,
                //   transportMethod !== "private"
                //     ? info?.transportPrice
                //     : kilometer * missionTransportPerKm,
                hidden: !kilometer && transportMethod !== "public",
                disabled: transportMethod !== "public",
                col: 6,
                setValue: setTransportPrice,
              },
              {
                name: "missionCost",
                value: missionCost,
                hidden: allowance === null,
                col: 12,
                render: () => {
                  const night = missionType - 1;
                  const zone = district.find(
                    (value) => value.id === districtId
                  );
                  return (
                    <div className={"mb-3"}>
                      <Alert severity={"success"}>
                        <AlertTitle>
                          Your Allowance{" "}
                          <FormControlLabel
                            hidden={!financeAccess}
                            className="ms-2"
                            control={
                              <Switch
                                checked={includeNight}
                                onChange={(event: any) => {
                                  setIncludeNight(event.target.checked);
                                }}
                              />
                            }
                            label="Include night"
                          />
                        </AlertTitle>
                        <div>
                          Day : <b>{allowance?.day?.toLocaleString()} RWF</b>
                        </div>
                        {includeNight ? (
                          <div>
                            Night :{" "}
                            <b>{allowance?.night?.toLocaleString()} RWF</b>
                          </div>
                        ) : null}

                        {startDate && endDate ? (
                          <div>
                            Total :{" "}
                            <b>
                              {missionCost?.toLocaleString()} RWF for{" "}
                              {missionType?.toLocaleString()} {"Day(s)"}
                              &nbsp;
                              {missionType > 1 && includeNight
                                ? `and ${night} Night(s)`
                                : null}
                            </b>
                          </div>
                        ) : null}
                        <div>
                          <b>Destination:</b> {zone?.name}
                        </div>
                      </Alert>
                    </div>
                  );
                },
              },
            ],
          },
        ]}
        url={"/"}
        beforeSubmit={(e) => {
          const arrayList = [...destination];
          if (selectedItem >= 0) {
            destination[selectedItem] = e;
            setDestination([...destination]);
          } else {
            arrayList.push(e);
            setDestination(arrayList);
          }

          setAddNew(0);
          return false;
        }}
        onSuccess={() => setAddNew(0)}
        open={addNew}
        setOpen={() => setAddNew(0)}
      />
    </div>
  );
};

// export default CreateMission;
export default connect(mapStateToProps)(CreateMission);
