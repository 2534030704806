// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CREDIT: https://getbootstrap.com/docs/5.1/examples/sign-in/signin.css */

html,
body {
  height: 100%;
  background-color: hwb(205 85% 10%);
}

body {
  align-items: center;
}

.container-auth {
  width: 100%;
  max-width: 400px;
  padding: 15px;
  margin: auto;
}

.container-auth .checkbox {
  font-weight: 400;
}

.container-auth .form-floating:focus-within {
  z-index: 2;
}

.container-auth .form-input-top {
  margin-bottom: -1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.container-auth .form-input-middle {
  margin-bottom: -1px;
  border-radius: 0;
}

.container-auth .form-input-bottom {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.auth-icon {
  color: #7713F7;
  font-size: 4rem;
}
.blue-color{
  color: #054D6F;
}
.radius{
  border-radius: 10px;
}
.btn-primary {
  color: white;
  background-color: #054D6F;
  border-color: #054D6F
}

.btn-primary:hover {
  color: white;
  background-color: #054D6F;
  border-color: #054D6F
}
`, "",{"version":3,"sources":["webpack://./src/pages/auth.css"],"names":[],"mappings":"AAAA,0EAA0E;;AAE1E;;EAEE,YAAY;EACZ,kCAAkC;AACpC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,4BAA4B;EAC5B,6BAA6B;AAC/B;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,0BAA0B;AAC5B;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,cAAc;AAChB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,yBAAyB;EACzB;AACF;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB;AACF","sourcesContent":["/* CREDIT: https://getbootstrap.com/docs/5.1/examples/sign-in/signin.css */\n\nhtml,\nbody {\n  height: 100%;\n  background-color: hwb(205 85% 10%);\n}\n\nbody {\n  align-items: center;\n}\n\n.container-auth {\n  width: 100%;\n  max-width: 400px;\n  padding: 15px;\n  margin: auto;\n}\n\n.container-auth .checkbox {\n  font-weight: 400;\n}\n\n.container-auth .form-floating:focus-within {\n  z-index: 2;\n}\n\n.container-auth .form-input-top {\n  margin-bottom: -1px;\n  border-bottom-left-radius: 0;\n  border-bottom-right-radius: 0;\n}\n\n.container-auth .form-input-middle {\n  margin-bottom: -1px;\n  border-radius: 0;\n}\n\n.container-auth .form-input-bottom {\n  margin-bottom: 10px;\n  border-top-left-radius: 0;\n  border-top-right-radius: 0;\n}\n\n.auth-icon {\n  color: #7713F7;\n  font-size: 4rem;\n}\n.blue-color{\n  color: #054D6F;\n}\n.radius{\n  border-radius: 10px;\n}\n.btn-primary {\n  color: white;\n  background-color: #054D6F;\n  border-color: #054D6F\n}\n\n.btn-primary:hover {\n  color: white;\n  background-color: #054D6F;\n  border-color: #054D6F\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
